import * as moment from 'moment';

export function buildWeekNumber(year: number, week: number) {
  return (year + week / 100).toFixed(2);
}

/**
 * Gets the current week number determined by the date.
 *
 * @returns {string}
 */
export function getCurrentWeekNumber() {
  return buildWeekNumber(moment().isoWeekYear(), moment().isoWeek());
}

export function getCurrentDayName() {
  return moment()
    .format('dddd')
    .toLowerCase();
}

//
// export function getCurrentWeekNumber() {
//     let weekNumber: string;
//     weekNumber = (moment().isoWeekYear() + moment().isoWeek() / 100).toFixed(2);
//     return weekNumber;
// }
