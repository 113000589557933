/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/grocery-item.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export interface GroceryItem {
  unit: string;
  item: string;
  quantity: number;
  groceryCategory: string;
}

export function sortGroceries(groceries: GroceryItem[]) {
  groceries.sort((a, b) => {
    const firstItem = a.item?.toLowerCase() ?? '';
    const secondItem = b.item?.toLowerCase() ?? '';

    if (firstItem < secondItem) {
      return -1;
    }
    if (firstItem > secondItem) {
      return 1;
    }
    return 0;
  });
}
