import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { User } from '../../lprx-shared-lib/entities/user/user';
import { UserStatus } from '../../lprx-shared-lib/entities/user/user-status';
import { UserType } from '../../lprx-shared-lib/entities/user/UserType';
import { Api } from '../../providers/aws.api';
import { DynamoDB } from '../../providers/aws.dynamodb';
import { AuthService } from '../auth.service';
import { DistributorService } from '../distributor/distributor-service';
import { LayoutService } from '../layout/layout.service';
import { CdnService } from '../service/cdn.service';
import { DistributorsService } from '../service/distributors.service';
import { HeaderService } from '../service/header.service';
import {
  authLogOut,
  getTokenResponse,
  handleAuthorizationCode,
  RedirectAuthOptions,
  redirectToAuth,
} from '../../lprx-auth';
import { decodeJwt } from 'jose';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../lprx-shared-lib/utils/unsubscribe';
import { environment } from '../../environments/environment';

export class LoginDetails {
  email: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  error: Error;
  loginDetails: LoginDetails;
  distributor: Distributor;
  private userId: string;
  private logo: SafeUrl;
  private redirect: string;
  private warning: string;
  isProcessing: boolean = false;
  processingMagicLink: boolean = false;
  showBranding: boolean = false;

  subs: Subscription[] = [];

  /**
   * Redirects the user to the proper URL
   *
   * @param user
   * @param router
   * @param redirect
   */
  static redirectUser(user: User | any, router: Router, redirect: string) {
    if (redirect) {
      if (redirect.match(/^http/)) {
        window.location.href = redirect;
      } else {
        router.navigateByUrl(redirect);
      }
    } else if (user.userType === 'admin') {
      console.log('to /admin/dashboard');
      router.navigateByUrl('/admin/dashboard');
    } else if (user.userType === 'distributor') {
      console.log('to /distributor/dashboard');
      router.navigateByUrl('/distributor/dashboard');
    } else if (user.userType === UserType.Client) {
      console.log('to /planner');
      if (user.status() === UserStatus.FREE_TRIAL) {
        router.navigateByUrl('/planner?show_upgrade=1');
      } else {
        router.navigateByUrl('/planner');
      }
    }
  }

  constructor(
    public db: DynamoDB,
    public router: Router,
    private route: ActivatedRoute,
    private header: HeaderService,
    public layout: LayoutService,
    private api: Api,
    private cdn: CdnService,
    private renderer: Renderer2,
    public authService: AuthService,
    public distributorsService: DistributorsService,
    public distributorService: DistributorService,
  ) {}

  ngOnInit() {
    if (this.router.url.includes('logout')) {
      alert('logout');
      authLogOut();
    } else {
      this._init();
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
    // todo: this is repeated many times..do something like this.setPageLayout('focused-form');
    this.renderer.removeClass(document.body, 'bg-gray');
    this.header.show();
  }

  async _init() {
    // current url has state and code
    if (window.location.href.includes('logout')) {
      // logout - not sure if this is needed anymore....what is the purpose of this?
    } else if (window.location.href.includes('state') && window.location.href.includes('code')) {
      await handleAuthorizationCode();

      const tokenResponse = getTokenResponse();

      // decode the token
      let decoded = decodeJwt(tokenResponse.id_token);

      //    alert(JSON.stringify(decoded, null, 2));

      decoded = decodeJwt(tokenResponse.access_token);
      //      alert(JSON.stringify(decoded, null, 2));
      const username = decoded['username'];

      // get the username
      //        alert(username);

      const user = await this.authService.getLoggedInUser().toPromise();
      localStorage.setItem('userDetails', JSON.stringify(user));

      this.route.queryParams.subscribe((params) => {
        if (params['redirect']) {
          console.log(params['redirect']);
          this.redirect = params['redirect'];
        }

        LoginComponent.redirectUser(user, this.router, this.redirect);
      });
    } else {
      this.route.params.subscribe((params) => {
        let options: RedirectAuthOptions = {};

        if (params['distributorVanityId']) {
          options.urlParams = {
            vanity_id: params['distributorVanityId'],
          };
          redirectToAuth(options);
        } else {
          window.location.href = 'https://' + environment.lprx_auth_www_domain + '/login';
        }
      });
    }
  }

  // _ngOnInit() {
  //   try {
  //     this.authService.signOut();
  //   } catch (e) {
  //     // ignore
  //   }
  //
  //   this.loginDetails = new LoginDetails();
  //
  //   this.renderer.addClass(document.body, 'bg-gray');
  //   this.header.hide();
  //
  //   this.route.queryParams.subscribe((params) => {
  //     if (params['redirect']) {
  //       console.log(params['redirect']);
  //       this.redirect = params['redirect'];
  //     }
  //
  //     if (params['warning']) {
  //       this.warning = params['warning'];
  //     }
  //
  //     if (params['magic']) {
  //       this.processingMagicLink = true;
  //       this.showBranding = false;
  //       console.log(PasswordPasser.decrypt(params['magic']));
  //       const s = PasswordPasser.decrypt(params['magic']).split('|');
  //       this.login(s[0], s[1], true)
  //         .catch(() => {
  //           this.processingMagicLink = false;
  //           this.error = {
  //             name: '',
  //             stack: '',
  //             message:
  //               'Sorry, we were unable to log you in. Please login with your email address and password, or try a new link.',
  //           };
  //         })
  //         .then(() => {});
  //     }
  //   });
  //
  //   this.route.params.subscribe((params) => {
  //     // if (params['username']) {
  //     //     // todo: Use our API instead of Amplify SDK
  //     //     const getParams = {
  //     //         'TableName': `livingplaterx-mobilehub-1710364101-users`,
  //     //         'Key': {username: params['username']}
  //     //     };
  //     //     this.db.getDocumentClient()
  //     //         .then(client => client.get(getParams).promise())
  //     //         .then(data => {
  //     //             this.loginDetails.email = data.Item.email;
  //     //         })
  //     //         .catch((err: Error) => console.log(err));
  //     // }
  //
  //     if (params['distributorVanityId']) {
  //       this.initBranding(params['distributorVanityId']);
  //     }
  //   });
  // }

  // /**
  //  * Log in the user and redirect to the proper location
  //  */
  // async login(usernameOrEmail?: string, password?: string, throwOnError = false) {
  //   if (this.isProcessing) {
  //     return;
  //   }
  //
  //   console.log(usernameOrEmail);
  //
  //   this.isProcessing = true;
  //
  //   this.error = null;
  //   try {
  //     const emailAddress = usernameOrEmail || this.loginDetails.email.toLowerCase().trim();
  //     password = password || this.loginDetails.password.trim();
  //
  //     const u = await this.authService.signIn(emailAddress, password);
  //
  //     sessionStorage.removeItem('time-to-upgrade-shown');
  //
  //     const clientId = u.pool.clientId;
  //     const username = localStorage.getItem(
  //       `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
  //     ); // <--- username of last auth
  //
  //     this.authService
  //       .getLoggedInUser()
  //       .pipe(
  //         tap((user) => {
  //           localStorage.setItem('userDetails', JSON.stringify(user));
  //           if (user.userType !== UserType.Admin) {
  //             this.distributorsService.getCurrent();
  //           }
  //           if (user.userType === UserType.Distributor) {
  //             this.distributorService._initMealPlans();
  //           }
  //         })
  //       )
  //       .subscribe((user) => {
  //         this.isProcessing = false;
  //         LoginComponent.redirectUser(user, this.router, this.redirect);
  //       });
  //   } catch (e) {
  //     console.log('Login Error: ' + JSON.stringify(e));
  //
  //     if (e.message === 'Password attempts exceeded') {
  //       e.message =
  //         'Sorry, you have exceeded the number of attempts to login. Please try again later.';
  //     }
  //     this.error = e;
  //     this.isProcessing = false;
  //     if (throwOnError) {
  //       throw e;
  //     }
  //   }
  // }

  private async initBranding(distributorVanityId: string) {
    this.distributor = await this.api.get(`distributor/v/${distributorVanityId}`);
    this.logo = this.cdn.getSafeUrl(this.distributor.logo);
    this.layout.setBrandingFromDistributor(this.distributor);
  }
}
