import { environment } from '../../environments/environment';

export function apiHostname() {
  let currentHostname = window.location.hostname;

  if (currentHostname.match(/lprx-sandbox/)) {
    return 'api.lprx-sandbox.com';
  }

  if (environment.useLocal && currentHostname.match(/ngrok/)) {
    return 'api-lprx.ngrok.io';
  }

  return new URL(environment.api_base_url).hostname;
}
