import { plainToClass } from 'class-transformer';
import { DistributorType } from '../../../lprx-shared-lib/entities/distributor-type';
import { MealPlanCategories } from '../../../lprx-shared-lib/entities/meal-plan/meal-plan-categories';
import { MealPlanDistributorTypes } from '../../../lprx-shared-lib/entities/meal-plan/meal-plan-distributor-types';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { DEFAULT_LIST_LIMIT } from '../../../lprx-shared-lib/utils/constants';
import { ListResult } from '../../../lprx-shared-lib/utils/list-result';
import { LprxApi } from '../api';

export class MealPlans {
  constructor(private readonly lprxApi: LprxApi) {}

  get(id: string, distId?: string): Promise<MealPlan> {
    let path = `v3/meal-plans/${id}`;

    if (distId) {
      path += `/${distId}`;
    }

    return this.lprxApi.get(path).then((mealPlan: MealPlan) => plainToClass(MealPlan, mealPlan));
  }

  list(limit = DEFAULT_LIST_LIMIT, nextKey?: string): Promise<ListResult<MealPlan>> {
    const queryParams: any = { limit };
    if (nextKey) {
      queryParams.next_key = nextKey;
    }
    return this.lprxApi.get('v3/meal-plans', queryParams).then((result: ListResult<MealPlan>) => {
      return new ListResult<MealPlan>(plainToClass(MealPlan, result.items), result.next_key);
    });
  }

  save(mealPlan: MealPlan): Promise<MealPlan> {
    return this.lprxApi
      .post('v3/meal-plans', mealPlan)
      .then((result: MealPlan) => plainToClass(MealPlan, result));
  }

  remove(mealPlanId: string) {
    return this.lprxApi.delete(`v3/meal-plans/${mealPlanId}`);
  }

  getDistributorTypes(mealPlanId: string): Promise<MealPlanDistributorTypes> {
    return this.lprxApi.get(`v3/meal-plans/${mealPlanId}/distributor-types`);
  }

  enableDistributorType(
    mealPlanId: string,
    type: DistributorType
  ): Promise<MealPlanDistributorTypes> {
    return this.lprxApi.post(`v3/meal-plans/${mealPlanId}/distributor-types`, [type]);
  }

  disableDistributorType(
    mealPlanId: string,
    type: DistributorType
  ): Promise<MealPlanDistributorTypes> {
    return this.lprxApi.delete(`v3/meal-plans/${mealPlanId}/distributor-types/${type}`);
  }

  categories() {
    return this.lprxApi.get<MealPlanCategories>(`v3/meal-plans/categories`);
  }
}
