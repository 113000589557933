<h2 mat-dialog-title>Generate PDF</h2>
<mat-dialog-content>
  <ng-container *ngIf="!pdfUrl && !processing">
    <form (submit)="submit()">
      <div class="form-group mb-3">
        <div class="row align-items-center">
          <div class="col-4">Start&nbsp;Week</div>
          <div class="col">
            <select
              class="form-control"
              name="startWeek"
              [(ngModel)]="startWeek"
              (change)="checkNumWeeks()"
            >
              <option *ngFor="let d of potentialStartWeeks" [value]="d.weekNumber">
                {{ d.humanWeek }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="form-group mb-3">
        <div class="row align-items-center">
          <div class="col-4">#&nbsp;Weeks</div>
          <div class="col">
            <input
              class="form-control"
              type="number"
              step="1"
              min="1"
              max="10"
              name="numWeeks"
              [(ngModel)]="numWeeks"
              (change)="checkNumWeeks()"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div>
          <label
            ><input type="checkbox" name="includeCoverPage" [(ngModel)]="includeCoverPage" />
            Include Cover Page</label
          >
        </div>

        <div *ngIf="hasIntroPage">
          <label
            ><input type="checkbox" name="includeCoverPage" [(ngModel)]="includeIntroPage" />
            Include Intro Page</label
          >
        </div>
        <div>
          <label
            ><input type="checkbox" name="includeCalendar" [(ngModel)]="includeCalendar" /> Include
            Calendar</label
          >
        </div>
        <div *ngIf="!data.weeklyPlans[0].mealPlan.nonCalendar">
          <label
            ><input type="checkbox" name="showDates" [(ngModel)]="showDates" /> Show Dates</label
          >
        </div>
        <ng-container *ngIf="includeCalendar">
          <div>
            &nbsp;&nbsp;
            <label
              ><input type="checkbox" name="includePublicNotes" [(ngModel)]="includePublicNotes" />
              Public Notes</label
            >
          </div>
          <div>
            &nbsp;&nbsp;
            <label
              ><input
                type="checkbox"
                name="includePrivateNotes"
                [(ngModel)]="includePrivateNotes"
              />
              Private Notes</label
            >
          </div>
        </ng-container>
        <div>
          <label
            ><input type="checkbox" name="includeGroceries" [(ngModel)]="includeGroceries" />
            Include Groceries</label
          >
        </div>
        <div *ngIf="plannerConfig?.showNutritionFacts">
          <label
            ><input type="checkbox" name="includeNutrition" [(ngModel)]="includeNutrition" />
            Include Nutrition</label
          >
        </div>

        <div>
          <select name="unitSystem" [(ngModel)]="unitSystem" class="form-control">
            <option [value]="UnitSystem.Imperial" [selected]="unitSystem === UnitSystem.Imperial">
              Unit System: lb, oz
            </option>
            <option [value]="UnitSystem.Metric" [selected]="unitSystem === UnitSystem.Metric">
              Unit System: ml, g
            </option>
          </select>
        </div>
      </div>

      <p class="text-right">
        <button type="submit" appButton>Generate PDF</button>
      </p>
    </form>
  </ng-container>

  <ng-container *ngIf="processing">
    <app-loading [style]="'icon'"></app-loading> Processing, please wait…<br />
  </ng-container>

  <ng-container *ngIf="pdfUrl">
    <div class="text-center">
      <a target="_blank" appButton [href]="pdfUrl">Download PDF</a>
    </div>
  </ng-container>
</mat-dialog-content>
