import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { NutrientsCollection } from '../../../lprx-shared-lib/recipe/NutrientsCollection';
import { USDAFactsResults } from '../../../lprx-shared-lib/recipe/USDAFactsResults';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { nutrientTypesMap } from '../../nutrition-facts-label/nutrientTypes';
import { getNutrient } from '../../utilities/get-nutrient';
import { PlannerService } from '../planner.service';
import { RecipeOpenerService } from '../recipe-opener.service';

@Component({
  selector: 'app-usda-nutrition-facts-row',
  templateUrl: './usda-nutrition-facts-row.component.html',
  styleUrls: ['./usda-nutrition-facts-row.component.scss'],
})
export class UsdaNutritionFactsRowComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() recipe: Recipe;
  @Input() usdaFacts: USDAFactsResults;
  @Input() boldTitle: boolean;
  @Input() showHeader: boolean;
  @Input() showDailyPercentage: boolean;
  @Input() nutritionFacts: NutrientsCollection;
  @Input() boldValues: boolean;

  defaultNutrients = ['NA', 'FIBTG', 'SUGAR', 'CHOCDF', 'ENERC_KCAL', 'PROCNT', 'FAT'];
  private subs: Subscription[] = [];
  additionalNutrients: string[];

  constructor(private recipeOpener: RecipeOpenerService, private plannerService: PlannerService) {}

  ngOnInit() {
    this.subs.push(
      this.plannerService.plannerConfig$.subscribe((config) => {
        this.additionalNutrients = config.additionalNutrients.filter(
          (n) => !this.defaultNutrients.includes(n)
        );
      })
    );
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  openRecipe() {
    this.recipeOpener.openRecipe(this.recipe);
  }

  nutrientName(type: string) {
    const nutrient = getNutrient(this.usdaFacts, type);
    return nutrientTypesMap.get(type).name.replace(/,.*/, '');
  }
}
