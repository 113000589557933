/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/bundle/bundle.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Type } from 'class-transformer';
import { Default } from '../../utils/default.decorator';
import { createId } from '../../utils/id-generator';
import { BaseItem } from '../base-item';
import { BundleClient } from '../bundle-client';
import { PlannerType } from '../weeky-meal-plan/PlannerType';

export class PaymentInfo {
  cardHolderName = '';
  tokenId = '';
}

function typeBundleClient() {
  return BundleClient;
}

function typePaymentInfo() {
  return PaymentInfo;
}

@Exclude()
export class DistributorBundle implements BaseItem {
  @Expose()
  get id() {
    return this.bundleId;
  }

  set id(value: string) {
    this.bundleId = value;
  }

  // partition key
  @Expose()
  distributorId: string;

  // sort key
  @Expose()
  @Default(() => createId('db'))
  bundleId: string = createId('db');

  @Expose()
  mealPlanId: string;

  @Expose()
  version: string;

  @Expose()
  @Default(Date.now())
  startsAt: number = Date.now();

  @Expose()
  @Default(Date.now())
  sendInvitesAt: number = Date.now();

  @Expose()
  @Default(1)
  months: number = 1;

  @Expose()
  @Default('')
  note: string = '';

  @Expose()
  transactionId: string = '';

  @Expose()
  @Default(0)
  totalCost: number = 0;

  @Expose()
  @Default('draft')
  status: 'paid' | 'draft' = 'draft'; // draft, paid

  @Expose()
  @Default(PlannerType.Static)
  plannerType: PlannerType = PlannerType.Custom;

  @Expose()
  @Default([])
  @Type(typeBundleClient)
  clients: BundleClient[] = [];

  @Expose()
  @Type(typePaymentInfo)
  paymentInfo: PaymentInfo;

  @Expose()
  orderId: string;

  @Expose()
  @Default(0)
  appliedCredits = 0;

  static create(distributorId: string, mealPlanId: string): DistributorBundle {
    const distributorBundle = new DistributorBundle();
    distributorBundle.distributorId = distributorId;
    distributorBundle.mealPlanId = mealPlanId;
    return distributorBundle;
  }

  addClient(bundleClient: BundleClient) {
    if (!this.clients) {
      this.clients = [];
    }
    this.clients.push(bundleClient);
  }

  @Expose()
  @Default(() => Date.now())
  createdAt: number = Date.now();

  @Expose()
  @Default(() => Date.now())
  modifiedAt: number = Date.now();

  @Expose()
  @Default(false)
  isDeleted: boolean = false;

  @Expose()
  primaryPractitioner?: string;
}

/**
 * @deprecated Use DistributorBundle
 */
export { DistributorBundle as Bundle };
