<div class="row form-group" *ngIf="showCardSelect">
  <label for="selectedCard" class="col-md-5 col-form-label">Payment Method: </label>
  <div class="col-md-7">
    <select
      *ngIf="cardPaymentMethods"
      [(ngModel)]="selectedCard"
      (change)="onChangeCard($event)"
      id="selectedCard"
      name="selectedCard"
      class="form-control"
    >
      <option
        *ngFor="let paymentMethodObject of cardPaymentMethods"
        [value]="paymentMethodObject.id"
        [selected]="paymentMethodObject.id === cardPaymentMethods[0].id"
      >
        {{ paymentMethodObject.card.brand | titlecase }} - {{ paymentMethodObject.card.last4 }} Exp:
        {{ paymentMethodObject.card.exp_month }}/{{ paymentMethodObject.card.exp_year }}
      </option>
      <option [ngValue]="'--new--'">New Card</option>
    </select>
  </div>
</div>

<div [class.d-none]="!showNewCardForm">
  <ng-container *ngIf="collectName">
    <label for="cardHolderName" class="d-none">Card Holder Name</label>
    <input
      type="text"
      [(ngModel)]="cardHolderName"
      required
      id="cardHolderName"
      name="cardHolderName"
      placeholder="Name on Card"
      class="form-control"
      style="padding: 12px"
    />
  </ng-container>
  <div id="card-element" class="field"></div>
  <div id="card-errors" role="alert"></div>
</div>
