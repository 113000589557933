import { ChangeContext } from '@angular-slider/ngx-slider';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { PlannerConfiguration } from '../../../lprx-shared-lib/planner/planner-configuration';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { infinitySymbol } from '../../../lprx-shared-lib/vars';
import { AuthService } from '../../auth.service';
import { RecipeResult } from '../../model/entities/recipe-result';
import { RecipeToOpen } from '../../model/entities/recipe-to-open';
import { RecipeService } from '../../service/recipe.service';
import { MacroOption, macroOptions } from '../macro-options';
import { PlannerRecipeComponent } from '../planner-recipe/planner-recipe.component';
import { PlannerService } from '../planner.service';
import { RecipeOpenerService } from '../recipe-opener.service';
import { mealTypes } from '../../../lprx-shared-lib/entities/recipe/meal-types';

@Component({
  selector: 'app-recipe-box',
  templateUrl: './recipe-box.component.html',
  styleUrls: ['./recipe-box.component.scss'],
})
export class RecipeBoxComponent implements OnInit, OnDestroy {
  from = 0;
  term = '';
  recipes: RecipeResult[] = [];
  limit = 60;
  showLoadMore = false;

  subs: Subscription[] = [];

  mealTypes = mealTypes;
  selectedMealTypes = Array.from(this.mealTypes);

  showPending: boolean;

  termChanged: Subject<string> = new Subject<string>();
  onlyOwners: boolean = false;
  onlyFavorites: boolean = false;

  macroOptions: MacroOption[] = macroOptions;
  plannerConfig: PlannerConfiguration;

  @Input()
  clientId: string | undefined;

  constructor(
    private recipesService: RecipeService,
    private recipeOpenerService: RecipeOpenerService,
    private modalService: NgbModal,
    private plannerService: PlannerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.selectedMealTypes = Array.from(this.mealTypes);

    this.subs.push(this.getParamsSub(), this.plannerConfigSub(), this.termsChangedSubscription());

    this.plannerService.enableSlideOutPlanner.next(true);
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((p) => (this.plannerConfig = p));
  }

  private getParamsSub() {
    return this.route.params.pipe(take(1)).subscribe((p) => {
      this.clientId = p['username'];
      console.log(p);
      this.search();
    });
  }

  // /**
  //  * @returns {Subscription}
  //  */
  // private recipeOpenerSubscription() {
  //   return this.recipeOpenerService
  //     .getRecipeOpener()
  //     .subscribe(recipeToOpen => this.openRecipe(recipeToOpen));
  // }

  hasMacroFilter(): boolean {
    for (const macro of this.macroOptions) {
      if (macro.low !== macro.floor || macro.high !== macro.ceil) {
        return true;
      }
    }
    return false;
  }

  get filterApplied() {
    const selectedPlansCount = this.selectedMealTypes.length;
    const hasFilteredMealPlans =
      selectedPlansCount > 0 && selectedPlansCount < this.mealTypes.length;

    return hasFilteredMealPlans || this.onlyFavorites || this.onlyOwners || this.hasMacroFilter();
  }

  resetFilters() {
    this.onlyFavorites = false;
    this.onlyOwners = false;
    this.selectedMealTypes = Array.from(this.mealTypes);
    this.macroOptions.forEach((mo) => {
      mo.low = 0;
      mo.high = mo.ceil;
      mo.highDisplay = infinitySymbol;
    });
    this.search();
  }

  /**
   * @returns {Subscription}
   */
  private termsChangedSubscription() {
    return this.termChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((term) => {
      if (term.length === 0 || term.length > 1) {
        this.term = term;
        this.recipes = [];
        this.search();
      }
    });
  }

  changedTerm(text: string) {
    this.termChanged.next(text);
  }

  private search(clear = true) {
    if (clear) {
      this.from = 0;
      this.recipes = [];
    }

    this.showPending = true;
    this.showLoadMore = false;

    this.authService
      .getUser()
      .pipe(
        map((user) => user.userType),
        switchMap((userType) =>
          this.recipesService.search({
            clientUsername: this.clientId,
            mealTypes: this.selectedMealTypes,
            onlyFavorites: this.onlyFavorites,
            onlyUserRecipes: this.onlyOwners,
            searchAllMealPlans: false,
            searchTerm: this.term,
            userType: this.clientId ? UserType.Client : userType,
            from: this.from,
            limit: this.limit,
            macroOptions: this.macroOptions,
          })
        )
      )
      .subscribe((results) => this.processSearchResult(results));
  }

  private processSearchResult(result) {
    this.showPending = false;
    this.showLoadMore = result.hasMore;
    this.recipes = this.recipes.concat(result.recipes);
  }

  /**
   * @param type
   */
  filterMealType(type) {
    if (new Set(this.selectedMealTypes).has(type)) {
      this.selectedMealTypes.splice(this.selectedMealTypes.indexOf(type), 1);
    } else {
      this.selectedMealTypes.push(type);
    }
    this.search();
  }

  loadMore() {
    this.from = this.recipes.length;
    this.search(false);
  }

  ngOnDestroy(): void {
    this.plannerService.enableSlideOutPlanner.next(false);
    unsubscribe(this.subs);
  }

  /**
   * Opens the recipe modal
   *
   * @param {RecipeToOpen} recipeToOpen
   */
  private openRecipe(recipeToOpen: RecipeToOpen) {
    const modalRef = this.modalService.open(PlannerRecipeComponent, { windowClass: 'full-modal' });
    console.log(recipeToOpen);
    modalRef.componentInstance.recipe = recipeToOpen.recipe;
    modalRef.componentInstance.servings = recipeToOpen.servings;
  }

  toggleOwnRecipes() {
    this.onlyOwners = !this.onlyOwners;
    this.search();
  }

  toggleFavorites() {
    this.onlyFavorites = !this.onlyFavorites;
    this.search();
  }

  backToPlanner() {
    this.location.back();
  }

  // noinspection JSMethodCanBeStatic
  private macroHighDisplay(macroOption: MacroOption) {
    macroOption.highDisplay =
      macroOption.high === macroOption.ceil ? infinitySymbol : macroOption.high.toString();
  }

  onUserChangeEnd($event: ChangeContext, macroOption: MacroOption) {
    this.macroHighDisplay(macroOption);
    this.search(true);
  }

  onUserChange($event: ChangeContext, macroOption: MacroOption) {
    this.macroHighDisplay(macroOption);
  }
}
