<ng-container *ngIf="isNonCalendar">
  <select [(ngModel)]="currentWeek" (change)='changeWeek()' class='form-control'>
      <option *ngFor="let i of nonCalweeks" [value]="'1970.' + i.toString().padStart(2, '0')" [selected]='currentWeek === "1970." + i.toString().padStart(2, "0")'>Week {{ i }}</option>
  </select>
</ng-container>
<div id="daySelector" #daySelect style="width: 100%; overflow: auto; white-space: nowrap">
  <ng-container *ngIf="showPrevWeekButton && !loadingPreviousWeek"
    ><div
      (click)="loadPreviousWeek()"
      class="border-right border-bottom px-2 py-1 text-center text-muted"
      style="display: inline-block; max-width: 18%"
    >
      < <br />
      Week
    </div></ng-container
  ><ng-container *ngIf="loadingPreviousWeek">
    <div
      style="display: inline-block; max-width: 18%"
      class="border-right border-bottom px-1 py-1 text-center text-muted"
    >
      Loading<br /><i class="fa fa-spinner fa-spin text-muted"></i></div></ng-container
  ><ng-container *ngFor="let weeklyPlan of weeklyPlans$ | async"
    ><div
      *ngFor="
        let day of ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
      "
      (click)="setDay(weeklyPlan.weekNumber, day)"
      id="day-select-{{ weeklyPlan.weekNumber.replace('\.', '-') }}-{{ day }}"
      class="border-right border-bottom px-1 py-1 text-center"
      [class.bg-primary]="selectedWeekNumber == weeklyPlan.weekNumber && selectedDay === day"
      [class.text-white]="selectedWeekNumber == weeklyPlan.weekNumber && selectedDay === day"
      style="display: inline-block; width: 18%"
    >
      <div *ngIf="weeklyPlan.mealPlan.nonCalendar" class="text-muted">
        Wk {{ convertNonCalWeekNumber(weeklyPlan.weekNumber) }}<br />
      </div>
      {{ day.substr(0, 3).toUpperCase() }}

      <ng-container *ngIf="!weeklyPlan.mealPlan.nonCalendar">
        <br />{{ day | dayDisplay: weeklyPlan.weekNumber:'D' }}
      </ng-container>
    </div></ng-container
  >
</div>

<div *ngFor="let meal of meals" class="pb-3 border-bottom" id="meal-{{ meal.name.toLowerCase() }}">
  <div class="container-fluid">
    <div class="row align-items-center py-2 border-bottom">
      <div class="col-5">
        <h5 class="mb-0">{{ meal.name }}</h5>
      </div>
      <div class="col-7 text-muted text-right" style="font-size: 0.8em">
        {{ selectedDay | dayDisplay: selectedWeekNumber:'dddd, MMMM D' }}
      </div>
    </div>

    <div class="row">
      <ng-container *ngFor="let card of meal.cards">
        <div
          *ngIf="card.type === CardType.Recipe"
          (click)="openRecipeCard(card, selectedWeekNumber, selectedDay, meal)"
          class="col-6 recipe-card-mobile"
          [class.is-leftover]="card.isLeftover"
          style=""
        >
          <img
            [src]="card.recipe.image | cdn: 300:250:false"
            [alt]="card.recipe.title"
            style="max-width: 100%"
          />
          <div class="text-center p-2">
            <img
              *ngIf="card.isLeftover"
              src="/assets/img/icon/doggie-bag.svg"
              height="20"
              alt="Leftover"
            />

            {{ card.recipe.title }}
          </div>
        </div>

        <div *ngIf="card.type === CardType.Note" class="col-12 note-card-mobile" style="">
          <div class="text-center p-2" [innerHTML]="card.note.message"></div>
        </div>
      </ng-container>

      <div
        class="text-center add-recipe-button"
        [attr.data-numRcards]="countRecipeCards(meal)"
        [class.col-12]="evenNumberOfRecipeCards(meal)"
        [class.col-6]="oddNumberOfRecipeCards(meal)"
      >
        <a
          (click)="openSearch(selectedWeekNumber, selectedDay, meal.name)"
          class="btn btn-outline-secondary btn-block"
          style="border: 1px #aec17f dashed; color: #aec17f"
        >
          <br />
          <i class="fa fa-plus fa-2x"></i><br />
          Add recipe
          <br />
          <br />
        </a>
      </div>
    </div>
  </div>
</div>
