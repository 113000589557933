import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';
import { distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';
import { RecipesSearchResult } from '../../lprx-shared-lib/dto/recipes-search.result';
import { FavoriteRecipes } from '../../lprx-shared-lib/entities/favorite-recipes';
import { UserType } from '../../lprx-shared-lib/entities/user/UserType';
import { NutrientLimitsFilter } from '../../lprx-shared-lib/interface/nutrient-limits.filter';
import { RecipeSearchQueryParams } from '../../lprx-shared-lib/interface/recipe-search-query-params';
import { AdminService } from '../../providers/admin/admin.service';
import { ApiV2 } from '../../providers/api.v2';
import { ApiV3 } from '../../providers/api.v3';
import { Api } from '../../providers/aws.api';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { DistributorService } from '../distributor/distributor-service';
import { RecipeSearchResultInterface } from '../model/recipe-search-result.interface';
import { MacroOption } from '../planner/macro-options';
import { PlannerService } from '../planner/planner.service';
import { RecipeCacheService } from './recipe-cache.service';
import { PractitionerTag, PractitionerTags } from '../../lprx-shared-lib/recipe/practitioner-tags';
import { Result } from '../../lprx-shared-lib/result';
import { from } from 'rxjs/internal/observable/from';
import { asClass } from '../../lprx-shared-lib/as-class';

export function genNutrientLimitsFromMacroOptions(
  macroOptions: MacroOption[]
): NutrientLimitsFilter {
  if (!macroOptions) {
    return;
  }
  const nutrientLimits: NutrientLimitsFilter = {};
  for (const macro of macroOptions) {
    if (macro.low !== macro.floor || macro.high !== macro.ceil) {
      nutrientLimits[macro.key] = {
        low: macro.low,
        high: macro.high === macro.ceil ? 99999 : macro.high, // adjust for infinity
      };
    }
  }
  return nutrientLimits;
}

export interface SearchFuncParamsForClient {
  term?: string;
  from?: number;
  limit?: number;
  mealTypes?: string[];
  only_user_recipes?: boolean;
  only_favorites?: boolean;
  nutrient_limits?: NutrientLimitsFilter;
  clientId?: string;
}

interface SearchParams {
  userType: UserType;
  searchTerm: string;
  from: number;
  limit: number;
  mealTypes: any[];
  onlyUserRecipes?: boolean;
  onlyFavorites?: any;
  // nutrientLimits: NutrientLimitsFilter;
  clientUsername?: string; // Not present on searches by Practitioner within dashboard
  searchAllMealPlans: boolean;
  macroOptions?: MacroOption[];
}

@Injectable({ providedIn: 'root' })
export class RecipeService implements OnDestroy {
  private isFetchingFavorites = false;
  private _favoriteRecipes: FavoriteRecipes;
  private favoriteRecipesSubject = new BehaviorSubject<FavoriteRecipes>(null);
  readonly favoriteRecipes$ = this.favoriteRecipesSubject.asObservable();
  private clientId: string;
  private readonly refresherSub: Subscription;

  constructor(
    private api: Api,
    private apiV2: ApiV2,
    private apiV3: ApiV3,
    private lprxApi: LprxApiProvider,
    private recipeCache: RecipeCacheService,
    private adminService: AdminService,
    private distributorService: DistributorService,
    private plannerService: PlannerService
  ) {
    // this.refresherSub = interval(5000).subscribe(() => {
    //   this.favoriteRecipes(true);
    // });
  }

  ngOnDestroy(): void {
    if (this.refresherSub) {
      this.refresherSub.unsubscribe();
    }
  }

  /**
   * @param recipeId
   */
  getRecipe(recipeId: string) {
    if (this.recipeCache.has(recipeId)) {
      console.log('from cache');
      return of(this.recipeCache.get(recipeId));
    }

    return this.apiV2.get$(`recipes/${recipeId}`).pipe(
      map((recipeData) => {
        const recipe = Recipe.fromObject(recipeData);
        this.recipeCache.cache(recipe);
        return recipe;
      })
    );
  }

  /**
   * @param recipe
   * @param {string} version
   */
  save(recipe: Recipe, version = 'v2') {
    if (version === 'v2') {
      return this.apiV2.post$(`recipes/save`, recipe).pipe(map(Recipe.fromObject));
    } else {
      return this.apiV3.post(`recipes`, recipe).pipe(map(Recipe.fromObject));
    }
  }

  /**
   * Update a recipe
   *
   * @param id
   * @param recipe
   */
  update(id: string, recipe: Recipe) {
    return this.apiV3.post(`recipes/${id}`, recipe).pipe(map(Recipe.fromObject));
  }

  /**
   * Get the user's own recipes
   */
  getOwn(limit: number = 20, from: number = 0): Observable<RecipesSearchResult<Recipe>> {
    return this.apiV3.get(`recipes/own`, { limit, from }).pipe(
      map((recipesData: RecipesSearchResult<Recipe>) => {
        recipesData.recipes = recipesData.recipes ? recipesData.recipes.map(Recipe.fromObject) : [];
        return recipesData;
      })
    );
  }

  /**
   * @param tag
   */
  getOwnByTag(tag: string): Observable<Recipe[]> {
    return this.apiV3
      .get<Result<Recipe[]>>(`recipes/tag/${tag}`)
      .pipe(map((r) => r.data.map((r) => asClass(Recipe, r))));
  }

  /**
   * Copies a recipe to the user's own recipe box
   *
   * @param id
   */
  copy(id: string) {
    return this.apiV3.get('recipes/copy/' + id).pipe(map(Recipe.fromObject));
  }

  /**
   * @param {string} term
   * @param {number} from
   * @param {number} limit
   * @param {any[]} mealTypes
   * @param {boolean} only_user_recipes
   * @param only_favorites
   * @param nutrient_limits
   * @param username
   * @returns Observable<RecipeSearchResultInterface>
   */
  private _search({
    term = '',
    from = 0,
    limit = 30,
    mealTypes = ['Breakfast', 'Lunch', 'Dinner', 'Snack', 'Side'],
    only_user_recipes = false,
    only_favorites = false,
    nutrient_limits = {},
    clientId,
  }: SearchFuncParamsForClient): Observable<RecipeSearchResultInterface> {
    const queryParams: RecipeSearchQueryParams = {
      term,
      from,
      limit,
      meal_types: mealTypes,
      only_user_recipes,
      favorites: only_favorites,
      nutrient_limits: JSON.stringify(nutrient_limits),
    };

    if (this.clientId) {
      queryParams.client_id = this.clientId;
    }

    if (clientId) {
      queryParams.client_id = clientId;
    }

    return this.apiV3.get<RecipeSearchResultInterface>('recipes/search', queryParams).pipe(
      map((result) => {
        result.recipes = result.recipes.map((recipe) => Recipe.fromObject(recipe));
        return result as RecipeSearchResultInterface;
      })
    );
  }

  search({
    userType,
    searchTerm,
    from,
    limit,
    mealTypes,
    onlyUserRecipes,
    onlyFavorites,
    clientUsername,
    searchAllMealPlans,
    macroOptions,
  }: SearchParams) {
    const nutrientLimits = genNutrientLimitsFromMacroOptions(macroOptions);

    if (userType === UserType.Client) {
      return this.clientSearch(
        searchTerm,
        from,
        limit,
        mealTypes,
        onlyUserRecipes,
        onlyFavorites,
        nutrientLimits,
        clientUsername
      );
    }

    if (userType === UserType.Distributor) {
      return this.distributorSearch(
        clientUsername,
        searchTerm,
        from,
        limit,
        mealTypes,
        nutrientLimits,
        onlyFavorites
      );
    }

    if (userType === UserType.Admin) {
      return this.adminSearch(
        searchAllMealPlans,
        searchTerm,
        from,
        limit,
        mealTypes,
        nutrientLimits
      );
    }
  }

  private adminSearch(
    searchAllMealPlans: boolean,
    searchTerm: string,
    from: number,
    limit: number,
    mealTypes: any[],
    nutrientLimits?: NutrientLimitsFilter
  ) {
    let mealPlanId$: Observable<string>;

    if (searchAllMealPlans) {
      mealPlanId$ = of('---any---');
    } else {
      mealPlanId$ = this.plannerService.weeklyPlans$.pipe(
        filter((weeklyPlans) => weeklyPlans && weeklyPlans.length > 0),
        map((weeklyPlans) => weeklyPlans[0].mealPlanId),
        distinctUntilChanged()
      );
    }

    return mealPlanId$.pipe(
      switchMap((mealPlanId) =>
        this.adminService.recipes.search(
          mealPlanId,
          searchTerm,
          from,
          limit,
          mealTypes,
          nutrientLimits
        )
      )
    );
  }

  private distributorSearch(
    clientUsername: string,
    searchTerm: string,
    from: number,
    limit: number,
    mealTypes: any[],
    nutrientLimits?: NutrientLimitsFilter,
    onlyFavorites = false
  ) {
    if (clientUsername) {
      // return this.distributorService.recipes.clientSearch(
      //   clientUsername,
      //   searchTerm,
      //   from,
      //   limit,
      //   mealTypes,
      //   nutrientLimits
      // );
    } else {
      const mealPlanId2$ = this.plannerService.weeklyPlans$.pipe(
        filter((weeklyPlans) => weeklyPlans && weeklyPlans.length > 0),
        map((weeklyPlans) => weeklyPlans[0].mealPlanId),
        distinctUntilChanged()
      );
      return mealPlanId2$.pipe(
        switchMap((mealPlanId) => {
          return this.lprxApi.recipes.search({
            mealPlanId: mealPlanId,
            term: searchTerm,
            from: from,
            limit: limit,
            mealTypes: mealTypes,
            includeDrafts: false,
            sortBy: undefined,
            sortOrder: 'asc',
            nutrientLimits: nutrientLimits,
            onlyFavorites: onlyFavorites,
          });
        })
      );
    }
  }

  private clientSearch(
    searchTerm: string,
    from: number,
    limit: number,
    mealTypes: any[],
    onlyUserRecipes: boolean,
    onlyFavorites,
    nutrientLimits?: NutrientLimitsFilter,
    clientId?: string
  ) {
    const searchParams: SearchFuncParamsForClient = {
      term: searchTerm,
      from,
      limit,
      mealTypes,
      only_user_recipes: onlyUserRecipes,
      only_favorites: onlyFavorites,
      nutrient_limits: nutrientLimits,
    };

    if (clientId) {
      searchParams.clientId = clientId;
    }

    return this._search(searchParams);
  }

  /**
   *
   * @param input
   * @private
   */
  private getFavoriteRecipesObservable(input: Promise<FavoriteRecipes>) {
    return fromPromise(input).pipe(
      tap((favoriteRecipes: FavoriteRecipes) => {
        this.isFetchingFavorites = false;
        this.favoriteRecipesSubject.next(favoriteRecipes);
        this._favoriteRecipes = favoriteRecipes;
      })
    );
  }

  favoriteRecipes() {
    if (this._favoriteRecipes) {
      return of(this._favoriteRecipes);
    }
    if (!this.isFetchingFavorites) {
      this.isFetchingFavorites = true;
      const action = this.lprxApi.recipes.favorites(this.clientId);
      return this.getFavoriteRecipesObservable(action);
    }
    return of(null);
  }

  /**
   *
   * @param recipeId
   */
  unlike(recipeId: string) {
    this.protectClientFavorites();
    const action = this.lprxApi.recipes.like(recipeId, this.clientId);
    return this.getFavoriteRecipesObservable(action);
  }

  private protectClientFavorites() {
    // disabling for now
    // if (this.clientId) {
    //   throw new Error("You cannot modify a client's favorites.");
    // }
  }

  /**
   *
   * @param recipeId
   */
  like(recipeId: string) {
    this.protectClientFavorites();

    const action = this.lprxApi.recipes.unlike(recipeId, this.clientId);
    return this.getFavoriteRecipesObservable(action);
  }

  setClient(clientId: string | null) {
    if (clientId) {
      this.clientId = clientId;
      this._favoriteRecipes = null;
    }
  }

  clearClient() {
    this.clientId = null;
    this._favoriteRecipes = null;
  }

  /**
   *
   * @param {string} recipeId
   */
  delete(recipeId: string) {
    return of(this.lprxApi.recipes.delete(recipeId));
  }

  deleteTag(tag: string): Observable<Result<PractitionerTags>> {
    return from(this.lprxApi.recipes.deleteTag(tag));
  }

  // get tags
  getTags(): Observable<Result<PractitionerTags>> {
    return from(this.lprxApi.recipes.getTags());
  }

  addTag(newTag: string) {
    return from(this.lprxApi.recipes.addTag(newTag));
  }

  tagRecipe(tag: PractitionerTag, recipe: Recipe) {
    return from(this.lprxApi.recipes.tagRecipe(tag, recipe));
  }

  untagRecipe(tag: PractitionerTag, recipe: Recipe) {
    return from(this.lprxApi.recipes.untagRecipe(tag, recipe));
  }

  updateTag(tag: PractitionerTag) {
    return from(this.lprxApi.recipes.updateTag(tag));

  }
}
