import {
  BehaviorSubject,
  from,
  from as fromPromise,
  from as observableFrom,
  Observable,
  of as observableOf,
} from 'rxjs';

import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { map, tap } from 'rxjs/operators';
import { Bundle } from '../../lprx-shared-lib/entities/bundle/bundle';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { MealPlanCategories } from '../../lprx-shared-lib/entities/meal-plan/meal-plan-categories';
import { User } from '../../lprx-shared-lib/entities/user/user';
import { PlannerType } from '../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { ApiV2 } from '../../providers/api.v2';
import { Api } from '../../providers/aws.api';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { AuthService } from '../auth.service';
import { DistributorMealPlan } from '../../lprx-shared-lib/DistributorMealPlan';

import { PayoutAccount } from '../../lprx-shared-lib/entities/payout-account';
import { UserFactory } from '../model/entities/user';

import { DistributorAccountService } from '../service/distributor/distributor-account.service';
import { DistributorBundlesService } from '../service/distributor/distributor-bundles.service';
import { DistributorClientPlannerService } from '../service/distributor/distributor-client-planner.service';
import { DistributorClientsService } from '../service/distributor/distributor-clients.service';
import { DistributorMealPlansService } from '../service/distributor/distributor-meal-plans.service';
import { DistributorRecipesService } from '../service/distributor/distributor-recipes.service';
import { BundleClient } from '../../lprx-shared-lib/entities/bundle-client';
import { PayoutAccountStatus } from '../../lprx-shared-lib/enum/payout-account-status';
import { PurchaseExtensionResult } from '../../lprx-shared-lib/dto/purchase-extension-result';

/**
 * @deprecated Migrate to DistributorsService. Note: Not all methods are migrated yet
 */
@Injectable()
export class DistributorService {
  distributorMealPlans: DistributorMealPlan[];
  private _bundles: Bundle[];

  private isInitializedSubject = new BehaviorSubject<boolean>(false);

  private mealPlansSubject = new BehaviorSubject<DistributorMealPlan[]>(null);
  // private availableMealPlans = new BehaviorSubject<MealPlan[]>(null);

  private distributorId: string;

  private areMealPlansLoaded = false;
  private distributor: Distributor;

  constructor(
    private api: Api,
    private apiV2: ApiV2,
    private authService: AuthService,
    public account: DistributorAccountService,
    public clientPlanner: DistributorClientPlannerService,
    public clients: DistributorClientsService,
    public mealPlans: DistributorMealPlansService,
    public recipes: DistributorRecipesService,
    public bundles: DistributorBundlesService,
    private lprxApi: LprxApiProvider
  ) {
    // todo: remove init on initialization for class... lazy init or rework the service.
    this.init();
  }

  init() {
    console.log('DistributorService::Init()');
    try {
      this.distributorId = this.getDistributorId();
      this._initMealPlans();
    } catch (e) {
      // ignore for now
    }
  }

  getIsInitialized(): Observable<boolean> {
    console.log('DistributorService::getIsInitialized()');
    return this.isInitializedSubject.asObservable();
  }

  // async getMealPlans() {
  //     if (!this.distributorMealPlans) {
  //         await this._initMealPlans();
  //     }
  //     return this.distributorMealPlans;
  // }

  getMealPlans(): Observable<DistributorMealPlan[]> {
    console.log(`DistributorService::getMealPlans()`);
    return this.mealPlansSubject.asObservable();
  }

  getMealPlanName(mealPlanId: string) {
    console.log(`DistributorService::getMealPlanName(${mealPlanId})`);
    for (const m of this.distributorMealPlans) {
      if (m.mealPlanId === mealPlanId) {
        return m.name;
      }
    }
    return '';
  }

  async getBundles(refresh = false) {
    console.log(`DistributorService::getBundles()`);
    if (!this._bundles || refresh) {
      this._bundles = await this.apiV2.get('distributor/bundles');
    }
    return this._bundles;
  }

  async getBundle(bundleId: string): Promise<Bundle> {
    console.log(`DistributorService::getBundle(${bundleId})`);
    const bundles = await this.getBundles();
    for (const bundle of bundles) {
      if (bundleId === bundle.bundleId) {
        return bundle;
      }
    }
    return new Bundle();
  }

  async saveBundle(bundle: Bundle) {
    console.log(`DistributorService::saveBundle()`);
    try {
      const res = await this.api.post('distributor/bundle', bundle);
      if (res) {
        for (const b of this._bundles) {
          if (bundle.bundleId === b.bundleId) {
            this._bundles.splice(this._bundles.indexOf(b), 1);
          }
        }
        this._bundles.push(bundle);
      }
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * @returns {Observable<User[]>}
   */
  getClients(): Observable<User[]> {
    console.log(`DistributorService::getClients()`);
    return fromPromise(this.api.get(`distributor/clients/${this.getDistributorId()}`)).pipe(
      map((res: User[]) => res.map((item) => User.fromObject(item) as User))
    );
  }

  /**
   * Get the distributor id.
   *
   * @returns {string}
   */
  getDistributorId() {
    console.log(`DistributorService::getDistributorId()`);
    return this.authService._getUser().distributorId;
  }

  /**
   *
   * @returns {Promise<Distributor>}
   */
  async getDistributor(refresh = false) {
    console.log(`DistributorService::getDistributor()`);
    if (!this.distributor || refresh) {
      const distributorObject = (await this.apiV2.get(`distributor`)) as Distributor;
      this.distributor = plainToClass(Distributor, distributorObject) as Distributor;
    }
    return this.distributor;
  }

  private lastDistFetch = 0;

  /**
   * Get the current logged in Distributor
   */
  get() {
    const now = Date.now();
    const timeDiff = now - this.lastDistFetch;
    if (this.distributor && timeDiff < 1500) {
      return observableOf(plainToClass(Distributor, this.distributor));
    } else {
      return this.apiV2.get$(`distributor`).pipe(
        map((distributorObject: Distributor) => plainToClass(Distributor, distributorObject)),
        tap((distributor: Distributor) => {
          console.log(`Retrieved Distributor(${distributor.id})`);
          this.distributor = distributor;
          this.lastDistFetch = Date.now();
        })
      );
    }
  }

  /**
   *
   * @param code
   * @param state
   * @returns {Promise<boolean>}
   */
  async processAuthorization(code: any, state: any) {
    const data = {
      distributorId: this.getDistributorId(),
      code,
      state,
    };
    const res = await this.api.post('distributor/connect/authorize', data);
    return true;
  }

  /**
   *
   * @returns {Promise<PayoutAccount>}
   */
  async getPayoutAccount(): Promise<PayoutAccount> {
    return this.api.get(`distributor/connect/client-id/${this.getDistributorId()}`);
  }

  async canAccessMealPlans(): Promise<boolean> {
    await this.getDistributor(true);
    if (!this.distributor.canReceivePayouts) {
      return true;
    }
    const payoutAccount = await this.getPayoutAccount();
    return payoutAccount.status === PayoutAccountStatus.COMPLETE;
  }

  /**
   *
   */
  async getStripeLoginUrl() {
    return this.lprxApi.get(`v3/account/stripe-dashboard`);
  }

  _initMealPlans() {
    console.log(`DistributorService::_initMealPlans()`);
    fromPromise(this.apiV2.get(`distributor/meal-plans/`)).subscribe((distributorMealPlans) => {
      this.distributorMealPlans = distributorMealPlans.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        } else if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      });
      this.mealPlansSubject.next(this.distributorMealPlans);
      this.areMealPlansLoaded = true;
      this.isInitializedSubject.next(true);
    });
  }

  /**
   * @param {string} username
   * @returns {User}
   */
  async getClient(username: string): Promise<User> {
    console.log(`DistributorService.getClient(${username})`);
    return this.lprxApi.get(`v3/clients/${username}`);
  }

  /**
   * @param {string} username
   * @returns {Observable<User>}
   */
  getClient$(username: string) {
    return observableFrom(this.getClient(username)).pipe(
      map((userData: User) => UserFactory.fromObject(userData))
    );
  }

  /**
   * @param {string} bundleId
   * @returns {any}
   */
  getBundleClients(bundleId: string) {
    return fromPromise(this.api.get(`distributor/bundle/${bundleId}/clients`));
  }

  /**
   * @param {BundleClient} bundleClient
   * @returns {any}
   */
  sendBundleClientActivationEmail(bundleClient: BundleClient) {
    return observableFrom(
      this.api.get(`distributor/bundle-client/${bundleClient.id}/send-activation-email`)
    );
  }

  /**
   * @param {User} client
   * @param {string} sourceId
   * @param {number} expirationTime
   * @param {string} plannerType
   * @param {number} applyCredits
   * @returns Observable
   */
  purchaseExtension(
    client: User,
    sourceId: string,
    expirationTime: number,
    plannerType: PlannerType,
    applyCredits: number = 0
  ): Observable<PurchaseExtensionResult> {
    return observableFrom(
      this.lprxApi.bundles.extend(
        client.username,
        sourceId,
        expirationTime,
        plannerType,
        applyCredits
      )
    );
  }

  saveMasterPlan(wp: WeeklyPlan) {
    return fromPromise(this.lprxApi.masterPlans.save(wp));
  }

  mealPlanCategories(): Observable<MealPlanCategories> {
    return from(this.lprxApi.mealPlans.categories());
  }

  disablePayments() {
    return fromPromise(this.lprxApi.delete('v3/distributor-config/connect/payments'));
  }
}
