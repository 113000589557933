/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/planner/ingredient.preferences.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class IngredientPreferences {
  dislikes: string[] = [];
  likes: string[] = [];
}
