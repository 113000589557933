import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SweetAlertOptions } from 'sweetalert2';
import { alertModal } from './sweet-alert/sweet-alert';

/**
 * Find the damn error messages
 *
 * @param err
 */
export function errorMessage(err: any) {
  let message = '';
  if (err.data && err.data.message) {
    message = err.data.message;
  } else if (err.response && err.response.data && err.response.data.message) {
    message = err.response.data.message;
  } else if (err.error && err.error.message) {
    message = err.error.message;
  } else {
    message = err.message;
  }
  return message;
}

export function alertHttpError() {
  const selector = (err: any, caught: Observable<any>) => {
    // const message = err.error && err.error.message ? err.error.message : err.message;
    const options: SweetAlertOptions = {
      title: 'Error',
      icon: 'error',
      text: errorMessage(err),
      showCancelButton: true,
      cancelButtonText: 'Close',
      showConfirmButton: false
    };
    alertModal(options);
    throw err;
  };
  // noinspection TypeScriptValidateTypes
  return catchError(selector);
}
