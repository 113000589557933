<mat-sidenav-container (scroll)="onSideNavScroll($event)" [class]="'window-size-' + windowSize">
  <mat-sidenav
    #sidenav
    mode="push"
    position="end"
    [fixedInViewport]="true"
    (closed)="sideNavClosed()"
  >
    <mat-nav-list>
      <!-- When a client is logged in -->
      <ng-container *ngIf="isLoggedIn && user?.userType == 'user'">
        <a mat-list-item class="text-white" routerLink="/planner">My Planner</a>
        <a mat-list-item class="text-white" routerLink="/planner/search">Search Recipes</a>
        <a mat-list-item class="text-white" routerLink="/planner/grocery-list">Grocery List</a>
        <a mat-list-item class="text-white" routerLink="/account">My Account</a>
      </ng-container>

      <hr />

      <a
        *ngIf="!isLoggedIn"
        mat-list-item
        class="text-white"
        routerLink="{{ brand?.baseUrl }}/login"
        >Log In</a
      >
      <a *ngIf="isLoggedIn" mat-list-item class="text-white" routerLink="/logout">Log Out</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-header *ngIf='headerVisible'></app-header>

    <div id="content-wrapper" [style.padding-top]="headerVisible ? headerHeight : 0">
      <router-outlet></router-outlet>
    </div>

    <footer class="footer bg-light d-print-none">
      <div class="container text-muted text-center">
        &copy; 2018-{{ currentYear }} Living Plate Rx
        <br />
        <a routerLink="/terms">Terms of Use</a>
        | <a routerLink="/privacy">Privacy Policy</a> <br /><span style="font-size: 10px"
          >ver. {{ versionNumber }}</span
        >
      </div>
    </footer>
  </mat-sidenav-content>
</mat-sidenav-container>
