/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/distributor-type.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { ArgumentException } from '../exceptions/argument.exception';

export enum DistributorType {
  HealthCareProfessional = 'healthcare',
  WellnessProfessional = 'wellness',
}

export class DistributorTypes {
  public static readonly types: Array<{ name: string; value: DistributorType }> = [
    { name: 'Health Care Professional', value: DistributorType.HealthCareProfessional },
    { name: 'Wellness Professional', value: DistributorType.WellnessProfessional },
  ];

  static find(
    distributorType: DistributorType
  ): { name: string; value: DistributorType } | undefined {
    return DistributorTypes.types.find((d) => d.value === distributorType);
  }

  static isValid(distributorType: DistributorType) {
    return !!DistributorTypes.find(distributorType);
  }

  static nameOf(distributorType: DistributorType) {
    const type = DistributorTypes.find(distributorType);
    return type ? type.name : '';
  }

  static fromName(typeName: string) {
    switch (typeName) {
      case 'wellness':
        return DistributorType.WellnessProfessional;
      case 'healthcare':
        return DistributorType.HealthCareProfessional;
      default:
        throw new ArgumentException(typeName + ' was not recognized.');
    }
  }
}
