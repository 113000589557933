/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/nutrient-value.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { NutrientValue } from './entities/recipe/IngredientNutrientValue';

export function nutrientValue(
  nutrient: NutrientValue,
  decimalPlaces: number = 0,
  showUnit: boolean = true
) {
  if (nutrient && nutrient.value >= 0) {
    return nutrient.value.toFixed(decimalPlaces) + (showUnit ? nutrient.unit : '');
  }
  return 'N/A';
}
