import { WeeklyPlan } from '../../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { MealPlan } from '../../../../lprx-shared-lib/entities/meal-plan/MealPlan';

export class WeeklyPlanFactory {
  static createMasterWeeklyPlan(mealPlan: MealPlan, weekNumber: string): WeeklyPlan {
    const weeklyPlan = new WeeklyPlan();
    weeklyPlan.init('master', mealPlan, weekNumber);
    return weeklyPlan;
  }
}
