/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/format-ingredient.ts
 *  --------------------------
 *  - Swagger items commented out
 */

// @ts-ignore -- mathjs types (@types/mathjs) are in package.json ... don't know inspection is complaining
import { create, formatDependencies, fractionDependencies, numberDependencies } from 'mathjs';

import { Ingredient } from './entities/recipe/Ingredient';
import { UnitDescription, UnitSystem } from './enum/unit-system';
import { calcQuantityBasedOnPreferredServings } from './utils/calc-quantity-based-on-preferred-servings';
import { roundFractionToRecipeMeasurement } from './utils/round-fraction-to-recipe-measurement';
import { UnitConverter } from './utils/unit-converter';
import { convertUnits } from './convert-units';

// @ts-ignore
const { fraction, format, number } = create(
  {
    fractionDependencies,
    formatDependencies,
    numberDependencies,
  },
  {}
);

export function ingredientFormatter(
  ingredient: Ingredient,
  unitSystem: UnitSystem | null,
  roundUp: boolean,
  userServings: number,
  serves: number,
  fractionSpacing: string,
  metricUnitSpace: string,
  itemNameFirst: boolean
) {
  // if (!unitSystem) {
  // determine the unit system based on ingredient.unit
  const u = UnitConverter.findUnitOrNull(ingredient.unit);
  if (!unitSystem && u) {
    unitSystem = u.system as UnitSystem;
  }
  // }

  let output = '';

  let quantity = ingredient.quantity === null ? 0 : ingredient.quantity;
  let unit: convertUnits.Unit | string = (ingredient.unit || '') as convertUnits.Unit;
  const itemName = ingredient.item;

  const technique = ingredient.technique || '';
  const equivalency = ingredient.equivalency || '';

  if (roundUp) {
    quantity = Math.ceil(quantity);
  }

  // if the unit a substring of the item name, then remove it
  // in some cases like 2 carrots, the unit is carrot, but the food name is carrots,
  // which makes strange when displayed
  if (!u && itemName.toLowerCase().indexOf(unit.toLowerCase()) > -1) {
    unit = '';
  }

  const note = ingredient.note || '';

  if (quantity > 0) {
    quantity = calcQuantityBasedOnPreferredServings(userServings, serves, quantity);

    // if (unit === null) {
    //   unit = '';
    // }

    // @ts-ignore -- type juggling unit is really a string
    if (unit !== '') {
      // unit = UnitConverter.findUnitAbbrvOrDefault(unit);
      let unitDescription: UnitDescription;
      let convertTo: any;
      try {
        unitDescription = UnitConverter.findUnit(unit);

        if (unitDescription.system !== unitSystem) {
          const possibilities = convertUnits().from(unitDescription.abbr).possibilities();
          for (const possibility of possibilities) {
            const _u = convertUnits().describe(possibility);
            if (_u.system === unitSystem) {
              convertTo = _u.abbr;
              break;
            }
          }
        }
      } catch (e) {
        // ignore
      }

      try {
        const excludedUnits = [
          'fl-oz',
          'in3',
          'gal',
          'krm',
          'tsk',
          'msk',
          'kkp',
          'glas',
          'kanna',
          'cm3',
          'mm3',
          'dl',
          'cl',
        ];

        let cutOffNumber = 1;

        if (unit.match(/cup/i)) {
          if (quantity < 1) {
            cutOffNumber = 0.25;
            excludedUnits.push('pnt');
          } else if (quantity < 12) {
            excludedUnits.push('pnt');
            excludedUnits.push('qt');
          }
        }

        const uuuu = UnitConverter.findUnitOrNull(unit);
        let from = convertUnits(quantity).from(uuuu?.abbr as convertUnits.Unit);

        if (convertTo) {
          const convertedValue = from.to(convertTo);

          from = convertUnits(convertedValue).from(convertTo);
        }

        const uuu = from.toBest({
          exclude: excludedUnits as convertUnits.Unit[],
          cutOffNumber,
        });
        quantity = uuu.val;
        unit = uuu.unit as convertUnits.Unit;
      } catch (e) {}
    }

    const _unit = UnitConverter.findUnitOrNull(unit);
    if (_unit) {
      const unitStr = quantity > 1 ? _unit.plural : _unit.singular;
      unit = unitStr.length < 5 ? unitStr.toLowerCase() : _unit.abbr;
    }

    if (!unitSystem || unitSystem === UnitSystem.Imperial) {
      // quantity = Math.round(quantity * 24) / 24;
      let numberString = quantity.toString();
      if (!numberString.match(/\./)) {
        numberString += '.0';
      }

      let whole: string;
      let decimal: string;
      [whole, decimal] = numberString.split('.');

      if (parseInt(whole, 10) === 0) {
        whole = '';
      }

      let spacing = '';
      let _fraction = '';
      const decimalString = '0.' + decimal;
      if (parseFloat(decimalString) > 0) {
        const f = fraction(number(decimalString));
        _fraction = roundFractionToRecipeMeasurement(format(f, { fraction: 'ratio' }));
        _fraction += '';

        if (whole !== '') {
          spacing = fractionSpacing;
        }
      }
      output += whole + spacing + _fraction + ' ' + unit;
    } else {
      output += Math.round(quantity * 4) / 4 + ' ' + (unit ? unit + metricUnitSpace : '');
    }
  }

  if (itemNameFirst) {
    output = itemName + ', ' + output;
  } else {
    output += ' ' + itemName;
  }

  if (technique && technique !== '') {
    output += `, <em>${technique}</em>`;
  }

  if (equivalency && equivalency !== '') {
    output += ` (${equivalency})`;
  }

  if (note && note !== '') {
    output += ` ${note}`;
  }

  return output.trim();
}

export function formatIngredient(
  ingredient: Ingredient,
  serves: number = 1,
  userServings = 0,
  itemNameFirst = false,
  unitSystem: UnitSystem | null = null,
  roundUp = false
) {
  const fractionSpacing = ' ';
  const metricUnitSpace = ' ';
  return ingredientFormatter(
    ingredient,
    unitSystem,
    roundUp,
    userServings,
    serves,
    fractionSpacing,
    metricUnitSpace,
    itemNameFirst
  );
}
