<h6 class="p-2 bg-light"><strong>Ingredients</strong></h6>

<div *ngIf="editSimple || !ingredients || ingredients.length === 0" class="p-3">
  <p>Enter one ingredient per line.</p>
  <textarea
    [(ngModel)]="simpleInput"
    class="form-control mb-3"
    [placeholder]="placeHolderForSimple"
    [disabled]="processingConvertIngredients"
    rows="10"
  ></textarea>

  <ng-container *ngIf="ingredientErrors?.length > 0">
    <ul class="pt-2">
      <li *ngFor="let error of ingredientErrors" class="text-danger">
        <ng-container *ngIf="error.err_code === 100">
          There were multiple foods found for the ingredient. Please check for redundancy (e.g.:
          'fusilli pasta', 'tomato, small plum)
          <strong>{{error.original_text}}</strong>.
        </ng-container>
        <ng-container *ngIf="error.err_code !== 100"> {{error.warning}} </ng-container>
        <ng-container *ngIf="error.err_code !== 100"> {{error.warning}} </ng-container>
      </li>
    </ul>
  </ng-container>

  <div class="row">
    <div class="col">
      <label>
        &nbsp;
        <input type="checkbox" [(ngModel)]="useRaw" />
        Use raw ingredients
      </label>
    </div>
    <div class="col-auto ml-auto">
      <app-loading
        *ngIf="processingConvertIngredients"
        [style]='"xs-term"'
        class="mr-2"
        term="Processing"
      ></app-loading>

      <button
        type="button"
        (click)="convertIngredients()"
        [disabled]="processingConvertIngredients"
        appButton
      >
        Continue
      </button>
    </div>
  </div>
</div>

<ng-container *ngIf="ingredients && ingredients.length > 0 && !processingConvertIngredients">
  <div [hidden]="editSimple || processingConvertIngredients">
    <div *ngIf="ingredients?.length > 0">
      <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div cdkDrag *ngFor="let ingredient of ingredients" class="py-1 px-3 mb-2 bg-light">
          <!-- simple edit entry input text field -->
          <ng-container *ngIf="!showIngredientAdvanced.has(ingredient)">
            <div [hidden]="!editIngredient.has(ingredient)">
              <app-ingredient-simple-input
                [ingredient]="ingredient"
                (ingredientChange)="ingredientChanged($event, ingredient)"
              ></app-ingredient-simple-input>
            </div>
            <div
              class="row no-gutters align-items-center"
              [hidden]="editIngredient.has(ingredient)"
            >
              <div class="col-auto pr-2">
                <i
                  class="fa fa-fw fa-pencil-square-o fa-lg text-primary"
                  (click)="showEdit(ingredient)"
                  title="Edit"
                ></i>
              </div>
              <div
                class="col drag-handle"
                cdkDragHandle
                [innerHTML]="ingredient | ingredientDisplay"
              ></div>

              <div class="col-4 drag-handle" cdkDragHandle>
                {{mapCategory(ingredient.groceryCategory)}}
              </div>

              <div class="col-auto ml-auto">
                &nbsp;
                <button
                  type="button"
                  mat-icon-button
                  (click)="toggleAdvanced(ingredient)"
                  title="Open Advanced"
                >
                  <mat-icon>expand_more</mat-icon>
                </button>
                <button
                  type="button"
                  mat-icon-button
                  (click)="deleteIngredient(ingredient)"
                  title="Remove"
                >
                  <mat-icon [color]="'warn'">close</mat-icon>
                </button>
              </div>
            </div>
          </ng-container>
          <div *ngIf="showIngredientAdvanced.has(ingredient)" class="row align-items-center">
            <div class="col" [innerHTML]="ingredient | ingredientDisplay"></div>
            <div class="col-auto">
              <button type="button" btnSecondary (click)="toggleAdvanced(ingredient)">
                Close
              </button>
            </div>
          </div>
          <div *ngIf="showIngredientAdvanced.has(ingredient)">
            <!-- hidden drag handle to prevent drag on full row... could use api or whatever instead but this is quick and easy  -->
            <span cdkDragHandle></span>
            <br />
            <app-recipe-ingredient-input [ingredient]="ingredient"></app-recipe-ingredient-input>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="p-3">
    <div class="row align-items-center">
      <div class="col-auto"><strong>Add Ingredient:</strong></div>
      <div class="col">
        <input
          type="text"
          [(ngModel)]="newSimpleIngredient"
          class="form-control"
          (keydown)="handleReturnKey($event)"
          placeholder="'1 tablespoon olive oil' or '1/2 cup chopped onion'"
        />
      </div>
      <div class="col-auto">
        <label class="mb-0"
          ><input type="checkbox" [(ngModel)]="useRaw" /> Use raw ingredient</label
        >
      </div>
      <div class="col-auto">
        <button type="button" btnSecondary (click)="addIngredientSimple()">Add</button>
      </div>
    </div>
    <div class="text-danger" *ngIf="addIngredientError">{{addIngredientError}}</div>
  </div>
</ng-container>

<app-loading
  *ngIf="processingConvertIngredients"
  [style]='"sm"'
  class=""
  term="Processing ingredients..."
></app-loading>
