import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { from, of, Subscription } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { AuthService } from '../../auth.service';
import { ClientAccountBillingService } from '../../service/client/client-account-billing.service';
import { VarService } from '../../service/var.service';
import {
  StripeCardElement,
  StripeCardElementOptions,
  StripeElements,
  StripeElementsOptions,
  TokenResult,
} from '@stripe/stripe-js';
import { StripeService } from 'ngx-stripe';

declare var window: Window;

@Component({
  selector: 'app-update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.scss'],
})
export class UpdatePaymentMethodComponent implements OnInit, OnDestroy {
  client: User;

  private subs: Subscription[] = [];

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '24px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elements: StripeElements;

  error: { message?: string };
  errors: string[] = [];

  cardHolderName: string;

  elementsOptions: StripeElementsOptions = {
    // locale: 'es'
  };

  card: StripeCardElement;
  purchaseError: any;
  nameOnCard: any;
  isProcessing: boolean;

  constructor(
    private readonly auth: AuthService,
    private readonly stripeService: StripeService,
    private clientBilling: ClientAccountBillingService,
    private varService: VarService,
    private readonly toastr: ToastrService,
    private readonly router: Router,
    private readonly location: Location,
    private readonly lprxApi: LprxApiProvider
  ) {}

  ngOnInit() {
    this.initStripe(environment.stripe_public_key);

    this.subs.push(
      this.auth.user$.subscribe((user) => {
        if (user.isClient) {
          return (this.client = user);
        } else {
          from(this.lprxApi.get('v3/account/customer-portal')).subscribe((res) => {
            window.location.href = res.redirectUrl;
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private initStripe(key) {
    console.log('Changing Key!');
    this.stripeService.changeKey(key);

    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create('card', this.cardOptions);
        this.card.mount('#card-element');
      }
    });
  }

  submit($event: any) {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;

    // for observables
    const onCreatedToken = (tokenResult: TokenResult) => {
      if (tokenResult.error) {
        throw new Error(tokenResult.error.message);
      }
      return this.clientBilling.updatePaymentMethod(tokenResult.token);
    };

    const onUpdatedPaymentMethod = (result) => {
      this.toastr.success('Payment method updated!');
      this.router.navigateByUrl('/account');
    };

    const onError = (err) => {
      this.error = err;
    };

    this.stripeService
      .createToken(this.card, { name: this.cardHolderName })
      .pipe(
        finalize(() => {
          this.isProcessing = false;
        }),
        switchMap(onCreatedToken)
      )
      .subscribe(onUpdatedPaymentMethod, onError);
  }

  cancel() {
    this.location.back();
  }
}
