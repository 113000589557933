import { Injectable } from '@angular/core';

import { AdminPlannerService } from '../../app/service/admin/admin-planner.service';
import { AdminDistributorsService } from './admin-distributors.service';
import { AdminMealPlansService } from './admin-meal-plans.service';
import { AdminRecipesService } from './admin-recipes.service';

@Injectable()
export class AdminService {
  constructor(
    public distributors: AdminDistributorsService,
    public mealPlans: AdminMealPlansService,
    public planner: AdminPlannerService,
    public recipes: AdminRecipesService
  ) {}
}
