/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/planner/planner-search-configuration.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class PlannerSearchConfiguration {
  meals = true;
}
