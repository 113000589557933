import { Injectable } from '@angular/core';
import { ApiV2 } from '../../providers/api.v2';
import { NewIngredient } from '../recipe/recipe-editor/inputs/recipe-ingredient-input/new-ingredient';
import { alertHttpError } from '../utilities/alert-http-error';
import { Ingredient } from '../../lprx-shared-lib/entities/recipe/Ingredient';

@Injectable()
export class NutrientService {
  constructor(private apiV2: ApiV2) {}

  /**
   * @param {Ingredient} ingredient
   * @param includeNdbNo
   * @returns {Observable<*>}
   */
  matchIngredient(ingredient: Ingredient, includeNdbNo?: string) {
    return this.apiV2.post$('utility/nutrients/ingredient', { ingredient, includeNdbNo });
  }

  /**
   * @param ingredient
   */
  createNewIngredient(ingredient: NewIngredient) {
    return this.apiV2.post$('admin/ingredients/new', { ingredient }).pipe(alertHttpError());
  }
}
