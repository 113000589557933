import { NewIngredientNutrient } from './new-ingredient-nutrient';

export class NewIngredient {
  name = '';
  servingSize = 0;
  servingSizeMeasurement = '';
  servingSizeInGrams = 0;
  calories = 0;
  totalFat = new NewIngredientNutrient();
  saturatedFat = new NewIngredientNutrient();
  transFat = new NewIngredientNutrient();
  cholesterol = new NewIngredientNutrient();
  sodium = new NewIngredientNutrient();
  totalCarbohydrates = new NewIngredientNutrient();
  dietaryFiber = new NewIngredientNutrient();
  totalSugars = new NewIngredientNutrient();
  protein = new NewIngredientNutrient();
  vitaminD = new NewIngredientNutrient();
  calcium = new NewIngredientNutrient();
  iron = new NewIngredientNutrient();
  potassium = new NewIngredientNutrient();
}
