/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/note.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { Default } from '../../utils/default.decorator';
import { createId } from '../../utils/id-generator';
import { BaseItem } from '../base-item';

@Exclude()
export class Note implements BaseItem {
  @Expose()
  @Default(() => createId('note'))
  id = createId('note');

  @Expose()
  message = '';

  @Expose()
  addedBy: { username: string; name: string };

  @Expose()
  lastUpdatedBy: { username: string; name: string };

  @Expose()
  @Default(false)
  isPrivate = false;

  @Expose()
  ownedBy: string;

  @Expose()
  @Default(Date.now())
  createdAt: number = Date.now();

  @Expose()
  @Default(false)
  isDeleted = false;

  @Expose()
  @Default(Date.now())
  modifiedAt: number = Date.now();

  @Expose()
  clientId: string;

  @Expose()
  weekNumber: string;

  @Expose()
  dayName: string;

  @Expose()
  mealName: string;
}
