import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { UnitSystemService } from './unit-system-service';
import { UnitSystemToggleComponent } from './unit-system-toggle.component';

@NgModule({
  imports: [CommonModule],
  exports: [UnitSystemToggleComponent],
  declarations: [UnitSystemToggleComponent]
})
export class UnitSystemToggleModule {
  static forRoot(): ModuleWithProviders<UnitSystemToggleModule> {
    return {
      ngModule: UnitSystemToggleModule,
      providers: [UnitSystemService]
    };
  }
}
