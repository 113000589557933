import { Pipe, PipeTransform } from '@angular/core';
import { PlannerType } from '../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';

@Pipe({
  name: 'plannerType'
})
export class PlannerTypePipe implements PipeTransform {
  transform(plannerType: PlannerType, args?: any): any {
    switch (plannerType) {
      case PlannerType.Static:
        return 'Static';
      case PlannerType.Custom:
        return 'Custom';
    }
  }
}
