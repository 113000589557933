let _isTablet: null | boolean = null;

// let hasMouse = false;

// const listener = () => {
//   hasMouse = true;
//   _isTablet = false;
// window.removeEventListener('mousemove', listener);
// };

// window.addEventListener('mousemove', listener);

export function isTablet() {
  if (_isTablet !== null) {
    return _isTablet;
  }
  // detect if touch enabled device is a tablet
  _isTablet = navigator.maxTouchPoints > 0 && window.innerWidth > 768;
  return _isTablet;
}

if (window.innerWidth <= 1124) {
  console.log('disabling context menu');
  window.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
}
