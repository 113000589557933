<div *ngIf="showHeader" class="row">
    <div class="col-4"></div>
    <div class="col text-center"><strong>Calories</strong></div>
    <div class="col text-center"><strong>Fat</strong></div>
    <div class="col text-center"><strong>Carbs</strong></div>
    <div class="col text-center"><strong>Sugar</strong></div>
    <div class="col text-center"><strong>Fiber</strong></div>
    <div class="col text-center"><strong>Protein</strong></div>
</div>


<div class="row" [class.showDailyPercentage]="showDailyPercentage">
    <div class="col-4" [class.text-bold]="boldTitle">
        {{title | titlecase }}
    </div>
    <div class="col text-center">
        {{nutritionFacts.calories}}
        <small class="text-muted"> ({{nutritionFacts.calories | round|percentDaily:2000}})</small>
    </div>
    <div class="col text-center">
        {{nutritionFacts.fat | round}}g
        <small class="text-muted"> ({{nutritionFacts.fat|percentDaily:65}})</small>
    </div>
    <div class="col text-center">
        {{nutritionFacts.carbs | round}}g
        <small class="text-muted"> ({{nutritionFacts.carbs|percentDaily:300}})</small>
    </div>
    <div class="col text-center">
        {{nutritionFacts.carbsFromSugar | round}}g
    </div>
    <div class="col text-center">
        {{nutritionFacts.carbsFromFiber | round}}g
        <small class="text-muted">({{nutritionFacts.carbsFromFiber|percentDaily:25}})</small>
    </div>
    <div class="col text-center">
        {{nutritionFacts.protein | round}}g
    </div>
</div>
