/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-plan/MealPlanMeal.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { IsString } from 'class-validator';
import { Default } from '../../utils/default.decorator';
import { createId } from '../../utils/id-generator';

import { MealType } from './MealType';

@Exclude()
export class MealPlanMeal {
  @Expose()
  @IsString()
  @Default(() => createId('mpm'))
  id: string = createId('mpm');

  @Expose()
  @IsString()
  @Default('Meal Name')
  name: string = 'Meal Name';

  @Expose()
  @Default(MealType.Dinner)
  // @IsEnum(MealType)
  type: MealType = MealType.Dinner; // enum this

  @Expose()
  @Default(false)
  canEdit = false;
}
