/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/calc-date.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import * as moment from 'moment';

export function calcDate(weekNumber: string, day: string): moment.Moment {
  const [yearStr, weekStr] = weekNumber.split('.');
  const year = parseInt(yearStr, 10);
  let week = parseInt(weekStr, 10);
  if (day === 'sunday') {
    week++;
  }
  return moment().isoWeekYear(year).isoWeek(week).isoWeekday(day);
}

export interface DateObject {
  year: number;
  month: number;
  day: number;
}

/**
 * Converts a YYYYMMDD string to a date object
 *
 * @param {string} key
 * @returns {{year: number, month: number, day: number}}
 */
export function parseKeySegmentToDateObject(key: string): DateObject {
  return {
    year: parseInt(key.slice(0, 4), 10),
    month: parseInt(key.slice(4, 6), 10),
    day: parseInt(key.slice(6, 8), 10),
  };
}

export function keyToDateObjects(key: string) {
  return key.split('-').map((d) => parseKeySegmentToDateObject(d));
}

export function dateObjectToString(date: DateObject): string {
  const month = date.month.toString().padStart(2, '0');
  const day = date.day.toString().padStart(2, '0');
  return `${date.year}-${month}-${day}`;
}

export function areDatesEqual(date1: DateObject, date2: DateObject) {
  if (date1 && date2) {
    return dateObjectToString(date1) === dateObjectToString(date2);
  }
  return false;
}

export function equals(one: DateObject, two: DateObject) {
  return compareDates(one, two) === 0;
}

export function before(one: DateObject, two: DateObject) {
  return compareDates(one, two) === -1;
}

export function after(one: DateObject, two: DateObject) {
  return compareDates(one, two) === 1;
}

export function compareDates(one: DateObject, two: DateObject): number {
  if (!one || !two) {
    return 0;
  }

  if (one.year !== two.year) {
    return one.year < two.year ? -1 : 1;
  }

  if (one.month !== two.month) {
    return one.month < two.month ? -1 : 1;
  }

  if (one.day !== two.day) {
    return one.day < two.day ? -1 : 1;
  }

  return 0;
}
