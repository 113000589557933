import { froalaOptionsKey } from './env-common';
import { EnvironmentConfig } from './environment-config';

console.log(
  '!!!!!!!!!!!!!!!!!!!!! SANDBOX SANDBOX SANDBOX SANDBOX !!!!!!!!!!!!!!!!!!!!!\n'.repeat(10),
);

export const environment: EnvironmentConfig = {
  api_base_url: 'https://api.lprx-sandbox.com/',
  lprx_auth_www_domain: '',
  lprx_auth_api_domain: '',
  lprx_auth_client_id: '',
  production: false,
  froalaOptionsKey,
  useLocal: true,
  fullscript_client_id: '',
  fullscript_redirect_url: '',
  fullscript_domain_ca: '',
  fullscript_domain_us: '',
  stripe_public_key: '',
};
