/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/as-user.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { plainToClass } from 'class-transformer';
import { User } from '../entities/user/user';

export const asUser: (userData: User) => User = (user: User) => plainToClass(User, user);
