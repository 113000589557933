/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/SimpleNutritionFacts.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass } from 'class-transformer';
import { Default } from '../../utils/default.decorator';

@Exclude()
export class SimpleNutritionFacts {
  @Expose()
  @Default(0)
  fat: number = 0;

  @Expose()
  @Default(0)
  carbsFromFiber: number = 0;

  @Expose()
  @Default(0)
  calories: number = 0;

  @Expose()
  @Default(0)
  carbs: number = 0;

  @Expose()
  @Default(0)
  protein: number = 0;

  @Expose()
  @Default(0)
  carbsFromSugar: number = 0;

  static fromObject(object: any): SimpleNutritionFacts {
    return plainToClass(SimpleNutritionFacts, object);
  }
}
