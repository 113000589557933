import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function passwordValidatorFunction(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const pwd = control.value;
    let isValid = false;

    // password must be at least 6 characters long, have at least one number, one uppercase letter, and one lowercase letter

    let errMsg = 'Password must have at least';

    let errors = [];

    if (!pwd) {
      return { invalidPassword: { value: ' ' } };
    }

    if (!/[0-9]/.test(pwd)) {
      errors.push('one number');
    }

    if (!/[A-Z]/.test(pwd)) {
      errors.push('one uppercase letter');
    }

    if (!/[a-z]/.test(pwd)) {
      errors.push('one lowercase letter');
    }

    if (pwd.length < 6) {
      errors.push('6 characters');
    }

    if (errors.length > 0) {
      if (errors.length > 1) {
        errors[errors.length - 1] = 'and ' + errors[errors.length - 1];
      }
    }

    errMsg += ' ' + errors.join(', ') + '.';

    isValid = errors.length === 0;

    return !isValid ? { invalidPassword: { value: errMsg } } : null;
  };
}
