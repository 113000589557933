/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/resource-folder.response.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Type } from 'class-transformer';
import { plainToClass } from 'class-transformer';
import { DistributorAccessLevel } from '../distributor-access-level';
import { ROOT_FOLDER_ID } from '../utils/constants';
import { Default } from '../utils/default.decorator';
import { ResourceFile } from './resource-file';
import { ResourceFolder } from './resource-folder';

const typeResourceFolderResponse = () => {
  return ResourceFolderResponse;
};

@Exclude()
export class ResourceFolderResponse extends ResourceFolder {
  @Expose()
  @Type(typeResourceFolderResponse)
  @Default([])
  subfolders: ResourceFolderResponse[] = [];

  @Expose()
  @Type(() => ResourceFile)
  @Default([])
  files: ResourceFile[] = [];

  @Expose()
  @Type(typeResourceFolderResponse)
  parent: ResourceFolderResponse;

  static root() {
    return plainToClass(ResourceFolderResponse, {
      name: ROOT_FOLDER_ID,
      createdAt: 0,
      modifiedAt: 0,
      isPublished: true,
      accessLevel: DistributorAccessLevel.Open
    } as ResourceFolderResponse);
  }

  static fromResourceFolder(rf: ResourceFolder): ResourceFolderResponse {
    return plainToClass(ResourceFolderResponse, rf);
  }
}
