import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { useLocal } from '../../use-local';
import { AmplifyAdapter } from './amplify-adapter';
import { HttpClientAdapter } from './http-client-adapter';
import { getTokenResponse } from '../../lprx-auth';

@Injectable()
export class HttpAdapterFactory {
  constructor(private http: HttpClient) {}

  /**
   *
   */
  create() {
    if (useLocal() || getTokenResponse()) {
      return new HttpClientAdapter(this.http, getTokenResponse());
    } else {
      return new AmplifyAdapter();
    }
  }
}
