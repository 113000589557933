import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoadingModule } from '../loading/loading.module';
import { PipesModule } from '../pipe/pipes.module';
import { BuyPageComponent } from './buy-page/buy-page.component';
import { BuyComponent } from './buy.component';
import { CreditCardInputModule } from '../credit-card-input/credit-card-input.module';
import {
  ButtonDirective,
  SecondaryButtonDirective,
} from '../../component-directives/button.directive';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LoadingModule,
    PipesModule,
    CreditCardInputModule,
    ButtonDirective,
    SecondaryButtonDirective,
  ],
  exports: [BuyComponent],
  declarations: [BuyComponent, BuyPageComponent],
})
export class BuyModule {}
