import { plainToClass } from 'class-transformer';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { stripRecipeDataFromWeeklyMealPlan } from '../../lprx-shared-lib/utils/strip-recipe-data';
import { LprxApi } from './api';

export class MasterPlans {
  constructor(private readonly lprxApi: LprxApi) {}

  get(mealPlanId: string, weekNumber: string): Promise<WeeklyPlan> {
    return this.lprxApi
      .get(`v3/master-plans/${mealPlanId}/${weekNumber}`)
      .then((weeklyPlan: WeeklyPlan) => plainToClass(WeeklyPlan, weeklyPlan));
  }

  copy(mealPlanId: string, weekNumber: any, toWeekNumber: string): Promise<WeeklyPlan> {
    return this.lprxApi
      .post(`v3/master-plans/${mealPlanId}/${weekNumber}/copy`, { weekNumber: toWeekNumber })
      .then((weeklyPlan: WeeklyPlan) => plainToClass(WeeklyPlan, weeklyPlan));
  }

  save(weeklyPlan: WeeklyPlan) {
    return this.lprxApi
      .post(`v3/master-plans`, stripRecipeDataFromWeeklyMealPlan(weeklyPlan))
      .then((weeklyPlanResponse: WeeklyPlan) => plainToClass(WeeklyPlan, weeklyPlanResponse));
  }

  delete(mealPlanId: string, weekNumber: string): Promise<any> {
    return this.lprxApi.delete(`v3/master-plans/${mealPlanId}/${weekNumber}`);
  }
}
