<app-focused-form size="sm" [background]="'url(\'/assets/img/home/hero-banner-002.jpg\') center center no-repeat'"
    [distributorId]='distributorVanityId'>

    <h4 class="text-center mb-4">You have not completed your Food Frequency Questionnaire.</h4>

    <h5 class="text-center">
        You may continue at any time to complete the questionnaire by accessing the link in your email. Look for the
        email with the subject "Food Assessment Questionnaire" in your inbox or your spam folder.
    </h5>

</app-focused-form>