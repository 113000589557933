import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';

export class RecipeToOpen {
  constructor(
    public readonly recipe: Recipe,
    public readonly servings: number = 0,
    public readonly card?: Card
  ) {}
}
