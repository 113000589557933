import { NavigationExtras, UrlTree } from '@angular/router';

export class BackButtonConfig {
  readonly url: string | UrlTree;
  readonly extras: NavigationExtras;

  constructor(url?: string | UrlTree, extras?: NavigationExtras) {
    this.url = url;
    this.extras = extras;
  }
}
