/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/EquipmentItem.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class EquipmentItem {
  @Expose()
  name: string;

  @Expose()
  key: string;
}
