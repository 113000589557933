import { Component, Input } from '@angular/core';
import { ElementDirections } from '../../../../../lprx-shared-lib/entities/recipe/ElementDirections';
import { Step } from '../../../../../lprx-shared-lib/entities/recipe/Step';
import { Directions } from '../../../../../lprx-shared-lib/entities/recipe/Directions';

// import { DragulaService } from 'ng2-dragula';

/**
 * Generated class for the RecipeDirectionsInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'recipe-directions-input',
  templateUrl: 'recipe-directions-input.html',
})
export class RecipeDirectionsInputComponent {
  @Input() directions: ElementDirections;

  constructor() {
    // try {
    //   dragulaService.setOptions('bag-steps', {
    //     revertOnSpill: true,
    //     moves: function (el, container, handle) {
    //       return handle.className === 'handle-ingredient';
    //     }
    //   });
    //   dragulaService.drag.subscribe((value) => {
    //     this.onDrag(value.slice(1));
    //   });
    //   dragulaService.drop.subscribe((value) => {
    //     this.onDrop(value.slice(1));
    //   });
    //   dragulaService.over.subscribe((value) => {
    //     this.onOver(value.slice(1));
    //   });
    //   dragulaService.out.subscribe((value) => {
    //     this.onOut(value.slice(1));
    //   });
    // }
    // catch (e) {
    // }
  }

  addPrep() {
    this.directions.prep = new Directions();
    this.directions.prep.steps.push(new Step());
  }

  private hasClass(el: any, name: string) {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private addClass(el: any, name: string) {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  private removeClass(el: any, name: string) {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }

  private onDrag(args) {
    const [e, el] = args;
    this.removeClass(e, 'ex-moved');
  }

  private onDrop(args) {
    const [e, el] = args;
    this.addClass(e, 'ex-moved');
  }

  private onOver(args) {
    const [e, el, container] = args;
    this.addClass(el, 'ex-over');
  }

  private onOut(args) {
    const [e, el, container] = args;
    this.removeClass(el, 'ex-over');
  }
}
