import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { NutrientLimitsFilter } from '../../../lprx-shared-lib/interface/nutrient-limits.filter';
import { ApiV2 } from '../../../providers/api.v2';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';

import { RecipeSearchResultInterface } from '../../model/recipe-search-result.interface';

@Injectable()
export class DistributorRecipesService {
  constructor(private apiV2: ApiV2) {}

  public clientSearch(
    clientUsername: string,
    term = '',
    from = 0,
    limit = 30,
    mealTypes = [],
    nutrientLimits: NutrientLimitsFilter
  ) {
    const searchParams = {
      clientUsername,
      term,
      from,
      limit,
      mealTypes,
      nutrientLimits,
    };
    return this.apiV2.post$('distributor/recipes/search/client', searchParams).pipe(
      map((result) => {
        result.recipes = result.recipes.map((recipe) => Recipe.fromObject(recipe));
        return result as RecipeSearchResultInterface;
      })
    );
  }
}
