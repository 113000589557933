/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/distributor-application.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { BaseItem } from './entities/base-item';
import { DistributorType } from './entities/distributor-type';
import { User } from './entities/user/user';
import { UserType } from './entities/user/UserType';
import { sanitizeEmail } from './sanitize-email';
import { Default } from './utils/default.decorator';
import { NoProofImage } from './vars';

export enum DistributorApplicationStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
  Onboarded = 'onboarded',
}

export interface DistributorApplicationCreateParams {
  firstName: string;
  lastName: string;
  credentials: string;
  email: string;
  business: string;
  website?: string;
  proof?: string;
  distributorType?: DistributorType;
  referrer?: string;
  referringUrl?: string;
}

@Exclude()
export class DistributorApplication implements BaseItem {
  @Expose()
  id: string;
  @Expose()
  firstName: string = '';
  @Expose()
  lastName: string = '';
  @Expose()
  business: string = '';
  @Expose()
  email: string = '';
  @Expose()
  website: string = '';
  @Expose()
  credentials: string = '';
  @Expose()
  proof: string;
  @Expose()
  status: DistributorApplicationStatus = DistributorApplicationStatus.Pending;
  @Expose()
  appliedAt: number = 0;
  @Expose()
  approvedAt: number = 0;
  @Expose()
  approvedBy: string;
  @Expose()
  rejectedAt: number = 0;
  @Expose()
  rejectedMessage: string;
  @Expose()
  referrer?: string;
  @Expose()
  referringUrl?: string;
  @Expose()
  createdAt: number;
  @Expose()
  isDeleted: boolean;
  @Expose()
  modifiedAt: number;
  @Expose()
  rejectedBy: string;

  @Expose()
  distributorId: string;

  @Expose()
  source?: string;

  @Expose()
  onboardedAt?: number;

  @Expose()
  @Default(DistributorType.HealthCareProfessional)
  distributorType: DistributorType = DistributorType.HealthCareProfessional;

  static create(params: DistributorApplicationCreateParams) {
    const {
      firstName,
      lastName,
      credentials,
      email,
      business,
      website = 'www.YOUR-WEBSITE.com',
      proof = NoProofImage,
      distributorType = DistributorType.HealthCareProfessional,
      referrer,
    } = params;

    const application = new DistributorApplication();
    application.firstName = firstName;
    application.lastName = lastName;
    application.credentials = credentials;
    application.email = sanitizeEmail(email);
    application.business = business;
    application.website = website;
    application.proof = proof;
    application.distributorType = distributorType;
    application.referrer = referrer;
    return application;
  }

  isHealthCareProfessional() {
    return this.distributorType === DistributorType.HealthCareProfessional;
  }

  isWellnessProfessional() {
    return this.distributorType === DistributorType.WellnessProfessional;
  }

  /**
   * Rejects the application
   *
   * @param user
   */
  reject(user: User) {
    if (!user || ![UserType.Admin, UserType.System].includes(user.userType)) {
      throw new Error('Only admins can rejects applications');
    }

    this.rejectedAt = Date.now();
    this.rejectedBy = user.username;
    this.status = DistributorApplicationStatus.Rejected;
  }

  approve(user: User) {
    if (!user || ![UserType.Admin, UserType.System].includes(user.userType)) {
      throw new Error('Only admins can approve applications');
    }
    this.approvedAt = Date.now();
    this.approvedBy = user.username;
    this.status = DistributorApplicationStatus.Approved;
  }

  onboard() {
    this.onboardedAt = Date.now();
    this.status = DistributorApplicationStatus.Onboarded;
  }
}
