/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/NdbMatch.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class NdbMatch {
  @Expose()
  ndbNo: string;

  @Expose()
  description: string;

  @Expose()
  measurement: string;

  @Expose()
  amount: number;

  /** @deprecated in favor of measurement */
  @Expose()
  weight: string;
}
