/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/weekly-plan-type.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum WeeklyPlanType {
  Master = 'master',
  Client = 'client'
}
