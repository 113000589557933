import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { FavoriteRecipes } from '../../../lprx-shared-lib/entities/favorite-recipes';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { AuthService } from '../../auth.service';
import { RecipeService } from '../../service/recipe.service';
import { errorMessage } from '../../utilities/alert-http-error';

@Component({
  selector: 'app-favorite-recipe-toggle',
  templateUrl: './favorite-recipe-toggle.component.html',
  styleUrls: ['./favorite-recipe-toggle.component.scss']
})
export class FavoriteRecipeToggleComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;
  @Input() enabled: boolean = true;
  @Input() block: boolean;

  isFavorite = false;
  private subs: Subscription[] = [];
  user: User;

  constructor(
    private recipesService: RecipeService,
    private ch: ChangeDetectorRef,
    private toastr: ToastrService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.subs.push(this.favoriteRecipesSub(), this.userSub());
    this.recipesService.favoriteRecipes().subscribe(favs => this._favs(favs));
  }

  private userSub() {
    return this.auth.user$.subscribe(u => (this.user = u));
  }

  private favoriteRecipesSub() {
    return this.recipesService.favoriteRecipes$.subscribe(favs => this._favs(favs));
  }

  private _favs(favs) {
    if (favs) {
      this.isFavorite = favs.is(this.recipe.id);
      this.ch.markForCheck();
    }
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  toggleFavorite($event: MouseEvent) {
    if (!this.enabled) {
      return;
    }

    $event.stopPropagation();
    try {
      const sd$: Observable<FavoriteRecipes> = this.isFavorite
        ? this.recipesService.like(this.recipe.id)
        : this.recipesService.unlike(this.recipe.id);

      sd$.subscribe(
        () => {
          this.ch.markForCheck();
        },
        e => {
          this.toastr.error(errorMessage(e));
        }
      );
    } catch (e) {
      this.toastr.error(errorMessage(e));
    }
  }
}
