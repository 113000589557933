<main role="main" class="container py-5">

    <div class="row align-items-center">
        <div class="col-md-6">
            <h2>Recipe Box</h2>
        </div>
        <div class="col-md-6 text-right">

            <button type='button' (click)="backToPlanner()" class='btn btn-link'>Back To Planner</button>

            <a routerLink="/recipe/edit/new" appButton>+ New Recipe</a>
        </div>
    </div>

    <hr>
    <div class="row">
        <div class="col-md-3">

            <div class="input-group">
                <input [(ngModel)]="term"
                       (ngModelChange)='changedTerm($event)'
                       id="search-input"
                       class="form-control"
                       placeholder="Search" />


                <a [ngbPopover]="popContent"
                   #filterPopover="ngbPopover"
                   [popoverTitle]="popTitle"
                   [autoClose]="'outside'"
                   class="input-group-append"
                   title="filter"
                   popoverClass="filter-popover">

                    <div class="input-group-text">
                        <i class="fa fa-filter" [class.text-success]="filterApplied"></i>
                    </div>

                </a>
            </div>

<!--            <input class="form-control" placeholder="Search" [(ngModel)]="term" (ngModelChange)='changedTerm($event)'>-->

        </div>
        <div class="col-md-5">
            <div class="btn-group" role="group" aria-label="Filter recipes by type">

                <button *ngFor="let mealType of mealTypes" type="button" class="btn"
                        [class.btn-primary]="selectedMealTypes.includes(mealType)"
                        (click)="filterMealType(mealType)">
                    <i class="fa"
                       [class.fa-square-o]="!selectedMealTypes.includes(mealType)"
                       [class.fa-check-square-o]="selectedMealTypes.includes(mealType)"
                    ></i>
                    {{mealType}}
                </button>

            </div>
        </div>

        <div class="col-md-4">

            <button type="button" class="btn btn-link" (click)="toggleOwnRecipes()">
                <i class="fa"
                   [class.fa-square-o]="!onlyOwners"
                   [class.fa-check-square-o]="onlyOwners"
                ></i> My recipes
            </button>

            <button type="button" class="btn btn-link" (click)="toggleFavorites()">
                <i class="fa"
                   [class.fa-square-o]="!onlyFavorites"
                   [class.fa-check-square-o]="onlyFavorites"
                ></i>
                Favorites
            </button>

        </div>
    </div>

    <hr>

    <div *ngIf="recipes.length > 0">
        <div class="row no-gutters">

            <div class="col-md-2 mb-3" *ngFor="let recipe of recipes">
                <app-recipe-box-result [recipe]="recipe" id="recipe-{{recipe.id}}"></app-recipe-box-result>
            </div>

        </div>
    </div>

    <div *ngIf="!showPending && recipes.length === 0">
        <div class="alert alert-warning">
            <h5 class="text-center">
                Sorry, we couldn't find any recipes with '<strong>{{term}}</strong>'.
            </h5>
        </div>
    </div>

    <p class="text-center">
        <br>
        <i *ngIf="showPending" class="fa fa-spinner fa-spin fa-2x text-secondary"></i>
        <button type="button" appButton (click)="loadMore()" *ngIf="showLoadMore">Show me more
        </button>
    </p>

</main>



<ng-template #popTitle>
    <div class="row no-gutters text-center align-items-center">
        <div class="col-3">
            <button
                type="button"
                (click)="resetFilters()"
                class="btn btn-block btn-xs btn-outline-secondary">Reset
            </button>
        </div>
        <div class="col">
            <strong>Filters</strong>
        </div>
        <div class="col-3">
            <button type="button" (click)="filterPopover.close()" class="btn btn-block btn-xs btn-outline-primary">
                Close
            </button>
        </div>
    </div>
</ng-template>

<ng-template #popContent>
    <div style="max-height: 400px; overflow-y: scroll;">

        <div class="list-group">


            <ng-container *ngIf="plannerConfig.showNutritionFacts">
                <div class="list-group-item">
                    <strong>Macronutrients</strong>
                </div>

                <label *ngFor="let macroOption of macroOptions"
                       class="list-group-item align-items-center">
                    <div class="row align-items-center no-gutters">
                        <div class="col-6">
                            &nbsp;{{macroOption.title}}
                            <br>
                            &nbsp;{{macroOption.low}} - {{macroOption.highDisplay}}
                        </div>
                        <div class="col-6">
                            <ngx-slider
                                [(value)]="macroOption.low"
                                [(highValue)]="macroOption.high"
                                (userChangeEnd)="onUserChangeEnd($event, macroOption)"
                                (userChange)="onUserChange($event, macroOption)"
                                [options]="{floor: macroOption.floor, ceil: macroOption.ceil, step: macroOption.step}">
                            </ngx-slider>
                        </div>
                    </div>
                </label>
            </ng-container>

        </div>
    </div>

</ng-template>
