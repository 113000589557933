/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/enum/unit-system.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { convertUnits } from '../convert-units';

export enum UnitSystem {
  Metric = 'metric',
  Imperial = 'imperial',
  Bits = 'bits',
  Bytes = 'bytes',
}

export interface UnitDescription {
  abbr: convertUnits.Unit;
  measure: convertUnits.Measure;
  system: convertUnits.System;
  singular: string;
  plural: string;
}
