import { plainToClass } from 'class-transformer';
import { PrimeCheckPromoCodeCommand } from '../../lprx-shared-lib/prime/commands/prime-check-promo-code-command';
import { PrimeCouponSetting } from '../../lprx-shared-lib/prime/commands/prime-coupon-setting';
import { PrimePurchaseCommand } from '../../lprx-shared-lib/prime/commands/prime-purchase-command';
import { PrimePurchaseResponse } from '../../lprx-shared-lib/prime/commands/prime-purchase-response';
import { LprxApi } from './api';
import {
  PrimePurchasePlansResponse
} from '../../lprx-shared-lib/dto/prime-purchase-plans-response';

export class PrimePurchase {
  constructor(private readonly lprxApi: LprxApi) {}

  async checkPromoCode(promoCode: string, planId: string): Promise<PrimeCouponSetting> {
    const payload: PrimeCheckPromoCodeCommand = { promoCode, planId };
    return this.lprxApi.post(`v3/prime-purchase/check-promo-code`, payload);
  }

  async getPlans() {
    return this.lprxApi.get<PrimePurchasePlansResponse>(`v3/prime-purchase/plans`);
  }

  /**
   * Execute a Purchase Command
   *
   * @param planId
   * @param source
   * @param promoCode
   */
  async purchase(
    planId: string,
    source: string,
    promoCode: string
  ): Promise<PrimePurchaseResponse> {
    return this.lprxApi
      .post(`v3/prime-purchase`, plainToClass(PrimePurchaseCommand, { planId, source, promoCode }))
      .then((res: object) => plainToClass(PrimePurchaseResponse, res));
  }
}
