<div
  cdkDropListGroup
  [cdkDropListGroupDisabled]="!canEdit || previewMode"
  class="-container-fluid pb-5"
  [class.disable-drag]="!canEdit"
  [class.is-admin]="isAdmin()"
  style="user-select: none; -webkit-user-select: none"
>
  <div
    *ngIf="isTablet"
    cdkDropList
    (cdkDropListDropped)="removeRecipe($event)"
    class="remove-recipe-bin"
  >
    <mat-icon style="display: block !important">close</mat-icon>
  </div>

  <mat-sidenav-container>
    <mat-sidenav
      mode="side"
      opened="opened"
      position="end"
      [fixedInViewport]="true"
      [fixedTopGap]="headerHeight"
      style="width: 16%"
    >
      <div
        class="-sidebar-sticky"
        [attr.data-style]="'position: fixed; padding-top: 15px'"
        [class.px-0]="isTablet"
        [class.px-2]="!isTablet"
      >
        <app-planner-search
          *ngIf="weeklyPlans && weeklyPlans.length"
          [weeklyPlans]="weeklyPlans"
          [client]="client"
        >
        </app-planner-search>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <main
        role="main"
        class="--col --col-md-10 mr-sm-auto pt-3"
        [class.px-4]="!isTablet"
        [class.tablet-border]="isTablet"
      >
        <div class="planner-wrapper mx-auto {{ cardLayout }}" [class.dragging]="isDragging">
          <div *ngIf="weeklyPlans && weeklyPlans.length">
            <div
              *ngIf="isAdmin() && weeklyPlans[0].type === 'master'"
              class="alert alert-info text-center"
            >
              You are {{ previewMode ? 'previewing' : 'editing' }} the
              <strong>{{ weeklyPlans[0].mealPlan.name }}</strong> meal plan.
            </div>

            <div *ngIf="previewMode" class="alert alert-danger text-center">
              Drag and drop is disabled in preview mode.
            </div>

            <div
              *ngIf="user?.userType === 'distributor' && client && weeklyPlans[0].type !== 'master'"
              class="alert alert-info text-center"
            >
              You are <strong>{{ client.plannerType === 'c' ? 'editing' : 'viewing' }}</strong> the
              <strong>{{ weeklyPlans[0].mealPlan.name }}</strong> meal plan for
              <strong>{{ client.firstName }} {{ client.lastName }}</strong
              >.

              <span
                *ngIf="
                  weeklyPlans[1] && weeklyPlans[0].mealPlan.name !== weeklyPlans[1].mealPlan.name
                "
              >
                <br /><em
                  >The following week, {{ weeklyPlans[1].weekNumber | humanWeek: true }} is
                  {{ weeklyPlans[1].mealPlan.name }}.</em
                >
              </span>
            </div>
          </div>

          <div class="row" [class.px-2]="isTablet">
            <div class="col-md-5">
              <ng-container *ngIf="weeklyPlans[0].mealPlan.nonCalendar">
                <h3>
                  {{ weeklyPlans[0].weekNumber | humanWeek }}
                  <div ngbDropdown placement="bottom-right" class="d-inline-block">
                    <button
                      class="btn btn-outline-secondary no-carat"
                      id="dropdownBasic1"
                      ngbDropdownToggle
                    >
                      <i class="fa fa-chevron-down"></i>
                    </button>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <button
                        *ngFor="let i of nonCalweeks"
                        type="button"
                        class="dropdown-item"
                        (click)="changeWeek(i)"
                      >
                        Week {{ i }}
                      </button>
                    </div>
                  </div>
                </h3>
              </ng-container>

              <h3 *ngIf="!weeklyPlans[0].mealPlan.nonCalendar">
                {{ weekNumber | humanWeek }}
                <a *ngIf="isAdmin()" (click)="d.toggle()"><i class="fa fa-calendar"></i></a>
                <input
                  type="input"
                  style="
                    visibility: hidden;
                    height: 1px;
                    width: 1px;
                    position: relative;
                    top: -15px;
                  "
                  ngbDatepicker
                  [placement]="'bottom-left'"
                  [(ngModel)]="dateModel"
                  (dateSelect)="calendarSelect($event)"
                  #d="ngbDatepicker"
                />
              </h3>
            </div>
            <div class="col-md-2 text-center">
              <div *ngIf="showWeekPaging && weeklyPlans[0].weekNumber !== '1970.01'">
                <a (click)="loadPreviousWeek()" class="btn btn-outline-secondary">
                  <i class="fa fa-chevron-up"></i>
                </a>
              </div>
            </div>
            <div class="col-md-5 text-right planner-utilities">
              <span class="layouts">
                <a (click)="setLayout('layout-compressed')" title="Compressed Layout">
                  <i class="fa fa-window-minimize"></i> </a
                >&nbsp;
                <!--                            <a (click)="setLayout('layout-layered')" title="Layered Layout">-->
                <!--                                  <i class="fa fa-window-restore"></i>-->
                <!--                            </a>&nbsp;-->
                <a (click)="setLayout('layout-expanded')" title="Expanded Layout">
                  <i class="fa fa-window-maximize"></i>
                </a>
              </span>
              &nbsp;

              <ng-container *ngIf="showNutritionalInfo">
                <a routerLink="/planner/nutrition-facts" title="Nutrition Facts"
                  ><img src="/assets/img/icon/calculator.svg" style="height: 25px"
                /></a>
                &nbsp;
              </ng-container>

              <a routerLink="/planner/grocery-list" title="Grocery List"
                ><img src="/assets/img/icon/meal-plan.svg" style="height: 25px"
              /></a>
              &nbsp;
              <a *ngIf="!previewMode" [routerLink]="['print']"
                ><img src="/assets/img/icon/printer.svg" style="height: 25px"
              /></a>

              <a
                *ngIf="!previewMode && user?.userType === 'distributor'"
                (click)="pdf()"
                style="position: relative; top: 3px; margin-left: 12px"
                ><i class="fa fa-file-pdf-o" style="font-size: 22px; color: #c7d4a0"></i
              ></a>

              <ng-container *ngIf="!user?.isClient">
                &nbsp;&nbsp;

                <a
                  *ngIf="client?.username"
                  [routerLink]="'/planner/client/' + client.username + '/recipes'"
                  >Recipe Box</a
                >

                <a *ngIf="!client?.username" [routerLink]="'recipes'">Recipe Box</a>
              </ng-container>

              <app-planner-fullscript-button
                [user]="user"
                [client]="client"
              ></app-planner-fullscript-button>
            </div>
          </div>

          <div *ngIf="!weeklyPlans[0].mealPlan.nonCalendar" class="row no-gutters">
            <div class="col day-labels" *ngFor="let day of daysOfTheWeek">
              {{ day }}
            </div>
          </div>

          <ng-container *ngIf="weeklyPlans && weeklyPlans.length">
            <app-planner-week
              *ngFor="let weeklyPlan of weeklyPlans; let i = index"
              (copied)="copied($event)"
              [weeklyPlan]="weeklyPlan"
              [canSwapUp]="i > 0"
              [canSwapDown]="i < weeklyPlans.length - 1"
              [attr.data-week-number]="weeklyPlan.weekNumber"
            ></app-planner-week>
          </ng-container>

          <div *ngIf="_showLoadNextWeek" class="text-center mt-2">
            <a (click)="loadAdditionalWeek()" class="btn btn-outline-secondary">
              <i class="fa fa-chevron-down"></i>
            </a>
          </div>
        </div>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
