import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { FullscriptTreatmentPlan } from '../../../lprx-shared-lib/entities/i-fullscript-practitioner';

import { User } from '../../../lprx-shared-lib/entities/user/user';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { AuthService } from '../../auth.service';
import { DistributorsService } from '../../service/distributors.service';
import { PlannerService } from '../planner.service';

@Injectable({
  providedIn: 'root'
})
export class PlannerFullscriptButtonService {
  constructor(
    private auth: AuthService,
    private distributorService: DistributorsService,
    private plannerService: PlannerService,
    private lprxApi: LprxApiProvider
  ) {}

  getTreatmentPlans(client: User): Observable<FullscriptTreatmentPlan[]> {
    return fromPromise(this.lprxApi.fullscript.treatmentPlans(client.username));
  }
}
