/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/user/user.ts
 *  --------------------------
 *  - Swagger items commented out
 */

// Not for Angular: import { ApiProperty } from '@nestjs/swagger';
import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import { IsEmail } from 'class-validator';
import { UnitSystem } from '../../enum/unit-system';
import { InitialAccountType } from '../../initial-account-type.enum';
import { PlannerConfigNutrientLimits } from '../../planner/planner-configuration';
import { sanitizeEmail } from '../../sanitize-email';
import { Default } from '../../utils/default.decorator';
import { createId } from '../../utils/id-generator';
import { IdPrefix } from '../../utils/id-prefix';
import { BaseItem } from '../base-item';
import { PlannerType } from '../weeky-meal-plan/PlannerType';
import { ClientAccountType } from './client-account-type';
import { NutritionFactsDisplay } from './nutrition-facts-display';
import { UserStatus } from './user-status';
import { UserType } from './UserType';
import Stripe from 'stripe';
import { IngredientPreferences } from '../../planner/ingredient.preferences';

@Exclude()
export class User implements BaseItem {
  /* **********************************************
   * All user Attributes
   * *********************************************/

  @Expose()
  get isAdmin() {
    return this.userType === UserType.Admin;
  }

  set isAdmin(value: boolean) {
    // ignore
  }

  @Expose()
  get isDistributor() {
    return this.userType === UserType.Distributor;
  }

  set isDistributor(value: boolean) {
    // ignore
  }

  @Expose()
  get isClient() {
    return this.userType === UserType.Client;
  }

  set isClient(value: boolean) {
    // ignore
  }

  @Expose()
  @Default(() => createId(IdPrefix.User))
  /* Not for Angular: @ApiProperty({ example: 'user_abc465464' }) */
  username: string = createId(IdPrefix.User);

  @Expose()
  @Transform((v, obj) => obj.username)
  id = this.username;

  @Expose()
  @Transform((v, obj) => obj.username)
  userId = this.username;

  @Expose()
  @Default('')
  @IsEmail()
  /* Not for Angular: @ApiProperty({ example: 'hollytree@example.com' }) */
  email: string = '';

  @Expose()
  @Default('')
  /* Not for Angular: @ApiProperty({ example: 'Holly' }) */
  firstName: string = '';

  @Expose()
  @Default('')
  /* Not for Angular: @ApiProperty({ example: 'Camps' }) */
  lastName: string = '';

  @Expose()
  createdAt: number;

  @Expose()
  @Default(UserType.Client)
  userType: UserType = UserType.Client;

  @Expose()
  modifiedAt: number;

  @Expose()
  isDeleted: boolean;

  @Expose()
  deletedAt: number;

  @Expose()
  deletedBy: string;

  /* **********************************************
   * Practitioner Attributes
   * *********************************************/

  @Expose()
  permissions: PractitionerPermissions[];

  /* **********************************************
   * Practitioner and Client Attributes
   * *********************************************/

  @Expose()
  distributorId: string;

  /* **********************************************
   * Client Attributes
   * *********************************************/

  get accountType(): ClientAccountType {
    if (this.terminatesAt < Date.now()) {
      return ClientAccountType.Expired;
    }
    return ClientAccountType.FreeTrial;
  }

  set accountType(value: ClientAccountType) {
    // ignore
  }

  @Expose()
  unitSystem: UnitSystem;

  @Expose()
  terminatesAt: number;

  @Expose()
  mealPlanId: string;

  @Expose()
  startsAt: number;

  @Expose()
  bundleClientId: string;

  @Expose()
  stripeCustomerId: string;

  @Expose()
  stripeAccountId?: string;

  @Expose()
  subscriptionId?: string;

  @Expose()
  plannerType: PlannerType;

  @Expose()
  initialAccountType: InitialAccountType;

  @Expose()
  isInFreeTrial: boolean;

  @Expose()
  @Default(true)
  receiveWeeklyEmails: boolean = true;

  @Expose()
  @Default(NutritionFactsDisplay.Default)
  nutritionFactsDisplay: NutritionFactsDisplay = NutritionFactsDisplay.Default;

  @Expose()
  field: string;

  @Expose()
  fullscriptPatientId?: string;

  @Expose()
  activatedAt: number;

  @Expose()
  @Default(true)
  allowSubscription: boolean = true;

  @Expose()
  nutrientLimits?: PlannerConfigNutrientLimits;

  @Expose()
  @Default(() => new IngredientPreferences())
  ingredientPreferences: IngredientPreferences = new IngredientPreferences();

  @Expose()
  lastWeekNumberLoaded?: string;

  /**
   * Username of the primary practitioner of the client
   *
   * @type {string}
   */
  @Expose()
  primaryPractitioner?: string;

  /* **********************************************
   * Methods for All user Roles
   * *********************************************/

  /**
   * @param {ClientAccountType} obj
   * @returns {ClientAccountType | ClientAccountType}
   */
  public static fromObject(obj: any): User {
    return plainToClass(User, obj as User);
  }

  /**
   *
   * @param details
   */
  static newClient(details: NewClientDetails) {
    const user = new User();

    user.userType = UserType.Client;
    user.username = details.username || createId(IdPrefix.User);
    user.firstName = details.firstName;
    user.lastName = details.lastName;
    user.email = sanitizeEmail(details.email);
    user.distributorId = details.distributorId;
    user.mealPlanId = details.mealPlanId;
    user.plannerType = details.plannerType;
    user.terminatesAt = details.terminatesAt;
    user.initialAccountType = details.initialAccountType;
    user.primaryPractitioner = details.primaryPractitioner;

    return user;
  }

  public name() {
    return this.firstName + ' ' + this.lastName;
  }

  delete(by: User) {
    const now = Date.now();

    this.username = 'DELETED' + this.username;
    this.distributorId = 'DELETED' + this.distributorId;
    this.email = 'deleted-' + this.email;
    this.terminatesAt = now;
    this.deletedAt = now;
    this.deletedBy = by.username;
  }

  /* **********************************************
   * Client Methods
   * *********************************************/

  /**
   * @todo: move the logic to the server on retrieving a client and have it set a status field.
   * @returns {any}
   */
  status(): UserStatus {
    if (!this.isActive()) {
      return UserStatus.EXPIRED;
    }

    if (this.isInFreeTrial) {
      return UserStatus.FREE_TRIAL;
    }

    if (this.subscriptionId) {
      return UserStatus.ACTIVE;
    }

    // the only thing left
    return UserStatus.BUNDLED;
  }

  /**
   * @returns {boolean}
   */
  isActive() {
    return this.terminatesAt > Date.now();
  }

  /**
   * @returns {boolean}
   */
  isBundled() {
    return this.status() === UserStatus.BUNDLED;
  }

  /**
   * todo: clean up on server
   * @returns {any}
   */
  getPlannerType() {
    if (!this.plannerType) {
      return PlannerType.Static;
    }
    return this.plannerType;
  }

  hasCustomPlanner() {
    return this.getPlannerType() === PlannerType.Custom;
  }

  hasStaticPlanner() {
    return this.getPlannerType() === PlannerType.Static;
  }

  canUpgrade() {
    return this.hasStaticPlanner() && !this.isBundled();
  }

  changeMealPlan(mealPlanId: string) {
    if (this.userType !== UserType.Client) {
      throw new Error(`Cannot change MealPlan as User(${this.username}) is not a client account.`);
    }

    this.mealPlanId = mealPlanId;
  }

  updateSubscription(subscription: Stripe.Subscription) {
    this.subscriptionId = subscription.id;
    this.terminatesAt = subscription.current_period_end * 1000;
  }

  /**
   * Check if the user has the given permission
   *
   * If the user has the admin permission, they have all permissions.
   *
   * @param {PractitionerPermissions} permission
   * @returns {boolean}
   */
  hasPermission(permission: PractitionerPermissions) {
    if (!Array.isArray(this.permissions)) {
      this.permissions = [PractitionerPermissions.admin];
      return true;
    }

    return (
      this.permissions.includes(PractitionerPermissions.admin) ||
      this.permissions.includes(permission)
    );
  }
}

export interface NewClientDetails {
  username?: string;
  firstName: string;
  lastName: string;
  email: string;
  distributorId: string;
  mealPlanId: string;
  plannerType: PlannerType;
  terminatesAt: number;
  initialAccountType: InitialAccountType;
  primaryPractitioner?: string;
  // promocode?: string;
}

// alias for User because intellisense does not work great with User
export { User as LprxUser };

export enum PractitionerPermissions {
  admin = 'admin',
  recipes = 'recipes',
  clients = 'clients',
  mealPlans = 'meal-plans',
  tdCode = 'td-code', // Today's Dietitian CEU Promo Code
}

export const allPractitionerPermissions = [
  PractitionerPermissions.admin,
  PractitionerPermissions.clients,
  PractitionerPermissions.recipes,
  PractitionerPermissions.mealPlans,
  PractitionerPermissions.tdCode,
];

export type PractitionerUserInterface = Pick<
  User,
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'username'
  | 'permissions'
  | 'distributorId'
  | 'userType'
  | 'createdAt'
  | 'modifiedAt'
  | 'isDeleted'
  | 'deletedAt'
  | 'deletedBy'
  | 'hasPermission'
  | 'name'
  | 'isDistributor'
>;

@Exclude()
export class PractitionerUser implements PractitionerUserInterface {
  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  email: string;

  @Expose()
  username: string;

  @Expose()
  permissions: PractitionerPermissions[];

  @Expose()
  distributorId: string;

  @Expose()
  userType: UserType;

  @Expose()
  createdAt: number;

  @Expose()
  modifiedAt: number;

  @Expose()
  isDeleted: boolean;

  @Expose()
  deletedAt: number;

  @Expose()
  deletedBy: string;

  @Expose()
  get isDistributor() {
    return this.userType === UserType.Distributor;
  }

  set isDistributor(value: boolean) {
    // ignore
  }

  hasPermission(permission: PractitionerPermissions) {
    if (!Array.isArray(this.permissions)) {
      this.permissions = [PractitionerPermissions.admin];
      return true;
    }

    return (
      this.permissions.includes(PractitionerPermissions.admin) ||
      this.permissions.includes(permission)
    );
  }

  name(): string {
    return this.firstName + ' ' + this.lastName;
  }

  static new(
    distributorId: string,
    email: string,
    firstName: string,
    lastName: string,
    permissions: PractitionerPermissions[]
  ) {
    if (permissions.includes(PractitionerPermissions.admin)) {
      permissions = allPractitionerPermissions;
    }
    const user = new User();
    user.email = sanitizeEmail(email);
    user.distributorId = distributorId;
    user.firstName = firstName;
    user.lastName = lastName;
    user.permissions = permissions;
    user.userType = UserType.Distributor;
    return user;
  }
}

export class EditTeamMemberRequest {
  firstName: string;
  lastName: string;
  email: string;
  permissions: PractitionerPermissions[];
}
