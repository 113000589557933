/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/resource-folder.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { IsBoolean, IsPositive, IsString, ValidateIf } from 'class-validator';
import { DistributorAccessLevel } from '../distributor-access-level';
import { BaseItem } from '../entities/base-item';
import { DistributorType } from '../entities/distributor-type';
import { ROOT_FOLDER_ID } from '../utils/constants';
import { Default } from '../utils/default.decorator';
// import { ResourcePublishStatus } from './resource-publish-status';

@Exclude()
export class ResourceFolder implements BaseItem {
  @Expose()
  @Default(ROOT_FOLDER_ID)
  id = ROOT_FOLDER_ID;

  @Expose()
  @IsString()
  name = ROOT_FOLDER_ID;

  @Expose()
  accessLevel: DistributorAccessLevel;

  @Expose()
  @Default(false)
  isPublished = false;

  @Expose()
  @Default(ROOT_FOLDER_ID)
  @ValidateIf(o => o.parentId)
  @IsString()
  parentId = ROOT_FOLDER_ID;

  @Expose()
  @Default(Date.now())
  @IsPositive()
  createdAt: number = Date.now();

  @Expose()
  @Default(Date.now())
  @IsPositive()
  modifiedAt: number = Date.now();

  @Expose()
  @Default(false)
  @IsBoolean()
  isDeleted = false;

  @Expose()
  @Default([DistributorType.HealthCareProfessional, DistributorType.WellnessProfessional])
  distributorTypes: DistributorType[] = [
    DistributorType.HealthCareProfessional,
    DistributorType.WellnessProfessional
  ];

  // @Expose()
  get isAccessLevelOpen() {
    return this.accessLevel === DistributorAccessLevel.Open;
  }

  publish() {
    this.isPublished = true;
  }

  unpublish() {
    this.isPublished = false;
  }
}
