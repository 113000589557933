import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { combineLatest } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { USDAFactsResults } from '../../../lprx-shared-lib/recipe/USDAFactsResults';
import { RecipeToOpen } from '../../model/entities/recipe-to-open';
import { PlannerRecipeComponent } from '../planner-recipe/planner-recipe.component';
import { PlannerService } from '../planner.service';
import { RecipeOpenerService } from '../recipe-opener.service';

@Component({
  selector: 'app-nutrition-report',
  templateUrl: './nutrition-report.component.html',
  styleUrls: ['./nutrition-report.component.scss']
})
export class NutritionReportComponent implements OnInit, OnDestroy {
  // nutritionFacts: any[];
  nutritionFactsUSDA: USDAFactsResults[] = [];

  plannerPath: string | null;
  showRecipes = true;
  private subscriptions: Subscription[] = [];

  constructor(
    private modalService: NgbModal,
    private plannerService: PlannerService,
    private recipeOpenerService: RecipeOpenerService,
    private router: Router
  ) {}

  ngOnInit() {
    const weeklyPlansSub = combineLatest(
      this.plannerService.plannerConfig$,
      this.plannerService.weeklyPlans$
    ).subscribe(([config, weeklyPlans]) => {
      this.nutritionFactsUSDA = [];
      weeklyPlans.forEach(wp => {
        this.nutritionFactsUSDA.push(wp.getUSDAFacts(config.nutrients));
      });
    });

    // const weeklyPlansSub = this.plannerService.weeklyPlans$.subscribe(weeklyPlans => {
    //   // this.nutritionFacts = [];
    //   // weeklyPlans.forEach(wp => {
    //   //   this.nutritionFacts.push(wp.getNutritionFacts());
    //   // });
    //
    //   this.nutritionFactsUSDA = [];
    //   weeklyPlans.forEach(wp => {
    //     this.nutritionFactsUSDA.push(wp.getUSDAFacts());
    //   });
    //
    //   console.log(this.nutritionFactsUSDA);
    // });

    // what's going on here?
    // const recipeOpenerSub = this.recipeOpenerService
    //   .getRecipeOpener()
    //   .subscribe(recipeToOpen => this.openRecipe(recipeToOpen));
    //
    // this.subscriptions.push(weeklyPlansSub, recipeOpenerSub);

    this.plannerPath = sessionStorage.getItem('plannerPath');

    // console.log(this.plannerService.weeklyPlans);
    // this.plannerPath = sessionStorage.getItem('plannerPath');
    // if (!this.plannerService.weeklyPlans) {
    //     this.router.navigateByUrl(this.plannerPath);
    //     return;
    // }

    // this.nutritionFacts = [];
    // this.plannerService.weeklyPlans.forEach(wp => {
    //     this.nutritionFacts.push(wp.getNutritionFacts());
    // });
    //
    // this.nutritionFactsUSDA = [];
    // this.plannerService.weeklyPlans.forEach(wp => {
    //     this.nutritionFactsUSDA.push(wp.getUSDAFacts());
    // });
    //
    // console.log(this.nutritionFactsUSDA);
    //
    // this.subscriptions.push(
    //     this.recipeOpenerSubscription()
    // );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  /**
   * Opens the recipe modal
   *
   * @param {RecipeToOpen} recipeToOpen
   */
  private openRecipe(recipeToOpen: RecipeToOpen) {
    console.log('NutritionReportComponent:openRecipe()');
    const modalRef = this.modalService.open(PlannerRecipeComponent, { windowClass: 'full-modal' });
    console.log(recipeToOpen);
    modalRef.componentInstance.recipe = recipeToOpen.recipe;
    modalRef.componentInstance.servings = recipeToOpen.servings;
  }
}
