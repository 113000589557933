import { Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';

@Injectable()
export class DistributorClientPlannerService {
  constructor(private apiV2: ApiV2, private readonly lprxApi: LprxApiProvider) {}

  /**
   * @param username
   * @param weekNumber
   */
  get(username: string, weekNumber: string): Observable<WeeklyPlan> {
    return fromPromise(this.lprxApi.weeklyPlans.get(weekNumber, username));
  }

  /**
   * @param weeklyPlan
   */
  save(weeklyPlan: WeeklyPlan) {
    return fromPromise(this.lprxApi.weeklyPlans.save(weeklyPlan));
  }
}
