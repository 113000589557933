import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { RecipeService } from '../../service/recipe.service';
import { PlannerService } from '../planner.service';

@Component({
  selector: 'app-planner-recipe-viewer',
  templateUrl: './planner-recipe-viewer.component.html',
  styleUrls: ['./planner-recipe-viewer.component.scss'],
})
export class PlannerRecipeViewerComponent implements OnInit {
  card: Card;
  recipe: Recipe;
  action: string;
  weekNumber: string;
  day: string;
  meal: string;

  constructor(
    private plannerService: PlannerService,
    private recipeService: RecipeService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        tap((params) => {
          console.log(params);

          this.recipe = null;

          if (params['meal']) {
            this.weekNumber = params['weekNumber'];
            this.day = params['day'];
            this.meal = params['meal'];
          }
        }),
        switchMap((params) => {
          const card = this.plannerService.getCard(
            params['weekNumber'],
            params['day'],
            params['meal'],
            params['recipeId']
          );
          if (card) {
            this.card = card;
            return of(card.recipe);
          }
          return this.recipeService.getRecipe(params['recipeId']);
        })
      )
      .subscribe((recipe: Recipe) => {
        console.log(recipe.title);
        this.recipe = recipe;
      });
  }
}
