export function getLastAuthUsername() {
  const lastAuthUsername = localStorage.getItem('lastAuthUsername');
  if (lastAuthUsername) {
    return lastAuthUsername;
  }

  let username = '';
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.match(/LastAuthUser/)) {
      username = localStorage.getItem(key);
    }
  }
  return username;
}

export function clearLastAuthUserName() {
  localStorage.removeItem('lastAuthUsername');
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key.match(/LastAuthUser/)) {
      localStorage.removeItem(key);
    }
  }
}
