/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/client-promo-codes.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum PromoCodeType {
  FreeTrial
}

export interface PromoCodes {
  [key: string]: { type: PromoCodeType; trialDays: number; mealPlans?: string[] };
}

export const promoCodes: PromoCodes = {
  mme60: {
    type: PromoCodeType.FreeTrial,
    trialDays: 60
  },
  mm60: {
    type: PromoCodeType.FreeTrial,
    trialDays: 60
  },
  flavor30: {
    type: PromoCodeType.FreeTrial,
    trialDays: 30,
    mealPlans: ['mp_kb1pj86er7186']
  }
};
