/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-plan/MealPlan.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Transform, Type } from 'class-transformer';
import {
  ArrayMinSize,
  IsArray,
  IsBoolean,
  IsNotEmpty,
  IsPositive,
  IsString,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { PlannerConfigNutrientLimits } from '../../planner/planner-configuration';
import { Default } from '../../utils/default.decorator';
import { BaseItem } from '../base-item';
import { Cobrand } from '../cobrand';
import { DistributorType } from '../distributor-type';
import { MealPlanOptions } from './meal-plan-options';
import { MealPlanMigration } from './meal-plan.migration';
import { MealPlanMeal } from './MealPlanMeal';
import { MealPlanState } from './MealPlanState';
import { MealPlanType } from './MealPlanType';
import { MealType } from './MealType';
import { DEFAULT_PDF_COVER_BACKGROUND_IMAGE } from '../../vars';

const defaultSignUpHeader = 'Free Trial Sign Up';
const defaultSignupButtonLabel = 'Sign Up For Your Free Trial';

@Exclude()
export class MealPlan implements BaseItem {
  @Expose()
  id: string;

  @Expose()
  @Default(Date.now())
  @IsPositive()
  createdAt: number = Date.now();

  @Expose()
  @Default(Date.now())
  @IsPositive()
  modifiedAt: number = Date.now();

  @Expose()
  @Default(false)
  @IsBoolean()
  isDeleted = false;

  @Expose()
  @Default(null)
  @IsString()
  @IsNotEmpty()
  name: string;

  @Expose()
  @Default(null)
  @IsString()
  @IsNotEmpty()
  vanityUrl: string;

  @Expose()
  @Default(null)
  @IsString()
  description: string;

  @Expose()
  @Default(null)
  @IsString()
  activateAccountEmail: string;

  @Expose()
  @Type(() => MealPlanMeal)
  @Default([])
  // @IsArray()  /// bugs out for some reason
  // @ArrayMinSize(1)
  @ValidateNested()
  meals: MealPlanMeal[] = [];

  @Expose()
  options: MealPlanOptions = new MealPlanOptions();

  @Expose()
  @Default(MealPlanState.Draft)
  // @Type(() => MealPlanState)
  // @IsEnum(MealPlanState)
  state: MealPlanState = MealPlanState.Draft;

  @Expose()
  @Default(MealPlanType.ROOT)
  // @IsEnum(MealPlanType)
  type: MealPlanType = MealPlanType.ROOT;

  @Expose()
  @Default(null)
  @ValidateIf((mp) => mp.type !== MealPlanType.ROOT)
  parentId?: string;

  @Expose()
  @Default(true)
  @IsBoolean()
  enableNutritionalData = true;

  @Expose()
  @Default(null)
  @ValidateIf((mp) => mp.cobrandId)
  @IsString()
  cobrandId?: string | null;

  @Expose()
  @ValidateIf((mp) => mp.cobrand)
  cobrand?: Cobrand;

  @Expose()
  @Type(() => MealPlanMigration)
  mealPlanMigration?: MealPlanMigration;

  @Expose()
  @Default('sc')
  availablePlanTypes = 'sc'; // s=static c=customizable

  @Expose()
  @Default(defaultSignupButtonLabel)
  signUpButtonLabel = defaultSignupButtonLabel;

  @Expose()
  @Default(defaultSignUpHeader)
  signUpHeader = defaultSignUpHeader;

  @Expose()
  @Default('')
  signUpSubheader = '';

  @Expose()
  @Default('')
  showRecipes = true;

  @Expose()
  @Default(6)
  repeatInterval = 6;

  @Expose()
  @Default('lprx')
  ownedBy: string = 'lprx';

  @Expose()
  @Default([])
  additionalNutrients: string[] = [];

  @Expose()
  eBookIntro?: string;

  @Expose()
  @Default(1)
  @Transform((value) => {
    value = parseInt(value, 10);
    return value && value >= 1 && value <= 14 ? value : 1;
  })
  initialWeeksToLoad = 1;

  @Expose()
  @Default([DistributorType.HealthCareProfessional])
  distributorTypes = [DistributorType.HealthCareProfessional];

  @Expose()
  @Default(new PlannerConfigNutrientLimits())
  @Transform((value: PlannerConfigNutrientLimits, obj, transformationType) => {
    const keysToNutrientName = {
      ENERC_KCAL: 'Calories',
      CHOCDF: 'Carbohydrates (g)',
      PROCNT: 'Protein (g)',
      FIBTG: 'Fiber (g)',
      SUGAR: 'Sugar (g)',
      NA: 'Sodium (mg)',
    };

    if (value?.daily) {
      for (const nutrientLimit of value.daily) {
        if (keysToNutrientName[nutrientLimit.key]) {
          nutrientLimit.nutrient = keysToNutrientName[nutrientLimit.key];
        }
      }
    }

    return value;
  })
  nutrientLimits = new PlannerConfigNutrientLimits();

  @Expose()
  @Transform((value, obj: MealPlan) =>
    obj.ownedBy && obj.ownedBy !== 'lprx' ? 'signature' : value
  )
  @Default('base')
  category = 'base';

  @Expose()
  @Default(null)
  pdfIntro?: string | null = null;

  @Expose()
  @Default(DEFAULT_PDF_COVER_BACKGROUND_IMAGE)
  pdfCoverImage: string = DEFAULT_PDF_COVER_BACKGROUND_IMAGE;

  @Expose()
  @Default(true)
  pdfCoverTitleBar: boolean = true;

  @Expose()
  // @Transform((value) => value === 'true' || value)
  nonCalendar?: boolean;

  get isActive() {
    return this.state === MealPlanState.Active;
  }

  get isDraft() {
    return this.state === MealPlanState.Draft;
  }

  get isTest() {
    return this.state === MealPlanState.Test;
  }

  get isPrivate() {
    return this.state === MealPlanState.Private;
  }

  get canBeCustomPlan() {
    return this.availablePlanTypes.match(/c/);
  }

  get canBeStaticPlan() {
    return this.availablePlanTypes.match(/s/);
  }

  public static createMealPlan() {
    const makeid = () => {
      let id = '';
      const possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 15; i++) {
        id += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return id;
    };

    const mealPlan = new MealPlan();

    mealPlan.enableNutritionalData = true;
    mealPlan.description = '';
    mealPlan.meals = [];
    mealPlan.state = MealPlanState.Draft;
    mealPlan.meals.push(new MealPlanMeal());
    mealPlan.meals.push(new MealPlanMeal());
    mealPlan.meals.push(new MealPlanMeal());
    mealPlan.meals.push(new MealPlanMeal());
    mealPlan.meals[0].id = makeid();
    mealPlan.meals[0].name = 'Breakfast';
    mealPlan.meals[0].type = MealType.Breakfast;
    mealPlan.meals[1].id = makeid();
    mealPlan.meals[1].name = 'Lunch';
    mealPlan.meals[1].type = MealType.Lunch;
    mealPlan.meals[2].id = makeid();
    mealPlan.meals[2].name = 'Dinner';
    mealPlan.meals[2].type = MealType.Dinner;
    mealPlan.meals[3].id = makeid();
    mealPlan.meals[3].name = 'Snack';
    mealPlan.meals[3].type = MealType.Snack;

    return mealPlan;
  }

  /**
   *
   * @param object
   * @returns {MealPlan}
   */
  public static fromObject(object: any) {
    const mealPlan = new MealPlan();
    const propertyNames = Object.getOwnPropertyNames(object);
    for (const property of propertyNames) {
      mealPlan[property] = object[property];
    }
    return mealPlan;
  }
}
