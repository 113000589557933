/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Element.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Type } from 'class-transformer';
import { defaultNutrients } from '../../default-nutrients';
import { NutrientsCollection } from '../../recipe/NutrientsCollection';
import { Default } from '../../utils/default.decorator';
import { ElementDirections } from './ElementDirections';
import { GroceryItem } from './grocery-item';
import { Ingredient } from './Ingredient';

@Exclude()
export class Element {
  @Expose()
  @Type(() => ElementDirections)
  directions: ElementDirections = new ElementDirections();

  @Expose()
  @Default(true)
  includeInNutritonalCalculation = true;

  @Expose()
  get includeInNutritionalCalculation() {
    return this.includeInNutritonalCalculation;
  }

  set includeInNutritionalCalculation(bool: boolean) {
    this.includeInNutritonalCalculation = bool;
  }

  @Expose()
  @Default([])
  @Type(() => Ingredient)
  ingredients: Ingredient[] = [];

  @Expose()
  name: string;

  static fromObject(object: any) {
    return plainToClass(Element, object);
  }

  getGroceries(): GroceryItem[] {
    const list: GroceryItem[] = [];
    this.ingredients.forEach(i => {
      // console.log({quantity: i.quantity, unit: i.unit, item: i.item});
      const _i: GroceryItem = {
        quantity: i.quantity,
        unit: i.unit,
        item: i.item,
        groceryCategory: i.groceryCategory
      };
      list.push(_i);
    });
    return list;
  }

  getNutrients(nutrients: Set<string> = defaultNutrients): NutrientsCollection {
    const nutrientSum: NutrientsCollection = {};
    for (const ingredient of this.ingredients) {
      if (ingredient && ingredient.nutrients) {
        for (const nutrient of Object.getOwnPropertyNames(ingredient.nutrients)) {
          if (nutrients && !nutrients.has(nutrient)) {
            continue;
          }
          const ingredientNutrients = ingredient.nutrients[nutrient];
          if (!nutrientSum[nutrient]) {
            nutrientSum[nutrient] = {
              value: 0,
              unit: ingredientNutrients.unit,
              isKnown: ingredientNutrients.isKnown
            };
          }
          nutrientSum[nutrient].value += ingredientNutrients.value;
        }
      }
    }
    return nutrientSum;
  }
}
