import { Injectable } from '@angular/core';
import { Api } from '../../../providers/aws.api';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { MasterWeeklyPlan } from '../../model/entities/weekly-plan/master-weekly-plan';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';

@Injectable()
export class AdminMasterMealPlanService {
  constructor(private api: Api, private readonly lprxApi: LprxApiProvider) {}

  saveWeeklyPlan(weeklyPlan: WeeklyPlan): any {
    console.log(`Saving WeeklyPlan(${weeklyPlan.id})`);
    return this.api.post('admin/master-plans/', weeklyPlan);
  }

  getMasterWeeklyPlan(mealPlanId: string, weekNumber: string): Promise<MasterWeeklyPlan> {
    const w = parseFloat(weekNumber).toFixed(2);
    return this.lprxApi.masterPlans.get(mealPlanId, weekNumber);
    // return this.api.get(`admin/master-plans/${mealPlanId}/${w}`);
  }
}
