<app-focused-form>
  <h1 class="text-center">Sorry!</h1>
  <br />
  <h5 class="text-center">You tried accessing the Library using a client account.</h5>
  <br />
  <p>Please log in using your Practitioner account or continue to the Meal Planner.</p>

  <ng-container *ngIf="partialEmail !== ''">
    <p>Your Practitioner account is associated with the email address: {{ partialEmail }}.</p>
  </ng-container>

  <br />

  <div class="text-center">
    <a href="javascript:" routerLink="/logout" btnPrimary class='tw-text-secondary'>Go to Login</a>
    <br />
    <br />
    <button (click)="toPlanner()" btnSecondary>Continue to Meal Planner</button>
  </div>
</app-focused-form>
