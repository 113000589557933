/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/meal-plan-pricing.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { IntervalPricing } from './distributor-meal-plan-pricing';

export class MealPlanPricing {
  stripeProductId?: string;
  monthly: IntervalPricing;
  yearly: IntervalPricing;
}
