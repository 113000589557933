import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { Brand } from '../../model/entities/brand';
import { CdnService } from '../../service/cdn.service';
import { HeaderService } from '../../service/header.service';
import { alertHttpError } from '../../utilities/alert-http-error';
import { ActivateAccountService } from '../activate-account.service';

@Component({
  selector: 'app-activate-account-password',
  ///////// USING THE SAME TEMPLATE ///////////
  templateUrl: '../activate-account.component.html',
  styleUrls: ['./activate-account-password.component.scss'],
})
export class ActivateAccountPasswordComponent implements OnInit, OnDestroy {
  password = '';
  passwordConfirm: string;
  email = '';
  username = '';
  sig = '';
  error: any;
  isPasswordValid = false;
  isPasswordLongEnough = false;
  doesPasswordHaveNumber = false;
  doesPasswordHaveLowercase = false;
  doesPasswordHaveUppercase = false;
  distributor: Distributor;
  logo: SafeUrl;
  termsAgreement = false;
  viewState = 'activate';

  loading = true;

  private subs: Subscription[] = [];
  user: User;
  magicLink: SafeUrl;

  constructor(
    private renderer: Renderer2,
    private header: HeaderService,
    private activateAccountService: ActivateAccountService,
    private route: ActivatedRoute,
    private cdn: CdnService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'bg-gray');
    this.header.hide();

    this.route.params
      .pipe(
        switchMap((params) => {
          const clientId = params['clientId'];
          const sig = params['sig'];

          return this.getActivationDetails(clientId, sig);
        }),
        alertHttpError(),
      )
      .subscribe(
        () => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  private async getActivationDetails(clientId: string, sig: string) {
    try {
      this.user = await this.activateAccountService.getClient(clientId, sig);
    } catch (e) {
      return this.router.navigateByUrl(
        '/login?warning=Your account is already activated. You can log in below.',
      );
    }
    this.username = this.user.username;
    this.email = this.user.email;
    this.sig = sig;

    const distributor = await this.activateAccountService.getDistributor(this.user.distributorId);

    this.distributor = distributor;
    // todo: i want to be able to send the Distributor to brand. a brand service
    const brand = new Brand(distributor.business, distributor.logo, distributor.vanityId);
    this.header.setBrand(brand);
    this.logo = this.cdn.getSafeUrl(distributor.logo);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
    this.renderer.removeClass(document.body, 'bg-gray');
    this.header.show();
  }

  validatePassword() {
    const p = this.password;
    this.isPasswordLongEnough = p.length >= 6;
    this.doesPasswordHaveLowercase = p.match(/[a-z]/) !== null;
    this.doesPasswordHaveUppercase = p.match(/[A-Z]/) !== null;
    this.doesPasswordHaveNumber = p.match(/[0-9]/) !== null;

    this.isPasswordValid =
      this.isPasswordLongEnough &&
      this.doesPasswordHaveUppercase &&
      this.doesPasswordHaveLowercase &&
      this.doesPasswordHaveNumber &&
      this.password === this.passwordConfirm;
    console.log(this.isPasswordValid);
  }

  async activate(event) {
    try {
      const setPassResponse = await this.activateAccountService.activateAccountPassword(
        this.username,
        this.sig,
        this.password,
      );
      this.viewState = 'success';
      this.magicLink = this.sanitizer.bypassSecurityTrustUrl(setPassResponse.magicLink);
    } catch (e) {
      this.error = e;
    }
  }
}
