import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';

// tslint:disable-next-line:interface-name
export interface MealHeight {
  weekNumber: string;
  mealId: string;
  height: number;
}

@Injectable()
export class PlannerLayoutService {
  private layoutSubject = new Subject<string>();
  private maxMealHeight = new BehaviorSubject<MealHeight>(null);
  private maxMealHeights: any = {};

  constructor() {}

  reportMealHeight(mealHeight: MealHeight) {
    const key = mealHeight.weekNumber + '-' + mealHeight.mealId;
    if (!this.maxMealHeights[key] || mealHeight.height > this.maxMealHeights[key]) {
      this.maxMealHeights[key] = mealHeight.height;
      const mh: MealHeight = {
        weekNumber: mealHeight.weekNumber,
        mealId: mealHeight.mealId,
        height: mealHeight.height
      };
      this.maxMealHeight.next(mh);
    }

    console.log(this.maxMealHeights);
  }

  changeLayout(layout: string) {
    this.layoutSubject.next(layout);
  }

  getLayout() {
    return this.layoutSubject.asObservable();
  }

  getMaxMealHeight() {
    return this.maxMealHeight.asObservable();
  }
}
