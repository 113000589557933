/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/dto/file-download-url.response.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class FileDownloadUrlResponse {
  url: string;
}
