/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/commands/finalize-resource-file-upload.command.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { FinalizeResourceFileUploadDto } from '../dto/finalize-resource-file-upload.dto';

export class FinalizeResourceFileUploadCommand {
  constructor(
    public readonly folderId: string,
    public readonly key: string,
    public readonly filename: string
  ) {}

  static fromDto(folderId: string, dto: FinalizeResourceFileUploadDto) {
    return new FinalizeResourceFileUploadCommand(folderId, dto.key, dto.filename);
  }
}
