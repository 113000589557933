import { plainToClass } from 'class-transformer';
import {
  ChangeClientMealPlanDto,
  PurgeFromWeek,
} from '../../../lprx-shared-lib/dto/change-client-meal-plan-dto';
import { ClientContactUpdateDto } from '../../../lprx-shared-lib/dto/client-contact-update-dto';
import { NutritionFactsDisplay } from '../../../lprx-shared-lib/entities/user/nutrition-facts-display';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { IngredientPreferences } from '../../../lprx-shared-lib/planner/ingredient.preferences';
import { PlannerConfigNutrientLimits } from '../../../lprx-shared-lib/planner/planner-configuration';
import { asUser } from '../../../lprx-shared-lib/utils/as-user';
import { LprxApi } from '../api';
import { ListResult } from '../../../lprx-shared-lib/utils/list-result';
import { ClientWithStatus } from '../../../lprx-shared-lib/utils/types';

export class Clients {
  constructor(private readonly lprxApi: LprxApi) {}

  async get(): Promise<User[]> {
    const clients = await this.lprxApi.get<User[]>(`v3/clients`);
    return plainToClass(User, clients);
  }

  async getClientsWithStatus(): Promise<ListResult<ClientWithStatus>> {
    const clients = await this.lprxApi.get<ListResult<ClientWithStatus>>(`v3/clients/statuses`);

    const items = clients.items.map((client) => ({
      ...client,
      client: asUser(client.client),
    }));

    return {
      ...clients,
      items,
    };
  }

  async getClient(username: string): Promise<User> {
    const user = await this.lprxApi.get(`v3/clients/${username}`);
    return asUser(user);
  }

  updateClientContactDetails(username: string, details: ClientContactUpdateDto) {
    return this.lprxApi.patch<User>(`v3/clients/${username}/contact`, details);
  }

  async changeNutritionFactsDisplay(
    username: string,
    nutritionFactsDisplay: NutritionFactsDisplay
  ): Promise<User> {
    const user = await this.lprxApi.patch(`v3/clients/${username}/nutrition-facts-display`, {
      nutritionFactsDisplay,
    });
    return asUser(user);
  }

  changeMealPlan(username: string, mealPlanId: string, purgeFromWeek: PurgeFromWeek) {
    const dto: ChangeClientMealPlanDto = { mealPlanId, purgeFromWeek };
    console.log(dto);
    return this.lprxApi.put(`v3/clients/${username}/meal-plan`, dto);
  }

  async activationStatus(username: string): Promise<'pending' | 'activated'> {
    return this.lprxApi.get<'pending' | 'activated'>(`v3/clients/${username}/activation-status`);
  }

  async updateClientNutrientLimits(username: string, nutrientLimits: PlannerConfigNutrientLimits) {
    return this.lprxApi.patch(`v3/clients/${username}/nutrient-limits`, nutrientLimits);
  }

  /**
   *
   * @param {string} username
   * @param {string[]} dislikeEntries
   * @return {Promise<IngredientPreferences>}
   */
  addClientIngredientDislikes(
    username: string,
    dislikeEntries: string[]
  ): Promise<IngredientPreferences> {
    return this.lprxApi.post<IngredientPreferences>(
      `v3/clients/${username}/ingredient-preferences/dislikes`,
      dislikeEntries
    );
  }

  /**
   *
   * @param {string} username
   * @param {string[]} dislikeEntry
   * @return {Promise<IngredientPreferences>}
   */
  removeClientIngredientsDislikes(
    username: string,
    dislikeEntry: string
  ): Promise<IngredientPreferences> {
    return this.lprxApi.delete<IngredientPreferences>(
      `v3/clients/${username}/ingredient-preferences/dislikes/${encodeURI(dislikeEntry)}`
    );
  }

  /**
   * Pause a client's account
   *
   * @param {string} username
   * @returns {Promise<User>}
   */
  pauseClientAccount(username: string): Promise<User> {
    return this.lprxApi.delete<User>(`v3/clients/${username}/pause`);
  }
}
