import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { stripRecipeDataFromWeeklyMealPlan } from '../../lprx-shared-lib/utils/strip-recipe-data';
import { ApiV2 } from '../api.v2';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';

@Injectable()
export class WeeklyPlansService {
  constructor(private apiV2: ApiV2) {}

  public save(weeklyPlan: WeeklyPlan) {
    const wp = stripRecipeDataFromWeeklyMealPlan(weeklyPlan);
    return this.apiV2
      .post$('weekly-plans/', wp)
      .pipe(map((weeklyPlanData: WeeklyPlan) => WeeklyPlan.fromObject(weeklyPlanData)));
  }
}
