import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UnitSystem } from '../../lprx-shared-lib/enum/unit-system';

@Injectable()
export class UnitSystemService {
  private readonly unitSystemSubject = new BehaviorSubject<UnitSystem>(this.get());
  public readonly unitSystem$ = this.unitSystemSubject.asObservable();

  get(): UnitSystem {
    const unitSystemName = localStorage.getItem('unitSystem') || 'imperial';

    return unitSystemName === 'metric' ? UnitSystem.Metric : UnitSystem.Imperial;
  }

  set(system: string | UnitSystem) {
    const unitSystemName = system === 'metric' ? UnitSystem.Metric : UnitSystem.Imperial;

    localStorage.setItem('unitSystem', unitSystemName);
    this.unitSystemSubject.next(unitSystemName);
  }
}
