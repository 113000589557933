import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';
import { PlannerFullscriptButtonService } from './planner-fullscript-button.service';

@Component({
  selector: 'app-planner-fullscript-button',
  templateUrl: './planner-fullscript-button.component.html',
  styleUrls: ['./planner-fullscript-button.component.scss']
})
export class PlannerFullscriptButtonComponent implements OnInit {
  @Input()
  client: User;

  @Input()
  user: User;

  hasPlans: boolean = false;

  constructor(
    private buttonService: PlannerFullscriptButtonService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const client = this.user.userType === UserType.Distributor ? this.client : this.user;
    this.buttonService.getTreatmentPlans(client).subscribe(treatmentPlans => {
      if (treatmentPlans) {
        for (const tp of treatmentPlans) {
          if (tp.state !== 'cancelled') {
            this.hasPlans = true;
            break;
          }
        }
      }
    });
  }
}
