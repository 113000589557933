import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MealLocation } from '../../../../lprx-shared-lib/entities/meal-location';
import { Note } from '../../../../lprx-shared-lib/entities/weeky-meal-plan/note';
import { AuthService } from '../../../auth.service';

@Component({
  selector: 'app-view-note-modal',
  templateUrl: './view-note-modal.component.html',
  styleUrls: ['./view-note-modal.component.scss']
})
export class ViewNoteModalComponent implements OnInit {
  @Input()
  note: Note;

  canEdit = false;
  addedByName: string;

  @Input()
  mealLocation: MealLocation;

  constructor(public readonly activeModal: NgbActiveModal, public authService: AuthService) {}

  ngOnInit() {
    this.authService.user$.subscribe(user => {
      if (this.note.addedBy.username === user.username) {
        this.canEdit = true;
      } else {
        this.addedByName = this.note.addedBy.name;
      }
    });
  }

  delete() {
    this.activeModal.close('delete');
  }
}
