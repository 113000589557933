import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdminLayoutService } from '../../admin/service/admin-layout.service';

@Component({
  selector: 'app-recipe-editor',
  templateUrl: './recipe-editor.component.html',
  styleUrls: ['./recipe-editor.component.scss']
})
export class RecipeEditorComponent implements OnInit, OnDestroy {
  constructor(private adminLayoutService: AdminLayoutService) {}

  ngOnInit() {
    this.adminLayoutService.setIsPlannerActive(true);
  }

  ngOnDestroy(): void {
    this.adminLayoutService.setIsPlannerActive(false);
  }
}
