import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { UnitSystem } from '../../lprx-shared-lib/enum/unit-system';
import { humanWeek } from '../../lprx-shared-lib/human-week';
import { convertNonCalWeekNumber } from '../../lprx-shared-lib/non-calendar-display';
import { PlannerConfiguration } from '../../lprx-shared-lib/planner/planner-configuration';
import { addWeek } from '../../lprx-shared-lib/utils/getCurrentWeekNumber';
import { unsubscribe } from '../../lprx-shared-lib/utils/unsubscribe';
import { nonCalendarStartWeek } from '../../lprx-shared-lib/vars';
import { PdfGeneratorService } from '../pdf-generator.service';
import { PlannerService } from '../planner/planner.service';
import { PdfDialogData } from './pdf-dialog-data';

@Component({
  selector: 'app-pdf-generator',
  templateUrl: './pdf-generator.component.html',
  styleUrls: ['./pdf-generator.component.scss'],
})
export class PdfGeneratorComponent implements OnInit, OnDestroy {
  potentialStartWeeks: Array<{ weekNumber: string; humanWeek: string }> = [];

  weeklyPlans: WeeklyPlan[];
  startWeek: string;

  numWeeks: number = 1;
  processing: boolean = false;
  pdfUrl?: string;

  includeCoverPage: boolean = true;
  includeIntroPage: boolean = true;
  includeCalendar: boolean = true;
  includeGroceries: boolean = true;
  includeRecipes: boolean = true;
  showDates: boolean = true;
  unitSystem: UnitSystem = UnitSystem.Imperial;
  UnitSystem = UnitSystem;
  includeNutrition: boolean = true;
  plannerConfig: PlannerConfiguration;

  private subs: Subscription[] = [];
  includePublicNotes: boolean = false;
  includePrivateNotes: boolean = false;

  hasIntroPage: boolean = false;

  constructor(
    private pdfService: PdfGeneratorService,
    @Inject(MAT_DIALOG_DATA) public data: PdfDialogData,

    private plannerService: PlannerService
  ) {
    const mealPlan = this.data.weeklyPlans[0].mealPlan;

    this.hasIntroPage = mealPlan.pdfIntro && mealPlan.pdfIntro.trim() !== '';

    if (mealPlan.nonCalendar) {
      this.potentialStartWeeks = [];

      let weekNumber = nonCalendarStartWeek;
      for (let i = 0; i < mealPlan.repeatInterval; i++) {
        this.potentialStartWeeks.push({ weekNumber, humanWeek: humanWeek(weekNumber) });
        weekNumber = addWeek(weekNumber);
      }
    } else {
      this.potentialStartWeeks = this.data.weeklyPlans.map((wp) => ({
        weekNumber: wp.weekNumber,
        humanWeek: humanWeek(wp.weekNumber, true),
      }));
    }

    this.startWeek = this.potentialStartWeeks[0].weekNumber;
  }

  ngOnInit(): void {
    console.log('ngOnInit', this.data);

    this.subs.push(this.plannerConfigSub());
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((config) => {
      this.plannerConfig = config;
    });
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  submit() {
    this.pdfUrl = undefined;
    this.processing = true;

    this.pdfService
      .generate({
        username: this.data.client?.username,
        mealPlanId: this.data.mealPlanId,
        startWeek: this.startWeek,
        numWeeks: this.numWeeks,
        includeRecipes: this.includeRecipes,
        includeGroceries: this.includeGroceries,
        includeCoverPage: this.includeCoverPage,
        includeCalendar: this.includeCalendar,
        includeIntroPage: this.includeIntroPage,
        unitSystem: this.unitSystem,
        showDates: this.showDates,
        includeNutrition: this.includeNutrition,
        includePublicNotes: this.includePublicNotes,
        includePrivateNotes: this.includePrivateNotes,
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.pdfUrl = res.url;
          this.processing = false;
          console.log(res);
        },
        error: () => {
          this.processing = false;
        },
      });
  }

  checkNumWeeks() {
    if (this.data.weeklyPlans[0].mealPlan.nonCalendar) {
      const maxWeeks = this.data.weeklyPlans[0].mealPlan.repeatInterval;
      const weekNum = convertNonCalWeekNumber(this.startWeek);
      if (this.numWeeks + weekNum > maxWeeks) {
        this.numWeeks = maxWeeks - weekNum + 1;
      }
    }
  }
}
