import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Injectable()
export class CdnService {
  constructor(private sanitizer: DomSanitizer) {}

  getSafeStyleUrl(url: string, width: number = null, height: number = null) {
    if (width !== null && height !== null) {
      url = this.getSizedImageUrl(url, width, height);
    } else {
      url = this.getUrl(url);
    }

    return this.sanitizer.bypassSecurityTrustStyle('url("' + url + '")');
  }

  getSafeUrl(url: string) {
    url = this.getUrl(url);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getUrl(url: string) {
    if (!url) {
      return '';
    }

    url = `${environment.files_cdn_base_url}/${url}`;
    return url;
  }

  getSizedImageUrl(url: string, width: number, height: number) {
    if (!url) {
      return '';
    }
    // url = url.replace(/(\.[a-z]{3})$/, `_${width}x${height}$1`);
    url = url.replace(/(\.[a-z]{3,4})$/i, `_${width}x${height}$1`).toLowerCase();
    return this.getUrl(url);
  }
}
