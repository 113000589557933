import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiV2 } from '../../../providers/api.v2';
import { DistributorMealPlan } from '../../../lprx-shared-lib/DistributorMealPlan';

@Injectable()
export class DistributorMealPlansService {
  constructor(private apiV2: ApiV2) {}

  /**
   * Get the Distributor's available meal plans
   */
  getMealPlans() {
    return this.apiV2.get$('distributor/meal-plans').pipe(
      map((results: DistributorMealPlan[]) =>
        results.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        })
      )
    );
  }
}
