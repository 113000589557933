import { plainToClass } from 'class-transformer';
import { DistributorApplication } from '../../../lprx-shared-lib/distributor-application';
import { DistributorApplicationApprovalOptions } from '../../../lprx-shared-lib/dto/distributor-application-approval-options';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { ListResult } from '../../../lprx-shared-lib/utils/list-result';
import { LprxApi } from '../api';

export class DistributorApplications {
  constructor(private readonly lprxApi: LprxApi) {}

  /**
   *
   * @param application
   */
  async apply(application: DistributorApplication): Promise<DistributorApplication> {
    return this.lprxApi
      .post(`v3/distributor-applications`, application)
      .then((res) => plainToClass(DistributorApplication, res));
  }

  /**
   *
   * @param id
   */
  async reject(id: string) {
    return this.lprxApi.delete(`v3/distributor-applications/${id}`);
  }

  /**
   *
   */
  async list(): Promise<ListResult<DistributorApplication>> {
    return this.lprxApi
      .get(`v3/distributor-applications`)
      .then((res: ListResult<DistributorApplication>) => {
        const items = plainToClass(DistributorApplication, res.items);
        return new ListResult<DistributorApplication>(items, res.next_key);
      });
  }

  /**
   *
   * @param id
   * @param options
   */
  async approve(id: string, options?: DistributorApplicationApprovalOptions): Promise<Distributor> {
    return this.lprxApi
      .post(`v3/distributor-applications/${id}/approve`, options)
      .then((d: object) => plainToClass(Distributor, d));
  }

  /**
   *
   * @param applicationId
   * @param distributorId
   */
  getOnboarding(applicationId: string, distributorId: string): Promise<Distributor> {
    return this.lprxApi
      .get(`v3/distributor-applications/${applicationId}/${distributorId}`)
      .then((res: object) => {
        for (const k of Object.getOwnPropertyNames(res)) {
          if (res[k] === '...') {
            res[k] = '';
          }
        }
        return res;
      })
      .then((res: Distributor) => plainToClass(Distributor, res));
  }
}
