import { Pipe, PipeTransform } from '@angular/core';
import { stripeCurrencies } from '../../lprx-shared-lib/currency';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {
  transform(value: string): unknown {
    return stripeCurrencies[value.toUpperCase()].symbol;
  }
}
