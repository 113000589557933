import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { AuthService } from '../../auth.service';
import { redirectToAuth } from '../../../lprx-auth';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';

@Injectable()
export class DistributorGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService._getUser();
    if (user.userType === 'distributor') {
      return true;
    }

    if (user.userType === UserType.Admin) {
      this.router.navigate(['/admin/dashboard']);
      return false;
    }

    if (user.userType === UserType.Client) {
      this.router.navigate(['/planner']);
      return false;
    }

    console.log('User is not a distributor, redirecting to login.', user);
    redirectToAuth({
      urlParams: {
        redirect: state.url,
      },
    });
  }
}
