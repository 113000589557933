import { HttpClient } from '@angular/common/http';
import { HttpOptions } from '../api.v3';
import { IRestHttpHandler } from './i-rest-http-handler';
import { authLogOut, getTokenResponse, refreshToken, TokenResponse } from '../../lprx-auth';
import { apiHostname } from './api-hostname';


export class HttpClientAdapter implements IRestHttpHandler {
  constructor(private readonly http: HttpClient, private tokenResponse?: TokenResponse) {}

  static isObject(obj: any) {
    return obj === Object(obj);
  }

  /**
   *
   * @param path
   */
  async delete(path: string): Promise<any> {
    const options = await this.httpOptions();
    const http$ = this.http.delete(`https://${apiHostname()}/${path}`, options);
    return http$.toPromise();
  }

  /**
   *
   * @param path
   * @param queryParams
   */
  async get(path: string, queryParams?: any): Promise<any> {
    const options = await this.httpOptions();

    let query = '';
    if (queryParams) {
      query =
        '?' +
        Object.keys(queryParams)
          .map((key) => {
            const value = queryParams[key];
            if (Array.isArray(value)) {
              return value.reduce(
                (acc, val, i) =>
                  acc +
                  (acc ? '&' : '') +
                  `${encodeURIComponent(key)}[${i}]=` +
                  `${encodeURIComponent(val)}`,
                ''
              );
            } else if (HttpClientAdapter.isObject(value)) {
              return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
            } else {
              return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
          })
          .join('&');
    }


    const url = `https://${apiHostname()}/${path}${query}`;
    const http$ = this.http.get(url, options);
    return http$.toPromise();
  }

  /**
   *
   * @param path
   * @param payload
   */
  async patch(path: string, payload: any): Promise<any> {
    const options = await this.httpOptions();
    const url = `https://${apiHostname()}/${path}`;
    const http$ = this.http.patch(url, payload, options);
    return http$.toPromise();
  }

  /**
   *
   * @param path
   * @param payload
   */
  async post(path: string, payload: any): Promise<any> {
    const options = await this.httpOptions();
    const url = `https://${apiHostname()}/${path}`;
    const http$ = this.http.post(url, payload, options);
    return http$.toPromise();
  }

  /**
   *
   * @param path
   * @param payload
   */
  async put(path: string, payload: any): Promise<any> {
    const options = await this.httpOptions();
    const url = `https://${apiHostname()}/${path}`;
    const http$ = this.http.put(url, payload, options);
    return http$.toPromise();
  }

  private async httpOptions() {
    let tokenResponse = getTokenResponse();

    const options: HttpOptions = {
      headers: {
        // 'x-aws-cognito-username': getLastAuthUsername(),
        Authorization: 'Bearer ' + tokenResponse.access_token,
      },
    };

    if (this.tokenResponse) {
      // if expired, refresh token
      if (Date.now() > tokenResponse.expires_at) {
        console.log('Token expired');
        try {
          this.tokenResponse = await refreshToken();
        } catch (e) {
          console.error('Error refreshing token', e);
          await authLogOut();
        }
      }

      if (this.tokenResponse) {
        options.headers['Authorization'] = `Bearer ${this.tokenResponse.access_token}`;
      }
    }

    return options;
  }
}
