import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planner-recipe-ingredients',
  templateUrl: './planner-recipe-ingredients.component.html',
  styleUrls: ['./planner-recipe-ingredients.component.scss']
})
export class PlannerRecipeIngredientsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
