import { Amplify } from '@aws-amplify/core';
import { environment } from './environments/environment';

export async function amplifyInit() {
  Amplify.configure({ region: 'us-east-1' });

  if (!environment.aws_exports) {
    const evnJson = await (await fetch(`${environment.api_base_url}v3/env.json`)).json();
    Object.assign(environment, evnJson);
  }

  /*
  Set the api endpoints for AWS amplify
   */
  ['appApi', 'v2', 'v3'].forEach((apiName) => {
    /**
     * Serverless API (v2) endpoint
     */
    environment.aws_exports.aws_cloud_logic_custom.push({
      id: 'r6fapjijed',
      name: apiName,
      description: '',
      endpoint: environment.api_base_url,
      region: 'us-east-1',
      service: 'execute-api',
      paths: ['/api', '/api/123'],
    });
  });

  environment.aws_exports.keyPrefix = false;
  Amplify.configure(environment.aws_exports);
  // AWS.config.correctClockSkew = true;
}
