import { createId } from './lprx-shared-lib/utils/id-generator';
import { CanActivateFn } from '@angular/router';
import { environment } from './environments/environment';
import { PractitionerUser, User } from './lprx-shared-lib/entities/user/user';
import { UserType } from './lprx-shared-lib/entities/user/UserType';
import { asClass } from './lprx-shared-lib/as-class';

export function oAuth2ApiUrl(path: string) {
  return `https://${environment.lprx_auth_api_domain}/` + path;
}

export function authSiteUrl(path: string) {
  return `https://${environment.lprx_auth_www_domain}/` + path;
}

export function oAuth2Endpoint(path: string) {
  return oAuth2ApiUrl(`oauth2/${path}`);
}

export function clientId() {
  return environment.lprx_auth_client_id;
}

export interface TokenResponse {
  access_token: string;
  id_token: string;
  refresh_token: string;
  expires_in: number;
  expires_at?: number;
}

async function generateCodeVerifier(): Promise<string> {
  const array = new Uint8Array(32);
  window.crypto.getRandomValues(array);
  return Array.from(array)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
}

async function sha256(plain: string): Promise<ArrayBuffer> {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}

async function base64UrlEncode(arrayBuffer: ArrayBuffer): Promise<string> {
  return btoa(String.fromCharCode(...new Uint8Array(arrayBuffer)))
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
}

async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const hashed = await sha256(codeVerifier);
  return await base64UrlEncode(hashed);
}

let refreshInterval: any;
let stateKey = '_state_';

export function saveTokenResponse(tokenResponse: TokenResponse) {
  // minuses 4 seconds to be safe
  tokenResponse.expires_at = Date.now() + tokenResponse.expires_in * 1000 - 4000;
  // to window.TOKEN_RESPONSE
  localStorage.setItem('LPRX_TOKEN_RESPONSE', JSON.stringify(tokenResponse));

  if (refreshInterval) {
    clearInterval(refreshInterval);
  }
  refreshInterval = setInterval(refreshToken, 4 * 60 * 1000); // N minutes
}

let redirectingToAuth = false;

export function getTokenResponse(redirect = false): TokenResponse | undefined {
  if (localStorage.getItem('LPRX_TOKEN_RESPONSE')) {
    return JSON.parse(localStorage.getItem('LPRX_TOKEN_RESPONSE'));
  } else {
    if (!redirect) {
      return {
        access_token: '',
        id_token: '',
        refresh_token: '',
        expires_in: 0,
        expires_at: 0,
      };
    }

    redirectingToAuth = true;

    console.log('No token response found');
    alert('No token response found');

    let redirectUri = window.location.href;

    // strip protocol and domain, only use path and query
    const url = new URL(redirectUri);
    redirectUri = url.pathname;
    if (url.search) {
      redirectUri += url.search;
    }

    redirectToAuth({
      urlParams: {
        redirect: redirectUri,
      },
    });
  }
}

/**
 * Revoke the token by making a POST request to the revoke endpoint.
 * This method assumes that the `getTokenResponse` function is defined elsewhere and returns a valid token response object.
 * If the token response is present, the method will create the request body and send a POST request to the revoke endpoint.
 *
 * @return {Promise<void>} A promise that resolves when the token is successfully revoked.
 */
async function revokeToken(): Promise<void> {
  const tokenResponse = getTokenResponse();
  if (!tokenResponse) {
    return;
  }
  const url = oAuth2Endpoint('revoke');
  const body = new URLSearchParams({
    token: tokenResponse.refresh_token,
    client_id: clientId(),
  });
  await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  });
}

// @ts-ignore
window.revokeToken = revokeToken;

export async function authLogOut() {
  // revoke the token

  const user = getUserDetails();
  try {
    await revokeToken();
  } catch (e) {
    console.error('Error revoking token', e);
  }

  localStorage.removeItem('LPRX_TOKEN_RESPONSE');
  localStorage.removeItem('userDetails');

  console.log('Logged out', user);
  if (user?.userType === UserType.Client) {
    window.location.href = authSiteUrl('login?_pract_=' + user.distributorId);
  } else {
    window.location.href = authSiteUrl('login');
  }
}

export const logoutFn: CanActivateFn = async () => {
  await authLogOut();
  return false;
};

const isLoggedInCache: { ttl: number; value: boolean } = {
  ttl: 0,
  value: false,
};

export async function isLoggedIn() {
  // check cache
  if (isLoggedInCache.ttl > Date.now()) {
    return isLoggedInCache.value;
  }

  let _isLoggedIn = false;
  try {
    const userDetails = await userInfo();
    console.log({ userDetails });
    if (!userDetails.error) {
      _isLoggedIn = true;
    }
  } catch (e) {
    // ignore...assume not logged in
    console.error('Error checking if logged in', e);
  }

  // cache
  isLoggedInCache.value = _isLoggedIn;
  isLoggedInCache.ttl = Date.now() + 30 * 1000;

  return _isLoggedIn;
}

/**
 * Get a claim from the access token
 * @param claimKey
 */
export function getClaimFromAccessToken(claimKey: string) {
  const tokenResponse = getTokenResponse();
  const access_token = tokenResponse?.access_token; // base64 encoded token
  // decode token
  const decodedTokenPayload = JSON.parse(atob(access_token.split('.')[1]));
  return decodedTokenPayload[claimKey];
}

export async function userInfo() {
  const tokenResponse = getTokenResponse();

  const url = oAuth2Endpoint('userinfo');
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${tokenResponse?.access_token}`,
    },
  });

  return await response.json();
}

// @ts-ignore
window.userInfo = userInfo;

export async function refreshToken() {
  const tokenResponse = getTokenResponse();

  if (!tokenResponse?.refresh_token) {
    return;
  }

  const url = oAuth2Endpoint('token');
  const body = new URLSearchParams({
    grant_type: 'refresh_token',
    client_id: clientId(),
    refresh_token: tokenResponse.refresh_token,
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  });

  const newTokenResponse = await response.json();
  saveTokenResponse(newTokenResponse);
  return newTokenResponse;
}

// @ts-ignore
window.refreshToken = refreshToken;

export interface RedirectAuthOptions {
  urlParams?: {
    [key: string]: string;
  };
}

export async function redirectToAuth(redirectAuthOptions?: RedirectAuthOptions) {
  const urlParams = new URLSearchParams();
  urlParams.append('response_type', 'code');
  urlParams.append('client_id', clientId());
  urlParams.append('redirect_uri', `https://${window.location.hostname}/login`);

  let state = createId('state');
  urlParams.append('state', state);
  localStorage.setItem(stateKey, state);

  // PKCE

  // Generate Code Verifier
  const codeVerifier = await generateCodeVerifier();
  localStorage.setItem('_', codeVerifier);

  // Generate Code Challenge
  const codeChallenge = await generateCodeChallenge(codeVerifier);
  urlParams.append('code_challenge', await generateCodeChallenge(codeVerifier));

  // PKCE code_challenge_method
  urlParams.append('code_challenge_method', 'S256');

  if (redirectAuthOptions?.urlParams) {
    for (const key in redirectAuthOptions.urlParams) {
      if (Object.prototype.hasOwnProperty.call(redirectAuthOptions.urlParams, key)) {
        const value = redirectAuthOptions.urlParams[key];
        urlParams.append(key, value);
      }
    }
  }

  window.location.href = oAuth2Endpoint(`authorize?${urlParams.toString()}`);
}

export async function handleAuthorizationCode() {
  let codeVerifier = localStorage.getItem('_');

  if (!codeVerifier) {
    await redirectToAuth();
    return;
  }

  // get the code and state
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state'); // TODO: validate state

  if (state !== localStorage.getItem(stateKey)) {
    console.error('Invalid state');
    await redirectToAuth();
    return;
  }

  const response = fetch(oAuth2Endpoint('token'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: clientId(),
      code: code,
      code_verifier: codeVerifier,
    }),
  });

  // remove the code verifier
  localStorage.removeItem('_');
  const tokenResponse = await (await response).json();
  saveTokenResponse(tokenResponse);
  // alert('We are logged in now');
}

export function getUserDetails(): User {
  return localStorage.getItem('userDetails')
    ? JSON.parse(localStorage.getItem('userDetails'))
    : null;
}

export function getCurrentPractitionerUser(): PractitionerUser {
  const user = getUserDetails();
  if (user.userType === UserType.Distributor) {
    return asClass(PractitionerUser, user);
  }
  return null;
}
