import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'epoch'
})
export class EpochPipe implements PipeTransform {
  transform(timestamp: number, format = 'YYYY-MM-DD', gmtOffset = false): any {
    if (gmtOffset) {
      const timezoneOffset = new Date().getTimezoneOffset();
      timestamp += timezoneOffset * 60 * 1000;
    }

    return moment(timestamp).format(format);
  }
}
