import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { Brand } from '../model/entities/brand';
import { BundleClient } from '../../lprx-shared-lib/entities/bundle-client';
import { CdnService } from '../service/cdn.service';
import { HeaderService } from '../service/header.service';
import { alertHttpError } from '../utilities/alert-http-error';
import { ActivateAccountService } from './activate-account.service';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
})
export class ActivateAccountComponent implements OnInit, OnDestroy {
  password = '';
  passwordConfirm: string;
  email = '';
  username = '';
  error: any;
  isPasswordValid = false;
  isPasswordLongEnough = false;
  doesPasswordHaveNumber = false;
  doesPasswordHaveLowercase = false;
  doesPasswordHaveUppercase = false;
  bundleClient: BundleClient;
  distributor: Distributor;
  logo: SafeUrl;
  termsAgreement = false;
  viewState = 'activate';

  loading = true;

  private subs: Subscription[] = [];

  magicLink: SafeUrl;

  constructor(
    private renderer: Renderer2,
    private header: HeaderService,
    private activateAccountService: ActivateAccountService,
    private route: ActivatedRoute,
    private cdn: CdnService,
    private router: Router,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'bg-gray');
    this.header.hide();

    this.route.params
      .pipe(
        switchMap((params) => {
          const clientId = params['clientId'];
          const bundleId = params['bundleId'];

          return this.getActivationDetails(clientId, bundleId);
        }),
        alertHttpError(),
      )
      .subscribe(
        () => {
          this.loading = false;
        },
        () => {
          this.loading = false;
        },
      );
  }

  private async getActivationDetails(clientId, bundleId) {
    this.bundleClient = await this.activateAccountService.getBundleClient(clientId, bundleId);
    this.email = this.bundleClient.email;
    const distributor = await this.activateAccountService.getDistributor(
      this.bundleClient.distributorId,
    );

    if (this.bundleClient.status !== 'pending') {
      return this.router.navigateByUrl(
        `${distributor.vanityId}/login?warning=Your account is already activated. You can log in below.`,
      );
    }

    this.distributor = distributor;
    // todo: i want to be able to send the Distributor to brand. a brand service
    const brand = new Brand(distributor.business, distributor.logo, distributor.vanityId);
    this.header.setBrand(brand);
    this.logo = this.cdn.getSafeUrl(distributor.logo);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
    this.renderer.removeClass(document.body, 'bg-gray');
    this.header.show();
  }

  validatePassword() {
    const p = this.password;
    this.isPasswordLongEnough = p.length >= 6;
    this.doesPasswordHaveLowercase = p.match(/[a-z]/) !== null;
    this.doesPasswordHaveUppercase = p.match(/[A-Z]/) !== null;
    this.doesPasswordHaveNumber = p.match(/[0-9]/) !== null;

    this.isPasswordValid =
      this.isPasswordLongEnough &&
      this.doesPasswordHaveUppercase &&
      this.doesPasswordHaveLowercase &&
      this.doesPasswordHaveNumber &&
      this.password === this.passwordConfirm;
    console.log(this.isPasswordValid);
  }

  async activate(event) {
    try {
      const activateRes = await this.activateAccountService.activateAccount(
        this.bundleClient,
        this.password,
      );
      this.magicLink = this.sanitizer.bypassSecurityTrustUrl(activateRes.magicLink);
      this.viewState = 'success';
    } catch (e) {
      this.error = e;
    }
  }
}
