import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { USDAFactsResults } from '../../../lprx-shared-lib/recipe/USDAFactsResults';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';

import { nutrientTypes, nutrientTypesMap } from '../../nutrition-facts-label/nutrientTypes';
import { getNutrient } from '../../utilities/get-nutrient';
import { PlannerService } from '../planner.service';
import { WeeklyPlanUpdatedEvent } from '../weekly-plan-updated-event';

@Component({
  selector: 'app-nutrition-summary',
  templateUrl: './nutrition-summary.component.html',
  styleUrls: ['./nutrition-summary.component.scss']
})
export class NutritionSummaryComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  @Input()
  USDAFacts: USDAFactsResults;

  @Input()
  dayName: string;
  @Input()
  weekNumber: string;

  calories: number = 0;
  fat: number = 0;
  protein: number = 0;
  carbs: number = 0;

  carbRatio: number = 0;
  proteinRatio: number = 0;
  fatRatio: number = 0;

  additionalNutrients: any[];
  tcal449: number;
  fatWidth: SafeStyle;
  proteinWidth: SafeStyle;
  carbWidth: SafeStyle;

  nutrientTypes = nutrientTypes;

  constructor(
    private readonly plannerService: PlannerService,
    private readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    const plannerConfigSub = this.plannerService.plannerConfig$.subscribe(config => {
      this.additionalNutrients = config.additionalNutrients;
    });
    this.subs.push(plannerConfigSub);

    const weeklyPlanUpdatedSub = this.plannerService.plannerUpdated$.subscribe(
      (event: WeeklyPlanUpdatedEvent) => {
        if (event.weekNumber === this.weekNumber) {
          this.calculate();
        }
      }
    );
    this.subs.push(weeklyPlanUpdatedSub);

    this.calculate();
  }

  private calculate() {
    setTimeout(() => {
      this.calories = Math.round(getNutrient(this.USDAFacts, 'ENERC_KCAL').value);

      this.fat = Math.round(getNutrient(this.USDAFacts, 'FAT').value);
      this.carbs = Math.round(getNutrient(this.USDAFacts, 'CHOCDF').value);
      this.protein = Math.round(getNutrient(this.USDAFacts, 'PROCNT').value);

      this.tcal449 = (this.carbs + this.protein) * 4 + this.fat * 9;

      if (this.tcal449 > 0) {
        this.fatRatio = ((this.fat * 9) / this.tcal449) * 100;
        this.proteinRatio = ((this.protein * 4) / this.tcal449) * 100;
        this.carbRatio = ((this.carbs * 4) / this.tcal449) * 100;
        const largestRatio = Math.max(this.fatRatio, this.proteinRatio, this.carbRatio);

        this.fatWidth = this.domSanitizer.bypassSecurityTrustStyle(
          (this.fatRatio / largestRatio) * 100 + '%'
        );
        this.proteinWidth = this.domSanitizer.bypassSecurityTrustStyle(
          (this.proteinRatio / largestRatio) * 100 + '%'
        );

        this.carbWidth = this.domSanitizer.bypassSecurityTrustStyle(
          (this.carbRatio / largestRatio) * 100 + '%'
        );
      } else {
        this.fatRatio = 0;
        this.fatWidth = this.domSanitizer.bypassSecurityTrustStyle('0%');
        this.proteinRatio = 0;
        this.proteinWidth = this.domSanitizer.bypassSecurityTrustStyle('0%');
        this.carbRatio = 0;
        this.carbWidth = this.domSanitizer.bypassSecurityTrustStyle('0%');
      }
    }, 10);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  nutrientName(type: string) {
    const nutrient = getNutrient(this.USDAFacts, type);
    return nutrientTypesMap.get(type).name.replace(/,.*/, '');
  }

  nutrientValue(type: string) {
    const nutrient = getNutrient(this.USDAFacts, type);
    return Math.round(nutrient.value) + nutrient.unit;
  }
}
