import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UpdatePaymentMethodComponent } from './account/update-payment-method/update-payment-method.component';
import { ActivateAccountPasswordComponent } from './activate-account/activate-account-password/activate-account-password.component';
import { ActivateAccountComponent } from './activate-account/activate-account.component';
import { ForgotPasswordCodeComponent } from './auth/forgot-password-code/forgot-password-code.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { PendingSetupComponent } from './auth/pending-setup/pending-setup.component';
import { BuyPageComponent } from './buy/buy-page/buy-page.component';
import { ActiveDistributorGuard } from './distributor/guard/active-distributor.guard';
import { GetPrimeComponent } from './get-prime/get-prime.component';
import { HomeComponent } from './home/home.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LoginComponent } from './login/login.component';
import { MagicLinkComponent } from './magic-link/magic-link.component';
import { PrivacyComponent } from './page/privacy/privacy.component';
import { TermsComponent } from './page/terms/terms.component';
import { RecipeEditorComponent } from './recipe/recipe-editor/recipe-editor.component';
import { RecipeViewerComponent } from './recipe/recipe-viewer/recipe-viewer.component';
import { ConfirmComponent } from './signup/confirm/confirm.component';
import { SignupComponent } from './signup/signup.component';
import {
  AdminGuard,
  AuthGuard,
  DistributorGuard,
  PlannerGuard,
  UserGuard,
} from './utilities/guards';
import { VerifyEmailChangeComponent } from './verify-email-change/verify-email-change.component';
import { logoutFn } from '../lprx-auth';
import { MealPlannerSetupComponent } from './meal-planner-setup/meal-planner-setup.component';
import { HomeAccessIssueComponent } from './home-access-issue/home-access-issue.component';
import {
  VioscreenLandingPageComponent
} from './voiscreen/vioscreen-landing-page/vioscreen-landing-page.component';
import { VioscreenFinishedComponent } from './voiscreen/vioscreen-finished/vioscreen-finished.component';
import {
  VioscreenNotFinishedComponent
} from './voiscreen/vioscreen-not-finished/vioscreen-not-finished.component';

// rdn/ai/signup

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'login', component: LoginComponent, pathMatch: 'full' },
  { path: 'logout', canActivate: [logoutFn], component: LoginComponent },
  { path: 'login/:username', component: LoginComponent, pathMatch: 'full' },
  { path: 'flavis', pathMatch: 'full', redirectTo: '/flavis/flavis/signup' },
  {
    path: 'home-access-issue',
    component: HomeAccessIssueComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pending-setup',
    component: PendingSetupComponent,
  },
  {
    path: ':distributorVanityId/ffq',
    component: VioscreenLandingPageComponent,
  },
  {
    path: ':distributorVanityId/ffq/finished',
    component: VioscreenFinishedComponent,
  },
  {
    path: ':distributorVanityId/ffq/not-finished',
    component: VioscreenNotFinishedComponent,
  },
  {
    path: ':distributorVanityId/login',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: ':distributorVanityId/:mealPlanVanityId/signup',
    component: LandingPageComponent,
  },
  {
    path: 'mealplanner/setup',
    component: MealPlannerSetupComponent,
  },
  {
    path: 'signup',
    children: [
      { path: '', component: SignupComponent, pathMatch: 'full' },
      {
        path: 'confirm/:username',
        component: ConfirmComponent,
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'magic-link',
    component: MagicLinkComponent,
  },
  {
    path: 'forgot-password/code/:encodedParams',
    component: ForgotPasswordCodeComponent,
  },
  {
    path: 'update-payment-method',
    component: UpdatePaymentMethodComponent,
    canActivate: [AuthGuard],
  },
  // Admin Routes
  {
    path: 'admin',
    // component: AdminComponent,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [AuthGuard, AdminGuard],
  },
  // Print
  {
    path: 'print',
    loadChildren: () => import('./print/print.module').then((m) => m.PrintModule),
    canActivate: [AuthGuard],
  },
  // Recipe
  {
    path: 'recipe',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'edit/:recipeId',
        pathMatch: 'full',
        component: RecipeEditorComponent,
        canActivate: [ActiveDistributorGuard],
      },
      {
        path: 'edit',
        pathMatch: 'full',
        component: RecipeEditorComponent,
        canActivate: [ActiveDistributorGuard],
      },
      {
        path: ':recipeId',
        pathMatch: 'full',
        component: RecipeViewerComponent,
      },
    ],
  },
  {
    path: 'oauth',
    loadChildren: () => import('./oauth/oauth.module').then((m) => m.OauthModule),
  },
  // {
  //     path: 'recipe/:recipeId',
  //     component: RecipeViewerComponent,
  //     canActivate: [AuthGuard],
  // },
  // Distributor Onboarding
  {
    path: 'join',
    loadChildren: () => import('./join/join.module').then((m) => m.JoinModule),
  },
  {
    path: 'distributor',
    loadChildren: () => import('./distributor/distributor.module').then((m) => m.DistributorModule),
    canActivate: [AuthGuard, DistributorGuard],
  },
  // User Onboarding
  {
    path: 'activate/account/:clientId/:sig',
    component: ActivateAccountPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'activate/:clientId/:bundleId',
    component: ActivateAccountComponent,
  },
  {
    path: 'planner',
    loadChildren: () => import('./planner/planner.module').then((m) => m.PlannerModule),
    canActivate: [AuthGuard, PlannerGuard],
  },
  {
    path: 'buy',
    component: BuyPageComponent,
  },
  // My Account for Users
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard, UserGuard],
  },
  { path: 'verify-email-change/:key', component: VerifyEmailChangeComponent },
  // the lawyers
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'prime', component: GetPrimeComponent },
  { path: 'get-prime', component: GetPrimeComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
