<div
  class="day"
  #dayContainer
  (mouseenter)="mouseEnter()"
  (touchstart)="mouseEnter()"
  [class.mouseover]="isMouseOver"
  [class.isToday]="isToday"
  style="position: relative"
>
  <div class="day-number">
    <span class="d-none day-name">{{ dayName }}&nbsp;</span>

    <ng-container *ngIf="plannerConfig.showNutritionFacts && nutritionFactWarnings.length">
      <mat-icon
        color="warn"
        class="nut-facts-warning"
        style="cursor: pointer"
        [ngbPopover]="nutFactsWarnings"
        triggers="mouseenter:mouseleave"
        >report_problem</mat-icon
      >
      <span>&nbsp;</span>
    </ng-container>

      <span *ngIf="mealPlan.nonCalendar" class='non-calendar-day-name'>{{ dayName }}</span>
      <ng-container *ngIf="!mealPlan.nonCalendar">{{ dateNumber }}</ng-container>
  </div>

  <div>
    <app-nutrition-summary
      *ngIf="plannerConfig.showNutritionFacts"
      [dayName]="dayName"
      [weekNumber]="weekNumber"
      [USDAFacts]="usdaFacts"
    >
    </app-nutrition-summary>
  </div>

  <app-planner-meal
    *ngFor="let meal of mealPlan.meals"
    [dayName]="dayName"
    [meal]="day.hasMeal(meal.id) ? day.getMeal(meal.id) : day.addMeal(meal)"
    [weekNumber]="weekNumber"
    [attr.data-week-meal]="weekNumber + '-' + meal.id"
  >
  </app-planner-meal>

  <div class="recipe-dropper" *ngIf="isDraggingCard">
    <div
      *ngFor="let meal of mealPlan.meals"

      id="{{ weekNumber }}-{{ dayName }}-{{ meal.id }}"
      class="drop-area"
    >
      <div class="meal-name">{{ meal.name }}</div>
    </div>
    <div class="no-drop"></div>
  </div>
</div>

<ng-template #nutFactsWarnings>
  <div class="text-center">
    <div *ngFor="let warn of nutritionFactWarnings" [innerHTML]="warn"></div>
  </div>
</ng-template>
