<div cdkDropList (cdkDropListDropped)="drop($event)">
    <div cdkDrag *ngFor="let step of steps" class="row">
        <div class="col-11">
            <textarea class="form-control" [(ngModel)]="step.direction">{{step.direction}}</textarea>
        </div>
        <div class="col-1">
        <div>
            <i class="material-icons step-drag-handle mover" title="Move" cdkDragHandle>drag_handle</i>
            <br>
            <i (click)="removeStep(step)" class="material-icons text-danger clickable" title="Remove step">close</i>
        </div>
        </div>
    </div>
</div>


<p>
    <button type="button" class="btn btn-link" (click)="addStep() ">+ Add Step</button>
</p>
