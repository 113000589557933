import { Injectable } from '@angular/core';
import { from } from 'rxjs/observable/from';
import { CreatePdfRequest } from '../lprx-shared-lib/create-pdf-request';
import { LprxApiProvider } from '../providers/lprx-api/api-provider';

@Injectable({
  providedIn: 'root',
})
export class PdfGeneratorService {
  constructor(private lprxApi: LprxApiProvider) {}

  generate(params: CreatePdfRequest) {
    if (params.username) {
      return from(this.lprxApi.get('v3/pdf/client', params));
    } else {
      return from(this.lprxApi.get('v3/pdf/master', params));
    }
  }
}
