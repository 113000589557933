import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from as fromPromise, Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MealLocation } from '../../../lprx-shared-lib/entities/meal-location';
import { Note } from '../../../lprx-shared-lib/entities/weeky-meal-plan/note';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { AuthService } from '../../auth.service';
import { isTablet } from '../../is-tablet';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { CardType } from '../../model/enum/card-type';
import { CdnService } from '../../service/cdn.service';
import { PlannerLayoutService } from '../planner-layout.service';
import { NoteModalService } from '../planner-meal/note-modal.service';
import { PlannerPrinterService } from '../planner-printer.service';
import { PlannerService } from '../planner.service';

@Component({
  selector: 'app-planner-card',
  templateUrl: './planner-card.component.html',
  styleUrls: ['./planner-card.component.scss'],
})
export class PlannerCardComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() card: Card;

  @Input() weekNumber: string;
  @Input() dayName: string;
  @Input() mealName: string;

  cardImage: string;
  width: string;
  private subs: Subscription[] = [];

  @Output() openCard = new EventEmitter<Card>();
  @Output() removeCard = new EventEmitter<Card>();

  isRecipe: boolean;
  showReadMore: boolean = false;
  mealLocation: MealLocation;
  showNote: boolean = false;

  canDelete = true;

  ingredientAlert: string[] = [];

  isTablet = isTablet();
  title = '';
  showRemove: boolean = false;

  constructor(
    private plannerService: PlannerService,
    private plannerLayout: PlannerLayoutService,
    private plannerPrinter: PlannerPrinterService,
    private cdn: CdnService,
    private renderer: Renderer2,
    private el: ElementRef,
    private modalService: NgbModal,
    private noteModalService: NoteModalService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.title = (this.card.isLeftover ? 'Leftover ' : '') + this.card.recipe?.title;

    this.isRecipe = this.card.type === CardType.Recipe && !!this.card.recipe;
    this.mealLocation = new MealLocation(this.weekNumber, this.dayName, this.mealName);

    this.subs.push(this.plannerConfigSub(), this.getUserSub(), this.ingredientAlerts());

    if (this.isRecipe) {
      this.cardImage = this.cdn.getSizedImageUrl(this.card.recipe.image, 400, 300);
      // const that = this;

      const layoutSub = this.plannerLayout.getLayout().subscribe(() => {
        setTimeout(() => {
          this.sizeImage();
        }, 1000);
      });
      this.subs.push(layoutSub);

      setTimeout(() => {
        this.sizeImage();
      }, 1000);
    }
  }

  private getUserSub() {
    return this.authService.user$.subscribe((user) => {
      if (this.card.isNote()) {
        const note = this.card.note;
        this.showNote = note.isPrivate ? note.ownedBy === user.username : true;
      }
    });
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((config) => {
      // console.log(config);
      this.canDelete = config.canEditMeals;
    });
  }

  private ingredientAlerts() {
    return this.plannerService.plannerConfig$.subscribe((config) => {
      this.ingredientAlert = [];

      if (this.card.type !== CardType.Recipe) {
        return;
      }

      const ingredientNames = this.card.getRecipe().getIngredientNames().join(' | ');

      // console.log('ingredientNames', ingredientNames);
      // console.log('config.ingredientPreferences', config.ingredientPreferences);

      for (const ingredient of config.ingredientPreferences.dislikes) {
        if (ingredientNames.match(new RegExp(ingredient, 'i'))) {
          this.ingredientAlert.push(ingredient);
        }
      }
    });
  }

  removeThisCard($event?) {
    if ($event) {
      $event.stopPropagation();
    }
    if (
      this.card.type === CardType.Recipe ||
      confirm('Are you sure you want to remove this note?')
    ) {
      this.removeCard.emit(this.card);
    }
  }

  viewRecipe() {
    this.plannerService.openCard(this.card, this.el.nativeElement.id);
  }

  sizeImage() {
    const cardImage = this.el.nativeElement.querySelector('.card-image img');
    cardImage.setAttribute('style', `max-width: 100%; max-height: 100%;`);
    // background-image: url('${this.cardImage}');
    this.width = '';

    const cardImageWrapper = this.el.nativeElement.querySelector('.card-image');
    const containerWidth = cardImageWrapper.clientWidth;
    const containerHeight = cardImageWrapper.clientHeight;
    const imgWidth = cardImage.clientWidth;
    const imgHeight = cardImage.clientHeight;

    let ratio: number;
    let newImageWidth: number;
    let newImageHeight: number;
    let offsetX = 0;
    let offsetY = 0;

    if (imgWidth < containerWidth) {
      ratio = containerWidth / imgWidth;
      newImageWidth = containerWidth;
      newImageHeight = imgHeight * ratio;
      offsetY = (containerHeight - newImageHeight) / 2;
    }

    if (imgHeight < containerHeight) {
      ratio = containerHeight / imgHeight;
      newImageHeight = containerHeight;
      newImageWidth = imgWidth * ratio;
      offsetX = (containerWidth - newImageWidth) / 2;
    }

    this.width = containerWidth + 'px';

    const imgStyle =
      `top: ${offsetY}px; left: ${offsetX}px; ` +
      ` width: ${newImageWidth}px; height: ${newImageHeight}px; ` +
      ` max-width: none; max-height: none;`;

    cardImage.setAttribute('style', imgStyle);
  }

  openNote() {
    const modal = this.noteModalService.openView(this.card.note, this.mealLocation);
    // modal.componentInstance.note = this.card.note;
    // modal.componentInstance.mealLocation = this.mealLocation;
    const modalResult$ = fromPromise(modal.result);

    modalResult$.pipe(filter((reason) => reason === 'delete')).subscribe(() => {
      this.removeThisCard();
    });

    modalResult$
      .pipe(
        filter((reason) => reason === 'edit'),
        switchMap(() => {
          const editModal = this.noteModalService.openCreateOrEdit(
            this.mealLocation,
            this.card.note
          );
          return fromPromise(editModal.result);
        })
      )
      .subscribe((note: Note) => {
        this.initShowReadMore();
        this.card.note = note;
        console.log('PlannerCardComponent');
        this.plannerService.weeklyPlanUpdated(
          this.weekNumber,
          this.dayName,
          this.mealName,
          this.card
        );
      });
  }

  ngAfterViewInit(): void {
    this.initShowReadMore();
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  private initShowReadMore() {
    if (this.card.isNote()) {
      setTimeout(() => {
        const noteContentElement = this.el.nativeElement.querySelector('.note-content');
        const noteContentElementHeight = noteContentElement.clientHeight;
        const cardHeight = this.el.nativeElement.clientHeight;
        if (noteContentElementHeight > cardHeight) {
          this.showReadMore = true;
        }
      });
    }
  }
}
