/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-plan/meal-plan-options.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class MealPlanOptions {
  enableNutritionalData = true;
}
