import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPopoverModule,
  // NgbTabsetModule,
} from '@ng-bootstrap/ng-bootstrap';
import { FroalaEditorModule } from 'angular-froala-wysiwyg';
// import { DragulaModule } from 'ng2-dragula';
import { BuyModule } from '../buy/buy.module';
import { DistributorService } from '../distributor/distributor-service';
import { FavoriteRecipesModule } from '../favorite-recipes/favorite-recipes.module';
import { LoadingModule } from '../loading/loading.module';
import { PipesModule } from '../pipe/pipes.module';
import { RecipeViewComponent } from '../recipe/recipe-view/recipe-view.component';
import { RecipeViewerModule } from '../recipe/recipe-view/recipe-view.module';
import { CdnService } from '../service/cdn.service';
import { SharedModule } from '../shared/shared.module';
import { UnitSystemToggleModule } from '../unit-system-toggle/unit-system-toggle.module';
import { GroceryListComponent } from './grocery-list/grocery-list.component';
import { CreateOrEditNoteModalComponent } from './note/create-or-edit-note-modal/create-or-edit-note-modal.component';
import { ViewNoteModalComponent } from './note/view-note-modal/view-note-modal.component';
import { NutritionSummaryComponent } from './nutrition-day-summary/nutrition-summary.component';
import { NutritionFactsRowComponent } from './nutrition-facts-row/nutrition-facts-row.component';
import { NutritionReportComponent } from './nutrition-report/nutrition-report.component';
import { PlannerCalendarSelectComponent } from './planner-calendar-select/planner-calendar-select.component';
import { PlannerCardComponent } from './planner-card/planner-card.component';
import { PlannerDayComponent } from './planner-day/planner-day.component';
import { PlannerDisplayComponent } from './planner-display/planner-display.component';
import { PlannerFullscriptButtonComponent } from './planner-fullscript-button/planner-fullscript-button.component';
import { PlannerLayoutNarrowComponent } from './planner-layout-narrow/planner-layout-narrow.component';
import { NewDragAndDropDialogComponent } from './planner-layout-wide/new-drag-and-drop-dialog/new-drag-and-drop-dialog.component';
import { PlannerLayoutWideComponent } from './planner-layout-wide/planner-layout-wide.component';
import { PlannerLayoutService } from './planner-layout.service';
import { NoteModalService } from './planner-meal/note-modal.service';
import { PlannerMealComponent } from './planner-meal/planner-meal.component';
import { RecipeDragComponent } from './planner-meal/recipe-drag/recipe-drag.component';
import { PlannerOutletComponent } from './planner-outlet/planner-outlet.component';
import { PlannerPrinterService } from './planner-printer.service';
import { PlannerPrinterComponent } from './planner-printer/planner-printer.component';
import { PlannerRecipeViewerComponent } from './planner-recipe-viewer/planner-recipe-viewer.component';
import { PlannerRecipeIngredientsComponent } from './planner-recipe/planner-recipe-ingredients/planner-recipe-ingredients.component';
import { PlannerRecipeComponent } from './planner-recipe/planner-recipe.component';
import { PlannerRoutingModule } from './planner-routing.module';
import { PlannerSearchComponent } from './planner-search/planner-search.component';
import { PlannerWeekComponent } from './planner-week/planner-week.component';
import { PlannerComponent } from './planner/planner.component';
import { PrintWeekSelectionComponent } from './print-week-selection/print-week-selection.component';
import { RecipeBoxResultComponent } from './recipe-box-result/recipe-box-result.component';
import { RecipeBoxComponent } from './recipe-box/recipe-box.component';
import { RecipeSearchResultComponent } from './recipe-search-result/recipe-search-result.component';
import { RecommendationsComponentService } from './recommendations/recommendations-component.service';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { UsdaNutritionFactsRowComponent } from './usda-nutrition-facts-row/usda-nutrition-facts-row.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ButtonDirective } from '../../component-directives/button.directive';
import { LucideAngularModule } from 'lucide-angular';

@NgModule({
  imports: [
    CommonModule,
    PlannerRoutingModule,
    PipesModule,
    // DragulaModule,
    NgbModalModule,
    // NgbTabsetModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    MatIconModule,
    FormsModule,
    RecipeViewerModule,
    BuyModule,
    FroalaEditorModule,
    UnitSystemToggleModule,
    NgbPopoverModule,
    LoadingModule,
    FavoriteRecipesModule,
    SharedModule,
    OverlayModule,
    NgxSliderModule,
    MatDialogModule,
    MatButtonModule,
    DragDropModule,
    NgbNavModule,
    MatTabsModule,
    ButtonDirective,
    LucideAngularModule
  ],
  declarations: [
    PlannerComponent,
    PlannerComponent,
    PlannerWeekComponent,
    PlannerDayComponent,
    PlannerMealComponent,
    PlannerSearchComponent,
    PlannerRecipeComponent,
    PlannerCardComponent,
    RecipeSearchResultComponent,
    PlannerRecipeIngredientsComponent,
    PlannerPrinterComponent,
    PlannerCalendarSelectComponent,
    GroceryListComponent,
    PlannerDisplayComponent,
    PlannerOutletComponent,
    NutritionReportComponent,
    NutritionFactsRowComponent,
    RecipeBoxComponent,
    RecipeBoxResultComponent,
    UsdaNutritionFactsRowComponent,
    PlannerLayoutNarrowComponent,
    PlannerLayoutWideComponent,
    PlannerRecipeViewerComponent,
    UpgradeComponent,
    ViewNoteModalComponent,
    CreateOrEditNoteModalComponent,
    NutritionSummaryComponent,
    PrintWeekSelectionComponent,
    PlannerFullscriptButtonComponent,
    RecommendationsComponent,
    RecipeDragComponent,
    NewDragAndDropDialogComponent,
  ],
  exports: [PlannerComponent, RecipeViewComponent, RecipeBoxResultComponent],
  providers: [
    DistributorService,
    PlannerPrinterService,
    PlannerLayoutService,
    CdnService,
    NoteModalService,
    RecommendationsComponentService,
  ],
  // entryComponents: [
  //   NutritionReportComponent,
  //   CreateOrEditNoteModalComponent,
  //   ViewNoteModalComponent,
  // ],
})
export class PlannerModule {}
