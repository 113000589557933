/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/user/UserType.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum UserType {
  System = 'system',
  Admin = 'admin',
  Client = 'user',
  Distributor = 'distributor',
  Anonymous = 'anonymous'
}
