<app-focused-form [background]="'url(\'/assets/img/home/hero-banner-002.jpg\') center center no-repeat'">
    <form class='form-signin' (submit)='sendLink()' #loginForm='ngForm'>

        <h1 class='h4 mb-3 font-weight-normal text-center'>Log in with a Link</h1>


        <p>Enter your email address below and we'll send you a link to log in.</p>

        <app-bad-clock></app-bad-clock>

        <label for='inputEmail' class='sr-only'>Email address</label>
        <input type='email' [(ngModel)]='email' id='inputEmail' class='form-control mb-3'
               placeholder='Email address'
               required='' autofocus='' name='email'>

        <button appButton fullWidth type='submit' [disabled]='!loginForm.form.valid || isProcessing'>
            <span *ngIf='!isProcessing'>Send Link</span>
            <i *ngIf='isProcessing' class='fa fa-spinner fa-spin'></i>
        </button>

        <div *ngIf='isSent' class='alert alert-success'>
            Please check your email for your log in link.
        </div>

        <div *ngIf='error' class='alert alert-danger mt-3'>
            {{error}}
        </div>

    </form>


</app-focused-form>
