<main role="main" class="grocery-list-wrapper">
  <div class="container">
    <ng-container *ngIf="layout.isNarrow$ | async">
      <div class="row align-items-center">
        <div class="col">
          <h4 class="m-0">Grocery List</h4>
        </div>
        <div class="col-auto text-right">
          <app-unit-system-toggle></app-unit-system-toggle>
        </div>
        <div class="col-auto ml-auto">
          <button type="button" mat-icon-button (click)="copyToClipboard()">
            <mat-icon title="Copy to Clipboard">content_copy</mat-icon>
          </button>
          <button type="button" mat-icon-button (click)="resetList()">
            <mat-icon title="Reset List" color="warn" style="transform: scaleX(-1)"
              >refresh</mat-icon
            >
          </button>
        </div>
      </div>
      <div>
        <div class="text-muted" *ngIf="weeklyPlan?.mealPlan?.nonCalendar">
          {{ convertToWeekNumber(weeklyPlan.weekNumber) }}
          <ng-container *ngIf="weeklyPlans.length > 1">
            &nbsp;<small><a (click)="showCalendar = true" class="text-primary">(Change)</a></small>
          </ng-container>
        </div>

        <ng-container *ngIf="!weeklyPlan?.mealPlan?.nonCalendar">
          <div class="text-muted">
            {{ fromDate | ngbdate }}
            <span *ngIf="toDate">- {{ toDate | ngbdate }}</span>
            &nbsp;<small><a (click)="showCalendar = true" class="text-primary">(Change)</a></small>
          </div>
        </ng-container>
      </div>

      <hr />
    </ng-container>

    <div *ngIf="weeklyPlans" class="row">
      <div class="grocery-list-calendar col-md-3 d-print-none mb-3" [class.d-none]="!showCalendar">
        <!--        <ng-container *ngIf="groceryLists?.length > 0">-->
        <!--          <div-->
        <!--            class="rounded p-2 py-3 mb-2"-->
        <!--            style="background-color: #f7f7f9; border: 1px solid #e1e1e1"-->
        <!--          >-->
        <!--            <h5>Saved Lists</h5>-->
        <!--            <div *ngFor="let item of groceryLists" [class.selected-list]="item.key == selectedList">-->
        <!--              <div class="row">-->
        <!--                <div class="col" (click)="changeList(item.key)">-->
        <!--                  {{ item.key }}-->
        <!--                </div>-->
        <!--                <div class="col-auto">-->
        <!--                  <i class="fa fa-close" (click)="deleteGroceryList(item.key)"></i>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </ng-container>-->

        <div
          class="text-center rounded p-2 py-3"
          style="background-color: #f7f7f9; border: 1px solid #e1e1e1"
        >
          <ng-container *ngIf="groceryLists?.length > 0">
            <div class="rounded mb-2" style="background-color: #f7f7f9">
              <h5>Your Saved Lists</h5>
              <div
                *ngFor="let item of groceryLists"
                [class.selected-list]="item.key == selectedList"
                class="saved-list-item clickable"
              >
                <div class="row mb-2 no-gutters">
                  <div class="col-1"></div>
                  <div class="col" (click)="changeList(item.key)">
                    {{ keyToDate(item.key) }}
                  </div>
                  <div class="col-1">
                    <i
                      class="fa fa-fw fa-close text-danger"
                      title="Delete List {{ keyToDate(item.key) }}"
                      (click)="deleteGroceryList(item.key)"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </ng-container>

          <ng-container *ngIf="this.weeklyPlans[0].mealPlan.nonCalendar">
            <p>Select a week below to filter the grocery list.</p>
            <select (change)="changeWeek($event)" class="form-control">
              <option *ngFor="let wp of weeklyPlans" [value]="wp.weekNumber">
                {{ convertToWeekNumber(wp.weekNumber) }}
              </option>
            </select>
          </ng-container>

          <div [class.d-none]="this.weeklyPlans[0].mealPlan.nonCalendar">
            <p>Use the calendar below to filter the grocery list by day.</p>
            <div class="alert alert-warning">
              <i class="fa fa-warning"></i> Changing the date will reset your checked off items.
            </div>
            <ngb-datepicker
              #dp
              (dateSelect)="onDateSelection($event)"
              [minDate]="minGroceryDate"
              [maxDate]="maxGroceryDate"
              [displayMonths]="1"
              [dayTemplate]="t"
            >
            </ngb-datepicker>
          </div>

          <ng-container *ngIf="layout.isWide$ | async">
            <br />
            <br />

            <a href="javascript:window.print()" appButton>Print Grocery List</a>
            <br />
            <br />
            <a routerLink="{{ plannerPath }}" btnSecondary btnSmall>Back to Planner</a>

            <br />
            <a (click)="toggleItemConsolidation()">.</a>
          </ng-container>

          <ng-container *ngIf="layout.isNarrow$ | async">
            <br />
            <br />
            <button (click)="hideCalendar()" btnSecondary btnSmall>Hide</button>
          </ng-container>
        </div>
      </div>

      <div class="col-md-9" *ngIf="!processing">
        <ng-container *ngIf="layout.isWide$ | async">
          <p *ngIf="printBranding" class="d-none d-print-block">
            <img [src]="(layout.getBrand() | async).logoUrl | cdn: 200:0:false" />
            <br />
            <br />
          </p>

          <div class="row align-items-center">
            <div class="col-auto">
              <h4 style="margin: 0">
                Grocery List
                <ng-container *ngIf="weeklyPlan?.mealPlan?.nonCalendar">
                  <em>({{ convertToWeekNumber(weeklyPlan.weekNumber) }})</em>
                </ng-container>
                <ng-container *ngIf="!weeklyPlan?.mealPlan?.nonCalendar">
                  <em
                    >({{ fromDate | ngbdate }} <span *ngIf="toDate">- {{ toDate | ngbdate }}</span
                    >)</em
                  >
                </ng-container>
              </h4>
            </div>
            <div class="col-auto">
              <app-unit-system-toggle></app-unit-system-toggle>
            </div>
            <!-- mat icon to copy text to clipboard -->
            <div class="col-auto ml-auto">
              <button type="button" mat-icon-button (click)="copyToClipboard()">
                <mat-icon title="Copy to Clipboard">content_copy</mat-icon>
              </button>
              <button type="button" mat-icon-button (click)="resetList()">
                <mat-icon title="Reset List" color="warn" style="transform: scaleX(-1)"
                  >refresh</mat-icon
                >
              </button>
            </div>
          </div>

          <hr />
        </ng-container>

        <div class="row mb-2 no-gutters align-items-center d-print-none add-item-row">
          <div class="col-auto mr-1" [class.d-none]="layout.isNarrow$ | async">
            <strong>Add Item</strong>
          </div>
          <div class="col mr-1">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="addItem"
              placeholder="Item Name"
              (keyup)="addItemOnTabOrEnter($event)"
            />
          </div>
          <div class="col-4 mr-1">
            <select class="form-control" [(ngModel)]="addItemSelectedCategory">
              <ng-container *ngFor="let category of categories()">
                <option
                  [value]="category"
                  [selected]="
                    category === addItemSelectedCategory ||
                    (!addItemSelectedCategory && category == 'Other')
                  "
                >
                  {{ category }}
                </option>
              </ng-container>
            </select>
          </div>
          <div class="col-auto">
            <button type="button" appButton (click)="addNewAdditionalItem()">
              <i class="fa fa-plus"></i> Add
            </button>
          </div>
        </div>

        <div *ngFor="let groupKey of groceryListCategories">
          <ng-container *ngIf="showGroupIfHasVisibleItems(groupedItems[groupKey])">
            <h5 *ngIf="groupKey !== ' '" class="mt-3 p-3 alert alert-success">{{ groupKey }}</h5>
            <ng-container *ngFor="let item of groupedItems[groupKey]">
              <div class="item" *ngIf="!item.isHidden" [class.d-none]="item.item == ''">
                <div class="row">
                  <div (click)="toggleItem(item)" class="col">
                    <div class="clickable">
                      <mat-icon [class.is-checked]="item.isChecked" *ngIf="item.isChecked"
                        >check</mat-icon
                      >
                      <mat-icon *ngIf="!item.isChecked">check_box_outline_blank</mat-icon>

                      <span
                        [class.text-muted]="item.isChecked"
                        [innerHtml]="
                          item.isAdditional
                            ? item.item
                            : (item | ingredientDisplay: undefined:undefined:true:unitSystem)
                        "
                      ></span>
                    </div>
                  </div>
                  <div class="col-auto ml-auto d-print-none mb-2">
                    <mat-icon
                      class="float-right clickable mr-2"
                      color="warn"
                      title="Hide {{ item.item }}"
                      (click)="hideItem(item)"
                      >close</mat-icon
                    >
                  </div>
                </div>
              </div></ng-container
            ></ng-container
          >
        </div>

        <div *ngIf="hiddenItems?.length > 0">
          <h5 class="mt-3 p-3 alert alert-warning clickable" (click)="showHidden = !showHidden">
            <i class="fa fa-chevron-down" *ngIf="showHidden"></i>
            <i class="fa fa-chevron-up" *ngIf="!showHidden"></i>
            Hidden Items
          </h5>
          <ng-container *ngIf="showHidden">
            <ng-container *ngFor="let item of hiddenItems">
              <div *ngIf="item.isHidden" [class.d-none]="item.item == ''" class="row">
                <div (click)="toggleItem(item)" class="col">
                  <div class="item text-muted">
                    <mat-icon [class.is-checked]="item.isChecked" *ngIf="item.isChecked"
                      >check</mat-icon
                    >
                    <mat-icon *ngIf="!item.isChecked">check_box_outline_blank</mat-icon>

                    <!--                        <span class="checkbox" [class.is-checked]="item.isChecked"></span>-->

                    <span
                      class="text-muted"
                      [class.text-muted]="item.isChecked"
                      [innerHtml]="item | ingredientDisplay: undefined:undefined:true:unitSystem"
                    ></span>
                  </div>
                </div>
                <div class="col-auto ml-auto">
                  <mat-icon class="float-right clickable" (click)="unHideItem(item)">add</mat-icon>
                </div>
              </div></ng-container
            ></ng-container
          >
        </div>
      </div>
    </div>
  </div>

  <ng-template #t let-date="date" let-focused="focused">
    <span
      class="custom-day"
      [class.focused]="focused"
      [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
      [class.faded]="isHovered(date) || isInside(date)"
      (mouseenter)="hoveredDate = date"
      (mouseleave)="hoveredDate = null"
    >
      {{ date.day }}
    </span>
  </ng-template>
</main>
