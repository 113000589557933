import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PdfGeneratorComponent } from './pdf-generator.component';
import { LoadingModule } from '../loading/loading.module';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [PdfGeneratorComponent],
  imports: [CommonModule, FormsModule, LoadingModule, MatDialogModule],
})
export class PdfGeneratorModule {}
