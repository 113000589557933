import { DistributorCreditTransaction } from '../../lprx-shared-lib/distributor-tokens/distributor-token-transaction';
import { DistributorCreditCommandDto } from '../../lprx-shared-lib/distributor-tokens/dto/credit-distributor-credit-dto';
import { DEFAULT_LIST_LIMIT } from '../../lprx-shared-lib/utils/constants';
import { ListResult } from '../../lprx-shared-lib/utils/list-result';
import { LprxApi } from './api';

export class Credits {
  constructor(private readonly lprxApi: LprxApi) {}

  list(
    distributorId: string,
    limit = DEFAULT_LIST_LIMIT,
    nextKey?: string
  ): Promise<ListResult<DistributorCreditTransaction>> {
    const queryParams = { limit };
    if (nextKey) {
      queryParams['next_key'] = nextKey;
    }
    return this.lprxApi.get(`v3/credits/${distributorId}`, queryParams);
  }

  credit(
    distributorId: string,
    amount: number,
    memo?: string
  ): Promise<DistributorCreditTransaction> {
    const dto: DistributorCreditCommandDto = {
      distributorId,
      amount,
      memo
    };
    return this.lprxApi.post(`v3/credits`, dto);
  }

  balance(distributorId?: string): Promise<number> {
    if (distributorId) {
      return this.lprxApi.get(`v3/credits/balance`, { distributor: distributorId });
    } else {
      return this.lprxApi.get(`v3/credits/balance`);
    }
  }
}
