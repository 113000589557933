<div class="row no-gutters">
  <div class="col-md-1">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <input
          class="form-control"
          [(ngModel)]="fractionalQuantity"
          (keyup)="convert()"
          name="quantity[]"
          placement="top"
          ngbTooltip="Quantity"
          triggers="focus:blur"
        />
      </div>
    </div>
  </div>

  <div class="col-md-2">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <input
          class="form-control"
          [ngbTypeahead]="search"
          [(ngModel)]="ingredient.unit"
          name="unit[]"
          tooltip-placement="top"
          ngbTooltip="Unit (Cups, ...)"
          triggers="focus:blur"
          placeholder="Cups, Teaspoons, ..."
        />
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">of</span>
        </div>
        <input
          class="form-control"
          [(ngModel)]="ingredient.item"
          (keyup)="fillInUSDASearch($event)"
          name="item[]"
          placeholder="Ingredient"
          placement="top"
          ngbTooltip="Ingredient"
        />
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">,</span>
        </div>
        <input
          class="form-control"
          [(ngModel)]="ingredient.technique"
          name="technique[]"
          placeholder="Technique (chopped, diced, ...)"
          placement="top"
          ngbTooltip="Technique (chopped, ...)"
          triggers="focus:blur"
        />
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text">(</span>
        </div>
        <input
          class="form-control"
          [(ngModel)]="ingredient.equivalency"
          name="equivalency[]"
          placement="top"
          ngbTooltip="Equivalent To"
          placeholder="Equivalent To"
          triggers="focus:blur"
        />
        <div class="input-group-append">
          <span class="input-group-text">)</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row no-gutters">
  <div class="col-md-3">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <input
          class="form-control"
          [(ngModel)]="ingredient.note"
          name="note[]"
          placement="top"
          placeholder="Extra Notes"
          ngbTooltip="Extra Notes"
          triggers="focus:blur"
        />
      </div>
    </div>
  </div>

  <div class="col-md-3">
    <div class="form-group">
      <div class="input-group -input-group-sm">
        <select [(ngModel)]="ingredient.groceryCategory" name="category" class="form-control">
          <option *ngFor="let type of groceryCategories" [ngValue]="type">{{type}}</option>
        </select>
      </div>
    </div>
  </div>
</div>

<hr />

<div *ngIf="(layout.showNutritionalInfo$ | async) && ingredient.nutrients" class="nutrients my-2">
    <div class="row">
        <div class="col">Calories: {{ingredient.nutrients.ENERC_KCAL | nutrientValue:1}}</div>
        <div class="col">Total Fat: {{ingredient.nutrients.FAT | nutrientValue:1}}</div>
        <div class="col">Sat Fat: {{ingredient.nutrients.FASAT | nutrientValue:1}}</div>
        <div class="col">Trans Fat: {{ingredient.nutrients.FATRN | nutrientValue:1}}</div>
        <div class="col">Chole: {{ingredient.nutrients.CHOLE | nutrientValue:1}}</div>
        <div class="col">Sodium: {{ingredient.nutrients.NA | nutrientValue:1}}</div>
    </div>
    <div class="row">
        <div class="col">Carbs: {{ingredient.nutrients.CHOCDF | nutrientValue:1}}</div>
        <div class="col">Fiber: {{ingredient.nutrients.FIBTG | nutrientValue:1}}</div>
        <div class="col">Sugars: {{ingredient.nutrients.SUGAR | nutrientValue:1}}</div>
        <!--<div class="col">Added Sugars:{{nutrients.NA.value}}</div>-->
        <div class="col">Protein: {{ingredient.nutrients.PROCNT | nutrientValue:1}}</div>
        <div class="col"></div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col">V.D: {{ingredient.nutrients.VITD | nutrientValue:1}}</div>
        <div class="col">Cal: {{ingredient.nutrients.CA | nutrientValue:1}}</div>
        <div class="col">Iron: {{ingredient.nutrients.FE | nutrientValue:1}}</div>
        <div class="col">Potassium: {{ingredient.nutrients.K | nutrientValue:1}}</div>
        <div class="col"></div>
        <div class="col"></div>
    </div>
</div>

<hr />

<div *ngIf="ingredient?.ndb?.ndbNo">
  <!--<a href="https://ndb.nal.usda.gov/ndb/foods/show/{{ingredient.ndb.ndbNo}}?format=Full" target="_blank">{{ingredient.ndb.description}}</a>-->
  <a href="javascript:" (click)="showSearch = !showSearch">{{ingredient.ndb.description}}</a>
  &middot;
  <span *ngIf="ingredient.ndb.amount">{{ndbFraction}} {{ingredient.ndb.measurement}}</span>

  &nbsp;&nbsp;&nbsp;
  <span (click)="showSearch = !showSearch">
    <i *ngIf="showSearch" class="fa fa-chevron-down"></i>
    <i *ngIf="!showSearch" class="fa fa-chevron-up"></i>
  </span>
</div>



<div [class.d-none]="!showSearch" class="my-3">
  <div class="row no-gutters" [class.d-none]="showNewIngredientForm">
    <div class="col-md-5">
      <div class="input-group">
        <input
          type="text"
          name="ndbTerm"
          [(ngModel)]="ndbTerm"
          class="form-control"
          placeholder=""
          aria-label=""
          aria-describedby=""
        />
        <div class="input-group-append">
          <button type="button" (click)="findIngredient(true)" class="btn btn-outline-primary">
            Search
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <select [(ngModel)]="ingredient.ndb.ndbNo" (change)="onChangeMatch()" class="form-control">
        <option *ngFor="let match of matches" [value]="match.ndbNo">
          {{match.longDescription}}
        </option>
      </select>
    </div>
    <div class="col-md-1">
      <input
        [(ngModel)]="ingredient.ndb.amount"
        type="number"
        min="0"
        step="1"
        max="1000"
        class="form-control"
        (change)="calculateNutrients()"
      />
    </div>
    <div class="col-md-2">
      <select
        [(ngModel)]="ingredient.ndb.measurement"
        (change)="calculateNutrients()"
        class="form-control"
      >
        <option *ngFor="let weight of weights" [value]="weight.measurement">
          {{weight.measurement}}
        </option>
      </select>
    </div>
  </div>

  <p class="text-center">
    <small
      ><a (click)="addNewIngredient()" *ngIf="!showNewIngredientForm"
        >+ Create New Ingredient</a
      ></small
    >
  </p>

  <div *ngIf="showNewIngredientForm">
    <br />

    <div class="new-ingredient-form">
      <div class="row">
        <div class="col-md-8">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Ingredient Name"
              [required]
              name="newIngredient.name"
              [(ngModel)]="newIngredient.name"
            />
          </div>
        </div>

        <div class="col-md-4">
          <div class="form-group">
            <button
              type="button"
              appButton
              (click)="createNewIngredient()"
              [disabled]="isProcessingNewIngredient"
            >
              {{isProcessingNewIngredient ? 'Just a moment' : 'Add Ingredient'}}
            </button>
            <button type="button" class="btn btn-link" (click)="closeNewIngredientForm()">
              Cancel
            </button>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Serving Size</div>
        </div>

        <div class="col-md-3">
          <div class="form-group">
            <input
              type="number"
              [required]
              class="form-control"
              name="newIngredient.servingSize"
              [(ngModel)]="newIngredient.servingSize"
            />
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Cups, Teaspoons, ..."
              [required]
              name="newIngredient.servingSizeMeasurement"
              [(ngModel)]="newIngredient.servingSizeMeasurement"
            />
          </div>
        </div>

        <div class="col-md-2 text-right">
          <div class="form-group">As grams</div>
        </div>

        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <input
                type="number"
                class="form-control"
                name="newIngredient.servingSizeInGrams"
                [(ngModel)]="newIngredient.servingSizeInGrams"
                [min]=".001"
                [required]
              />
              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Calories</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <input
                type="number"
                class="form-control"
                name="newIngredient.calories"
                [(ngModel)]="newIngredient.calories"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Total Fat</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.totalFat.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.totalFat.value"
                [disabled]="!newIngredient.totalFat.isKnown"
                [(ngModel)]="newIngredient.totalFat.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Saturated Fat</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.saturatedFat.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.saturatedFat.value"
                [disabled]="!newIngredient.saturatedFat.isKnown"
                [(ngModel)]="newIngredient.saturatedFat.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Trans Fat</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.transFat.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.transFat.value"
                [disabled]="!newIngredient.transFat.isKnown"
                [(ngModel)]="newIngredient.transFat.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Cholesterol</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.cholesterol.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.cholesterol.value"
                [disabled]="!newIngredient.cholesterol.isKnown"
                [(ngModel)]="newIngredient.cholesterol.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">mg</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Sodium</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.sodium.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.sodium.value"
                [disabled]="!newIngredient.sodium.isKnown"
                [(ngModel)]="newIngredient.sodium.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">mg</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Carbohydrates</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.totalCarbohydrates.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.totalCarbohydrates.value"
                [disabled]="!newIngredient.totalCarbohydrates.isKnown"
                [(ngModel)]="newIngredient.totalCarbohydrates.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Dietary Fiber</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.dietaryFiber.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.dietaryFiber.value"
                [disabled]="!newIngredient.dietaryFiber.isKnown"
                [(ngModel)]="newIngredient.dietaryFiber.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Total Sugars</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.totalSugars.isKnown"
                  />
                </div>
              </div>
              <input
                type="number"
                class="form-control"
                name="newIngredient.totalSugars.value"
                [disabled]="!newIngredient.totalSugars.isKnown"
                [(ngModel)]="newIngredient.totalSugars.value"
              />
              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Protein</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.protein.isKnown"
                  />
                </div>
              </div>
              <input
                type="number"
                class="form-control"
                name="newIngredient.protein.value"
                [disabled]="!newIngredient.protein.isKnown"
                [(ngModel)]="newIngredient.protein.value"
              />
              <div class="input-group-append">
                <span class="input-group-text">g</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Vitamin D</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.vitaminD.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.vitaminD.value"
                [disabled]="!newIngredient.vitaminD.isKnown"
                [(ngModel)]="newIngredient.vitaminD.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">mcg</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Calcium</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.calcium.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.calcium.value"
                [disabled]="!newIngredient.calcium.isKnown"
                [(ngModel)]="newIngredient.calcium.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">mg</span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-2">
          <div class="form-group">Iron</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.iron.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.iron.value"
                [disabled]="!newIngredient.iron.isKnown"
                [(ngModel)]="newIngredient.iron.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">mg</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center">
        <div class="col-md-2">
          <div class="form-group">Potassium</div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <div class="input-group">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  <input
                    type="checkbox"
                    aria-label="Checkbox for following text input"
                    [(ngModel)]="newIngredient.potassium.isKnown"
                  />
                </div>
              </div>

              <input
                type="number"
                class="form-control"
                name="newIngredient.potassium.value"
                [disabled]="!newIngredient.potassium.isKnown"
                [(ngModel)]="newIngredient.potassium.value"
              />

              <div class="input-group-append">
                <span class="input-group-text">mg</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
