export function isTouchEnabled() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export function dragDropDelay() {
  if (isTouchEnabled()) {
    return 25;
  } else {
    return 0;
  }
}
