/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/round-fraction-to-recipe-measurement.ts
 *  --------------------------
 *  - Swagger items commented out
 */

/**
 * Rounds a given fractional string to the nearest common recipe measurement.
 *
 * This function takes a fraction represented as a string (e.g. "67/100"),
 * and attempts to convert it to a common recipe measurement for easier
 * readability and practical use in cooking. Common recipe measurements typically
 * include whole numbers, halves, thirds, quarters, and eighths.
 * The function handles fractions with denominators up to 8, rounding to the nearest
 * common measurement.
 *
 * @param {string} fraction - The fraction to convert, represented as a string.
 * @returns {string} A string representing the nearest common recipe measurement.
 *
 * @example
 * roundFractionToRecipeMeasurement("3/4"); // "3/4"
 * roundFractionToRecipeMeasurement("2/3"); // "2/3"
 * roundFractionToRecipeMeasurement("67/100"); // "2/3"
 */
export function roundFractionToRecipeMeasurement(fraction: string): string {
  fraction = fraction.replace(/[^0-9/]/g, '');
  const [numerator, denominator] = fraction.split('/').map(Number);
  const decimal = numerator / denominator;

  const commonMeasurements = [
    { value: 0, label: '0' },

    { value: 1 / 8, label: '1/8' },
    { value: 3 / 8, label: '3/8' },
    { value: 5 / 8, label: '5/8' },
    { value: 7 / 8, label: '7/8' },

    { value: 1 / 4, label: '1/4' },
    { value: 3 / 4, label: '3/4' },

    { value: 1 / 3, label: '1/3' },
    { value: 2 / 3, label: '2/3' },

    { value: 1 / 2, label: '1/2' },

    { value: 1, label: '1' },
  ];

  let nearest = commonMeasurements[0];
  for (const measurement of commonMeasurements) {
    if (Math.abs(measurement.value - decimal) < Math.abs(nearest.value - decimal)) {
      nearest = measurement;
    }
  }

  return nearest.label;
}
