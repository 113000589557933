<app-focused-form
  [background]="'url(\'/assets/img/home/hero-banner-002.jpg\') center center no-repeat'"
  [showLprxLogo]="true"
  size="md"
>
  <script>
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.agent = 'plkajabi';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '260006911038767', {
      ct: '',
      country: null,
      em: '',
      fn: '',
      ln: '',
      pn: '',
      zp: '',
    });
    fbq('track', 'PageView');
  </script>
  <noscript
    ><img
      height="1"
      width="1"
      style="display: none"
      src="https://www.facebook.com/tr?id=260006911038767&ev=PageView&noscript=1"
    />
  </noscript>

  <div class="container" [hidden]="loading">
    <div class="text-center">
      <h2>Become a Member</h2>
      <p>Complete the form below</p>
    </div>

    <div
      *ngIf="welcomeBackMessage"
      [innerHtml]="welcomeBackMessage"
      class="welcome-back alert alert-warning"
    >
      <!-- placeholder for headline -->
      <p class="wb-headline"></p>
    </div>

    <div *ngIf="alert" class="alert alert-danger">
      {{ alert }}
    </div>

    <form [formGroup]="form" (submit)="submit($event)">
      <div class="row">
        <div class="col"></div>
        <div class="col">
          <div class="price-highlight">MOST POPULAR</div>
        </div>
      </div>
      <div class="row">
        <div class="col" *ngIf="_monthlyPrice">
          <button
            type="button"
            class="price btn btn-block"
            [class.chosen-price]="planChoice === 'monthly'"
            (click)="changePlan('monthly')"
          >
            <strong>Monthly<br />Membership</strong><br />
            <div class="pricing">{{ monthlyPrice }}</div>
            <div
              class="btn btn-block rounded-pill"
              [class.btn-secondary]="planChoice === 'monthly'"
              [class.btn-primary]="planChoice !== 'monthly'"
            >
              {{ planChoice === 'monthly' ? 'CHOSEN' : 'SELECT' }}
            </div>
          </button>
        </div>
        <div class="col" *ngIf="_annualPrice > 0">
          <button
            type="button"
            class="price btn btn-block"
            [class.chosen-price]="planChoice === 'annual'"
            (click)="changePlan('annual')"
          >
            <strong>Annual<br />Membership</strong><br />
            <div class="pricing">{{ annualPrice }} <br />[save ${{ savings }}!]</div>
            <div
              class="btn btn-block rounded-pill"
              [class.btn-secondary]="planChoice === 'annual'"
              [class.btn-primary]="planChoice !== 'annual'"
            >
              {{ planChoice === 'annual' ? 'CHOSEN' : 'SELECT' }}
            </div>
          </button>
        </div>
      </div>

      <br />

      <section [hidden]="!showDistributor">
        <div class="alert alert-warning text-center">
            <strong>Coming back to Living Plate Rx?</strong> &nbsp;
            <a href='/distributor/dashboard'>Log In</a> to see your special discount.
        </div>

        <div class="row">
          <div class="col-md-6 mb-3">
            <label for="form.firstName">First name</label>
            <input class="form-control" id="form.firstName" formControlName="firstName" />
          </div>
          <div class="col-md-6 mb-3">
            <label for="form.lastName">Last name</label>
            <input class="form-control" id="form.lastName" formControlName="lastName" />
          </div>
        </div>

        <div class="mb-3">
          <label for="form.email">Email</label>
          <input class="form-control" id="form.email" formControlName="email" />
          <!-- email validation error -->
          <div *ngIf="form.get('email').invalid && form.get('email').touched">
            <!-- email validation error : required -->
            <div class="text-danger" *ngIf="form.get('email').hasError('required')">
              Email is required.
            </div>
            <!-- email validation error : pattern -->
            <div class="text-danger" *ngIf="form.get('email').hasError('email')">
              Invalid email address.
            </div>
          </div>
        </div>

        <div class="mb-3">
          <label for="form.email">Password</label>
          <input
            class="form-control"
            id="form.password"
            formControlName="password"
            type="password"
          />
          <!-- password validation error -->
          <div *ngIf="form.get('password').invalid && form.get('password').touched">
            <div class="text-danger">
              {{
                form.get('password').hasError('invalidPassword')
                  ? form.get('password').getError('invalidPassword').value
                  : 'Password is required.'
              }}
            </div>
          </div>
        </div>

        <br />

        <h5>
          I am a…
          <small *ngIf="form.get('type').invalid" class="text-danger">(please choose)</small>
        </h5>

        <div class="row mb-3">
          <div class="col-6">
            <button
              class="btn btn-block rounded-pill"
              type="button"
              (click)="form.get('type').setValue('wellness')"
              [class.btn-outline-secondary]="form.get('type').value !== 'wellness'"
              [class.btn-primary]="form.get('type').value === 'wellness'"
            >
              Wellness Professional<br /><small>(e.g. Wellness/Fitness/Health Coach)</small>
            </button>
          </div>

          <div class="col-6">
            <button
              class="btn btn-block rounded-pill"
              type="button"
              (click)="form.get('type').setValue('healthcare')"
              [class.btn-outline-secondary]="form.get('type').value !== 'healthcare'"
              [class.btn-primary]="form.get('type').value === 'healthcare'"
            >
              Healthcare Professional<br /><small>(e.g. RDN, MD, DC, CNS, etc.)</small>
            </button>
          </div>
        </div>

        <!-- alert -->
        <div
          class="alert alert-danger text-center mb-3"
          *ngIf="
            form.get('type').invalid ||
            form.get('password').invalid ||
            form.get('email').invalid ||
            form.get('lastName').invalid ||
            form.get('firstName').invalid
          "
        >
          Please complete the form above.
        </div>

        <div *ngIf="error" class="alert alert-danger" [innerHtml]="error"></div>

        <button
          type="button"
          *ngIf="!showTerms"
          appButton
          fullWidth
          (click)="enableTerms()"
          class="continue-button"
          [disabled]="
            form.get('type').invalid ||
            form.get('password').invalid ||
            form.get('email').invalid ||
            form.get('lastName').invalid ||
            form.get('firstName').invalid
          "
        >
          Continue
        </button>
      </section>

      <section #terms [hidden]="!showTerms">
        <h5 class="">Terms of Use</h5>

        <div
          #touBox
          id="tou-box"
          (scrollend)="checkTouScrollBottom($event)"
          (wheel)="checkTouScrollBottom($event)"
          (touchend)="checkTouScrollBottom($event)"
        >
          <app-terms-html></app-terms-html>
        </div>

        <div *ngIf="touDisabled" class="text-right tw-text-danger">
          (Scroll to the bottom to enable the checkbox.)
        </div>

        <mat-checkbox formControlName="tou" required [disabled]="touDisabled" label="">
          I agree to the Terms of Use
        </mat-checkbox>

        <div class="alert alert-danger text-center mt-3" *ngIf="!form.get('tou').value">
          Please agree to the Terms of Use.
        </div>

        <button
          type="button"
          *ngIf="!showPaymentForm"
          appButton
          fullWidth
          [disabled]="!form.get('tou').value"
          (click)="enablePaymentForm()"
          class="continue-button mt-4"
        >
          Continue
        </button>
      </section>

      <section [hidden]="!showPaymentForm">
        <h5 #paymentForm id="paymentform">Billing Information</h5>
        <!--      <app-credit-card-form-->
        <!--        #ccForm-->
        <!--        [showCardSelect]="false"-->
        <!--        [collectName]="false"-->
        <!--      ></app-credit-card-form>-->

        <div #paymentElement id="payment-element">
          <!-- Elements will create form elements here -->
        </div>

        <br />

        <div class="row align-items-center my-3" [hidden]='!enablePromoCode'>
          <div class="col-4 text-right">
            <strong class="">Promo Code</strong>
          </div>
          <div class="col-4">
            <input
              type="text"
              id="promoCode"
              name="promoCode"
              class="form-control"
              formControlName="promoCode"
            />
          </div>
          <div class="col-4">
            <button type="button" appButton (click)="applyPromoCode()">Apply</button>
          </div>

          <div id="promo-code-text" class="d-none"></div>
        </div>

        <div class="alert alert-danger text-center mt-3" *ngIf="promoCodeError">
          {{ promoCodeError }}
        </div>
        <div class="alert alert-primary text-center mt-3" *ngIf="appliedCode">
          <strong>Promo Code Applied: {{ appliedCode }}</strong>
        </div>

        <br />

        <div *ngIf="error" class="alert alert-danger" [innerHtml]="error"></div>

        <div *ngIf="form.invalid" class="alert alert-danger text-center">
          Please complete the form above.
        </div>

        <button
          type="submit"
          appButton
          fullWidth
          [disabled]="form.invalid || processing"
          [innerHTML]="processing ? 'Processing...' : buyNowText()"
        ></button>
      </section>
    </form>
  </div>
</app-focused-form>
