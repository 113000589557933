import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';

export class Brand {
  name: string;
  baseUrl = '/';
  logoUrl: string;
  safeLogo: SafeUrl;

  constructor(name: string, logoUrl: string, baseUrl = '') {
    this.name = name;
    this.logoUrl = logoUrl;
    this.baseUrl = '/' + baseUrl;

    // this.baseUrl = this.baseUrl;
  }

  static fromDistributor(distributor: Distributor) {
    return new Brand(distributor.business, distributor.logo, distributor.vanityId);
  }
}
