// service class for convertToSimpleEntry$
import { Component, Input, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Recipe } from '../../../../../lprx-shared-lib/entities/recipe/Recipe';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SwitchToSimpleEntryDialog } from './switch-to-simple-entry-dialog/switch-to-simple-entry-dialog';
import { Element } from '../../../../../lprx-shared-lib/entities/recipe/Element';
import { ConvertToSimpleEntryService } from './convert-to-simple-entry.service';

/**
 * Generated class for the RecipeElementsInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-elements-input',
  templateUrl: 'recipe-elements-input.html',
  styleUrls: ['recipe-elements-input.scss'],
})
export class RecipeElementsInputComponent implements OnInit {
  @Input()
  recipe: Recipe;

  // @Input()
  elements: Element[];

  elementIngredientErrors: Map<Element, string[]> = new Map();

  constructor(
    public dialog: MatDialog,
    private convertToSimpleEntryService: ConvertToSimpleEntryService
  ) {}

  hasMultipleElements = false;

  // constructor(private dragulaService: DragulaService) {}
  editing: boolean;
  processingUpdate = false;

  ngOnInit() {
    this.elements = this.recipe.elements;

    // try {
    //   this.dragulaService.createGroup('bag-o-elements', {
    //     revertOnSpill: true,
    //     moves: (el, container, handle) => {
    //       const className = handle.className;
    //       console.log(className);
    //       return !!className.match(/element-drag-handle/);
    //     },
    //   });
    // } catch (err) {}

    if (!this.elements) {
      this.elements = [];
    }

    if (this.elements.length === 0) {
      this.addElement();
    }

    this.setHasMultipleElements();
  }

  addElement() {
    this.elements.push(new Element());
    this.setHasMultipleElements();
  }

  private hasClass(el: any, name: string) {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private addClass(el: any, name: string) {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  private removeClass(el: any, name: string) {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }

  setHasMultipleElements() {
    this.hasMultipleElements = this.elements.length > 1;
  }

  onDeletedElement(element: Element) {
    const index = this.elements.indexOf(element);
    if (index >= 0) {
      this.elements.splice(index, 1);
      this.setHasMultipleElements();
    }
  }

  swapDown(i: number) {
    if (i < this.elements.length - 1) {
      const temp = this.elements[i];
      this.elements[i] = this.elements[i + 1];
      this.elements[i + 1] = temp;
    }
  }

  swapUp(i: number) {
    if (i > 0) {
      const temp = this.elements[i];
      this.elements[i] = this.elements[i - 1];
      this.elements[i - 1] = temp;
    }
  }

  remove(i: number) {
    this.elements.splice(i, 1);
    this.setHasMultipleElements();
  }

  onDrop($event: CdkDragDrop<Element[], any>) {
    moveItemInArray(this.elements, $event.previousIndex, $event.currentIndex);
  }

  convertToSimpleEntry() {
    const dialogRef: MatDialogRef<SwitchToSimpleEntryDialog, boolean> = this.dialog.open(
      SwitchToSimpleEntryDialog,
      {
        data: {
          recipe: this.recipe,
        },
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.convertToSimpleEntryService.convertToSimpleEntry();
      }
    });
  }

  goEdit() {
    this.editing = true;
  }
}
