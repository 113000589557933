import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { finalize, tap } from 'rxjs/operators';
import { Stripe } from 'stripe';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';
import { ClientService } from '../../service/client/client.service';
import { UserService } from '../../service/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  private isProcessing = false;
  showCancelAccountConfirmation: boolean;
  canCancel = false;
  private billingHistory: any;
  subscription: Stripe.Subscription;
  billingAmount: number;
  nextRenewal: any;
  accountPaidBy: string;
  user: User;
  isActiveSubscription = true;
  canUpgrade = true;
  receiveWeeklyEmails: boolean = true;

  /**
   * @param {User} user
   * @param subscription
   * @returns {string}
   */
  private static getAccountPaidBy(user: User, subscription: any) {
    const subscriptionAmount = subscription ? subscription.plan.amount : 0;
    if (subscriptionAmount === 0) {
      return 'distributor';
    }
    return 'user';
  }

  constructor(
    private userService: UserService,
    private toastr: ToastrService,
    private clientService: ClientService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.auth.getUser().subscribe((user) => {
      this.user = user as User;
      this.canUpgrade = this.user.canUpgrade();
      this.receiveWeeklyEmails = this.user.receiveWeeklyEmails;
      if (this.user.subscriptionId) {
        this.getSubscription();
      } else if (this.user.isInFreeTrial) {
        this.isActiveSubscription = false;
        this.accountPaidBy = 'user';
      } else {
        this.accountPaidBy = 'distributor';
        this.setNextRenewal();
      }
    });
  }

  private setNextRenewal() {
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    this.nextRenewal = new Date(this.user.terminatesAt).toLocaleDateString('en-US', dateOptions);
  }

  private getSubscription() {
    this.userService
      .getSubscription()
      .subscribe((subscription) => this.subscriptionHandler(subscription));
  }

  private subscriptionHandler(subscription) {
    this.subscription = subscription;
    this.accountPaidBy = DashboardComponent.getAccountPaidBy(this.user, this.subscription);
    if (this.accountPaidBy === UserType.Client) {
      this.isActiveSubscription = this.subscription.status !== 'canceled';
      if (this.subscription.discount) {
        let amountOff = 0;
        amountOff = this.subscription.discount.coupon.amount_off
          ? this.subscription.discount.coupon.amount_off
          : this.subscription.plan.amount * (this.subscription.discount.coupon.percent_off / 100);
        this.billingAmount = (this.subscription.plan.amount - amountOff) / 100;
      } else {
        this.billingAmount = this.subscription.plan.amount / 100;
      }
    }
    this.setNextRenewal();
  }

  cancelAccount() {
    if (this.isProcessing) {
      return;
    }

    this.clientService.account
      .cancelAccount()
      .pipe(finalize(() => (this.isProcessing = false)))
      .subscribe(
        () => {
          this.toastr.success('Your plan is canceled.');
          this.getSubscription();
        },
        () => {
          this.toastr.error('There was an issue trying to cancel your account.');
        }
      );
  }

  changeWeeklyEmails(receiveWeeklyEmails: boolean) {
    this.clientService
      .setReceiveWeeklyEmails(receiveWeeklyEmails)
      .subscribe((_receiveWeeklyEmails) => {
        this.receiveWeeklyEmails = _receiveWeeklyEmails;
      });
  }
}
