import { Injectable } from '@angular/core';

declare const fbq: any;

@Injectable()
export class FacebookPixelService {
  constructor() {}

  public trackEvent(eventType: string, properties: Object) {
    if (typeof fbq === 'undefined') {
      return;
    }
    fbq('track', eventType, properties);
  }
}
