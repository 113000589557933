<div
  class="result"
  [ngbPopover]="popContent"
  placement="left"
  [disablePopover]="!plannerConfig?.showNutritionFacts"
  triggers="mouseenter:mouseleave"
>
  <div class="row no-gutters --copy-only" (click)="openRecipe()" [title]="recipe.title">
    <div class="col">
      <div class="row align-items-center no-gutters">
        <div style="width: 60px">
          <div
            class="recipe-image"
            style="background-size: cover"
            [style.background-image]="imageUrl"
          >
            &nbsp;
          </div>
        </div>
        <div class="col">
          <div class="recipe-title" style="user-select: none; -webkit-user-select: none">
            {{ recipe.title | truncate: 50 }}
          </div>
        </div>
      </div>
    </div>
    <div class="col-auto ml-auto">
      <app-favorite-recipe-toggle [enabled]="false" [recipe]="recipe"></app-favorite-recipe-toggle>
      <div *ngIf="isUsersRecipe" class="belongs-to-user-icon">
        <i class="fa fa-user-circle -fa-3x"></i>
      </div>
    </div>
  </div>
  <div *ngIf="plannerConfig.showNutritionFacts" class="nutrients">
    {{ calories }} cal / {{ carbs }} carb / {{ protein }} pro / {{ fat }} fat&nbsp;
  </div>
</div>
<ng-template #popContent>
  <div style="width: 200px">
    <app-nutrition-summary [USDAFacts]="usdaFacts"></app-nutrition-summary>
  </div>
</ng-template>
