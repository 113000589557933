import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { Api } from '../../providers/aws.api';

@Injectable()
export class VarService {
  private varSubjects = {};

  constructor(private api: Api) {}

  getVar(name: string): Observable<string> {
    if (!this.varSubjects[name]) {
      this.varSubjects[name] = new BehaviorSubject<string>(null);
      this.fetchVar(name);
    }
    return this.varSubjects[name].asObservable();
  }

  async fetchVar(name: string) {
    const val = await this.api.get(`vars/${name}`);

    this.getVar(name)
      .subscribe(oldVal => {
        if (oldVal !== val) {
          this.varSubjects[name].next(val);
        }
      })
      .unsubscribe();
  }
}
