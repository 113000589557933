<div style='max-width: 400px'>

    <h5 *ngIf='data.user' class='mb-3'>
        Reset Password for <br />
        <strong>{{data.user.firstName}} {{data.user.lastName}}</strong>
    </h5>

    <app-password-input (isValidated)='onIsValidated($event)'></app-password-input>

    <p class='text-right'>
        <button (click)='cancel()' class='btn btn-link'>Cancel</button>
        <button appButton (click)='save()' [disabled]='!isPasswordValidated'>Reset</button>
    </p>

</div>
