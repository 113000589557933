import { CurrencyPipe, formatCurrency } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pricingWithCurrency',
})
export class PricingWithCurrencyPipe implements PipeTransform {
  private currencyPipe: CurrencyPipe;

  constructor(
    @Inject(DEFAULT_CURRENCY_CODE) private _defaultCurrencyCode,
    @Inject(LOCALE_ID) private _locale
  ) {
    this.currencyPipe = new CurrencyPipe(_locale, _defaultCurrencyCode);
  }

  transform(value: number, currency: string): string {
    value = value || 0;
    const currencyCode = currency.toUpperCase();
    return this.currencyPipe.transform(
      value,
      currencyCode,
      currencyCode === 'USD' ? 'symbol-narrow' : 'symbol',
      undefined,
      this._locale
    );
  }
}
