import { Injectable } from '@angular/core';
import { ApiV2 } from '../../../providers/api.v2';
import { MasterWeeklyPlan } from '../../model/entities/weekly-plan/master-weekly-plan';

@Injectable()
export class AdminPlannerService {
  constructor(private apiV2: ApiV2) {}

  save(weeklyPlan: MasterWeeklyPlan) {
    return this.apiV2.post$('admin/master-weekly-plan', { weeklyPlan });
  }
}
