import { plainToClass } from 'class-transformer';
import { AppNotification } from '../../../lprx-shared-lib/notification/notification';
import { ListResult } from '../../../lprx-shared-lib/utils/list-result';
import { LprxApi } from '../api';

export class Notifications {
  constructor(private readonly lprxApi: LprxApi) {}

  /**
   *
   * @return {Promise<ListResult<AppNotification>>}
   */
  async getVisible(): Promise<ListResult<AppNotification>> {
    return this.lprxApi.get<ListResult<any>>(`v3/notifications`).then((listResult) => {
      return new ListResult<AppNotification>(
        plainToClass(AppNotification, listResult.items),
        listResult.next_key
      );
    });
  }

  /**
   *
   * @param {string} notificationId
   * @return {Promise<AppNotification>}
   */
  hide(notificationId: string): Promise<AppNotification> {
    return this.lprxApi
      .delete<AppNotification>(`v3/notifications/${notificationId}`)
      .then((notification) => plainToClass(AppNotification, notification));
  }

  /**
   *
   * @param {string} notificationId
   * @return {Promise<AppNotification>}
   */
  markRead(notificationId: string): Promise<AppNotification> {
    return this.lprxApi
      .patch<AppNotification>(`v3/notifications/${notificationId}/read`)
      .then((notification) => plainToClass(AppNotification, notification));
  }

  /**
   *
   * @param {string} notificationId
   * @return {Promise<AppNotification>}
   */
  markUnread(notificationId: string): Promise<AppNotification> {
    return this.lprxApi
      .delete<AppNotification>(`v3/notifications/${notificationId}/read`)
      .then((notification) => plainToClass(AppNotification, notification));
  }
}
