/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/enum/prime-purchase-source.type.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export type PrimePurchaseSource =
  | 'Default'
  | 'Offer399' //
  | 'Offer499'
  | string; //

export const DefaultPrimePurchaseSource: PrimePurchaseSource = 'Default';
