import { Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Brand } from '../../model/entities/brand';
import { HeaderService } from '../../service/header.service';
import { LayoutService } from '../layout.service';
import { setContainerBgColor } from './set-container-bg-color';
import { DistributorsService } from '../../service/distributors.service';
import { CdnService } from '../../service/cdn.service';

@Component({
  selector: 'app-focused-form',
  templateUrl: './focused-form.component.html',
  styleUrls: ['./focused-form.component.scss']
})
export class FocusedFormComponent implements OnInit, OnDestroy {
  @Input()
  showBranding = false;

  brand: Brand;
  private _distributorId: string;

  /**
   * The distributor ID or vanityId to use for the focused form.
   */
  @Input()
  get distributorId(): string | null {
    return this._distributorId;
  }
  set distributorId(value: string | null) {
    console.log('distributorId', value);

    if (value) {
      this.showLprxLogo = false;
      this.distributorService.get(value).subscribe(distributor => {
        this.brand = new Brand(distributor.business, distributor.logo);
        this.brand.safeLogo = this.cdn.getSafeUrl(this.brand.logoUrl);
        this.showBranding = true;
      });
    }

    this._distributorId = value;
  }

  @Input()
  background: string;

  @Input()
  size: 'sm' | 'md' = 'sm';

  private subs: Subscription[] = [];
  safeBackground: SafeStyle;
  private oldBg: string;

  private wrapperClassSizes = {
    sm: 'col-lg-4 col-md-7',
    md: 'col-xl-7 col-lg-8'
  };

  wrapperClasses: string = this.getWrapperClassSize();

  @Input()
  showLprxLogo = false;

  constructor(
    private header: HeaderService,
    public layout: LayoutService,
    private renderer: Renderer2,
    private domSan: DomSanitizer,
    private distributorService: DistributorsService,
    private cdn: CdnService
  ) {}

  ngOnInit() {
    this.setWrapperClasses();

    this.renderer.addClass(document.body, 'focused-form');

    if (this.background) {
      this.oldBg = document.body.style.background;
      document.body.style.background = this.background;
      document.body.style.backgroundSize = 'cover';
      document.body.style.backgroundAttachment = 'fixed';
      setContainerBgColor('transparent');
    }
    this.header.hide();

    if (this.showLprxLogo) {
      this.showBranding = true;
    }

    if (this.showBranding) {
      this.subs.push(
        this.layout.getBrand().subscribe(brand => {
          console.log('branding changed');
          console.log(brand);
          this.brand = brand;
          this.showBranding = true;
        })
      );
    }
  }

  private setWrapperClasses() {
    this.wrapperClasses = this.getWrapperClassSize();
  }

  private getWrapperClassSize() {
    return this.wrapperClassSizes[this.size];
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'focused-form');
    this.header.show();
    this.subs.forEach(s => s.unsubscribe());
    setContainerBgColor('#fafafa');
    document.body.style.background = this.oldBg;
  }
}
