import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentDaily'
})
export class PercentDailyPipe implements PipeTransform {
  transform(amount: number, base: number): string {
    return Math.floor((amount / base) * 100) + '%';
  }
}
