import { Injectable } from '@angular/core';
import axios from 'axios';
import { from as fromPromise } from 'rxjs';
import { User } from '../lprx-shared-lib/entities/user/user';
import { Api } from './aws.api';
import { LprxApiProvider } from './lprx-api/api-provider';
import { getTokenResponse } from '../lprx-auth';
import { apiHostname } from './lprx-api/api-hostname';

@Injectable()
export class ApiV2 {
  private user: User;

  /**
   * @param {Api} api
   * @param lprxApi
   */
  constructor(
    private api: Api,
    private lprxApi: LprxApiProvider,
  ) {}

  /**
   * @param path
   * @returns {any}
   */
  private static prefixPath(path: any): any {
    return '/api/' + path;
  }

  /**
   * @param path
   * @returns {Promise<any>}
   */
  async get(path: any) {
    path = ApiV2.prefixPath(path);

    // trim /
    if (path.charAt(0) === '/') {
      path = path.substr(1);
    }

    return this.lprxApi.get(path);
  }

  /**
   * @param path
   * @param data
   * @returns {Promise<any>}
   */
  async post(path: any, data: any) {
    try {
      /** local dev environment **/
      const response = await axios.post(`https://${apiHostname()}/api/${path}`, data, {
        headers: {
          // 'x-aws-cognito-username': getLastAuthUsername(),
          Authorization: `Bearer ${getTokenResponse().access_token}`,
        },
      });
      return response.data;
    } catch (e) {
      throw new Error(e.response.data.message);
    }
  }

  /**
   * @param path
   */
  get$(path: string) {
    return fromPromise(this.get(path));
  }

  /**
   * @param path
   * @param data
   */
  post$(path: any, data: any) {
    return fromPromise(this.post(path, data));
  }
}
