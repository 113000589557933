<app-loading *ngIf="!client"></app-loading>

<div [class.d-none]="!client" class="container my-5">
  <div class="row">
    <div class="col-md-6 mx-auto">
      <form #checkoutForm="ngForm" (ngSubmit)="submit($event)" class="checkout">
        <h4>Update Payment Method</h4>

        <p class="text-muted">Please enter your new payment details below.</p>

        <hr />
        <div class="mb-3">
          <input
            type="text"
            name="nameOnCard"
            [(ngModel)]="nameOnCard"
            class="form-control StripeElement name-on-card"
            placeholder="Name on Card"
            style="width: 100%"
            required
          />
        </div>
        <div class="mb-3">
          <div id="card-element" class="field form-control"></div>
        </div>
        <div id="card-errors" role="alert"></div>

        <div *ngIf="error" class="alert alert-danger">{{ error.message }}</div>

        <p class="text-right">
          <button type="button" (click)="cancel()" class="btn btn-link">Cancel</button>
          <button type="submit" [disabled]="isProcessing" appButton>Update</button>
        </p>
      </form>
    </div>
  </div>
</div>
