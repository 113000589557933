import { Component, Input } from '@angular/core';
import { Step } from '../../../../../lprx-shared-lib/entities/recipe/Step';

/**
 * Generated class for the RecipeStepInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'recipe-step-input',
  templateUrl: 'recipe-step-input.html',
})
export class RecipeStepInputComponent {
  @Input() step: Step;

  constructor() {}
}
