import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { sanitizeEmail } from '../../lprx-shared-lib/sanitize-email';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { errorMessage } from '../utilities/alert-http-error';

@Component({
  selector: 'app-magic-link',
  templateUrl: './magic-link.component.html',
  styleUrls: ['./magic-link.component.scss']
})
export class MagicLinkComponent implements OnInit {
  email: string;
  isProcessing: boolean;
  error: string;
  isSent = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private lprxApi: LprxApiProvider
  ) {
    this.email = this.router.getCurrentNavigation()?.extras?.state?.email;
  }

  ngOnInit(): void {}

  sendLink() {
    this.error = null;
    this.isSent = false;
    this.isProcessing = true;
    this.lprxApi
      .post('v3/magic-link', { email: sanitizeEmail(this.email) })
      .then(() => {
        this.isSent = true;
      })
      .catch((e: Error) => {
        this.error = errorMessage(e);
      })
      .finally(() => {
        this.isProcessing = false;
      });
  }
}
