<div class="bg-light py-4">
    <div class="container">
        <div class="row">
            <div class="col-lg-10 mx-auto">

                <div class="row">
                    <div class="col-auto">
                        <h5 class="card-title">Fullscript Recommendations</h5>
                    </div>
                    <div class="col-auto ml-auto d-none d-lg-block">
                        <a [routerLink]="['..']"><i class="fa fa-fw fa-calendar-o"></i> Planner</a>
                    </div>
                </div>


                <app-loading *ngIf="loading" [style]="'sm'"></app-loading>

                <ng-container *ngIf="!loading">


                    <div *ngIf="treatmentPlans.length === 0" class="card">
                        <div class="card-body text-center">
                            <strong>No recommendations at this time.</strong>
                        </div>
                    </div>

                    <ng-container *ngIf="treatmentPlans.length > 0">

                        <ng-container *ngFor="let treatmentPlan of treatmentPlans">

                            <div *ngIf="treatmentPlan.state !== 'cancelled'" class="card">

                                <div class="card-header">

                                    <div class="row align-items-center">
                                        <div class="col-auto">
                                            <h5 class="card-title">
                                                <a [href]="isClient ? treatmentPlan.invitation_url : treatmentPlan.link"
                                                   target="_blank">
                                                    <ng-container *ngIf="treatmentPlan.state === 'active'">
                                                        Activated
                                                        on {{(treatmentPlan.available_at * 1000) | epoch:"ll":true}}
                                                    </ng-container>

                                                    <ng-container *ngIf="treatmentPlan.state === 'draft'">
                                                        Last updated
                                                        on  {{(treatmentPlan.modifiedAt * 1000) | epoch:"ll":true}}
                                                    </ng-container>
                                                </a>
                                            </h5>
                                        </div>
                                        <div class="col-auto ml-auto">
                                            <a [href]="isClient ? treatmentPlan.invitation_url : treatmentPlan.link"
                                               appButton btnSmall
                                               target="_blank">{{isClient ? 'Order' : 'Edit'}}</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="list-group">

                                    <ng-container *ngFor="let recommendation of treatmentPlan.recommendations">
                                        <div class="list-group-item">
                                            <div class="row align-items-center">
                                                <div class="col-auto d-none d-lg-block">
                                                    <img [src]="fsImg(recommendation.variant.image_url_small)"
                                                         style="height: 70px"/>
                                                </div>
                                                <div class="col-2 col-lg-1">
                                                    <div
                                                        btnSecondary btnSmall>{{recommendation.units_to_purchase + 'x'}}
                                                    </div>
                                                </div>
                                                <div class="col-10 col-lg-8">
                                                    <strong>{{recommendation.variant.product.name}}</strong>
                                                    <ng-container *ngIf="recommendation.variant.units">
                                                        ({{recommendation.variant.units}}
                                                        {{recommendation.variant.unit_of_measure}})
                                                    </ng-container>

                                                    by {{recommendation.variant.product.brand.name}}

                                                    <div class="text-secondary">
                                                        <small>
                                                            {{recommendation.dosage.amount}}
                                                            {{recommendation.dosage.format}}
                                                            {{recommendation.dosage.frequency}}
                                                            {{recommendation.dosage.additional_info}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                </div>
                            </div>

                        </ng-container>

                    </ng-container>

                </ng-container>

            </div>
        </div>
    </div>

</div>
