import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdatePaymentMethodComponent } from '../account/update-payment-method/update-payment-method.component';
import { ActivateAccountComponent } from '../activate-account/activate-account.component';
import { ForgotPasswordCodeComponent } from '../auth/forgot-password-code/forgot-password-code.component';
import { ForgotPasswordComponent } from '../auth/forgot-password/forgot-password.component';
import { CreditCardInputModule } from '../credit-card-input/credit-card-input.module';
import { HeaderComponent } from '../header/header/header.component';
import { HomeComponent } from '../home/home.component';
import { LandingPageComponent } from '../landing-page/landing-page.component';
import { LoadingModule } from '../loading/loading.module';
import { LoginComponent } from '../login/login.component';
import { PrivacyComponent } from '../page/privacy/privacy.component';
import { TermsHtmlModule } from '../page/terms/terms-html/terms-html.module';
import { TermsComponent } from '../page/terms/terms.component';
import { PipesModule } from '../pipe/pipes.module';
import { RecipeViewerModule } from '../recipe/recipe-view/recipe-view.module';
import { RecipeViewerComponent } from '../recipe/recipe-viewer/recipe-viewer.component';
import { SharedModule } from '../shared/shared.module';
import { ConfirmComponent } from '../signup/confirm/confirm.component';
import { SignupComponent } from '../signup/signup.component';
import { ButtonDirective } from '../../component-directives/button.directive';
import { LucideAngularModule } from 'lucide-angular';

@NgModule({
  declarations: [
    HomeComponent,
    LoginComponent,
    SignupComponent,
    ConfirmComponent,
    HeaderComponent,
    LandingPageComponent,
    RecipeViewerComponent,
    ActivateAccountComponent,
    TermsComponent,
    PrivacyComponent,
    ForgotPasswordComponent,
    ForgotPasswordCodeComponent,
    UpdatePaymentMethodComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TermsHtmlModule,
    RouterModule,
    NgbCollapseModule,
    RecipeViewerModule,
    LoadingModule,
    CreditCardInputModule,
    PipesModule,
    ButtonDirective,
    LucideAngularModule,
  ],
  exports: [
    HomeComponent,
    LoginComponent,
    SignupComponent,
    ConfirmComponent,
    HeaderComponent,
    LandingPageComponent,
    RecipeViewerComponent,
    ActivateAccountComponent,
    TermsComponent,
    PrivacyComponent,
    ForgotPasswordComponent,
    ForgotPasswordCodeComponent,
    UpdatePaymentMethodComponent,
  ],
})
export class BasicsModule {}
