import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

const now = new Date();

@Component({
  selector: 'app-planner-calendar-select',
  templateUrl: './planner-calendar-select.component.html',
  styleUrls: ['./planner-calendar-select.component.scss']
})
export class PlannerCalendarSelectComponent implements OnInit {
  @Input() weekNumber: string;
  @Output() selectedWeekNumber = new EventEmitter<string>();

  model: NgbDateStruct;
  date: { year: number; month: number };

  constructor() {}

  ngOnInit() {}

  selected(event) {
    console.log(event);
    if (this.model) {
      const week = moment()
        .year(this.model.year)
        .month(this.model.month - 1)
        .date(this.model.day)
        .isoWeek();
      const paddedWeek = ('0' + week.toString()).slice(-2); // 1 -> 01
      const weekNumber = this.model.year + '.' + paddedWeek;
      this.selectedWeekNumber.emit(weekNumber);
    }
  }
}
