<div class="row align-items-center py-1">
  <div class="col">
    <input [(ngModel)]="ingredientText" class="form-control" (keydown)="handleKeyUp($event)" />
  </div>
  <div class="col-auto ml-auto">
    <button mat-icon-button type="button" (click)="saveIngredient()" [color]="'primary'">
      <mat-icon>check</mat-icon>
    </button>
    <!-- cancel button -->
    <button
      mat-icon-button
      type="button"
      (click)="cancelIngredient()"
      title="Cancel"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>
