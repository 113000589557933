/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/dto/change-resource-item-access-level.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { DistributorAccessLevel } from '../../distributor-access-level';

export class ChangeResourceItemAccessLevel {
  level: DistributorAccessLevel;
}
