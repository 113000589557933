import { infinitySymbol } from '../../lprx-shared-lib/vars';

export interface MacroOption {
  step: number;
  key: string;
  title: string;
  floor: number;
  ceil: number;
  low: number;
  high: number;
  highDisplay: string;
}

export const macroOptions: MacroOption[] = [
  {
    key: 'ENERC_KCAL',
    title: 'Calories',
    floor: 0,
    ceil: 800,
    step: 20,
    low: 0,
    high: 800,
    highDisplay: infinitySymbol,
  },
  {
    key: 'CHOCDF',
    title: 'Carbs (g)',
    floor: 0,
    ceil: 100,
    step: 20,
    low: 0,
    high: 100,
    highDisplay: infinitySymbol,
  },
  {
    key: 'FIBTG',
    title: 'Fiber (g)',
    floor: 0,
    ceil: 50,
    step: 10,
    low: 0,
    high: 50,
    highDisplay: infinitySymbol,
  },
  {
    key: 'SUGAR',
    title: 'Sugar (g)',
    floor: 0,
    ceil: 100,
    step: 10,
    low: 0,
    high: 100,
    highDisplay: infinitySymbol,
  },
  {
    key: 'PROCNT',
    title: 'Protein (g)',
    floor: 0,
    ceil: 100,
    step: 10,
    low: 0,
    high: 100,
    highDisplay: infinitySymbol,
  },
  {
    key: 'NA',
    title: 'Sodium (mg)',
    floor: 0,
    ceil: 500,
    step: 10,
    low: 0,
    high: 500,
    highDisplay: infinitySymbol,
  },
  {
    key: 'FAT',
    title: 'Fat (g)',
    floor: 0,
    ceil: 200,
    step: 10,
    low: 0,
    high: 200,
    highDisplay: infinitySymbol,
  },
].map((o) => {
  o.ceil += o.step;
  o.high = o.ceil;
  o.highDisplay = infinitySymbol;
  return o;
});
