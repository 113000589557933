import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { ApiV2 } from '../api.v2';

@Injectable()
export class AdminDistributorsService {
  constructor(private apiV2: ApiV2) {}

  /**
   * @param term
   */
  search(term: string): Observable<Distributor[]> {
    return this.apiV2.post$('admin/distributors/search', { term });
  }

  /**
   * @param distributorId
   */
  get(distributorId: string) {
    return this.apiV2.get$(`admin/distributors/${distributorId}`);
  }

  /**
   * @param distributor
   */
  save(distributor: Distributor): Observable<Distributor> {
    return this.apiV2
      .post$(`admin/distributors`, distributor)
      .pipe(map((distributorResponse) => plainToClass(Distributor, distributorResponse as object)));
  }
}
