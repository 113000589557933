import { plainToClass } from 'class-transformer';
import { PurchaseExtensionResult } from '../../lprx-shared-lib/dto/purchase-extension-result';
import { PurchaseBundleDto } from '../../lprx-shared-lib/bundles/purchase-bundle-dto';
import { PurchaseExtensionDto } from '../../lprx-shared-lib/dto/purchase-extension-dto';
import { BundleClient } from '../../lprx-shared-lib/entities/bundle-client';
import { DistributorBundle } from '../../lprx-shared-lib/entities/bundle/bundle';
import { PlannerType } from '../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { LprxApi } from './api';

export class Bundles {
  constructor(private readonly lprxApi: LprxApi) {}

  async purchase(bundleId, paymentMethod, applyCredits) {
    const dto: PurchaseBundleDto = {
      bundleId,
      paymentMethod,
      applyCredits,
    };
    return this.lprxApi.post('v3/bundles/purchase', dto);
  }

  get(bundleId: string): Promise<DistributorBundle> {
    return this.lprxApi
      .get(`v3/bundles/${bundleId}`)
      .then((data: object) => plainToClass(DistributorBundle, data));
  }

  clients(bundleId: string): Promise<BundleClient[]> {
    return this.lprxApi
      .get(`v3/bundles/${bundleId}/clients`)
      .then((data: object[]) => plainToClass(BundleClient, data));
  }

  extend(
    clientId: string,
    sourceId: string,
    expirationTime: number,
    plannerType: PlannerType,
    applyCredits: number
  ): Promise<PurchaseExtensionResult> {
    const dto: PurchaseExtensionDto = {
      client: clientId,
      sourceId,
      expirationTime,
      plannerType,
      applyCredits,
    };
    return this.lprxApi.post(`v3/bundles/extend`, dto);
  }

  delete(bundleId: string) {
    return this.lprxApi.delete(`v3/bundles/${bundleId}`);
  }
}
