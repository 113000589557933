import { Bundles } from './bundles';
import { Clients } from './clients/clients';
import { Credits } from './credits';
import { DistributorPlanConfigs } from './distributor-plan-config';
import { DistributorApplications } from './distributors/distributor-applications';
import { Distributors } from './distributors/distributors';
import { Fullscript } from './fullscript';
import { IRestHttpHandler } from './i-rest-http-handler';
import { MasterPlans } from './master-plans';
import { MealPlans } from './meal-plans/meal-plans';
import { Notifications } from './notifications/notifications';
import { PrimePurchase } from './prime-purchase';
import { Recipes } from './recipes';
import { Resources } from './resources/resources';
import { Users } from './users';
import { WeeklyPlans } from './weekly-plans/weekly-plans';
import { ToastrService } from 'ngx-toastr';
import { errorMessage } from '../../app/utilities/alert-http-error';

export class LprxApi {
  resources: Resources = new Resources(this);
  distributors: Distributors = new Distributors(this);
  distributorApplications: DistributorApplications = new DistributorApplications(this);
  clients: Clients = new Clients(this);
  mealPlans: MealPlans = new MealPlans(this);
  weeklyPlans: WeeklyPlans = new WeeklyPlans(this);
  masterPlans: MasterPlans = new MasterPlans(this);
  primePurchase: PrimePurchase = new PrimePurchase(this);
  recipes: Recipes = new Recipes(this);
  credits: Credits = new Credits(this);
  bundles: Bundles = new Bundles(this);
  users: Users = new Users(this);
  fullscript: Fullscript = new Fullscript(this);
  distributorPlanConfig = new DistributorPlanConfigs(this);
  notifications = new Notifications(this);

  constructor(private readonly httpHandler: IRestHttpHandler, private toastr: ToastrService) {}

  async delete<T = any>(path: string): Promise<T> {
    return this.httpHandler.delete(path).catch((error) => {
      this.toastr.error(errorMessage(error));
      throw error;
    });
  }

  get<T = any>(path: string, queryParams?: any): Promise<T> {
    return this.httpHandler.get(path, queryParams);
  }

  async head(path: string): Promise<any> {
    return undefined;
  }

  async options(path: string): Promise<any> {
    return undefined;
  }

  async patch<T = any, P = any>(path: string, payload?: P): Promise<T> {
    return this.httpHandler.patch(path, payload).catch((error) => {
      this.toastr.error(errorMessage(error));
      throw error;
    });
  }

  async post<T = any, P = any>(path: string, payload?: P): Promise<T> {
    return this.httpHandler.post(path, payload).catch((error) => {
      this.toastr.error(errorMessage(error));
      throw error;
    });
  }

  async put<T = any>(path: string, payload?: any): Promise<T> {
    return this.httpHandler.put(path, payload).catch((error) => {
      this.toastr.error(errorMessage(error));
      throw error;
    });
  }

  async time(): Promise<number> {
    return fetch('https://api.livingplaterx.com/v3/dmz/time')
      .then((res) => {
        return res.text();
      })
      .then((text) => {
        const serverTime = parseInt(text, 10);
        console.log('Time: ' + serverTime);
        return serverTime;
      });
  }

  async saveVar<T>(key: string, value: T): Promise<void> {
    const key64 = btoa(key);
    await this.post<T>(`v3/vars/${key64}?${key}`, value);
  }

  async getVar<T>(key: string): Promise<T> {
    // base64 encode the key
    const key64 = btoa(key);
    return this.get<T>(`v3/vars/${key64}?${key}`);
  }
}
