import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';

@Injectable()
export class UserGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService._getUser();
    if (this.authService._getUser().userType === UserType.Client) {
      return true;
    }
    console.log('User is not a client, redirecting to login.', user);
    this.router.navigateByUrl('/login');
  }
}
