/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/vars.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export const NoProofImage = '/assets/img/no-proof.png';

export const infinitySymbol = '∞';

export const nonCalendarStartWeek = '1970.01';

export const DEFAULT_PDF_COVER_BACKGROUND_IMAGE = 'images/hero-banner-002_612x792.jpg';

export const LAUNCH_DATE = 1715558401000; // Monday, 13th of November 2024 12:00:01 AM GMT+00:00

export function BEFORE_LAUNCH() {
  return Date.now() < LAUNCH_DATE;
}

export function AFTER_LAUNCH() {
  return !BEFORE_LAUNCH();
}
