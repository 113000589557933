<main role="main" class="container py-4">
  <h2 class="view-title">My Account</h2>

  <div class="my-3">
    <div class="card">
      <div class="card-header">Subscription</div>
      <div class="card-body">
        <div
          *ngIf="
            accountPaidBy === 'distributor' || !subscription || subscription.status != 'active'
          "
          class=""
        >
          <em>Ends at: {{ this.user.terminatesAt | epoch: 'lll' }}</em>
        </div>

        <div *ngIf="subscription && subscription.status === 'active'" class="row">
          <div class="col-md-6">
            <strong>
              {{ subscription.plan.nickname }} -
              {{ billingAmount | pricingWithCurrency: subscription.plan.currency }}/{{
                subscription.plan.interval
              }}
              <span *ngIf="canUpgrade">- <a routerLink="/account/upgrade">Upgrade</a> </span>
            </strong>
            <br />
            <em>{{ isActiveSubscription ? 'Renews' : 'Cancels' }} on: {{ nextRenewal }}</em>

            <br />
            <br />
            <p>
              <a routerLink="/update-payment-method" btnSecondary btnSmall
                >Update Payment Method</a
              >
            </p>
          </div>
          <div class="col-md-6">
            <div *ngIf="isActiveSubscription">
              <div *ngIf="!showCancelAccountConfirmation" class="text-right">
                <button type="button" btnDanger (click)="showCancelAccountConfirmation = true">
                  I'd like to cancel my subscription.
                </button>
              </div>
              <div *ngIf="showCancelAccountConfirmation">
                <strong>Are you sure? We'd really hate to see you go.</strong>
                <br />
                <br />

                <button type="button" btnDanger class="mb-2" (click)="cancelAccount()">
                  Yes, please cancel my subscription.
                </button>

                <button
                  type="button"
                  class="btn mb-2"
                  (click)="showCancelAccountConfirmation = false"
                >
                  I changed my mind.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="my-3">
    <div class="card">
      <div class="card-header">Email Preferences</div>
      <div class="card-body">
        <div *ngIf="receiveWeeklyEmails">
          <p class="text-success">You are receiving weekly meal plan emails.</p>
          <button type="button" btnDanger btnSmall (click)="changeWeeklyEmails(false)">
            Turn Off
          </button>
        </div>

        <div *ngIf="!receiveWeeklyEmails">
          <p class="text-danger">You are not receiving weekly meal plan emails.</p>
          <button type="button" class="btn btn-success btn-sm" (click)="changeWeeklyEmails(true)">
            Turn On
          </button>
        </div>
      </div>
    </div>
  </div>
</main>
