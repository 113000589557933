import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../service/header.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  constructor(private header: HeaderService) {}

  ngOnInit() {
    this.header.hideBackButton();
  }
}
