import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserType } from '../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  show: boolean = false;
  private sub: Subscription;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(Window) private window: Window
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    console.log('Route', this.route);
    console.log('Path', window.location.pathname);

    // const hostMatcher = /lprx\.ngrok\.io/;
    const hostMatcher = /[^.]*\.livingplaterx\.com/;

    if (window.location.host.match(hostMatcher)) {
      window.location.assign('https://livingplaterx.com');
      return;
    }

    this.sub = this.auth.getUser().subscribe({
      next: (user) => {
        if (!user) {
          this.router.navigateByUrl('/login');
          return;
        }

        switch (user.userType) {
          case UserType.Admin:
            this.router.navigateByUrl('/admin/dashboard');
            break;
          case UserType.Client:
            this.router.navigateByUrl('/planner');
            break;
          case UserType.Distributor:
            this.router.navigateByUrl('/distributor/dashboard');
            break;
          case UserType.System:
          case UserType.Anonymous:
          default:
            this.router.navigateByUrl('/login');
        }
      },
      error: (error) => {},
    });
  }
}
