<ng-container *ngIf="false">
  <app-focused-form
    [background]="'url(\'/assets/img/home/hero-banner-002.jpg\') center center no-repeat'"
  >
    <div [hidden]="processingMagicLink">
      <form *ngIf="loginDetails" class="form-signin" (submit)="login()" #loginForm="ngForm">
        <!-- <img class="mb-4" src="https://getbootstrap.com/assets/brand/bootstrap-solid.svg" alt="" width="72" height="72"> -->
        <h1 class="h4 mb-3 font-weight-normal text-center">Log In</h1>

        <div *ngIf="error" class="alert alert-danger">
          {{ error.message }}
        </div>

        <app-bad-clock></app-bad-clock>

        <div *ngIf="warning" class="alert alert-warning">
          {{ warning }}
        </div>

        <label for="inputEmail" class="sr-only">Email address</label>
        <input
          type="email"
          [(ngModel)]="loginDetails.email"
          id="inputEmail"
          class="form-control mb-3"
          placeholder="Email address"
          required=""
          autofocus=""
          name="email"
        />

        <label for="inputPassword" class="sr-only">Password</label>
        <input
          type="password"
          [(ngModel)]="loginDetails.password"
          id="inputPassword"
          class="form-control mb-3"
          placeholder="Password"
          required=""
          name="password"
        />

        <button
          appButton btnSmall fullWidth
          type="submit"
          [disabled]="!loginForm.form.valid || isProcessing"
        >
          <span *ngIf="!isProcessing">Log In</span>
          <i *ngIf="isProcessing" class="fa fa-spinner fa-spin"></i>
        </button>
      </form>

      <div class="text-center">
        <br />
        <br />

        <p class="text-muted">
          <span style="letter-spacing: -3px !important"
            >&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</span
          >
          or
          <span style="letter-spacing: -3px !important"
            >&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;&mdash;</span
          >
        </p>

        <br />

        <a
          routerLink="/magic-link"
          [state]="{ email: loginDetails.email }"
          class="btn btn-outline-secondary btn-block"
          >Log in with a Link</a
        >

        <br />
        <br />

        <a routerLink="/forgot-password" class="text-secondary">My password isn't working.</a>
      </div>
    </div>

    <app-loading *ngIf="processingMagicLink" [style]="'icon'" term="Just a moment..."></app-loading>
  </app-focused-form>
</ng-container>
