import { Injectable } from '@angular/core';
import { Observable ,  from as fromPromise } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';

@Injectable()
export class ClientAccountService {
  constructor(private apiV2: ApiV2, private readonly lprxApi: LprxApiProvider) {}

  /**
   * Upgrade the current logged in client
   *
   * @param source
   */
  upgrade(source): Observable<boolean> {
    return this.apiV2.post$('client/account/upgrade', { source });
  }

  /**
   * Get the preview invoice for an upgrade
   */
  upgradePreview(): Observable<any> {
    return this.apiV2.get$('client/account/upgrade/preview');
  }

  /**
   * Cancel the current logged in client's subscription
   */
  cancelAccount(): Observable<boolean> {
    return fromPromise(this.lprxApi.delete(`v3/subscription`));
  }
}
