import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CdnPipe } from './cdn.pipe';
import { ClientViewLinkPipe } from './client-view-link.pipe';
import { CurrencySymbolPipe } from './currency-symbol.pipe';
import { DayAsAbbrvPipe } from './day-as-abbrv.pipe';
import { DayAsLongPipe } from './day-as-long.pipe';
import { DayDisplayPipe } from './day-display.pipe';
import { EpochPipe } from './epoch.pipe';
import { HumanWeekPipe } from './human-week.pipe';
import { IngredientDisplayPipe } from './ingredient-display.pipe';
import { NgbdatePipe } from './ngbdate.pipe';
import { NutrientValuePipe } from './nutrient-value.pipe';
import { PercentDailyPipe } from './percent-daily.pipe';
import { PlannerTypePipe } from './planner-type.pipe';
import { RoundPipe } from './round.pipe';
import { SanitizeUrlPipe } from './sanitize-url.pipe';
import { SourceDisplayPipe } from './source-display.pipe';
import { TruncatePipe } from './truncate.pipe';
import { WindowSizePipe } from './window-size.pipe';
import { PricingWithCurrencyPipe } from './pricing-with-currency.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    HumanWeekPipe,
    IngredientDisplayPipe,
    SanitizeUrlPipe,
    SourceDisplayPipe,
    EpochPipe,
    NgbdatePipe,
    PercentDailyPipe,
    RoundPipe,
    DayDisplayPipe,
    TruncatePipe,
    NutrientValuePipe,
    CdnPipe,
    WindowSizePipe,
    DayAsLongPipe,
    DayAsAbbrvPipe,
    PlannerTypePipe,
    ClientViewLinkPipe,
    CurrencySymbolPipe,
    PricingWithCurrencyPipe
  ],
  exports: [
    HumanWeekPipe,
    IngredientDisplayPipe,
    SanitizeUrlPipe,
    SourceDisplayPipe,
    EpochPipe,
    NgbdatePipe,
    PercentDailyPipe,
    RoundPipe,
    DayDisplayPipe,
    TruncatePipe,
    NutrientValuePipe,
    CdnPipe,
    WindowSizePipe,
    DayAsLongPipe,
    PlannerTypePipe,
    CurrencySymbolPipe,
    PricingWithCurrencyPipe
  ]
})
export class PipesModule {}
