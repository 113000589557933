import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FocusedFormComponent } from '../layout/focused-form/focused-form.component';
import { BadClockComponent } from '../util/bad-clock/bad-clock.component';
import {
  ButtonDirective,
  DangerButtonDirective,
  SecondaryButtonDirective,
} from '../../component-directives/button.directive';

@NgModule({
  declarations: [FocusedFormComponent, BadClockComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatChipsModule,
    ClipboardModule,
    ButtonDirective,
    DangerButtonDirective,
    SecondaryButtonDirective,
  ],
  exports: [
    MatCheckboxModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatChipsModule,
    FormsModule,
    ReactiveFormsModule,
    FocusedFormComponent,
    BadClockComponent,
    ButtonDirective,
    DangerButtonDirective,
    SecondaryButtonDirective,
  ],
})
export class SharedModule {}
