import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout/layout.service';
import { Brand } from '../../model/entities/brand';
import { RecipeSearchService } from '../../planner/recipe-search.service';
import { BackButtonConfig } from '../../service/BackButtonConfig';
import { CdnService } from '../../service/cdn.service';
import { HeaderService } from '../../service/header.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLoggedIn = false;
  logoUrl: string;
  brand: Brand;
  showBranding = true;
  showHeader = true;
  navbarCollapsed = true;

  // userType: string;
  private subs: Subscription[] = [];
  user: User;
  backButton: BackButtonConfig = null;
  hasPlans = false;
  private latestHeight: number;

  constructor(
    private auth: AuthService,
    private cdn: CdnService,
    private el: ElementRef,
    private header: HeaderService,
    public layout: LayoutService,
    private location: Location,
    private recipeSearch: RecipeSearchService,
    private router: Router
  ) {}

  ngOnInit() {
    this.subs.push(
      this.auth.getIsSignedIn().subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn)),
      this.layout.getBrand().subscribe((brand) => this.setBranding(brand)),
      this.header.getVisibility().subscribe((show) => (this.showHeader = show)),
      this.header.getShowBranding().subscribe((showBranding) => (this.showBranding = showBranding)),
      this.header.backButton$.subscribe((backButton) => {
        this.backButton = backButton;
      }),
      this.auth.getUser().subscribe((user) => (this.user = user))
    );

    this.subs.push(
      interval(1250)
        .pipe(switchMap(() => of(this.el.nativeElement.offsetHeight)))
        .subscribe(() => {
          if (this.latestHeight !== this.el.nativeElement.offsetHeight) {
            console.log('Header Height changed to ' + this.el.nativeElement.offsetHeight);
            this.latestHeight = this.el.nativeElement.offsetHeight;
            this.layout.headerHeight(this.latestHeight + 'px');
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private setBranding(brand) {
    this.brand = brand;
    if (brand) {
      this.logoUrl = this.cdn.getUrl(this.brand.logoUrl);
      this.showBranding = true;
    }
  }

  openSideNav() {
    this.layout.openSideNav();
  }

  openPlannerSearch() {
    this.recipeSearch.clear();
    this.router.navigateByUrl('/planner/search');
  }

  openGroceryList() {
    this.router.navigateByUrl('/planner/grocery-list');
  }

  goBack() {
    if (this.backButton.url) {
      this.router.navigateByUrl(this.backButton.url, this.backButton.extras);
    } else {
      this.location.back();
    }
  }

  openPlanner() {
    this.router.navigateByUrl('/planner');
  }

  toRecommendations() {
    this.router.navigateByUrl('/planner/recommendations');
  }
}
