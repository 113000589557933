/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/source-display-label.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export function sourceDisplayLabel(value: string, lineBreak?: boolean) {
  if (value && value.toLowerCase() === 'adapted') {
    return value + ' from ' + (lineBreak ? '<br>' : '');
  }
  return value;
}
