import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { UnitSystem } from '../../lprx-shared-lib/enum/unit-system';
import { UnitSystemService } from './unit-system-service';

@Component({
  selector: 'app-unit-system-toggle',
  templateUrl: './unit-system-toggle.component.html',
  styleUrls: ['./unit-system-toggle.component.scss']
})
export class UnitSystemToggleComponent implements OnInit, OnDestroy {
  unitSystem: UnitSystem;

  UnitSystem = UnitSystem;

  private sub: Subscription;

  @Output()
  unitSystemChanged = new EventEmitter<UnitSystem>();

  constructor(private readonly unitSystemService: UnitSystemService) {}

  ngOnInit() {
    this.sub = this.unitSystemService.unitSystem$.subscribe(system => (this.unitSystem = system));
  }

  changeSystem(unitSystemName: UnitSystem) {
    this.unitSystemService.set(unitSystemName);
    this.unitSystemChanged.emit(this.unitSystemService.get());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
