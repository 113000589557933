<main role="main" class="container py-5">
    <div class="row">
        <div class="col-md-6 mx-auto bg-light py-4">
            <h3 class="">Planner Upgrade</h3>
            <hr>

            <div class="text-center py-5 px-md-5">
                <h5>
                    <strong>Thank you!</strong><br>Your planner has been upgraded to the
                    <span style="display: inline-block">Custom Plan.</span>
                </h5>
            </div>


            <p class="text-center">
                <a routerLink="/planner" btnSecondary btnSmall>Back to My Planner</a>
            </p>

        </div>
    </div>
</main>
