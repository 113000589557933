/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Source.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass } from 'class-transformer';
import { SourceType } from '../../enum/source-type';
import { Default } from '../../utils/default.decorator';

@Exclude()
export class Source {
  @Expose()
  @Default(SourceType.Adapted)
  type: SourceType = SourceType.Adapted;

  @Expose()
  name: string;

  @Expose()
  url: string;

  static fromObject(object: any): Source {
    return plainToClass(Source, object);
  }
}
