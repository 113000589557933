/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/default-nutrients.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Nutrients } from './entities/recipe/Nutrients';

export const defaultNutrients = new Set<string>([
  'ENERC_KCAL',
  'FAT',
  'CHOCDF',
  'PROCNT',
  'SUGAR',
  'FIBTG',
  'NA'
]);

export const allNutrients: Set<string> = new Set<string>(
  Object.getOwnPropertyNames(new Nutrients())
);
