import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this.authService._getUser();
    if (user.userType === 'admin') {
      return true;
    }

    console.log('User is not an admin, redirecting to login.', user);
    this.router.navigateByUrl('/login');
  }
}
