<div class="tw-p-5">
  <h4 class="tw-mb-7">Copy as Leftovers</h4>

  <div class="flex tw-items-center text-lg tw-mb-7">
    <img [ngSrc]="recipeSrc" class="" alt="{{ data.recipe.title }}" width="90" height="90" />
    <span class="tw-pl-3 tw-text-lg">
      {{ data.recipe.title }}
    </span>
  </div>

  <ng-container *ngIf="!data.nextDays.length">
    <ng-container *ngIf="user.userType === UserType.Client"> No days to copy to. </ng-container>
    <ng-container *ngIf="user.userType !== UserType.Client">
      <strong>No additional weeks loaded in planner.</strong>
      <br />
      <p class="tw-text-right">
        <button btnSecondary type="button" (click)="cancel()">Cancel</button>
      </p>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="data.nextDays.length > 0">
    <div>
      <p><strong>Select a Meal</strong></p>
      <label
        *ngFor="let meal of meals"
        class="tw-mr-2 tw-p-2 tw-px-4 day-select pill clickable"
        [class.selected]="selectedMealId === meal.id"
      >
        <input
          type="radio"
          name="meal-select"
          (click)="selectMeal(meal)"
          [checked]="selectedMealId === meal.id"
          [hidden]="true"
        />
        {{ meal.name }}</label
      >
    </div>

    <div class="tw-mb-7">
      <p><strong>Select Days</strong></p>
      <div class="tw-flex tw-flex-wrap">
        <label
          *ngFor="let next of data.nextDays"
          class="day-select tw-mr-2 tw-p-3 clickable"
          [class.selected]="isSelected(next)"
        >
          <input type="checkbox" (change)="changed($event, next)" [hidden]="true" />
          <ng-container *ngIf="next.weekNumber > '2000.00'">
            {{ next.dayName | dayDisplay: next.weekNumber : 'ddd' }}<br />
            {{ next.dayName | dayDisplay: next.weekNumber : 'MMM Do' }}
          </ng-container>
          <ng-container *ngIf="next.weekNumber < '2000.00'">
            {{ next.dayName | dayDisplay: next.weekNumber : 'ddd' }}
          </ng-container>
        </label>
      </div>
    </div>

    <p class="tw-text-right">
      <button btnSecondary type="button" (click)="cancel()">Cancel</button>
      &nbsp;
      <button btnPrimary (click)="copy()" [disabled]="selected.length == 0">Copy</button>
    </p>
  </ng-container>
</div>
