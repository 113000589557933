import { Component, Input, OnInit } from '@angular/core';

import { nutrientTypes } from './nutrientTypes';
import { NutrientsCollection } from '../../lprx-shared-lib/recipe/NutrientsCollection';

@Component({
  selector: 'app-nutrition-facts-label',
  templateUrl: './nutrition-facts-label.component.html',
  styleUrls: ['./nutrition-facts-label.component.scss'],
})
export class NutritionFactsLabelComponent implements OnInit {
  @Input() nutrients: NutrientsCollection;
  @Input() servings: number;

  nutrientTypes = nutrientTypes;

  showAllNutrients = false;

  @Input() showDisclaimer: boolean = true;
  @Input() enableShowAllNutrients: boolean = true;

  constructor() {}

  ngOnInit() {
    this.nutrientTypes.sort((a, b) => {
      return a.order < b.order ? -1 : 1;
    });
  }

  percentDaily(amount: number, base: number) {
    return Math.floor((amount / base) * 100) + '%';
  }

  toggleShowAllNutrients() {
    this.showAllNutrients = !this.showAllNutrients;
  }
}
