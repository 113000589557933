import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Bundle } from '../../../lprx-shared-lib/entities/bundle/bundle';
import { ApiV2 } from '../../../providers/api.v2';

@Injectable()
export class DistributorBundlesService {
  constructor(private apiV2: ApiV2) {}

  private toBundleClass = (bundleObject: Bundle) => plainToClass(Bundle, bundleObject);

  /**
   * @param bundleId
   */
  get(bundleId: string): Observable<Bundle> {
    return this.apiV2.get$(`distributor/bundles/${bundleId}`).pipe(map(this.toBundleClass));
  }

  all(): Observable<Bundle[]> {
    return this.apiV2
      .get$('distributor/bundles')
      .pipe(map((_bundleObjects: object[]) => plainToClass(Bundle, _bundleObjects as object[])));
  }

  /**
   *
   * @param bundle
   */
  save(bundle: Bundle) {
    return this.apiV2.post$('distributor/bundles', bundle).pipe(
      map(this.toBundleClass),
      tap((b: Bundle) => console.log(`Saved DistributorBundle(${b.distributorId}, ${b.bundleId}`))
    );
  }
}
