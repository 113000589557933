import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { Stripe } from 'stripe';

@Injectable()
export class DistributorAccountService {
  constructor(private apiV2: ApiV2, private lprxApi: LprxApiProvider) {}

  getPaymentMethods() {
    return this.apiV2.get$('distributor/account/billing/payment-methods');
  }

  getAvailableCredit(): Observable<number> {
    return this.apiV2.get$('distributor/account/billing/available-credit');
  }

  /**
   * Get the Practitioner's Stripe subscription
   *
   * @returns {Promise<Stripe.Subscription>}
   */
  async getStripeSubscription(): Promise<Stripe.Subscription> {
    try {
      const res = await this.lprxApi.get<{ subscription: Stripe.Subscription }>(
        'v3/account/subscription'
      );
      return res.subscription;
    } catch (err) {
      console.log('err', err);
      throw err;
    }
  }

  /**
   * Get the Practitioner's Stripe billing page
   *
   * @returns {Promise<string>}
   */
  async getStripeBillingPage(): Promise<string> {
    const url = await this.lprxApi.get<{ redirectUrl: string }>('v3/account/customer-portal');
    return url.redirectUrl;
  }
}
