<app-focused-form size='md'>
  <h3 class="text-center">Profile Setup</h3>

  <p>We have detected that you have not completed your Practitioner profile setup.</p>
  <p>
    Please use the button below to complete your profile setup. We'll email instructions to:
    <strong>{{ email }}</strong
    >
  </p>

  <button type="button" (click)="sendEmail()" appButton btnSmall fullWidth>
    Please, send me my Profile Setup email.
  </button>

  <div *ngIf="emailSent" class="alert alert-success text-center">
    Email sent! Please check your inbox.
  </div>
</app-focused-form>
