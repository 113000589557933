import { Pipe, PipeTransform } from '@angular/core';
import { dayAsLong } from '../../lprx-shared-lib/day-as-long';
import { Case } from '../../lprx-shared-lib/utils/case';

@Pipe({
  name: 'dayAsLong'
})
export class DayAsLongPipe implements PipeTransform {
  transform(day: any, asCase: Case | string = Case.Upper): any {
    return dayAsLong(day, asCase);
  }
}
