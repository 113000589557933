import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, from, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ClientCoupon, ClientCouponType } from '../../lprx-shared-lib/client-coupon';
import { DistributorPlanConfig } from '../../lprx-shared-lib/entities/distributor-config';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { Plans } from '../../lprx-shared-lib/entities/plans';
import { PlannerType } from '../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { IDEMPOTENCY_KEY_PREFIX } from '../../lprx-shared-lib/utils/constants';
import { createId } from '../../lprx-shared-lib/utils/id-generator';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { AuthService } from '../auth.service';
import { DistributorPlanConfigService } from '../distributor/distributor-plan-config.service';
import { LayoutService } from '../layout/layout.service';
import { PricingWithCurrencyPipe } from '../pipe/pricing-with-currency.pipe';
import { UserService } from '../service/user.service';
import { CreditCardInputComponent } from '../credit-card-input/credit-card-input.component';

interface BuyPlanType {
  name: string;
  price: number;
  code: string;
  plannerType: PlannerType;
}

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss'],
  providers: [PricingWithCurrencyPipe],
})
export class BuyComponent implements OnInit, OnDestroy, AfterViewInit {
  isProcessing = false;

  @ViewChild('ccForm', { static: true })
  creditCardInputComponent: CreditCardInputComponent;

  // generate an idempotent token to prevent duplicate subscriptions
  idempotentToken = createId(IDEMPOTENCY_KEY_PREFIX);

  errors: string[] = [];

  currency = 'usd';

  chosenPlan!: BuyPlanType;

  plans: { [key: string]: BuyPlanType } = {
    static: {
      name: 'Weekly Static Plans',
      price: 9,
      code: '',
      plannerType: PlannerType.Static,
    },
    custom: {
      name: 'Weekly Custom Plans',
      price: 19,
      code: '',
      plannerType: PlannerType.Custom,
    },
  };

  showStaticOption = true;
  showCustomOption = true;

  nameOnCard: string;
  @Input() viewState = 'subscribe';

  @Output() closedUpgradePrompt = new EventEmitter<boolean>();

  initialViewState = '';

  private subs: Subscription[] = [];
  purchaseError: string;

  showUpdatePaymentMethodLink = false;
  enableChoosePlan: boolean = true;
  isLoading: boolean = true;
  promoCode: string;
  planConfig!: DistributorPlanConfig;
  promoCodeApplied: boolean = false;
  private coupon: ClientCoupon;
  promoCodeError: string;
  promoCodeMessage: string;
  private showPromoCodeApplied: boolean;
  mealPlan: MealPlan;

  constructor(
    private auth: AuthService,
    public layout: LayoutService,
    private userService: UserService,
    private route: ActivatedRoute,
    private readonly lprxApi: LprxApiProvider,
    private configService: DistributorPlanConfigService,
    private pricingWithCurrencyPipe: PricingWithCurrencyPipe
  ) {}

  ngAfterViewInit() {
    this.subs.push(
      this.userService.getSubscription().subscribe((subscription: any) => {
        this.showUpdatePaymentMethodLink =
          subscription !== false && subscription.status !== 'canceled';
      })
    );
  }

  ngOnInit() {
    this.initialViewState = this.viewState;

    this.subs.push(
      this.route.queryParams.subscribe((params) => {
        this.viewState = params['view'] || 'subscribe';
        this.initialViewState = this.viewState;
      })
    );

    this.chosenPlan = this.plans.static;

    this.subs.push(
      this.auth
        .refreshUser()
        .pipe(
          switchMap((user) =>
            forkJoin({
              plans: from(this.lprxApi.get<Plans>(`v3/plans/${user.distributorId}`)),
              mealPlan: from(this.lprxApi.mealPlans.get(user.mealPlanId)),
              planConfig: from(this.configService.getDistConfig()),
            })
          )
        )
        .subscribe({
          next: (v) => {
            this.planConfig = v.planConfig;

            if (this.planConfig.canAcceptPayments) {
              this.currency = this.planConfig.pricing.currency;

              this.plans.static.price = this.planConfig.pricing.static.monthly.price;
              this.plans.custom.price = this.planConfig.pricing.custom.monthly.price;
            }

            this.mealPlan = v.mealPlan;

            this.currency = this.planConfig.pricing.currency;
            if (v.plans.static) {
              this.plans.static.code = v.plans.static.monthly.priceId;
              this.plans.static.price = v.plans.static.monthly.price;
            }

            if (v.plans.custom) {
              this.plans.custom.code = v.plans.custom.monthly.priceId;
              this.plans.custom.price = v.plans.custom.monthly.price;
            }

            this.enableChoosePlan = !(!v.plans.static || !v.plans.custom);

            if (v.plans.static && !v.plans.custom) {
              this.chosePlan('static');
            } else if (!v.plans.static && v.plans.custom) {
              this.chosePlan('custom');
            }

            this.showCustomOption = this.plans.custom && !!v.mealPlan.availablePlanTypes.match(/c/);
            this.showStaticOption = this.plans.static && !!v.mealPlan.availablePlanTypes.match(/s/);

            this.isLoading = false;
          },
          error: (e) => {},
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  choosePlan() {
    this.viewState = 'choose';
  }

  buy($event) {
    if (this.isProcessing) {
      return;
    }

    // Block duplicate payment attempts
    this.isProcessing = true;

    // Clear any error message
    this.purchaseError = null;

    this.creditCardInputComponent
      .getPaymentSource()
      // .createPaymentMethod(this.card)
      // .createSource(this.card, { owner: { name: this.nameOnCard } })
      .pipe(
        switchMap((sourceResult) => {
          // if (sourceResult.error) {
          //   throw sourceResult.error;
          // }

          const pCode = this.promoCodeApplied
            ? this.planConfig.coupons.filter((c) => c.code === this.promoCode)[0].stripeCouponId
            : undefined;

          return this.userService.purchasePlan(
            sourceResult,
            this.chosenPlan.code,
            this.idempotentToken,
            pCode
          );
        })
      )
      .subscribe(
        () => {
          const u = this.auth._getUser();
          u.terminatesAt = Date.now() + 1000 * 60 * 60 * 24 * 30;
          u.plannerType = this.chosenPlan.plannerType;
          this.auth.setUser(u); // ?? should updated user from server...refreshUser
          this.viewState = 'thankyou';
          console.log('Thank you!');
          this.isProcessing = false;
        },
        (err) => {
          this.isProcessing = false;
          console.log(err);
          this.purchaseError =
            "We're sorry! We were unable to process your payment. " +
            'Please check that you entered your card information correctly.';
        }
      );
  }

  closeUpgradePrompt() {
    this.closedUpgradePrompt.emit(true);
  }

  chosePlan(planType: string) {
    this.chosenPlan = planType === 'custom' ? this.plans.custom : this.plans.static;
    this.viewState = 'purchase';
  }

  close() {
    // activate ModalClose
  }

  applyPromoCode() {
    this.promoCodeError = undefined;
    this.promoCodeApplied = false;

    if (this.promoCode === '') {
      return;
    }

    for (const coupon of this.planConfig.coupons) {
      if (coupon.code.toLowerCase() === this.promoCode && !coupon.isDeactivated) {
        if (!coupon.plannerTypes.includes(this.chosenPlan.plannerType)) {
          this.promoCodeError = 'Promo code not valid for the selected planner type.';
          return false;
        }

        const isValidForSelectedPlan =
          coupon.forMealPlans.includes('all') || coupon.forMealPlans.includes(this.mealPlan.id);

        if (!isValidForSelectedPlan) {
          this.promoCodeError = 'Promo code not valid for this meal plan.';
          return false;
        }

        this.coupon = coupon;

        if (coupon.type === ClientCouponType.AmountOff) {
          this.promoCodeMessage = `Congrats! You're getting ${this.pricingWithCurrencyPipe.transform(
            coupon.amount,
            this.planConfig.pricing.currency
          )} off per month.`;
          this.promoCodeApplied = true;
          return true;
        }

        if (coupon.type === ClientCouponType.PercentOff) {
          this.promoCodeMessage = `Congrats! You're getting ${coupon.amount}% off per month.`;
          this.promoCodeApplied = true;
          return true;
        }
      }
    }
  }

  netPrice(price: number, plannerType: PlannerType): string {
    if (!this.planConfig?.pricing) {
      return '';
    }

    let netPrice = price;

    if (this.coupon) {
      if (this.coupon.plannerTypes.includes(plannerType)) {
        netPrice =
          this.coupon.type === ClientCouponType.PercentOff
            ? price * (1 - this.coupon.amount / 100)
            : price - this.coupon.amount;
      }
    }

    if (netPrice === price) {
      return this.pricingWithCurrencyPipe.transform(netPrice, this.planConfig.pricing.currency);
    }

    return (
      `<s>$${price}</s> ` +
      this.pricingWithCurrencyPipe.transform(netPrice, this.planConfig.pricing.currency)
    );
  }

  minPrice() {
    const prices: number[] = [];
    if (this.planConfig.enableCustomPlans) {
      prices.push(this.plans.custom.price);
    }
    if (this.planConfig.enableStaticPlans) {
      prices.push(this.plans.static.price);
    }

    return this.pricingWithCurrencyPipe.transform(Math.min(...prices), this.currency || 'usd');
  }
}
