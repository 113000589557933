/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/user/nutrition-facts-display.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum NutritionFactsDisplay {
  Default = 'Default',
  Show = 'Show',
  Hide = 'Hide'
}
