<div class="wrapper">
    <div class="container">
        <div class="row">
            <div class="col-md-7 mx-auto my-5 text-center">
                <div class="inner-wrapper px-3">

                    <app-loading *ngIf="isLoading"></app-loading>

                    <ng-container *ngIf="!isLoading">

                        <app-buy *ngIf="canSubscribe"></app-buy>

                        <div *ngIf="!canSubscribe" class="py-5 px-3">
                            <h4>Please contact <strong>{{distributor.business}}</strong> to continue your meal plan.</h4>
                        </div>

                    </ng-container>

                </div>
            </div>
        </div>
    </div>
</div>
