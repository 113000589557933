import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  view = 'email';
  error: string;
  email: string;
  isProcessing = false;

  password = '';
  passwordConfirm: string;
  isPasswordLongEnough = false;
  doesPasswordHaveNumber = false;
  doesPasswordHaveLowercase = false;
  doesPasswordHaveUppercase = false;
  doesPasswordMatch = false;
  isPasswordValid = false;
  code: string;

  constructor(
    private toastr: ToastrService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params['e']) {
        this.email = params['e'];
      }
    });
  }

  async sendResetCode() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;
    try {
      const result = await this.auth.forgotPassword(this.email);
      if (result) {
        this.view = 'password';
      } else {
        this.error =
          "We don't recognize that email address. Please verify that you entered it correctly.";
      }
    } catch (err) {
      this.error = "We're sorry! There was an issue processing your request.";
    }

    this.isProcessing = false;
    return this.error === null; // no errors...good to go
  }

  async sendNewCode() {
    if (await this.sendResetCode()) {
      this.toastr.success("A new code is on it's way!");
    } else {
      this.toastr.error("We're sorry! There was an issue in sending you a new reset code.");
    }
  }

  async resetPassword() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.error = null;
    try {
      const result = await this.auth.forgotPasswordSubmit(this.email, this.code, this.password);
      this.view = 'success';
      console.log(result);
    } catch (err) {
      console.log(err);
      this.error = err.message;
    }
    this.isProcessing = false;
  }

  // todo : this is duplicated in many places....in fact, create a component that can be reused for creating a password
  validatePassword() {
    const p = this.password;
    this.isPasswordLongEnough = p.length >= 6;
    this.doesPasswordHaveLowercase = p.match(/[a-z]/) !== null;
    this.doesPasswordHaveUppercase = p.match(/[A-Z]/) !== null;
    this.doesPasswordHaveNumber = p.match(/[0-9]/) !== null;
    this.doesPasswordMatch = this.password && this.password === this.passwordConfirm;

    this.isPasswordValid =
      this.isPasswordLongEnough &&
      this.doesPasswordHaveUppercase &&
      this.doesPasswordHaveLowercase &&
      this.doesPasswordHaveNumber &&
      this.doesPasswordMatch;
    console.log(this.isPasswordValid);
  }
}
