<div *ngIf="view === 'weekSelection'" class="d-print-none text-center py-4">
    <h3>Please select a week to print.</h3>
    <p *ngFor="let weeklyPlan of weeklyPlans$ | async">
        <a routerLink="../{{weeklyPlan.weekNumber}}/print" appButton class="mb-2">
<!--                (click)="printWeeklyPlan(weeklyPlan)">-->
            {{weeklyPlan.weekNumber | humanWeek:true}}
        </a>
    </p>

    <button (click)="cancelPrint()" class="btn btn-outline-secondary">Cancel</button>
</div>
