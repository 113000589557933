<h5>Elements</h5>
<br />

<div class="elementoo">
  <button (click)="convertToSimpleEntry()" type="button" class="btn btn-sm btn-secondary mb-3">
    Update Nutrition Values
  </button>
  <div *ngIf="processingUpdate"><app-loading [style]="'sm'"></app-loading> Processing...</div>

  <div
    cdkDropList
    [cdkDropListDisabled]="elements.length === 1"
    [cdkDropListData]="elements"
    class="elements"
    (cdkDropListDropped)="onDrop($event)"
  >
    <div
      *ngFor="let element of elements; index as i"
      cdkDrag
      [cdkDragData]="element"
      class="element"
    >
      <div *cdkDragPreview>
        <div class="alert alert-warning">{{element.name || ' '}}</div>
      </div>
      <div class="placeholder" *cdkDragPlaceholder>
        <div class="alert alert-warning">{{element.name}}</div>
      </div>
      <div class="row align-items-center">
        <div class="col-8">
          <input
            [(ngModel)]="element.name"
            class="form-control"
            placeholder="Recipe element name: (e.g.: Dressing)"
          />
        </div>
        <div class="col-auto ml-auto">
          <ng-container *ngIf="elements.length > 1">
            <mat-icon cdkDragHandle style="cursor: move">drag_handle</mat-icon>
            <button
              type="button"
              mat-icon-button
              *ngIf=" i < (elements.length - 1)"
              (click)="swapDown(i)"
            >
              <mat-icon>arrow_downward</mat-icon>
            </button>
            <button type="button" mat-icon-button *ngIf=" i > 0" (click)="swapUp(i)">
              <mat-icon>arrow_upward</mat-icon>
            </button>
          </ng-container>
          <button type="button" mat-icon-button (click)="remove(i)">
            <mat-icon [color]="'warn'">close</mat-icon>
          </button>
        </div>
      </div>

      <app-recipe-element-input
        [hasMultipleElements]="hasMultipleElements"
        [element]="element"
        (deletedElement)="onDeletedElement(element)"
      >
      </app-recipe-element-input>
    </div>
  </div>

  <br />
  <button type="button" class="btn btn-info" (click)="addElement()">+ Add Another Element</button>
</div>
