import { ChangeContext } from '@angular-slider/ngx-slider';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDrawer, MatDrawerToggleResult } from '@angular/material/sidenav';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { PlannerConfiguration } from '../../../lprx-shared-lib/planner/planner-configuration';
import { infinitySymbol } from '../../../lprx-shared-lib/vars';
import { AdminService } from '../../../providers/admin/admin.service';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { AuthService } from '../../auth.service';
import { DistributorService } from '../../distributor/distributor-service';
import { isTablet } from '../../is-tablet';
import { LayoutService } from '../../layout/layout.service';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { RecipeResult } from '../../model/entities/recipe-result';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { RecipeSearchResultInterface } from '../../model/recipe-search-result.interface';
import { ClientService } from '../../service/client/client.service';
import { HeaderService } from '../../service/header.service';
import { RecipeCacheService } from '../../service/recipe-cache.service';
import { RecipeService } from '../../service/recipe.service';
import { DropService } from '../drop.service';
import { MacroOption, macroOptions } from '../macro-options';
import { dragDropDelay } from '../planner-meal/is-touch-enabled';
import { PlannerService } from '../planner.service';
import { RecipeSearchService } from '../recipe-search.service';
import { SearchState } from '../search.state';

@Component({
  selector: 'app-planner-search',
  templateUrl: './planner-search.component.html',
  styleUrls: ['./planner-search.component.scss'],
})
export class PlannerSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  get filterApplied() {
    const selectedPlansCount = this.selectedMealTypes.length;
    const hasFilteredMealPlans =
      selectedPlansCount > 0 && selectedPlansCount < this.mealTypes.length;

    return hasFilteredMealPlans || this.onlyFavorites || this.onlyMine || this.hasMacroFilter();
  }

  constructor(
    private adminService: AdminService,
    private auth: AuthService,
    private clientService: ClientService,
    private distributorService: DistributorService,
    private header: HeaderService,
    public layout: LayoutService,
    private plannerService: PlannerService,
    private recipeCache: RecipeCacheService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private searchService: RecipeSearchService,
    private recipeService: RecipeService,
    private lprxApi: LprxApiProvider,
    private changRef: ChangeDetectorRef,
    private dropService: DropService
  ) {}
  // the results
  recipes: RecipeResult[] = [];
  subs: Subscription[] = [];
  cards: Card[];
  term: string;
  termChanged: Subject<string> = new Subject<string>();
  searchAll = false;
  @Input() user: User;
  @Input() client: User;
  @Input() weeklyPlans: WeeklyPlan[];
  canSearch = false;

  macroOptions: MacroOption[] = macroOptions;

  mealTypes = ['Breakfast', 'Lunch', 'Dinner', 'Side', 'Snack'];
  selectedMealTypes = [];

  plannerConfig: PlannerConfiguration;

  @Output() filterOpenEvent = new EventEmitter<boolean>();

  @ViewChild('drawer') drawer: MatDrawer;

  private from = 0;
  private limit = 30;
  isProcessing = false;
  showLoadMore = false;
  rr: SafeStyle;

  isNarrow = false;

  private searchResultsObservable: Observable<RecipeSearchResultInterface>;

  /**
   * These are used to pass along to the recipe view in the event the user is trying to
   * add a recipe to specific spot in the planner.
   */
  private weekNumber: string;
  private dayName: string;
  private mealName: string;
  private clientUsername: string;
  searchToolHeight: SafeStyle;

  isTablet = isTablet();

  @ViewChild('searchTool', { static: true }) searchTool: ElementRef;
  hideAdvanced: boolean = true;
  onlyFavorites: any;
  filterOpen: boolean = false;
  isOpen: boolean;
  showAdvanced = false;
  onlyMine = false;
  showClientFilters: boolean = false;
  tick: number = 0;

  dragDropDelayTimeout = dragDropDelay();
  removeDragHandle: boolean = false;

  ngOnInit() {
    // Set the last term if available
    this.term = this.searchService.searchState.term;
    this.recipes = this.searchService.searchState.results;
    this.showLoadMore = this.searchService.searchState.showLoadMore;

    this.selectedMealTypes = Array.from(this.mealTypes);

    this.initSubs();

    // there are better places and ways to do this...
    // const that = this;
    setTimeout(() => {
      const headerHeight = (document.querySelector('app-header .navbar') as HTMLElement)
        .offsetHeight;
      const searchToolsHeight = (document.getElementsByClassName('search-tool')[0] as HTMLElement)
        .offsetHeight;
      this.rr = this.sanitizer.bypassSecurityTrustStyle('height: 600px; overflow-y: scroll;');

      // various paddings
      const resultsHeight = window.innerHeight - headerHeight - searchToolsHeight - 40;

      this.rr = this.sanitizer.bypassSecurityTrustStyle(
        `height: ${resultsHeight}px; overflow-y: scroll;`
      );
    }, 2000);

    this.setFilterOpenState(false);
  }

  private initSubs() {
    this.subs.push(
      this.userSub(),
      this.narrowSub(),
      this.canEditSub(),
      this.routeParamsSub(),
      this.weeklyPlansSub(),
      this.termsChangedSub(),
      this.plannerConfigSub()
    );
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((p) => (this.plannerConfig = p));
  }

  /**
   * Detects when a the search term has changed. Then, executes the search.
   */
  private termsChangedSub() {
    return this.termChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((term) => {
      this.searchService.searchState.term = term;
      this.term = term;
      this.search();
    });
  }

  private weeklyPlansSub() {
    return this.plannerService.weeklyPlans$.subscribe((weeklyPlans) => {
      this.weeklyPlans = weeklyPlans;
    });
  }

  private narrowSub() {
    return this.layout.isNarrow$.subscribe((isNarrow) => (this.isNarrow = isNarrow));
  }

  private routeParamsSub() {
    return this.route.params.subscribe((params) => {
      console.log(params);
      this.weekNumber = params['weekNumber'];
      this.dayName = params['day'];
      this.mealName = params['meal'];
      this.clientUsername = params['username'];

      if (this.clientUsername) {
        this.showClientFilters = true;
      }

      this.recipeService.setClient(this.clientUsername);

      if (params['meal']) {
        this.selectedMealTypes = [params['meal']];
      }

      if (params['action'] === 'add-recipe') {
        this.searchService.searchState = new SearchState();
        this.router.navigateByUrl(
          `/planner/search/${this.weekNumber}/${this.dayName}/${this.mealName}`,
          { replaceUrl: true }
        );
      }

      this.search();
    });
  }

  private canEditSub() {
    return this.plannerService.getCanEdit().subscribe((canEdit) => {
      console.log('can edit this planner', canEdit);
      this.canSearch = canEdit;
    });
  }

  private userSub() {
    return this.auth.user$.subscribe((user) => {
      // note: do not want to change existing
      if (user?.userType === UserType.Client) {
        this.showClientFilters = true;
      }

      if (!this.user && user) {
        this.user = user;
      }
    });
  }

  changed(text: string) {
    this.termChanged.next(text);
  }

  hasMacroFilter(): boolean {
    for (const macro of this.macroOptions) {
      if (macro.low !== macro.floor || macro.high !== macro.ceil) {
        return true;
      }
    }
    return false;
  }

  search(clear = true): any {
    if (!this.user) {
      return;
    }

    if (clear) {
      this.from = 0;
      this.recipes = [];

      if (this.isNarrow) {
        window.scrollTo(0, 0);
      }
    }

    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.showLoadMore = false;

    // todo: do something about this crappy implementation
    const userType = this.clientUsername ? UserType.Client : this.user.userType;

    this.searchResultsObservable = this.recipeService.search({
      userType,
      searchTerm: this.term,
      from: this.from,
      limit: this.limit,
      mealTypes: this.selectedMealTypes,
      onlyUserRecipes: this.onlyMine,
      onlyFavorites: this.onlyFavorites,
      clientUsername: this.client?.username,
      searchAllMealPlans: this.searchAll,
      macroOptions: this.macroOptions,
    });

    const subscription = this.searchResultsObservable.subscribe(
      (result: RecipeSearchResultInterface) => {
        this.processSearchResult(result);
      }
    );

    this.subs.push(subscription);
  }

  public processSearchResult(result: RecipeSearchResultInterface) {
    this.isProcessing = false;

    this.showLoadMore = result.hasMore;

    result.recipes.forEach((recipe) => this.recipeCache.cache(recipe));

    const recipeResults = this.recipes.concat(result.recipes);
    this.recipes = recipeResults;

    this.searchService.searchState = {
      term: this.term,
      results: recipeResults,
      showLoadMore: this.showLoadMore,
      searchAll: this.searchAll,
      scrollY: window.scrollY,
    };
  }

  ngOnDestroy() {
    this.subs.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * @param $event
   * @param type
   */
  filterMealType($event: MouseEvent, type) {
    $event.stopPropagation();
    $event.preventDefault();
    if (new Set(this.selectedMealTypes).has(type)) {
      this.selectedMealTypes.splice(this.selectedMealTypes.indexOf(type), 1);
    } else {
      this.selectedMealTypes.push(type);
    }
    this.changRef.markForCheck();
    this.search();
  }

  loadMore() {
    this.from = this.recipes.length;
    this.search(false);
  }

  /**
   * @param recipe
   */
  openRecipe(recipe: RecipeResult) {
    this.searchService.searchState.scrollY = window.scrollY;

    const url: string =
      this.weekNumber && this.dayName && this.mealName
        ? `/planner/recipe/${recipe.id}/${this.weekNumber}/${this.dayName}/${this.mealName}/add`
        : `/planner/recipe/${recipe.id}`;

    this.router.navigateByUrl(url);
  }

  ngAfterViewInit(): void {
    const height = this.searchTool.nativeElement.offsetHeight;
    this.searchToolHeight = this.sanitizer.bypassSecurityTrustStyle(height + 'px');
    window.scrollTo(0, this.searchService.searchState.scrollY);
  }

  setFilterOpenState(openState: boolean) {
    this.filterOpen = openState;
    let p: Promise<MatDrawerToggleResult>;

    if (this.drawer) {
      p = openState ? this.drawer.open() : this.drawer.close();
      p.then(() => {
        this.filterOpenEvent.emit(this.filterOpen);
      });
    }
  }

  openFilter() {
    this.setFilterOpenState(true);
  }

  closeFilter() {
    this.setFilterOpenState(false);
  }

  resetFilters() {
    this.onlyFavorites = false;
    this.onlyMine = false;
    this.selectedMealTypes = Array.from(this.mealTypes);
    this.searchAll = false;
    this.macroOptions.forEach((mo) => {
      mo.low = 0;
      mo.high = mo.ceil;
    });
    this.search();
  }

  toggleOnlyFavorites() {
    this.onlyFavorites = !this.onlyFavorites;
    this.search(true);
  }

  toggleOnlyMyRecipe() {
    this.onlyMine = !this.onlyMine;
    this.search(true);
  }

  toggleSearchAll() {
    this.searchAll = !this.searchAll;
    this.search(true);
  }

  // noinspection JSMethodCanBeStatic
  private macroHighDisplay(macroOption: MacroOption) {
    macroOption.highDisplay =
      macroOption.high === macroOption.ceil ? infinitySymbol : macroOption.high.toString();
  }

  onUserChangeEnd($event: ChangeContext, macroOption: MacroOption) {
    this.macroHighDisplay(macroOption);
    this.search(true);
  }

  onUserChange($event: ChangeContext, macroOption: MacroOption) {
    this.macroHighDisplay(macroOption);
  }

  dragStarted($event: CdkDragStart, recipe: RecipeResult) {
    // this.dropService.startDraggingCard();
    console.log('drag started', $event);

    this.dropService.isDragging = true;

    // const clonedRecipe = classToClass (recipe);
    // const index = this.recipes.findIndex((r) => r.id === recipe.id);
    // console.log('index', index);
    // this.recipes.splice(index + 1, 0, clonedRecipe);
    // this.tick = Math.random();
  }

  // dragMoved($event: CdkDragMove<RecipeResult>) {
  //   // console.log('drag moved', { x: $event.pointerPosition.x, y: $event.pointerPosition.y });
  //   this.dropService.draggingCard($event.pointerPosition.x, $event.pointerPosition.y);
  // }
  enterPredicate = () => false;

  dragEnded($event: CdkDragEnd, recipe: RecipeResult) {
    // const index = this.recipes.findIndex((r) => r.id === recipe.id);
    // this.recipes.splice(index + 1, 1);
    // this.tick = Math.random();
    this.dropService.isDragging = false;
  }
}
