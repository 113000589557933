/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/Meal.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Type } from 'class-transformer';
import { defaultNutrients } from '../../default-nutrients';
import { CardType } from '../../enum/card-type';
import { USDAFactsResults } from '../../recipe/USDAFactsResults';
import { Default } from '../../utils/default.decorator';
import { MealPlanMeal } from '../meal-plan/MealPlanMeal';
import { GroceryItem } from '../recipe/grocery-item';
import { Recipe } from '../recipe/Recipe';
import { SimpleNutritionFacts } from '../recipe/SimpleNutritionFacts';
import { Card } from './Card';
import { Note } from './note';

@Exclude()
export class Meal {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  @Type(() => Card)
  @Default([])
  cards: Card[] = [];

  static fromObject(object: any): Meal {
    return plainToClass(Meal, object);
  }

  static create(id: string, name: string) {
    const meal = new Meal();
    meal.init({ id, name });
    return meal;
  }

  init(mealPlan: Pick<MealPlanMeal, 'id' | 'name'>): any {
    this.id = mealPlan.id;
    this.name = mealPlan.name;
    this.cards = [];
  }

  addRecipe(recipe: Recipe, userServings = 0): any {
    if (!this.recipeExists(recipe.id)) {
      const card = Card.newRecipeCard(recipe, userServings);
      this.cards.push(card);
      console.log(`Added Recipe(${recipe.id}) to Meal(${this.id})`);
    } else {
      console.log(`Cannot add Recipe(${recipe.id}) to Meal(${this.id}) as it already exists.`);
    }
  }

  removeRecipe(recipeId: string): any {
    console.log('removing card');
    for (const card of this.cards) {
      if (card.type === CardType.Recipe && card.recipeId === recipeId) {
        const i = this.cards.indexOf(card);
        this.cards.splice(i, 1);
        console.log('done removing card');
      }
    }
  }

  // @ts-ignore TS2366: Function lacks ending return statement and return type does not include 'undefined'.
  getRecipeCard(recipeId: string): Card {
    for (const card of this.cards) {
      if (
        card.type === CardType.Recipe &&
        card.recipeId &&
        card.recipeId.toString() === recipeId.toString()
      ) {
        return card;
      }
    }
  }

  recipeExists(recipeId?: string) {
    if (!recipeId) {
      return false;
    }
    return this.getRecipeCard(recipeId) != null;
  }

  firstRecipeCardIndex(): number {
    let indexCount = -1;
    for (const card of this.cards) {
      indexCount++;
      if (card.isRecipe()) {
        return indexCount;
      }
    }
    return -1;
  }

  addCard(card: Card) {
    if (!card) {
      return;
    }

    switch (card.type) {
      case CardType.Recipe:
        const canAddRecipeCard = !this.recipeExists(card.recipeId);
        if (canAddRecipeCard) {
          this.cards.push(card);
          // console.log(`Added Card(${card.recipeId}) to Meal(${this.id})`);
        } else {
          console.log(
            `Cannot add Card for Recipe(${card.recipeId}) to Meal(${this.id}) as it already exists.`
          );
        }
        break;
      case CardType.Note:
        this.cards.unshift(card);
        break;
    }
  }

  getGroceries(): GroceryItem[] {
    let list: GroceryItem[] = [];
    this.cards.forEach((c) => {
      list = list.concat(c.getGroceries());
    });
    return list;
  }

  getNutritionFacts() {
    const sum = new SimpleNutritionFacts();

    let stats: any[] = [];
    this.cards.forEach((c) => {
      const nf = c.getNutritionFacts();
      if (nf) {
        stats = stats.concat(nf);
        sum.protein += nf.nutritionFacts.protein;
        sum.calories += nf.nutritionFacts.calories;
        sum.fat += nf.nutritionFacts.fat;
        sum.carbs += nf.nutritionFacts.carbs;
        sum.carbsFromFiber += nf.nutritionFacts.carbsFromFiber;
        sum.carbsFromSugar += nf.nutritionFacts.carbsFromSugar;
      }
    });
    return {
      id: this.id,
      title: this.name,
      type: 'meal',
      nutritionFacts: sum,
      children: stats,
    };
  }

  getUSDAFacts(nutrients: Set<string> = defaultNutrients): USDAFactsResults {
    let stats: USDAFactsResults[] = [];
    const nutrientSum: any = {};

    for (const card of this.cards) {
      if (!card.recipe) {
        continue;
      }

      const usdaFacts = card.getUSDAFacts(nutrients);
      const cardNutrients = usdaFacts.nutrients;
      stats = stats.concat(usdaFacts);
      for (const nutrient of Object.getOwnPropertyNames(cardNutrients)) {
        if (nutrients && !nutrients.has(nutrient)) {
          continue;
        }
        if (!nutrientSum[nutrient]) {
          nutrientSum[nutrient] = {
            value: 0,
            unit: cardNutrients[nutrient].unit,
          };
        }
        nutrientSum[nutrient].value += cardNutrients[nutrient].value;
      }
    }

    return {
      id: this.id,
      title: this.name,
      type: 'meal',
      nutrients: nutrientSum,
      children: stats,
    };
  }

  getNotes() {
    const notes: Note[] = [];
    for (const card of this.cards) {
      if (card.note) {
        notes.push(card.note);
      }
    }
    return notes;
  }

  hasNotes() {
    const notes: Note[] = [];
    for (const card of this.cards) {
      if (card.note) {
        return true;
      }
    }
    return false;
  }

  getRecipes() {
    const recipes: Recipe[] = [];
    for (const card of this.cards) {
      if (card.recipe) {
        recipes.push(card.recipe);
      }
    }
    return recipes;
  }

  getRecipe(recipeId: string) {
    for (const recipe of this.getRecipes()) {
      if (recipe.id === recipeId) {
        return recipe;
      }
    }
    throw new Error(`Recipe(${recipeId}) not found for Meal(${this.name})`);
  }

  /**
   * @param recipeId
   */
  hasRecipe(recipeId: string): boolean {
    try {
      return this.getRecipe(recipeId).id != null;
    } catch (err) {
      return false;
    }
  }

  hasRecipes(): boolean {
    const recipes: Recipe[] = [];
    for (const card of this.cards) {
      if (card.recipe) {
        return true;
      }
    }
    return false;
  }

  removeCard(card: Card) {
    if (card.type === CardType.Recipe && card.recipeId) {
      this.removeRecipe(card.recipeId);
    } else if (card.type === CardType.Note && card.note) {
      this.removeNote(card.note.id);
    }
  }

  removeNote(noteId: string) {
    console.log('removing card');
    for (const card of this.cards) {
      if (card.type === CardType.Note && card.note && card.note.id === noteId) {
        const i = this.cards.indexOf(card);
        this.cards.splice(i, 1);
        console.log('done removing card');
      }
    }
  }

  getCard(cardId: string): Card | null {
    for (const card of this.cards) {
      if (card.type === CardType.Recipe && card.recipeId === cardId) {
        return card;
      } else if (card.type === CardType.Note && card.note && card.note.id === cardId) {
        return card;
      }
    }
    return null;
  }
}
