<div class="btn-group btn-group-sm">
    <button type="button" class="btn" title="Switch to US/UK Units"
            (click)="changeSystem(UnitSystem.Imperial)"
            [class.btn-primary]="unitSystem == 'imperial'"
            [class.btn-outline-primary]="unitSystem !== 'imperial'"> lb,c
    </button>
    <button type="button" class="btn" title="Switch to Metric Units"
            (click)="changeSystem(UnitSystem.Metric)"
            [class.btn-primary]="unitSystem == 'metric'"
            [class.btn-outline-primary]="unitSystem != 'metric'"> g,ml
    </button>
</div>
