
import {switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable ,  of } from 'rxjs';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';
import { UserService } from '../../service/user.service';

@Injectable()
export class PlannerGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getLoggedInUser().pipe(switchMap(user => {
      if (user.userType === UserType.Admin || user.userType === UserType.Distributor) {
        return of(true);
      }

      // Active plan
      if (user.terminatesAt > Date.now()) {
        return of(true);
      }

      // Plan isn't active anymore
      this.router.navigateByUrl('/buy');
      return of(false);

      // // If the user has a subscription check to see if it's active
      // if (user.subscriptionId) {
      //   return this.userService.getSubscription()
      //     .map((subscription: any) => {
      //       if (subscription.status === 'active') {
      //         return true; // map creates the Observable
      //       } else if (subscription.status === 'canceled') {
      //         this.router.navigateByUrl('/buy');
      //         return false; // map creates the Observable
      //       }
      //     });
      // }
      // return of(true);
    }));
  }
}
