/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/password-passer.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class PasswordPasser {
  private static delimiter() {
    return '`';
  }

  private static salt() {
    return 'mypass9876t';
  }

  static encrypt(password: string) {
    const value = this.to64(this.salt() + this.delimiter() + password);
    return this.to64(
      this.to64(value) + this.delimiter() + this.to64(this.salt() + this.delimiter() + value)
    );
  }

  static to64(value: string) {
    return typeof btoa === 'undefined' ? Buffer.from(value).toString('base64') : btoa(value);
  }

  static from64(value: string) {
    return typeof atob === 'undefined'
      ? Buffer.from(value, 'base64').toString('ascii')
      : atob(value);
  }

  static decrypt(value: string) {
    const [v, sig] = this.from64(value).split(this.delimiter());
    const [salt, password] = this.from64(this.from64(v)).split(this.delimiter(), 2);
    return password;
  }
}
