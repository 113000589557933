import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, length = 100): any {
    let output = '';
    const words = value.split(' ');

    for (const word of words) {
      if (output.length + word.length > length - 1) {
        break;
      }
      output += ' ' + word;
    }

    output = output.trim();

    if (value.length > output.length) {
      output += '…';
    }

    return output;
  }
}
