import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbCalendar, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// noinspection TypeScriptCheckImport

import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Subscription } from 'rxjs';
import { from } from 'rxjs/observable/from';
import { interval } from 'rxjs/internal/observable/interval';
import { of } from 'rxjs/observable/of';
import { switchMap, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';
import {
  after,
  areDatesEqual,
  before,
  DateObject,
  dateObjectToString,
  equals,
  keyToDateObjects,
} from '../../../lprx-shared-lib/calc-date';
import { GroceryItem, sortGroceries } from '../../../lprx-shared-lib/entities/recipe/grocery-item';
import { UnitSystem } from '../../../lprx-shared-lib/enum/unit-system';
import { addWeek } from '../../../lprx-shared-lib/utils/getCurrentWeekNumber';
import {
  GroceryListItem,
  GroceryLists,
  GroupedGroceryItems,
} from '../../../lprx-shared-lib/utils/types';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { LayoutService } from '../../layout/layout.service';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { UnitSystemService } from '../../unit-system-toggle/unit-system-service';
import { groceryListKeyFromDates } from '../grocery-list-key-from-dates';
import { PlannerService } from '../planner.service';
import { WeekPoint } from './week-point';
import { GroceryCategories } from '../../../lprx-shared-lib/GroceryCategories';
import { UnitConverter } from '../../../lprx-shared-lib/utils/unit-converter';
import { nonCalendarDisplay } from '../../../lprx-shared-lib/non-calendar-display';
import { convertUnits } from '../../../lprx-shared-lib/convert-units';

@Component({
  selector: 'app-grocery-list',
  templateUrl: './grocery-list.component.html',
  styleUrls: ['./grocery-list.component.scss'],
})
export class GroceryListComponent implements OnInit, OnDestroy {
  private canSetLastDate: boolean = false;

  private lastDateSet: 'from' | 'to' | null = 'to';

  get toDate(): NgbDateStruct {
    return this._toDate;
  }

  set toDate(value: NgbDateStruct) {
    if (value && dateObjectToString(value) > dateObjectToString(this.maxGroceryDate)) {
      // console.log('setting maxGroceryDate', value);
      this.maxGroceryDate = value;
    }
    this._toDate = value;
  }

  get fromDate(): NgbDateStruct {
    return this._fromDate;
  }

  set fromDate(value: NgbDateStruct) {
    if (value && dateObjectToString(value) < dateObjectToString(this.minGroceryDate)) {
      // console.log('setting minGroceryDate', value);
      this.minGroceryDate = value;
    }
    this._fromDate = value;
  }
  get selectedList(): string {
    return this._selectedList;
  }

  set selectedList(value: string) {
    this._selectedList = value;
    // console.log('selectedList', value);
  }

  groceryLists: GroceryLists;

  private _selectedList: string;

  list: any = [];
  listItems: any = [];
  weeklyPlans: WeeklyPlan[] = [];

  addItemSelectedCategory: string = 'Other';

  // checkedItems: { [key: string]: number } = {};

  private subs: Subscription[] = [];

  consolidateItems = true;

  addItem: string;

  @Input() weeklyPlan: WeeklyPlan;
  @Input() fullWeek = false;

  hoveredDate: NgbDateStruct;

  private _fromDate: NgbDateStruct;
  private _toDate: NgbDateStruct;

  minGroceryDate: NgbDateStruct = {
    year: 2099,
    month: 12,
    day: 31,
  };
  maxGroceryDate: NgbDateStruct = {
    year: 1970,
    month: 1,
    day: 1,
  };

  startWeekNumber = '';
  endWeekNumber = '';
  plannerPath: string | null;
  processing = false;
  private groceryCategories: string[];
  groupedItems: GroupedGroceryItems = {};
  hiddenItems: GroceryListItem[] = [];
  groceryListCategories: string[];
  showCalendar: boolean = true;
  showHidden: boolean = false;

  protected readonly Object = Object;

  @Input()
  printBranding: boolean = true;

  itemStatus = new Map<string, boolean>();
  unitSystem = UnitSystem.Imperial;
  private lastUpdatedAt: number = 0;

  private destroy$ = new Subject();
  private groceryListsTrigger$ = new Subject<GroceryLists | null>();

  constructor(
    private calendar: NgbCalendar,
    private router: Router,
    private route: ActivatedRoute,
    private plannerService: PlannerService,
    private lprxApi: LprxApiProvider,
    public layout: LayoutService,
    private readonly unitSystemService: UnitSystemService,
    private toastr: ToastrService,
    private clipboard: Clipboard,
  ) {
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getToday();
    // console.log('constructor set dates', this.fromDate, this.toDate);
    this.selectedList = groceryListKeyFromDates(this.fromDate, this.toDate);
  }

  // static equals(one: NgbDateStruct, two: NgbDateStruct) {
  //   return one && two && two.year === one.year && two.month === one.month && two.day === one.day;
  // }
  //
  // static before(one: NgbDateStruct, two: NgbDateStruct) {
  //   return !one || !two
  //     ? false
  //     : one.year === two.year
  //     ? one.month === two.month
  //       ? one.day === two.day
  //         ? false
  //         : one.day < two.day
  //       : one.month < two.month
  //     : one.year < two.year;
  // }
  //
  // static after(one: NgbDateStruct, two: NgbDateStruct) {
  //   return !one || !two
  //     ? false
  //     : one.year === two.year
  //     ? one.month === two.month
  //       ? one.day === two.day
  //         ? false
  //         : one.day > two.day
  //       : one.month > two.month
  //     : one.year > two.year;
  // }

  isHovered(date) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      after(date, this.fromDate) &&
      before(date, this.hoveredDate)
    );
  }

  isInside(date) {
    return after(date, this.fromDate) && before(date, this.toDate);
  }

  isFrom(date) {
    return equals(date, this.fromDate);
  }

  isTo(date) {
    return equals(date, this.toDate);
  }

  // onDateSelection(date: NgbDateStruct) {
  //   // console.log('onDateSelection', date);
  //   if (!this.fromDate && !this.toDate) {
  //     this.fromDate = date;
  //   } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
  //     this.toDate = date;
  //     // console.log('onDateSelection', this.fromDate, this.toDate);
  //   } else {
  //     this.toDate = null;
  //     this.fromDate = date;
  //     // console.log('onDateSelection else', this.fromDate, this.toDate);
  //   }
  //   this.filterList();
  // }

  onDateSelection(date: NgbDateStruct) {
    this._setDates(date);
    this.filterList();
  }

  private _setDates(date: NgbDateStruct) {
    console.log('_setDates', date, this.fromDate, this.toDate);

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.toDate = date;
      this.lastDateSet = 'to';
      return;
    }

    if (this.lastDateSet === 'to' || before(date, this.fromDate)) {
      this.fromDate = date;
      this.toDate = date;
      this.lastDateSet = 'from';
      return;
    }

    if (this.lastDateSet === 'from') {
      this.toDate = date;
      this.lastDateSet = 'to';
      return;
    }
  }

  private async filterList() {
    this.selectedList = groceryListKeyFromDates(this.fromDate, this.toDate);

    this.setLastDate();
    const weekNumbers = this.calculateWeekNumbers();
    await this.loadWeeklyPlans(weekNumbers);

    const startMoment = this.getStartOfDayMoment(this.fromDate);
    const endMoment = this.getEndOfDayMoment(this.toDate || this.fromDate);

    this.startWeekNumber = this.getWeekNumber(startMoment);
    this.endWeekNumber = this.getWeekNumber(endMoment);

    const groceries = this.getGroceriesBetweenMoments(startMoment, endMoment);

    this.processAndOrganizeGroceries(groceries);
    this.sortGroups();
    this.mergeInCheckedItems(this.selectedList);
  }

  private calculateWeekNumbers() {
    const fromWeekNumber = this.getWeekNumber(this.dateObjectStructToMoment(this.fromDate));
    const toWeekNumber = this.toDate
      ? this.getWeekNumber(this.dateObjectStructToMoment(this.toDate))
      : fromWeekNumber;

    let currentWeekNumber = fromWeekNumber;
    const weekNumbers = [currentWeekNumber];

    while (currentWeekNumber !== toWeekNumber) {
      currentWeekNumber = addWeek(currentWeekNumber);
      weekNumbers.push(currentWeekNumber);
    }

    return weekNumbers;
  }

  private getWeekNumber(momentDate: moment.Moment) {
    return momentDate.isoWeekYear() + '.' + momentDate.isoWeek().toString().padStart(2, '0');
  }

  private async loadWeeklyPlans(weekNumbers) {
    const promises = weekNumbers
      .filter((wn) => !this.plannerService.hasWeeklyPlan(wn))
      .map((wn) => this.plannerService.loadWeeklyPlan(wn).toPromise());

    return Promise.all(promises);
  }

  private getStartOfDayMoment(date) {
    const momentDate = this.dateObjectStructToMoment(date);
    momentDate.hour(0);
    momentDate.minute(0);
    momentDate.second(0);

    return momentDate;
  }

  private getEndOfDayMoment(date) {
    const momentDate = this.dateObjectStructToMoment(date);
    momentDate.hour(23);
    momentDate.minute(59);
    momentDate.second(59);

    return momentDate;
  }

  private getGroceriesBetweenMoments(startMoment, endMoment) {
    let groceries = [];

    for (const p of this.weeklyPlans) {
      const weekPoints = this.weekNumberToMoments(p.weekNumber);
      const monMoment = moment(weekPoints.start).day(1);

      ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(
        (day, i) => {
          const dayMoment = moment(monMoment).add(i, 'days');
          if (dayMoment.isBetween(startMoment, endMoment, 'day', '[]')) {
            groceries = groceries.concat(p[day].getGroceries());
          }
        },
      );
    }

    return groceries;
  }

  private setLastDate() {
    this.lprxApi
      .saveVar('lastGroceryListDateSelection', {
        fromDate: this.fromDate,
        toDate: this.toDate,
      })
      .then((r) => {});
  }

  private getLastDate() {
    return from(
      this.lprxApi.getVar<{ fromDate: NgbDateStruct; toDate: NgbDateStruct }>(
        'lastGroceryListDateSelection',
      ),
    );
  }

  ngOnInit() {
    this.subscribeToUnitSystem();
    this.subscribeToGroceryLists();
    this.setPlannerPath();
    this.handleWeeklyPlan();
  }

  /**
   * Set the planner path in the session storage.
   */
  subscribeToUnitSystem() {
    const unitSystemSub = this.unitSystemService.unitSystem$.subscribe(
      (sys) => (this.unitSystem = sys),
    );
    this.subs.push(unitSystemSub);
  }

  getGroceryLists() {
    return from(this.plannerService.getGroceryLists());
  }

  /**
   * Get the grocery lists and subscribe to changes.
   * In addition, refresh the grocery lists every 10 seconds.
   */
  subscribeToGroceryLists() {
    const sub = this.getGroceryLists().subscribe(() => {});
    this.subs.push(sub);

    const groceryListsSub = this.groceryListsTrigger$
      .pipe(
        switchMap((list?) => {
          if (list) {
            return of(list);
          } else {
            return this.getGroceryLists();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((groceryLists) => {
        this.groceryLists = groceryLists;
        for (const gl of groceryLists) {
          if (gl.key === this.selectedList && gl.updatedAt > this.lastUpdatedAt) {
            this.lastUpdatedAt = gl.updatedAt;
            this.mergeInCheckedItems(gl.key);
          }
        }
      });

    const groceryListsSub2 = interval(1000 * 2.25).subscribe(() => {
      this.groceryListsTrigger$.next();
    });
    // const groceryListsSub = interval(1000 * 7)
    //   .pipe(switchMap(() => this.getGroceryLists()))
    //   .subscribe((groceryLists) => {
    //     for (const gl of groceryLists) {
    //       if (gl.key === this.selectedList && gl.updatedAt > this.lastUpdatedAt) {
    //         this.mergeInCheckedItems(gl.key);
    //       }
    //     }
    //   });

    this.subs.push(groceryListsSub);
    this.subs.push(groceryListsSub2);
  }

  setPlannerPath() {
    this.plannerPath = sessionStorage.getItem('plannerPath');
  }

  handleWeeklyPlan() {
    if (this.weeklyPlan) {
      this.handleExistingWeeklyPlan();
    } else {
      this.handleNewWeeklyPlan();
    }
  }

  /**
   * Handle the case where the weekly plan already exists.
   */
  handleExistingWeeklyPlan() {
    this.weeklyPlans = [this.weeklyPlan];
    const m = this.weekNumberToMoments(this.weeklyPlan.weekNumber);
    this.fromDate = this.momentToNgbDateObject(m.start);
    this.toDate = this.momentToNgbDateObject(m.end);
    // console.log('ngOnInit set dates', this.fromDate, this.toDate);
    this._init();
  }

  /**
   * Handle the case where the weekly plan does not exist.
   */
  handleNewWeeklyPlan() {
    const routeParams$ = this.route.params;
    const comb = combineLatest(routeParams$, this.plannerService.weeklyPlans$).subscribe(
      ([routeParams, weeklyPlans]) => {
        this.weeklyPlans = weeklyPlans;
        this.weeklyPlan = this.weeklyPlans?.[0]?.mealPlan?.nonCalendar
          ? this.weeklyPlans[0]
          : undefined;
        if (this.weeklyPlans && this.weeklyPlans.length > 0) {
          // console.log("_init set dates routeParams['weekNumber']", routeParams['weekNumber']);
          this._init(routeParams['weekNumber']);
        }
      },
    );

    this.subs.push(comb);
    this.subscribeToLayoutWidth();
  }

  subscribeToLayoutWidth() {
    const widthSub = this.layout.isWide$.subscribe((isWide) => {
      this.showCalendar = isWide;
    });
    this.subs.push(widthSub);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.subs.forEach((s) => s.unsubscribe());
  }

  private _init(weekNumber: string = null) {
    this.groceryCategories = GroceryCategories.topLevelCategories();

    for (const wp of this.weeklyPlans) {
      this.setGroceryDates(wp.weekNumber);
    }

    if (weekNumber) {
      this.setDateRangeFromWeekNumber(weekNumber);
    } else if (this.fullWeek) {
      this.setDateRangeFromWeekNumber(this.weeklyPlan.weekNumber);
    } else {
      // this.fromDate = this.calendar.getToday();
      // this.toDate = this.calendar.getNext(this.calendar.getToday(), 'd', 10);
      // // console.log('_init set dates 1', this.fromDate, this.toDate);
    }

    const minNgbDate = NgbDate.from(this.minGroceryDate);
    const maxNgbDate = NgbDate.from(this.maxGroceryDate);

    if (minNgbDate.after(NgbDate.from(this.fromDate))) {
      // this.fromDate = minNgbDate;
    }

    if (minNgbDate.after(NgbDate.from(this.toDate))) {
      // this.toDate = minNgbDate;
      // // console.log('_init set dates 2', this.fromDate, this.toDate);
    }

    if (maxNgbDate.before(NgbDate.from(this.fromDate))) {
      // this.fromDate = maxNgbDate;
    }

    if (maxNgbDate.before(NgbDate.from(this.toDate))) {
      // this.toDate = maxNgbDate;
      // console.log('_init set dates 3', this.fromDate, this.toDate);
    }
    if (!this.weeklyPlan) {
      this.getLastDate().subscribe({
        next: (lastGroceryListDateSelection) => {
          // console.log('lastGroceryListDateSelection', lastGroceryListDateSelection);
          // const areDatesTheSame =
          //   this.toDate &&
          //   this.fromDate &&
          //   lastGroceryListDateSelection &&
          //   lastGroceryListDateSelection.toDate &&
          //   lastGroceryListDateSelection.fromDate &&
          //   dateObjectToString(lastGroceryListDateSelection.toDate) ===
          //     dateObjectToString(this.toDate) &&
          //   dateObjectToString(this.fromDate) ===
          //     dateObjectToString(lastGroceryListDateSelection.fromDate);

          const areDatesTheSame =
            lastGroceryListDateSelection &&
            areDatesEqual(this.toDate, lastGroceryListDateSelection.toDate) &&
            areDatesEqual(this.fromDate, lastGroceryListDateSelection.fromDate);

          if (areDatesTheSame) {
            // console.log(this.fromDate, this.toDate, lastGroceryListDateSelection);
            return;
          }

          this.selectedList = groceryListKeyFromDates(this.fromDate, this.toDate);

          this.fromDate = lastGroceryListDateSelection.fromDate;
          this.toDate = lastGroceryListDateSelection.toDate;
          // // console.log(
          //   'this.getLastDate() set dates',
          //   this.fromDate,
          //   this.toDate,
          //   lastGroceryListDateSelection
          // );

          const groceries = this.weeklyPlans[0].getGroceries();
          this.processAndOrganizeGroceries(groceries);
          this.filterList().then((r) => {});
        },
        error: (err) => {
          this.fromDate = this.momentToNgbDateObject(
            this.weekNumberToMoments(this.weeklyPlans[0].weekNumber).start,
          );
          this.toDate = this.momentToNgbDateObject(
            this.weekNumberToMoments(this.weeklyPlans[0].weekNumber).end,
          );
          this.filterList().then((r) => {});
        },
        complete: () => {
          this.canSetLastDate = true;
          this.groceryListsTrigger$.next();
        },
      });
    } else {
      if (this.weeklyPlans[0].mealPlan.nonCalendar) {
        this.fromDate = this.momentToNgbDateObject(
          this.weekNumberToMoments(this.weeklyPlans[0].weekNumber).start,
        );
        this.toDate = this.momentToNgbDateObject(
          this.weekNumberToMoments(this.weeklyPlans[0].weekNumber).end,
        );
      }
      this.filterList().then((r) => {});
    }

    // const lastGroceryListDateSelection = JSON.parse(
    //   localStorage.getItem('lastGroceryListDateSelection')
    // ) as DateSelection;
    //
    // if (lastGroceryListDateSelection) {
    //   this.fromDate = lastGroceryListDateSelection.fromDate;
    //   this.toDate = lastGroceryListDateSelection.toDate;
    // }
    // this.selectedList = groceryListKeyFromDates(this.fromDate, this.toDate);
    //
    // const groceries = this.weeklyPlans[0].getGroceries();
    // this.groupGroceries(groceries);
  }

  // private groupGroceries(groceries: GroceryItem[] = []) {
  //   this.processing = true;
  //
  //   groceries.sort((a, b) => {
  //     if (typeof a.item !== 'string') {
  //       a.item = '';
  //     }
  //
  //     if (typeof b.item !== 'string') {
  //       b.item = '';
  //     }
  //
  //     if (a.item == null) {
  //       return 0;
  //     }
  //
  //     if (b.item == null) {
  //       return 0;
  //     }
  //
  //     if (a.item.toLowerCase() < b.item.toLowerCase()) {
  //       return -1;
  //     }
  //     if (a.item.toLowerCase() > b.item.toLowerCase()) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //
  //   const onList = [];
  //
  //   this.listItems = [];
  //   for (const i of groceries) {
  //     // this.allItems.forEach(i => {
  //     this.addToList(i);
  //   }
  //   // );
  //
  //   if (this.consolidateItems) {
  //     for (let i = 0; i < this.listItems.length; i++) {
  //       if (this.listItems[i].unit == null) {
  //         this.listItems[i].quantity = Math.ceil(this.listItems[i].quantity);
  //       }
  //     }
  //   }
  //
  //   // this.checkedItems = JSON.parse(localStorage.getItem('groceryListCheckedItems'));
  //   // if (!this.checkedItems) {
  //   //   this.checkedItems = {};
  //   // }
  //
  //   // const _that = this;
  //
  //   this.groupedItems = this.listItems.reduce((accumulator, item) => {
  //     // const key = this.groceryListItemKey(item);
  //     // item.isChecked = !!_that.checkedItems[key];
  //
  //     if (!item.groceryCategory) {
  //       item.groceryCategory = 'Other';
  //     }
  //     if (!accumulator.hasOwnProperty(item.groceryCategory)) {
  //       accumulator[item.groceryCategory] = [];
  //     }
  //     accumulator[item.groceryCategory].push(item);
  //     return accumulator;
  //   }, {});
  //
  //   this.groceryListCategories = Object.keys(this.groupedItems);
  //   this.processing = false;
  // }

  private processAndOrganizeGroceries(groceries: GroceryItem[] = []) {
    this.processing = true;

    sortGroceries(groceries);
    this.addGroceriesToList(groceries);
    this.consolidateGroceryItems();
    this.groupGroceryItems();

    this.groceryListCategories = Object.keys(this.groupedItems);
    this.processing = false;
  }

  // private sortGroceries(groceries: GroceryItem[]) {
  //   groceries.sort((a, b) => {
  //     const firstItem = a.item?.toLowerCase() ?? '';
  //     const secondItem = b.item?.toLowerCase() ?? '';
  //
  //     if (firstItem < secondItem) {
  //       return -1;
  //     }
  //     if (firstItem > secondItem) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  // }

  private addGroceriesToList(groceries: GroceryItem[]) {
    this.listItems = [];
    for (const item of groceries) {
      this.addToList(item);
    }
  }

  private consolidateGroceryItems() {
    if (this.consolidateItems) {
      for (const item of this.listItems) {
        if (item.unit == null) {
          item.quantity = Math.ceil(item.quantity);
        }
      }
    }
  }

  private groupGroceryItems() {
    this.groupedItems = this.listItems.reduce(
      (
        accumulator: { [x: string]: any[] },
        item: { item: string; groceryCategory: string; groceryCategoryKey: string },
      ) => {
        item.groceryCategory = GroceryCategories.mapOldCategoryToNew(item.groceryCategory);

        item.groceryCategory = item.groceryCategory || 'Other';
        accumulator[item.groceryCategory] = accumulator[item.groceryCategory] || [];
        accumulator[item.groceryCategory].push(item);
        return accumulator;
      },
      {},
    );
  }

  private addToList(i) {
    console.log('addToList', i);
    if (i.item == null) {
      i.item = '';
    }

    const itemName = i.item.toLowerCase();
    const quantity = i.quantity ? i.quantity : 0;

    if (i.unit) {
      i.unit = i.unit.toLowerCase();
    }
    const foundUnit = UnitConverter.findUnitAbbrvOrDefault(i.unit);

    if (this.consolidateItems) {
      for (const listItem of this.listItems) {
        // if the units are the same, then just add the quantity
        if (
          listItem.item === itemName ||
          listItem.item.replace(/s$/, '') === itemName.replace(/s$/, '')
        ) {
          if (foundUnit === listItem.unit) {
            console.log(`Adding ${i.quantity} ${listItem.quantity} for ${listItem.item}`);
            listItem.quantity += i.quantity;
            return;
          }

          try {
            // try a unit conversion
            const q = convertUnits(quantity).from(foundUnit).to(listItem.unit);
            listItem.quantity += q;
            return;
          } catch (e) {
            // console.log(`Could not convert ${foundUnit} to ${listItem.unit}`);
          }
        }
      }
    }

    // // console.log(`Add ${itemName} ${quantity} ${foundUnit} to the list`);
    this.listItems.push({
      item: itemName,
      quantity,
      unit: foundUnit,
      groceryCategory: i.groceryCategory,
    });
  }

  private setGroceryDates(weekNumber: string) {
    // console.log(`setGroceryDates(${weekNumber})`);
    const weekPoints = this.weekNumberToMoments(weekNumber);
    if (weekPoints.start.isBefore(this.dateObjectStructToMoment(this.minGroceryDate))) {
      // console.log('appending grocery week to:  ');
      // console.log(weekPoints.start);
      this.minGroceryDate = this.momentToNgbDateObject(weekPoints.start);
    }

    if (weekPoints.end.isAfter(this.dateObjectStructToMoment(this.maxGroceryDate))) {
      // console.log('extending grocery week to:  ');
      // console.log(weekPoints.end);
      this.maxGroceryDate = this.momentToNgbDateObject(weekPoints.end);
    }
  }

  private momentToNgbDateObject(m: moment.Moment): DateObject {
    return {
      year: m.year(),
      month: m.month() + 1,
      day: m.date(),
    };
  }

  private dateObjectStructToMoment(date: DateObject): moment.Moment {
    return moment()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day);
  }

  private weekNumberToMoments(weekNumber: string): WeekPoint {
    const [year, week] = weekNumber.split('.');
    const start = moment()
      .isoWeekYear(parseInt(year, 10))
      .isoWeek(parseInt(week, 10))
      .isoWeekday(1);
    const end = moment().isoWeekYear(parseInt(year, 10)).isoWeek(parseInt(week, 10)).isoWeekday(7);
    return {
      start,
      end,
    };
  }

  toggleItemConsolidation() {
    this.consolidateItems = !this.consolidateItems;
    this.processAndOrganizeGroceries();
  }

  /**
   * @param {string} weekNumber
   */
  private setDateRangeFromWeekNumber(weekNumber: string) {
    const weekPoints = this.weekNumberToMoments(weekNumber);
    this.fromDate = this.momentToNgbDateObject(weekPoints.start);
    this.toDate = this.momentToNgbDateObject(weekPoints.end);
    // console.log('setDateRangeFromWeekNumber set dates', this.fromDate, this.toDate);
    this.selectedList = groceryListKeyFromDates(this.fromDate, this.toDate);
  }

  /**
   * @param item
   */
  toggleItem(item: GroceryListItem) {
    item.isChecked = !item.isChecked;

    this.sortGroups();

    const key = this.groceryListItemKey(item);

    // if (item.isChecked) {
    //   this.checkedItems[key] = Date.now();
    // } else {
    //   delete this.checkedItems[key];
    // }

    // localStorage.setItem('groceryListCheckedItems', JSON.stringify(this.checkedItems));

    this.saveGroceryList();
  }

  private groceryListItemKey(item: GroceryListItem) {
    return (
      this.formatDateStruct(this.fromDate) +
      '-' +
      (this.toDate !== null
        ? this.formatDateStruct(this.toDate)
        : this.formatDateStruct(this.fromDate)) +
      '-' +
      item.item +
      '-x' +
      item.quantity
    );
  }

  formatDateStruct(d: NgbDateStruct) {
    if (d === null) {
      return '';
    }
    return `${d.year}-${d.month}-${d.day}`;
  }

  getKey(item: any) {
    return `grocery-list-checked-${item.item}`;
  }

  hideCalendar() {
    this.showCalendar = false;
  }

  // /**
  //  *
  //  * @param unitSystemName
  //  */
  // setUnitSystem(unitSystemName: string) {
  //   this.unitSystem =
  //     unitSystemName === 'imperial'
  //       ? UnitSystem.Imperial
  //       : UnitSystem.Metric;
  //
  //   setUnitSystemPreference(this.unitSystem);
  // }

  convertToWeekNumber(weekNumber: string) {
    return nonCalendarDisplay(weekNumber);
  }

  saveGroceryList() {
    this.lastUpdatedAt = Date.now();
    this.plannerService
      .saveGroceryList(this.fromDate, this.toDate, this.groupedItems)
      .then((dto) => {
        this.selectedList = dto.key;
        // this.groupedItems = dto.groupedItems;
      });
  }

  deleteGroceryList(key: string) {
    from(this.plannerService.deleteGroceryList(key)).subscribe({
      next: (groceryLists) => {
        // this.groceryLists = groceryLists;
        this.groceryListsTrigger$.next(groceryLists);
        this.toastr.success('Deleted', 'Grocery list deleted');
      },
    });
  }

  // Compares two dates and updates min and max dates
  updateMinMaxDates(date: NgbDateStruct) {
    const dateStr = dateObjectToString(date);
    if (dateStr < dateObjectToString(this.minGroceryDate)) {
      this.minGroceryDate = date;
    }
    if (dateStr > dateObjectToString(this.maxGroceryDate)) {
      this.maxGroceryDate = date;
    }
  }

  changeList(key: string) {
    this.layout.isNarrow$.pipe(take(1)).subscribe((isNarrow) => {
      if (isNarrow) {
        this.hideCalendar();
      }
    });

    // if non-calendar display, use changeToWeekNumber
    if (this.weeklyPlans[0].mealPlan.nonCalendar) {
      const dates = keyToDateObjects(key);
      const fromDateObject = dates[0];
      const moment1 = this.dateObjectStructToMoment(fromDateObject);
      const weekNumber = this.getWeekNumber(moment1);
      this.changeToWeekNumber(weekNumber);
      return;
    }

    this.updateFromGroceryListKey(key);

    // console.log('changeList set dates', this.fromDate, this.toDate);

    this.selectedList = groceryListKeyFromDates(this.fromDate, this.toDate);

    // this.groupedItems = list.groupedItems;
    this.filterList();
  }

  changeWeek($event) {
    // console.log($event);
    const selectedWeekNumber = $event.target.value;

    this.changeToWeekNumber(selectedWeekNumber);
  }

  private changeToWeekNumber(selectedWeekNumber) {
    for (const wp of this.weeklyPlans) {
      if (selectedWeekNumber === wp.weekNumber) {
        const toMoment = this.weekNumberToMoments(wp.weekNumber);
        this.fromDate = this.momentToNgbDateObject(toMoment.start);
        this.toDate = this.momentToNgbDateObject(toMoment.end);
        // console.log('changeWeek set dates', this.fromDate, this.toDate);
        this.filterList();
        this.weeklyPlan = wp;
        this.lastUpdatedAt = 0;
        // this.ngOnInit();
      }
    }
  }

  /**
   * Update the fromDate and toDate objects from a grocery list key
   *
   * @param {string} key
   * @private
   */
  private updateFromGroceryListKey(key: string) {
    const [fromDateString, toDateString] = keyToDateObjects(key);

    this.fromDate = fromDateString;
    this.updateMinMaxDates(this.fromDate);

    this.toDate = toDateString;
    this.updateMinMaxDates(this.toDate);
  }

  private addAdditionalItems(category, item, list) {
    if (!this.groupedItems[category]) {
      this.groupedItems[category] = [];
    }

    if (!this.groupedItems[category].find((i) => i.item === item.item)) {
      this.groupedItems[category].push(item);
    }
  }

  private checkItems(category, item, list) {
    const itemKey = this.groceryListItemKey(item);

    if (!list.groupedItems[category]) {
      list.groupedItems[category] = [];
    }

    const glItem = list.groupedItems[category].find((i) => this.groceryListItemKey(i) === itemKey);

    if (glItem) {
      item.isChecked = glItem.isChecked;
      item.isHidden = glItem.isHidden;
    }
  }

  private mergeInCheckedItems(key) {
    from(this.plannerService.getGroceryList(key)).subscribe({
      next: (list) => {
        for (const category of Object.keys(this.groupedItems)) {
          // Uncheck and unhide all items, remove any additional items
          this.groupedItems[category] = this.groupedItems[category]
            .filter((item) => !item.isAdditional)
            .map((item) => {
              item.isChecked = false;
              item.isHidden = false;
              return item;
            });

          // Ensure any list.groupedItems that are isAdditional are added to
          // the appropriate this.groupedItems category
          if (list.groupedItems[category]) {
            for (const item of list.groupedItems[category]) {
              if (item.isAdditional) {
                this.addAdditionalItems(category, item, list);
              }
            }
          }

          // Check items in this.groupedItems that are checked in list.groupedItems
          for (const item of this.groupedItems[category]) {
            this.checkItems(category, item, list);
          }
        }

        this.sortGroups();
      },
    });
  }

  private sortGroups() {
    this.hiddenItems = [];

    // Sort by isChecked, then by item name
    const sortItems = (a, b) => {
      return a.item.localeCompare(b.item);
    };

    for (const category of Object.keys(this.groupedItems)) {
      this.groupedItems[category].sort(sortItems);

      for (const item of this.groupedItems[category]) {
        if (item.isHidden) {
          this.hiddenItems.push(item);
        }
      }
    }

    this.hiddenItems.sort((a, b) => a.item.localeCompare(b.item));
  }

  hideItem(item: GroceryListItem) {
    item.isHidden = true;
    this.sortGroups();
    this.saveGroceryList();
  }

  addNewAdditionalItem() {
    // if groupedItems does not have category 'Other' create it then add this.addItem

    if (this.addItem === '') {
      return;
    }

    if (!this.groupedItems[this.addItemSelectedCategory]) {
      this.groupedItems[this.addItemSelectedCategory] = [];
      this.groceryListCategories = Object.keys(this.groupedItems);
    }

    this.groupedItems[this.addItemSelectedCategory].push({
      item: this.addItem,
      isChecked: false,
      equivalency: '',
      groceryCategory: '',
      groceryCategoryKey: '',
      ndb: undefined,
      note: '',
      nutrients: undefined,
      ownedBy: '',
      quantity: 0,
      technique: '',
      unit: '',
      isAdditional: true,
      isHidden: false,
    });

    this.toastr.success(`Added ${this.addItem} to list`, 'Success');

    this.addItem = '';

    this.saveGroceryList();

    // this.additionalItems.push({
    //   item: '',
    //   quantity: 1,
    //   isChecked: false,
    // });
  }

  unHideItem(item: GroceryListItem) {
    item.isHidden = false;
    this.sortGroups();
    this.saveGroceryList();
  }

  addItemOnTabOrEnter($event: KeyboardEvent) {
    if ($event.key === 'Tab' || $event.key === 'Enter') {
      this.addNewAdditionalItem();
    }
  }
  categories() {
    if (!this.groupedItems) {
      return [];
    }

    const cats = GroceryCategories.topLevelCategories();

    // const cats = Object.keys(this.groupedItems);

    // // add Other category if it doesn't exist
    // if (!cats.includes('Other')) {
    //   cats.push('Other');
    // }

    cats.sort((a, b) => a.localeCompare(b));

    return cats;
  }

  // Creates a mapping of month numbers to their abbreviated names
  private get monthMapping() {
    return [
      null,
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
  }

  private getWeekOfMoment(momentDate: moment.Moment) {
    return `Week ${momentDate.isoWeek()}`;
  }

  private getFormattedDateRange(fromDate, toDate) {
    const startMonth = this.monthMapping[fromDate.month];
    const startDay = Number(fromDate.day);
    const endMonth = this.monthMapping[toDate.month];
    const endDay = Number(toDate.day);

    // If the months and days are the same, return a single date
    if (startMonth === endMonth && startDay === endDay) {
      return `${startMonth} ${startDay}`;
    }

    // If the months are the same but the days are different, return a range with one month
    if (startMonth === endMonth) {
      return `${startMonth} ${startDay}-${endDay}`;
    }

    // If the months are different, return a range with both months
    return `${startMonth} ${startDay}-${endMonth} ${endDay}`;
  }

  keyToDate(key: string) {
    const [fromDate, toDate] = keyToDateObjects(key);

    // if fromDate.year is less than 2000 then it is a non-calendar date range,
    // and we should return the key as Week 1, Week 2, etc.
    // Week 1 is the first week of the year that has a Sunday in it
    // Week 2 is the second week of the year that has a Sunday in it
    // etc.
    if (fromDate.year < 2000) {
      const fromMoment = this.dateObjectStructToMoment(fromDate);
      return this.getWeekOfMoment(fromMoment);
    }

    // // console.log('keyToDate', key, fromDate, toDate);

    return this.getFormattedDateRange(fromDate, toDate);
  }

  showGroupIfHasVisibleItems(groupedItem: GroceryListItem[]) {
    return groupedItem.filter((i) => !i.isHidden).length > 0;
  }

  copyToClipboard() {
    // collect all visible items and copy to clipboard
    const items = [];

    for (const category of Object.keys(this.groupedItems)) {
      for (const item of this.groupedItems[category]) {
        if (!item.isHidden) {
          items.push(item);
        }
      }
    }

    // Prepare text to be copied
    const text = items
      .map((item) => {
        const { item: itemName, quantity, unit } = item;
        const quantityText = quantity ? `, (${Math.ceil(quantity)})` : '';
        const unitText = unit ? ` ${unit}` : '';

        return `${itemName}${quantityText}${unitText}`;
      })
      .join('\n');

    this.clipboard.copy(text);

    this.toastr.success('Copied to clipboard', 'Success');
  }

  resetList() {
    // reset all items in the list to unchecked, remove additional items, show all items
    for (const category of Object.keys(this.groupedItems)) {
      for (let i = 0; i < this.groupedItems[category].length; i++) {
        const item = this.groupedItems[category][i];
        item.isChecked = false;
        item.isHidden = false;
        if (item.isAdditional) {
          this.groupedItems[category].splice(this.groupedItems[category].indexOf(item), 1);
          i--;
        }
      }
    }

    this.saveGroceryList();

    this.toastr.success('List reset', 'Success');
  }
}
