/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/distributor-access-level.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum DistributorAccessLevel {
  Open = 'o',
  ActiveDistributor = 'a'
}
