<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->
<!--- NARROW ---->

<ng-container *ngIf="layout.isNarrow$ | async">
  <div
    class="bg-light"
    style="position: fixed; width: 100%; z-index: 1"
    [class.is-leftover]="card?.isLeftover"
  >
    <ng-container *ngIf="canCustomizePlanner">
      <ng-container *ngIf="controlBarType == 'info'">
        <div
          class="row align-items-center no-gutters"
          [class.bg--warning]="showEdit"
          [class.bg--primary]="!showEdit"
        >
          <div class="col-10">
            <div class="btn btn-block bg-control-bar rounded-0">
              {{ day | dayDisplay: weekNumber:'dddd, MMMM, D' }} &ndash; {{ mealName | titlecase }}
            </div>
          </div>
          <div class="col-2 bg-info">
            <button
              (click)="showEdit = !showEdit"
              type="button"
              class="btn btn-block rounded-0"
              [class.btn-primary]="!showEdit"
            >
              <i class="fa" [class.fa-edit]="!showEdit" [class.fa-chevron-up]="showEdit"></i>
            </button>
          </div>
        </div>

        <ng-container *ngIf="isOnPlanner && showEdit">
          <div class="row align-items-center no-gutters">
            <div class="col-4">
              <button (click)="remove()" type="button" class="btn btn-danger btn-block rounded-0">
                <i class="fa fa-close"></i> Remove
              </button>
            </div>
            <div class="col-8">
              <button
                (click)="controlBarType = 'edit'"
                type="button"
                class="btn btn-success btn-block rounded-0"
              >
                <i class="fa fa-arrows"></i> Move Recipe
              </button>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="controlBarType == 'edit'">
        <ng-container *ngIf="isOnPlanner">
          <div class="text-center control-bar">Select a new date or time to move.</div>
        </ng-container>

        <ng-container *ngIf="!isOnPlanner">
          <div class="text-center control-bar">Add this recipe to your planner.</div>
        </ng-container>

        <div class="row align-items-center bg-light no-gutters">
          <div class="col-6">
            <div class="border-right" ngbDropdown #dayDrop="ngbDropdown">
              <button
                ngbDropdownAnchor
                (focus)="dayDrop.open()"
                (click)="dayDrop.open()"
                class="btn btn-block btn-light mr-2 text-left"
                id="dropdownManual"
              >
                {{ addToPlannerDay.dayName | dayDisplay: addToPlannerDay.weekNumber:'dddd, MMM D' }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownManual">
                <button
                  *ngFor="let plannerDay of plannerDays.values()"
                  (click)="setMoveToDay(plannerDay)"
                  ngbDropdownItem
                  class="btn btn-block btn-light text-left rounded-0"
                >
                  {{ plannerDay.dayName | dayDisplay: plannerDay.weekNumber:'ddd, MMM D' }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="" ngbDropdown #mealDrop="ngbDropdown">
              <button
                ngbDropdownAnchor
                (focus)="mealDrop.open()"
                (click)="mealDrop.open()"
                class="btn btn-block btn-light mr-2 text-left rounded-0"
                id="dropdownManual2"
              >
                {{ addToMealName | titlecase }}
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownManual2">
                <button
                  *ngFor="let mealOption of mealOptions"
                  (click)="setToMeal(mealOption)"
                  ngbDropdownItem
                  type="button"
                  class="btn btn-block btn-light text-left"
                >
                  {{ mealOption | titlecase }}
                </button>
              </div>
            </div>
          </div>

          <div class="col-4">
            <button (click)="cancel()" type="button" class="btn btn-danger btn-block rounded-0">
              <i *ngIf="isOnPlanner" class="fa fa-chevron-up"></i>
              <i *ngIf="!isOnPlanner" class="fa fa-chevron-left"></i>
              Cancel
            </button>
          </div>

          <div class="col-8">
            <button
              (click)="isOnPlanner ? moveRecipe() : addToPlanner()"
              type="button"
              class="btn btn-block rounded-0"
              [class.btn-primary]="readyToSave || !isOnPlanner"
              style="border-radius: 0"
            >
              <ng-container *ngIf="isOnPlanner"> <i class="fa fa-check"></i> Save </ng-container>

              <ng-container *ngIf="!isOnPlanner">
                <i class="fa fa-plus"></i> Add Recipe
              </ng-container>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!canCustomizePlanner">
      <ng-container *ngIf="isOnPlanner">
        <div class="btn btn-block bg-control-bar rounded-0">
          {{ day | dayDisplay: weekNumber:'dddd, MMMM, D' }} &ndash; {{ mealName | titlecase }}
        </div>
      </ng-container>

      <ng-container *ngIf="!isOnPlanner">
        <div class="btn btn-block bg-control-bar rounded-0">
          Upgrade to put this recipe on your planner.
          <br />
          <button (click)="goToUpgrade()" type="button" appButton btnSmall>
            Upgrade
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div [style.background-image]="recipe.image | cdn: 650:650" class="mobile-recipe-image"></div>

  <div class="mobile-recipe-wrapper">
    <div class="container py-3">
      <div class="row no-gutters">
        <div class="col">
          <h3 class="mb-0">{{ recipe.title }}</h3>
        </div>
        <div class="col-auto">
          <app-favorite-recipe-toggle [recipe]="recipe"></app-favorite-recipe-toggle>
        </div>
      </div>
    </div>

    <div class="row no-gutters align-items-center text-center border-top">
      <div class="col-6 border-right">
        <div class="p-3">
          Contributed by

          <ng-container *ngIf="contributor">
            <ng-container
              *ngIf="
                contributor.website &&
                contributor.website !== '#' &&
                !contributor.website?.match('livingplate')
              "
            >
              <a [href]="contributor.website" target="_blank">
                <ng-container *ngIf="contributor.logo">
                  <img
                    [src]="contributor.logo | cdn: 150:0:false"
                    [alt]="contributor.name"
                    style="max-width: 100%"
                  />
                </ng-container>

                <ng-container *ngIf="contributor?.logo === ''">
                  {{ contributor.name }}
                </ng-container>
              </a>
            </ng-container>

            <ng-container
              *ngIf="
                !(
                  contributor.website &&
                  contributor.website !== '#' &&
                  !contributor.website?.match('livingplate')
                )
              "
            >
              <ng-container *ngIf="contributor.logo">
                <img
                  [src]="contributor.logo | cdn: 150:0:false"
                  [alt]="contributor.name"
                  style="max-width: 100%"
                />
              </ng-container>

              <ng-container *ngIf="contributor?.logo === ''">
                {{ contributor.name }}
              </ng-container>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="!(contributor?.name !== '')">
            <h4>-</h4>
          </ng-container>
        </div>
      </div>
      <div class="col-6">
        <div class="p-3" [class.coupon-border]="sourceLabel === ''">
          <span [innerHTML]="sourceLabel | titlecase | sourceDisplay: true"></span>
          <ng-container *ngIf="!recipe.source.url?.match('livingplate')">
            <a [href]="recipe.source.url | sanitizeUrl" target="_blank">{{ recipe.source.name }}</a>
          </ng-container>
          <ng-container *ngIf="recipe.source.url?.match('livingplate')">
            {{ recipe.source.name }}
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row no-gutters text-center border-bottom border-top bg-light">
      <ng-container *ngIf="card?.isLeftover">
        <div class="col-12 py-2 bg-control-bar">
          <img src="/assets/img/icon/doggie-bag.svg" height="30" alt="" />
          <span style="position: relative; top: 2px">Leftovers</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!card?.isLeftover || overrideHiddenWhenLeftover">
        <div class="col-4 py-2">
          Servings
          <br />
          <div (click)="subServing()" class="btn btn-sm bg-light">
            <i class="fa fa-chevron-left"></i>
          </div>
          &nbsp;&nbsp;
          <h4 style="display: inline-block">{{ userServings }}</h4>
          &nbsp;&nbsp;
          <div (click)="addServing()" class="btn btn-sm bg-light">
            <i class="fa fa-chevron-right"></i>
          </div>
        </div>
        <div class="col-4 py-2 border-left border-right">
          Prep Time
          <h4>{{ prepTime ? prepTime : '-' }}</h4>
        </div>
        <div class="col-4 py-2">
          Total Time
          <h4>{{ totalTime ? totalTime : '-' }}</h4>
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="!canCustomizePlanner && !isOnPlanner">
      <div class="container">
        <div class="alert alert-warning text-center p-4">
          <h5>
            Your plan does not include the ability to view recipes that are not on your planner.
          </h5>
          <br />
          <button type="button" appButton (click)="goToUpgrade()">
            Upgrade to the Custom Planner
          </button>
        </div>
      </div>
    </ng-container>

    <div class="container">
      <ng-container *ngIf="card?.isLeftover && !overrideHiddenWhenLeftover">
        <div class="row">
          <div class="col-12 py-4 text-center">
            <button
              type="button"
              class="btn btn-outline-secondary"
              (click)="overrideHiddenWhenLeftover = !overrideHiddenWhenLeftover"
            >
              Show Recipe
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!card?.isLeftover || overrideHiddenWhenLeftover">
        <div *ngFor="let element of recipe.elements">
          <ng-container *ngIf="recipe.elements?.length > 1">
            <div class="row mobile-element-name">
              <div class="col">
                <h5 class="my-0 py-3">{{ element.name }}</h5>
              </div>
            </div>
          </ng-container>

          <div *ngIf="element.ingredients.length > 0">
            <div class="row border-bottom-dashed align-items-center">
              <div class="col">
                <h5 class="py-2 my-0">Ingredients</h5>
              </div>
              <div class="col-4 text-right">
                <app-unit-system-toggle></app-unit-system-toggle>
              </div>
            </div>

            <ul class="ingredient-list my-3 mobile-list">
              <li
                *ngFor="let ingredient of element.ingredients"
                [innerHTML]="
                  ingredient | ingredientDisplay: recipe.servings:userServings:false:unitSystem
                "
              ></li>
            </ul>
          </div>

          <div *ngIf="showDirections(element)">
            <div class="row border-top border-bottom-dashed">
              <div class="col">
                <h5 class="py-2 my-0">Directions</h5>
              </div>
            </div>

            <ng-container *ngIf="element.directions.prep?.steps.length > 0">
              <div class="mb-2">
                <div class="row bg-light mb-2">
                  <div class="col py-2">
                    <strong>Prep</strong>
                  </div>
                </div>
                <ol class="direction-list my-2 mobile-list">
                  <li *ngFor="let step of element.directions.prep.steps" class="py-3">
                    {{ step.direction }}
                  </li>
                </ol>
              </div>
            </ng-container>

            <ng-container *ngIf="element.directions.make?.steps.length > 0">
              <div class="mb-2">
                <div class="row bg-light mb-2">
                  <div class="col py-2">
                    <strong>Make</strong>
                  </div>
                </div>
                <ol class="direction-list mobile-list">
                  <li *ngFor="let step of element.directions.make.steps" class="py-3">
                    {{ step.direction }}
                  </li>
                </ol>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>

      <div *ngIf="recipe.notes && recipe.notes != ''" class="">
        <div class="row border-top border-bottom-dashed">
          <div class="col">
            <h5 class="py-2 my-0">Notes</h5>
          </div>
        </div>
        <div class="row recipe-notes">
          <div [innerHTML]="notes" class="col py-3"></div>
        </div>
      </div>

      <ng-container *ngIf="showNutritionalInfo">
        <div class="row border-top py-3 bg-light">
          <div class="col">
            <app-nutrition-facts-label
              [nutrients]="recipe.getNutrients(allNutrients)"
            ></app-nutrition-facts-label>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->
<!--- WIDE ---->

<ng-container *ngIf="layout.isWide$ | async">
  <!--    <ng-container *ngIf="showEditButton">-->
  <!--        <div class="d-print-none">-->
  <!--            <a [routerLink]="'/recipe/edit/' + recipe.id + '?returnTo='"-->
  <!--               btnSecondary>Edit Recipe</a>-->
  <!--        </div>-->
  <!--    </ng-container>-->

  <div class="row print-keep-together" [class.is-leftover]="card?.isLeftover">
    <div class="col-6">
      <div class="row print-only">
        <div class="col">
          <h2>{{ recipe.title }}</h2>
          <br />
        </div>
      </div>

      <ng-container *ngIf="prepTime">
        <div class="row align-items-center">
          <div class="col-4">Prep Time</div>
          <div class="col-8">{{ prepTime }}</div>
        </div>
      </ng-container>

      <ng-container *ngIf="totalTime">
        <br class="d-print-none" />

        <div class="row align-items-center">
          <div class="col-4">Total Time</div>
          <div class="col-8">{{ totalTime }}</div>
        </div>
      </ng-container>

      <br class="d-print-none" />

      <div class="row">
        <div class="col-4">Meal Type</div>
        <div class="col-8">
          {{ recipe.mealTypes }}
        </div>
      </div>

      <div *ngIf="contributor?.name">
        <br class="d-print-none" />
        <div class="row">
          <div class="col-4">Contributed By</div>
          <div class="col-6">
            <ng-container
              *ngIf="
                contributor.website &&
                contributor.website !== '#' &&
                !contributor.website?.match('livingplate')
              "
            >
              <a [href]="contributor.website">
                <img [src]="contributorLogo" width="150" />
              </a>
            </ng-container>
            <ng-container
              *ngIf="
                !(
                  contributor.website &&
                  contributor.website !== '#' &&
                  !contributor.website?.match('livingplate')
                )
              "
            >
              <img [src]="contributorLogo" width="150" />
            </ng-container>
          </div>
        </div>
      </div>

      <div *ngIf="recipe.source?.name">
        <br class="d-print-none" />
        <div class="row">
          <div class="col-4">Source</div>
          <div class="col-6">
            {{ sourceLabel | titlecase | sourceDisplay }}
            <ng-container *ngIf="!recipe.source.url?.match('livingplate')">
              <a [href]="recipe.source.url | sanitizeUrl" target="_blank">{{
                recipe.source.name
              }}</a>
            </ng-container>
            <ng-container *ngIf="recipe.source.url?.match('livingplate')">
              {{ recipe.source.name }}
            </ng-container>
          </div>
        </div>
      </div>

      <br />

      <div class="row align-items-center">
        <div class="col-4">Servings</div>
        <div class="col">
          <div *ngIf="!card?.isLeftover" style="display: inline">
            {{ userServings }} &nbsp;&nbsp;
            <button
              type="button"
              (click)="subServing()"
              class="btn-servings-adjust btn btn-sm btn-default"
            >
              -
            </button>
            <button
              type="button"
              (click)="addServing()"
              class="btn-servings-adjust btn btn-sm btn-success"
            >
              +
            </button>

            &nbsp;
          </div>

          <button
            *ngIf="card"
            (click)="toggleIsLeftover()"
            type="button"
            class="btn-bare"
            title="{{
              card.isLeftover
                ? 'Unmark this as a leftover'
                : 'Mark this as a leftover on the planner'
            }}"
          >
            <img class="leftover-icon" src="/assets/img/icon/doggie-bag.svg" height="26" />
            <span *ngIf="card.isLeftover" class="text-primary" style="position: relative; top: 3px"
              >Leftovers</span
            >
          </button>
        </div>
      </div>

      <br class="d-print-none" />
    </div>

    <div class="col-6 text-center">
      <img src="{{ recipeImageUrl }}" style="max-width: 100%" />
    </div>
  </div>

  <br class="d-print-none" />

  <ng-container *ngIf="card?.isLeftover && !overrideHiddenWhenLeftover">
    <div class="text-center d-print-none">
      <br />
      <button
        type="button"
        class="btn btn-sm btn-outline-secondary"
        (click)="overrideHiddenWhenLeftover = !overrideHiddenWhenLeftover"
      >
        Show Recipe
      </button>
    </div>
  </ng-container>

  <div *ngIf="!recipe.canView" class="alert alert-warning text-center p-4">
    <h5>Your plan does not include the ability to view recipes that are not on your planner.</h5>
    <br />
    <button type="button" appButton (click)="goToUpgrade()">
      Upgrade to the Custom Planner
    </button>
  </div>

  <ng-container *ngIf="!card?.isLeftover || overrideHiddenWhenLeftover">
    <div *ngFor="let element of recipe.elements" class="hide-when-is-leftover">
      <ng-container *ngIf="recipe.elements?.length > 1">
        <div class="row border-top bg-light">
          <div class="col">
            <h5 class="my-0 py-3 element-header">{{ element.name }}</h5>
          </div>
        </div>
      </ng-container>

      <div class="row border-top py-4">
        <div *ngIf="element.ingredients.length > 0" class="col-5">
          <div class="row align-items-center ingredient-heading">
            <div class="col-auto"><h5>Ingredients</h5></div>
            <div class="col-auto">
              <app-unit-system-toggle></app-unit-system-toggle>
            </div>
          </div>
          <ul class="ingredient-list">
            <li
              *ngFor="let ingredient of element.ingredients"
              [innerHTML]="
                ingredient | ingredientDisplay: recipe.servings:userServings:false:unitSystem
              "
            ></li>
          </ul>
        </div>

        <div *ngIf="showDirections(element)" class="col-7">
          <h5>Directions</h5>
          <div *ngIf="element.directions.prep">
            <div *ngIf="element.directions.prep.steps.length > 0">
              <strong>Prep</strong>
              <ol class="direction-list">
                <li *ngFor="let step of element.directions.prep.steps">{{ step.direction }}</li>
              </ol>
            </div>
          </div>

          <div *ngIf="element.directions.make">
            <div *ngIf="element.directions.make.steps.length > 0">
              <strong>Make</strong>
              <ol class="direction-list">
                <li *ngFor="let step of element.directions.make.steps">{{ step.direction }}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div *ngIf="recipe.notes && recipe.notes != ''">
    <br />
    <h5>Notes</h5>
    <div class="recipe-notes" [innerHTML]="notes"></div>
  </div>

  <br class="d-print-none" />

  <div class="text-center" *ngIf="false">
    <small
      >(Facts are pulled from USDA database. New FDA Nutrition Facts label with expanded
      macronutrient and micronutrient information coming soon!)
    </small>
  </div>
  <div class="nutrition-facts" *ngIf="false">
    <div class="row">
      <div class="col-2">
        <div class="row">
          <div class="col">
            <h3>
              <strong>Nutrition<br />Facts</strong>
            </h3>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h4><strong>Calories</strong></h4>
            <strong>
              <small>per serving</small>
            </strong>
          </div>
          <div class="col text-right">
            <h3>
              <strong>{{ recipe.simpleNutritionFacts.calories }}</strong>
            </h3>
          </div>
        </div>
      </div>

      <div class="col-4 nutritional-data-table">
        <div class="row table-top">
          <div class="col-6">
            <small>Amount/serving</small>
          </div>
          <div class="col-6 text-right">
            <small>% Daily Value*</small>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <strong>Total Fat</strong> {{ recipe.simpleNutritionFacts.fat }}g
          </div>
          <div class="col-2 text-right">
            {{ percentDaily(recipe.simpleNutritionFacts.fat, 65) }}
          </div>
        </div>
        <div class="row">
          <div class="col-10">&nbsp;</div>
          <div class="col-2 text-right">&nbsp;</div>
        </div>
        <div class="row">
          <div class="col-10">&nbsp;</div>
          <div class="col-2 text-right">&nbsp;</div>
        </div>
        <div class="row">
          <div class="col-10">&nbsp;</div>
          <div class="col-2 text-right">&nbsp;</div>
        </div>
      </div>
      <div class="col-4 nutritional-data-table">
        <div class="row table-top">
          <div class="col-6">
            <small>Amount/serving</small>
          </div>
          <div class="col-6 text-right">
            <small>% Daily Value*</small>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <strong>Total Carbohydrates</strong> {{ recipe.simpleNutritionFacts.carbs }}g
          </div>
          <div class="col-2 text-right">
            {{ percentDaily(recipe.simpleNutritionFacts.carbs, 300) }}
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            &nbsp;&nbsp;Dietary Fiber {{ recipe.simpleNutritionFacts.carbsFromFiber }}g
          </div>
          <div class="col-2 text-right">
            {{ percentDaily(recipe.simpleNutritionFacts.carbsFromFiber, 25) }}
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            &nbsp;&nbsp;Total Sugars {{ recipe.simpleNutritionFacts.carbsFromSugar }}g
          </div>
          <div class="col-2 text-right"></div>
        </div>
        <div class="row">
          <div class="col-10">
            <strong>Protein</strong> {{ recipe.simpleNutritionFacts.protein }}g
          </div>
          <div class="col-2 text-right"></div>
        </div>
      </div>
      <div class="col-2">
        <small class="daily-info"
          ><br />* The percent Daily Value (DV) tells you how much a nutrient in a serving of food
          contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
        </small>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showNutritionalInfo">
    <div class="row border-top pt-3 nutrition-facts-wrapper">
      <div class="col">
        <app-nutrition-facts-label
          [nutrients]="recipe.getNutrients(allNutrients)"
        ></app-nutrition-facts-label>
      </div>
    </div>
  </ng-container>
</ng-container>
