import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { Step } from '../../../../../lprx-shared-lib/entities/recipe/Step';

/**
 * Generated class for the RecipeStepsInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'recipe-steps-input',
  templateUrl: 'recipe-steps-input.html',
})
export class RecipeStepsInputComponent implements OnInit {
  @Input() steps: Step[] = [];
  bag: string;

  // constructor(private dragulaService: DragulaService) {}

  ngOnInit(): void {
    this.bag = 'bag-' + Math.random() * 1000;

    // try {
    //   this.dragulaService.createGroup(this.bag, {
    //     direction: 'vertical',
    //     revertOnSpill: true,
    //     moves: (el, container, handle) => !!handle.className.match(/step-drag-handle/)
    //   });
    // } catch (e) {
    //   console.log(e.message);
    // }

    // dragulaService.drag.subscribe((value) => {
    //   this.onDrag(value.slice(1));
    // });
    // this.dragulaService.drop.subscribe((value) => {
    //   this.onDrop(value.slice(1));
    // });
    // dragulaService.over.subscribe((value) => {
    //   this.onOver(value.slice(1));
    // });
    // dragulaService.out.subscribe((value) => {
    //   this.onOut(value.slice(1));
    // });
  }

  addStep() {
    this.steps.push(new Step());
  }

  removeStep(step) {
    if (confirm('Are you sure you want to remove that step?')) {
      const index: number = this.steps.indexOf(step);
      if (index !== -1) {
        this.steps.splice(index, 1);
      }
    }
  }

  private hasClass(el: any, name: string) {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private addClass(el: any, name: string) {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  private removeClass(el: any, name: string) {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }

  private onDrag(args) {
    const [e, el] = args;
    this.removeClass(e, 'ex-moved');
  }

  private onDrop(args) {
    const [e, el] = args;
    this.addClass(e, 'ex-moved');
    console.log('asdasdsad');
    console.log(this.steps);
  }

  private onOver(args) {
    const [e, el, container] = args;
    this.addClass(el, 'ex-over');
  }

  private onOut(args) {
    const [e, el, container] = args;
    this.removeClass(el, 'ex-over');
  }

  drop($event: CdkDragDrop<any>) {
    console.log($event);
    moveItemInArray(this.steps, $event.previousIndex, $event.currentIndex);
  }
}
