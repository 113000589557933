<h5>Meal Plans</h5>
<p class="">
  Select which meal plans you would like this recipe to show up for when you or your clients
  searches on the their planner.
</p>

<label
  ><input
    type="checkbox"
    class='tw-h-4 tw-w-4 tw-border-gray-300 tw-focus:ring-2 tw-focus:ring-blue-300'
    (click)="selectAll()"
    [checked]="allChecked()"
  />
  Select All</label
><br />

<br />
<!--
<div class="row">
    <div *ngFor="let option of options" class="col-md-6">
        <label>
            <input type="checkbox" name="options" value="{{option.name}}" [(ngModel)]="option.checked" (change)="updateMealPlans()" /> {{option.name}}
        </label>
    </div>
</div>
-->
<div class="row">
  <div class="col-md-6">
    <label *ngFor="let option of optionsCol1" style="display: block">
      <input
        type="checkbox"
        name="options"
        value="{{ option.name }}"
        [(ngModel)]="option.checked"
        (change)="updateMealPlans()"
      />
      {{ option.name }}
    </label>
  </div>

  <div class="col-md-6">
    <label *ngFor="let option of optionsCol2" style="display: block">
      <input
        type="checkbox"
        name="options"
        value="{{ option.name }}"
        [(ngModel)]="option.checked"
        (change)="updateMealPlans()"
      />
      {{ option.name }}
    </label>
  </div>
</div>
