import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { AuthService } from '../../auth.service';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { getCurrentWeekNumber } from '../../utilities/getCurrentWeekNumber';
import { PlannerService } from '../planner.service';
import { nonCalendarDisplay } from '../../../lprx-shared-lib/non-calendar-display';
import { Day } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Day';

@Component({
  selector: 'app-planner-week',
  templateUrl: './planner-week.component.html',
  styleUrls: ['./planner-week.component.scss'],
})
export class PlannerWeekComponent implements OnInit, OnDestroy {
  // @Input() mealPlan: MealPlan;
  @Input() weeklyPlan: WeeklyPlan;

  @Input() canSwapUp: boolean = false;
  @Input() canSwapDown: boolean = false;

  isAdmin = false;

  user: User;

  @Output() copied = new EventEmitter<WeeklyPlan>();
  canPaste = false;
  days: string[] = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  today: string;

  private subs: Subscription[] = [];
  currentWeekNumber: string;

  // public canPasteSubject = new BehaviorSubject(false);

  constructor(private auth: AuthService, private plannerService: PlannerService) {}

  ngOnInit() {
    console.log('Planner Week: the mealPlan is');
    console.log(this.weeklyPlan.mealPlan);

    // if (!this.weeklyPlan) {
    //   this.weeklyPlan = new WeeklyPlan();
    // }

    this.today = this.days[moment().isoWeekday() - 1];
    this.currentWeekNumber = getCurrentWeekNumber();

    this.subs.push(
      this.plannerService.canPasteSubject.subscribe((canPaste) => (this.canPaste = canPaste))
    );
    this.subs.push(this.auth.getUser().subscribe((user) => (this.user = user)));
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
    this.plannerService.clearCopyPaste();
  }

  print() {}

  copy() {
    this.plannerService.copy(this.weeklyPlan);
  }

  paste() {
    this.plannerService.paste(this.weeklyPlan.weekNumber).subscribe((wp: WeeklyPlan) => {
      if (wp) {
        this.weeklyPlan = wp;
        this.copied.emit(this.weeklyPlan);
      }
    });
  }

  reset() {
    if (confirm('Are you sure you want to Reset this week?')) {
      this.plannerService.reset(this.weeklyPlan);
    }
  }

  nonCalendarDisplay(weekNumber: string) {
    return nonCalendarDisplay(weekNumber);
  }

  swapUp() {
    this.plannerService.swapWithPreviousWeek(this.weeklyPlan);
  }

  swapDown() {
    this.plannerService.swapWithNextWeek(this.weeklyPlan);
  }

  dayHasCards(day: Day) {
    return day.getCards().length > 0;
  }
}
