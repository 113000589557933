import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { ApiV2 } from '../../../providers/api.v2';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';

@Injectable()
export class DistributorClientsService {
  constructor(private apiV2: ApiV2, private lprxApi: LprxApiProvider) {}

  changeClientMealPlan(
    client: User,
    mealPlanId: string,
    refreshWeeks: string
  ): Observable<boolean> {
    const username = client.username;

    const promise = this.lprxApi.clients
      .changeMealPlan(username, mealPlanId, refreshWeeks)
      .then(() => true);

    return from(promise);

    // return this.apiV2.post$('distributor/clients/change-meal-plan', {
    //   username,
    //   mealPlanId,
    //   refreshWeeks
    // });
  }

  /**
   *
   * @param {string} username
   * @return {Observable<"pending" | "activated">}
   */
  activationStatus(username: string): Observable<'pending' | 'activated'> {
    return from(this.lprxApi.clients.activationStatus(username));
  }

  /**
   *
   * @param {string} username
   * @return {Observable<{url: string}>}
   */
  getActivationLink(username: string): Observable<{ url: string }> {
    return from(this.lprxApi.get<{ url: string }>(`v3/clients/${username}/activation-url`));
  }
}
