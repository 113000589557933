import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../lprx-shared-lib/utils/unsubscribe';
import { Auth } from '../../providers/aws.api';
import { clearLastAuthUserName } from '../../providers/getLastAuthUsername';
import { AuthService } from '../auth.service';
import { errorMessage } from '../utilities/alert-http-error';
import { ChangeLoginRequest } from '../../lprx-shared-lib/change-login-request';

@Component({
  selector: 'app-verify-email-change',
  templateUrl: './verify-email-change.component.html',
  styleUrls: ['./verify-email-change.component.scss'],
})
export class VerifyEmailChangeComponent implements OnInit, OnDestroy {
  errorMessage: string;
  success: boolean;
  clr: ChangeLoginRequest;
  newEmail: string;
  processing = true;

  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private router: Router
  ) {}

  private subs: Subscription[] = [];

  ngOnInit(): void {
    this.subs.push(
      this.route.params.subscribe((params) => {
        this.verifyEmail(params['key']);
      })
    );
  }

  private verifyEmail(key: string) {
    this.authService
      .changeLoginEmailAddressVerify(key)
      .then(async (clr: ChangeLoginRequest) => {
        this.clr = clr;
        await Auth.signOut();
        clearLastAuthUserName();
        this.success = true;
        this.newEmail = clr.email;
      })
      .catch((error) => {
        this.errorMessage = errorMessage(error);
      })
      .finally(() => (this.processing = false));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  login() {
    this.router.navigateByUrl('/login');
  }
}
