import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { WeeklyPlanFactory } from '../model/entities/weekly-plan/weekly-plan-factory';

/**
 * Simple check to see if the data is valid, as in not an empty object.
 *
 * @param weeklyPlanData
 * @returns {boolean}
 */
export function isValidWeeklyPlan(weeklyPlanData) {
  console.log(weeklyPlanData.hasOwnProperty('monday'));
  return weeklyPlanData.hasOwnProperty('monday');
}

/**
 * @param weeklyPlanData
 * @param {string} weekNumber
 * @param {MealPlan} mealPlan
 * @returns {WeeklyPlan}
 */
export function constructOrCreateWeeklyPlan(
  weeklyPlanData,
  weekNumber: string,
  mealPlan: MealPlan
) {
  return isValidWeeklyPlan(weeklyPlanData)
    ? WeeklyPlan.fromObject(weeklyPlanData)
    : WeeklyPlanFactory.createMasterWeeklyPlan(mealPlan, weekNumber);
}
