<div *ngIf="showHeader" class="row headers">
    <div class="col-3"></div>
    <div class="col text-center"><strong>Calories</strong></div>
    <div class="col text-center"><strong>Fat</strong></div>
    <div class="col text-center"><strong>Protein</strong></div>
    <div class="col text-center"><strong>Carbs</strong></div>
    <div class="col text-center"><strong>Sugar</strong></div>
    <div class="col text-center"><strong>Fiber</strong></div>
    <div class="col text-center"><strong>Sodium</strong></div>

    <!--
      nutrientName(type: string) {
    const nutrient = getNutrient(this.USDAFacts, type);
    return nutrientTypesMap.get(type).name.replace(/,.*/, '');
  }
    -->

    <ng-container *ngIf="additionalNutrients.length">
        <div *ngFor="let nutrient of additionalNutrients" class="col text-center"><strong>{{nutrientName(nutrient)}}</strong></div>
    </ng-container>
</div>


<div class="row" [class.showDailyPercentage]="showDailyPercentage" [class.separator]="usdaFacts.type == 'recipe'">
    <div class="col-3" [class.text-bold]="boldTitle">
        <a *ngIf="usdaFacts.type == 'recipe'" (click)="openRecipe()" class="text-primary">{{title | titlecase }}</a>
        <div *ngIf="usdaFacts.type != 'recipe'">{{title | titlecase }}</div>
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.ENERC_KCAL | nutrientValue:0:false}}
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.FAT | nutrientValue}}
        <small *ngIf="nutritionFacts.ENERC_KCAL" class="text-muted">
            ({{((nutritionFacts.FAT.value * 9) / nutritionFacts.ENERC_KCAL.value * 100).toFixed(0)}}%)
        </small>
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.PROCNT | nutrientValue}}
        <small *ngIf="nutritionFacts.ENERC_KCAL && nutritionFacts.ENERC_KCAL.value > 0" class="text-muted">
            ({{((nutritionFacts.PROCNT.value * 4) / nutritionFacts.ENERC_KCAL.value * 100).toFixed(0)}}%)
        </small>
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.CHOCDF | nutrientValue}}
        <small *ngIf="nutritionFacts.ENERC_KCAL" class="text-muted">
            ({{((nutritionFacts.CHOCDF.value * 4) / nutritionFacts.ENERC_KCAL.value * 100).toFixed(0)}}%)
        </small>
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.SUGAR | nutrientValue}}
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.FIBTG | nutrientValue}}
    </div>
    <div class="col text-center" [class.text-bold]="boldValues">
        {{nutritionFacts.NA | nutrientValue}}
    </div>

    <ng-container *ngIf="additionalNutrients.length">
        <div *ngFor="let nutrient of additionalNutrients"
             class="col text-center" [class.text-bold]="boldValues">
            {{nutritionFacts[nutrient] | nutrientValue}}
        </div>
    </ng-container>
</div>
