import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, of, Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { DistributorPlanConfig } from '../../lprx-shared-lib/entities/distributor-config';
import { Plans } from '../../lprx-shared-lib/entities/plans';
import { Pricing } from '../../lprx-shared-lib/entities/pricing';
import { LayoutService } from '../layout/layout.service';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { CdnService } from '../service/cdn.service';
import { HeaderService } from '../service/header.service';
import { SignupComponent } from '../signup/signup.component';
import { LandingPageComponentService } from './landing-page-component.service';
import { errorMessage } from '../utilities/alert-http-error';

type ButtonType = 'default' | 'promo' | 'requestAccess' | 'subscribe' | 'error';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  distributor: Distributor;
  mealPlan: MealPlan;
  logoUrl: string;
  photoUrl: string;
  mealPlanDescription;
  showSignUp = false;
  cobrandLogoUrl: string;

  signupStyle = 'subscribe'; // promo
  plans: Plans;
  distConfig: DistributorPlanConfig;

  buttonType: ButtonType = 'default';

  lowestPrice: Pricing;

  loading = true;
  error: string;

  constructor(
    private service: LandingPageComponentService,
    private cdn: CdnService,
    private header: HeaderService,
    public layout: LayoutService,
    private modal: NgbModal,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer, // private mealPlansService: MealPlansService
  ) {}

  ngOnInit() {
    this.header.hideBackButton();

    console.log('Hiding branding');
    this.header.showBranding(false);

    const routeParamsSub = this.route.params
      .pipe(
        take(1),
        tap((p) => console.log(p)),
        switchMap((params) =>
          forkJoin({
            distributor: this.service.getDistributor(params['distributorVanityId']),
            mealPlanId: of(params['mealPlanVanityId']),
          }),
        ),
        tap((v) => {
          this.distributor = v.distributor;

          this.layout.setBrandingFromDistributor(this.distributor);
          this.logoUrl = this.cdn.getUrl(this.distributor.logo);
          this.photoUrl = this.cdn.getSizedImageUrl(this.distributor.photo, 600, 0);
        }),
        switchMap((v) => {
          const vId: string = v.mealPlanId || '';
          return forkJoin({
            mealPlan: this.service
              .getMealPlan(v.distributor.id, vId)
              .pipe(tap((mealPlan) => (this.mealPlan = mealPlan))),
            plans: this.service.getPlans(v.distributor.id, vId),
            config: this.service.getDistConfig(v.distributor.id),
            queryParams: this.route.queryParams.pipe(take(1)),
          });
        }),
      )
      .subscribe(
        (v) => {
          console.log(v);

          if (!v.config.enableFreeTrial) {
            v.mealPlan.signUpButtonLabel = 'Subscribe Now';
          }

          this.mealPlan = v.mealPlan;

          this.plans = v.plans;

          if (this.plans.static) {
            this.lowestPrice = this.plans.static.monthly;
          } else if (this.plans.custom) {
            this.lowestPrice = this.plans.custom.monthly;
          }

          this.distConfig = v.config;

          this.mealPlanDescription = this.sanitizer.bypassSecurityTrustHtml(
            this.mealPlan.description,
          );

          if (this.mealPlan.cobrand) {
            this.cobrandLogoUrl = this.cdn.getUrl(this.mealPlan.cobrand.logo);
          }

          if (!v.config.enableSubscriptions) {
            this.buttonType = 'requestAccess';
          } else if (v.queryParams['promo']) {
            this.buttonType = 'promo';
          } else {
            this.buttonType = 'default';
          }

          this.loading = false;
        },
        (error) => {
          this.error = errorMessage(error);
          this.buttonType = 'error';
          this.loading = false;
          console.log(error);
        },
      );

    this.subs.push(routeParamsSub);

    // const queryParamsSub = this.route.queryParams.subscribe(params => {
    //   if (params['promo']) {
    //     this.buttonType = 'promo';
    //   }
    //
    //
    //
    // });
    //
    // this.subs.push(queryParamsSub);
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  signUp() {
    const modalRef = this.modal.open(SignupComponent, {
      windowClass: 'full-modal',
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.mealPlan = this.mealPlan;
    modalRef.componentInstance.distributor = this.distributor;
    modalRef.componentInstance.distributorConfig = this.distConfig;
    modalRef.componentInstance.plans = this.plans;

    // this.showSignUp = true;
    // window.scrollTo(0, 0);
  }

  closeSignUp() {}

  // private _init() {
  //   this.layout.setBrandingFromDistributor(this.distributor);
  //   this.logoUrl = this.cdn.getUrl(this.distributor.logo);
  //   this.photoUrl = this.cdn.getSizedImageUrl(this.distributor.photo, 600, 0);
  //
  //   if (this.mealPlan.cobrand) {
  //     this.cobrandLogoUrl = this.cdn.getUrl(this.mealPlan.cobrand.logo);
  //   }
  // }

  requestAccess() {
    this.service.requestAccess();
  }

  subscribe() {}
}
