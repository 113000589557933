import { Component, Input } from '@angular/core';
import { SourceType } from '../../../../../lprx-shared-lib/enum/source-type';
import { Source } from '../../../../../lprx-shared-lib/entities/recipe/Source';

/**
 * Generated class for the RecipeSourceInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-source-input',
  templateUrl: './recipe-source-input.html',
  styleUrls: ['./recipe-source-input.scss'],
})
export class RecipeSourceInputComponent {
  @Input() source: Source;

  constructor() {}

  selectedInspiredBy() {
    this.source.type = SourceType.Inspired;
  }

  selectedAdaptedFrom() {
    this.source.type = SourceType.Adapted;
  }

  selectedIsFrom() {
    this.source.type = SourceType.From;
  }
}
