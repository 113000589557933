/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-plan/meal-plan.migration.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Transform } from 'class-transformer';

@Exclude()
export class MealPlanMigration {
  @Expose()
  mealPlanId: string;

  @Expose()
  @Transform(value => {
    if (value instanceof String || typeof value === 'string') {
      return parseInt(value as string, 10);
    }
    return value;
  })
  weeks: number;
}
