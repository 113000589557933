import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sanitizeUrl'
})
export class SanitizeUrlPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return (value || '').match(/^http.*$/) ? value : 'http://' + value + '';
  }
}
