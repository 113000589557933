/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Step.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass } from 'class-transformer';

@Exclude()
export class Step {
  @Expose()
  direction: string;

  @Expose()
  image: string;

  static fromObject(object: any): Step {
    return plainToClass(Step, object);
  }
}
