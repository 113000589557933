/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/non-calendar-display.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export function convertNonCalWeekNumber(weekNumber: string) {
  return parseInt(weekNumber.split('.')[1], 10);
}

export function nonCalendarDisplay(weekNumber: string) {
  return 'Week ' + convertNonCalWeekNumber(weekNumber);
}
