import { Injectable } from '@angular/core';
import { from as observableFrom, Observable } from 'rxjs';
import { Stripe } from 'stripe';
import { CreateSubscriptionCommand } from '../../lprx-shared-lib/dto/create-subscription.command';
import { IDEMPOTENCY_KEY_PREFIX } from '../../lprx-shared-lib/utils/constants';
import { createId } from '../../lprx-shared-lib/utils/id-generator';
import { ApiV3 } from '../../providers/api.v3';
import { Api } from '../../providers/aws.api';
import { AuthService } from '../auth.service';

@Injectable()
export class UserService {
  constructor(
    private readonly api: Api,
    private readonly apiV3: ApiV3,
    private auth: AuthService
  ) {}

  async isEmailInUse(email: string) {
    // todo : this reveals too much info via the response
    return this.api.post(`user/e`, { email }); // , {queryStringParameters: {email: email}});
  }

  getBillingHistory() {
    return observableFrom(this.api.get(`user/${this.auth._getUser().username}/billing-history`));
  }

  // getSubscription() {
  //     return Observable.fromPromise(this.api.get(`user/${this.auth._getUser().username}/subscription`));
  // }

  /**
   *
   * @param tokenId
   * @param planCode
   * @param idempotencyKey
   */
  purchasePlan(
    tokenId: string,
    planCode: string,
    idempotencyKey: string = createId(IDEMPOTENCY_KEY_PREFIX),
    promoCode?: string
  ): Observable<Stripe.Subscription> {
    const createSubscriptionCommand = CreateSubscriptionCommand.create(
      tokenId,
      planCode,
      idempotencyKey,
      true,
      promoCode
    );
    return this.apiV3.post<Stripe.Subscription>('subscription', createSubscriptionCommand);
  }

  cancelSubscription() {
    return this.apiV3.delete('subscription');
  }

  getSubscription() {
    return this.apiV3.get('subscription');
  }

  // cancelSubscription() {
  //   return this.apiV3.delete('/subscription');
  // }
}
