/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/user/client-account-type.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum ClientAccountType {
  Subscription = 'Subscription',
  Bundle = 'Bundle',
  FreeTrial = 'Free Trial',
  Expired = 'Expired'
}
