<div class="logo-for-print">
  <img [src]="logo" alt="logo" />
</div>
<div
  id="sadasd"
  class="modal-body text-right"
  style="padding: 0 16px; border-bottom: 1px solid #dee2e6; background-color: #f3f3f3"
>
  <div class="row align-items-center icons d-print-none">
    <div class="col-auto ml-auto">
      <button *ngIf="enableRecipeInsert" (click)="addToPlanner()" class="btn btn-link ml-0">
        <i class="fa fa-fw fa-calendar"></i> Add to Planner
      </button>

      <button *ngIf="canLeftover()" (click)="copyToLeftOvers()" class="btn btn-link ml-0">
        <img src="/assets/img/icon/doggie-bag.svg" alt="Doggie bag" />
        Copy as Leftovers
      </button>

      <button (click)="copyAndEdit()" class="btn btn-link ml-0" [class.d-none]="!canCopy">
        <img src="/assets/img/icon/edit.svg" /> Copy & Edit
        <app-loading *ngIf="isCopying" [style]="'icon'"></app-loading>
      </button>

      <button *ngIf="canEdit" (click)="goEdit()" class="btn btn-link ml-0">
        <img src="/assets/img/icon/edit.svg" />&nbsp;Edit
      </button>

      <a
        *ngIf="canPrint && recipe.canView"
        href="javascript:"
        (click)="print()"
        class="btn btn-link ml-0 clickable"
      >
        <img src="/assets/img/icon/printer.svg" />&nbsp;Print
      </a>
    </div>

    <div class="col-auto">
      <mat-icon (click)="activeModal.dismiss('Cross click')" class="clickable">close</mat-icon>
    </div>
  </div>
</div>

<div class="modal-header">
  <div class="container-fluid p-0">
    <div class="row align-items-center">
      <div class="col">
        <h4 class="modal-title">
          <app-favorite-recipe-toggle [recipe]="recipe"></app-favorite-recipe-toggle>
          {{ recipe.title }}
        </h4>
      </div>
    </div>
  </div>
</div>

<div class="modal-body">
  <app-recipe-view
    [recipe]="recipe"
    [userServings]="servings"
    [card]="card"
    (servingsChanged)="updateServings($event)"
  >
  </app-recipe-view>
</div>

<div class="modal-footer">
  <div style="visibility: hidden" class="text-muted">{{ recipe.id }}</div>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">
    Close
  </button>
</div>
