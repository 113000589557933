/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/case.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum Case {
  Lower = 'lower',
  Upper = 'upper'
}
