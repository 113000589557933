import { CdkDrag } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { string } from 'mathjs';
import * as moment from 'moment';
// import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { from } from 'rxjs/observable/from';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DaysOfTheWeek } from '../../../lprx-shared-lib/days-of-the-week';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { Meal } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Meal';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { AdminService } from '../../../providers/admin/admin.service';
import { Api } from '../../../providers/aws.api';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { showNewDragAndDropDialogKey, ShowNewDragAndDropDialogSetting } from '../../../types';
import { AdminLayoutService } from '../../admin/service/admin-layout.service';
import { AuthService } from '../../auth.service';
import { DistributorService } from '../../distributor/distributor-service';
import { isTablet } from '../../is-tablet';
import { LayoutService } from '../../layout/layout.service';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { PdfGeneratorService } from '../../pdf-generator.service';
import { PdfDialogData } from '../../pdf-generator/pdf-dialog-data';
import { PdfGeneratorComponent } from '../../pdf-generator/pdf-generator.component';
import { ClientService } from '../../service/client/client.service';
import { HeaderService } from '../../service/header.service';
import { RecipeService } from '../../service/recipe.service';
import { parseCardId } from '../../utilities/card-data-extractor';
import { getCurrentWeekNumber } from '../../utilities/getCurrentWeekNumber';
import { DropService } from '../drop.service';
import { PlannerLayoutService } from '../planner-layout.service';
import { PlannerPrinterService } from '../planner-printer.service';
import { PlannerService } from '../planner.service';
import { RecipeOpenerService } from '../recipe-opener.service';
import { doShowNewDragDropDialog } from './new-drag-and-drop-dialog/do-not-show-new-drag-drop-dialog';
import { NewDragAndDropDialogComponent } from './new-drag-and-drop-dialog/new-drag-and-drop-dialog.component';
import { interval } from 'rxjs/internal/observable/interval';
import { convertNonCalWeekNumber } from '../../../lprx-shared-lib/non-calendar-display';

@Component({
  selector: 'app-planner-layout-wide',
  templateUrl: './planner-layout-wide.component.html',
  styleUrls: ['./planner-layout-wide.component.scss'],
})
export class PlannerLayoutWideComponent implements OnInit, OnDestroy, AfterViewInit {
  mealPlan: MealPlan;
  weekNumber: string;
  userType = 'user';
  dateModel: NgbDateStruct;
  date: { year: number; month: number };
  dragulaBag = 'bag-o-cards';
  cardLayout = 'layout-compressed';
  isLoading = true;
  canEdit = true;
  isDragging = false;
  showWeekPaging = true;
  list = [];
  daysOfTheWeek: string[] = new DaysOfTheWeek().getDays();
  showNutritionalInfo = true;
  weeklyPlans: WeeklyPlan[] = [];
  showPlanner = true;
  timeToSubscribe = false;

  here: string;

  private subs: Subscription[] = [];
  user: User;
  private doRedirect;
  client: User;
  private dragNDropInitialized: boolean = false;

  previewMode: boolean = false;
  private modalRef: NgbModalRef;

  nonCalweeks: number[];
  openedNewDragDropDialog: boolean = false;
  public _showLoadNextWeek: boolean;

  isTablet = isTablet();
  headerHeight: number = 0;

  constructor(
    private adminLayoutService: AdminLayoutService,
    private adminService: AdminService,
    private api: Api,
    private auth: AuthService,
    private clientService: ClientService,
    private distributorService: DistributorService,
    // private dragulaService: DragulaService,
    private dropService: DropService,
    private header: HeaderService,
    public layout: LayoutService,
    private modalService: NgbModal,
    private plannerLayout: PlannerLayoutService,
    private plannerPrinter: PlannerPrinterService,
    private plannerService: PlannerService,
    private recipeOpenerService: RecipeOpenerService,
    private recipesService: RecipeService,
    private route: ActivatedRoute,
    private router: Router,
    private pdfService: PdfGeneratorService,
    private dialog: MatDialog,
    private lprxApi: LprxApiProvider,
    @Inject(Window) private window: Window
  ) {}

  /**
   * Check if the user is an Administrator
   *
   * @returns {boolean}
   */
  isDistributor() {
    return this.user && this.user.userType === 'distributor';
  }

  /**
   * Check if the user is an Administrator
   *
   * @returns {boolean}
   */
  isAdmin() {
    return this.user
      ? new Set(this.user && [UserType.Admin, UserType.Distributor]).has(this.user.userType)
      : false;
  }

  /**
   * Finds a weekly plan by its weekNumber, from the array of loaded plans.
   *
   * @param {string} weekNumber
   * @returns {WeeklyPlan}
   */
  getWeeklyPlan(weekNumber: string) {
    for (const weeklyPlan of this.weeklyPlans) {
      if (weeklyPlan.weekNumber === weekNumber) {
        return weeklyPlan;
      }
    }
  }

  /**
   * Initialize the component
   */
  ngOnInit() {
    this.subs.push(
      this.layout.getHeaderHeight().subscribe((height) => {
        this.headerHeight = parseInt(height.replace('px', ''), 10);
      })
    );

    this.weeklyPlans = this.plannerService.weeklyPlans;

    this.nonCalweeks = Array.from(
      new Array(this.weeklyPlans[0].mealPlan.repeatInterval),
      (x, i) => i + 1
    );

    this.subs.push(
      interval(2000).subscribe(() => {
        this.showLoadNextWeek();
      })
    );

    this.subs.push(
      this.plannerService.plannerUpdated$.subscribe(() => {
        setTimeout(() => {
          this.setHeights();
        }, 250);
      })
    );

    this.adminLayoutService.setIsPlannerActive(true);
    this.plannerService.clearCopyPaste();
    sessionStorage.setItem('plannerPath', location.pathname);

    const setClientIfNecessary$ = this.route.params.pipe(
      tap((p) => {
        console.log('PLW: ', p);
      }),
      switchMap((params) => {
        if (params['master']) {
          // return of(null);

          return from(this.lprxApi.mealPlans.get(params['mealPlanId'])).pipe(
            tap((mp) => (this.mealPlan = mp)),
            map(() => null)
          );
        } else {
          return this.plannerService.client$.pipe(
            tap((client) => {
              this.client = client;
            })
          );
        }
      })
    );

    this.subs.push(
      this.route.data
        .pipe(
          take(1),
          tap((routeData) => {
            console.log('Route Data: ', routeData);
            // @ts-ignore preview does not exist on routeData
            const preview = routeData?.preview;
            if (preview) {
              this.previewMode = preview;
              this.plannerService.setReadOnly(true);
            } else {
              this.plannerService.setReadOnly(false);
            }
          }),
          switchMap(() => setClientIfNecessary$)
        )
        .subscribe(() => {
          this._initObservables();
        })
    );

    this.header.hideBackButton();

    this.subs.push(
      this.dropService.getIsCardDragging().subscribe((isDragging) => {
        this.isDragging = isDragging;
      })
    );

    // this.subs.push(
    //   interval(250).subscribe(() => {
    //     this.setHeights();
    //   })
    // );
  }

  private setHeights() {
    if (this.dropService.isDragging) {
      console.log('setHeights: isDragging');
      return;
    }

    console.log('setHeights: not dragging');

    Array.from(document.querySelectorAll('app-planner-meal'))
      .map((e) => e.attributes['data-week-meal'].value)
      .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []) // unique values
      .forEach((e) => {
        const elements = Array.from(document.querySelectorAll(`[data-week-meal="${e}"] .cards`));
        let maxHeight = 0;

        elements.forEach((el) => {
          // @ts-ignore
          el.style.minHeight = '40px';
          // @ts-ignore
          const offsetHeight = el.offsetHeight;
          if (offsetHeight > maxHeight) {
            maxHeight = offsetHeight;
          }
        });

        elements.forEach((el) => {
          // @ts-ignore
          const style = el.style;
          style.minHeight = `${maxHeight}px`;
          style.display = 'block';
        });
      });
  }

  /**
   * Initialize various Observables
   *
   * @private
   */
  private _initObservables() {
    this.subs.push(
      this.queryParamsSub(),
      this.updatedServingsSub(),
      this.showNutritionalInfoSub(),
      this.authSub(),
      this.weeklyPlansSub()
    );
  }

  private weeklyPlansSub() {
    return this.plannerService.weeklyPlans$
      .pipe(filter((weeklyPlans) => weeklyPlans && weeklyPlans.length > 0))
      .subscribe((weeklyPlans) => {
        this.weeklyPlans = weeklyPlans;
        this.weekNumber = weeklyPlans[0].weekNumber;
        setTimeout(() => {
          this.setHeights();
        }, 1500);
      });
  }

  private authSub() {
    return this.auth.user$.subscribe((user) => {
      this.user = user;
      this.loadPlanner();
    });
  }

  /**
   * Listen for when servings have been updated for an recipe that is opened
   * @private
   */
  private updatedServingsSub() {
    return this.plannerService.getUpdatedServings$.subscribe((servings) =>
      this.updateServings(servings)
    );
  }

  private showNutritionalInfoSub() {
    return this.plannerService.plannerConfig$.subscribe(
      (config) => (this.showNutritionalInfo = config.showNutritionFacts)
    );
  }

  private queryParamsSub() {
    return this.route.queryParams.subscribe((params) => {
      if (params['do']) {
        this.doRedirect = params['do'];
      }
    });
  }

  /**
   * Executed by observable
   *
   * @param {number} servings
   */
  private updateServings(servings: number) {
    const openedCardId = this.plannerService.getOpenedCardId();
    console.log(`updating servings for ${openedCardId}`);
    const { weekNumber, dayName, mealId, recipeId } = parseCardId(openedCardId);
    const weeklyPlan = this.getWeeklyPlan(weekNumber);
    const card = weeklyPlan.getDay(dayName).getMeal(mealId).getRecipeCard(recipeId);
    card.setUserServings(servings);
    this.plannerService.weeklyPlanUpdated(weekNumber, dayName, mealId, card);
  }

  /**
   * HULK SMASH!
   */
  ngOnDestroy() {
    unsubscribe(this.subs);
    this.adminLayoutService.setIsPlannerActive(false);
    this.plannerService.setPlannerOpenState(false);

    try {
      // Throws an error if it was previously destroyed when preventing the user from dragging
      // this.dragulaService.destroy(this.dragulaBag);
    } catch (e) {
      console.log(e);
    }
  }

  // /**
  //  * Save a weekly plan
  //  *
  //  * @todo The method implementation should be handled by the planner service
  //  *
  //  * @param {WeeklyPlan} weeklyPlan
  //  */
  // saveWeeklyPlan(weeklyPlan: WeeklyPlan) {
  //   const wp = stripRecipeDataFromWeeklyMealPlan<WeeklyPlan>(weeklyPlan);
  //
  //   let save$: Observable<any>;
  //
  //   switch (this.user.userType) {
  //     case UserType.Admin:
  //       save$ = this.adminService.planner.save(wp);
  //       break;
  //     case UserType.Distributor:
  //       save$ = this.client
  //         ? this.distributorService.clientPlanner.save(wp)
  //         : this.distributorService.saveMasterPlan(wp);
  //       this.plannerService.setWeeklyPlans(this.weeklyPlans);
  //       break;
  //     default:
  //       save$ = this.plannerService.saveWeeklyPlan(weeklyPlan.weekNumber);
  //       break;
  //   }
  //
  //   save$.subscribe(() => {
  //     this.plannerService.broadcastWeeklyPlanUpdate();
  //   });
  // }

  /**
   * @param layout
   */
  setLayout(layout: string) {
    this.cardLayout = layout;
    this.plannerLayout.changeLayout(layout);
  }

  print() {
    window.scrollTo(0, 0);
    this.showPlanner = false;
    this.plannerPrinter.printWeek(this.weeklyPlans);
  }

  /**
   * Load a previous week into the planner
   *
   * @returns {Promise<void>}
   */
  loadPreviousWeek() {
    this.showWeekPaging = false;
    switch (this.user.userType) {
      case UserType.Admin:
        this.plannerService.adminLoadPreviousWeek().subscribe(
          () => {
            this.showWeekPaging = true;
          },
          (err) => {
            this.layout.toastr.error(err.message, 'Unable to load the previous week.');
            this.showWeekPaging = true;
          }
        );
        break;
      case UserType.Client:
        this.plannerService.clientLoadPreviousWeek().subscribe(() => {});
        break;
      case UserType.Distributor:
        this.plannerService.distLoadPreviousWeek().subscribe(() => {
          this.showWeekPaging = true;
        });
        break;
    }
  }

  /**
   * Load the next week into the planner
   *
   * @returns {Promise<boolean>}
   */
  loadAdditionalWeek() {
    this.showWeekPaging = false;

    let weeklyPlans$: Observable<WeeklyPlan[]>;
    if (this.user.userType === UserType.Client) {
      weeklyPlans$ = this.plannerService.clientLoadAdditionalWeek();
    } else if (this.client) {
      weeklyPlans$ = this.plannerService.distLoadAdditionalWeek();
    } else {
      weeklyPlans$ = this.plannerService.adminLoadAdditionalWeek();
    }

    weeklyPlans$.subscribe(
      () => (this.showWeekPaging = true),
      (err) => {
        this.layout.toastr.error(err.message, 'Unable to load the next week.');
        this.showWeekPaging = true;
      }
    );
  }

  /**
   * @note
   * @param {WeeklyPlan} pastedWeeklyPlan
   */
  copied(pastedWeeklyPlan: WeeklyPlan) {
    const index = this.weeklyPlans.findIndex((e) => e.weekNumber === pastedWeeklyPlan.weekNumber);
    this.weeklyPlans[index] = pastedWeeklyPlan;
  }

  /**
   * @param event
   */
  calendarSelect(event) {
    console.log(event);
    if (this.dateModel) {
      const week = moment()
        .year(this.dateModel.year)
        .month(this.dateModel.month - 1)
        .date(this.dateModel.day)
        .isoWeek();

      /**
       * todo: create a project-wide utility function to create the proper weekNumber from a year
       *      and replace all the (year + week/100).toFixed(2) that is everywhere
       *      (must of been smoking crack...j/k)
       */
      const paddedWeek = ('0' + week.toString()).slice(-2); // 1 -> 01
      const weekNumber = this.dateModel.year + '.' + paddedWeek;

      this.plannerService.adminLoadPanner(
        this.plannerService.weeklyPlans[0].mealPlan.id,
        weekNumber
      );
    }
  }

  /**
   * @returns {boolean}
   */
  private _canEdit(): boolean {
    if (!this.user) {
      return false;
    }

    if (this.previewMode) {
      return false;
    }

    return (
      this.user.userType === UserType.Admin ||
      (this.user.userType === UserType.Client &&
        this.user.plannerType === 'c' &&
        this.user.terminatesAt > Date.now()) ||
      (this.user.userType === UserType.Distributor &&
        this.client &&
        this.client.plannerType === 'c') ||
      (this.user.userType === UserType.Distributor && !this.client)
    );
  }

  /**
   *
   * @returns {boolean}
   */
  private canDragNDrop(): boolean {
    return this._canEdit();
  }

  /**
   * Initialize the Drag-n-Drop of the planner
   */
  private initDragNDrop() {
    if (this.previewMode) {
      console.log('Preview do not enable drag-n-drop');
      return;
    }

    if (this.dragNDropInitialized) {
      return;
    }

    console.log('Init Drag');

    this.dragNDropInitialized = true;

    try {
      // this.dragulaService.createGroup(this.dragulaBag, {
      //   copy: (el: Element, source: Element) => {
      //     return true;
      //   },
      //   copyItem: (item: any) => {
      //     return classToClass (item);
      //   },
      //   moves: (el, container, handle) => {
      //     if (this.canDragNDrop()) {
      //       console.log('This user can drag');
      //       return true;
      //     } else {
      //       console.log('Preventing Drag');
      //       this.dragulaService.destroy('bag-o-cards');
      //       return false;
      //     }
      //   },
      // });
    } catch (e) {
      // options already set, most likely
      console.log(e);
    }

    // Subscribe to the dragula to know when a recipe, or card, is being dragged.
    // const dragSub = this.dragulaService.drag('bag-o-cards').subscribe(({ name, el, source }) => {
    //   // if (value[0] === 'bag-o-cards') {
    //   this.isDragging = true;
    //   console.log('started dragging recipe');
    //   console.log(el);
    //   this.dropService.startDraggingCard();
    //   // }
    // });
    // this.subs.push(dragSub);

    // const dropSub = this.dragulaService.drop('bag-o-cards').subscribe((value) => {
    //   this.onDrop(value);
    // });
    // this.subs.push(dropSub);
  }

  /**
   * Loads the weekly planner by either retrieving
   */
  private async loadPlanner() {
    console.log('Loading Planner');
    console.log(this.route);

    this.weekNumber = getCurrentWeekNumber();

    if (!this.user) {
      return;
    }

    let showNewDragAndDrop = true;

    try {
      const dialogSetting = await this.lprxApi.getVar<ShowNewDragAndDropDialogSetting>(
        showNewDragAndDropDialogKey
      );
      showNewDragAndDrop = dialogSetting.enabled;
    } catch (e) {
      showNewDragAndDrop = doShowNewDragDropDialog();
      this.lprxApi
        .saveVar<ShowNewDragAndDropDialogSetting>(showNewDragAndDropDialogKey, {
          enabled: showNewDragAndDrop,
        })
        .then(() => {
          console.log('Saved showNewDragAndDropDialogKey');
        });
    }

    if (showNewDragAndDrop && this.canEdit) {
      // prevent opening the dialog again

      if (!this.openedNewDragDropDialog) {
        this.dialog.open(NewDragAndDropDialogComponent);
        this.openedNewDragDropDialog = true;
      }
    }

    switch (this.user.userType) {
      case 'user':
        await this.initUser();
        break;
      case 'distributor':
        const initDistributorSub = this.route.params.subscribe((params) => {
          this.initDistributor(params)
            .then()
            .catch((e) => console.log(e));
        });
        this.subs.push(initDistributorSub);
        break;
      default:
        this.initDragNDrop();
        this.showWeekPaging = true;
        break;
    }
  }

  /**
   * Initialize the planner for a distributor
   *
   * @param params
   * @param weekNumber
   * @returns {Promise<void>}
   */
  private async initDistributor(params) {
    if (params['username']) {
      this.client = await this.distributorService.getClient(params['username']);
      if (this.client.plannerType === 'c') {
        this.initDragNDrop();
      }
    } else {
      this.initDragNDrop();
    }

    this.canEdit = true;
    this.showWeekPaging = true;

    this.layout.setBrandingFromDistributorId(this.user.distributorId);

    this.subs.push(this.getIsInitialized());
  }

  private getIsInitialized() {
    return this.distributorService.getIsInitialized().subscribe((isInitialized) => {
      if (!isInitialized) {
        this.distributorService.init();
      }
    });
  }

  /**
   * Initialize the planner for a user
   *
   * @returns {Promise<void>}
   */
  private initUser() {
    this.layout.setBrandingFromDistributorId(this.user.distributorId);
    this.timeToSubscribe =
      !this.user.hasOwnProperty('terminatesAt') || this.user.terminatesAt < Date.now();

    this.canEdit = this._canEdit();

    this.plannerService.setCanEdit(this.canEdit);
    this.initDragNDrop();
    this.isLoading = false;
  }

  /**
   * Handler for Dragula drop events
   *
   * @param args
   */
  private onDrop(args: {
    name: string;
    el: Element;
    target: Element;
    source: Element;
    sibling: Element;
  }) {
    this.isDragging = false;
    this.dropService.stopDraggingCard();
    // const [bag, droppedElement, droppedOnElement] = args;
    const bag = args.name;
    const droppedElement = args.el;
    const droppedOnElement = args.target;
    if (bag === 'bag-o-cards') {
      try {
        // noinspection JSIgnoredPromiseFromCall
        this.doDropOnBagOCards(droppedElement, droppedOnElement);
      } catch (err) {
        console.log(err);
      }
    }
  }

  /**
   * If a card is dropped on a bag-o-cards, figure out what card it is and on what meal
   * it should be added to. Then, add the Recipe(Card) to that Meal.
   */
  private async doDropOnBagOCards(droppedElement: any, droppedOnElement: any) {
    if (!droppedElement) {
      console.log('Nothing dropped? Odd! What would trigger the drop?');
      return;
    }

    if (!droppedOnElement) {
      console.log('Nothing dropped on? Odd! How can you not drop it somewhere?');
      return;
    }

    const [droppedOnWeekNumber, droppedOnDayName, droppedOnMealId] = droppedOnElement.id.split('-');

    const droppedOnWeeklyPlan = this.getWeeklyPlan(droppedOnWeekNumber);
    const droppedOnMeal = droppedOnWeeklyPlan.getDay(droppedOnDayName).getMeal(droppedOnMealId);

    // Dropped a Card?
    // card-{weekNumber}-{day}-{mealId}-r-{recipeId}
    const cardData = droppedElement.id.match(/^card-([^-]*)-([^-]*)-([^-]*)-(.)-(.*)$/);

    // const isDroppedRecipeCard = cardType === CardType.Recipe;
    if (cardData) {
      let m: string;
      let draggedFromWeekNumber: string;
      let draggedFromDayName: string;
      let draggedFromMealId: string;
      let cardType: string;
      let draggedFromId: string;

      // extract the card's data from the match above
      [m, draggedFromWeekNumber, draggedFromDayName, draggedFromMealId, cardType, draggedFromId] =
        cardData;

      const draggedFromWeeklyPlan = this.getWeeklyPlan(draggedFromWeekNumber);
      const draggedFromMeal = draggedFromWeeklyPlan
        .getDay(draggedFromDayName)
        .getMeal(draggedFromMealId);

      if (draggedFromMeal === droppedOnMeal) {
        console.log('Dropped on the same place.');
        return;
      }

      const card = draggedFromMeal.getCard(draggedFromId);

      if (card) {
        droppedOnMeal.addCard(card);

        draggedFromMeal.removeCard(card);
        draggedFromMeal.removeRecipe(draggedFromId);

        console.log('Saving droppedOnWeeklyPlan');
        // this.saveWeeklyPlan(droppedOnWeeklyPlan);

        this.plannerService.weeklyPlanUpdated(
          droppedOnWeekNumber,
          droppedOnDayName,
          droppedOnMeal.name,
          card
        );

        /*
         If dragged from one week to another, save the other week as well.
         */
        if (droppedOnWeeklyPlan.weekNumber !== draggedFromWeeklyPlan.weekNumber) {
          console.log('Saving draggedFromWeeklyPlan');
          this.plannerService.weeklyPlanUpdated(
            draggedFromWeekNumber,
            draggedFromDayName,
            draggedFromMeal.name,
            card
          );
        }
      }

      return;
    }

    // Dropped a search result?
    const isDroppedRecipeResult = droppedElement.id.match(/^recipe-.*$/) != null;

    if (isDroppedRecipeResult) {
      const recipeId = droppedElement.id.replace(/recipe-/, '');
      console.log(`Dropped Recipe(${recipeId}) from search results`);

      this.recipesService.getRecipe(recipeId).subscribe((recipe) => {
        droppedOnMeal.addRecipe(Recipe.fromObject(recipe));
        this.plannerService.weeklyPlanUpdated(
          droppedOnWeekNumber,
          droppedOnDayName,
          droppedOnMeal.name
        );
      });
    }
  }

  pdf() {
    this.dialog.open<PdfGeneratorComponent, PdfDialogData>(PdfGeneratorComponent, {
      minWidth: '300px',
      data: {
        client: this.client,
        weeklyPlans: this.weeklyPlans,
        mealPlanId: this.weeklyPlans[0].mealPlanId,
      },
    });
  }

  showLoadNextWeek() {
    let _showLoadNextWeek = false;

    const mealPlan = this.weeklyPlans[0].mealPlan;
    if (mealPlan.nonCalendar) {
      const nonCalWeekNumber = convertNonCalWeekNumber(
        this.weeklyPlans[this.weeklyPlans.length - 1].weekNumber
      );
      _showLoadNextWeek = nonCalWeekNumber < mealPlan.repeatInterval;
    } else {
      _showLoadNextWeek = this.showWeekPaging && (this.isAdmin() || this.isDistributor());
    }

    if (this._showLoadNextWeek !== _showLoadNextWeek) {
      this._showLoadNextWeek = _showLoadNextWeek;
    }
  }

  changeWeek(i: number) {
    const weekNumber = '1970.' + i.toString().padStart(2, '0');

    if (this.user.userType === UserType.Client) {
      this.plannerService.clientSkipToWeek(weekNumber);
    } else if (this.user.userType === UserType.Distributor) {
      this.plannerService.distSkipToWeek(weekNumber);
    }
  }

  ngAfterViewInit(): void {}

  removeRecipe($event: {
    isPointerOverContainer: boolean;
    item: CdkDrag<{ card: Card; meal: Meal; weekNumber: string; dayName: string }>;
  }) {
    console.log({ $event });

    const data = $event.item.data;

    console.log({ data });

    if ($event.isPointerOverContainer) {
      this.plannerService
        .getWeeklyPlan(data.weekNumber)
        .getDay(data.dayName)
        .getMeal(data.meal.name)
        .removeCard(data.card);

      this.plannerService.weeklyPlanUpdated(
        data.weekNumber,
        data.dayName,
        data.meal.name,
        data.card
      );
    }
  }
}
