import { Brand } from './app/model/entities/brand';

export type OnFileUploadFulfilled = (result: { key: string }) => void;

export const defaultBrand = new Brand('Living Plate Rx', '/assets/img/lprx-logo.png', '');

export interface ShowNewDragAndDropDialogSetting {
  enabled: boolean;
}

export const showNewDragAndDropDialogKey = 'show-new-drag-dialog';
