import { Injectable } from '@angular/core';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';

@Injectable()
export class RecipeCacheService {
  private recipes = new Map<string, Recipe>();

  has(recipeId: string) {
    return this.recipes.has(recipeId);
  }

  get(recipeId: string) {
    return this.recipes.get(recipeId);
  }

  cache(recipe: Recipe) {
    this.recipes.set(recipe.id, recipe);
  }
}
