import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  @Input()
  style: 'default' | 'icon' | 'icon-2x' | 'sm' | 'xs' | 'xs-term' = 'default';

  @Input()
  term: string = 'Loading';

  constructor() {}

  ngOnInit() {}
}
