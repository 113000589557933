import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { classToClass } from 'class-transformer';

import Fraction from 'fraction.js';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Nutrients } from '../../../../../lprx-shared-lib/entities/recipe/Nutrients';
import { LayoutService } from '../../../../layout/layout.service';
import { NutrientService } from '../../../../service/nutrient.service';
import { RecipeEditorService } from '../../recipe-editor.service';
import { NewIngredient } from './new-ingredient';
import { Ingredient } from '../../../../../lprx-shared-lib/entities/recipe/Ingredient';
import { GroceryCategories } from '../../../../../lprx-shared-lib/GroceryCategories';
import {
  roundFractionToRecipeMeasurement
} from '../../../../../lprx-shared-lib/utils/round-fraction-to-recipe-measurement';
import { convertUnits } from '../../../../../lprx-shared-lib/convert-units';

/**
 * Generated class for the RecipeIngredientInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-ingredient-input',
  templateUrl: 'recipe-ingredient-input.html',
  styleUrls: ['./recipe-ingredient-input.scss'],
})
export class RecipeIngredientInputComponent implements OnInit {
  @Input() ingredient: Ingredient;

  fractionalQuantity: string;

  ndbTerm: string;

  showNutrients = false;
  showSearch = true;

  ndbMatch = {
    ndbNo: '',
    amount: 0,
    measurement: '',
    description: '',
  };

  groceryCategories = [];
  showNewIngredientForm = false;

  ndbFraction: string; // replacing 'toFraction(ingredient.ndb.amount)' that was in template

  @ViewChild('instance') instance: NgbTypeahead;

  units: string[];
  newIngredient: NewIngredient;
  isProcessingNewIngredient = false;

  nutrients = {};

  matches: any[] = [];
  weights: any[] = [];

  constructor(
    private nutrientService: NutrientService,
    private recipeEditorService: RecipeEditorService,
    public layout: LayoutService,
  ) {}

  convert() {
    this.ingredient.quantity = this.toDecimal(this.fractionalQuantity);
    this.ingredient.ndb.amount = this.ingredient.quantity;
    this.calculateNutrients();
    console.log(this.ingredient.quantity);
  }

  ngOnInit(): void {
    if (!this.ingredient) {
      this.ingredient = new Ingredient();
    }

    this.fractionalQuantity = this.toFraction(this.ingredient.quantity);
    this.groceryCategories = GroceryCategories.topLevelCategories();

    // a little clean up
    if (this.ingredient['ndbNo']) {
      delete this.ingredient['ndbNo'];
    }

    if (this.ingredient['ndbWeight']) {
      delete this.ingredient['ndbWeight'];
    }

    if (this.ingredient && this.ingredient.ndb) {
      if (this.ingredient.ndb.ndbNo) {
        this.showSearch = false;
      }

      if (this.ingredient.ndb.description) {
        this.ndbTerm = this.ingredient.ndb.description;
      }

      // todo: do this on the server instead
      if (!this.ingredient.ndb.measurement && this.ingredient.ndb.weight) {
        this.ingredient.ndb.measurement = this.ingredient.ndb.weight;
        delete this.ingredient.ndb.weight;
      }
    }

    this.findIngredient(false);
  }

  private toDecimal(fractionalQuantity: string) {
    const reducer = (accumulator: number, currentValue: string): number => {
      const cv: number = currentValue
        .split('/')
        .map((a) => parseFloat(a))
        .reduce((a: number, c: number): number => {
          console.log('Divide: ' + a + ' / ' + c + ' = ' + a / c);
          return a / c;
        });

      console.log('Adding: ' + accumulator + ' + ' + currentValue + ' = ' + (accumulator + cv));
      return accumulator + cv;
    };

    return fractionalQuantity ? fractionalQuantity.split(' ').reduce(reducer, 0) : 0;
  }

  /**
   * Converts a decimal quantity to a fractional number
   *
   * @param decimalQuantity
   */
  public toFraction(decimalQuantity: number) {
    const integerPart = Math.floor(decimalQuantity);
    const fractionalPart = decimalQuantity - integerPart;
    let output = '';

    if (integerPart > 0) {
      output = integerPart.toString();
    }

    if (fractionalPart > 0) {
      let fraction = new Fraction(fractionalPart).toFraction(true);
      fraction = roundFractionToRecipeMeasurement(fraction);
      output += ' ' + fraction;
    }

    return output.trim();
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : convertUnits()
              .list()
              .filter((u) => u.measure === 'mass' || u.measure === 'volume')
              .map((u) => (this.ingredient.quantity <= 1 ? u.singular : u.plural))
              .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
              .slice(0, 10)
              .map((v) => v.toLowerCase())
      )
    );

  calculateNutrients() {
    this.ndbFraction = this.toFraction(this.ingredient.ndb.amount);

    // get the selected match
    const match = this.getMatch();
    this.ingredient.nutrients = new Nutrients();
    for (const weight of match.weights) {
      if (weight.measurement === this.ingredient.ndb.measurement) {
        console.log('------------------------');
        for (const nutrientProperty of Object.getOwnPropertyNames(match.nutrients)) {
          const nutrientValue = match.nutrients[nutrientProperty].value;
          const calculatedGrams = weight.grams * (this.ingredient.ndb.amount / weight.amount);

          let adjustedValue = (calculatedGrams / 100) * nutrientValue;
          adjustedValue = Math.round(adjustedValue * 100) / 100;

          this.ingredient.nutrients[nutrientProperty] = {
            value: adjustedValue,
            unit: match.nutrients[nutrientProperty].unit,
          };
        }
        this.recipeEditorService.updatedIngredient();
        return;
      }
    }
  }

  private getMatch() {
    for (const m of this.matches) {
      const ingredientNdbNo = this.ingredient.ndb.ndbNo;
      // nutritionix returns an int we convert to string, but USDA returns a string usually zero padded
      // so we'll compare the integer value of the string
      // ingredientNdbNo that start with x are custom ingredients, no need to match ints
      const isNotACustomIngredient = !ingredientNdbNo.match(/^x/);
      if (
        (isNotACustomIngredient && parseInt(ingredientNdbNo, 10) === parseInt(m.ndbNo, 10)) ||
        ingredientNdbNo === m.ndbNo
      ) {
        return m;
      }
    }
  }

  onChangeMatch() {
    const match = this.getMatch();
    this.weights = match.weights;
    this.ingredient.ndb.measurement = this.weights[0].measurement;
    this.ingredient.ndb.description = match.longDescription;
    this.calculateNutrients();
  }

  findIngredient(override: boolean = false) {
    const ingredient = classToClass(this.ingredient);

    if (this.ndbTerm && this.ndbTerm.trim() !== '') {
      ingredient.item = this.ndbTerm;
    }

    if (!ingredient.item || ingredient.item.trim() === '') {
      return;
    }

    this.nutrientService.matchIngredient(ingredient, ingredient.ndb.ndbNo).subscribe((results) => {
      // set the matches dropdown option
      this.matches = results.matches;

      if (this.ingredient.ndb && !override) {
        const match = this.getMatch();
        this.weights = match.weights;
        return;
      }

      // set the weights dropdown option to the first match
      this.weights = this.matches[0].weights;

      this.ingredient.ndb.ndbNo = this.matches[0].ndbNo;
      this.ingredient.ndb.description = this.matches[0].longDescription;
      this.ingredient.ndb.measurement = this.matches[0].weights[0].measurement;
      this.ingredient.ndb.amount = 1;

      if (results.best) {
        this.ingredient.ndb.ndbNo = results.best.ingredient.ndbNo;
        this.ingredient.ndb.description = results.best.ingredient.longDescription;
        this.ingredient.ndb.amount = 1;

        if (results.best.weight) {
          this.ingredient.ndb.measurement = results.best.weight.measurement;
          this.ingredient.ndb.amount = this.ingredient.quantity;
        }
      }

      this.calculateNutrients();
    });
  }

  addNewIngredient() {
    this.showNewIngredientForm = true;
    this.isProcessingNewIngredient = false;
    this.newIngredient = new NewIngredient();
  }

  createNewIngredient() {
    this.isProcessingNewIngredient = true;
    this.nutrientService.createNewIngredient(this.newIngredient).subscribe(
      (ingredient) => {
        this.enableForm();
        console.log(ingredient);
        this.showSearch = true;
        this.showNewIngredientForm = false;
        this.ndbTerm = this.newIngredient.name;
        this.findIngredient(true);
      },
      (error) => {
        this.enableForm();
      },
    );
  }

  private enableForm() {
    this.isProcessingNewIngredient = false;
  }

  closeNewIngredientForm() {
    this.showNewIngredientForm = false;
  }

  fillInUSDASearch($event: KeyboardEvent) {
    if (!this.ndbTerm || this.ndbTerm === '' || this.ingredient.item.match(this.ndbTerm)) {
      this.ndbTerm = this.ingredient.item;
    }
  }
}
