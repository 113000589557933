import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable, from as fromPromise, of } from 'rxjs';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';
import { DistributorsService } from '../../service/distributors.service';
import { PremiumAlertModalService } from '../resources/distributor-folder-list-item/premium-alert-modal.service';

@Injectable()
export class ActiveDistributorGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly distributorsService: DistributorsService,
    private readonly premiumAlertModalService: PremiumAlertModalService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.user$.pipe(
      switchMap((user) => {
        /**
         * If the current user is not a Distributor, then we'll let other guards do their thing.
         */
        if (user.userType !== UserType.Distributor) {
          return of(true);
        }
        return this.distributorsService.currentDistributor$.pipe(
          switchMap((distributor: Distributor) => {
            if (distributor && distributor.isActive()) {
              return of(true);
            } else {
              const html =
                'You cannot access the <strong>Recipe editor and recipe box</strong> ' +
                ' are only available to Living Plate Rx subscribers.';
              return fromPromise(this.premiumAlertModalService.show(html)).pipe(
                switchMap(() => of(false))
              );
            }
          })
        );
      })
    );
  }
}
