import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { Api } from '../../../providers/aws.api';

@Injectable({
  providedIn: 'root',
})
export class RecipeViewerService {
  constructor(private api: Api) {}

  getRecipe(recipeId: string): Observable<Recipe> {
    return fromPromise(this.api.get(`recipe/${recipeId}`)).pipe(
      map((recipeData) => plainToClass(Recipe, recipeData))
    );
  }
}
