import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
// tslint:disable-next-line:import-blacklist
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { defaultBrand } from '../../types';
import { LayoutService } from '../layout/layout.service';
import { Brand } from '../model/entities/brand';
import { BackButtonConfig } from './BackButtonConfig';

@Injectable()
export class HeaderService {
  private headerVisibility = new Subject<boolean>();
  private brandSubject = new Subject<Brand>();
  private showBrandingSubject = new Subject<boolean>();

  private backButton = new BehaviorSubject<BackButtonConfig>(new BackButtonConfig());
  backButton$ = this.backButton.asObservable();

  constructor(public layout: LayoutService) {
    this.layout.getBrand().subscribe(brand => {
      this.setBrand(brand);
    });
  }

  setBrand(brand: Brand) {
    this.brandSubject.next(brand);
  }

  getBrand(): Observable<Brand> {
    return this.brandSubject.asObservable();
  }

  getShowBranding(): Observable<boolean> {
    return this.showBrandingSubject.asObservable();
  }

  showBranding(showBranding: boolean) {
    this.showBrandingSubject.next(showBranding);
  }

  show() {
    this.headerVisibility.next(true);
  }

  hide() {
    this.headerVisibility.next(false);
  }

  getVisibility(): Observable<boolean> {
    return this.headerVisibility.asObservable();
  }

  /**
   *
   * @param url
   * @param extras
   */
  showBackButton(url?: string | UrlTree, extras?: NavigationExtras) {
    this.backButton.next(new BackButtonConfig(url, extras));
  }

  /**
   *
   */
  hideBackButton() {
    this.backButton.next(null);
  }
}
