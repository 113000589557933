import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DEFAULT_LIST_LIMIT } from '../../lprx-shared-lib/utils/constants';
import { ListResult } from '../../lprx-shared-lib/utils/list-result';
import { ApiV3 } from '../../providers/api.v3';
import { Api } from '../../providers/aws.api';
import { DynamoDB } from '../../providers/providers';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';
import { RecipeCacheService } from '../service/recipe-cache.service';
import { SearchState } from './search.state';

interface RecipeSearchResults {
  hits: {
    hits: Array<{
      _source: Recipe;
    }>;
  };
}

@Injectable()
export class RecipeSearchService {
  private recipeResults = new BehaviorSubject<Recipe[]>([]);

  searchState: SearchState = new SearchState();

  constructor(
    private api: Api,
    private apiV3: ApiV3,
    private db: DynamoDB,
    private recipeCache: RecipeCacheService
  ) {}

  /**
   * @param term
   * @param mealPlan
   */
  search(term: string, mealPlan: string) {
    const recipeResults: Recipe[] = [];
    this.api.post<RecipeSearchResults>('recipe/search', { term, mealPlan }).then((res) => {
      const hasResults = res.hits && res.hits.hits.length > 0;
      if (hasResults) {
        res.hits.hits.forEach((hit) => {
          const recipe = Recipe.fromObject(hit._source);
          recipeResults.push(recipe);
          this.recipeCache.cache(recipe);
        });
        this.recipeResults.next(recipeResults);
      }
    });
  }

  /**
   *
   */
  getSearchResults(): Observable<Recipe[]> {
    return this.recipeResults.asObservable();
  }

  /**
   *
   * @param limit
   * @param nextKey
   */
  getAdminRecipes(
    limit: number = DEFAULT_LIST_LIMIT,
    nextKey?: string
  ): Observable<ListResult<Recipe>> {
    const queryParams = nextKey ? { limit, next_key: nextKey } : { limit };
    return this.apiV3.get<ListResult<Recipe>>('admin/recipes/list', queryParams);
  }

  // async getAll() {
  //
  //   let recipeResults = [];
  //   let data: any;
  //
  //   const params = {
  //     TableName: 'livingplaterx-mobilehub-1710364101-recipes',
  //     Select: 'SPECIFIC_ATTRIBUTES',
  //     AttributesToGet: [
  //       'id', 'title', 'totalTime', 'prepTime', 'forMealPlans', 'status',
  //       'createdAt', 'modifiedAt', '_ingredients', 'mealTypes', 'isUsdaComplete'
  //     ]
  //   };
  //
  //   try {
  //     const client = await this.db.getDocumentClient();
  //     do {
  //       data = await client.scan(params).promise();
  //       console.log(data);
  //       recipeResults = recipeResults.concat(<Recipe[]>data.Items);
  //       if (data.LastEvaluatedKey) {
  //         params['ExclusiveStartKey'] = data.LastEvaluatedKey;
  //       }
  //     } while (data.LastEvaluatedKey);
  //   } catch (err) {
  //     console.log('error in refresh tasks', err);
  //   }
  //
  //   recipeResults = recipeResults.filter((recipe) => recipe.status !== 'deleted');
  //
  //   console.log(recipeResults);
  //   this.recipeResults.next(recipeResults);
  // }

  clear() {
    this.searchState = new SearchState();
  }
}
