/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/human-week.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import * as moment from 'moment';
import { nonCalendarDisplay } from './non-calendar-display';

export function humanWeek(weekNumber: string, showRange: boolean = false) {
  if (weekNumber < '2000.00') {
    return nonCalendarDisplay(weekNumber);
  }

  const w = parseFloat(weekNumber).toFixed(2);
  const [year, week] = w.split('.');
  const date = moment()
    .isoWeekYear(parseInt(year, 10))
    .isoWeek(parseInt(week, 10))
    .isoWeekday('monday');

  if (showRange) {
    const startMonth = date.format('MMMM');
    const endDate = moment(date).add(6, 'days');
    const endMonth = endDate.format('MMMM');

    let out = startMonth + ' ' + date.format('D') + ' - ';

    if (startMonth !== endMonth) {
      out += endMonth + ' ';
    }

    out += endDate.format('D, YYYY');

    return out;
  } else {
    return date.format('MMMM YYYY');
  }
}
