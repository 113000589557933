import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { Card } from '../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { RecipeToOpen } from '../model/entities/recipe-to-open';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';

@Injectable()
export class RecipeOpenerService {
  private subject = new Subject<RecipeToOpen>();
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  openCard(card: Card) {
    const recipeToOpen = new RecipeToOpen(card.recipe, card.userServings, card);
    return this._doIt(recipeToOpen);
    // this.subject.next(recipeToOpen);
  }

  openRecipe(recipe: Recipe) {
    const recipeToOpen = new RecipeToOpen(recipe);
    return this._doIt(recipeToOpen);
    // this.subject.next(recipeToOpen);
  }

  getRecipeOpener(): Observable<RecipeToOpen> {
    return this.subject.asObservable();
  }

  _doIt(recipeToOpen: RecipeToOpen) {
    if (this.modalRef) {
      this.modalRef.close();
      this.modalRef = null;
    }

    // Dynamic import prevents circular dependency warning/error
    import('./planner-recipe/planner-recipe.component').then((module) => {
      this.modalRef = this.modalService.open(module.PlannerRecipeComponent, {
        windowClass: 'full-modal',
      });
      console.log(recipeToOpen);
      this.modalRef.componentInstance.recipe = recipeToOpen.recipe;
      this.modalRef.componentInstance.servings = recipeToOpen.servings;
      this.modalRef.componentInstance.card = recipeToOpen.card;
    });
  }
}
