<h2 mat-dialog-title>New Drag-and-Drop</h2>
<mat-dialog-content>
  <!--  <div style="padding: 56.25% 0 0 0; position: relative">-->
  <!--    <iframe-->
  <!--      src="https://player.vimeo.com/video/842727040?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"-->
  <!--      frameborder="0"-->
  <!--      allow="autoplay"-->
  <!--      allowfullscreen-->
  <!--      style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"-->
  <!--      title="Healthy with Chris (Test) - Meal Planner - Google Chrome - 5 July 2023 (1)"-->
  <!--    ></iframe>-->
  <!--  </div>-->
  <!--  <script src="https://player.vimeo.com/api/player.js"></script>-->

  <p><strong>Welcome to our new drag-and-drop for meal planning!</strong></p>
  <p>You can now:</p>
  <ol>
    <li>Drag and drop recipes directly in to meals.</li>
    <li>Reorder recipes within meals simply by dragging them to your preferred position.</li>
    <li>Remove recipes by dragging them to the delete icon.</li>
  </ol>
  <p *ngIf="isTablet">
    On tablets, use the drag handle at the top-left of recipes to start dragging.
  </p>

  <div
    style="
      max-width: 400px;
      width: 100%;
      margin: auto;
      border: 2px dashed #858585;
      overflow: hidden;
    "
  >
    <video playsinline autoplay muted loop controls>
      <source src="https://cdn.livingplaterx.com/videos/new-drag-n-drop.mp4" type="video/mp4" />
    </video>
  </div>
</mat-dialog-content>

<br />
<mat-dialog-actions>
  <div class="row align-items-center no-gutters" style="width: 100%">
    <div class="col">
      <mat-checkbox [(ngModel)]="showNoMore">Don't show again</mat-checkbox>
    </div>
    <div class="col-auto ml-auto">
      <button mat-button (click)="close($event)">Close</button>
    </div>
  </div>
</mat-dialog-actions>
