import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Ingredient } from '../../../../../lprx-shared-lib/entities/recipe/Ingredient';
import { formatIngredient } from '../../../../../lprx-shared-lib/format-ingredient';

import { LprxApiProvider } from '../../../../../providers/lprx-api/api-provider';
import { stripTags } from './strip-tags';
import { RecipeEditorService } from '../../recipe-editor.service';

@Component({
  selector: 'app-ingredient-simple-input',
  templateUrl: './ingredient-simple-input.component.html',
  styleUrls: ['./ingredient-simple-input.component.scss'],
})
export class IngredientSimpleInputComponent implements OnInit {
  @Input() ingredient: Ingredient;

  ingredientText: string = '';
  errorMessage: string | null = null;

  private originalIngredientText: string;

  @Output() ingredientChange = new EventEmitter<Ingredient>();

  constructor(private lprxApi: LprxApiProvider, private recipeEditorService: RecipeEditorService) {}

  ngOnInit() {
    if (this.ingredient) {
      this.ingredientText = stripTags(formatIngredient(this.ingredient));
      this.originalIngredientText = this.ingredientText;
    }
  }

  saveIngredient() {
    if (this.ingredientText === this.originalIngredientText) {
      this.ingredientChange.emit(this.ingredient);
      return;
    }
    this.errorMessage = null;

    this.lprxApi.recipes
      .getIngredients([this.ingredientText])
      .then((result) => {
        console.log(result);
        if (result.ingredients.length > 0) {
          this.ingredient = result.ingredients[0];
          this.ingredientChange.emit(this.ingredient);
        }

        if (result.errors.length === 1 && result.errors[0].err_code === 100) {
          this.errorMessage = `There were multiple matches for "${this.ingredientText}". Please check for redundancy (e.g.: 'fusilli pasta', 'tomato, small plum)'.`;
        } else if (result.errors.length === 1 && result.errors[0].err_code === 101) {
          this.errorMessage = `No foods detected in input "${this.ingredientText}".`;
        } else if (result.errors.length > 0) {
          // collect multiple errors into one message
          this.errorMessage = result.errors.reduce((acc, err) => acc + err.warning + '\n', '');
        }
      })
      .catch((err) => {
        console.log(err);
        this.errorMessage = err;
      })
      .finally(() => {
        this.recipeEditorService.updatedIngredient();
      });
  }

  cancelIngredient() {
    this.ingredientChange.emit(this.ingredient);
  }

  handleKeyUp($event: KeyboardEvent) {
    // Check if the pressed key is 'Enter'
    if ($event.key === 'Enter' || $event.keyCode === 13) {
      // Prevent the default action (Form submission, in this case)
      $event.preventDefault();

      // Trigger saveIngredient() method
      this.saveIngredient();
    }
  }
}
