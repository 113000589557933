import { switchMap, take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { AuthService } from '../../auth.service';
import { CreditCardInputComponent } from '../../credit-card-input/credit-card-input.component';
import { ClientService } from '../../service/client/client.service';

enum UpgradePreventionReason {
  HAS_CUSTOM_PLANNER,
  IS_BUNDLED,
}

enum UpgradeComponentView {
  NONE,
  UPGRADE,
  ERROR,
  SUCCESS,
}

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit, OnDestroy {
  reasons = UpgradePreventionReason;
  views = UpgradeComponentView;
  client: User;
  distributor: Distributor;
  upgradePreventedReason: UpgradePreventionReason = null;
  view = UpgradeComponentView.NONE;
  proratedAmount = 0;
  nextRenewal = 0;

  @ViewChild(CreditCardInputComponent)
  private ccComponent: CreditCardInputComponent;

  private subs: Subscription[] = [];

  constructor(
    // private api: Api,
    private auth: AuthService,
    private clientService: ClientService,
    private router: Router
  ) {}

  ngOnInit() {
    const upgradePreviewSub = this.clientService.account
      .upgradePreview()
      .pipe(take(1))
      .subscribe((preview) => {
        // Calculate the proration cost:
        const currentProrations = [];
        this.proratedAmount = 0;
        const invoice = preview.invoice;
        for (let i = 0; i < invoice.lines.data.length; i++) {
          const invoice_item = invoice.lines.data[i];
          if (invoice_item.period.start === preview.proration_date) {
            currentProrations.push(invoice_item);
            this.proratedAmount += invoice_item.amount;
          }
        }
        this.nextRenewal = invoice.period_end * 1000;
      });
    this.subs.push(upgradePreviewSub);

    const setClientSub = this.auth.clientUser$.pipe(take(1)).subscribe((client) => {
      this.view = UpgradeComponentView.UPGRADE;
      this.client = client;
      if (!this.client.canUpgrade()) {
        if (this.client.hasCustomPlanner()) {
          this.upgradePreventedReason = UpgradePreventionReason.HAS_CUSTOM_PLANNER;
        } else if (this.client.isBundled()) {
          this.upgradePreventedReason = UpgradePreventionReason.IS_BUNDLED;
        }
      }
    });
    this.subs.push(setClientSub);
  }

  upgrade() {
    this.ccComponent
      .getPaymentSource()
      .pipe(switchMap((source) => this.clientService.account.upgrade(source)))
      .subscribe((result) => {
        if (result === true) {
          this.auth.refreshUser().subscribe();
          // this.view = UpgradeComponentView.SUCCESS;
          this.router.navigateByUrl('/account/upgrade/confirmation');
        } else {
          this.view = UpgradeComponentView.ERROR;
        }
      });
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }
}
