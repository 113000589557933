import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../../auth.service';
import { redirectToAuth } from '../../../lprx-auth';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('AuthGuard.canActivate()');

    return this.authService
      ._isLoggedIn()
      .then((isLoggedIn) => {
        console.log('AuthGuard.canActivate() isLoggedIn:', isLoggedIn);
        if (!isLoggedIn) {
          return this.redirectToAuth(state);

          // const path = window.location.href
          //   .replace(/https?:\/\//, '')
          //   .replace(window.location.host, '');
          // this.router.navigateByUrl('/login?redirect=' + encodeURIComponent(path));
        }
        return Promise.resolve(isLoggedIn);
      })
      .catch((e) => this.redirectToAuth(state));
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }


  private redirectToAuth(state: RouterStateSnapshot) {
    redirectToAuth({
      urlParams: {
        redirect: state.url
      },
    });

    return false;
  }
}
