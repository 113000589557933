import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { getTokenResponse } from '../lprx-auth';
import { apiHostname } from './lprx-api/api-hostname';

function isObject(obj) {
  return obj === Object(obj);
}

export interface HttpOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable()
export class ApiV3 {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
  ) {
    this.configAmplify();
  }

  private configAmplify() {}

  private prefixPath(path) {
    return ('/v3/' + path).replace(/\/\//g, '/');
  }

  /**
   * GET request
   *
   * @param path
   * @param queryParams
   */
  get<T>(path: string, queryParams?: any): Observable<T> {
    const options = this.httpOptions();

    let query = '';
    if (queryParams) {
      query =
        '?' +
        Object.keys(queryParams)
          .map((key) => {
            const value = queryParams[key];

            if (Array.isArray(value)) {
              return value.reduce(
                (acc, val, i) =>
                  acc +
                  (acc ? '&' : '') +
                  `${encodeURIComponent(key)}[${i}]=` +
                  `${encodeURIComponent(val)}`,
                '',
              );
              // return encodeURIComponent(key) + '=' + queryParams[key].reduce((acc, value) => acc + (acc ? ',' : '') + `${encodeURIComponent(value)}`, '');
            } else if (isObject(value)) {
              return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
            } else {
              return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            }
          })
          .join('&');
    }

    // hackish way to force the GET since HttpClient doesn't trigger until subscribe() is called
    const http$ = this.http.get<T>(
      `https://${apiHostname()}${this.prefixPath(path)}${query}`,
      options,
    );
    return fromPromise(http$.toPromise());
  }

  /**
   * DELETE request
   *
   * @param path
   */
  delete(path: string) {
    const options = this.httpOptions();
    // hackish way to force the GET since HttpClient doesn't trigger until subscribe() is called
    const http$ = this.http.delete(`https://${apiHostname()}${this.prefixPath(path)}`, options);
    return fromPromise(http$.toPromise());
  }

  /**
   * POST request
   *
   * @param path
   * @param payload
   */
  post<T>(path: string, payload: any): Observable<T> {
    const options = this.httpOptions();
    // hackish way to force the POST since HttpClient doesn't trigger until subscribe is called
    const http$ = this.http.post<T>(
      `https://${apiHostname()}${this.prefixPath(path)}`,
      payload,
      options,
    );
    return fromPromise(http$.toPromise());
  }

  private httpOptions() {
    const options: HttpOptions = {
      headers: {
        // 'x-aws-cognito-username': getLastAuthUsername()
        Authorization: `Bearer ${getTokenResponse().access_token}`,
      },
    };
    return options;
  }
}
