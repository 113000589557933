import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs-compat/observable/fromPromise';
import { Observable } from 'rxjs/Observable';
import { FullscriptTreatmentPlan } from '../../../lprx-shared-lib/entities/i-fullscript-practitioner';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';

@Injectable()
export class RecommendationsComponentService {
  constructor(private lprxApi: LprxApiProvider) {}

  getTreatmentPlans(username: string): Observable<FullscriptTreatmentPlan[]> {
    return fromPromise(this.lprxApi.get(`v3/fullscript/treatment-plans/${username}`));
  }
}
