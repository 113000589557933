/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/constants.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export const DEFAULT_CREATION_TIME = new Date(1970, 1, 1, 0, 0, 0, 0).getTime();
export const DEFAULT_LIST_LIMIT = 20;
export const IDEMPOTENCY_KEY_PREFIX = 'idpk';
export const ROOT_FOLDER_ID = '~';
