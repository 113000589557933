/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/day-as-long.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { DaysOfTheWeek } from './days-of-the-week';
import { Case } from './utils/case';

export function dayAsLong(day: any, asCase: Case | string = Case.Upper) {
  for (const d of DaysOfTheWeek.daysMap) {
    const _day = day.toLowerCase();
    if (d.abbrv.toLowerCase() === _day || d.name.toLowerCase() === _day) {
      return asCase === Case.Lower ? d.name.toLowerCase() : d.name;
    }
  }
  throw new Error(`Could not find day '${day}'`);
}
