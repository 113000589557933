<br>
<h6 class="p-2 bg-light"><strong>Directions</strong></h6>

<h6><strong>Prep</strong></h6>
<div *ngIf="directions.prep">
    <recipe-steps-input [steps]="directions.prep.steps"></recipe-steps-input>
</div>
<div *ngIf="!directions.prep">
    <button type="button" class="btn btn-link" (click)="addPrep()">+ Add Prep</button>
</div>

<br>

<h6><strong>Make</strong></h6>
<div *ngIf="directions.make">
    <recipe-steps-input [steps]="directions.make.steps"></recipe-steps-input>
</div>
