/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-plan/MealPlanType.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum MealPlanType {
  ROOT = 'root',
  ALIAS = 'alias',
  COPY = 'copy'
}
