import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute } from '@angular/router';
import { min } from 'mathjs';
// import { DragulaService } from 'ng2-dragula';
import { Observable, Subscription } from 'rxjs';
import { from } from 'rxjs/observable/from';
import { map, take, tap } from 'rxjs/operators';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserStatus } from '../../../lprx-shared-lib/entities/user/user-status';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { PlannerType } from '../../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import {
  PlannerConfigNutrientLimits,
  PlannerConfiguration,
} from '../../../lprx-shared-lib/planner/planner-configuration';
import { getCurrentWeekNumber } from '../../../lprx-shared-lib/utils/getCurrentWeekNumber';
import { nonCalendarStartWeek } from '../../../lprx-shared-lib/vars';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout/layout.service';
import { DistributorsService } from '../../service/distributors.service';
import { RecipeService } from '../../service/recipe.service';
import { getFinalFirstChild } from '../../utilities/get-final-first-child';
import { PlannerService } from '../planner.service';

@Component({
  selector: 'app-planner-wrapper',
  templateUrl: './planner-outlet.component.html',
  styleUrls: ['./planner-outlet.component.scss'],
})
export class PlannerOutletComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  showClientUpgrade = false;
  timeToSubscribe = false;
  user: User;
  isReady = false;
  recipeToInsert: Recipe;
  weeklyPlans: WeeklyPlan[];

  @ViewChild('drawer') drawer: MatSidenav;
  enableSlideOutPlanner$: Observable<boolean>;

  constructor(
    private auth: AuthService,
    public layout: LayoutService,
    private plannerService: PlannerService,
    private route: ActivatedRoute,
    // private dragulaService: DragulaService,
    private distributorsService: DistributorsService,
    private recipesService: RecipeService,
    private lprxApi: LprxApiProvider
  ) {}

  ngOnInit() {
    this.recipesService.clearClient();

    console.log('PlannerOutletComponent');

    this.plannerService.setWeeklyPlans([]);
    this.plannerService.plannerConfig.next(new PlannerConfiguration());
    this.plannerService.client = null;

    this.unsubscribe();
    this.plannerService.clear();

    this.subs.push(
      this.currentDistributorSub(),
      this.userSub(),
      this.weeklyPlansSub(),
      this.recipeToInsertSub()
    );

    this.enableSlideOutPlanner$ = this.plannerService.enableSlideOutPlanner$;

    this.plannerService.clearInsertRecipe();
  }

  private currentDistributorSub() {
    return this.distributorsService
      .getCurrent$()
      .pipe(take(1))
      .subscribe((distributor) => this.layout.setBrandingFromDistributor(distributor));
  }

  private userSub() {
    return this.auth
      .getLoggedInUser()
      .pipe(
        tap((user) => this.initUser(user)),
        tap(() => {
          // this.dragulaService.createGroup('bag-o-cards', {
          //   copy: (el: Element, source: Element) => {
          //     console.log('Copying recipe', el);
          //     return true;
          //   },
          //   moves: (el, container, handle) => {
          //     if (this.canDragNDrop()) {
          //       console.log('This user can drag');
          //       return true;
          //     } else {
          //       console.log('Preventing Drag');
          //       this.dragulaService.destroy('bag-o-cards');
          //       return false;
          //     }
          //   },
          // });
        })
      )
      .subscribe((user: User) => {
        this.user = user;
      });
  }

  private weeklyPlansSub() {
    return this.plannerService.weeklyPlans$.subscribe((weeklyPlans) => {
      if (weeklyPlans.length > 0) {
        this.isReady = true;
      }
      this.weeklyPlans = weeklyPlans;
    });
  }

  onClosedStart() {
    this.plannerService.clearInsertRecipe();
  }

  private recipeToInsertSub() {
    console.log('recipeToInsertSub');
    return this.plannerService.recipeToInsert$.subscribe((recipeInsertDetails) => {
      console.log(recipeInsertDetails);
      if (!this.drawer) {
        return;
      }

      this.recipeToInsert = recipeInsertDetails?.recipe;
      console.log(recipeInsertDetails);

      if (recipeInsertDetails) {
        this.drawer.open().then((matDrawResult) => {});
      } else {
        this.drawer.close().then((matDrawResult) => {});
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  private unsubscribe() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private initUser(user: User) {
    this.user = user;
    switch (user.userType) {
      case UserType.Client:
        this.initClientLoadPlannerSub(user as User);
        break;
      case UserType.Admin:
        this.initAdminLoadPlannerSub();
        break;
      case UserType.Distributor:
        this.initDistributorLoadPlannerSub();
        break;
    }
  }

  /**
   * Admin
   */
  private initAdminLoadPlannerSub() {
    const adminLoadPlannerSub = this.route.firstChild.params.subscribe((params) => {
      if (this.plannerService.weeklyPlans.length) {
        return;
      }
      this.plannerService.adminLoadPanner(params['mealPlanId']);
    });
    this.subs.push(adminLoadPlannerSub);
  }

  /**
   * Distributor
   */
  private initDistributorLoadPlannerSub() {
    const distributorLoadPlannerSub = getFinalFirstChild(this.route).params.subscribe((params) => {
      if (this.plannerService.weeklyPlans.length) {
        return;
      }
      if (params['username']) {
        this.plannerService.distLoadPlanner(params['username']);
      } else {
        from(this.lprxApi.mealPlans.get(params['mealPlanId']))
          .pipe(
            map((mp) => {
              if (params['weekNumber']) {
                return { startingWeekNumber: params['weekNumber'] };
              }

              if (mp.nonCalendar) {
                return {
                  startingWeekNumber: nonCalendarStartWeek,
                  additionalWeeksToLoad: min(mp.repeatInterval - 1, 3),
                };
              }

              return { startingWeekNumber: getCurrentWeekNumber(), additionalWeeksToLoad: 2 };
            })
          )
          .subscribe(({ startingWeekNumber, additionalWeeksToLoad }) => {
            this.plannerService.adminLoadPanner(
              params['mealPlanId'],
              startingWeekNumber,
              additionalWeeksToLoad
            );
          });
      }
    });
    this.subs.push(distributorLoadPlannerSub);
  }

  /**
   * Client
   */
  private initClientLoadPlannerSub(user: User) {
    const clientInFreeTrial =
      user.status() === UserStatus.FREE_TRIAL || user.terminatesAt < Date.now();
    const upgradeWindowAlreadyShown = sessionStorage.getItem('time-to-upgrade-shown') === 'true';
    this.showClientUpgrade = clientInFreeTrial && !upgradeWindowAlreadyShown;
    this.timeToSubscribe = user.terminatesAt < Date.now();
    sessionStorage.setItem('time-to-upgrade-shown', 'true');
    getFinalFirstChild(this.route)
      .params.pipe(take(1))
      .subscribe((params) => {
        this.plannerService.clientLoadPlanner(params['weekNumber']);
      });
  }

  onClosed(closed: boolean) {
    this.showClientUpgrade = false;
  }

  private canDragNDrop() {
    if (!this.user) {
      return false;
    }

    if (this.user.userType === 'admin') {
      return true;
    }

    if (this.user.userType === UserType.Client) {
      return this.user.plannerType === PlannerType.Custom;
    }

    if (this.user.userType === UserType.Distributor) {
      return true;
    }

    return false;
  }

  closeDrawer() {
    this.drawer.close();
  }
}
