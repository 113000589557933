/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/notification/notification-status.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum NotificationStatus {
  Pending = 'pending',
  Unread = 'unread',
  Read = 'read',
  Hidden = 'hidden'
}
