/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/get-upload-url.response.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';

@Exclude()
export class GetUploadUrlResponse {
  @Expose()
  key: string;

  @Expose()
  signedUrl: string;
}
