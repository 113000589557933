<h5>Nutritional Values</h5>

<div class="row">
  <div class="col-6">
    <!-- Calories -->

    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-5">Calories</div>
        <div class="col-4">
          <div class="input-group -input-group-sm">
            <input class="form-control" type="number" [(ngModel)]="nutritionFacts.calories" name="calories[]" placement="top" ngbTooltip="Calories"
              triggers="focus:blur">
          </div>
        </div>
      </div>
    </div>


    <!-- Protein -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-5">Protein</div>
        <div class="col-4">
          <div class="input-group -input-group-sm">
            <input class="form-control" type="number" [(ngModel)]="nutritionFacts.protein" name="protein[]" placement="top" ngbTooltip="Protein"
              triggers="focus:blur">
            <div class="input-group-append">
              <span class="input-group-text"  >g</span>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Fat -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-5">Fat</div>
        <div class="col-4">
          <div class="input-group -input-group-sm">
            <input class="form-control" type="number" [(ngModel)]="nutritionFacts.fat" name="fat[]" placement="top" ngbTooltip="Fat"
              triggers="focus:blur">
            <div class="input-group-append">
              <span class="input-group-text"  >g</span>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div class="col-6">
    <!-- Carbs -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-5">Total Carbs</div>
        <div class="col-4">
          <div class="input-group -input-group-sm">
            <input class="form-control" type="number" [(ngModel)]="nutritionFacts.carbs" name="carbs[]" placement="top" ngbTooltip="Total Carbs"
              triggers="focus:blur">
            <div class="input-group-append">
              <span class="input-group-text"  >g</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Carbs From Sugar -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-5">Sugar</div>
        <div class="col-4">
          <div class="input-group -input-group-sm">
            <input class="form-control" type="number" [(ngModel)]="nutritionFacts.carbsFromSugar" name="carbsFromSugar[]" placement="top" ngbTooltip="Sugar"
              triggers="focus:blur">
            <div class="input-group-append">
              <span class="input-group-text"  >g</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Carbs From Fiber -->
    <div class="form-group">
      <div class="row align-items-center">
        <div class="col-5">Fiber</div>
        <div class="col-4">
          <div class="input-group -input-group-sm">
            <input class="form-control" type="number" [(ngModel)]="nutritionFacts.carbsFromFiber" name="carbsFromFiber[]" placement="top"
              ngbTooltip="Fiber" triggers="focus:blur">
            <div class="input-group-append">
              <span class="input-group-text"  >g</span>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>
