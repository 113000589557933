import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DistributorsService } from '../../service/distributors.service';
import { ActivatedRoute } from '@angular/router';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { sanitizeEmail } from '../../../lprx-shared-lib/sanitize-email';

@Component({
  selector: 'app-vioscreen-landing-page',
  templateUrl: './vioscreen-landing-page.component.html',
  styleUrls: ['./vioscreen-landing-page.component.scss'],
})
export class VioscreenLandingPageComponent {
  userForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.minLength(2)]],
    lastName: ['', [Validators.required, Validators.minLength(2)]],
    email: ['', [Validators.required, Validators.email, Validators.minLength(2)]]
  });

  distributorVanityId: string | null = null;
  private distributor: Distributor;
  processing: boolean = false;

  constructor(
    private fb: FormBuilder,
    private distributorService: DistributorsService,
    private route: ActivatedRoute
  ) {
    // get distributorId from route
    this.route.params.subscribe((params) => {
      this.distributorVanityId = params['distributorVanityId'];
      this.distributorService.get(this.distributorVanityId).subscribe((distributor) => {
        this.distributor = distributor;
      });
    });
  }

  onSubmit() {
    this.processing = true;
    if (this.userForm.valid) {
      console.log('Form submitted', this.userForm.value);
      // Handle form submission logic here

      this.distributorService
        .vioscreenLandingPageSubmission(this.distributor.id, {
          email: sanitizeEmail(this.userForm.value.email),
          firstName: this.userForm.value.firstName,
          lastName: this.userForm.value.lastName,
        })
        .subscribe({
          next: (vioTokenResponse) => {

          console.log('VioTokenResponse', vioTokenResponse);

          // new a link to the url
          const link = document.createElement('a');
          link.href = vioTokenResponse.url;
          link.click();

          },
          error: (error) => {
            console.error('Error submitting form', error);
            this.processing = false;
          },
          complete: () => {            
          }
        });
    } else {
      console.log('Form is invalid');
    }
  }
}
