/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/minutes-to-human.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export function minutesToHuman(time: number) {
  if (time === 0) {
    return '0 mins';
  }
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  let humanTime = '';
  if (hours === 0) {
  } else if (hours === 1) {
    humanTime += '1 hr ';
  } else {
    humanTime += hours + ' hrs ';
  }
  if (minutes === 0) {
  } else if (minutes === 1) {
    humanTime += '1 min ';
  } else {
    humanTime += minutes + ' mins';
  }
  return humanTime;
}
