import { Directive, ElementRef, Input } from '@angular/core';
import { conLogButton } from './con-log-button';

export type ButtonStyle = 'primary' | 'secondary' | 'danger';

@Directive({
  standalone: true,
  selector: '[appButton]',
})
export class ButtonDirective {
  type: ButtonStyle = 'primary';

  @Input() set appButton(value: ButtonStyle) {
    this.type = value ? value : 'primary';
    this.setButtonType();
  }

  @Input() set btnSmall(value: boolean) {
    this.el.nativeElement.className = this.el.nativeElement.className.replace(
      'tw-py-2.5',
      'tw-py-2',
    );
  }

  @Input() set btnXSmall(value: boolean) {
    this.el.nativeElement.className = this.el.nativeElement.className.replace(
      'tw-py-2.5',
      'tw-py-1',
    );
  }

  // full width button
  @Input() set fullWidth(value: boolean) {
    this.el.nativeElement.className += ' tw-w-full';
  }

  constructor(private el: ElementRef) {
    // alert(this.el.nativeElement.type);

    let nativeElement = this.el.nativeElement;

    // If button is not inside a form, set type to button
    conLogButton(nativeElement);

    setTimeout(() => {
      conLogButton(nativeElement);

      if (!nativeElement.form) {
        nativeElement.type = 'button';
      }
    }, 500);

    // base styles
    nativeElement.className +=
      ' tw-inline-flex tw-items-center tw-justify-center tw-border tw-border-transparent tw-rounded-3xl tw-gap-2' +
      ' tw-text-secondary-900 tw-text-sm tw-font-medium tw-no-underline' +
      ' focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus:tw-outline-none' +
      ' disabled:tw-pointer-events-none disabled:tw-opacity-50' +
      ' tw-transition tw-duration-150 tw-ease-[cubic-bezier(0.4, 0.0, 0.2, 1)]' +
      ' tw-px-5 tw-py-2.5 tw-ml-auto';
  }

  protected setButtonType() {
    const classes = {
      primary: 'focus-visible:tw-ring-primary-600 tw-bg-primary hover:tw-bg-primary-300',
      secondary: 'focus-visible:tw-ring-primary-600 tw-bg-secondary-100 hover:tw-bg-secondary-50',
      danger:
        'tw-text-white focus-visible:tw-ring-secondary-700 tw-bg-danger hover:tw-bg-danger-500',
    };

    this.el.nativeElement.className += ' ' + classes[this.type];
  }
}

// primary button directive
@Directive({
  standalone: true,
  selector: '[btnPrimary]',
})
export class PrimaryButtonDirective extends ButtonDirective {
  constructor(el: ElementRef) {
    super(el);
    this.type = 'primary';
    this.setButtonType();
  }
}

// danger button directive inherits from primary button directive
@Directive({
  standalone: true,
  selector: '[btnDanger]',
})
export class DangerButtonDirective extends ButtonDirective {
  constructor(el: ElementRef) {
    super(el);
    this.type = 'danger';
    this.setButtonType();
  }
}

// secondary button directive inherits from primary button directive
@Directive({
  standalone: true,
  selector: '[btnSecondary]',
})
export class SecondaryButtonDirective extends ButtonDirective {
  constructor(el: ElementRef) {
    super(el);
    this.type = 'secondary';
    this.setButtonType();
  }
}
