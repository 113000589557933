<div class='form-group'>
    <label for='inputPassword' class='sr-only'>Password</label>
    <div class='input-group mb-3'>
        <input [type]="showPassword ? 'text' : 'password'"
               [(ngModel)]='password'
               (change)='validatePassword()'
               (keyup)='validatePassword()'
               id='inputPassword'
               name='input-password'
               placeholder='Password'
               required
               class='form-control'>

        <div class='input-group-append' (click)='showPassword = !showPassword'>
            <span class='input-group-text' id='basic-addon2'>
                <i class='fa fa-fw' [class.fa-eye]='showPassword' [class.fa-eye-slash]='!showPassword'></i>
            </span>
        </div>
    </div>
</div>


<p>Password must be at least
    <span [class.text-success]='isPasswordLongEnough'
          [class.text-danger]='password && !isPasswordLongEnough'>
        6 characters</span> with

    <span [class.text-success]='doesPasswordHaveUppercase'
          [class.text-danger]='password && !doesPasswordHaveUppercase'>
        1 uppercase</span>,

    <span [class.text-success]='doesPasswordHaveLowercase'
          [class.text-danger]='password && !doesPasswordHaveLowercase'>
         1 lowercase</span>,

    and
    <span [class.text-success]='doesPasswordHaveNumber'
          [class.text-danger]='password && !doesPasswordHaveNumber'>
        1 number.
    </span>
</p>
