import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { FullscriptTreatmentPlan } from '../../../lprx-shared-lib/entities/i-fullscript-practitioner';
import { AuthService } from '../../auth.service';
import { RecommendationsComponentService } from './recommendations-component.service';

@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {
  loading = true;
  treatmentPlans: FullscriptTreatmentPlan[];
  isClient = false;

  constructor(
    private service: RecommendationsComponentService,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        switchMap(params => {
          if (params['username']) {
            return of(params['username']);
          } else {
            this.isClient = true;
            return this.auth.getUser().pipe(
              take(1),
              map(u => {
                return u.username;
              })
            );
          }
        }),
        switchMap(username => {
          return this.service.getTreatmentPlans(username);
        })
      )
      .subscribe((tps: FullscriptTreatmentPlan[]) => {
        this.loading = false;
        this.treatmentPlans = tps.filter(t => t.state !== 'cancelled');
      });
  }

  fsImg(i: string) {
    return i.match(/^http/) ? i : 'https://api-us.fullscript.io/assets/noimage/product.png';
  }
}
