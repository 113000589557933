import { filter, map, switchMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AdminService } from '../../../../../providers/admin/admin.service';
import { Api } from '../../../../../providers/aws.api';
import { AuthService } from '../../../../auth.service';
import { DistributorService } from '../../../../distributor/distributor-service';
import { MealPlan } from '../../../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { MealPlanType } from '../../../../../lprx-shared-lib/entities/meal-plan/MealPlanType';

interface MealPlanSelectionOption {
  name: string;
  checked: boolean;
  value: string;
}

@Component({
  selector: 'app-recipe-meal-plans-input',
  templateUrl: './recipe-meal-plans-input.component.html',
  styleUrls: ['./recipe-meal-plans-input.component.scss'],
})
export class RecipeMealPlansInputComponent implements OnInit, OnDestroy {
  @Input() mealPlans: MealPlan[] = [];
  @Output() updatedMealPlans = new EventEmitter<string[]>();

  options: MealPlanSelectionOption[] = [];
  optionsCol1: MealPlanSelectionOption[] = [];
  optionsCol2: MealPlanSelectionOption[] = [];

  allMealPlans: string[] = [];

  private subs: Subscription[] = [];

  constructor(
    private api: Api,
    private adminService: AdminService,
    private distributorService: DistributorService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    const getMealPlansOptionsSub = this.auth.user$
      .pipe(
        filter((user) => user.userType === 'admin' || user.userType === 'distributor'),
        switchMap((user) => this.getMealPlanOptionsForUser(user))
      )
      .subscribe((mealPlanOptions: MealPlanSelectionOption[]) => {
        this.options = mealPlanOptions;
        this.options = this.options.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
        this.optionsCol2 = Array.from(this.options);
        this.optionsCol1 = this.optionsCol2.splice(0, Math.ceil(this.options.length / 2));
      });

    this.subs.push(getMealPlansOptionsSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private getMealPlanOptionsForUser(user) {
    switch (user.userType) {
      case 'admin':
        return this.getAdministratorMealPlanOptions();
      case 'distributor':
        return this.getDistributorMealPlanOptions();
      default:
        throw new Error('Clients do not have access to meal plans');
    }
  }

  private getDistributorMealPlanOptions() {
    return this.distributorService.mealPlans.getMealPlans().pipe(
      map((dMealPlans) => {
        return dMealPlans.map((mp) => ({
          name: mp.name,
          value: mp.mealPlanId,
          checked: this.isSelected(mp.mealPlanId),
        }));
      })
    );
  }

  private getAdministratorMealPlanOptions() {
    return this.adminService.mealPlans.all().pipe(
      map((mealPlans: MealPlan[]) => {
        return mealPlans
          .filter((mp) => mp.type !== MealPlanType.ALIAS)
          .map((mp) => ({ name: mp.name, value: mp.id, checked: this.isSelected(mp.id) }));
      })
    );
  }

  isSelected(mealPlanId) {
    return this.mealPlans && this.mealPlans.indexOf(mealPlanId) > -1;
  }

  updateMealPlans() {
    const mealPlans = [];
    this.options
      .filter((opt) => opt.checked)
      .map((opt) => opt.value)
      .forEach((opt) => mealPlans.push(opt));

    this.updatedMealPlans.emit(mealPlans);
  }

  selectAll() {
    const checked = !this.allChecked();
    this.options.forEach((opt) => (opt.checked = checked));
    this.updateMealPlans();
  }

  allChecked() {
    return this.options.every((opt) => opt.checked);
  }
}
