// component that sends the payment source to window that opened it
// Path: lprx-angular\src\app\credit-card-input\credit-card-input.component.ts
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Stripe } from 'stripe';
import { Observable } from 'rxjs/Observable';

import { StripeService } from 'ngx-stripe';
import {
  SourceResult,
  StripeCardElement,
  StripeElements,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { from } from 'rxjs/observable/from';

@Component({
  selector: 'app-credit-card-form',
  templateUrl: './credit-card-input.component.html',
  styleUrls: ['./credit-card-input.component.scss'],
})
export class CreditCardInputComponent implements OnInit, OnChanges {
  selectedCard: string;

  @Input()
  cardPaymentMethods: Stripe.Card[] = [];
  card: StripeCardElement;

  @Input()
  showCardSelect = true;

  @Input()
  collectName = true;

  @Output()
  source = new EventEmitter<string>();

  private elements: StripeElements;
  private elementsOptions: StripeElementsOptions = {
    // locale: 'es'
  };
  showNewCardForm: boolean;
  cardHolderName: string;

  constructor(private stripeService: StripeService) {}

  ngOnInit() {
    if (this.cardPaymentMethods && this.cardPaymentMethods.length > 0) {
      this.selectedCard = this.cardPaymentMethods[0].id;
    } else {
      this.selectedCard = '--new--';
      this.showNewCardForm = true;
    }
    this.initStripeElement();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.selectedCard && this.cardPaymentMethods && this.cardPaymentMethods.length > 0) {
      this.selectedCard = this.cardPaymentMethods[0].id;
    }
  }

  private initStripeElement() {
    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create('card', {
          style: {
            base: {
              iconColor: '#666EE8',
              color: '#31325F',
              lineHeight: '40px',
              fontWeight: 300,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: '18px',
              '::placeholder': {
                color: '#CFD7E0',
              },
            },
          },
        });
        this.card.mount('#card-element');
      }
    });
  }

  onChangeCard($event: Event) {
    this.showNewCardForm = this.selectedCard === '--new--';
  }

  getPaymentSource(amount?: number): Observable<string> {
    if (this.showNewCardForm) {
      const sourceResult$ = this.collectName
        ? this.stripeService.createSource(this.card, { owner: { name: this.cardHolderName } })
        : // @ts-ignore
          this.stripeService.createSource(this.card);

      return from(this.elements.submit()).pipe(
        switchMap((e) => {
          return this.stripeService.stripe.createPaymentMethod({ elements: this.elements });
        }),
        map((e) => {
          return e.paymentMethod.id;
        })
      );
    } else {
      return of(this.selectedCard);
    }
  }
}
