import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { DistributorType } from '../../../lprx-shared-lib/entities/distributor-type';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';
import { DistributorsService } from '../../service/distributors.service';

@Injectable()
export class DistributorWellnessProGuard implements CanActivate, CanActivateChild {
  constructor(
    private authService: AuthService,
    private router: Router,
    private distService: DistributorsService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.can(state);
  }

  private can(state: RouterStateSnapshot) {
    return this.authService.getUser().pipe(
      switchMap((user) => {
        if (user.userType !== UserType.Distributor) {
          return of(this.router.createUrlTree(['/login']));
        }

        return this.distService.currentDistributor$.pipe(
          map((dist: Distributor) => {
            if (dist.type === DistributorType.HealthCareProfessional) {
              return true;
            }

            if (
              !!state.url.match(/distributor\/plans/) ||
              !!state.url.match(/distributor\/prime/) ||
              !!state.url.match(/distributor\/account/)
            ) {
              return true;
            }

            if (dist.primeTerminatesAt < Date.now()) {
              return this.router.createUrlTree(['/distributor/prime/dash']);
            }

            return true;

            // const r = state.url;
            // const isInPrimePurchase = !!state.url.match(/distributor\/prime\/purchase/);
            // const isInDistributorAccount = !!state.url.match(/distributor\/account/);
            // const isHealthCareProfessional = dist.type === DistributorType.HealthCareProfessional;
            // const hasActivePrimeSubscription = dist.primeTerminatesAt > Date.now();
            // if (isHealthCareProfessional && !hasActivePrimeSubscription) {
            //   const urlTree = this.router.createUrlTree(['/distributor', 'prime']);
            //   console.log(urlTree);
            //   return urlTree;
            // }
            // return true;
          })
        );
      })
    );
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.can(state);
  }
}
