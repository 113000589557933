import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { takeWhile, tap } from 'rxjs/operators';
import { combineLatest, interval, Observable, Subscription } from 'rxjs';
import { nonCalendarStartWeek } from '../../../lprx-shared-lib/vars';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { CardType } from '../../model/enum/card-type';
import { HeaderService } from '../../service/header.service';
import { PlannerService } from '../planner.service';
import { convertNonCalWeekNumber } from '../../../lprx-shared-lib/non-calendar-display';
import { Day } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Day';
import { Meal } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Meal';

@Component({
  selector: 'app-planner-layout-narrow',
  templateUrl: './planner-layout-narrow.component.html',
  styleUrls: ['./planner-layout-narrow.component.scss'],
})
export class PlannerLayoutNarrowComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];

  CardType: typeof CardType = CardType;
  weeklyPlans: WeeklyPlan[];

  selectedWeekNumber = '';
  selectedDay = '';
  meals: Meal[] = [];
  weeklyPlans$: Observable<WeeklyPlan[]> = this.plannerService.weeklyPlans$;
  showPrevWeekButton = true;
  loadingPreviousWeek = false;

  private isScrolled = false;

  @ViewChild('daySelect', { static: true }) daySelect: ElementRef;

  nonCalweeks: any;
  isNonCalendar = false;
  currentWeek: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private header: HeaderService,
    private plannerService: PlannerService,
    private router: Router
  ) {}

  setShowPrevWeekButton(earliestWeeklyPlan: WeeklyPlan) {
    if (earliestWeeklyPlan.weekNumber === nonCalendarStartWeek) {
      this.showPrevWeekButton = false;
      return;
    }
  }

  ngOnInit() {
    // this.weeklyPlans$ = this.plannerService.weeklyPlans$;

    const combinedSubscription = combineLatest(
      this.activatedRoute.params,
      this.weeklyPlans$
    ).subscribe(([params, weeklyPlans]) => {
      this.header.hideBackButton();

      const dayName = params['day'];

      this.currentWeek = params['weekNumber'];
      const weekNumber = this.currentWeek;

      this.setShowPrevWeekButton(weeklyPlans[0]);

      if (!weeklyPlans || weeklyPlans.length === 0 || !weekNumber || !dayName) {
        return;
      }

      this.isNonCalendar = weeklyPlans[0].mealPlan.nonCalendar;

      const mealPlan = weeklyPlans[0].mealPlan;
      const array = new Array(mealPlan.repeatInterval);
      console.log({ mealPlan, array });

      this.nonCalweeks = Array.from(array, (x, i) => i + 1);

      if (weekNumber && dayName) {
        this.selectedWeekNumber = weekNumber;
        this.selectedDay = dayName;
        for (const _wp of weeklyPlans) {
          if (_wp.weekNumber === this.selectedWeekNumber) {
            const day = _wp[this.selectedDay] as Day;
            this.meals = day.meals;
          }
        }

        this.scrollDaySelection(weekNumber, dayName);
      } else {
        this.selectedWeekNumber = '!!!';
        this.selectedDay = '!!!';
        this.meals = [];
      }
    });

    this.subs.push(combinedSubscription);

    this.header.hideBackButton();

    // const scrollXSub = interval(500).subscribe(() => {
    //     // document.getElementById('daySelector').scrollTo(document.getElementById('day-select-2019.13-tuesday').offsetLeft,0)
    //     if (this.daySelect.nativeElement.scrollTo)
    //
    // });
  }

  scrollDaySelection(weekNumber: string, dayName: string) {
    this.isScrolled = false;

    interval(250)
      .pipe(takeWhile(() => !this.isScrolled))
      .subscribe(() => {
        try {
          const selector = `#day-select-${weekNumber.replace('.', '-')}-${dayName}`;
          const querySelector = this.daySelect.nativeElement.querySelector(selector);
          const scrollToX = querySelector.offsetLeft - 172;
          this.daySelect.nativeElement.scrollTo(scrollToX, 0);
          this.isScrolled = true;
        } catch (e) {
          // ignore
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  setDay(weekNumber: string, day: string) {
    // this.selectedDay = weekNumber + ' ' + day;
    this.router.navigateByUrl(`/planner/day/${weekNumber}/${day}`);
  }

  /**
   * @param card
   * @param selectedWeekNumber
   * @param selectedDay
   * @param meal
   */
  openRecipeCard(card: Card, selectedWeekNumber: string, selectedDay: string, meal: Meal) {
    const path = `/planner/recipe/${
      card.recipe.id
    }/${selectedWeekNumber}/${selectedDay}/${meal.name.toLowerCase()}`;
    this.router.navigateByUrl(path);
  }

  /**
   * @param weekNumber
   * @param day
   * @param meal
   */
  openSearch(weekNumber: string, day: string, meal: string) {
    this.router.navigateByUrl(`/planner/search/${weekNumber}/${day}/${meal}/add-recipe`);
  }

  countRecipeCards(meal: Meal) {
    const reducer = (acc: number, c: Card) => {
      if (c.type === CardType.Recipe) {
        return acc + 1;
      }
      return acc;
    };

    if (meal.cards && meal.cards.length) {
      const totalRecipeCards = meal.cards.reduce(reducer, 0);
      return totalRecipeCards;
    }
    return 0;
  }

  evenNumberOfRecipeCards(meal: Meal) {
    return this.countRecipeCards(meal) % 2 === 0;
  }

  oddNumberOfRecipeCards(meal: Meal) {
    return !this.evenNumberOfRecipeCards(meal);
  }

  loadPreviousWeek() {
    this.loadingPreviousWeek = true;
    this.showPrevWeekButton = false;
    this.plannerService
      .clientLoadPreviousWeek()
      .pipe(tap((wp) => this.setShowPrevWeekButton(wp)))
      // .pipe(finalize(() => (this.loadingPreviousWeek = false))) <-- doesn't seem to fire
      .subscribe(
        () => (this.loadingPreviousWeek = false),
        () => (this.loadingPreviousWeek = false)
      );
  }

  convertNonCalWeekNumber(weekNumber: string) {
    return convertNonCalWeekNumber(weekNumber);
  }

  changeWeek() {
    this.router.navigateByUrl(`/planner/day/${this.currentWeek}/monday`);
    this.plannerService.clientSkipToWeek(this.currentWeek);
  }

  isCurrentWeek(weekNumber: string) {
    return weekNumber === this.currentWeek ? 'selected' : 'false';
  }
}
