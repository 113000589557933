<!--<div *ngIf="showHeader" class="d-none d-print-block mt-2 mb-3">-->
<!--    &lt;!&ndash;<img src="/assets/img/lprx-logo.png" alt="Living Plate Rx" class="brand-image">&ndash;&gt;-->
<!--    <img [src]="brand.safeLogo" alt="{{brand.name}}" class="brand-image">-->
<!--</div>-->

<nav
  *ngIf="showHeader"
  class="navbar navbar-expand-md sticky-top"
  style="border-bottom: 1px solid #e5e5e5; background-color: white"
>
  <ng-container *ngIf="backButton && (layout.isNarrow$ | async)">
    <button
      (click)="goBack()"
      class="navbar-toggler navbar-toggler-right"
      type="button"
      aria-controls="navbarContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="fa fa-chevron-left"></span>
    </button>
  </ng-container>

  <ng-container *ngIf="!backButton || (layout.isWide$ | async)">
    <a *ngIf="!brand && showBranding" class="navbar-brand" href="/" id="default-brand">
      <img src="/assets/img/lprx-logo.png" alt="Living Plate Rx" class="brand-image"
    /></a>
    <a
      *ngIf="brand && showBranding"
      class="navbar-brand"
      href="javascript:"
      style="cursor: default"
    >
      <img [src]="brand.safeLogo" alt="{{ brand.name }}" class="brand-image"
    /></a>
  </ng-container>

  <ng-container *ngIf="isLoggedIn && (layout.isNarrow$ | async)">
    <ng-container *ngIf="user && user.userType == 'user'">
      <a class="nav-link" routerLink="/planner">My Planner</a>
      <!-- Only show when then back button is active as the back button doesn't show during the planner view -->
      <button
        *ngIf="backButton"
        (click)="openPlanner()"
        class="navbar-toggler navbar-toggler-right"
        type="button"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="fa fa-calendar"></span>
      </button>

      <button
        (click)="openPlannerSearch()"
        class="navbar-toggler navbar-toggler-right"
        type="button"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="fa fa-search"></span>
      </button>

      <button
        (click)="openGroceryList()"
        class="navbar-toggler navbar-toggler-right"
        type="button"
        aria-controls="navbarContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img src="/assets/img/icon/meal-plan.svg" style="height: 25px" />
      </button>

      <a *ngIf="!hasPlans" routerLink="/planner/recommendations">
        <img src="/assets/img/fullscript.png" height="25" />
      </a>
    </ng-container>
  </ng-container>

  <button
    class="navbar-toggler navbar-toggler-right"
    type="button"
    (click)="openSideNav()"
    [attr.aria-expanded]="!navbarCollapsed"
    aria-controls="navbarContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="fa fa-bars"></span>
  </button>

  <div class="navbar-collapse" [ngbCollapse]="navbarCollapsed" id="navbarContent">
    <ul class="navbar-nav ml-auto mt-sm-4 mt-md-0">
      <li *ngIf="user && isLoggedIn" class="nav-item">
        <a
          *ngIf="user.userType == 'distributor'"
          class="nav-link"
          routerLink="/distributor/dashboard"
        >
          <lucide-angular name="layout-dashboard"></lucide-angular>&nbsp;
          Dashboard
        </a>
        <a *ngIf="user.userType == 'admin'" class="nav-link" routerLink="/admin/dashboard"
          >Admin Dashboard</a
        >
        <a *ngIf="user.userType == 'user'" class="nav-link" routerLink="/planner">My Planner</a>
      </li>
      <li *ngIf="user && user.userType == 'user'">
        <a class="nav-link" routerLink="/planner/recipes">Recipe Box</a>
      </li>
      <li *ngIf="user && user && user.userType == 'user'" class="nav-item">
        <a class="nav-link" routerLink="/account">Account</a>
      </li>
      <li class="d-sm-none nav-item">
        <a *ngIf="isLoggedIn" class="nav-link" routerLink="/logout">Log Out</a>
        <a *ngIf="!isLoggedIn" class="nav-link" routerLink="{{ brand.baseUrl }}/login">Log In</a>
      </li>
    </ul>

    <form class="form-inline mt-2 mt-md-0 d-none d-md-inline ml-2">
      <a *ngIf="isLoggedIn" class="btn btn-outline-primary" routerLink="/logout">Log Out</a>
      <a *ngIf="!isLoggedIn" class="btn btn-outline-primary" routerLink="{{ brand.baseUrl }}/login"
        >Log In</a
      >
    </form>
  </div>
</nav>
