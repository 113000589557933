/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/dto/activate-account-dto.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { BundleClient } from '../entities/bundle-client';
import { PasswordPasser } from '../password-passer';

@Exclude()
export class ActivateAccountDto {
  @Expose()
  p: string;

  @Expose()
  bundleClientId: string;

  @Expose()
  bundleId: string;

  constructor(bundleClient: BundleClient, password: string) {
    if (arguments.length > 0) {
      this.bundleId = bundleClient.bundleId;
      this.bundleClientId = bundleClient.id;
      this.p = PasswordPasser.encrypt(password);
    }
  }
}
