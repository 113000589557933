import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MealType } from '../../../../../lprx-shared-lib/entities/meal-plan/MealType';

/**
 * Generated class for the RecipeMealTypeInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-meal-type-input',
  templateUrl: 'recipe-meal-type-input.html',
  styleUrls: ['./recipe-meal-type-input.scss'],
})
export class RecipeMealTypeInputComponent implements OnInit {
  @Input() mealTypes: string[];
  @Output() updated = new EventEmitter<string[]>();

  allTypes: string[] = [];
  options = [];

  constructor() {}

  ngOnInit() {
    this.allTypes = [
      MealType.Breakfast,
      MealType.Lunch,
      MealType.Dinner,
      MealType.Snack,
      MealType.Side,
    ];

    this.options = [];
    this.allTypes.forEach((element) => {
      this.options.push({ name: element, value: element, checked: this.isSelected(element) });
    });

    console.log(this.options);
  }

  isSelected(mealType) {
    return this.mealTypes.indexOf(mealType) > -1;
  }

  updateMealTypes() {
    const mealTypes = [];
    this.options
      .filter((opt) => opt.checked)
      .map((opt) => opt.value)
      .forEach((opt) => mealTypes.push(opt));

    console.log(mealTypes);

    this.updated.emit(mealTypes);
  }
}
