import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class DropService {
  private subject = new Subject<string>();
  private draggingCardSubject = new Subject<boolean>();

  // readonly draggingCardPosition = new BehaviorSubject<{ x: number; y: number }>({ x: 0, y: 0 });
  // public readonly draggingCardPosition$ = this.draggingCardPosition
  //   .asObservable()
  //   .pipe(debounceTime(50));
  //
  isDragging = false;

  constructor() {}

  setActiveDrop(container: string) {
    this.subject.next(container);
  }

  getActiveDrop(): Observable<string> {
    return this.subject.asObservable();
  }

  getIsCardDragging(): Observable<boolean> {
    return this.draggingCardSubject.asObservable();
  }

  startDraggingCard() {
    this.draggingCardSubject.next(true);
  }

  stopDraggingCard() {
    this.draggingCardSubject.next(false);
  }

  // dragStart() {
  //   this.isDragging = true;
  // }
  //
  // draggingCard(x: number, y: number) {
  //   this.draggingCardPosition.next({ x, y });
  // }
}
