import { Location, PopStateEvent } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StripeService } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { environment } from '../environments/environment';
import { User } from '../lprx-shared-lib/entities/user/user';
import { unsubscribe } from '../lprx-shared-lib/utils/unsubscribe';
import { Api } from '../providers/aws.api';
import { LprxApiProvider } from '../providers/lprx-api/api-provider';
import { AuthService } from './auth.service';
import { isBadClock } from './is-bad-clock';
import { LayoutService } from './layout/layout.service';
import { WindowSize } from './layout/windowSize';
import { Brand } from './model/entities/brand';
import { CdnService } from './service/cdn.service';
import { HeaderService } from './service/header.service';
import { VarService } from './service/var.service';
import { VersionCheckerService } from './service/version-checker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';
  versionNumber = '';
  currentYear = new Date().getFullYear();
  brand: Brand = new Brand('Living Plate Rx', '/assets/img/lprx-logo.png', '');

  @ViewChild('sidenav', { static: true }) sideNavRef: MatSidenav;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  innerHeight: number;
  headerHeight: SafeStyle;
  windowSize: WindowSize;
  isLoggedIn: boolean;
  user: User;
  logoUrl: string;
  showBranding: boolean;
  subs: Subscription[] = [];
  headerVisible: boolean = true;

  onSideNavScroll(event) {
    event.stopPropagation();
  }

  constructor(
    private api: Api,
    private auth: AuthService,
    private cdn: CdnService,
    private domSanitizer: DomSanitizer,
    private header: HeaderService,
    public layout: LayoutService,
    private location: Location,
    private router: Router,
    private stripeService: StripeService,
    private varService: VarService,
    private versionChecker: VersionCheckerService,
    private readonly activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private lprxApi: LprxApiProvider
  ) {}

  ngOnInit() {
    this.checkClock().then(() => {
      interval(60000).subscribe(() => this.checkClock());
    });

    this.auth.init();

    this.changeStripeKey();

    this.innerHeight = window.innerHeight;

    this.layout.getHeaderHeight().subscribe((height) => {
      this.headerHeight = this.domSanitizer.bypassSecurityTrustStyle(height);
    });

    this.versionChecker.getVersion().subscribe((v) => {
      this.versionNumber = v.version.toString();
    });

    this.auth.user$.subscribe((user) => (this.user = user));

    this.auth.refreshUser();

    this.auth.getIsSignedIn().subscribe((isLoggedIn) => (this.isLoggedIn = isLoggedIn));
    this.layout.getBrand().subscribe((brand) => this.setBranding(brand));

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });

    const firstPromoterSub = this.route.queryParams.subscribe((params) => {
      if (params['fpr']) {
        try {
          localStorage.setItem('referrerId', params['fpr']);
        } catch (e) {}
      }
    });
    this.subs.push(firstPromoterSub);

    const routerEventsSub = this.router.events.subscribe((ev: any) => {
      this.layout.closeSideNav();
      this.header.showBackButton();

      this.activeModal.close();

      if (ev instanceof NavigationStart && ev.url !== this.lastPoppedUrl) {
        this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
        if (ev.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this.subs.push(routerEventsSub);

    // this.router.events.subscribe(params => {
    //     this.layout.closeSideNav();
    // });

    this.layout.getSideNavOpen().subscribe((open) => {
      if (open) {
        this.sideNavRef.open();
      } else {
        this.sideNavRef.close();
      }
    });

    this.layout.windowSize$.pipe(debounceTime(100)).subscribe((windowSize) => {
      this.windowSize = windowSize;
      console.log('Window Size: ' + windowSize);
    });

    this.subs.push(
      this.layout.headerVisible$.subscribe((showHeader) => (this.headerVisible = showHeader))
    );
  }

  private async checkClock() {
    // this.lprxApi
    //   .time()
    //   .then(time => isBadClock(4, time))
    //   .then(_isBadClock => {
    //     if (_isBadClock) {
    //       this.toastr.error(
    //         "Your device's time is off by more than 4 minutes. This website may not work properly until you correct your time.",
    //         'Important: Time is off!',
    //         { timeOut: 45000 }
    //       );
    //     }
    //   });
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  private setBranding(brand) {
    this.brand = brand;
    if (brand) {
      this.logoUrl = this.cdn.getUrl(this.brand.logoUrl);
      this.showBranding = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout.onWindowResize({
      width: event.target.innerWidth,
      height: event.target.innerHeight,
    });
  }

  private async changeStripeKey() {
    console.log('Setting Key');
    this.stripeService.changeKey(environment.stripe_public_key);
  }

  sideNavClosed() {
    // reset the top of the sidenav
    document.getElementsByTagName('mat-sidenav')[0].scrollTo(0, 0);
  }
}
