import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export function groceryListKeyFromDates(minDate: NgbDateStruct, maxDate: NgbDateStruct) {
  // if no maxDate is provided, use minDate
  if (!maxDate) {
    maxDate = minDate;
  }

  const padZero = (num) => num.toString().padStart(2, '0');
  const minDateString = `${minDate.year}${padZero(minDate.month)}${padZero(minDate.day)}`;
  const maxDateString = `${maxDate.year}${padZero(maxDate.month)}${padZero(maxDate.day)}`;

  return `${minDateString}-${maxDateString}`;
}
