/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/recipe/tool.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class Tool {
  key = '';
  name = '';

  static getAllTools() {
    return [
      { key: 'blender', label: 'Blender' },
      { key: 'cutting_board', label: 'Cutting Board' },
      { key: 'food_processor', label: 'Food Processor' },
      { key: 'slow_cooker', label: 'Instant Pot/Slow Cooker' },
      { key: 'knives', label: 'Knives' },
      { key: 'measuring_equipment', label: 'Measuring Equipment' },
      { key: 'mixing_bowls', label: 'Mixing Bowls' },
      { key: 'saute_pan', label: 'Sauté Pan' },
      { key: 'soup_pot', label: 'Soup Pot' },
      { key: 'steaming_basket', label: 'Steaming Basket' },
      { key: 'utensils', label: 'Utensils' }
    ];
  }

  // @ts-ignore TS7030: Not all code paths return a value
  static getLabel(key: string) {
    const tools = Tool.getAllTools();
    for (const i in tools) {
      if (tools[i].key === key) {
        return tools[i].label;
      }
    }
  }
}
