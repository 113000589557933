import {
  BookCheck, BookDashed,
  CalendarCheck,
  Check,
  ChefHat,
  Copy,
  Edit,
  ExternalLink,
  Home,
  LayoutDashboard,
  LibraryBig,
  LoaderCircle,
  Mail,
  PieChart,
  Plus,
  Settings,
  ShoppingBasket, Tags,
  Trash,
  UserRound,
  Users,
  Utensils,
  X
} from 'lucide-angular';

export let lucideIcons = {
  BookCheck,
  BookDashed,
  CalendarCheck,
  ChefHat,
  Check,
  Copy,
  Edit,
  ExternalLink,
  Home,
  LayoutDashboard,
  LibraryBig,
  LoaderCircle,
  Mail,
  PieChart,
  Plus,
  Settings,
  ShoppingBasket,
  Tags,
  Trash,
  UserRound,
  Users,
  Utensils,
  X
};
