<app-focused-form>
  <app-loading *ngIf="processing"></app-loading>
  <ng-container *ngIf="!processing">
    <ng-container *ngIf="success">
      <div class="text-center">
        <h5>Thank you for verifying your email address!</h5>
        <p>
          Your email address and login was successfully changed to <strong>{{ clr.email }}</strong
          >.
        </p>
        <p>Please login below.</p>
      </div></ng-container
    >
    <ng-container *ngIf="errorMessage">
      <p *ngIf="errorMessage" class="text-danger text-center">
        Sorry, {{ errorMessage }}
      </p></ng-container
    >
    <button (click)="login()" appButton btnSmall fullWidth>Go to Login</button>
  </ng-container>
</app-focused-form>
