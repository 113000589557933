import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home-access-issue',
  templateUrl: './home-access-issue.component.html',
  styleUrls: ['./home-access-issue.component.scss'],
})
export class HomeAccessIssueComponent implements OnInit {
  protected partialEmail: string = '';
  authDomain: string = environment.lprx_auth_www_domain;
  constructor(
    private router: Router,
    private lprxApi: LprxApiProvider,
  ) {}

  ngOnInit() {
    this.lprxApi.get(`v3/dist-partial-email`).then((r) => {
      this.partialEmail = r.partialEmail;
    });
  }

  toPlanner() {
    this.router.navigateByUrl('/planner');
  }
}
