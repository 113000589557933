import { Component, Input, OnInit } from '@angular/core';
import { SimpleNutritionFacts } from '../../../../../lprx-shared-lib/entities/recipe/SimpleNutritionFacts';

@Component({
  selector: 'app-recipe-nutrition-facts-input',
  templateUrl: './recipe-nutrition-facts-input.component.html',
  styleUrls: ['./recipe-nutrition-facts-input.component.scss'],
})
export class RecipeNutritionFactsInputComponent implements OnInit {
  @Input() nutritionFacts = new SimpleNutritionFacts();

  constructor() {}

  ngOnInit() {
    if (!this.nutritionFacts) {
      this.nutritionFacts = new SimpleNutritionFacts();
    }
  }
}
