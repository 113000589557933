import { Component, OnInit } from '@angular/core';
import { HeaderService } from '../../service/header.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  constructor(private header: HeaderService) {}

  ngOnInit() {
    this.header.hideBackButton();
  }
}
