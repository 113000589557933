/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/dto/create-subscription.command.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { IDEMPOTENCY_KEY_PREFIX } from '../utils/constants';
import { createId } from '../utils/id-generator';

export class CreateSubscriptionCommand {
  tokenId?: string;
  priceId: string;
  cancelExisting: boolean;
  idempotencyKey: string;
  couponId?: string;

  // plainToClass chokes on constructors so we a static create method.

  static create(
    tokenId: string,
    planCode: string,
    idempotencyKey: string = createId(IDEMPOTENCY_KEY_PREFIX),
    cancelExisting = true,
    couponId?: string
  ) {
    const command = new CreateSubscriptionCommand();
    command.tokenId = tokenId;
    command.priceId = planCode;
    command.cancelExisting = cancelExisting;
    command.idempotencyKey = idempotencyKey;

    if (couponId) {
      command.couponId = couponId;
    }

    return command;
  }
}
