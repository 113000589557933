import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, from as fromPromise, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { allNutrients } from '../../../lprx-shared-lib/default-nutrients';
import { Contributor } from '../../../lprx-shared-lib/entities/contributor';
import { NutritionFactsDisplay } from '../../../lprx-shared-lib/entities/user/nutrition-facts-display';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { PlannerType } from '../../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { UnitSystem } from '../../../lprx-shared-lib/enum/unit-system';
import { PlannerConfiguration } from '../../../lprx-shared-lib/planner/planner-configuration';
import { minutesToHuman } from '../../../lprx-shared-lib/utils/minutes-to-human';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout/layout.service';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { Element } from '../../../lprx-shared-lib/entities/recipe/Element';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { PlannerService } from '../../planner/planner.service';
import { CdnService } from '../../service/cdn.service';
import { ContributorsService } from '../../service/contributors.service';
import { UnitSystemService } from '../../unit-system-toggle/unit-system-service';
import { daysOfTheWeek } from '../../utilities/days-of-the-week';
import { getCurrentWeekNumber } from '../../utilities/getCurrentWeekNumber';

interface PlannerDay {
  dayName: string;
  weekNumber: string;
}

@Component({
  selector: 'app-recipe-view',
  templateUrl: './recipe-view.component.html',
  styleUrls: ['./recipe-view.component.scss'],
})
export class RecipeViewComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;
  @Input() userServings = 0;
  @Input() card: Card;
  @Input() recipeImageSize: { width: number; height: number } = { width: 300, height: 300 };
  @Input() weekNumber: string;
  @Input() day: string;
  @Input() mealName: string;
  @Input() action: string;

  @Output() servingsChanged = new EventEmitter();

  recipeImageUrl: string;
  prepTime = '';
  totalTime = '';
  contributor: Contributor;
  contributorLogo = '';
  allNutrients = allNutrients;
  showEdit = false;
  notes: SafeHtml;
  sourceLabel: string;
  showNutritionalInfo: boolean;
  addToPlannerDay: PlannerDay = {
    dayName: 'monday',
    weekNumber: getCurrentWeekNumber(),
  };
  addToMealName: string = 'Lunch';
  mealOptions: string[] = ['Breakfast', 'Lunch', 'Dinner', 'Snack'];
  plannerDays = new Map<string, { weekNumber: string; dayName: string }>();
  isOnPlanner: boolean = false;
  controlBarType: string = 'hidden';
  canCustomizePlanner: boolean;
  readyToSave = false;
  showEditButton = false;
  overrideHiddenWhenLeftover = false;
  plannerType = PlannerType.Custom;
  unitSystem: UnitSystem = UnitSystem.Imperial;
  plannerConfig: PlannerConfiguration;

  private subs: Subscription[] = [];

  constructor(
    private activeModal: NgbActiveModal,
    private auth: AuthService,
    private cdn: CdnService,
    private contributorsService: ContributorsService,
    public layout: LayoutService,
    private location: Location,
    private plannerService: PlannerService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private unitSystemService: UnitSystemService
  ) {}

  ngOnInit() {
    this.refreshImage();

    this.notes = this.sanitizer.bypassSecurityTrustHtml(this.recipe.notes);

    if (!this.userServings) {
      this.userServings = this.recipe.servings;
    }

    this.sourceLabel = this.recipe.source.type.toString();
    if (this.sourceLabel === 'self') {
      this.sourceLabel = '';
    }

    this.prepTime = this.toHuman(this.recipe.prepTime);
    this.totalTime = this.toHuman(this.recipe.totalTime);

    if (this.recipe.contributedBy !== '') {
      this.contributorsService.get(this.recipe.contributedBy)?.subscribe((contributor) => {
        if (contributor) {
          this.contributor = contributor;
          this.refreshContributorLogo();
        }
      });
    }

    this.initSubs();
  }

  /**
   *
   */
  private initSubs() {
    this.subs.push(this.usersServingsSub());
    this.subs.push(this.authUserSub());
    this.subs.push(this.weeklyPlanSub());
    this.subs.push(this.routeParamsSub());
    this.subs.push(this.latestRouteParamsAndWeeklyPlanSub());
    this.subs.push(this.canCustomizePlannerSub());
    this.subs.push(this.unitSystemSub());
    this.subs.push(this.plannerConfigSub());
  }

  private usersServingsSub() {
    return this.route.queryParams.subscribe((queryParams) => {
      if (queryParams['servings']) {
        this.userServings = parseInt(queryParams['servings'], 10);
        if (this.userServings === 0) {
          this.userServings = this.recipe.servings;
        }
      }
    });
  }

  private authUserSub() {
    return this.auth.user$.pipe(take(1)).subscribe((user) => {
      this.plannerType = user.plannerType;

      if (user.userType === 'distributor' && this.recipe.ownedBy === user.username) {
        this.showEditButton = true;
      }

      if (user.userType === UserType.Client) {
        this.showNutritionalInfo = user.nutritionFactsDisplay !== NutritionFactsDisplay.Hide;
      }
    });
  }

  private weeklyPlanSub() {
    return this.plannerService.weeklyPlans$.pipe(take(1)).subscribe((weeklyPlans) => {
      if (!weeklyPlans) {
        return;
      }

      if (weeklyPlans[0].mealPlan.nonCalendar) {
        this.addToPlannerDay.weekNumber = weeklyPlans[0].weekNumber;
      }

      for (const weeklyPlan of weeklyPlans) {
        for (const day of daysOfTheWeek) {
          this.plannerDays.set(weeklyPlan.weekNumber + day, {
            dayName: day,
            weekNumber: weeklyPlan.weekNumber,
          });
        }
      }
    });
  }

  private routeParamsSub() {
    return this.route.params.pipe(take(1)).subscribe((params) => {
      if (params['meal']) {
        this.weekNumber = params['weekNumber'];
        this.day = params['day'];
        this.addToMealName = params['meal'];

        this.addToPlannerDay = {
          weekNumber: this.weekNumber,
          dayName: this.day,
        };
      }
    });
  }

  private latestRouteParamsAndWeeklyPlanSub() {
    return combineLatest(this.route.params, this.plannerService.weeklyPlans$).subscribe(
      ([params, weeklyPlans]) => {
        const weekNumber = params['weekNumber'] || this.weekNumber;
        const day = params['day'] || this.day;
        const mealName = params['meal'] || this.mealName;
        if (weeklyPlans && weeklyPlans.length > 0 && mealName) {
          try {
            this.isOnPlanner = this.plannerService.isRecipeOnPlanner(
              this.recipe.id,
              weekNumber,
              day,
              mealName
            );

            if (this.isOnPlanner) {
              this.card = this.plannerService.getCard(weekNumber, day, mealName, this.recipe.id);
            }
          } catch (e) {}
        }

        this.controlBarType = this.isOnPlanner ? 'info' : 'edit';
      }
    );
  }

  private canCustomizePlannerSub() {
    return this.plannerService.canCustomize$.subscribe(
      (canCustomize) => (this.canCustomizePlanner = canCustomize)
    );
  }

  private unitSystemSub() {
    return this.unitSystemService.unitSystem$.subscribe((sys) => (this.unitSystem = sys));
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((plannerConfig) => {
      this.showNutritionalInfo = plannerConfig.showNutritionFacts;
    });
  }

  ngOnDestroy(): void {
    this.plannerService.cardClosed();
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  refreshImage() {
    this.recipeImageUrl = this.cdn.getSizedImageUrl(
      this.recipe.image,
      this.recipeImageSize.width,
      this.recipeImageSize.height
    );
  }

  refreshContributorLogo() {
    this.contributorLogo = this.cdn.getUrl(this.contributor.logo);
  }

  subServing() {
    this.userServings--;
    if (this.userServings <= 0) {
      this.userServings = 1;
    }

    if (!this.isLeftover()) {
      this.servingsChanged.emit(this.userServings);
      this.plannerService.updateServings(this.userServings);
      this.updateRecipeServings();
    }
  }

  private updateRecipeServings() {
    this.card.userServings = this.userServings;
    this.plannerService.weeklyPlanUpdated(this.weekNumber, this.day, this.mealName, this.card);
  }

  private isLeftover() {
    return this.card && this.card.isLeftover;
  }

  addServing() {
    this.userServings++;
    if (!this.isLeftover()) {
      this.servingsChanged.emit(this.userServings);
      this.plannerService.updateServings(this.userServings);
      this.updateRecipeServings();
    }
  }

  percentDaily(amount: number, base: number) {
    return Math.floor((amount / base) * 100) + '%';
  }

  goToUpgrade() {
    this.activeModal.close();
    this.router.navigateByUrl('/account/upgrade');
  }

  cancel() {
    if (this.isOnPlanner) {
      this.controlBarType = 'info';
      this.showEdit = false;
    } else {
      this.location.back();
    }
  }

  /**
   *
   * @param time
   */
  private toHuman(time: number) {
    return minutesToHuman(time);
  }

  showDirections(element: Element) {
    return (
      element.directions &&
      ((element.directions.prep &&
        element.directions.prep.steps &&
        element.directions.prep.steps.length > 0) ||
        (element.directions.make &&
          element.directions.make.steps &&
          element.directions.make.steps.length > 0))
    );
  }

  remove() {
    const confirmation = confirm(`Are you sure you want to remove ${this.recipe.title}?`);
    if (confirmation) {
      this.plannerService
        .removeRecipe(this.recipe.id, this.weekNumber, this.day, this.mealName)
        .subscribe(
          () => {
            this.toastr.success(`${this.recipe.title} removed.`);
            this.navigateBackToPlanner();
          },
          (err) => {
            this.toastr.error(
              `Sorry, there was an issue trying to remove the recipe. ${err.message}`
            );
          }
        );
    }
  }

  /**
   *
   */
  addToPlanner() {
    this.plannerService
      .insertRecipe(
        this.recipe,
        this.userServings,
        this.addToPlannerDay.weekNumber,
        this.addToPlannerDay.dayName,
        this.addToMealName
      )
      .subscribe(
        () => {
          this.toastr.success(`${this.recipe.title} added to planner.`);
          this.navigateBackToPlanner();
        },
        (err) => {
          this.toastr.error(`Recipe could not be added to the planner. ${err.message}`);
        }
      );
  }

  /**
   *
   */
  moveRecipe() {
    const confirmation = confirm(`Are you sure you want to move ${this.recipe.title}?`);
    if (confirmation) {
      this.plannerService
        .moveRecipe(
          this.recipe.id,
          this.weekNumber,
          this.day,
          this.mealName,
          this.addToPlannerDay.weekNumber,
          this.addToPlannerDay.dayName,
          this.addToMealName
        )
        .subscribe(
          () => {
            this.toastr.success(`${this.recipe.title} moved.`);
            this.navigateBackToPlanner();
          },
          (err) => {
            this.toastr.error(
              `Sorry, there was an issue trying to move the recipe. ${err.message}`
            );
          }
        );
    }
  }

  private navigateBackToPlanner() {
    const weekNumber = this.addToPlannerDay.weekNumber;
    const dayName = this.addToPlannerDay.dayName;
    const mealName = this.addToMealName.toLowerCase();
    const url = `/planner/day/${weekNumber}/${dayName}#meal-${mealName}`;
    fromPromise(this.router.navigateByUrl(url)).subscribe(
      () => {},
      () => this.toastr.error('could not navigate back')
    );
  }

  /**
   * @param plannerDay
   */
  setDay(plannerDay: PlannerDay) {
    this.weekNumber = plannerDay.weekNumber;
    this.day = plannerDay.dayName;
  }

  setToMeal(mealOption: string) {
    this.addToMealName = mealOption;
    this.readyToSave = true;
  }

  setMoveToDay(plannerDay: PlannerDay) {
    this.addToPlannerDay = plannerDay;
    this.readyToSave = true;
  }

  toggleIsLeftover() {
    this.card.isLeftover = !this.card.isLeftover;
    if (this.card.isLeftover) {
      this.overrideHiddenWhenLeftover = false;
    }
    // hackish till cleanup ...triggers weekly meal plan save
    this.plannerService.updateServings(this.userServings);
  }
}
