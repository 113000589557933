/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/as-class.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { plainToClass } from 'class-transformer';
// tslint:disable-next-line:no-submodule-imports
import { ClassType } from 'class-transformer/ClassTransformer';

/**
 * Transforms a plain object to an instance of the given class type.
 * If the object is already an instance of the class type, it returns the object as is.
 *
 * @example
 * class User {
 *   id: string;
 *   name: string;
 * }
 *
 * const plainObject = { id: '1', name: 'John' };
 * const userInstance = asClass(User, plainObject);  // Output: User { id: '1', name: 'John' }
 *
 * @template T - The type of the class to transform the object into.
 * @param {ClassType<T>} classType - The class constructor.
 * @param {object} object - The plain object to transform.
 * @returns {T} - An instance of the given class type.
 */
export function asClass<T = any>(classType: ClassType<T>, object: any): T {
  // If the object is already an instance of the class, return it as is
  if (object instanceof classType) {
    // @ts-ignore
    return object;
  }

  // Otherwise, transform the plain object to an instance of the class
  // @ts-ignore
  return plainToClass(classType, object);
}
