/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/Day.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Type } from 'class-transformer';
import { defaultNutrients } from '../../default-nutrients';
import { NutrientsCollection } from '../../recipe/NutrientsCollection';
import { USDAFactsResults } from '../../recipe/USDAFactsResults';
import { Default } from '../../utils/default.decorator';
import { MealPlan } from '../meal-plan/MealPlan';
import { MealPlanMeal } from '../meal-plan/MealPlanMeal';
import { GroceryItem } from '../recipe/grocery-item';
import { Recipe } from '../recipe/Recipe';
import { SimpleNutritionFacts } from '../recipe/SimpleNutritionFacts';
import { Card } from './Card';
import { Meal } from './Meal';

@Exclude()
export class Day {
  @Expose()
  id: number;

  @Expose()
  dayName: string;

  @Expose()
  date: string;

  @Expose()
  @Default([])
  @Type(() => Meal)
  meals: Meal[] = [];

  static fromObject(object: any, mealPlan: MealPlan): Day {
    return plainToClass(Day, object);
    // const day = new Day();
    // day.init(mealPlan);
    // const propertyNames = Object.getOwnPropertyNames(object);
    // for (const property of propertyNames) {
    //   if (property === 'meals') {
    //     for (const mealObj of object['meals']) {
    //       for (const cardObj of mealObj.cards) {
    //         day.getMeal(mealObj.id).addCard(Card.fromObject(cardObj));
    //       }
    //       // day.meals.push(Meal.fromObject(meal))
    //     }
    //   } else if (day[property]) {
    //     day[property] = object[property];
    //   }
    // }
    // return day;
  }

  init(mealPlan: MealPlan) {
    this.meals = [];
    for (const meal of mealPlan.meals) {
      const m = new Meal();
      m.init(meal);
      this.meals.push(m);
    }
  }

  hasMeal(mealIdOrName: string) {
    try {
      this.getMeal(mealIdOrName);
      return true;
    } catch (e) {
      return false;
    }
  }

  getMeal(mealIdOrName: string): Meal {
    for (const meal of this.meals) {
      const isMeal =
        meal.id === mealIdOrName || meal.name.toLowerCase() === mealIdOrName.toLowerCase();
      if (isMeal) {
        return meal;
      }
    }

    throw new Error(`Could not find Meal(${mealIdOrName})`);
  }

  addRecipeToMeal(recipe: Recipe, mealId: string) {
    for (const meal of this.meals) {
      if (meal.id === mealId) {
        return meal.addRecipe(recipe);
      }
    }

    console.log(`Meal(${mealId}) not found...adding meal.`);
    const newMeal = new Meal();
    newMeal.id = mealId;
    this.meals.push(newMeal);
    this.addRecipeToMeal(recipe, mealId);
  }

  removeRecipeFromMeal(recipeId: string, mealId: string): any {
    for (const meal of this.meals) {
      if (meal.id === mealId) {
        return meal.removeRecipe(recipeId);
      }
    }
  }

  build(mealPlan: MealPlan) {
    // console.log(`Building the day meal plan`)
    // console.log(mealPlan);
    // for (let meal of mealPlan.meals) {
    //     let newMeal = new Meal;
    //     newMeal.id = meal.id;
    //     newMeal.name = meal.name;
    //     this.meals.push(newMeal);
    //     console.log(`pushedMeal`)
    // }
  }

  getGroceries(): GroceryItem[] {
    let list: GroceryItem[] = [];
    this.meals.forEach((m) => {
      list = list.concat(m.getGroceries());
    });
    return list;
  }

  getNutritionFacts() {
    const sum = new SimpleNutritionFacts();

    let stats: any[] = [];
    this.meals.forEach((c) => {
      const nf = c.getNutritionFacts();
      stats = stats.concat(nf);
      sum.protein += nf.nutritionFacts.protein;
      sum.calories += nf.nutritionFacts.calories;
      sum.fat += nf.nutritionFacts.fat;
      sum.carbs += nf.nutritionFacts.carbs;
      sum.carbsFromFiber += nf.nutritionFacts.carbsFromFiber;
      sum.carbsFromSugar += nf.nutritionFacts.carbsFromSugar;
    });
    return {
      id: this.id,
      type: 'day',
      nutritionFacts: sum,
      children: stats,
    };
  }

  getUSDAFacts(nutrients: Set<string> = defaultNutrients): USDAFactsResults {
    let stats: USDAFactsResults[] = [];
    const nutrientSum: NutrientsCollection = {};

    for (const meal of this.meals) {
      const usdaFacts = meal.getUSDAFacts(nutrients);
      const mealNutrients = usdaFacts.nutrients;
      stats = stats.concat(usdaFacts);
      for (const nutrient of Object.getOwnPropertyNames(mealNutrients)) {
        if (nutrients && !nutrients.has(nutrient)) {
          continue;
        }
        if (!nutrientSum[nutrient]) {
          nutrientSum[nutrient] = {
            value: 0,
            unit: mealNutrients[nutrient].unit,
            isKnown: mealNutrients[nutrient].isKnown,
          };
        }
        nutrientSum[nutrient].value += mealNutrients[nutrient].value;
      }
    }

    return {
      id: this.id,
      type: 'day',
      nutrients: nutrientSum,
      children: stats,
    };
  }

  getCards() {
    let cards: Card[] = [];
    for (const meal of this.meals) {
      cards = cards.concat(meal.cards);
    }
    return cards;
  }

  getRecipes() {
    let recipes: Recipe[] = [];
    for (const meal of this.meals) {
      recipes = recipes.concat(meal.getRecipes());
    }
    return recipes;
  }

  hasRecipe(recipeId: string, mealName?: string): boolean {
    if (mealName) {
      return this.getMeal(mealName).hasRecipe(recipeId);
    }

    for (const meal of this.meals) {
      if (meal.hasRecipe(recipeId)) {
        return true;
      }
    }

    return false;
  }

  addMeal(mpm: MealPlanMeal) {
    const meal = new Meal();
    meal.init(mpm);
    this.meals.push(meal);
    return meal;
  }
}
