import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Element } from '../../../../../lprx-shared-lib/entities/recipe/Element';

/**
 * Generated class for the RecipeElementInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-element-input',
  templateUrl: 'recipe-element-input.html',
  // providers: [DragulaService]
})
export class RecipeElementInputComponent implements OnInit {
  @Input() element: Element;
  @Input() hasMultipleElements: boolean;

  @Output() deletedElement = new EventEmitter<Element>();

  constructor() {}

  ngOnInit(): void {
    console.log('hasMultipleElements:');
    console.log(this.hasMultipleElements);
  }

  remove() {
    this.deletedElement.emit(this.element);
  }
}
