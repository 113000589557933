export const nutrientTypes = [
  {
    unit: 'g',
    tagname: 'WATER',
    name: 'Water',
    order: 100
  },
  {
    unit: 'kcal',
    tagname: 'ENERC_KCAL',
    name: 'Energy',
    order: 300
  },
  {
    unit: 'kJ',
    tagname: 'ENERC_KJ',
    name: 'Energy',
    order: 400
  },
  {
    unit: 'g',
    tagname: 'PROCNT',
    name: 'Protein',
    order: 600
  },
  {
    unit: 'g',
    tagname: 'FAT',
    name: 'Total lipid (fat)',
    order: 800
  },
  {
    unit: 'g',
    tagname: 'ASH',
    name: 'Ash',
    order: 1000
  },
  {
    unit: 'g',
    tagname: 'CHOCDF',
    name: 'Carbohydrate, by difference',
    order: 1100
  },
  {
    unit: 'g',
    tagname: 'FIBTG',
    name: 'Fiber, total dietary',
    order: 1200
  },
  {
    unit: 'g',
    tagname: 'SUGAR',
    name: 'Sugars, total',
    order: 1500
  },
  {
    unit: 'g',
    tagname: 'SUCS',
    name: 'Sucrose',
    order: 1600
  },
  {
    unit: 'g',
    tagname: 'GLUS',
    name: 'Glucose (dextrose)',
    order: 1700
  },
  {
    unit: 'g',
    tagname: 'FRUS',
    name: 'Fructose',
    order: 1800
  },
  {
    unit: 'g',
    tagname: 'LACS',
    name: 'Lactose',
    order: 1900
  },
  {
    unit: 'g',
    tagname: 'MALS',
    name: 'Maltose',
    order: 2000
  },
  {
    unit: 'g',
    tagname: 'GALS',
    name: 'Galactose',
    order: 2100
  },
  {
    unit: 'g',
    tagname: 'STARCH',
    name: 'Starch',
    order: 2200
  },
  {
    unit: 'mg',
    tagname: 'CA',
    name: 'Calcium, Ca',
    order: 5300
  },
  {
    unit: 'mg',
    tagname: 'FE',
    name: 'Iron, Fe',
    order: 5400
  },
  {
    unit: 'mg',
    tagname: 'MG',
    name: 'Magnesium, Mg',
    order: 5500
  },
  {
    unit: 'mg',
    tagname: 'P',
    name: 'Phosphorus, P',
    order: 5600
  },
  {
    unit: 'mg',
    tagname: 'K',
    name: 'Potassium, K',
    order: 5700
  },
  {
    unit: 'mg',
    tagname: 'NA',
    name: 'Sodium, Na',
    order: 5800
  },
  {
    unit: 'mg',
    tagname: 'ZN',
    name: 'Zinc, Zn',
    order: 5900
  },
  {
    unit: 'mg',
    tagname: 'CU',
    name: 'Copper, Cu',
    order: 6000
  },
  {
    unit: 'mg',
    tagname: 'MN',
    name: 'Manganese, Mn',
    order: 6100
  },
  {
    unit: 'µg',
    tagname: 'SE',
    name: 'Selenium, Se',
    order: 6200
  },
  {
    unit: 'µg',
    tagname: 'FLD',
    name: 'Fluoride, F',
    order: 6240
  },
  {
    unit: 'mg',
    tagname: 'VITC',
    name: 'Vitamin C, total ascorbic acid',
    order: 6300
  },
  {
    unit: 'mg',
    tagname: 'THIA',
    name: 'Thiamin',
    order: 6400
  },
  {
    unit: 'mg',
    tagname: 'RIBF',
    name: 'Riboflavin',
    order: 6500
  },
  {
    unit: 'mg',
    tagname: 'NIA',
    name: 'Niacin',
    order: 6600
  },
  {
    unit: 'mg',
    tagname: 'PANTAC',
    name: 'Pantothenic acid',
    order: 6700
  },
  {
    unit: 'mg',
    tagname: 'VITB6A',
    name: 'Vitamin B-6',
    order: 6800
  },
  {
    unit: 'µg',
    tagname: 'FOL',
    name: 'Folate, total',
    order: 6900
  },
  {
    unit: 'µg',
    tagname: 'FOLAC',
    name: 'Folic acid',
    order: 7000
  },
  {
    unit: 'µg',
    tagname: 'FOLFD',
    name: 'Folate, food',
    order: 7100
  },
  {
    unit: 'µg',
    tagname: 'FOLDFE',
    name: 'Folate, DFE',
    order: 7200
  },
  {
    unit: 'mg',
    tagname: 'CHOLN',
    name: 'Choline, total',
    order: 7220
  },
  {
    unit: 'mg',
    tagname: 'BETN',
    name: 'Betaine',
    order: 7270
  },
  {
    unit: 'µg',
    tagname: 'VITB12',
    name: 'Vitamin B-12',
    order: 7300
  },
  {
    unit: 'µg',
    tagname: '',
    name: 'Vitamin B-12, added',
    order: 7340
  },
  {
    unit: 'µg',
    tagname: 'VITA_RAE',
    name: 'Vitamin A, RAE',
    order: 7420
  },
  {
    unit: 'µg',
    tagname: 'RETOL',
    name: 'Retinol',
    order: 7430
  },
  {
    unit: 'µg',
    tagname: 'CARTB',
    name: 'Carotene, beta',
    order: 7440
  },
  {
    unit: 'µg',
    tagname: 'CARTA',
    name: 'Carotene, alpha',
    order: 7450
  },
  {
    unit: 'µg',
    tagname: 'CRYPX',
    name: 'Cryptoxanthin, beta',
    order: 7460
  },
  {
    unit: 'IU',
    tagname: 'VITA_IU',
    name: 'Vitamin A, IU',
    order: 7500
  },
  {
    unit: 'µg',
    tagname: 'LYCPN',
    name: 'Lycopene',
    order: 7530
  },
  {
    unit: 'µg',
    tagname: 'LUT+ZEA',
    name: 'Lutein + zeaxanthin',
    order: 7560
  },
  {
    unit: 'mg',
    tagname: 'TOCPHA',
    name: 'Vitamin E (alpha-tocopherol)',
    order: 7900
  },
  {
    unit: 'mg',
    tagname: '',
    name: 'Vitamin E, added',
    order: 7920
  },
  {
    unit: 'mg',
    tagname: 'TOCPHB',
    name: 'Tocopherol, beta',
    order: 8000
  },
  {
    unit: 'mg',
    tagname: 'TOCPHG',
    name: 'Tocopherol, gamma',
    order: 8100
  },
  {
    unit: 'mg',
    tagname: 'TOCPHD',
    name: 'Tocopherol, delta',
    order: 8200
  },
  {
    unit: 'mg',
    tagname: 'TOCTRA',
    name: 'Tocotrienol, alpha',
    order: 8300
  },
  {
    unit: 'mg',
    tagname: 'TOCTRB',
    name: 'Tocotrienol, beta',
    order: 8400
  },
  {
    unit: 'mg',
    tagname: 'TOCTRG',
    name: 'Tocotrienol, gamma',
    order: 8500
  },
  {
    unit: 'mg',
    tagname: 'TOCTRD',
    name: 'Tocotrienol, delta',
    order: 8600
  },
  {
    unit: 'µg',
    tagname: 'VITD',
    name: 'Vitamin D (D2 + D3)',
    order: 8700
  },
  {
    unit: 'µg',
    tagname: 'ERGCAL',
    name: 'Vitamin D2 (ergocalciferol)',
    order: 8710
  },
  {
    unit: 'µg',
    tagname: 'CHOCAL',
    name: 'Vitamin D3 (cholecalciferol)',
    order: 8720
  },
  {
    unit: 'IU',
    tagname: 'VITD',
    name: 'Vitamin D',
    order: 8750
  },
  {
    unit: 'µg',
    tagname: 'VITK1',
    name: 'Vitamin K (phylloquinone)',
    order: 8800
  },
  {
    unit: 'µg',
    tagname: 'VITK1D',
    name: 'Dihydrophylloquinone',
    order: 8900
  },
  {
    unit: 'µg',
    tagname: 'MK4',
    name: 'Menaquinone-4',
    order: 8950
  },
  {
    unit: 'g',
    tagname: 'FASAT',
    name: 'Fatty acids, total saturated',
    order: 9700
  },
  {
    unit: 'g',
    tagname: 'F4D0',
    name: '4:0',
    order: 9800,
    hide: true /// do for all ratios later
  },
  {
    unit: 'g',
    tagname: 'F6D0',
    name: '6:0',
    order: 9900
  },
  {
    unit: 'g',
    tagname: 'F8D0',
    name: '8:0',
    order: 10000
  },
  {
    unit: 'g',
    tagname: 'F10D0',
    name: '10:0',
    order: 10100
  },
  {
    unit: 'g',
    tagname: 'F12D0',
    name: '12:0',
    order: 10300
  },
  {
    unit: 'g',
    tagname: 'F13D0',
    name: '13:0',
    order: 10400
  },
  {
    unit: 'g',
    tagname: 'F14D0',
    name: '14:0',
    order: 10500
  },
  {
    unit: 'g',
    tagname: 'F15D0',
    name: '15:0',
    order: 10600
  },
  {
    unit: 'g',
    tagname: 'F16D0',
    name: '16:0',
    order: 10700
  },
  {
    unit: 'g',
    tagname: 'F17D0',
    name: '17:0',
    order: 10800
  },
  {
    unit: 'g',
    tagname: 'F18D0',
    name: '18:0',
    order: 10900
  },
  {
    unit: 'g',
    tagname: 'F20D0',
    name: '20:0',
    order: 11100
  },
  {
    unit: 'g',
    tagname: 'F22D0',
    name: '22:0',
    order: 11200
  },
  {
    unit: 'g',
    tagname: 'F24D0',
    name: '24:0',
    order: 11300
  },
  {
    unit: 'g',
    tagname: 'FAMS',
    name: 'Fatty acids, total monounsaturated',
    order: 11400
  },
  {
    unit: 'g',
    tagname: 'F14D1',
    name: '14:1',
    order: 11500
  },
  {
    unit: 'g',
    tagname: 'F15D1',
    name: '15:1',
    order: 11600
  },
  {
    unit: 'g',
    tagname: 'F16D1',
    name: '16:1 undifferentiated',
    order: 11700
  },
  {
    unit: 'g',
    tagname: 'F16D1C',
    name: '16:1 c',
    order: 11800
  },
  {
    unit: 'g',
    tagname: 'F16D1T',
    name: '16:1 t',
    order: 11900
  },
  {
    unit: 'g',
    tagname: 'F17D1',
    name: '17:1',
    order: 12000
  },
  {
    unit: 'g',
    tagname: 'F18D1',
    name: '18:1 undifferentiated',
    order: 12100
  },
  {
    unit: 'g',
    tagname: 'F18D1C',
    name: '18:1 c',
    order: 12200
  },
  {
    unit: 'g',
    tagname: 'F18D1T',
    name: '18:1 t',
    order: 12300
  },
  {
    unit: 'g',
    tagname: 'F18D1TN7',
    name: '18:1-11 t (18:1t n-7)',
    order: 12310
  },
  {
    unit: 'g',
    tagname: 'F20D1',
    name: '20:1',
    order: 12400
  },
  {
    unit: 'g',
    tagname: 'F22D1',
    name: '22:1 undifferentiated',
    order: 12500
  },
  {
    unit: 'g',
    tagname: 'F22D1C',
    name: '22:1 c',
    order: 12600
  },
  {
    unit: 'g',
    tagname: 'F22D1T',
    name: '22:1 t',
    order: 12700
  },
  {
    unit: 'g',
    tagname: 'F24D1C',
    name: '24:1 c',
    order: 12800
  },
  {
    unit: 'g',
    tagname: 'FAPU',
    name: 'Fatty acids, total polyunsaturated',
    order: 12900
  },
  {
    unit: 'g',
    tagname: 'F18D2',
    name: '18:2 undifferentiated',
    order: 13100
  },
  {
    unit: 'g',
    tagname: 'F18D2CN6',
    name: '18:2 n-6 c,c',
    order: 13200
  },
  {
    unit: 'g',
    tagname: 'F18D2CLA',
    name: '18:2 CLAs',
    order: 13300
  },
  {
    unit: 'g',
    tagname: 'F18D2TT',
    name: '18:2 t,t',
    order: 13600
  },
  {
    unit: 'g',
    tagname: '',
    name: '18:2 i',
    order: 13700
  },
  {
    unit: 'g',
    tagname: '',
    name: '18:2 t not further defined',
    order: 13800
  },
  {
    unit: 'g',
    tagname: 'F18D3',
    name: '18:3 undifferentiated',
    order: 13900
  },
  {
    unit: 'g',
    tagname: 'F18D3CN3',
    name: '18:3 n-3 c,c,c (ALA)',
    order: 14000
  },
  {
    unit: 'g',
    tagname: 'F18D3CN6',
    name: '18:3 n-6 c,c,c',
    order: 14100
  },
  {
    unit: 'g',
    tagname: '',
    name: '18:3i',
    order: 14200
  },
  {
    unit: 'g',
    tagname: 'F18D4',
    name: '18:4',
    order: 14250
  },
  {
    unit: 'g',
    tagname: 'F20D2CN6',
    name: '20:2 n-6 c,c',
    order: 14300
  },
  {
    unit: 'g',
    tagname: 'F20D3',
    name: '20:3 undifferentiated',
    order: 14400
  },
  {
    unit: 'g',
    tagname: 'F20D3N3',
    name: '20:3 n-3',
    order: 14500
  },
  {
    unit: 'g',
    tagname: 'F20D3N6',
    name: '20:3 n-6',
    order: 14600
  },
  {
    unit: 'g',
    tagname: 'F20D4',
    name: '20:4 undifferentiated',
    order: 14700
  },
  {
    unit: 'g',
    tagname: 'F20D4N6',
    name: '20:4 n-6',
    order: 14900
  },
  {
    unit: 'g',
    tagname: 'F20D5',
    name: '20:5 n-3 (EPA)',
    order: 15000
  },
  {
    unit: 'g',
    tagname: 'F21D5',
    name: '21:5',
    order: 15100
  },
  {
    unit: 'g',
    tagname: 'F22D4',
    name: '22:4',
    order: 15160
  },
  {
    unit: 'g',
    tagname: 'F22D5',
    name: '22:5 n-3 (DPA)',
    order: 15200
  },
  {
    unit: 'g',
    tagname: 'F22D6',
    name: '22:6 n-3 (DHA)',
    order: 15300
  },
  {
    unit: 'g',
    tagname: 'FATRN',
    name: 'Fatty acids, total trans',
    order: 15400
  },
  {
    unit: 'g',
    tagname: 'FATRNM',
    name: 'Fatty acids, total trans-monoenoic',
    order: 15500
  },
  {
    unit: 'g',
    tagname: 'FATRNP',
    name: 'Fatty acids, total trans-polyenoic',
    order: 15600
  },
  {
    unit: 'mg',
    tagname: 'CHOLE',
    name: 'Cholesterol',
    order: 15700
  },
  {
    unit: 'mg',
    tagname: 'PHYSTR',
    name: 'Phytosterols',
    order: 15800
  },
  {
    unit: 'mg',
    tagname: 'STID7',
    name: 'Stigmasterol',
    order: 15900
  },
  {
    unit: 'mg',
    tagname: 'CAMD5',
    name: 'Campesterol',
    order: 16000
  },
  {
    unit: 'mg',
    tagname: 'SITSTR',
    name: 'Beta-sitosterol',
    order: 16200
  },
  {
    unit: 'g',
    tagname: 'TRP_G',
    name: 'Tryptophan',
    order: 16300
  },
  {
    unit: 'g',
    tagname: 'THR_G',
    name: 'Threonine',
    order: 16400
  },
  {
    unit: 'g',
    tagname: 'ILE_G',
    name: 'Isoleucine',
    order: 16500
  },
  {
    unit: 'g',
    tagname: 'LEU_G',
    name: 'Leucine',
    order: 16600
  },
  {
    unit: 'g',
    tagname: 'LYS_G',
    name: 'Lysine',
    order: 16700
  },
  {
    unit: 'g',
    tagname: 'MET_G',
    name: 'Methionine',
    order: 16800
  },
  {
    unit: 'g',
    tagname: 'CYS_G',
    name: 'Cystine',
    order: 16900
  },
  {
    unit: 'g',
    tagname: 'PHE_G',
    name: 'Phenylalanine',
    order: 17000
  },
  {
    unit: 'g',
    tagname: 'TYR_G',
    name: 'Tyrosine',
    order: 17100
  },
  {
    unit: 'g',
    tagname: 'VAL_G',
    name: 'Valine',
    order: 17200
  },
  {
    unit: 'g',
    tagname: 'ARG_G',
    name: 'Arginine',
    order: 17300
  },
  {
    unit: 'g',
    tagname: 'HISTN_G',
    name: 'Histidine',
    order: 17400
  },
  {
    unit: 'g',
    tagname: 'ALA_G',
    name: 'Alanine',
    order: 17500
  },
  {
    unit: 'g',
    tagname: 'ASP_G',
    name: 'Aspartic acid',
    order: 17600
  },
  {
    unit: 'g',
    tagname: 'GLU_G',
    name: 'Glutamic acid',
    order: 17700
  },
  {
    unit: 'g',
    tagname: 'GLY_G',
    name: 'Glycine',
    order: 17800
  },
  {
    unit: 'g',
    tagname: 'PRO_G',
    name: 'Proline',
    order: 17900
  },
  {
    unit: 'g',
    tagname: 'SER_G',
    name: 'Serine',
    order: 18000
  },
  {
    unit: 'g',
    tagname: 'HYP',
    name: 'Hydroxyproline',
    order: 18100
  },
  {
    unit: 'g',
    tagname: 'ALC',
    name: 'Alcohol, ethyl',
    order: 18200
  },
  {
    unit: 'mg',
    tagname: 'CAFFN',
    name: 'Caffeine',
    order: 18300
  },
  {
    unit: 'mg',
    tagname: 'THEBRN',
    name: 'Theobromine',
    order: 18400
  }
];

export const nutrientTypesMap = new Map();
for (const nutrientType of nutrientTypes) {
  nutrientTypesMap.set(nutrientType.tagname, nutrientType);
}
