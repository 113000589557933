import { Pipe, PipeTransform } from '@angular/core';
import { sourceDisplayLabel } from '../../lprx-shared-lib/source-display-label';

@Pipe({
  name: 'sourceDisplay',
})
export class SourceDisplayPipe implements PipeTransform {
  transform(value: string, lineBreak = false): any {
    return sourceDisplayLabel(value, lineBreak);
  }
}
