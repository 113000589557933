import { plainToClass } from 'class-transformer';
import { DistributorAccessLevel } from '../../../lprx-shared-lib/distributor-access-level';
import { GetUploadUrlRequest } from '../../../lprx-shared-lib/dto/get-upload-url.request';
import { DistributorType } from '../../../lprx-shared-lib/entities/distributor-type';
import { ChangeDistributorTypesDto } from '../../../lprx-shared-lib/resources/change-distributor-types-dto';
import { ChangeFileAccessLevelDto } from '../../../lprx-shared-lib/resources/change-file-access-level-dto';
import { FileDownloadUrlResponse } from '../../../lprx-shared-lib/resources/dto/file-download-url.response';
import { GetUploadUrlResponse } from '../../../lprx-shared-lib/resources/get-upload-url.response';
import { ResourceFile } from '../../../lprx-shared-lib/resources/resource-file';
import { FileId } from '../../../lprx-shared-lib/utils/types';
import { LprxApi } from '../api';

export class ResourceFiles {
  constructor(private readonly lprxApi: LprxApi) {}

  /**
   *
   * @param filename
   * @param contentType
   */
  async getUploadUrl(filename: string, contentType: string): Promise<GetUploadUrlResponse> {
    const payload: GetUploadUrlRequest = { filename, contentType };
    return this.lprxApi
      .post('v3/resources/upload-url', payload)
      .then((data: object) => plainToClass(GetUploadUrlResponse, data));
  }

  publish(id: FileId) {
    return this.lprxApi.patch(`v3/resources/file/${id}/publish`);
  }

  unpublish(id: FileId) {
    return this.lprxApi.patch(`v3/resources/file/${id}/unpublish`);
  }

  getDownloadUrl(id: FileId): Promise<FileDownloadUrlResponse> {
    return this.lprxApi
      .get(`v3/resources/file/${id}/url`)
      .then((data) => plainToClass(FileDownloadUrlResponse, data as object));
  }

  delete(id: FileId) {
    return this.lprxApi.delete(`v3/resources/file/${id}`);
  }

  rename(id: FileId, name: any): Promise<ResourceFile> {
    name = name.replace(/[^ A-Za-z0-9-_!.)(']/, '-');
    return this.lprxApi
      .patch(`v3/resources/file/${id}/name`, { name })
      .then((file) => plainToClass(ResourceFile, file as object));
  }

  changeAccessLevel(id: FileId, distributorAccessLevel: DistributorAccessLevel) {
    const dto: ChangeFileAccessLevelDto = plainToClass(ChangeFileAccessLevelDto, {
      distributorAccessLevel,
    });
    return this.lprxApi
      .patch(`v3/resources/file/${id}/access`, dto)
      .then((file) => plainToClass(ResourceFile, file as object));
  }

  changeDistributorTypes(id: FileId, types: DistributorType[]) {
    const dto: ChangeDistributorTypesDto = {
      types,
    };
    return this.lprxApi.patch(`v3/resources/file/${id}/distributor-types`, dto);
  }
}
