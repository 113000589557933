import { Pipe, PipeTransform } from '@angular/core';
import { dayAsAbbrv } from '../../lprx-shared-lib/day-as-abbrv';
import { Case } from '../../lprx-shared-lib/utils/case';

@Pipe({
  name: 'dayAsAbbrv'
})
export class DayAsAbbrvPipe implements PipeTransform {
  transform(day: any, asCase: Case | string = Case.Upper): any {
    return dayAsAbbrv(day, asCase);
  }
}
