<main role="main" class="container py-3 my-md-5 py-md-5">
  <app-loading *ngIf="loading"></app-loading>

  <div *ngIf="!loading" class="row">
    <div class="col-lg-5 mx-auto">
      <div class="card">
        <div class="px-md-4 py-5" *ngIf="distributor">
          <div class="text-center pb-5">
            <img [src]="logo" style="max-width: 80%" alt="{{ distributor.business }}" />
          </div>

          <form *ngIf="viewState === 'activate'" #passwordForm="ngForm" (submit)="activate($event)">
            <h4>Activate your {{ distributor.business }} Meal Plan by setting your password.</h4>
            <hr />
            <ul>
              <li
                [class.text-success]="isPasswordLongEnough"
                [class.text-danger]="password && !isPasswordLongEnough"
              >
                At least 6 Characters
              </li>

              <li
                [class.text-success]="doesPasswordHaveUppercase"
                [class.text-danger]="password && !doesPasswordHaveUppercase"
              >
                At least 1 Uppercase character
              </li>

              <li
                [class.text-success]="doesPasswordHaveLowercase"
                [class.text-danger]="password && !doesPasswordHaveLowercase"
              >
                At least 1 Lowercase character
              </li>

              <li
                [class.text-success]="doesPasswordHaveNumber"
                [class.text-danger]="password && !doesPasswordHaveNumber"
              >
                At least 1 Number
              </li>
            </ul>
            <div *ngIf="error" class="alert alert-danger">
              {{ error.message }}
            </div>

            <input type="hidden" name="email" [(ngModel)]="email" />

            <div class="form-group">
              <label for="inputPassword" class="-sr-only">Password</label>
              <input
                type="password"
                [(ngModel)]="password"
                (change)="validatePassword()"
                (keyup)="validatePassword()"
                id="inputPassword"
                name="password"
                placeholder="Password"
                required
                class="form-control mb-3"
              />
            </div>
            <div class="form-group">
              <label for="confirmPassword" class="-sr-only">Repeat Password</label>
              <input
                type="password"
                [(ngModel)]="passwordConfirm"
                (change)="validatePassword()"
                (keyup)="validatePassword()"
                name="confirmPassword"
                id="confirmPassword"
                placeholder="Repeat Password"
                class="form-control mb-3"
                required=""
              />
            </div>

            <!--
                        [(ngModel)]="termsAgreement"
                        -->
            <div class="form-group form-check">
              <label class="form-check-label" for="termsAgreement">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="termsAgreement"
                  name="termsAgreement"
                  required
                  [(ngModel)]="termsAgreement"
                />

                I agree to Living Plate Rx's
                <a href="/terms" target="_blank">Terms of Use</a> and
                <a href="/privacy" target="_blank">Privacy Policy</a>.</label
              >
            </div>

            <!--<div class="form-group">-->
            <!--To log in, you'll use your newly created password along with your email address,-->
            <!--<strong>{{bundleClient.email}}</strong>.-->
            <!--&lt;!&ndash;<span title="{{bundleClient.email}}"&ndash;&gt;-->
            <!--&lt;!&ndash;style="border-bottom: 1px dashed #999">email address</span>.&ndash;&gt;-->
            <!--</div>-->

            <div class="text-right">
              <button
                appButton
                type="submit"
                [disabled]="!passwordForm.form.valid || !isPasswordValid || !termsAgreement"
              >
                Activate
              </button>
            </div>
          </form>

          <ng-container *ngIf="viewState == 'success'">
            <h4>Bravo!</h4>
            <hr />
            <p>
              You activated your {{ distributor.business }} Meal Plan and you're on your way to some
              delicious healthy food.
            </p>
            <p>
              In the future, you'll use your email address,
              <strong>{{ email }}</strong
              >, and your newly created password to log in.
            </p>

            <p>
              A Welcome email is on its way to you with some more information. There is no need to
              check it now, let's head to your Meal Plan.
            </p>

            <div style="display: none">
              <input type="text" name="username" [(ngModel)]="email" />
              <input type="password" name="password" [(ngModel)]="password" />
            </div>
            <br />
            <p class="text-center">
                <a [href]='magicLink' appButton>Onward to My Meal Plan</a>

            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</main>
