import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { BillingAccountServiceInterface } from '../billing-account-service-interface';

@Injectable()
export class DistributorAccountBillingService implements BillingAccountServiceInterface {
  constructor(private apiV2: ApiV2) {}

  getPaymentMethods(): Observable<any[]> {
    return this.apiV2.get$('distributor/account/billing/payment-methods');
  }
}
