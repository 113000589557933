/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/prime/commands/prime-purchase-command.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { IsString, ValidateIf } from 'class-validator';
import {
  DefaultPrimePurchaseSource,
  PrimePurchaseSource,
} from '../../enum/prime-purchase-source.type';
import { Default } from '../../utils/default.decorator';

@Exclude()
export class PrimePurchaseCommand {
  @Expose()
  @IsString()
  planId: string;

  @Expose()
  @ValidateIf((object) => !!object.promoCode)
  @IsString()
  promoCode?: string;

  @Expose()
  @IsString()
  source: string;

  @Expose()
  @Default(DefaultPrimePurchaseSource)
  @IsString()
  purchaseSource?: PrimePurchaseSource = DefaultPrimePurchaseSource;

  @Expose()
  referrer?: string;

  @Expose()
  referringUrl?: string;
}
