import { plainToClass } from 'class-transformer';
import { RecipeSearchResultInterface } from '../../app/model/recipe-search-result.interface';
import { FavoriteRecipes } from '../../lprx-shared-lib/entities/favorite-recipes';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';
import { NutrientLimitsFilter } from '../../lprx-shared-lib/interface/nutrient-limits.filter';
import { LprxApi } from './api';
import { Ingredient } from '../../lprx-shared-lib/entities/recipe/Ingredient';
import { GetIngredientsDto } from '../../lprx-shared-lib/dto/get-ingredients-dto';
import { ConvertIngredientsResult } from '../../lprx-shared-lib/dto/convert-ingredients-result';
import { PractitionerTag, PractitionerTags } from '../../lprx-shared-lib/recipe/practitioner-tags';
import { Result } from '../../lprx-shared-lib/result';

export interface SearchParams {
  mealPlanId?: string;
  term?: string;
  from?: number;
  limit?: number;
  mealTypes?: string[];
  includeDrafts?: boolean;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  nutrientLimits?: NutrientLimitsFilter;
  onlyFavorites?: boolean;
  includeUser?: boolean;
  onlyOwnRecipes?: boolean;
}

export class Recipes {
  constructor(private readonly lprxApi: LprxApi) {}

  async search(searchParams: SearchParams): Promise<RecipeSearchResultInterface> {
    const {
      mealPlanId,
      term,
      from = 0,
      limit = 20,
      mealTypes,
      includeDrafts = false,
      sortBy,
      sortOrder = 'asc',
      nutrientLimits,
      onlyFavorites = false,
      includeUser = false,
      onlyOwnRecipes = false,
    } = searchParams;

    console.log(searchParams);

    const queryParams: any = {};
    if (mealPlanId) {
      queryParams.meal_plan_id = mealPlanId;
    }
    if (term) {
      queryParams.term = term;
    }
    if (limit) {
      queryParams.limit = limit;
    }
    if (from) {
      queryParams.from = from;
    }
    if (mealTypes) {
      queryParams.meal_types = mealTypes;
    }

    if (includeDrafts) {
      queryParams.include_drafts = true;
    }

    if (sortBy) {
      queryParams.sort_by = sortBy;
      queryParams.sort_order = sortOrder;
    }

    if (nutrientLimits) {
      queryParams.nutrient_limits = JSON.stringify(nutrientLimits);
    }

    if (onlyFavorites) {
      queryParams.favorites = true;
    }

    if (onlyOwnRecipes) {
      queryParams.only_user_recipes = true;
    }

    if (includeUser) {
      queryParams.include_dist_recipes = true;
    }

    return this.lprxApi
      .get(`v3/recipes/search`, queryParams)
      .then((results: RecipeSearchResultInterface) => {
        results.recipes = plainToClass(Recipe, results.recipes);
        return results;
      });
  }

  favorites(clientId?: string): Promise<FavoriteRecipes> {
    return this.lprxApi
      .get<FavoriteRecipes>('v3/recipes/favorites', clientId ? { client_id: clientId } : null)
      .then((p) => plainToClass(FavoriteRecipes, p));
  }

  like(recipeId: string, clientId?: string): Promise<FavoriteRecipes> {
    return this.lprxApi
      .post<FavoriteRecipes>(
        `v3/recipes/${recipeId}/like`,
        clientId ? { client_id: clientId } : null
      )
      .then((p) => plainToClass(FavoriteRecipes, p));
  }

  unlike(recipeId: string, clientId?: string): Promise<FavoriteRecipes> {
    let path = `v3/recipes/${recipeId}/like`;
    if (clientId) {
      path += '?client_id=' + clientId;
    }
    return this.lprxApi.delete<FavoriteRecipes>(path).then((p) => plainToClass(FavoriteRecipes, p));
  }

  delete(recipeId: string) {
    return this.lprxApi.delete(`v3/recipes/${recipeId}`);
  }

  getIngredients(
    ingredientInputs: string[],
    useRaw: boolean = false
  ): Promise<{
    ingredients: Ingredient[];
    errors: Array<{ err_code: number; original_text: string; warning: string }>;
  }> {
    return this.lprxApi.post<ConvertIngredientsResult, GetIngredientsDto>(
      'v3/recipes/ingredients',
      {
        ingredients: ingredientInputs,
        useRaw,
      }
    );
  }

  deleteTag(tag: string) {
    return this.lprxApi.delete(`v3/recipes/tags/${tag}`);
  }

  getTags() {
    return this.lprxApi.get<Result<PractitionerTags>>('v3/recipes/tags');
  }

  addTag(newTag: string) {
    return this.lprxApi.post<Result<PractitionerTags>>('v3/recipes/tags', { tag: newTag });
  }

  tagRecipe(tag: PractitionerTag, recipe: Recipe) {
    return this.lprxApi.post<Result<PractitionerTags>>(`v3/recipes/${recipe.id}/tags`, {
      id: tag.id,
    });
  }

  untagRecipe(tag: PractitionerTag, recipe: Recipe) {
    return this.lprxApi.delete<Result<PractitionerTags>>(`v3/recipes/${recipe.id}/tags/${tag.id}`);
  }

  updateTag(tag: PractitionerTag) {
    return this.lprxApi.put<Result<PractitionerTags>>(`v3/recipes/tags/${tag.id}`, {
      tag: tag.name,
    });
  }
}
