/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/bundle-client.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Transform } from 'class-transformer';
import { sanitizeEmail } from '../sanitize-email';
import { Default } from '../utils/default.decorator';
import { createId } from '../utils/id-generator';
import { IdPrefix } from '../utils/id-prefix';
import { BaseItem } from './base-item';
import { PlannerType } from './weeky-meal-plan/PlannerType';

export const BUNDLE_CLIENT_PREFIX = 'dbc';

export enum BundleClientStatus {
  Pending = 'pending',
  Activated = 'activated'
}

export enum ActivationEmailStatus {
  Pending = 'pending',
  Processed = 'processed',
  Deferred = 'deferred',
  Delivered = 'delivered',
  Bounced = 'bounced',
  Opened = 'opened',
  Clicked = 'clicked',
  Spammed = 'spammed'
}

@Exclude()
export class BundleClient implements BaseItem {
  // partition key
  @Expose()
  @Default(() => createId(BUNDLE_CLIENT_PREFIX))
  id = createId(BUNDLE_CLIENT_PREFIX);

  @Expose()
  bundleId = '';

  @Expose()
  distributorId = '';

  @Expose()
  firstName = '';

  @Expose()
  lastName = '';

  @Expose()
  @Transform(value => (typeof value === 'string' ? value.toLowerCase().trim() : ''))
  // @IsEmail()
  email: string;

  @Expose()
  @Default(() => createId(IdPrefix.User))
  userId: string = createId(IdPrefix.User);

  /**
   * timestamp in milliseconds
   */
  @Expose()
  @Default(() => Date.now)
  createdAt: number = Date.now();

  /**
   * timestamp in milliseconds
   */
  @Expose()
  @Default(0)
  activatedAt = 0;

  @Expose()
  status: BundleClientStatus = BundleClientStatus.Pending; // pending / activated

  /**
   * timestamp in milliseconds
   */
  @Expose()
  @Default(0)
  startsAt = 0; // when the user is eligible to see the meal plan

  @Expose()
  @Default(0)
  duration = 0; // number of months prepaid

  @Expose()
  activationEmailMessageId = '';

  @Expose()
  mealPlanId = '';

  /**
   * pending / processed / deferred / delivered / bounced / opened / clicked / spammed?
   */
  @Expose()
  activationEmailStatus: ActivationEmailStatus = ActivationEmailStatus.Pending;

  @Expose()
  @Default(PlannerType.Custom)
  plannerType = PlannerType.Custom;

  @Expose()
  isDeleted = false;

  @Expose()
  @Default(() => Date.now)
  modifiedAt: number = Date.now();

  /**
   * @param firstName
   * @param lastName
   * @param email
   */
  static create(firstName: string, lastName: string, email: string) {
    const bc = new BundleClient();
    bc.id = createId(BUNDLE_CLIENT_PREFIX);
    bc.firstName = firstName;
    bc.lastName = lastName;
    bc.email = sanitizeEmail(email);
    return bc;
  }

  markActivated() {
    this.activatedAt = Date.now();
    this.status = BundleClientStatus.Activated;
  }
}
