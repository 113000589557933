/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/commands/folder-rename-dto.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { IsString, Length, ValidateIf } from 'class-validator';

@Exclude()
export class FolderRenameDto {
  @Expose()
  @IsString()
  @Length(1)
  name: string;
}
