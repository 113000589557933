<!-- Generated template for the RecipeEditorComponent component -->

<main role="main" class="container py-4">
  <!--    <div *ngIf="user && user.userType == 'distributor'" class="alert alert-warning">-->
  <!--        <h5><i class="fa fa-warning"></i> Notice</h5>-->
  <!--        <p>-->
  <!--            Access to the recipe editor is available to everyone until June 21, 2019. After that, you'll need to be an-->
  <!--            active practitioner for access.-->
  <!--        </p>-->
  <!--        <p>-->
  <!--            The recipe editor is available, at no charge, to all active distributors. An active practitioner is defined-->
  <!--            as having, on average, one paying subscriber per month.-->
  <!--            If you have a client subscribed to a plan, you'll have access to the recipe editor for every month that-->
  <!--            their subscription is active.-->
  <!--        </p>-->
  <!--        <strong>Other examples:</strong>-->
  <!--        <ul>-->
  <!--            <li>-->
  <!--                <p>-->
  <!--                    If you have 12 paying subscribers in one month, either retail or bundled, you have access to the-->
  <!--                    recipe editor for a full year.-->
  <!--                </p>-->
  <!--            </li>-->
  <!--            <li>-->
  <!--                <p>-->
  <!--                    If you have a recurring personal account that you bundled, then you will have access as long as that-->
  <!--                    paying account is active.-->
  <!--                </p>-->
  <!--            </li>-->
  <!--        </ul>-->

  <!--        <p>-->
  <!--            If you have any questions, please email us at-->
  <!--            <a href="mailto:help@livingplaterx.com?subject=Recipe Editor Access"-->
  <!--               style="color: #856404; text-decoration: underline;">help@livingplaterx.com</a>.-->
  <!--        </p>-->

  <!--    </div>-->

  <div class="card">
    <div class="card-header">
      <h4>Recipe Editor</h4>
    </div>
    <div class="card-body">
      <form *ngIf="showForm && user && recipe" (submit)="saveRecipe()" #recipeForm="ngForm">
        <div class="form-group">
          <div class="row">
            <div class="col-12">
              <h5>Title</h5>
              <input
                class="form-control"
                [(ngModel)]="recipe.title"
                required
                type="text"
                autocorrect="off"
                name="title"
              />
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-4">
                  <label>Recipe Serves</label>
                </div>
                <div class="col-8">
                  <div class="row no-gutters align-items-center">
                    <div class="col-4">
                      <input
                        class="form-control"
                        [(ngModel)]="recipe.servings"
                        name="servings"
                        min="1"
                        max="999"
                        type="number"
                        (change)="updatedServings()"
                        autocorrect="off"
                        autocapitalize="none"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-4">Prep Time</div>
                <div class="col-8">
                  <div class="row no-gutters align-items-center">
                    <div class="col-4">
                      <input
                        class="form-control"
                        type="number"
                        [(ngModel)]="prepHours"
                        (keyup)="calcPrepTime()"
                        (blur)="calcPrepTime()"
                        name="prepHours"
                        min="0"
                      />
                    </div>
                    <div class="col-2">&nbsp;hrs</div>
                    <div class="col-4">
                      <input
                        class="form-control"
                        type="number"
                        [(ngModel)]="prepMinutes"
                        (keyup)="calcPrepTime()"
                        (blur)="calcPrepTime()"
                        name="prepMinutes"
                        min="0"
                      />
                    </div>
                    <div class="col-2">&nbsp;mins</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row align-items-center">
                <div class="col-4">Total Time</div>
                <div class="col-8">
                  <div class="row no-gutters align-items-center">
                    <div class="col-4">
                      <input
                        class="form-control"
                        type="number"
                        [(ngModel)]="totalHours"
                        (keyup)="calcTotalTime()"
                        (blur)="calcTotalTime()"
                        name="totalHours"
                        min="0"
                      />
                    </div>
                    <div class="col-2">&nbsp;hrs</div>
                    <div class="col-4">
                      <input
                        class="form-control"
                        type="number"
                        [(ngModel)]="totalMinutes"
                        (keyup)="calcTotalTime()"
                        (blur)="calcTotalTime()"
                        name="totalMinutes"
                        min="0"
                      />
                    </div>
                    <div class="col-2">&nbsp;mins</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-4">Meal Types</div>
                <div class="col-8">
                  <app-recipe-meal-type-input
                    [mealTypes]="recipe.mealTypes"
                    (updated)="updated($event)"
                  ></app-recipe-meal-type-input>
                </div>
              </div>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group">
              <label class="recipe-image-label" for="recipeImageFile">
                <div class="photo-change">Click to Change Image</div>
                <div
                  *ngIf="recipeImage"
                  class="recipe-image"
                  [style.background-image]="'url('+ recipeImage +')'"
                ></div>
              </label>
              <input
                id="recipeImageFile"
                type="file"
                (change)="uploadFromFile($event)"
                style="visibility: hidden"
              />
            </div>
          </div>
        </div>

        <hr />
        <br />

        <app-recipe-elements-input [recipe]="recipe"></app-recipe-elements-input>
        <br />
        <br />
        <br />

        <app-nutrition-facts-label
          *ngIf="(layout.showNutritionalInfo$ | async)"
          [nutrients]="nutrients"
          [servings]="recipe.servings"
        >
        </app-nutrition-facts-label>

        <br />

        <ng-container *ngIf="user.userType === 'admin'">
          <p class="text-center">
            <button
              type="button"
              (click)="recipe.isUsdaComplete = !recipe.isUsdaComplete"
              class="btn"
              [class.btn-outline-success]="recipe.isUsdaComplete"
              [class.btn-outline-danger]="!recipe.isUsdaComplete"
            >
              <i class="fa fa-check text-success" *ngIf="recipe.isUsdaComplete"></i>
              <i class="fa fa-close text-danger" *ngIf="!recipe.isUsdaComplete"></i>
              USDA Completed
            </button>
          </p>

          <br />
          <br />
          <app-recipe-nutrition-facts-input [nutritionFacts]="recipe.simpleNutritionFacts">
          </app-recipe-nutrition-facts-input>
        </ng-container>

        <br />
        <hr />
        <br />

        <app-recipe-equipment-input
          [tools]="recipe.equipment"
          (updatedEquipment)="onUpdatedEquipment($event)"
        ></app-recipe-equipment-input>

        <br />
        <hr />
        <br />

        <div class="form-group recipe-notes">
          <h5>Notes</h5>
          <br />
          <div
            *ngIf="froalaOptions"
            [froalaEditor]="froalaOptions"
            [(froalaModel)]="recipe.notes"
          ></div>
          <!--<textarea class="form-control" [(ngModel)]="recipe.notes" name="notes"></textarea>-->
        </div>

        <ng-container *ngIf="user.userType !== 'user'">
          <br />
          <hr />
          <br />

          <app-recipe-meal-plans-input
            [mealPlans]="recipe.forMealPlans"
            (updatedMealPlans)="onUpdatedMealPlans($event)"
          ></app-recipe-meal-plans-input>
        </ng-container>

<!--        <ng-container *ngIf="user.userType == 'distributor'">-->
<!--          <br />-->
<!--          <hr />-->
<!--          <br />-->

<!--          <h5>Tags</h5>-->
<!--          <p>-->
<!--            Tag this recipe to categorize it in your Recipe Box. You can enter multiple tags by-->
<!--            pressing the Enter key.-->
<!--          </p>-->
<!--          <br />-->
<!--          <div class="tag-input-wrapper">-->
<!--            <mat-form-field class="tag-chip-list">-->
<!--              <mat-chip-grid #chipList>-->
<!--                <mat-chip-row-->
<!--                  *ngFor="let tag of recipe.tags"-->
<!--                  [selectable]="selectable"-->
<!--                  [removable]="removable"-->
<!--                  (removed)="remove(tag)"-->
<!--                >-->
<!--                  {{tag}}-->
<!--                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
<!--                </mat-chip-row>-->
<!--                <input-->
<!--                  [matChipInputFor]="chipList"-->
<!--                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--                  [matChipInputAddOnBlur]="addOnBlur"-->
<!--                  (matChipInputTokenEnd)="add($event)"-->
<!--                />-->
<!--              </mat-chip-grid>-->
<!--            </mat-form-field>-->
<!--          </div>-->
<!--        </ng-container>-->

        <br />
        <hr />
        <br />

        <app-recipe-source-input [source]="recipe.source"></app-recipe-source-input>

        <ng-container *ngIf="user.userType == 'admin'">
          <br />
          <hr />
          <br />
          <div class="form-group">
            <h5>Contributor</h5>
            <br />
            <app-recipe-contributor-input
              [contributedBy]="recipe.contributedBy"
              (updatedContributor)="onUpdatedContributor($event)"
            ></app-recipe-contributor-input>
          </div>
        </ng-container>

        <ng-container *ngIf="user.userType === 'distributor'">
          <br />
          <div class="alert alert-warning">
            <h5>
              <i class="fa fa-warning"></i>
              Copyright Alert
            </h5>

            <div class="form-check">
              <input
                type="checkbox"
                [(ngModel)]="recipe.copyrightAcknowledged"
                name="have-permission"
                class="form-check-input"
                id="have-permission"
              />
              <label class="form-check-label" for="have-permission">
                I acknowledged that this recipe is my own, or I have permission from the recipe
                author to use and distribute this recipe in my meal plans.
              </label>
            </div>
          </div>
        </ng-container>

        <br />
        <br />

        <div class="form-row align-items-center">
          <div class="col">
            <button type="button" btnDanger (click)="delete()">Delete Recipe</button>
          </div>

          <ng-container *ngIf="user.userType != 'user'">
            <div class="col-auto text-right">
              <label for="recipe.status" class="col-form-label">Status:</label>
            </div>

            <div class="col-auto">
              <select
                id="recipe.status"
                class="form-control"
                name="recipe.status"
                [(ngModel)]="recipe.status"
              >
                <option [value]="'draft'">Draft</option>
                <option [value]="'published'">Published</option>
              </select>
            </div>
          </ng-container>

          <div class="col-auto">
            <button appButton [disabled]="!recipeForm.form.valid">Save Recipe</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!--    <app-recipe-view [recipe]="recipe" [userServings]="0"></app-recipe-view>-->
</main>
