import { CdkDrag, CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { classToClass } from 'class-transformer';
// import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { from as fromPromise, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { dayAsLong } from '../../../lprx-shared-lib/day-as-long';
import { MealLocation } from '../../../lprx-shared-lib/entities/meal-location';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { Note } from '../../../lprx-shared-lib/entities/weeky-meal-plan/note';
import { PlannerType } from '../../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { PlannerConfiguration } from '../../../lprx-shared-lib/planner/planner-configuration';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { AuthService } from '../../auth.service';
import { isTablet } from '../../is-tablet';
import { RecipeResult } from '../../model/entities/recipe-result';
import { CardType } from '../../model/enum/card-type';
import { getNutrient } from '../../utilities/get-nutrient';
import { DropService } from '../drop.service';
import { MealHeight, PlannerLayoutService } from '../planner-layout.service';
import { PlannerService } from '../planner.service';
import { RecipeToInsertDetails } from '../recipe-to-insert-details';
import { dragDropDelay } from './is-touch-enabled';
import { NoteModalService } from './note-modal.service';
import { Meal } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Meal';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';

declare const Chart: any;

@Component({
  selector: 'app-planner-meal',
  templateUrl: './planner-meal.component.html',
  styleUrls: ['./planner-meal.component.scss'],
})
export class PlannerMealComponent implements OnInit, OnDestroy {
  @Input() meal: Meal;
  @Input() dayName: string;
  @Input() weekNumber: string;

  recipeInsertDetails: RecipeToInsertDetails;

  // todo: transitioning from above to this
  @Input()
  mealLocation: MealLocation;

  ticks = 0;

  v: string = '';

  private interval: any;

  mealHeight = 0;

  canAddNote = false;

  readonly CardType = CardType;

  private isChartInitialize = false;
  hasChart: boolean = false;
  showNutritionalFacts = true;
  usdaFacts;
  canEditMeal: boolean = true;
  private subs: Subscription[] = [];
  private plannerConfig: PlannerConfiguration;

  isDragEmpty: boolean;

  dragDropDelayTimeout = dragDropDelay();
  isTablet = isTablet();
  removeDragHandle: boolean = false;

  constructor(
    private auth: AuthService,
    // private dragulaService: DragulaService,
    private el: ElementRef,
    private plannerLayoutService: PlannerLayoutService,
    private ref: ChangeDetectorRef,
    private plannerService: PlannerService,
    private noteModalService: NoteModalService,
    private toastr: ToastrService,
    private dropService: DropService,
  ) {}

  ngOnInit() {
    this.mealLocation = new MealLocation(this.weekNumber, this.dayName, this.meal.name);

    if (!this.meal.cards) {
      this.meal.cards = [];
    }

    this.subs.push(
      this.authSub(),
      this.recipeToInsertSub(),
      this.plannerLayoutServiceSub(),
      this.weeklyPlansSub(),
      this.plannerConfigSub(),
    );
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((config) => {
      this.plannerConfig = config;
      this.showNutritionalFacts = config.showNutritionFacts;
      this.usdaFacts = this.meal.getUSDAFacts(config.nutrients);
      this.canEditMeal = config.canEditMeals;
    });
  }

  private weeklyPlansSub() {
    return this.plannerService.plannerUpdated$.subscribe((wp) => {
      this.plannerService.plannerConfig$
        .pipe(
          // tap((config) => console.log(config)),
          take(1),
        )
        .subscribe((config) => {
          // console.log(this.dayName, this.meal.name, this.meal.cards);
          this.usdaFacts = this.meal.getUSDAFacts(config.nutrients);
        });
      this.isChartInitialize = false;
    });
  }

  private plannerLayoutServiceSub() {
    return this.plannerLayoutService
      .getMaxMealHeight()
      .pipe(
        filter((mh: MealHeight) => {
          return (
            mh &&
            mh.weekNumber === this.weekNumber &&
            mh.mealId === this.meal.id &&
            mh.height > this.mealHeight
          );
        }),
      )
      .subscribe((mh) => {
        console.log(mh);
        this.el.nativeElement.querySelector('.cards').style.minHeight = `${mh.height}px`;
      });
  }

  private recipeToInsertSub() {
    return this.plannerService.recipeToInsert$.subscribe((recipeInsertDetails) => {
      this.recipeInsertDetails = recipeInsertDetails;
    });
  }

  private authSub() {
    return this.auth.user$.subscribe((user) => {
      this.canAddNote =
        user.userType === UserType.Distributor ||
        (user.userType === UserType.Client && user.plannerType === PlannerType.Custom);
    });
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
    clearInterval(this.interval);
  }

  /**
   * Listener for child component PlannerCardComponent.removeCard
   *
   * @param card
   */
  onRemoveCard(card: Card) {
    const index = this.meal.cards.indexOf(card);
    if (index !== -1) {
      this.meal.cards.splice(index, 1);
      this.onChanged(card);
    }
  }

  private onChanged(card: Card) {
    this.plannerService.weeklyPlanUpdated(this.weekNumber, this.dayName, this.meal.name, card);
    this.ticks = Math.random();
  }

  createNote() {
    const modalRef = this.noteModalService.openCreateOrEdit(this.mealLocation);

    fromPromise(modalRef.result).subscribe((note: Note) => {
      const card = new Card();
      card.type = CardType.Note;
      card.note = note;
      this.meal.addCard(card);
      this.onChanged(card);
    });
  }

  drawChart($event: MouseEvent) {
    if (
      this.isChartInitialize ||
      !this.showNutritionalFacts ||
      this.meal.cards.length === 0 ||
      this.dropService.isDragging
    ) {
      return;
    }

    this.usdaFacts = this.meal.getUSDAFacts(this.plannerConfig.nutrients);
    const facts = this.usdaFacts;
    const fat = getNutrient(facts, 'FAT').value * 9;
    const carbs = getNutrient(facts, 'CHOCDF').value * 4;
    const protein = getNutrient(facts, 'PROCNT').value * 4;
    console.log(`${this.meal.name} - f:${fat} c:${carbs} p:${protein}`);

    if (fat + carbs + protein === 0) {
      this.hasChart = false;
      return;
    }

    this.isChartInitialize = true;
    this.hasChart = true;

    const chartElement: HTMLCanvasElement = document.getElementById(
      `chart-${this.weekNumber}-${this.dayName}-${this.meal.name}`,
    ) as HTMLCanvasElement;

    const ctx = chartElement.getContext('2d');

    const chart = new Chart(ctx, {
      // The type of chart we want to create
      type: 'pie',

      // The data for our dataset
      data: {
        datasets: [
          {
            data: [fat, carbs, protein],
            backgroundColor: ['#f4b5b5', '#bee1e8', '#c9db8d'],
            borderWidth: 1,
          },
        ],
      },

      // Configuration options go here
      options: {
        animation: { duration: 0 },

        hover: { animationDuration: 0 },
        responsiveAnimationDuration: 0,
        layout: { padding: 0 },
        legend: { display: false },
        tooltips: { enabled: false, intersect: false },
        events: [],
      },
    });
  }

  insertRecipe() {
    this.meal.addRecipe(this.recipeInsertDetails.recipe);
    const msg =
      this.recipeInsertDetails.recipe.title +
      ' added to ' +
      this.meal.name +
      ' on ' +
      dayAsLong(this.dayName);
    this.toastr.success(msg);

    const close = !this.recipeInsertDetails.stayOpen;
    if (close) {
      this.plannerService.clearInsertRecipe();
    }
    this.plannerService.saveWeeklyPlan(this.weekNumber);
  }

  drop($event: {
    currentIndex: number;
    item: CdkDrag<
      Recipe | RecipeResult | { card: Card; meal: Meal; weekNumber: string; dayName: string }
    >;
  }) {
    console.log($event);

    const recipeOrCard = $event.item.data;
    if (recipeOrCard instanceof Recipe || recipeOrCard instanceof RecipeResult) {
      // from search results
      const card = Card.newRecipeCard(recipeOrCard);
      this.meal.addCard(card);
      this.onChanged(card);
    } else {
      // from another meal
      const clonedCard = classToClass(recipeOrCard.card);
      // If card is from the same meal, then add it to the new location
      //  and remove it from the old location (sorting teh cards)
      // otherwise, add a clone of the card to the new location at index
      const isSameMeal =
        recipeOrCard.meal.id === this.meal.id &&
        recipeOrCard.weekNumber === this.weekNumber &&
        recipeOrCard.dayName === this.dayName;

      // remove the card from the old location
      if (isSameMeal) {
        const indexToRemove = this.meal.cards.indexOf(recipeOrCard.card);
        this.meal.cards.splice(indexToRemove, 1);
      } else {
        this.plannerService
          .getWeeklyPlan(recipeOrCard.weekNumber)
          .getDay(recipeOrCard.dayName)
          .getMeal(recipeOrCard.meal.name)
          .removeCard(recipeOrCard.card);
      }

      this.meal.cards.splice($event.currentIndex, 0, clonedCard);
      this.removeDuplicateRecipes();
      this.onChanged(clonedCard);
    }
  }

  private removeDuplicateRecipes() {
    // remove duplicate cards by card.recipe.id from the meal.cards
    this.meal.cards = this.meal.cards.filter((card, index, self) => {
      // if not a recipe card, then keep it
      if (card.type !== CardType.Recipe) {
        return true;
      }

      // if it is a recipe card, then keep it if it is the first one
      return (
        index ===
        self.findIndex((c) => {
          if (c.type !== CardType.Recipe) {
            return false;
          }
          return c.recipe.id === card.recipe.id;
        })
      );
    });
  }

  dragData(card: Card) {
    return { card, meal: this.meal, weekNumber: this.weekNumber, dayName: this.dayName };
  }

  dragStarted($event: CdkDragStart) {
    this.dropService.isDragging = true;
    $event.source.element.nativeElement.style.opacity = '0.2';
    if (this.meal.cards.length === 1) {
      this.isDragEmpty = true;
    }
  }

  dragEnded($event: CdkDragEnd) {
    this.dropService.isDragging = false;
    this.isDragEmpty = false;
    $event.source.element.nativeElement.style.opacity = '1';
  }
}
