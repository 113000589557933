/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/ElementDirections.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Type } from 'class-transformer';
import { Directions } from './Directions';

@Exclude()
export class ElementDirections {
  @Expose()
  @Type(() => Directions)
  make: Directions = new Directions();

  @Expose()
  @Type(() => Directions)
  prep: Directions = new Directions();

  static fromObject(object: any): ElementDirections {
    return plainToClass(ElementDirections, object);
  }
}
