/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Directions.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Type } from 'class-transformer';
import { Default } from '../../utils/default.decorator';
import { Step } from './Step';

@Exclude()
export class Directions {
  @Expose()
  minutes: number;

  @Expose()
  @Type(() => Step)
  @Default([])
  steps: Step[] = [];

  static fromObject(object: any): Directions {
    return plainToClass(Directions, object);
  }
}
