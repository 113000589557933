/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/favorite-recipes.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { Default } from '../utils/default.decorator';
import { BaseItem } from './base-item';
import { Recipe } from './recipe/Recipe';

@Exclude()
export class FavoriteRecipes {
  @Expose()
  @Default([])
  recipeIds: string[] = [];

  private ensure() {
    if (!this.recipeIds) {
      this.recipeIds = [];
    }
  }

  add(recipe: Pick<Recipe, 'id'>) {
    this.ensure();
    const set = new Set(this.recipeIds);
    set.add(recipe.id);
    this.recipeIds = Array.from(set);
  }

  del(recipe: Pick<Recipe, 'id'>) {
    this.ensure();
    const set = new Set(this.recipeIds);
    set.delete(recipe.id);
    this.recipeIds = Array.from(set);
  }

  is(recipeId: string): boolean {
    return this.recipeIds.includes(recipeId);
  }
}
