import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class ConvertToSimpleEntryService {
  private convertToSimpleEntrySubject = new Subject<boolean>();
  convertToSimpleEntry$ = this.convertToSimpleEntrySubject.asObservable();

  constructor() {}

  convertToSimpleEntry() {
    this.convertToSimpleEntrySubject.next(true);
  }
}
