import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { PlannerConfiguration } from '../../../lprx-shared-lib/planner/planner-configuration';
import { USDAFactsResults } from '../../../lprx-shared-lib/recipe/USDAFactsResults';
import { AuthService } from '../../auth.service';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { CdnService } from '../../service/cdn.service';
import { getNutrient } from '../../utilities/get-nutrient';
import { dragDropDelay } from '../planner-meal/is-touch-enabled';
import { PlannerService } from '../planner.service';
import { RecipeOpenerService } from '../recipe-opener.service';

@Component({
  selector: 'app-recipe-search-result',
  templateUrl: './recipe-search-result.component.html',
  styleUrls: ['./recipe-search-result.component.scss'],
})
export class RecipeSearchResultComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  imageUrl: SafeUrl;
  user: User;
  private subs: Subscription[] = [];

  canView = false;
  isUsersRecipe = false;
  isDistributorsRecipe = false;
  plannerConfig: PlannerConfiguration;
  calories: number = 0;
  protein: number = 0;
  carbs: number = 0;
  fat: number = 0;
  private usdaFacts: USDAFactsResults;

  constructor(
    private recipeOpener: RecipeOpenerService,
    private plannerService: PlannerService,
    private auth: AuthService,
    private cdn: CdnService
  ) {}

  ngOnInit() {
    this.imageUrl = this.cdn.getSafeStyleUrl(this.recipe.image, 60, 60);

    this.subs.push(
      this.auth.getUser().subscribe((user) => {
        this.user = user;
        this.isUsersRecipe = this.user.username === this.recipe.ownedBy;
        this.isDistributorsRecipe = this.user.distributorId === this.recipe.ownedBy;
      })
    );

    const plannerConfigSub = this.plannerService.plannerConfig$.subscribe((config) => {
      this.usdaFacts = this.recipe.getUSDAFacts(config.nutrients);
      this.plannerConfig = config;
    });
    this.subs.push(plannerConfigSub);

    // this.usdaFacts = this.recipe.getUSDAFacts(config.nutrients);
    this.calories = Math.round(getNutrient(this.usdaFacts, 'ENERC_KCAL').value);
    this.fat = Math.round(getNutrient(this.usdaFacts, 'FAT').value);
    this.carbs = Math.round(getNutrient(this.usdaFacts, 'CHOCDF').value);
    this.protein = Math.round(getNutrient(this.usdaFacts, 'PROCNT').value);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  openRecipe() {
    this.recipeOpener.openRecipe(this.recipe);
  }
}
