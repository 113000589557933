<div class="p-4">
  <form class="form-signin" (submit)="signUp()" #signUpForm="ngForm">
    <div class="row">
      <div class="col-10">
        <h4>{{ heading }}</h4>
        {{ subheading }}
      </div>
      <div class="col-2 text-right">
        <a (click)="closeForm()"><i class="fa fa-close"></i></a>
      </div>
    </div>
    <hr />

    <h5>Choose Planner Type</h5>

    <div class="row">
      <div
        *ngIf="plans.custom"
        class="col-md-6 plan-option"
        [class.selected]="plannerType === PlannerType.Custom"
      >
        <button type="button" (click)="selectCustomPlan()">
          <strong>Customizable</strong>
          <br />

          <div
            *ngIf="showMeTheMoney"
            [innerHTML]="netPrice(plans.custom.monthly.price, 'c') + ' per month'"
          ></div>
        </button>
      </div>

      <div
        *ngIf="plans.static"
        class="col-md-6 plan-option"
        [class.selected]="plannerType === PlannerType.Static"
      >
        <button type="button" (click)="selectStaticPlan()">
          <strong>Static</strong>
          <br />
          <div
            *ngIf="showMeTheMoney"
            [innerHTML]="netPrice(plans.static.monthly.price, 's') + ' per month'"
          ></div>
        </button>
      </div>
    </div>

    <br />
    <br />

    <h5>Account Information</h5>

    <div class="row">
      <div class="col-md-6">
        <label for="firstName" class="-sr-only">First Name</label>
        <input
          type="text"
          [(ngModel)]="userDetails.firstName"
          class="form-control mb-3"
          placeholder="First Name"
          id="firstName"
          required=""
          autofocus
          name="userDetails.firstName"
        />
      </div>
      <div class="col-md-6">
        <label for="lastName" class="-sr-only">Last Name</label>
        <input
          type="text"
          [(ngModel)]="userDetails.lastName"
          class="form-control mb-3"
          placeholder="Last Name"
          id="lastName"
          required=""
          name="userDetails.lastName"
        />
      </div>
    </div>

    <label for="inputEmail" class="-sr-only">Email address</label>
    <input
      type="email"
      [(ngModel)]="userDetails.email"
      id="inputEmail"
      class="form-control mb-3"
      placeholder="Email address"
      required=""
      name="userDetails.email"
    />

    <div class="row">
      <div class="col-sm-6">
        <label for="inputPassword" class="-sr-only">Password</label>
        <input
          type="password"
          [(ngModel)]="userDetails.password"
          id="inputPassword"
          (change)="validatePassword()"
          (keyup)="validatePassword()"
          class="form-control mb-3"
          placeholder="Password"
          required=""
          name="userDetails.password"
        />
      </div>
      <div class="col-sm-6">
        <label for="passwordConfirm" class="-sr-only">Confirm Password</label>
        <input
          type="password"
          [(ngModel)]="passwordConfirm"
          id="passwordConfirm"
          (change)="validatePassword()"
          (keyup)="validatePassword()"
          class="form-control mb-3"
          placeholder="Password"
          required=""
          name="passwordConfirm"
        />
      </div>
    </div>

    <em>
      <span [class.text-success]="isPasswordValid">At least </span>

      <span
        [class.text-success]="isPasswordLongEnough"
        [class.text-danger]="userDetails.password && !isPasswordLongEnough"
      >
        6 Characters,
      </span>

      <span
        [class.text-success]="doesPasswordHaveUppercase"
        [class.text-danger]="userDetails.password && !doesPasswordHaveUppercase"
      >
        1 Uppercase,
      </span>

      <span
        [class.text-success]="doesPasswordHaveLowercase"
        [class.text-danger]="userDetails.password && !doesPasswordHaveLowercase"
      >
        1 Lowercase,
      </span>

      <span
        [class.text-success]="doesPasswordHaveNumber"
        [class.text-danger]="userDetails.password && !doesPasswordHaveNumber"
      >
        1 Number
      </span>

      <span
        [class.text-success]="doesPasswordMatch"
        [class.text-danger]="userDetails.password && !doesPasswordMatch"
      >
        and confirmed.
      </span>
    </em>

    <br />
    <br />

    <div [hidden]="!showMeTheMoney">
      <h5>Payment Information</h5>

      <div #paymentElement id="payment-element">
        <!-- Elements will create form elements here -->
      </div>

      <!--      <hr />-->

      <!--      <app-credit-card-form #ccForm [showCardSelect]="false"></app-credit-card-form>-->
      <br />
    </div>

    <div class="form-group form-check">
      <label class="form-check-label" for="termsAgreement">
        <input
          type="checkbox"
          class="form-check-input"
          id="termsAgreement"
          name="termsAgreement"
          required
          [(ngModel)]="termsAgreement"
        />
        <strong>
          I agree to Living Plate Rx's
          <!--suppress HtmlUnknownTarget -->
          <a href="/terms" target="_blank">Terms of Use</a> and
          <!--suppress HtmlUnknownTarget -->
          <a href="/privacy" target="_blank">Privacy Policy</a>.
        </strong>
      </label>
    </div>

    <br />

    <div *ngIf="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>

    <div class="row">
      <div class="col-12">
        <ng-container *ngIf="!showPromoCodeApplied">
          <ng-container *ngIf="!showPromoCodeInput">
            <p class='tw-text-center'>
              <a
                *ngIf="!showPromoCodeInput"
                (click)="showPromoCodeInput = true"
                class="border-bottom-dashed text-success"
                >I have a promo code.</a
              >
            </p>
          </ng-container>

          <ng-container *ngIf="showPromoCodeInput">
            <div class="form-row mb-3">
              <div class="col-7">
                <label class="sr-only" for="promoCode">Enter Promo Code</label>
                <input
                  [(ngModel)]="promoCode"
                  id="promoCode"
                  name="promoCode"
                  placeholder="Enter promo code."
                  class="form-control"
                />
              </div>
              <div class="col-5">
                <button (click)="setSignUpCost()" type="button" appButton btnSmall fullWidth>
                  Apply
                </button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="promoCodeError">
            <p class="text-danger">{{ promoCodeError }}</p>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="showPromoCodeApplied">
          <p>
            <strong class="text-success">{{ promoCodeMessage }}</strong>
          </p>
        </ng-container>
      </div>
      <div class="col-12 text-right">
        <button
          appButton
          fullWidth
          btnXLarge
          class="pay-btn"
          type="submit"
          [disabled]="!signUpForm.form.valid || !isPasswordValid || isProcessing"
          [innerHTML]="
            isProcessing
              ? 'Just a moment.'
              : 'Sign Up ' + (showMeTheMoney ? this.signUpCost : 'Now')
          "
        ></button>
      </div>
    </div>
  </form>
</div>
