import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Recipe } from '../../../../../../lprx-shared-lib/entities/recipe/Recipe';

@Component({
  selector: 'app-switch-to-simple-entry-dialog',
  templateUrl: 'switch-to-simple-entry-dialog.html',
})
export class SwitchToSimpleEntryDialog {
  constructor(
    public dialogRef: MatDialogRef<SwitchToSimpleEntryDialog, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: { recipe: Recipe }
  ) {}

  cancelConvertRecipes() {
    this.dialogRef.close(false);
  }

  convertRecipe() {
    this.dialogRef.close(true);
  }
}

// because I'm lazy I'm just going to put this here while developing
