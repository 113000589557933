import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-code',
  templateUrl: './forgot-password-code.component.html',
  styleUrls: ['./forgot-password-code.component.scss']
})
export class ForgotPasswordCodeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
