/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/default.decorator.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Transform, TransformationType } from 'class-transformer';

export function Default(
  defaultValue: any | ((value: any, obj: any, transformationType: TransformationType) => any)
) {
  return Transform((value: any, obj: any, transformationType: TransformationType) =>
    value === undefined || value === null || (typeof value === 'string' && value.trim() === '')
      ? defaultValue instanceof Function
        ? defaultValue(value, obj, transformationType)
        : defaultValue
      : value
  );
}
