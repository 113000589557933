import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from '../../../providers/aws.api';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  public code: string;
  public username: string;

  error: Error;

  constructor(private route: ActivatedRoute, private router: Router) {
    this.route.params.subscribe(params => {
      console.log(params);
      this.username = params.username;
    });
  }

  confirm() {
    this.error = null;
    Auth.confirmSignUp(this.username, this.code)
      .then(() => {
        this.router.navigate(['/login', this.username]);
      })
      .catch(err => {
        this.error = err;
        console.log('confirm error', err);
      });
  }

  resendCode() {
    this.error = null;
    Auth.resendSignUp(this.username)
      .then(() => console.log('sent'))
      .catch(err => {
        this.error = err;
        console.log('send code error', err);
      });
  }

  ngOnInit() {}
}
