const isClear = window.location.href.match(new RegExp('\\/clear$'));
const isLogin = window.location.href.match(new RegExp('\\/login$'));
const isForgot = window.location.href.match(new RegExp('\\/forgot-password$'));
if (isClear || isLogin || isForgot) {
  window.localStorage.clear();
  window.sessionStorage.clear();
  document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  if (isClear) {
    window.location.href = 'https://' + window.location.host + '/login';
  }
}

if (!document.referrer.match(window.location.host) && document.referrer) {
  sessionStorage.setItem('referringUrl', document.referrer);
}

import { amplifyInit } from './aws_exports_init';
import { ieVersion } from './ie-version';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import './polyfills';

const ieVer = ieVersion();
if (ieVer > 0 && ieVer <= 11) {
  document.write(
    '<html><body><br><h6 style="text-align: center">Sorry, Internet Explorer is not supported by Living Plate Rx.</h6></body></html>'
  );
} else {
  if (environment.production) {
    enableProdMode();
  }

  amplifyInit().then(() => {
    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch((err) => console.log(err));
  });
}

function pageShowListener(event) {
  if (event.persisted) {
    // This page was restored from the bfcache--reload site
    window.location.reload();
  }
}

window.addEventListener('pageshow', pageShowListener);
