import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PipesModule } from '../pipe/pipes.module';
import { NutritionFactsLabelComponent } from './nutrition-facts-label.component';

@NgModule({
  imports: [CommonModule, PipesModule],
  declarations: [NutritionFactsLabelComponent],
  exports: [NutritionFactsLabelComponent]
})
export class NutritionFactsLabelModule {}
