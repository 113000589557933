/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/enum/source-type.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum SourceType {
  Adapted = 'adapted',
  Inspired = 'inspired',
  From = 'from',
  ContributedBy = 'contributed',
  None = ''
}
