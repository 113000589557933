import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiV3 } from '../../providers/api.v3';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';

@Injectable()
export class MealPlansService {
  constructor(private apiV3: ApiV3, private lprxApi: LprxApiProvider) {}

  /**
   *
   * @param mealPlanId
   */
  findOne(mealPlanId: string): Observable<MealPlan> {
    return this.apiV3.get(`meal-plans/${mealPlanId}`).pipe(map(MealPlan.fromObject));
  }

  /**
   *
   * @param mealPlan
   */
  save(mealPlan: MealPlan) {
    return this.apiV3.post(`meal-plans`, mealPlan);
  }

  categories() {
    return this.lprxApi.mealPlans.categories();
  }
}
