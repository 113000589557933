<div class="text-center col-4 offset-4">


  <div *ngIf="error" class="alert alert-danger">
    {{error.message}}
  </div>

  <form (submit)="confirm()">

    <label floating>Confirmation Code</label>
    <input type="text" [(ngModel)]="code" name="code">

    <br>

    <button class="btn" block>Confirm Account</button>

    <br>

    <p>Haven't received the confirmation code email yet?
      <a href="javascript:" (click)="resendCode()">Resend</a>
    </p>

  </form>

</div>