/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/convert-units.ts
 *  --------------------------
 *  - Swagger items commented out
 */

// @ts-ignore
const volumes = require('convert-units/lib/definitions/volume');

import * as convert from 'convert-units';

volumes.imperial['Tbsp'] = {
  name: {
    singular: 'Tablespoon',
    plural: 'Tablespoons',
  },
  to_anchor: 1 / 2,
};

volumes.imperial['tbsp'] = {
  name: {
    singular: 'Tablespoon',
    plural: 'Tablespoons',
  },
  to_anchor: 1 / 2,
};

export { convert as convertUnits };
