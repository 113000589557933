<ng-container *ngIf="show">


    <section id="hero" class="">
        <div class="container text-center">
            <div class="hero-content">
                <div class="row">
                    <div class="col-md-10 mx-auto">
                        <h2 class="mb-5">Imagine having access to done-for-you nutrition content and
                            digital meal plans that are branded for your practice.<br>
                            <br>
                            How much time and money would you save?<br>
                            <br>
                            Join us and find out!
                        </h2>
                        <div>
                        </div>
                        <p>
                            <a routerLink="/join/apply"
                               appButton>Apply Here</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section class="marble">
        <div class="container">
            <div class="text-center">
                <h3 class="">Living Plate Rx: Your Complete Nutrition Content Solution&trade;</h3>

                <!--            <hr class="mini">-->

                <!--            <h4 class="">MEAL PLANS&nbsp;-->
                <!--            </h4>-->
                <!--            <p class="">Living Plate Rx makes it easy to create meal plans quickly and simply for-->
                <!--                clients — from recipes hand-crafted by dietitians and chefs, not a computer—with-->
                <!--                full control and your branding and logo. </h5>-->

                <!--            <p class="">There’s no cost to be a <strong>Living Plate Rx Community Member</strong>,-->
                <!--                AND you’ll earn-->
                <!--                30% commission on all of your clients’ meal plan purchases as compensation for-->
                <!--                managing plans.-->
                <!--                </h5>-->
                <!--            <p class="">Get excited about meal planning again (no really—we mean it!) for FREE.-->
                <!--                Apply now to join the Living Plate Rx community.-->
                <!--            <hr class="mini">-->
                <!--            <h4>LIBRARY</h4>-->
                <!--            <p><img src="/assets/img/home/library-logo-hi-res.png" width="329" height="121" alt=""/><br>-->
                <!--            </p>-->
                <!--            <p>-->
                <!--                As a <strong>Living Plate Rx  Member</strong>, you’ll have access to the <em>growing</em>-->
                <!--                Nutrition and Culinary-->
                <!--                Library, a comprehensive collection of evidence-based content.-->
                <!--</h5>-->

                <!--          <p> Our library contains collections that are loaded with content, all branded for your-->
                <!--                business so you can project the highest level of professionalism. </p>-->
                <!--          <p>You will  find complete courses, handouts, guides, blog posts, social media support, e-books, and<br>-->
                <!--          business support tools that can be used to add value to your practice and offerings. </p>-->
            </div>

            <div class="col-lg-10 mx-auto">
                <hr class="mini">
                <h4><span>Before Living Plate Rx, you...</span></h4>
                <ul>
                    <li>
                        spend hours creating content and meal plans from scratch
                    </li>
                    <li>
                        purchase or borrow content from multiple sources without branding
                        or format consistency
                    </li>
                    <li>struggle with building and facilitating online courses</li>
                    <li>
                        lack the support you need to scale your practice
                    </li>
                    <li>
                        try to do it all without gaining traction in your business
                    </li>
                </ul>

                <hr class="mini">
                <h4><span>After joining Living Plate Rx, you will...</span></h4>
                <ul>
                    <li>
                        <strong>EASILY</strong> generate nutrition education materials, including meal
                        plans, in minutes and brand them for your practice to project the highest level
                        of professionalism
                    </li>
                    <li>
                        <strong>CONFIDENTLY</strong> publish a blog post or newsletter with done-for-you
                        content that you can edit (references included) to attract your ideal client
                    </li>
                    <li>
                        <strong>CREATE</strong> a digital course with ease with all the bells and
                        whistles needed to generate passive income
                    </li>
                    <li>
                        Never have another <strong>LATE NIGHT</strong> trying to be a content
                        machine and expecting to grow your business
                    </li>
                    <li>
                        Create <strong>CAPTIVATING</strong> opt-ins for your website and offers to grow
                        your list
                    </li>
                    <li>
                        <strong>STOP WASTING TIME</strong>, struggling to come up with social media
                        content to maintain and grow your presence&nbsp;
                    </li>
                    <li>
                        <strong>GENERATE</strong> signature meal plans for your programs and offerings
                        to reflect your philosophy of nutrition care
                    </li>
                </ul>
            </div>

        </div>
    </section>


    <section class="notice">
        <div class="container text-center">
            <a routerLink="/join/apply"
               class="btn btn-lg btn-xlg btn-outline-light">APPLY HERE AND LEARN MORE</a>
        </div>
    </section>

    <section class="marble">
        <div class="container">

            <div class="row">
                <div class="col-lg-5">
                    <img src="/assets/img/home/jp-kitchen-portrait.jpg" class="img-fluid" />

                </div>
                <div class="col-lg-7">
                    <p>
                        I am a <strong>Registered Dietitian Nutritionist, Nutrition Educator</strong>,
                        and
                        <strong>Culinary Coach</strong>
                        providing evidence-based nutrition education content and meal planning software
                        to
                        support nutrition professionals in scaling their practices.

                    </p>
                    <p> After opening a private practice I was frustrated with the lack of tools to
                        support me
                        in scaling my business. I created an education library and meal planning
                        platform from
                        scratch and <strong>not only grew my practice from 0-1,000+ clients</strong> in
                        less
                        than 18 months, but
                        generated <strong>passive income</strong> as well. I figured out how to
                        <strong>monetize</strong> these tools AND capture
                        my ideal clients.

                    </p>
                    <p> I didn't want to keep this a secret, so <strong>now I share my business tools
                        with
                        you</strong> - no more late nights, frustration, and overwhelm associated with
                        generating a constant stream of content. No more creating meal plans or
                        nutrition
                        materials [including complete courses] from scratch.
                        Don’t waste any more time – join our community for the solution you have been
                        looking
                        for.
                    </p>
                    <p> Have questions? I’m here to help. Feel free to reach out:
                        <a routerLink="mailto:jpetrucci@livingplaterx.com">jpetrucci@livingplaterx.com</a>
                    </p>
                </div>
            </div>
        </div>
    </section>


    <section class="notice">
        <div class="container text-center">
            <a routerLink="/join/apply"
               class="btn btn-lg btn-xlg btn-outline-light">YES, PLEASE! SUBMIT MY APPLICATION</a>
        </div>
    </section>


    <section class="marble">
        <div class="container">
            <h3 class="section-heading">Living Plate Rx content is…</h3>

            <div class="row text-center">
                <div class="col-md-4 tile">
                    <div class="tile-inner">
                        <h4>Evidence-Based</h4>
                        <p>Nutrition content is built on a framework of health behavior theory and meal
                            plans are based on evidence-based protocols.</p>
                    </div>
                </div>
                <div class="col-md-4 tile">
                    <div class="tile-inner">
                        <h4>Affordable</h4>
                        <p>Living Plate Rx membership is $999/year and grants you access to the growing
                            library AND meal planning software.</p>
                    </div>
                </div>
                <div class="col-md-4 tile">
                    <div class="tile-inner">
                        <h4>Customizable</h4>
                        <p>Content and meal plans can be edited and branded so you can project the
                            highest level of professionalism.</p>
                    </div>
                </div>

                <div class="col-md-4 tile">
                    <div class="tile-inner">
                        <h4>Easy-to-Use</h4>
                        <p>Materials are provided with Canva links for full editing privileges and meal
                            plans come with pre-populated templates.</p>
                    </div>
                </div>
                <div class="col-md-4 tile">
                    <div class="tile-inner">
                        <h4>Scalable</h4>
                        <p>Have a growing practice? We’re here for you with scalable solutions to meet
                            the needs of your clients.</p>
                    </div>
                </div>
                <div class="col-md-4 tile">
                    <div class="tile-inner">
                        <h4>Growing!</h4>
                        <p>Our growth is formative with plenty of input from our community. Our library
                            grows every month and new meal plans are created to meet the needs of our
                            practitioners.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section>
        <div class="container">
            <h3 class="section-heading">
                Transform your business — and help your clients
                succeed</h3>

            <div class="row align-items-center">
                <div class="col-md-6">
                    <img src="/assets/img/home/meryl-2.png" class="img-fluid mb-3 mb-md-0" alt="Meryl Brandwein">
                </div>
                <div class="col-md-6">

                    <p>
                        “Living Plate Rx has truly helped me transform my
                        business! The meal planners have proven to be
                        an invaluable tool—for us and for our clients. It
                        allows us to focus on the food, without having to
                        spend countless hours on the planning. We just
                        adore the team at Living Plate Rx. Customer
                        service is top-notch and so very resourceful!
                        Jeanne Petrucci, MS RDN, the founder, is a wealth
                        of knowledge and her vision and ability to bring
                        the planner to life is beyond incredible. We are
                        also so excited to be partnering with her and her
                        team!”
                    </p>
                    <p>
                        Meryl Brandwein, RD, LDN, and the Team<br>
                        Brandwein Institute for Nutrition & Wellness
                    </p>

                </div>
            </div>
        </div>
    </section>


    <section>
        <div class="container">
            <h3 class="section-heading">
                Take advantage of our easy-to-use, flexible system
            </h3>


            <div class="row align-items-center">
                <div class="col-md-6">
                    <img src="/assets/img/home/basheerah-enahora.jpeg" class="img-fluid px-5 mb-3 mb-md-0"
                         alt="Basheerah Enhora">
                </div>
                <div class="col-md-6">
                    “Ease of use, flexibility, and functionality were
                    most important for me in choosing a meal
                    planning platform. I also wanted a platform that
                    was supportive of its members and receptive of
                    feedback for improvement. Living Plate Rx
                    continues to make improvements to the platform
                    and provides resources and tools to members.
                    Jeanne is extremely responsive as well and I
                    value that commitment to quality service.”
                    <br>
                    <br>
                    Basheerah Enahora, MBA, MS, RDN, LDN<br>
                    Registered Dietitian Nutritionist & Owner
                    <br>BE Nutrition
                </div>
            </div>
        </div>
    </section>

    <section>
        <div class="container">

            <h3 class="section-heading">
                Empower your clients with dietitian-created meal plans
            </h3>

            <div class="row align-items-center">
                <div class="col-md-6">
                    <img src="/assets/img/home/amanda-sauceda.jpg" class="img-fluid px-5 mb-3 mb-md-0"
                         alt="Amanda Sauceda">
                </div>
                <div class="col-md-6">
                    <p>
                        “The most important aspect of selecting Living
                        Plate Rx as the meal planning tool for my practice
                        was the team! Their team is amazing and very
                        responsive to suggestions and questions.
                        Secondly, the cost can't be beaten—they are free
                        to get started. Win-win. Lastly, their meal plans
                        are created by a dietitian and not
                        computer-generated. This is something unique.”
                    </p>
                    <p>Amanda Sauceda, MS, RDN, CLT
                        <br>Amanda Sauceda</p>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div class="container">
            <h3 class="section-heading">
                Sign up now for the perfect tool to scale your business
            </h3>

            <div class="row align-items-center">
                <div class="col-md-6">
                    <img src="/assets/img/home/gretchen.jpg" class="img-fluid px-5 mb-3 mb-md-0"
                         alt="Gretchen Spetz">
                </div>
                <div class="col-md-6">
                    <p>“For many years, I have been searching for a
                        menu planning system that has a library of
                        delicious and simple meals that use minimally
                        processed or unprocessed foods. Enter Living
                        Plate Rx—the perfect tool for creating meal plans
                        for clients that have a wide variety of needs. My
                        clients LOVE the recipes and features (like the
                        grocery list) in their weekly menus. Living Plate Rx
                        is the tool every RD needs in their toolkit!”</p>
                    <p>Gretchen Spetz MS, RDN, LD<br>
                        The Functional Kitchen LLC</p></div>
            </div>
        </div>

    </section>


    <section>
        <div class="container">

            <h3 class="section-heading">
                Focus on your clients — without spending hours
                <span class="pbr">on planning their meals</span>
            </h3>

            <div class="row align-items-center">
                <div class="col-md-6">
                    <img src="/assets/img/home/eakitchen.jpg" class="img-fluid mb-3 mb-md-0" alt="E.A. Stewart">
                </div>
                <div class="col-md-6">
                    <p>“I’m in love with Living Plate Rx meal plans! I have
                        so many clients who want help with meal
                        planning, but I don’t have the time to create meal
                        plans for everyone. I use the Living Plate Rx meal
                        plans to help my clients to get creative in the
                        kitchen. Most clients will use a few days of meal
                        ideas each week. Then they get inspired to put
                        together their own meal plans. It’s a win-win for
                        everyone!”</p>
                    <p>E.A. Stewart, MBA, RD<br>
                        Spicy RD Nutrition</p>
                </div>
            </div>
        </div>
    </section>

    <section class="notice">
        <div class="container text-center">
            <a routerLink="/join/apply"
               class="btn btn-lg btn-xlg btn-outline-light">APPLY HERE AND LEARN MORE</a>
        </div>
    </section>


</ng-container>
