import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { RecipeSearchResultInterface } from '../../model/recipe-search-result.interface';

@Injectable()
export class ClientRecipesService {
  constructor(private apiV2: ApiV2) {}

  /**
   * @param {string} term
   * @param {number} from
   * @param {number} limit
   * @param {any[]} mealTypes
   * @returns Observable<RecipeSearchResultInterface>
   */
  search(term = '', from = 0, limit = 30, mealTypes = []): Observable<RecipeSearchResultInterface> {
    return this.apiV2.post$('client/recipes/search', { term, from, limit, mealTypes }).pipe(
      map((result) => {
        result.recipes = result.recipes.map((recipe) => Recipe.fromObject(recipe));
        return result as RecipeSearchResultInterface;
      })
    );
  }
}
