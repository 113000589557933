/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Nutrients.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Expose, Type } from 'class-transformer';
import { Default } from '../../utils/default.decorator';
import { NutrientValue } from './IngredientNutrientValue';

/*
Refer to for Nutritionix mapping
https://docs.google.com/spreadsheets/d/14ssR3_vFYrVAidDLJoio07guZM80SMR5nxdGpAX-1-A/edit#gid=309222162
 */

export class Nutrients {
  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITB6A = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F22D1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F22D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F22D5 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ALA_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F22D6 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  TRP_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ASP_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  WATER = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FAPU = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ZN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  TYR_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F6D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  HISTN_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITC = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CHOCDF = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  K = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITD = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  undefined = new NutrientValue(); // ???

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  P = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CHOLE = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ENERC_KCAL = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  SE = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VAL_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ASH = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  PHE_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CA = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CHOLN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FOLFD = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F12D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  THEBRN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F16D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  THR_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F16D1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FIBTG = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FOLDFE = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CU = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ALC = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F20D1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F20D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FAMS = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CRYPX = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F20D5 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F20D4 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F24D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  LEU_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  LYCPN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  PRO_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  MG = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  RIBF = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  MN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FOL = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F17D1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F17D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  THIA = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITB12 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  SUGAR = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  SUGAR_ADD = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  PROCNT = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  NA = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  PHYSTR = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  RETOL = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  SER_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F4D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CAFFN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F8D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FE = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITA_IU = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ARG_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ENERC_KJ = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  'LUT+ZEA' = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  GLY_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FASAT = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  GLU_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITK1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  MET_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F10D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  BETN = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  ILE_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F14D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  PANTAC = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  NIA = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F14D1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  TOCPHG = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F18D0 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F18D2 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  TOCPHD = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F18D1 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CARTB = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F18D4 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  TOCPHB = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  F18D3 = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  TOCPHA = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CARTA = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITK1D = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  VITA_RAE = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  LYS_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FAT = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FOLAC = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  CYS_G = new NutrientValue();

  @Expose()
  @Default(new NutrientValue())
  @Type(() => NutrientValue)
  FATRN = new NutrientValue();
}
