import { Injectable } from '@angular/core';
import { createId } from '../../lprx-shared-lib/utils/id-generator';

@Injectable()
export class IDGeneratorService {
  constructor() {}

  /**
   * @deprecated Unsafe to generate IDs on the client as they can be manipulated to overwrite other records
   */
  generate(prefix: string = ''): string {
    return createId(prefix);
  }
}
