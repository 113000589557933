<ng-container *ngIf="style === 'default'">
  <div class="py-5 text-center text-muted">
    <br />
    <h2>{{ term }}</h2>
    <i class="fa fa-spinner fa-3x fa-spin"></i>
  </div>
</ng-container>

<ng-container *ngIf="style === 'sm'">
  <div class="py-5 text-center text-muted">
    <h6>{{ term }}</h6>
    <i class="fa fa-spinner fa-spin"></i>
  </div>
</ng-container>

<ng-container *ngIf="style === 'xs'">
  <div class="py-5 text-center text-muted">
    <i class="fa fa-spinner fa-spin"></i>
  </div>
</ng-container>

<ng-container *ngIf="style === 'xs-term'">
  <span class="text-center text-muted">
    <i class="fa fa-spinner fa-spin"></i>&nbsp;{{ term }}
  </span>
</ng-container>

<ng-container *ngIf="style === 'icon'">
  <i class="fa fa-spinner fa-spin"></i>
</ng-container>

<ng-container *ngIf="style === 'icon-2x'">
  <div class="text-center text-muted">
    <i class="fa fa-spinner fa-spin fa-2x"></i>
  </div>
</ng-container>
