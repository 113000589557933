/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-location.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { ArgumentException } from '../exceptions/argument.exception';

export class MealLocation {
  constructor(
    public readonly weekNumber: string,
    public readonly dayName: string,
    public readonly mealName: string
  ) {
    if (!weekNumber) {
      throw new ArgumentException('weekNumber not provided');
    }

    if (!dayName) {
      throw new ArgumentException('dayName not provided');
    }

    if (!mealName) {
      throw new ArgumentException('mealName not provided');
    }
  }

  toString() {
    return [this.weekNumber, this.dayName, this.mealName].join('-');
  }
}
