import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { ApiV3 } from '../../../providers/api.v3';
import { BillingAccountServiceInterface } from '../billing-account-service-interface';

@Injectable()
export class ClientAccountBillingService implements BillingAccountServiceInterface {
  constructor(private readonly apiV2: ApiV2, private readonly apiV3: ApiV3) {}

  /**
   * Get the client's payment method
   */
  getPaymentMethods(): Observable<any[]> {
    return this.apiV2.get$('client/account/billing/payment-methods');
  }

  /**
   * Update the client's payment method
   *
   * @param token
   */
  updatePaymentMethod(token): Observable<any> {
    return this.apiV3.post('payment-methods', { token });
  }
}
