import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { switchMap, take, tap } from 'rxjs/operators';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout/layout.service';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { CdnService } from '../../service/cdn.service';
import { RecipeViewerService } from './recipe-viewer.service';

@Component({
  selector: 'app-recipe-viewer',
  templateUrl: './recipe-viewer.component.html',
  styleUrls: ['./recipe-viewer.component.scss'],
})
export class RecipeViewerComponent implements OnInit {
  recipe: Recipe;
  doPrint = false;

  @Output() servingsChanged = new EventEmitter();
  logoUrl: SafeUrl;

  constructor(
    private route: ActivatedRoute,
    private service: RecipeViewerService,
    private layout: LayoutService,
    private cdn: CdnService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.route.queryParams
      .pipe(
        tap((queryParams) => {
          if (queryParams['print']) {
            this.doPrint = true;
          }
        }),
        switchMap(() => this.route.params)
      )
      .pipe(switchMap((params) => this.service.getRecipe(params['recipeId'])))
      .subscribe((recipe) => {
        this.recipe = recipe;
        if (this.doPrint) {
          this.print();
        }
      });
  }

  private print() {
    // yes...it's f'd up...but it works for now.
    this.auth
      .getLoggedInUser()
      .pipe(take(1))
      .subscribe((user) => {
        if (user.userType !== UserType.Admin) {
          this.layout
            .setBrandingFromDistributorId(user.distributorId)
            .then(() => this.layout.brand$.pipe(take(1)).toPromise())
            .then((brand) => {
              this.logoUrl = this.cdn.getSafeUrl(brand.logoUrl);
              this._doPrint();
            });
        } else {
          this._doPrint();
        }
      });
  }

  private _doPrint() {
    setTimeout(() => {
      window.addEventListener('afterprint', (event: Event) => {
        window.close();
      });
      window.print();
    }, 2000);
  }
}
