<div *ngIf="!isPrinting && view === 'prepping'" class="d-print-none">
    <div class="row">
        <div class="col-6 mx-auto text-center">

            <br>
            <br>
            <br>
            <div>
                <h3>We're Preparing Your Meal Plan</h3>

                <br>
                <i class="fa fa-4x fa-spinner fa-spin"></i>
                <br>
                <br>
                <!--                    <div class="progress">-->
                <!--                        <div class="progress-bar bg-info" role="progressbar"-->
                <!--                             [style.width]="renderingProgressStyle" [attr.aria-valuenow]="renderingProgress"-->
                <!--                             aria-valuemin="0"-->
                <!--                             aria-valuemax="100"></div>-->
                <!--                    </div>-->

                <br>

                <button (click)="cancelPrint()" class="btn btn-outline-secondary">Cancel</button>
            </div>

        </div>
    </div>
</div>


<div *ngIf="weeklyPlan">

    <div class="d-print-none print-settings bg-light p-3">

        <div class="container">
            <div class="row align-items-center">
                <div class="col-auto mr-4">
                    <strong>Print Settings</strong>
                </div>
                <div class="col-auto mr-4" *ngIf="canShowNutritionFacts">
                    <label>
                        <input [(ngModel)]="printNutritionFacts" type="checkbox" name="printPublicNotes">
                        Nutrition Facts
                    </label>
                </div>
                <div class="col-auto mr-4">
                    <label>
                        <input [(ngModel)]="printPublicNotes" type="checkbox" name="printPublicNotes">
                        Public Notes
                    </label>
                </div>
                <div class="col-auto mr-4">
                    <label>
                        <input [(ngModel)]="printPrivateNotes" type="checkbox" name="printPublicNotes">
                        Private Notes
                    </label>
                </div>
                <div class="col-auto ml-auto">
                    <button type="button" appButton (click)="print()">Print</button>
                    &nbsp;
                    <a [routerLink]="backToPlannerUrl" btnSecondary>Cancel</a>
                </div>
            </div>
        </div>
    </div>


    <div class="container">

        <div class="print-content"
             [class.hide-public-notes]="!printPublicNotes"
             [class.hide-private-notes]="!printPrivateNotes"
             [class.hide-nutrition-facts]="!printNutritionFacts">

            <div id="weekly-planner-print-container" class='landscape'>
                <div id="weekly-planner-wrapper">
                    <img [src]="brand.safeLogo" width="200">
                    <br><br>
                    <h2>{{weeklyPlan.weekNumber | humanWeek}}</h2>
                    <app-planner-week [weeklyPlan]="weeklyPlan"></app-planner-week>
                </div>
            </div>

            <div style="page-break-inside: avoid;" class="print-grocery-list-wrapper">
                <img [src]="brand.safeLogo" width="200" class='mb-3'>
                <app-grocery-list [weeklyPlan]="weeklyPlan" [fullWeek]="true" [printBranding]="false"></app-grocery-list>
            </div>


            <div *ngFor="let r of recipesToPrint"
                 class="print-card card-wrapper"
                 style="page-break-before: always">

                <div class="row align-items-center">
                    <div class="col-4"><img [src]="brand.safeLogo" width="200"></div>
                </div>

                    <div class="print-recipe">
                        <br><br>
                        <app-recipe-view
                            [recipe]="r.recipe"
                            [userServings]="r.userServings"
                            [recipeImageSize]="{width: 200, height: 200}">
                        </app-recipe-view>
                    </div>
            </div>

<!--            <div *ngFor="let dayName of daysOfTheWeek">-->
<!--                <div *ngFor="let meal of weeklyPlan.getDay(dayName).meals">-->


<!--                    &lt;!&ndash; If meal does not have notes but has recipes &ndash;&gt;-->
<!--                    <ng-container *ngIf="meal.hasRecipes() && !meal.hasNotes()">-->
<!--                        <div *ngFor="let card of meal.cards"-->
<!--                             class="print-card card-wrapper"-->
<!--                             style="page-break-before: always">-->

<!--                            <div class="row align-items-center">-->
<!--                                <div class="col-4"><img [src]="brand.safeLogo" width="200"></div>-->
<!--                            </div>-->
<!--                            <ng-container *ngIf="card.isRecipe()">-->
<!--                                <div class="print-recipe">-->
<!--                                    <br><br>-->
<!--                                    <app-recipe-view-->
<!--                                        [recipe]="card.recipe"-->
<!--                                        [userServings]="card.userServings"-->
<!--                                        [recipeImageSize]="{width: 200, height: 200}">-->
<!--                                    </app-recipe-view>-->
<!--                                </div>-->
<!--                            </ng-container>-->
<!--                        </div>-->
<!--                    </ng-container>-->
<!--                    &lt;!&ndash; END meal does not have notes but has recipes &ndash;&gt;-->


<!--                    &lt;!&ndash; If meal has notes but does not have recipes &ndash;&gt;-->
<!--                    <ng-container *ngIf="!meal.hasRecipes() && meal.hasNotes()">-->
<!--                        <ng-container *ngFor="let card of meal.cards">-->
<!--                            <div class="print-card card-wrapper" style="page-break-before: always">-->
<!--                                <ng-container *ngIf="meal.hasNotes()">-->
<!--                                    <ng-container *ngFor="let note of meal.getNotes()">-->
<!--                                        <div class="alert alert-warning m-3"-->
<!--                                             [innerHtml]="note.message"-->
<!--                                             [class.private-note]="note.isPrivate"-->
<!--                                             [class.public-note]="!note.isPrivate">-->
<!--                                        </div>-->
<!--                                    </ng-container>-->
<!--                                </ng-container>-->
<!--                            </div>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                    &lt;!&ndash; END If meal has notes but does not have recipes &ndash;&gt;-->


<!--                    &lt;!&ndash; If meal has notes and recipes &ndash;&gt;-->
<!--                    <ng-container *ngIf="meal.hasRecipes() && meal.hasNotes()">-->
<!--                        <ng-container *ngFor="let card of meal.cards; index as cardIndex">-->
<!--                            <ng-container *ngIf="card.isRecipe()">-->
<!--                                <div class="print-card card-wrapper" style="page-break-before: always">-->
<!--                                    <div class="row align-items-center">-->
<!--                                        <div class="col-4"><img [src]="brand.safeLogo" width="200"></div>-->
<!--                                    </div>-->
<!--                                    <ng-container *ngIf="cardIndex === meal.firstRecipeCardIndex()">-->
<!--                                        <ng-container *ngFor="let note of meal.getNotes()">-->
<!--                                            <div class="alert alert-warning m-3"-->
<!--                                                 [innerHtml]="note.message"-->
<!--                                                 [class.private-note]="note.isPrivate"-->
<!--                                                 [class.public-note]="!note.isPrivate">-->
<!--                                            </div>-->
<!--                                        </ng-container>-->
<!--                                    </ng-container>-->
<!--                                    <div class="print-recipe">-->
<!--                                        <br><br>-->
<!--                                        <app-recipe-view-->
<!--                                            [recipe]="card.recipe"-->
<!--                                            [userServings]="card.userServings"-->
<!--                                            [recipeImageSize]="{width: 200, height: 200}">-->
<!--                                        </app-recipe-view>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </ng-container>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                    &lt;!&ndash; END If meal has notes and recipes &ndash;&gt;-->

<!--                </div>-->
<!--            </div>-->
        </div>
    </div>

    <div class="d-print-none text-center">
        <button type="button" appButton (click)="print()">Print</button>
        <br><br>
        <a [routerLink]="backToPlannerUrl" btnSecondary>Cancel</a>
        <br><br>
    </div>

</div>
