import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout/layout.service';
import { UserService } from '../../service/user.service';
import { VarService } from '../../service/var.service';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit, OnDestroy, AfterViewInit {
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  card: any;

  isProcessing = false;

  errors: string[] = [];
  elements: any;
  elementsOptions: StripeElementsOptions = {
    // locale: 'es'
  };

  chosenPlan: any;
  plans = {
    static: {
      name: 'Weekly Static Plans',
      price: 9,
      code: '',
      plannerType: 's',
    },
    custom: {
      name: 'Weekly Custom Plans',
      price: 19,
      code: '',
      plannerType: 'c',
    },
  };

  nameOnCard: string;
  @Input() viewState = 'subscribe';

  @Output() closedUpgradePrompt = new EventEmitter<boolean>();

  initialViewState = '';

  private subs: Subscription[] = [];
  purchaseError: string;
  public showUpdatePaymentMethodLink: boolean;

  constructor(
    private auth: AuthService,
    public layout: LayoutService,
    private varService: VarService,
    private stripeService: StripeService,
    private userService: UserService,
    private router: Router
  ) {}

  ngAfterViewInit() {
    this.userService.getSubscription().subscribe((subscription: any) => {
      console.log(subscription);
      this.showUpdatePaymentMethodLink =
        subscription !== false && subscription.status !== 'canceled';
    });
  }

  ngOnInit() {
    this.initialViewState = this.viewState;
    this.chosenPlan = this.plans.static;
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  closeUpgradePrompt() {
    this.closedUpgradePrompt.emit(true);
  }

  chosePlan(planType: string) {
    this.chosenPlan = planType === 'custom' ? this.plans.custom : this.plans.static;
    this.viewState = 'purchase';
  }

  close() {
    // activate ModalClose
  }

  goBuy() {
    this.router.navigateByUrl('/buy?view=choose');
  }
}
