<!--<div class="pb-2">-->
<div (mouseenter)="drawChart($event)" class="pb-2">
  <div class="meal-name">
    <div class="row no-gutters align-items-center justify-content-between">
      <div class="col-6">{{ meal.name }}</div>
      <div class="utility-icons col-auto">
        <div class="row no-gutters align-items-center">
          <div
            *ngIf="showNutritionalFacts && meal.cards.length > 0"
            class="col-auto chart-wrapper"
            [class.d-none]="!hasChart"
          >
            <canvas
              id="chart-{{ weekNumber }}-{{ dayName }}-{{ meal.name }}"
              height="18"
              width="18"
              [ngbPopover]="popContent"
              triggers="mouseenter:mouseleave"
            >
            </canvas>
          </div>
          <div class="col-auto">
            &nbsp;
            <button
              type="button"
              class="btn-image"
              *ngIf="canAddNote && canEditMeal"
              (click)="createNote()"
              title="Add Note"
            >
              <img src="/assets/img/icon/note-icon.png" height="18" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="recipeInsertDetails" class="insert-button" (click)="insertRecipe()">
    <i class="fa fa-plus"></i> Add Recipe
  </div>

  <div>
    <div
      class="cards ver-{{ v }} tick-{{ ticks }}"
      *ngIf="meal.cards"
      cdkDropList
      (cdkDropListDropped)="drop($event)"
      [class.meal-empty-space]="!meal.cards || meal.cards.length === 0 || isDragEmpty"
    >
      <ng-container *ngFor="let card of meal.cards">
        <div
          style="position: relative"
          cdkDrag
          (mouseenter)='removeDragHandle = true'
          (mouseleave)='removeDragHandle = false'
          [cdkDragStartDelay]="dragDropDelayTimeout"
          [cdkDragData]="dragData(card)"
          (cdkDragStarted)="dragStarted($event)"
          (cdkDragEnded)="dragEnded($event)"
        >
          <div
            *ngIf="isTablet && !removeDragHandle"
            cdkDragHandle
            class=""
            style="
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              background: url('/assets/img/drag-handle.png') bottom right no-repeat;
              background-size: cover;
              height: 32px;
              width: 32px;
            "
          ></div>

          <app-planner-card
            *ngIf="card"
            [card]="card"
            [weekNumber]="weekNumber"
            [dayName]="dayName"
            [mealName]="meal.name"
            (removeCard)="onRemoveCard($event)"
            id="card-{{ weekNumber }}-{{ dayName }}-{{ meal.id }}-{{ card.type }}-{{ card.id }}"
          >
            <!--          <div-->
            <!--            *cdkDragPlaceholder-->
            <!--            class="placeholder"-->
            <!--            style="-->
            <!--              background: #ccc;-->
            <!--              border: dotted 3px #999;-->
            <!--              min-height: 12px;-->
            <!--              margin-bottom: 4px;-->
            <!--              transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);-->
            <!--              opacity: 1;-->
            <!--            "-->
            <!--          ></div>-->

            <ng-container *ngIf="card.recipe">
              <div *cdkDragPlaceholder>
                <app-recipe-drag
                  [recipe]="card.recipe"
                  style="
                    position: relative;
                    display: block;
                    max-width: 100%;
                    background: white;
                    border: 3px dashed rgb(140, 140, 140);
                    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                    opacity: 0.5;
                    margin-bottom: 4px;
                  "
                ></app-recipe-drag></div
            ></ng-container>

            <ng-container *ngIf="card.note">
              <div *cdkDragPreview>
                <div
                  style="
                    position: relative;
                    display: block;
                    left: -250px;
                    top: -30px;
                    width: 200px;
                    background: #ffffc8;
                    border: 1px solid rgb(56, 56, 56);
                    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                  "
                >
                  {{ card.note.message.substring(0, 30) + '...' }}
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="card.recipe">
              <div *cdkDragPreview>
                <app-recipe-drag
                  [recipe]="card.recipe"
                  style="
                    position: relative;
                    display: block;
                    left: -150px;
                    top: -30px;
                    width: 200px;
                    background: white;
                    border: 1px solid rgb(56, 56, 56);
                    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                    transform: scale(0.5);
                  "
                ></app-recipe-drag>
              </div>
              <!--            <app-recipe-drag-->
              <!--              *cdkDragPlaceholder-->
              <!--              [card]="card"-->
              <!--              style="display: block; margin-bottom: 4px"-->
              <!--            ></app-recipe-drag>-->
            </ng-container>
          </app-planner-card>
        </div>
      </ng-container>
    </div>
    <!--    <div-->
    <!--      *ngIf="!meal.cards || meal.cards.length === 0 || isDragEmpty"-->
    <!--      cdkDropList-->
    <!--      [class.meal-empty-space]='!meal.cards || meal.cards.length === 0 || isDragEmpty'-->
    <!--      (cdkDropListDropped)="drop($event)"-->
    <!--    ></div>-->
  </div>
</div>

<ng-template #popContent>
  <div style="width: 200px">
    <app-nutrition-summary
      [USDAFacts]="usdaFacts"
      [weekNumber]="weekNumber"
    ></app-nutrition-summary>
  </div>
</ng-template>
