import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { nonCalendarDisplay } from '../../lprx-shared-lib/non-calendar-display';

@Pipe({
  name: 'dayDisplay',
})
export class DayDisplayPipe implements PipeTransform {
  transform(dayName: string, weekNumber: string, format = 'dddd, MMM D'): any {
    if (weekNumber < '2000.00') {
      let weekDay = nonCalendarDisplay(weekNumber) + ', ' + dayName;
      // title case all words
      weekDay = weekDay.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
      return weekDay;
    }

    const w = parseFloat(weekNumber).toFixed(2);
    const [year, week] = w.split('.');
    const date = moment().isoWeekYear(parseInt(year, 10)).isoWeek(parseInt(week, 10));

    date.isoWeekday(dayName);

    return date.format(format);
  }
}
