/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/IngredientNutrientValue.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Transform } from 'class-transformer';
import { Default } from '../../utils/default.decorator';

export class NutrientValue {
  @Expose()
  @Default(0)
  value = 0;

  @Expose()
  @Default('g')
  unit = 'g';

  @Expose()
  @Default(true)
  @Transform((value, obj, type) => {
    if (obj.isKnown !== undefined) {
      return obj.isKnown;
    } else if (obj.value || obj.value === 0) {
      return true;
    } else {
      return undefined;
    }
  })
  isKnown = true;
}
