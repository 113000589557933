import { Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { MealPlansService } from '../../app/service/meal-plans.service';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { MealPlanCategories } from '../../lprx-shared-lib/entities/meal-plan/meal-plan-categories';
import { ApiV2 } from '../api.v2';
import { CreateMealPlanAliasOptions } from './create-meal-plan-alias-options';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';

@Injectable()
export class AdminMealPlansService {
  constructor(private apiV2: ApiV2, private mealPlansService: MealPlansService) {}

  /**
   * @param mealPlanId
   */
  distributors(mealPlanId: string): Observable<Distributor[]> {
    const promise = this.apiV2.get(`admin/meal-plans/${mealPlanId}/distributors`);
    return fromPromise(promise);
  }

  /**
   * Gets all the Meal Plans
   */
  all(): Observable<MealPlan[]> {
    return this.apiV2.get$('admin/meal-plans');
  }

  /**
   * @param mealPlanId
   */
  get(mealPlanId: string): Observable<MealPlan> {
    return this.mealPlansService.findOne(mealPlanId);
    // const promise = this.apiV2.get(`admin/meal-plans/${mealPlanId}`);
    // return fromPromise(promise).map(mealPlanData => plainToClass<MealPlan, {}>(MealPlan, mealPlanData));
  }

  addDistributorToMealPlan(distributorId: string, mealPlanId: string) {
    const promise = this.apiV2.post(`admin/meal-plans/${mealPlanId}/distributors/add`, {
      distributorId,
    });
    return fromPromise(promise);
  }

  /**
   *
   * @param mealPlanId
   * @param mealPlanAliasOptions
   */
  createAlias(mealPlanId: string, mealPlanAliasOptions: CreateMealPlanAliasOptions) {
    return this.apiV2.post$(
      `admin/meal-plans/${mealPlanId}/create-derivative`,
      mealPlanAliasOptions
    );
  }

  /**
   *
   * @param mealPlan
   */
  save(mealPlan: MealPlan): Observable<MealPlan> {
    return this.mealPlansService.save(mealPlan) as Observable<MealPlan>;
    // return this.apiV2
    //   .post$(`admin/meal-plans`, mealPlan)
    //   .switchMap((mp: MealPlan) => this.get(mp.id));
  }

  categories(): Promise<MealPlanCategories> {
    return this.mealPlansService.categories();
  }
}
