/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/Card.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import { defaultNutrients } from '../../default-nutrients';
import { CardType } from '../../enum/card-type';
import { USDAFactsResults } from '../../recipe/USDAFactsResults';
import { Default } from '../../utils/default.decorator';
import { GroceryItem } from '../recipe/grocery-item';
import { Recipe } from '../recipe/Recipe';
import { Note } from './note';

@Exclude()
export class Card {
  /**
   * Recipe specific properties
   */

  @Expose()
  @Type(() => Recipe)
  @Transform((value: Recipe, obj: Card) => (obj.type === CardType.Recipe ? value : undefined))
  recipe?: Recipe;

  @Expose()
  @Transform((value: Recipe, obj: Card) => (obj.type === CardType.Recipe ? value : undefined))
  recipeId?: string;

  @Expose()
  @Default('r')
  type: CardType = CardType.Recipe;

  @Expose()
  @Default(0)
  @Transform((value: Recipe, obj: Card) => (obj.type === CardType.Recipe ? value : undefined))
  userServings?: number = 0;

  @Expose()
  @Default(false)
  @Transform((value: Recipe, obj: Card) => (obj.type === CardType.Recipe ? value : undefined))
  isLeftover?: boolean = false;

  /**
   * Note specific properties
   */

  @Expose()
  @Transform((value: Note, obj: Card) => (obj.type === CardType.Note ? value : undefined))
  note?: Note;

  get id(): string | null {
    if (this.type === CardType.Recipe && this.recipeId) {
      return this.recipeId;
    } else if (this.type === CardType.Note && this.note) {
      return this.note.id;
    }
    return null;
  }

  static fromObject(object: any): Card {
    return plainToClass(Card, object);
  }

  static newRecipeCard(recipe: Recipe, userServings = 0) {
    console.log('recipe to add to card');
    console.log(recipe);
    const card = new Card();
    card.type = CardType.Recipe;
    card.recipeId = recipe.id;
    card.recipe = recipe;
    card.userServings = 0;
    return card;
  }

  getGroceries(): GroceryItem[] {
    if (this.isRecipe() && !this.isLeftover && this.recipe) {
      return this.recipe.getGroceries(this.userServings);
    }
    return [];
  }

  // @ts-ignore TS7030: Not all code paths return a value
  getNutritionFacts() {
    if (this.recipe) {
      return this.recipe.getNutritionFacts();
    }
  }

  getUSDAFacts(nutrients: Set<string> = defaultNutrients): USDAFactsResults | any {
    if (this.recipe) {
      return this.recipe.getUSDAFacts(nutrients);
    }
  }

  getRecipe() {
    return this.recipe;
  }

  setUserServings(servings: number) {
    this.userServings = servings;
  }

  isRecipe() {
    return this.type === CardType.Recipe;
  }

  isNote() {
    return this.type === CardType.Note;
  }
}
