import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApiV3 } from '../api.v3';
import { HttpAdapterFactory } from './adapter-factory';
import { AmplifyAdapter } from './amplify-adapter';
import { LprxApiProvider } from './api-provider';

@NgModule({
  providers: [HttpAdapterFactory, ApiV3, AmplifyAdapter]
})
export class LprxApiModule {
  static forRoot(): ModuleWithProviders<LprxApiModule> {
    return {
      ngModule: LprxApiModule,
      providers: [LprxApiProvider]
    };
  }
}
