import { plainToClass } from 'class-transformer';
import { DistributorPlanConfig } from '../../lprx-shared-lib/entities/distributor-config';
import { LprxApi } from './api';

export class DistributorPlanConfigs {
  constructor(private readonly lprxApi: LprxApi) {}

  get(distributorId: string): Promise<DistributorPlanConfig> {
    return this.lprxApi
      .get<DistributorPlanConfig>(`v3/distributor-config/${distributorId}`)
      .then((d) => plainToClass(DistributorPlanConfig, d));
  }

  save(distConfig: DistributorPlanConfig) {
    return this.lprxApi.post(`v3/distributor-config`, distConfig);
  }
}
