/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/id-generator.ts
 *  --------------------------
 *  - Swagger items commented out
 */

/**
 * Generate a random
 *
 * @param {string} prefix
 * @param {number} length
 * @return {string}
 */
export function createId(prefix: string | null, length?: number): string {
  prefix = prefix ? prefix + '_' : '';
  let s =
    prefix + Date.now().toString(36) + 'r' + Math.floor(Math.random() * 8999 + 1000).toString();

  length = length || 1;

  while (s.length - prefix.length < length) {
    s += Math.floor(Math.random() * 36).toString(36);
  }

  return s;
}
