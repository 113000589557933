import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class RecipeEditorService {
  constructor() {}

  ingredientUpdated = new Subject();

  updatedIngredient() {
    this.ingredientUpdated.next(true);
  }

  getUpdatedIngredient$() {
    return this.ingredientUpdated.asObservable();
  }
}
