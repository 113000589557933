/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */
/* tslint:disable:no-submodule-imports ordered-imports jsdoc-format */
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

if (document['documentMode'] || /Edge/.test(navigator.userAgent)) {
  (window as any).__Zone_enable_cross_context_check = true;
} // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following for the Reflect API. */
import 'core-js/es6/reflect';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'core-js/shim'; // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'es6-shim';
import 'zone.js';

/**
 * Sort of polyfill that there is no Buffer.
 * Class-transformer has a check testForBuffer() that gets called a lot during transformations.
 * When the Buffer class doesn't exist it causes significant increase in processing time for the
 * bro
 *
 */

try {
  // tslint:disable-next-line:no-unused-expression
  Buffer;
  console.log('Buffer exists');
} catch (e) {
  console.log('Buffer does not exist');
  // tslint:disable-next-line:no-var-requires
  const buff = require('buffer');
  // @ts-ignore
  window.Buffer = buff.Buffer;
}

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
(window as any).global = window;
