import { Pipe, PipeTransform } from '@angular/core';
import { nutrientValue } from '../../lprx-shared-lib/nutrient-value';
import { NutrientValue } from '../../lprx-shared-lib/recipe/nutrient.value';

@Pipe({
  name: 'nutrientValue',
})
export class NutrientValuePipe implements PipeTransform {
  transform(nutrient: NutrientValue, decimalPlaces = 0, showUnit = true): any {
    return nutrientValue(nutrient, decimalPlaces, showUnit);
  }
}
