/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { classToClass, Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsPositive, IsString, ValidateIf } from 'class-validator';
import { dayAsLong } from '../../day-as-long';
import { DaysOfTheWeek } from '../../days-of-the-week';
import { defaultNutrients } from '../../default-nutrients';
import { NutrientsCollection } from '../../recipe/NutrientsCollection';
import { USDAFactsResults } from '../../recipe/USDAFactsResults';
import { Case } from '../../utils/case';
import { daysOfTheWeek } from '../../utils/daysOfTheWeek';
import { Default } from '../../utils/default.decorator';
import { createId } from '../../utils/id-generator';
import { BaseItem } from '../base-item';
import { MealPlan } from '../meal-plan/MealPlan';
import { GroceryItem } from '../recipe/grocery-item';
import { Recipe } from '../recipe/Recipe';
import { SimpleNutritionFacts } from '../recipe/SimpleNutritionFacts';
import { Card } from './Card';
import { Day } from './Day';
import { WeeklyPlanType } from './weekly-plan-type';

@Exclude()
export class WeeklyPlan implements BaseItem {
  @Expose()
  @Default(() => createId('wp'))
  id: string = createId('wp');

  @Expose()
  @Default(Date.now())
  @IsPositive()
  createdAt: number = Date.now();

  @Expose()
  @Default(Date.now())
  @IsPositive()
  modifiedAt: number = Date.now();

  @Expose()
  @Default(false)
  @IsBoolean()
  isDeleted = false;

  @Expose()
  @ValidateIf((o) => !!o.username)
  @IsString()
  username: string;

  @Expose()
  @IsString()
  weekNumber: string;

  @Expose()
  @IsString()
  masterMealPlanId: string;

  @Expose()
  @Transform((val: string, obj: WeeklyPlan) => (val ? val : obj.masterMealPlanId))
  @IsString()
  mealPlanId: string;

  @Expose()
  @Type(() => MealPlan)
  @Default(new MealPlan())
  mealPlan: MealPlan = new MealPlan(); // used to include with response

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  monday: Day = new Day();

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  tuesday: Day = new Day();

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  wednesday: Day = new Day();

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  thursday: Day = new Day();

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  friday: Day = new Day();

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  saturday: Day = new Day();

  @Expose()
  @Type(() => Day)
  @Default(new Day())
  sunday: Day = new Day();

  // We don't use these, yet. Not sure we ever will.
  status: string;

  @Expose()
  userId: string;

  @Expose()
  @Default(false)
  isVirtual: boolean = false;

  @Expose()
  sourcedFrom?: string;

  @Expose()
  @Default(WeeklyPlanType.Client)
  type: WeeklyPlanType = WeeklyPlanType.Client;

  static fromObject(object: any): WeeklyPlan {
    return plainToClass(WeeklyPlan, object);
    // const weeklyPlan = new WeeklyPlan();
    // const mealPlan = MealPlan.fromObject(object.mealPlan);
    // const propertyNames = Object.getOwnPropertyNames(object);
    //
    // for (const property of propertyNames) {
    //   if (property.match(/day$/)) {
    //     weeklyPlan[property] = Day.fromObject(object[property], mealPlan);
    //   } else if (property === 'mealPlan') {
    //     weeklyPlan.mealPlan = mealPlan;
    //   } else if (weeklyPlan.hasOwnProperty(property)) {
    //     weeklyPlan[property] = object[property];
    //   }
    // }
    // return weeklyPlan;
  }

  setId() {
    this.id = this.userId + '-' + this.mealPlanId + '-' + this.weekNumber;
  }

  init(userId: string, meaPlan: MealPlan, weekNumber: string) {
    this.userId = userId;
    this.mealPlanId = meaPlan.id;
    this.weekNumber = weekNumber;
    this.masterMealPlanId = meaPlan.id;
    this.mealPlan = meaPlan;
    this.setId();
    for (const day of daysOfTheWeek) {
      this[day] = new Day();
      this[day].init(meaPlan);
    }
  }

  /**
   *
   */
  public getCards() {
    let cards: Card[] = [];
    for (const day of daysOfTheWeek) {
      const d: Day = this.getDay(day);
      cards = cards.concat(d.getCards());
    }
    return cards;
  }

  /**
   * @return Recipe[]
   */
  public getRecipes(): Recipe[] {
    let recipes: Recipe[] = [];
    for (const day of daysOfTheWeek) {
      const d: Day = this.getDay(day);
      recipes = recipes.concat(d.getRecipes());
    }
    return recipes;
  }

  /**
   * Get a particular day of the week
   *
   * @param dayName
   * @return Day
   */
  public getDay(dayName: string): Day {
    const day = dayAsLong(dayName, Case.Lower);
    return this[day];
  }

  /**
   *
   */
  public days(): Day[] {
    const orderedDays: Day[] = [];
    for (const dayName of daysOfTheWeek) {
      const day = this.getDay(dayName);
      orderedDays.push(day);
    }
    return orderedDays;
  }

  getGroceries(): GroceryItem[] {
    let list: GroceryItem[] = [];
    list = list.concat(this.monday.getGroceries());
    list = list.concat(this.tuesday.getGroceries());
    list = list.concat(this.wednesday.getGroceries());
    list = list.concat(this.thursday.getGroceries());
    list = list.concat(this.friday.getGroceries());
    list = list.concat(this.saturday.getGroceries());
    list = list.concat(this.sunday.getGroceries());
    return list;
  }

  getNutritionFacts() {
    const sum = new SimpleNutritionFacts();

    let stats: any[] = [];

    // tslint:disable-next-line:no-this-assignment
    const t = this;

    DaysOfTheWeek.getDaysLong(true).forEach((d: string) => {
      const nf = t[d].getNutritionFacts();
      nf.title = d;
      sum.protein += nf.nutritionFacts.protein;
      sum.calories += nf.nutritionFacts.calories;
      sum.fat += nf.nutritionFacts.fat;
      sum.carbs += nf.nutritionFacts.carbs;
      sum.carbsFromFiber += nf.nutritionFacts.carbsFromFiber;
      sum.carbsFromSugar += nf.nutritionFacts.carbsFromSugar;
      stats = stats.concat(nf);
    });

    return {
      id: this.weekNumber,
      title: this.weekNumber,
      type: 'week',
      nutritionFacts: sum,
      children: stats,
    };
  }

  getUSDAFacts(nutrients: Set<string> = defaultNutrients): USDAFactsResults {
    let stats: USDAFactsResults[] = [];
    const nutrientSum: NutrientsCollection = {};

    DaysOfTheWeek.getDaysLong(true).forEach((dayName: string) => {
      const day: Day = this[dayName];
      const usdaFacts = day.getUSDAFacts(nutrients);
      usdaFacts.title = dayName;
      stats = stats.concat(usdaFacts);
      const dayNutrients = usdaFacts.nutrients;
      for (const nutrient of Object.getOwnPropertyNames(dayNutrients)) {
        if (nutrients && !nutrients.has(nutrient)) {
          continue;
        }
        if (!nutrientSum[nutrient]) {
          nutrientSum[nutrient] = {
            value: 0,
            unit: dayNutrients[nutrient].unit,
            isKnown: dayNutrients[nutrient].isKnown,
          };
        }
        nutrientSum[nutrient].value += dayNutrients[nutrient].value;
      }
    });

    return {
      id: this.weekNumber,
      title: this.weekNumber,
      type: 'week',
      nutrients: nutrientSum,
      children: stats,
    };
  }

  hasRecipe(recipeId: string, dayName?: string, mealName?: string): boolean {
    if (dayName) {
      return this.getDay(dayName).hasRecipe(recipeId, mealName);
    }

    for (const d of daysOfTheWeek) {
      if (this.getDay(d).hasRecipe(recipeId, mealName)) {
        return true;
      }
    }

    return false;
  }

  public copyAsWeek(weekNumber: string) {
    const wp = classToClass(this);
    wp.id = 'master-' + wp.mealPlanId + '-' + weekNumber;
    wp.weekNumber = weekNumber;
    return wp;
  }
}
