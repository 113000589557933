/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/unsubscribe.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Subscription } from 'rxjs';

export function unsubscribe(subs: Subscription[] = []) {
  subs.forEach((s) => s.unsubscribe());
}

export function unsub(likeADuck: any) {
  if (likeADuck.subs) {
    unsubscribe(likeADuck.subs);
  }

  // loop through the object and unsubscribe any subscriptions that are found
  for (const key in likeADuck) {
    if (likeADuck.hasOwnProperty(key)) {
      const element = likeADuck[key];
      if (element instanceof Subscription) {
        element.unsubscribe();
      }
    }
  }
}
