<div style="max-width: 600px" class="pt-3 px-3">
  <h3>Update Nutrition Values</h3>

  <p>
    Click the 'Update Nutrients' to refresh the nutritional facts for your recipe with the latest
    data. Although the system aims for accuracy, we recommend reviewing the newly calculated
    nutritional facts. If you encounter any discrepancies, you have the option to manually edit
    these values for greater accuracy by expanding the ingredient.
  </p>

  <p>After updating the ingredients, you must Save your recipe to persist the new values.</p>

  <p class="text-right">
    <button type="button" btnSecondary (click)="cancelConvertRecipes()">Cancel</button>
    <button type="button" appButton (click)="convertRecipe()">
      Update Nutrients
    </button>
  </p>
</div>
