import { Injectable } from '@angular/core';
import { HttpAdapterFactory } from './adapter-factory';
import { LprxApi } from './api';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class LprxApiProvider extends LprxApi {
  constructor(private readonly factory: HttpAdapterFactory, private toastrService: ToastrService) {
    super(factory.create(), toastrService);
  }
}
