import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, from as fromPromise } from 'rxjs';
import { ApiV2 } from '../../../providers/api.v2';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { WeeklyPlanFactory } from '../../model/entities/weekly-plan/weekly-plan-factory';

@Injectable()
export class ClientMealPlanService {
  constructor(private apiV2: ApiV2, private readonly lprxApi: LprxApiProvider) {}

  /**
   * @param weeklyPlanData
   * @param weekNumber
   */
  private static constructOrCreateWeeklyPlan(weeklyPlanData, weekNumber: string): WeeklyPlan {
    return ClientMealPlanService.isValidWeeklyPlan(weeklyPlanData)
      ? WeeklyPlan.fromObject(weeklyPlanData)
      : WeeklyPlanFactory.createMasterWeeklyPlan(null, weekNumber);
  }

  private static isValidWeeklyPlan(weeklyPlanData) {
    return !(Object.keys(weeklyPlanData).length === 0 && weeklyPlanData.constructor === Object);
  }

  /**
   * @param weekNumber
   */
  getWeeklyMealPlan(weekNumber: string): Observable<WeeklyPlan> {
    console.log(`Getting User Weekly Meal Plan for ${weekNumber}`);
    // return this.apiV2
    //   .get$(`client/meal-plan/${weekNumber}`)

    return fromPromise(this.lprxApi.weeklyPlans.get(weekNumber)).pipe(
      map((data) => ClientMealPlanService.constructOrCreateWeeklyPlan(data, weekNumber))
    );
  }

  /**
   * @param weeklyPlan
   */
  saveWeeklyPlan(weeklyPlan: WeeklyPlan) {
    console.log(`Saving User Weekly Meal Plan for ${weeklyPlan.weekNumber}`);
    return fromPromise(this.lprxApi.weeklyPlans.save(weeklyPlan));
  }
}
