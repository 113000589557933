/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/resource-file.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';

import { DistributorAccessLevel } from '../distributor-access-level';
import { BaseItem } from '../entities/base-item';
import { DistributorType } from '../entities/distributor-type';
import { Default } from '../utils/default.decorator';
import { FileId, FolderId } from '../utils/types';
// import { ResourcePublishStatus } from './resource-publish-status';

export interface ResourceItem {
  id: string;
  isDeleted: boolean;
  isPublished: boolean;
  accessLevel: DistributorAccessLevel;
}

@Exclude()
export class ResourceFile implements BaseItem {
  @Expose()
  id: FileId;

  @Expose()
  folderId: FolderId;

  @Expose()
  key: string;

  @Expose()
  name: string;

  @Expose()
  @Default(DistributorAccessLevel.ActiveDistributor)
  accessLevel: DistributorAccessLevel = DistributorAccessLevel.ActiveDistributor;

  @Expose()
  @Default(false)
  isPublished = false;

  @Expose()
  created: number;

  @Expose()
  modified: number;

  @Expose()
  createdAt: number;

  @Expose()
  @Default(false)
  isDeleted = false;

  @Expose()
  modifiedAt: number;

  @Expose()
  @Default([DistributorType.HealthCareProfessional, DistributorType.WellnessProfessional])
  distributorTypes: DistributorType[] = [
    DistributorType.HealthCareProfessional,
    DistributorType.WellnessProfessional
  ];

  get isAccessLevelOpen() {
    return this.accessLevel === DistributorAccessLevel.Open;
  }

  publish() {
    this.isPublished = true;
  }

  unpublish() {
    this.isPublished = false;
  }
}
