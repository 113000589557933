/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/prime/commands/prime-purchase-response.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Stripe } from 'stripe';
import { Distributor } from '../../entities/distributor';

export class PrimePurchaseResponse {
  subscription: Stripe.Subscription;
  distributor: Distributor;
}
