import { Component } from '@angular/core';
import { DistributorsService } from '../../service/distributors.service';
import { ActivatedRoute } from '@angular/router';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';

@Component({
  selector: 'app-vioscreen-not-finished',
  templateUrl: './vioscreen-not-finished.component.html',
  styleUrls: ['./vioscreen-not-finished.component.scss']
})
export class VioscreenNotFinishedComponent {
  distributorVanityId: string;
  private distributor: Distributor;

  constructor(
    private distributorService: DistributorsService,
    private route: ActivatedRoute,
  ) {
    // get distributorId from route
    this.route.params.subscribe((params) => {
      this.distributorVanityId = params['distributorVanityId'];
      this.distributorService.get(this.distributorVanityId).subscribe((distributor) => {
        this.distributor = distributor;
      });
    });
  }

}
