import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LprxApiProvider } from '../../../../providers/lprx-api/api-provider';
import { showNewDragAndDropDialogKey, ShowNewDragAndDropDialogSetting } from '../../../../types';
import { isTablet } from '../../../is-tablet';

@Component({
  selector: 'app-new-drag-and-drop',
  templateUrl: './new-drag-and-drop-dialog.component.html',
})
export class NewDragAndDropDialogComponent {
  showNoMore: boolean = false;

  isTablet = isTablet();

  constructor(
    public dialogRef: MatDialogRef<NewDragAndDropDialogComponent>,
    private lprxApi: LprxApiProvider
  ) {}

  close($event: Event) {
    if (this.showNoMore) {
      // noinspection JSIgnoredPromiseFromCall
      this.lprxApi.saveVar<ShowNewDragAndDropDialogSetting>(showNewDragAndDropDialogKey, {
        enabled: false,
      });
    }

    this.dialogRef.close();
  }
}
