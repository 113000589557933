<h5>Source</h5>
<br />
<div class="form-group">
  <mat-button-toggle-group name="radioBasic" [(ngModel)]="source.type">
    <!-- <label ngbButtonLabel class="btn-primary">
      <input ngbButton type="radio" value="inspired"> Inspired By
    </label> -->
    <mat-button-toggle value="self">It's My Recipe</mat-button-toggle>
    <mat-button-toggle value="adapted">Adapted From</mat-button-toggle>
    <mat-button-toggle value="from">Contributed By</mat-button-toggle>
  </mat-button-toggle-group>
</div>
<div class="form-group">
  <label>Name of Source</label>
  <input [(ngModel)]="source.name" class="form-control" />
</div>
<div class="form-group">
  <label>Source URL</label>
  <input [(ngModel)]="source.url" class="form-control" />
</div>
