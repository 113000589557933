import { NutrientValue } from '../../lprx-shared-lib/recipe/nutrient.value';
import { USDAFactsResults } from '../../lprx-shared-lib/recipe/USDAFactsResults';

export function getNutrient(usdaFacts: USDAFactsResults, key: string): NutrientValue {
  try {
    if (usdaFacts.nutrients[key]) {
      return usdaFacts.nutrients[key];
    }
  } catch (e) {}
  return { value: 0, unit: '', isKnown: true };
}
