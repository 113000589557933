import { Injectable } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { Brand } from '../model/entities/brand';
import { CdnService } from '../service/cdn.service';
import { WindowSize } from './windowSize';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';

@Injectable()
export class LayoutService {
  private brand: Brand;
  private brandSubject = new BehaviorSubject<Brand>(null);
  readonly brand$ = this.brandSubject.asObservable();

  private showNutritionalInfo = new BehaviorSubject<boolean>(true);
  readonly showNutritionalInfo$ = this.showNutritionalInfo
    .asObservable()
    .pipe(distinctUntilChanged());

  private layouts = {
    default: {
      showHeader: true,
      bodyBackgroundColor: 'bg-white',
    },
    focusedForm: {
      showHeader: false,
      bodyBackgroundColor: 'bg-light',
    },
  };

  // headerVisible as behavior subject
  private headerVisible = new BehaviorSubject<boolean>(true);
  // headerVisible$ as observable
  readonly headerVisible$ = this.headerVisible.asObservable().pipe(distinctUntilChanged());

  private currentWindowSize: WindowSize;
  private windowSize = new BehaviorSubject<WindowSize>(WindowSize.SM);
  readonly windowSize$ = this.windowSize.asObservable().pipe(distinctUntilChanged());

  private sideNavOpenSubject = new Subject<boolean>();
  private headerHeightSubject = new BehaviorSubject<string>('');

  private isWideSubject = new BehaviorSubject<boolean>(null);
  readonly isWide$ = this.isWideSubject.asObservable().pipe(distinctUntilChanged());

  private isNarrowSubject = new BehaviorSubject<boolean>(null);
  readonly isNarrow$ = this.isNarrowSubject.asObservable().pipe(distinctUntilChanged());

  public readonly availablePlanTypes = new BehaviorSubject<string>('sc');
  public readonly availablePlanTypes$ = this.availablePlanTypes
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(
    private lprxApi: LprxApiProvider,
    private cdn: CdnService,
    private sanitizer: DomSanitizer,
    public toastr: ToastrService,
    private title: Title
  ) {
    const brand = new Brand('Living Plate Rx', '/assets/img/lprx-logo.png', '');
    brand.safeLogo = this.sanitizer.bypassSecurityTrustResourceUrl(brand.logoUrl);
    this.brandSubject.next(brand);

    this.setWindowSize(window.innerWidth);
  }

  setBrandingToLPRx() {
    const brand = new Brand('Living Plate Rx', '/assets/img/lprx-logo.png', '');
    brand.safeLogo = this.sanitizer.bypassSecurityTrustResourceUrl(brand.logoUrl);
    this.title.setTitle('Living Plate Rx');
    this.brandSubject.next(brand);
  }

  setBrandingFromDistributor(distributor: Distributor) {
    console.log('Setting Practitioner branding');
    const brand = Brand.fromDistributor(distributor);
    brand.safeLogo = this.cdn.getSafeUrl(brand.logoUrl);
    this.title.setTitle(distributor.business + ' - Meal Planner');
    this.brandSubject.next(brand);
  }

  setBrand(brand: Brand) {
    brand.safeLogo = this.cdn.getSafeUrl(brand.logoUrl);
    this.brandSubject.next(brand);
  }

  async setBrandingFromDistributorId(distributorId: string) {
    const resDist = await this.lprxApi.distributors.current();
    console.log(resDist);
    this.setBrandingFromDistributor(resDist);
  }

  getBrand(): Observable<Brand> {
    return this.brandSubject.asObservable();
  }

  getSideNavOpen() {
    return this.sideNavOpenSubject.asObservable();
  }

  openSideNav() {
    this.sideNavOpenSubject.next(true);
  }

  closeSideNav() {
    this.sideNavOpenSubject.next(false);
  }

  getHeaderHeight() {
    return this.headerHeightSubject.asObservable();
  }

  headerHeight(height: string) {
    this.headerHeightSubject.next(height);
  }

  setWindowSize(width: number) {
    let windowSize: WindowSize;

    if (width < 576) {
      windowSize = WindowSize.XS;
    } else if (width < 768) {
      windowSize = WindowSize.SM;
    } else if (width < 992) {
      windowSize = WindowSize.MD;
    } else if (width < 1200) {
      windowSize = WindowSize.LG;
    } else {
      windowSize = WindowSize.XL;
    }

    if (this.currentWindowSize !== windowSize) {
      this.currentWindowSize = windowSize;
      this.windowSize.next(windowSize);
    }

    const isWide = width >= 769;
    this.isWideSubject.next(isWide);
    this.isNarrowSubject.next(!isWide);
  }

  onWindowResize(param: { width: any; height: any }) {
    this.setWindowSize(param.width);
  }

  showHeader() {
    this.headerVisible.next(true);
  }

  hideHeader() {
    this.headerVisible.next(false);
  }
}
