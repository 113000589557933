import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../../../lprx-shared-lib/utils/unsubscribe';
import { Ingredient } from '../../../../../lprx-shared-lib/entities/recipe/Ingredient';
import { formatIngredient } from '../../../../../lprx-shared-lib/format-ingredient';
import { from } from 'rxjs/observable/from';
import { LprxApiProvider } from '../../../../../providers/lprx-api/api-provider';
import { ConvertToSimpleEntryService } from '../recipe-elements-input/convert-to-simple-entry.service';
import { stripTags } from '../ingredient-simple-input/strip-tags';
import { RecipeEditorService } from '../../recipe-editor.service';
import { GroceryCategories } from '../../../../../lprx-shared-lib/GroceryCategories';

/**
 * Generated class for the RecipeIngredientsInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-ingredients-input',
  templateUrl: 'recipe-ingredients-input.html',
  styleUrls: ['./recipe-ingredients-input.scss'],
})
export class RecipeIngredientsInputComponent implements OnInit, OnDestroy {
  @Input() ingredients: Ingredient[];

  _ingredients: Ingredient[];

  private subs: Subscription[] = [];
  processingConvertIngredients: boolean = false;

  constructor(
    private lprxApi: LprxApiProvider,
    private convertToSimpleEntryService: ConvertToSimpleEntryService,
    private recipeEditorService: RecipeEditorService
  ) {}

  ingredientErrors: Array<{ err_code: number; original_text: string; warning: string }> = [];
  simpleInput: string;
  editSimple: boolean = false;
  showIngredientAdvanced: Set<Ingredient> = new Set();
  editIngredient: Set<Ingredient> = new Set();
  newSimpleIngredient: string;
  placeHolderForSimple: string = `For example:\n2 cucumbers, peeled into ribbons\n1 plum tomato, sliced\n1 Tbs olive oil`;
  addIngredientError: string = null;
  useRaw = false;

  ngOnInit(): void {
    this.copyIngredients();

    //   this.dragulaService.createGroup('bag-o-ingredients', {
    //     direction: 'vertical',
    //     revertOnSpill: true,
    //     moves: (el, container, handle, sibling) => {
    //       console.log(handle.className);
    //       return !!handle.className.match(/ingredient-drag-handle/);
    //     }
    //   });
    //
    //   /*
    //   Seems as if Dragula is now severing the reference to this.ingredients after drag-n-drop
    //   and causing the recipe not be updated properly when saving.
    //    */
    //   const dragulaSub = this.dragulaService
    //     .dropModel<Ingredient>('bag-o-ingredients')
    //     .subscribe(data => {
    //       data.targetModel.forEach(i => {
    //         this.ingredients.shift();
    //         this.ingredients.push(i);
    //         this.copyIngredients();
    //       });
    //     });
    //   this.subs.push(dragulaSub);

    this.subs.push(
      this.convertToSimpleEntryService.convertToSimpleEntry$.subscribe((convert) => {
        if (convert) {
          this.convertIngredients();
        }
      })
    );
  }

  private copyIngredients() {
    this._ingredients = Array.from(this.ingredients);
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  addIngredient() {
    this.ingredients.push(new Ingredient());
    this.copyIngredients();
  }

  removeIngredient(ingredient) {
    console.log(ingredient);
    const index: number = this.ingredients.indexOf(ingredient);
    if (index !== -1) {
      this.ingredients.splice(index, 1);
    }
    this.copyIngredients();
  }

  private hasClass(el: any, name: string) {
    return new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)').test(el.className);
  }

  private addClass(el: any, name: string) {
    if (!this.hasClass(el, name)) {
      el.className = el.className ? [el.className, name].join(' ') : name;
    }
  }

  private removeClass(el: any, name: string) {
    if (this.hasClass(el, name)) {
      el.className = el.className.replace(new RegExp('(?:^|\\s+)' + name + '(?:\\s+|$)', 'g'), '');
    }
  }

  drop(event: CdkDragDrop<any>) {
    moveItemInArray(this.ingredients, event.previousIndex, event.currentIndex);
  }

  convertIngredients() {
    if (this.processingConvertIngredients) {
      return;
    }

    this.processingConvertIngredients = true;

    this.ingredientErrors = [];

    if (this.simpleInput === undefined || this.simpleInput === null || this.simpleInput === '') {
      const ingredientInput = this.ingredients.map((i) => {
        return stripTags(formatIngredient(i));
      });

      this.simpleInput = ingredientInput.join('\n');
    }

    const ingredientInputs = this.simpleInput
      .trim()
      .split('\n')
      // filter empty lines
      .filter((line) => line.trim().length > 0);

    from(this.lprxApi.recipes.getIngredients(ingredientInputs)).subscribe({
      next: (ingredientsResponse) => {
        // if there are no ingredients and there are errors, then show the errors
        if (
          (!this.ingredients || this.ingredients.length === 0) &&
          ingredientsResponse.errors &&
          ingredientsResponse.errors.length > 0
        ) {
          this.ingredientErrors = ingredientsResponse.errors;
        } else {
          // Remove non-errored from this.ingredients
          const indicesToRemove = [];

          for (const i of this.ingredients) {
            // Check for errored ingredients, normalize the casing
            const foundErroredIngredient = ingredientsResponse.errors.find(
              (e) =>
                e.original_text.trim().toLowerCase() ===
                stripTags(formatIngredient(i)).trim().toLowerCase()
            );

            if (foundErroredIngredient === undefined) {
              // remove the ingredient
              indicesToRemove.push(this.ingredients.indexOf(i));
            }
          }

          // Remove elements in reverse order to avoid index shifting
          for (let i = indicesToRemove.length - 1; i >= 0; i--) {
            this.ingredients.splice(indicesToRemove[i], 1);
          }

          // Add new ingredients
          this.ingredients.push(...ingredientsResponse.ingredients);

          // // Notify service
          this.recipeEditorService.updatedIngredient();

          // // Step 1: Remove errored and duplicate ingredients
          // const indicesToRemove = [];
          // for (let i = 0; i < this.ingredients.length; i++) {
          //   // Check for errored ingredients
          //   const foundErroredIngredient = ingredientsResponse.errors.find((e) => {
          //     return (
          //       e.original_text ===
          //       stripTags(formatIngredient(this.ingredients[i])).trim().toLowerCase()
          //     );
          //   });
          //
          //   // Check for duplicate ingredients
          //   const foundDuplicateIngredient = ingredientsResponse.ingredients.find((newIng) => {
          //     return (
          //       stripTags(formatIngredient(newIng)).trim().toLowerCase() ===
          //       stripTags(formatIngredient(this.ingredients[i])).trim().toLowerCase()
          //     );
          //   });
          //
          //   console.log(foundDuplicateIngredient);
          //
          //   if (foundErroredIngredient !== undefined || foundDuplicateIngredient !== undefined) {
          //     indicesToRemove.push(i);
          //   }
          // }
          //
          // // Remove elements in reverse order to avoid index shifting
          // for (let i = indicesToRemove.length - 1; i >= 0; i--) {
          //   this.ingredients.splice(indicesToRemove[i], 1);
          // }
          //
          // // Step 2: Add new ingredients
          // this.ingredients.push(...ingredientsResponse.ingredients);
          //
          // // Notify service
          // this.recipeEditorService.updatedIngredient();
        }
      },
      error: (err) => {
        this.processingConvertIngredients = false;
      },
      complete: () => {
        this.processingConvertIngredients = false;
        this.recipeEditorService.updatedIngredient();
      },
    });
  }

  toggleAdvanced(ingredient: Ingredient) {
    if (this.isAdvancedOpen(ingredient)) {
      this.showIngredientAdvanced.delete(ingredient);
    } else {
      this.showIngredientAdvanced.add(ingredient);
      // remove from edit
      this.editIngredient.delete(ingredient);
    }
  }

  isAdvancedOpen(ingredient: Ingredient) {
    return this.showIngredientAdvanced.has(ingredient);
  }

  showEdit(ingredient: Ingredient) {
    this.editIngredient.add(ingredient);
    // remove from show advanced
    this.showIngredientAdvanced.delete(ingredient);
  }

  ingredientChanged($event: Ingredient, existingIngredient: Ingredient) {
    console.log({ $event, existingIngredient });
    this.editIngredient.delete(existingIngredient);
    // find existing ingredient and replace it
    const index = this.ingredients.indexOf(existingIngredient);
    if (index >= 0) {
      this.ingredients[index] = $event;
      this.updateSimpleInput();
    }
  }

  addIngredientSimple() {
    this.addIngredientError = null;

    if (this.newSimpleIngredient && this.newSimpleIngredient.trim().length > 0) {
      this.lprxApi.recipes
        .getIngredients([this.newSimpleIngredient])
        .then((result) => {
          console.log(result);
          if (result.ingredients.length === 1) {
            this.ingredients.push(result.ingredients[0]);
            this.updateSimpleInput();
            this.newSimpleIngredient = '';
            this.recipeEditorService.updatedIngredient();
          } else {
            this.addIngredientError =
              'Could not parse ingredient. Error: ' +
              result.errors[0].warning.replace('on line 1', '.');
          }
        })
        .catch((err) => {
          this.addIngredientError = 'Could not parse ingredient.';
        });
    }
  }

  deleteIngredient(ingredient: Ingredient) {
    const index = this.ingredients.indexOf(ingredient);
    if (index >= 0) {
      this.ingredients.splice(index, 1);
      this.updateSimpleInput();
      this.recipeEditorService.updatedIngredient();
    }
  }

  updateSimpleInput() {
    this.simpleInput = this.ingredients.map((i) => stripTags(formatIngredient(i))).join('\n');
  }

  handleReturnKey($event: KeyboardEvent) {
    if ($event.key === 'Enter' || $event.keyCode === 13) {
      this.addIngredientSimple();
      $event.preventDefault();
    }
  }

  mapCategory(groceryCategory: string) {
    // todo: this should be done on the server prior to sending the recipe to the client
    return GroceryCategories.mapOldCategoryToNew(groceryCategory);
  }
}
