import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import axios from 'axios';
import { ToastrService } from 'ngx-toastr';
import { from, Observable, Subscription } from 'rxjs';
import { ObservedValueOf } from 'rxjs/internal/types';
import { switchMap, take } from 'rxjs/operators';
import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { allNutrients } from '../../../../../lprx-shared-lib/default-nutrients';
import { GetUploadUrlRequest } from '../../../../../lprx-shared-lib/dto/get-upload-url.request';
import { PractitionerPermissions, User } from '../../../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../../../lprx-shared-lib/entities/user/UserType';
import { Tool } from '../../../../../lprx-shared-lib/recipe/tool';
import { GetUploadUrlResponse } from '../../../../../lprx-shared-lib/resources/get-upload-url.response';
import { Api, Storage } from '../../../../../providers/aws.api';
import { LprxApiProvider } from '../../../../../providers/lprx-api/api-provider';
import { AuthService } from '../../../../auth.service';
import { LayoutService } from '../../../../layout/layout.service';
import { Recipe } from '../../../../../lprx-shared-lib/entities/recipe/Recipe';
import { CdnService } from '../../../../service/cdn.service';
import { IDGeneratorService } from '../../../../service/idgenerator.service';
import { RecipeService } from '../../../../service/recipe.service';
import { swalOptions } from '../../../../utilities/sweet-alert/sweet-alert';
import { RecipeEditorService } from '../../recipe-editor.service';
import { NutrientsCollection } from '../../../../../lprx-shared-lib/recipe/NutrientsCollection';
import { errorMessage } from '../../../../utilities/alert-http-error';

enum FormType {
  Create,
  Edit,
}

/**
 * Generated class for the RecipeEditorComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-editor-form',
  templateUrl: 'recipe-editor-form.html',
  styleUrls: ['./recipe-editor-form.scss'],
})
export class RecipeEditorFormComponent implements OnInit, OnDestroy {
  static _lprxApi: LprxApiProvider;

  @Input() recipe: Recipe;

  nutrients: NutrientsCollection;

  recipeImage: string;

  prepMinutes = 0;
  prepHours = 0;
  totalHours = 0;
  totalMinutes = 0;
  user: User;

  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;

  formType: FormType = FormType.Create;

  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  froalaOptions: any = null;

  private subscriptions: Subscription[] = [];
  options = ['Hello', 'World'];
  isNew: boolean = false;
  showForm: boolean = false;

  constructor(
    private api: Api,
    private location: Location,
    private recipeService: RecipeService,
    private auth: AuthService,
    // private dragulaService: DragulaService,
    private route: ActivatedRoute,
    private cdn: CdnService,
    private router: Router,
    private toastr: ToastrService,
    private id: IDGeneratorService,
    private recipeEditorService: RecipeEditorService,
    public layout: LayoutService,
    private lprxApi: LprxApiProvider
  ) {}

  /**
   * Init
   */
  ngOnInit() {
    // Auth.currentUserInfo().then(info => this.userId = info.id);

    // this.dragulaService.createGroup('bag-o-ingredients', {
    //     direction: 'vertical',
    //     revertOnSpill: true,
    //     moves: function (el, container, handle, sibling) {
    //         console.log(handle);
    //         return false;
    //     }
    // });

    RecipeEditorFormComponent._lprxApi = this.lprxApi;

    // tslint:disable-next-line:no-this-assignment
    const _component = this;

    this.froalaOptions = {
      events: {
        'image.beforeUpload'(files: FileList) {
          // Do something here.
          // this is the editor instance.
          console.log(this);
          console.log(files);
          //

          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log(file);

            const payload: GetUploadUrlRequest = {
              contentType: file.type,
              filename: file.name,
            };

            console.log(payload);

            from(RecipeEditorFormComponent._lprxApi.post('v3/utils/froala-upload', payload))
              .pipe(
                switchMap((uploadUrlResponse: GetUploadUrlResponse) => {
                  console.log(uploadUrlResponse);
                  return from(
                    axios
                      .put(uploadUrlResponse.signedUrl, file, {
                        headers: {
                          'Content-Type': file.type,
                        },
                      })
                      .then((res) => ({ res, key: uploadUrlResponse.key }))
                  );
                })
              )
              .subscribe(({ res, key }) => {
                console.log(res);
                console.log(key);

                const url = _component.cdn.getUrl(key.replace('public/', ''));
                console.log(url);

                this.image.insert(url, null, null, this.image.get());
              });
          }

          return false;
        },
      },
      imageUpload: true,
      key: environment.froalaOptionsKey,
      charCounterCount: false,
      toolbarButtons: [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subscript',
        'superscript',
        'outdent',
        'indent',
        'clearFormatting',
        '|',
        'insertLink',
        'insertImage',
      ],
      toolbarButtonsXS: [
        'undo',
        'redo',
        '-',
        'bold',
        'italic',
        'underline',
        '-',
        'insertLink',
        'insertImage',
      ],
    };

    const initializationSub = this.auth.user$
      .pipe(
        take(1),
        switchMap((user) => {
          this.user = user;

          // if current user is distributor, but does not have permission to modify recipes
          // then redirect go back
          if (
            this.user.isDistributor &&
            !this.user.hasPermission(PractitionerPermissions.recipes)
          ) {
            this.toastr.error('You do not have permission to modify recipes.', 'Error');
            this.location.back();
            this.showForm = false;
          } else {
            this.showForm = true;
          }

          return this.route.params;
        })
      )
      .subscribe({
        next: (params) => {
          if (params['recipeId'] && params['recipeId'] !== 'new') {
            this.api.get(`recipe/${params['recipeId']}`).then((result) => {
              this.recipe = Recipe.fromObject(result);
              this.formType = FormType.Edit;
              this.initRecipe();
            });
          } else if (!this.recipe) {
            console.log('Initializing new Recipe.');
            this.recipe = new Recipe();
            this.recipe.id = this.id.generate('recipe');
            this.recipe.image = 'images/recipe/lprx-default-recipe-image.png';
            this.formType = FormType.Create;
            this.isNew = true;
            this.refreshImage();
          }
        },
        error: (err) => {},
      });
    this.subscriptions.push(initializationSub);

    const updatedIngredientSub = this.recipeEditorService.getUpdatedIngredient$().subscribe(() => {
      this.updateNutrients();
    });
    this.subscriptions.push(updatedIngredientSub);

    // // code above is not working some case when ingredients have change
    // // so backing it up with this to make sure it is always updated
    // this.subscriptions.push(
    //   interval(1000).subscribe(() => {
    //     this.updateNutrients();
    //   })
    // );
  }

  /**
   * Clean Up
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  /**
   * Generates an id for a recipe based on the time
   * @returns {string}
   */
  private makeId() {
    return Date.now().toString().split('').reverse().join('');
  }

  private initRecipe() {
    this.initTimes();
    if (this.recipe.image) {
      this.recipeImage = this.cdn.getSizedImageUrl(this.recipe.image, 375, 375);
    }

    this.updateNutrients();
  }

  private updateNutrients() {
    console.log('updating nutrients');
    this.nutrients = this.recipe.getNutrients(allNutrients);
  }

  private initTimes() {
    this.prepHours = Math.floor(this.recipe.prepTime / 60);
    this.prepMinutes = this.recipe.prepTime % 60;
    this.totalHours = Math.floor(this.recipe.totalTime / 60);
    this.totalMinutes = this.recipe.totalTime % 60;
  }

  calcPrepTime() {
    this.fixTimes();
    this.recipe.prepTime = this.prepHours * 60 + this.prepMinutes;
  }

  calcTotalTime() {
    this.fixTimes();
    this.recipe.totalTime = this.totalHours * 60 + this.totalMinutes;
  }

  _fix(val) {
    const number = val === null || val.toString().trim() === '' ? 0 : val;
    console.log(number);
    return parseInt(number, 10);
  }

  fixTimes() {
    this.totalHours = this._fix(this.totalHours);
    this.totalMinutes = this._fix(this.totalMinutes);
    this.prepHours = this._fix(this.prepHours);
    this.prepMinutes = this._fix(this.prepMinutes);
  }

  uploadFromFile(event) {
    const files = event.target.files;
    const file = files[0];
    const { type, name } = file;
    const ext = name.split('.').reverse()[0];
    const key = (
      'images/recipe/' +
      this.recipe.id +
      '/' +
      Date.now().toString() +
      '.' +
      ext
    ).toLowerCase();

    Storage.put(key, file, { contentType: type, level: 'public' })
      .then((result: { key: string }) => {
        this.recipe.image = result.key;
        this.refreshImage();
      })
      .catch((err) => {
        console.log(err);
        // logger.error(err);
      });
  }

  refreshImage() {
    this.recipeImage = this.cdn.getSizedImageUrl(this.recipe.image, 375, 375);
  }

  saveRecipe() {
    console.log(this.recipe);
    this.recipe.addedBy = 'lprx';

    if (this.user.isDistributor && !this.recipe.copyrightAcknowledged) {
      alert('Please confirm the copyright alert.');
      return;
    }

    if (this.user.userType === UserType.Client || this.user.userType === UserType.Distributor) {
      const save: Observable<Recipe> =
        this.formType === FormType.Create
          ? this.recipeService.save(this.recipe, 'v3')
          : this.recipeService.update(this.recipe.id, this.recipe);
      save.subscribe({
        next: (recipe: Recipe) => {
          this.toastr.success(`You saved the recipe '${this.recipe.title}'`, 'Recipe Saved');
          if (this.user.userType === UserType.Client) {
            this.router.navigateByUrl('/planner/recipes');
          } else {
            this.location.back();
          }
        },
        error: (err) => {
          this.toastr.error(errorMessage(err), 'Error');
        },
      });
      // } else if (this.user.userType === UserType.Distributor) {
      //   this.recipeService.save(this.recipe).subscribe((recipe: Recipe) => {
      //     this.toastr.success(`You saved the recipe '${this.recipe.title}'`, 'Recipe Saved');
      //     this.location.back();
      //   });
    } else if (this.user.userType === UserType.Admin) {
      this.api
        .post('recipe', this.recipe)
        .then((response) => {
          console.log(response);
          // this.createMealPlan();
          this.toastr.success(`You saved the recipe '${this.recipe.title}'`, 'Recipe Saved');
          this.location.back();
          // this.router.navigateByUrl('/admin/recipe');
        })
        .catch((err) => console.log(err));
    }
  }

  updated(mealTypes: string[]) {
    console.log(mealTypes);
    this.recipe.mealTypes = mealTypes;
  }

  onUpdatedMealPlans(mealPlans: string[]) {
    this.recipe.forMealPlans = mealPlans;
    console.log(this.recipe.forMealPlans);
  }

  onUpdatedEquipment(tools: Tool[]) {
    this.recipe.equipment = tools;
    console.log(this.recipe.equipment);
  }

  onUpdatedContributor(contributorId: string) {
    this.recipe.contributedBy = contributorId;
  }

  updatedServings() {
    if (!this.recipe.servings || this.recipe.servings < 0 || this.recipe.servings > 100) {
      this.recipe.servings = 1;
    }
    this.updateNutrients();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim()) {
      this.recipe.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(tag: any): void {
    const index = this.recipe.tags.indexOf(tag);
    if (index >= 0) {
      this.recipe.tags.splice(index, 1);
    }
  }

  delete() {
    let swalPromise: Promise<Observable<ObservedValueOf<Promise<any>>>>;

    if (!this.isNew) {
      const options: SweetAlertOptions = swalOptions({
        title: `Are you sure you want to delete the recipe '${this.recipe.title}'?`,
        html:
          `<p>Deleting a recipe will make it unsearchable. ` +
          `However, it will remain on current weekly plans.</p>` +
          `<p>You won't be able to revert this!</p>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
      });

      swalPromise = Swal.fire(options).then((result: SweetAlertResult) => {
        if (result.value) {
          return this.recipeService.delete(this.recipe.id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          throw new Error('Canceled recipe deletion.');
        }
      });
    } else {
      swalPromise = Promise.resolve(null);
    }

    return from(swalPromise).subscribe(() => {
      this.toastr.success(`Recipe '${this.recipe.title}' has been deleted.`);
      this.location.back();
    });
  }
}
