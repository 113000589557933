/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/resources/change-file-access-level-dto.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose } from 'class-transformer';
import { DistributorAccessLevel } from '../distributor-access-level';

@Exclude()
export class ChangeFileAccessLevelDto {
  @Expose()
  distributorAccessLevel: DistributorAccessLevel;
}
