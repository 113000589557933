import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nutrition-facts-row',
  templateUrl: './nutrition-facts-row.component.html',
  styleUrls: ['./nutrition-facts-row.component.scss']
})
export class NutritionFactsRowComponent implements OnInit {
  @Input() nutritionFacts;
  @Input() title;
  @Input() boldTitle = false;
  @Input() boldValues = false;
  @Input() showDailyPercentage = true;
  @Input() showHeader = false;

  constructor() {}

  ngOnInit() {}
}
