/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Ingredient.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import { Default } from '../../utils/default.decorator';
import { NdbMatch } from './NdbMatch';
import { Nutrients } from './Nutrients';
import { GroceryCategories } from '../../GroceryCategories';

@Exclude()
export class Ingredient {
  @Expose()
  @Transform((value: string) => (value ? value.trim() : null))
  equivalency?: string | null;

  @Expose()
  @Transform((value: string) => (value ? GroceryCategories.mapOldCategoryToNew(value.trim()) : ''))
  groceryCategory: string = '';

  @Expose()
  @Transform((value: string, obj: Ingredient) =>
    value && value !== ''
      ? value.trim()
      : GroceryCategories.findKeyByName(GroceryCategories.mapOldCategoryToNew(obj.groceryCategory))
  )
  groceryCategoryKey: string = '';

  @Expose()
  @Transform((value: string) => (value ? value.trim() : ''))
  item: string = '';

  @Expose()
  @Type(() => NdbMatch)
  @Default(new NdbMatch())
  ndb = new NdbMatch();

  @Expose()
  @Transform((value: string) => (value ? value.trim() : null))
  note?: string | null;

  @Expose()
  // @Type(() => Nutrients)
  nutrients: Nutrients;

  @Expose()
  @Default(0)
  quantity = 0;

  @Expose()
  @Transform((value: string) => (value ? value.trim() : null))
  technique?: string | null;

  @Expose()
  @Transform((value: string) => (value ? value.trim() : null))
  unit: string;

  @Expose()
  ownedBy: string;

  @Expose()
  @Default(false)
  fromSimpleInput?: boolean = false;

  @Expose()
  display?: string | null;

  static fromObject(object: any): Ingredient {
    return plainToClass(Ingredient, object);
  }
}

export class IngredientNDbMatch {
  description: string;
  ndbNo: string;
  measurement: string;
  amount: number;
  /** @deprecated in favor of measurement */
  weight: string;
}
