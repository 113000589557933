import { Component, Input, OnInit } from '@angular/core';
import { Recipe } from '../../../../lprx-shared-lib/entities/recipe/Recipe';
import { RecipeResult } from '../../../model/entities/recipe-result';

@Component({
  selector: 'app-recipe-drag',
  templateUrl: './recipe-drag.component.html',
  styleUrls: ['./recipe-drag.component.scss'],
})
export class RecipeDragComponent implements OnInit {
  @Input() recipe: Recipe | RecipeResult;

  constructor() {}

  ngOnInit(): void {}
}
