/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/distributor.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Transform, Type } from 'class-transformer';
import { IsBoolean, IsInt, IsNotEmpty, IsNumber, IsPositive } from 'class-validator';
import { SubscriptionState } from '../prime/commands/subscription-state';
import { sanitizeEmail } from '../sanitize-email';
import { Default } from '../utils/default.decorator';
import { createId } from '../utils/id-generator';
import { BaseItem } from './base-item';
import { DistributorType } from './distributor-type';
import { DistributorFeatures } from './user/distributor-features';

const defaultPremiumTerminatesAt = 1584458106000;

@Exclude()
export class Distributor implements BaseItem {
  // partition key
  @Expose()
  @Default(() => createId('dist'))
  id: string = createId('dist');

  @Expose()
  applicationId: string;

  @Expose()
  @Default('Coming soon...')
  bio: string = 'Coming soon...';

  @Expose()
  business: string;

  @Expose()
  country: string;

  @Expose()
  @Default(Date.now())
  @IsPositive()
  createdAt: number = Date.now();

  @Expose()
  credentials: string;

  @Expose()
  currDistributor: string;

  @Expose()
  // @Transform((v: string) => v ? v.toLowerCase() : v) -- breaks build "ERROR in : TypeError: Cannot read property 'kind' of undefined"
  email: string;

  @Expose()
  @IsNotEmpty()
  firstName: string;

  @Expose()
  isSetupComplete: boolean;

  @Expose()
  @IsNotEmpty()
  lastName: string;

  @Expose()
  @Default('images/default-logo.png')
  logo: string = 'images/default-logo.png';

  @Expose()
  @Default(Date.now())
  @IsPositive()
  modifiedAt: number = Date.now();

  // // todo: eliminate this
  // @Expose()
  // @Default(new PayoutConfiguration())
  // payoutConfiguration: PayoutConfiguration = new PayoutConfiguration();

  @Expose()
  @Default('images/recipe/lprx-default-recipe-image.png')
  photo: string = 'images/recipe/lprx-default-recipe-image.png';

  @Expose()
  postalCode: string;

  @Expose()
  setupCompletedAt: number;

  @Expose()
  setupCompletedBy: string;

  @Expose()
  status: string;

  @Expose()
  stripeCustomerId: string;

  @Expose()
  vanityId: string;

  @Expose()
  website: string;

  @Expose()
  @Default(true)
  canReceivePayouts = true;

  @Expose()
  @Default(30)
  payoutCommission = 30;

  /**
   * Whether or not the distributor can pay directly using a credit card
   */
  @Expose()
  @Default(true)
  canPayDirectly = true;

  @Expose()
  @Default(false)
  @IsBoolean()
  isDeleted = false;

  @Expose()
  @Default(defaultPremiumTerminatesAt)
  @Transform((value) => (value < defaultPremiumTerminatesAt ? defaultPremiumTerminatesAt : value))
  @IsNumber()
  premiumTerminatesAt = defaultPremiumTerminatesAt;

  @Expose()
  @Default(0)
  @IsNumber()
  primeTerminatesAt: number = 0;

  @Expose()
  primeSubscriptionId?: string;

  @Expose()
  primeSubscriptionState?: SubscriptionState;

  @Expose()
  @Default(0)
  @IsInt()
  distributorCreditBalance = 0;

  @Expose()
  referredBy?: string;

  /**
   * @deprecated Possibly deprecated!
   */
  @Expose()
  setupStatus: string;

  @Expose()
  @Default([])
  @Type(() => String)
  features: Array<keyof typeof DistributorFeatures>;

  @Expose()
  @Default(DistributorType.HealthCareProfessional)
  type: DistributorType = DistributorType.HealthCareProfessional;

  @Expose()
  @Default(5)
  maxUsers: number = 5;

  constructor(business?: string, firstName?: string, lastName?: string, email?: string) {
    if (arguments) {
      this.business = business || '';
      this.firstName = firstName || '';
      this.lastName = lastName || '';
      this.email = sanitizeEmail(email || '');
    }
  }

  static create(business: string, firstName: string, lastName: string, email: string) {
    const d = new Distributor();
    d.id = createId('dist');
    d.business = business;
    d.firstName = firstName;
    d.lastName = lastName;
    d.email = sanitizeEmail(email);
    return d;
  }

  /**
   *
   * @param asOf
   */
  isActive(asOf = Date.now()) {
    return this.premiumTerminatesAt >= asOf || this.primeTerminatesAt >= asOf;
  }

  setReferrer(referrerId: string) {
    if (this.id === referrerId) {
      throw new Error("Can't refer self.");
    }

    this.referredBy = referrerId;
  }

  /**
   * Whether the distributor has a specific feature
   *
   * @param {keyof typeof DistributorFeatures} feature
   * @returns {boolean}
   */
  hasFeature(feature: keyof typeof DistributorFeatures) {
    this.features = this.features || [];
    return this.features.includes(feature);
  }
}
