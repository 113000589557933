/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/notification/notification-meta-data.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class NotificationMetaData {
  [key: string]: any;
}
