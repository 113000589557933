import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs/Observable';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { Api } from '../../providers/aws.api';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { AuthService } from '../auth.service';
import { DistributorsService } from '../service/distributors.service';
import { MealPlannerSetupCommand } from '../../lprx-shared-lib/dto/meal-planner-setup-command';

@Injectable({
  providedIn: 'root',
})
export class MealPlannerSetupService {
  constructor(
    private api: Api,
    private lprxApi: LprxApiProvider,
    private distributorsService: DistributorsService,
    private auth: AuthService,
  ) {}

  getDistributorOnboarding(distributorId: string, applicationId: string): Promise<Distributor> {
    return this.lprxApi.distributorApplications.getOnboarding(applicationId, distributorId);
  }

  getDistributorByVanityId(value: any): Observable<Distributor> {
    return fromPromise(this.api.get(`distributor/v/${value}`));
  }

  setProfileImage(id: string, url: string) {
    return this.api.post(`distributor/profile-image/${id}`, { url });
  }

  checkEmail(value: any): Observable<any> {
    return fromPromise(this.lprxApi.get(`v3/utils/check-email/${value}`));
  }

  cleanDistributorAccount(distributorId: string) {
    return this.distributorsService.cleanUserAccount(distributorId);
  }

  profileSetup(mealPlannerSetupCommand: MealPlannerSetupCommand) {
    return this.distributorsService.mealPlannerSetup(mealPlannerSetupCommand);
  }
}
