import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { classToClass, plainToClass } from 'class-transformer';
import * as moment from 'moment';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { DistributorService } from '../../distributor/distributor-service';
import { LayoutService } from '../../layout/layout.service';
import { Brand } from '../../model/entities/brand';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { daysOfTheWeek } from '../../utilities/days-of-the-week';
import { PlannerPrinterService } from '../planner-printer.service';
import { PlannerService } from '../planner.service';

interface RecipeMap {
  id: string;
  userServings: number;
  recipe: Recipe;
}

@Component({
  selector: 'app-planner-printer',
  templateUrl: './planner-printer.component.html',
  styleUrls: ['./planner-printer.component.scss'],
})
export class PlannerPrinterComponent implements OnInit, OnDestroy {
  @Input() mealPlan: MealPlan;
  daysOfTheWeek = daysOfTheWeek;
  private subs: Subscription[] = [];

  weeklyPlans: WeeklyPlan[];
  weeklyPlans$: Observable<WeeklyPlan[]>;

  @Input()
  weeklyPlan: WeeklyPlan;

  showPrintView = false;
  readyToPrint = false;
  renderingProgress = 1;
  renderingProgressStyle = this.sanitizer.bypassSecurityTrustStyle(1 + '%');
  isPrinting = false;
  brand: Brand;
  view: string = 'prepping';

  hiddenRecipes = new Set<string>();

  printPublicNotes = false;
  printPrivateNotes = false;
  printNutritionFacts = false;
  canShowNutritionFacts: boolean = true;
  backToPlannerUrl: string;
  recipesMap = new Map<string, RecipeMap>();
  recipesToPrint: RecipeMap[];

  constructor(
    private plannerPrinter: PlannerPrinterService,
    private plannerService: PlannerService,
    public layout: LayoutService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private distributorService: DistributorService
  ) {}

  reset() {
    this.readyToPrint = false;
    this.renderingProgress = 1;
    this.renderingProgressStyle = this.sanitizer.bypassSecurityTrustStyle(1 + '%');
    this.isPrinting = false;
    this.weeklyPlan = null;
    this.showPrintView = false;
  }

  ngOnInit() {
    console.log(`PlannerPrinterComponent.ngOnInit()`);

    this.subs.push(this.plannerConfigSub());
    this.subs.push(this.getWeeklyPlanSub());
    this.subs.push(this.getBrandSub());

    this.backToPlannerUrl = sessionStorage.getItem('plannerPath');
  }

  private getBrandSub() {
    return this.layout.getBrand().subscribe((brand) => (this.brand = brand));
  }

  private getWeeklyPlanSub() {
    return this.route.params
      .pipe(
        take(1),
        switchMap((params) => {
          console.log(params);
          if (params['master']) {
            return this.plannerService.getMasterPlan(params['mealPlanId'], params['weekNumber']);
          } else if (params['distributorId']) {
            return this.distributorService.clientPlanner.get(
              params['username'],
              params['weekNumber']
            );
          } else {
            return of(this.plannerService.getWeeklyPlan(params['weekNumber']));
          }
        })
      )
      .subscribe((wp) => {
        this.printWeeklyPlan(wp);
      });
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.pipe(take(1)).subscribe((config) => {
      this.canShowNutritionFacts = config.showNutritionFacts;
    });
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  date(weekNumber, day) {
    const [yearStr, weekStr] = weekNumber.split('.');
    const year = parseInt(yearStr, 10);
    let week = parseInt(weekStr, 10);
    if (day === 'sunday') {
      week++;
    }
    const date = moment().isoWeekYear(year).isoWeek(week).isoWeekday(day);
    return date.format('MMMM D, YYYY');
  }

  adjust() {
    // const container = this.el.nativeElement.querySelector('#weekly-planner-print-container');
    // const wrapper = this.el.nativeElement.querySelector('#weekly-planner-wrapper');
    // const wrapperWidth = wrapper.clientWidth;
    // const wrapperHeight = wrapper.clientHeight;
    // const diff = (wrapperWidth - wrapperHeight) / 2;
    // console.log('diff: ' + diff);
    //
    // wrapper.setAttribute('style', `position: relative; top: ${diff}px;`);
  }

  print() {
    this.isPrinting = true;
    this.view = 'prepping';
    window.print();
    // this.closePrinter();
  }

  private closePrinter() {
    this.reset();
    // this.plannerPrinter.donePrinting();
    this.router.navigateByUrl(sessionStorage.getItem('plannerPath'));
  }

  cancelPrint() {
    this.closePrinter();
  }

  /**
   * @param {WeeklyPlan} weeklyPlan
   */
  printWeeklyPlan(weeklyPlan: WeeklyPlan) {
    const wp = classToClass(weeklyPlan);

    const recipesMap = new Map<string, RecipeMap>();

    // this.recipesMap.clear();

    wp.getCards()
      .map((c: Card) => (c instanceof Card ? c : plainToClass(Card, c as object)))
      .filter((c) => c.isRecipe() && !c.isLeftover)
      .map(
        (c): RecipeMap => ({
          id: c.recipeId + '' + c.userServings,
          userServings: c.userServings,
          recipe: classToClass(c.getRecipe()),
        })
      )
      .forEach((a) => recipesMap.set(a.id, a));

    this.showPrintView = true;
    this.view = null;

    this.renderingProgress = 100;

    this.weeklyPlan = wp;

    this.recipesToPrint = Array.from(recipesMap.values());

    setTimeout(() => this.adjust(), 1000);
    // setTimeout(() => this.print(), 5000);
  }

  refreshHiddenRecipes() {
    // triggers change detection
    this.hiddenRecipes = new Set(this.hiddenRecipes.values());
  }

  hideRecipe(locator: string) {
    this.hiddenRecipes.add(locator);
    this.refreshHiddenRecipes();
  }

  showRecipe(locator: string) {
    this.hiddenRecipes.delete(locator);
    this.refreshHiddenRecipes();
  }
}
