import { Injectable, OnDestroy } from '@angular/core';
import { NgbDateStruct, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { classToClass } from 'class-transformer';
import { boolean, min } from 'mathjs';

import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  from,
  from as fromPromise,
  Observable,
  of,
  Subject,
} from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs/Subscription';
import { DaysOfTheWeek } from '../../lprx-shared-lib/days-of-the-week';
import { NutritionFactsDisplay } from '../../lprx-shared-lib/entities/user/nutrition-facts-display';
import { User } from '../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../lprx-shared-lib/entities/user/UserType';
import { WeeklyPlanType } from '../../lprx-shared-lib/entities/weeky-meal-plan/weekly-plan-type';
import {
  PlannerConfigNutrientLimits,
  PlannerConfiguration,
} from '../../lprx-shared-lib/planner/planner-configuration';
import { addWeek, subWeek } from '../../lprx-shared-lib/utils/getCurrentWeekNumber';
import { GroceryListDto, GroceryListItem, GroceryLists } from '../../lprx-shared-lib/utils/types';
import { nonCalendarStartWeek } from '../../lprx-shared-lib/vars';
import { AdminService } from '../../providers/admin/admin.service';
import { WeeklyPlansService } from '../../providers/apiv2/userwp';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { AdminMasterMealPlanService } from '../admin/service/admin-master-meal-plan.service';
import { AuthService } from '../auth.service';
import { DistributorService } from '../distributor/distributor-service';
import { LayoutService } from '../layout/layout.service';
import { Card } from '../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { ClientMealPlanService } from '../service/client/client-meal-plan.service';
import { RecipeCacheService } from '../service/recipe-cache.service';
import { getCurrentWeekNumber } from '../utilities/getCurrentWeekNumber';
import { constructOrCreateWeeklyPlan } from '../utilities/isValidWeeklyPlan';
import { RecipeOpenerService } from './recipe-opener.service';
import { RecipeToInsertDetails } from './recipe-to-insert-details';
import { WeeklyPlanUpdatedEvent } from './weekly-plan-updated-event';
import { groceryListKeyFromDates } from './grocery-list-key-from-dates';
import { updateMealNames } from '../../lprx-shared-lib/utils/update-meal-names';
import { NextDays } from './next-days';

@Injectable({ providedIn: 'root' })
export class PlannerService implements OnDestroy {
  private openedCardId: string;

  private readonly servingsChangedSubject = new Subject<number>();
  readonly getUpdatedServings$ = this.servingsChangedSubject.asObservable();

  private readonly plannerUpdatedSubject = new Subject<WeeklyPlanUpdatedEvent>();
  public readonly plannerUpdated$ = this.plannerUpdatedSubject.asObservable();

  private canEditSubject = new BehaviorSubject<boolean>(false);

  private instance: number;

  public weeklyPlans: WeeklyPlan[] = [];
  private weeklyPlansSubject = new BehaviorSubject<WeeklyPlan[]>(null);

  public readonly enableSlideOutPlanner = new BehaviorSubject<boolean>(false);
  public readonly enableSlideOutPlanner$ = this.enableSlideOutPlanner.asObservable();

  /**
   * @note BehaviorSubject<WeeklyPlan[]>
   */
  readonly weeklyPlans$ = this.weeklyPlansSubject.asObservable();

  // open or closed
  private plannerOpenState = new Subject<boolean>();
  readonly isPlannerOpen$ = this.plannerOpenState.asObservable();

  public client?: User = undefined;
  private clientSubject = new BehaviorSubject<User>(null);
  readonly client$ = this.clientSubject.asObservable();

  private canCustomizeSubject = new BehaviorSubject(false);
  public readonly canCustomize$ = this.canCustomizeSubject.asObservable();

  plannerConfig = new BehaviorSubject(new PlannerConfiguration());
  public readonly plannerConfig$ = this.plannerConfig.asObservable();

  subs: Subscription[] = [];

  readonly recipeToInsert = new BehaviorSubject<RecipeToInsertDetails>(null);
  recipeToInsert$ = this.recipeToInsert.asObservable();
  private modalRef: NgbModalRef;
  private clientUsername: string;

  constructor(
    private adminService: AdminService,
    private clientMealPlanService: ClientMealPlanService,
    private distributorService: DistributorService,
    private recipeOpener: RecipeOpenerService,
    private recipeCache: RecipeCacheService,
    private weeklyPlansService: WeeklyPlansService,
    private masterMealPlanService: AdminMasterMealPlanService,
    private auth: AuthService,
    private layout: LayoutService,
    private lprxApi: LprxApiProvider
  ) {
    console.log('Init PlannerService ------------------------------------------------------------');

    this.instance = Math.floor(Math.random() * 1000);
    this.initSubs();

    this.recipeToInsert.next(null);
  }

  private initSubs() {}

  /**
   * Client Load Planner
   */
  clientLoadPlanner(startingWeekNumber: string) {
    this.clear();

    this.subs.push(
      this.auth.clientUser$.subscribe((user) => {
        this.canCustomizeSubject.next(user.hasCustomPlanner());
      })
    );

    const loggedInUser$ = this.auth.getLoggedInUser();
    this.subs.push(
      loggedInUser$
        .pipe(
          switchMap((user) =>
            combineLatest([of(user), from(this.lprxApi.mealPlans.get(user.mealPlanId))])
          ),
          switchMap(([user, mealPlan]) => {
            if (!startingWeekNumber) {
              startingWeekNumber = mealPlan.nonCalendar
                ? user.lastWeekNumberLoaded || nonCalendarStartWeek
                : getCurrentWeekNumber();
            }
            return combineLatest([
              of(user),
              this.clientMealPlanService.getWeeklyMealPlan(startingWeekNumber),
            ]);
          })
        )
        .subscribe(([user, weeklyPlan]) => {
          if (!weeklyPlan) {
            return;
          }
          this.addWeeklyPlan(weeklyPlan);

          const plannerConfig = new PlannerConfiguration();
          const showNutritionFacts =
            user.nutritionFactsDisplay === NutritionFactsDisplay.Default
              ? weeklyPlan.mealPlan.enableNutritionalData
                ? NutritionFactsDisplay.Show
                : NutritionFactsDisplay.Hide
              : user.nutritionFactsDisplay;

          plannerConfig.showNutritionFacts = showNutritionFacts === NutritionFactsDisplay.Show;

          plannerConfig.ingredientPreferences = user.ingredientPreferences;

          this.nextPlannerConfig(plannerConfig);

          const nutrientLimits = user.nutrientLimits;

          this.mergeNutrientLimits(nutrientLimits);

          // easy way to pick it up later in buy component
          this.layout.availablePlanTypes.next(weeklyPlan.mealPlan.availablePlanTypes);

          let weekNumber = weeklyPlan.weekNumber;

          const showMoreWeeks =
            weeklyPlan.mealPlan.initialWeeksToLoad && weeklyPlan.mealPlan.initialWeeksToLoad > 1;

          if (showMoreWeeks) {
            for (let i = 1; i < weeklyPlan.mealPlan.initialWeeksToLoad; i++) {
              weekNumber = addWeek(weekNumber);
              this.subs.push(
                this.clientMealPlanService
                  .getWeeklyMealPlan(weekNumber)
                  .subscribe((nextWeeklyPlan) => {
                    this.addWeeklyPlan(nextWeeklyPlan);
                  })
              );
            }
          }

          /*
           * The client isn't allowed to see the upcoming week if it's Mon-Thurs
           */
          const dayNumber = new Date().getDay();
          const isMonTuesWedOrThurs = dayNumber >= 1 && dayNumber <= 3;
          if (!isMonTuesWedOrThurs) {
            const nextWeekNumber = addWeek(weekNumber);
            this.subs.push(
              this.clientMealPlanService
                .getWeeklyMealPlan(nextWeekNumber)
                .subscribe((nextWeeklyPlan) => {
                  this.addWeeklyPlan(nextWeeklyPlan);
                })
            );
          }
        })
    );

    // this.subs.push(
    //   combineLatest([
    //     user$,
    //
    //   ]).subscribe(([user, weeklyPlan]: [User, WeeklyPlan]) => {
    //     if (!weeklyPlan) {
    //       return;
    //     }
    //     this.addWeeklyPlan(weeklyPlan);
    //
    //     const plannerConfig = new PlannerConfiguration();
    //     const showNutritionFacts =
    //       user.nutritionFactsDisplay === NutritionFactsDisplay.Default
    //         ? weeklyPlan.mealPlan.enableNutritionalData
    //           ? NutritionFactsDisplay.Show
    //           : NutritionFactsDisplay.Hide
    //         : user.nutritionFactsDisplay;
    //
    //     plannerConfig.showNutritionFacts = showNutritionFacts === NutritionFactsDisplay.Show;
    //
    //     plannerConfig.ingredientPreferences = user.ingredientPreferences;
    //
    //     this.plannerConfig.next(plannerConfig);
    //
    //     const nutrientLimits = user.nutrientLimits;
    //
    //     this.mergeNutrientLimits(nutrientLimits);
    //
    //     // easy way to pick it up later in buy component
    //     this.layout.availablePlanTypes.next(weeklyPlan.mealPlan.availablePlanTypes);
    //
    //     let weekNumber = weeklyPlan.weekNumber;
    //
    //     const showMoreWeeks =
    //       weeklyPlan.mealPlan.initialWeeksToLoad && weeklyPlan.mealPlan.initialWeeksToLoad > 1;
    //
    //     if (showMoreWeeks) {
    //       for (let i = 1; i < weeklyPlan.mealPlan.initialWeeksToLoad; i++) {
    //         weekNumber = addWeek(weekNumber);
    //         this.subs.push(
    //           this.clientMealPlanService
    //             .getWeeklyMealPlan(weekNumber)
    //             .subscribe((nextWeeklyPlan) => {
    //               this.addWeeklyPlan(nextWeeklyPlan);
    //             })
    //         );
    //       }
    //     }
    //
    //     /*
    //      * The client isn't allowed to see the upcoming week if it's Mon-Thurs
    //      */
    //     const dayNumber = new Date().getDay();
    //     const isMonTuesWedOrThurs = dayNumber >= 1 && dayNumber <= 3;
    //     if (!isMonTuesWedOrThurs) {
    //       const nextWeekNumber = addWeek(weekNumber);
    //       this.subs.push(
    //         this.clientMealPlanService
    //           .getWeeklyMealPlan(nextWeekNumber)
    //           .subscribe((nextWeeklyPlan) => {
    //             this.addWeeklyPlan(nextWeeklyPlan);
    //           })
    //       );
    //     }
    //   })
    // );
  }

  public mergeNutrientLimits(nutrientLimits: PlannerConfigNutrientLimits) {
    console.log('mergeNutrientLimits', nutrientLimits);
    const plannerConfiguration = this.plannerConfig.getValue();
    if (nutrientLimits?.daily && plannerConfiguration?.nutrientLimits?.daily) {
      for (const n of nutrientLimits.daily) {
        if (!n.enabled) {
          continue;
        }

        for (const cnl of plannerConfiguration.nutrientLimits.daily) {
          if (cnl.key === n.key && cnl.source !== 'client') {
            cnl.high = n.high;
            cnl.low = n.low;
            cnl.source = n.source || 'mealplan';
            cnl.enabled = true;
          }
        }
      }
    }
    console.log(plannerConfiguration);
    this.nextPlannerConfig(plannerConfiguration);
  }

  public nextPlannerConfig(plannerConfiguration: PlannerConfiguration) {
    console.log('nuts:', plannerConfiguration.nutrientLimits.daily[0].high);
    this.plannerConfig.next(plannerConfiguration);
  }

  /**
   * Load the client's planner for a distributor
   *
   * @param clientUsername
   */
  distLoadPlanner(clientUsername: string) {
    this.clear();

    const distServiceSub = this.distributorService
      .getClient$(clientUsername)
      .pipe(
        take(1),
        tap((client: User) => {
          this.client = client;
          this.clientSubject.next(client);

          const plannerConfiguration = this.plannerConfig.getValue();
          plannerConfiguration.ingredientPreferences = client.ingredientPreferences;
          this.nextPlannerConfig(plannerConfiguration);
          this.mergeNutrientLimits(client.nutrientLimits);
        }),
        switchMap((client) =>
          combineLatest(of(client), this.lprxApi.mealPlans.get(client.mealPlanId))
        ),
        switchMap(([client, mealPlan]) => {
          const joins: Array<Observable<WeeklyPlan>> = [];

          const weekNumber = mealPlan.nonCalendar ? nonCalendarStartWeek : getCurrentWeekNumber();

          const weeksToLoad = [weekNumber];
          const additionalWeeksToLoad = min(mealPlan.repeatInterval - 1, 2);
          for (let i = 0; i < additionalWeeksToLoad; i++) {
            weeksToLoad.push(addWeek(weeksToLoad[i]));
          }

          for (const weekNumberToLoad of weeksToLoad) {
            joins.push(
              this.distributorService.clientPlanner.get(client.username, weekNumberToLoad)
            );
          }

          return forkJoin(joins);
        })
      )
      .subscribe((plans: WeeklyPlan[]) => {
        for (const wp of plans) {
          this.addWeeklyPlan(wp);
        }
      });

    this.subs.push(distServiceSub);
  }

  distLoadPreviousWeek(): Observable<WeeklyPlan[]> {
    const prevWeekNumber = subWeek(this.weeklyPlans[0].weekNumber);
    return this.distLoadWeek(prevWeekNumber);
  }

  distLoadAdditionalWeek(): Observable<WeeklyPlan[]> {
    const additionalWeekNumber = addWeek(this.weeklyPlans[this.weeklyPlans.length - 1].weekNumber);
    return this.distLoadWeek(additionalWeekNumber);
  }

  clientLoadAdditionalWeek() {
    const additionalWeekNumber = addWeek(this.weeklyPlans[this.weeklyPlans.length - 1].weekNumber);
    return from(this.lprxApi.weeklyPlans.get(additionalWeekNumber)).pipe(
      tap((wp) => {
        this.addWeeklyPlan(wp);
      }),
      map(() => this.weeklyPlans)
    );
  }

  private distLoadWeek(weekNumber: string): Observable<WeeklyPlan[]> {
    const weeklyPlanObservable$ = this.distLoadWeeklyPlan$(weekNumber);

    return weeklyPlanObservable$.pipe(
      tap(
        (weeklyPlan: WeeklyPlan) => {
          this.addWeeklyPlan(weeklyPlan);
        },
        (e) => alert(e.message)
      ),
      map(() => this.weeklyPlans)
    );
  }

  private distLoadWeeklyPlan$(weekNumber: string) {
    return this.client
      ? this.distributorService.clientPlanner.get(this.client.username, weekNumber)
      : fromPromise(
          this.masterMealPlanService.getMasterWeeklyPlan(this.weeklyPlans[0].mealPlanId, weekNumber)
        );
  }

  /**
   * Sort the plans in order by week
   */
  private sortPlans() {
    this.weeklyPlans.sort((a, b) => {
      return a.weekNumber < b.weekNumber ? -1 : 1;
    });
  }

  /**
   *
   * @param weeklyPlan
   */
  addWeeklyPlan(weeklyPlan: WeeklyPlan) {
    updateMealNames(weeklyPlan);

    if (this.hasWeeklyPlan(weeklyPlan.weekNumber)) {
      for (const i in this.weeklyPlans) {
        if (this.weeklyPlans[i].weekNumber === weeklyPlan.weekNumber) {
          this.weeklyPlans[i] = weeklyPlan;
        }
      }
    } else {
      this.weeklyPlans.push(weeklyPlan);
    }

    const plannerConfig = this.plannerConfig.getValue();

    this.mergeNutrientLimits(weeklyPlan.mealPlan.nutrientLimits);

    const additionalNutrients = weeklyPlan.mealPlan.additionalNutrients;
    plannerConfig.additionalNutrients = additionalNutrients;
    additionalNutrients.forEach((n) => plannerConfig.nutrients.add(n));
    console.log(additionalNutrients);
    this.nextPlannerConfig(plannerConfig);

    this.sortPlans();
    this.broadcastWeeklyPlanUpdate();
  }

  setPlannerOpenState(isOpen: boolean) {
    this.plannerOpenState.next(isOpen);
  }

  getIsPlannerOpen(): Observable<boolean> {
    return this.plannerOpenState.asObservable();
  }

  openCard(card: Card, cardId: string) {
    this.openedCardId = cardId;
    this.recipeOpener.openCard(card);
  }

  cardClosed() {
    console.log('cardClosed()');
    console.log(this.openedCardId);
    this.openedCardId = null;
  }

  updateServings(servings) {
    if (this.openedCardId) {
      this.servingsChangedSubject.next(servings);
    }
  }

  getOpenedCardId() {
    return this.openedCardId;
  }

  weeklyPlanUpdated(weekNumber: string, dayName?: string, mealName?: string, card?: Card) {
    const event = { weekNumber, dayName, mealName, card };
    console.log('Weekly Plan Updated: ', event);
    this.plannerUpdatedSubject.next(event);

    this.saveWeeklyPlan(weekNumber);

    // // Listen for weekly plan updates that are triggered by various components
    // const weeklyPlanUpdatedSub = this.plannerService.plannerUpdated$.subscribe(
    //   weeklyPlanUpdated => {
    //     console.log('weeklyPlanUpdatedSub');
    //     return this.saveWeeklyPlan(this.getWeeklyPlan(weeklyPlanUpdated.weekNumber));
    //   }
    // );
  }

  /**
   *
   * @param {boolean} canEdit
   */
  setCanEdit(canEdit: boolean) {
    console.log('user can edit');
    this.canEditSubject.next(canEdit);
  }

  /**
   *
   * @returns {Observable<boolean>}
   */
  getCanEdit(): Observable<boolean> {
    return this.canEditSubject.asObservable();
  }

  getWeeklyPlans() {
    return this.weeklyPlansSubject.asObservable();
  }

  clear() {
    this.weeklyPlans = [];
    this.setWeeklyPlans([]);
  }

  setWeeklyPlans(weeklyPlans: WeeklyPlan[]) {
    console.log('Setting weeklyPlansSubject');
    if (!weeklyPlans) {
      return;
    }

    // todo: Traversing the weekly plans down to the recipes is a common pattern...DRY up the code!
    // eg: weeklyPlan getRecipes()
    for (const weeklyPlan of weeklyPlans) {
      for (const recipe of weeklyPlan.getRecipes()) {
        this.recipeCache.cache(recipe);
      }
    }

    this.broadcastWeeklyPlanUpdate();
  }

  public broadcastWeeklyPlanUpdate() {
    console.log('broadcastWeeklyPlanUpdate');
    this.weeklyPlansSubject.next(this.weeklyPlans);
  }

  /**
   * @param weekNumber
   * @param day
   * @param meal
   * @param recipeId
   */
  getCard(weekNumber: string, day: string, meal: string, recipeId: string) {
    for (const weeklyPlan of this.weeklyPlans) {
      if (weeklyPlan.weekNumber === weekNumber) {
        return weeklyPlan.getDay(day).getMeal(meal).getRecipeCard(recipeId);
      }
    }
  }

  getRecipe(recipeId: string) {
    return undefined;
  }

  /**
   * @param weekNumber
   */
  hasWeeklyPlan(weekNumber: string) {
    try {
      this.getWeeklyPlan(weekNumber);
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * @param weekNumber
   */
  getWeeklyPlan(weekNumber: string): WeeklyPlan {
    for (const weeklyPlan of this.weeklyPlans) {
      if (weeklyPlan.weekNumber === weekNumber) {
        return weeklyPlan;
      }
    }

    throw new Error(`WeeklyPlan(${weekNumber}) not loaded!`);
  }

  saveWeeklyPlan(weekNumber: string): Observable<WeeklyPlan> {
    const weeklyPlan = this.getWeeklyPlan(weekNumber);

    if (weeklyPlan.type === WeeklyPlanType.Master) {
      return fromPromise(this.lprxApi.masterPlans.save(weeklyPlan)).pipe(map(() => weeklyPlan));
    } else {
      return fromPromise(this.lprxApi.weeklyPlans.save(weeklyPlan)).pipe(
        switchMap(() => {
          console.log('Getting weekly plan');
          return this.lprxApi.weeklyPlans.get(weeklyPlan.weekNumber, this.client?.username);
        }),
        tap((wp) => {
          console.log('Got weekly plan');
          this.weeklyPlans.forEach((p) => {
            if (p.weekNumber === wp.weekNumber) {
              p = wp;
              this.setWeeklyPlans(this.weeklyPlans);
            }
          });
        })
      );
    }
  }

  clearInsertRecipe() {
    this.recipeToInsert.next(null);
  }

  openInsertRecipe(recipe: Recipe, stayOpen = false) {
    this.auth
      .getUser()
      .pipe(take(1))
      .subscribe((user) => {
        this.recipeToInsert.next({ recipe, stayOpen: user.isDistributor });
      });
  }

  insertRecipe(
    recipe: Recipe,
    userServings: number,
    weekNumber: string,
    dayName: string,
    mealName: string
  ) {
    this.getWeeklyPlan(weekNumber)
      .getDay(dayName)
      .getMeal(mealName)
      .addRecipe(recipe, userServings);
    return this.saveWeeklyPlan(weekNumber);
  }

  removeRecipe(recipeId: string, weekNumber: string, dayName: string, mealName: string) {
    this.getWeeklyPlan(weekNumber).getDay(dayName).getMeal(mealName).removeRecipe(recipeId);
    return this.saveWeeklyPlan(weekNumber);
  }

  moveRecipe(
    recipeId: string,
    fromWeekNumber: string,
    fromDayName: string,
    fromMealName: string,
    toWeekNumber: string,
    toDayName: string,
    toMealName: string
  ) {
    const recipeCard = this.getWeeklyPlan(fromWeekNumber)
      .getDay(fromDayName)
      .getMeal(fromMealName)
      .getRecipeCard(recipeId);
    this.getWeeklyPlan(fromWeekNumber)
      .getDay(fromDayName)
      .getMeal(fromMealName)
      .removeRecipe(recipeId);
    this.getWeeklyPlan(toWeekNumber).getDay(toDayName).getMeal(toMealName).addCard(recipeCard);

    if (fromWeekNumber !== toWeekNumber) {
      this.saveWeeklyPlan(fromWeekNumber);
    }

    return this.saveWeeklyPlan(toWeekNumber);
  }

  /**
   * @param recipeId
   * @param weekNumber
   * @param dayName
   * @param mealName
   */
  isRecipeOnPlanner(
    recipeId: string,
    weekNumber: string,
    dayName: string,
    mealName: string
  ): boolean {
    if (weekNumber) {
      return this.getWeeklyPlan(weekNumber).hasRecipe(recipeId, dayName, mealName);
    }

    for (const weeklyPlan of this.weeklyPlans) {
      if (weeklyPlan.hasRecipe(recipeId, dayName, mealName)) {
        return true;
      }
    }

    return false;
  }

  /**
   * @param mealPlanId
   * @param startingWeekNumber
   * @param additionalWeeksToLoad
   */
  adminLoadPanner(
    mealPlanId: string,
    startingWeekNumber = getCurrentWeekNumber(),
    additionalWeeksToLoad: number = 3
  ) {
    this.clear();

    const weeksToLoad = [startingWeekNumber]; // initial week
    for (let i = 0; i < additionalWeeksToLoad; i++) {
      weeksToLoad.push(addWeek(weeksToLoad[i]));
    }
    for (const weekNumber of weeksToLoad) {
      this.adminLoadWeek(mealPlanId, weekNumber).subscribe((wps) => wps);
    }
  }

  /**
   * @param mealPlanId
   * @param currentWeekNumber
   */
  private adminLoadWeek(mealPlanId: string, currentWeekNumber): Observable<WeeklyPlan[]> {
    const mealPlan$ = this.adminService.mealPlans.get(mealPlanId);

    const masterWeeklyPlanPromise = this.masterMealPlanService.getMasterWeeklyPlan(
      mealPlanId,
      currentWeekNumber
    );
    const masterWeeklyMealPlan$ = fromPromise(masterWeeklyPlanPromise);

    return forkJoin(mealPlan$, masterWeeklyMealPlan$).pipe(
      switchMap(([mealPlan, masterWeeklyMealPlan]) => {
        this.nextPlannerConfig(new PlannerConfiguration());
        this.mergeNutrientLimits(mealPlan.nutrientLimits);

        masterWeeklyMealPlan.mealPlan = mealPlan;
        masterWeeklyMealPlan = constructOrCreateWeeklyPlan(
          masterWeeklyMealPlan,
          currentWeekNumber,
          mealPlan
        );
        this.addWeeklyPlan(masterWeeklyMealPlan);
        return of(this.weeklyPlans);
      })
    );
  }

  adminLoadPreviousWeek() {
    const earliestWeek = this.weeklyPlans[0];
    const weekNumber = subWeek(earliestWeek.weekNumber);
    return this.adminLoadWeek(earliestWeek.mealPlan.id, weekNumber);
  }

  adminLoadAdditionalWeek() {
    const latestWeek = this.weeklyPlans[this.weeklyPlans.length - 1];
    const weekNumber = addWeek(latestWeek.weekNumber);
    return this.adminLoadWeek(latestWeek.mealPlan.id, weekNumber);
  }

  clientLoadPreviousWeek() {
    return this.auth.user$.pipe(
      switchMap((user: User) => {
        switch (user.userType) {
          case UserType.Admin:
            break;
          case UserType.Distributor:
            break;
          case UserType.Client:
            return this.clientMealPlanService.getWeeklyMealPlan(
              subWeek(this.weeklyPlans[0].weekNumber)
            );
        }
      }),
      tap((wp) => {
        this.addWeeklyPlan(wp);
      })
    );
  }

  async reset(weeklyPlan: WeeklyPlan) {
    if (weeklyPlan.type === WeeklyPlanType.Master) {
      await this.lprxApi.masterPlans.delete(weeklyPlan.mealPlanId, weeklyPlan.weekNumber);
      const wp = await this.lprxApi.masterPlans.get(weeklyPlan.mealPlanId, weeklyPlan.weekNumber);
      this.addWeeklyPlan(wp);
    } else {
      await this.lprxApi.weeklyPlans.delete(weeklyPlan.weekNumber, weeklyPlan.username);
      const wp = await this.lprxApi.weeklyPlans.get(weeklyPlan.weekNumber, weeklyPlan.username);
      this.addWeeklyPlan(wp);
    }
  }

  ngOnDestroy(): void {
    console.log('Destroying planner service');
    this.subs.forEach((s) => s.unsubscribe());
  }

  getMasterPlan(mealPlanId: string, weekNumber: string) {
    if (this.weeklyPlans.length) {
      for (const wp of this.weeklyPlans) {
        if (mealPlanId === wp.mealPlanId && weekNumber === wp.weekNumber) {
          return of(wp);
        }
      }
    }

    return fromPromise(this.lprxApi.masterPlans.get(mealPlanId, weekNumber));
  }

  setReadOnly(b: boolean) {
    this.plannerConfig.pipe(take(1)).subscribe({
      next: (config) => {
        config.canCopyRecipes = !b;
        config.canEditMeals = !b;
        config.canCanRecipes = !b;
        config.canPrint = !b;
        this.nextPlannerConfig(config);
      },
    });
  }

  // tslint:disable-next-line:member-ordering
  canPasteSubject = new BehaviorSubject(false);
  // tslint:disable-next-line:member-ordering
  copiedWeek: WeeklyPlan;

  copy(weeklyPlan: WeeklyPlan) {
    // localStorage.setItem(this.planNameKey, mealPlan.name);
    this.copiedWeek = classToClass(weeklyPlan);
    this.canPasteSubject.next(true);
  }

  paste(toWeekNumber: string): Observable<WeeklyPlan> | Observable<unknown> {
    const confirmed = confirm('You are about to overwrite this week.');
    if (!confirmed) {
      return of(null);
    } else {
      const t = classToClass(this.copiedWeek);

      return this.auth.getLoggedInUser().pipe(
        switchMap((user) => {
          if (
            user.userType === UserType.Admin ||
            (user.userType === UserType.Distributor && t.type === WeeklyPlanType.Master)
          ) {
            return from(this.lprxApi.masterPlans.copy(t.mealPlanId, t.weekNumber, toWeekNumber));
          } else {
            t.weekNumber = toWeekNumber;
            this.addWeeklyPlan(t);
            return this.saveWeeklyPlan(toWeekNumber);
          }
        }),
        tap(() => {
          this.canPasteSubject.next(false);
        })
      );
    }
  }

  clearCopyPaste() {
    this.copiedWeek = null;
    this.canPasteSubject.next(false);
  }

  private _skipToWeekUpdate(wp) {
    this.lprxApi.users.lastLoadedWeek(wp.weekNumber);
    this.weeklyPlans = [wp];
    this.broadcastWeeklyPlanUpdate();
  }

  clientSkipToWeek(weekNumber: string) {
    return this.clientMealPlanService
      .getWeeklyMealPlan(weekNumber)
      .subscribe((wp) => this._skipToWeekUpdate(wp));
  }

  distSkipToWeek(weekNumber: string) {
    return this.distLoadWeeklyPlan$(weekNumber).subscribe((wp) => this._skipToWeekUpdate(wp));
  }

  swapWithPreviousWeek(weeklyPlan: WeeklyPlan) {
    const previousWeekNumber = subWeek(weeklyPlan.weekNumber);
    this.swapWeeklyPlan(weeklyPlan, previousWeekNumber);
  }

  private swapWeeklyPlan(weeklyPlan: WeeklyPlan, toWeekNumber: string) {
    const swapWeek = this.getWeeklyPlan(toWeekNumber);

    const tempWeek = classToClass(weeklyPlan);

    for (const day of DaysOfTheWeek.getDaysLong(true)) {
      weeklyPlan[day] = swapWeek.getDay(day);
    }

    for (const day of DaysOfTheWeek.getDaysLong(true)) {
      swapWeek[day] = tempWeek.getDay(day);
    }
  }

  swapWithNextWeek(weeklyPlan: WeeklyPlan) {
    const nextWeekNumber = addWeek(weeklyPlan.weekNumber);
    this.swapWeeklyPlan(weeklyPlan, nextWeekNumber);
  }

  /**
   * Saves the grocery list for the given date range
   *
   * @param {NgbDateStruct} minDate
   * @param {NgbDateStruct} maxDate
   * @param {{[p: string]: GroceryListItem[]}} groupedItems
   * @returns {Promise<GroceryListDto>}
   */
  saveGroceryList(
    minDate: NgbDateStruct,
    maxDate: NgbDateStruct,
    groupedItems: {
      [p: string]: GroceryListItem[];
    }
  ): Promise<GroceryListDto> {
    const key = groceryListKeyFromDates(minDate, maxDate);

    const payload: GroceryListDto = { key, groupedItems };
    return this.lprxApi.post<GroceryListDto>(`v3/grocery-lists`, payload);
  }

  getGroceryLists(): Promise<GroceryLists> {
    return this.lprxApi.get<GroceryLists>(`v3/grocery-lists`).then(this.sortGroceryLists());
  }

  deleteGroceryList(key: string): Promise<GroceryLists> {
    return this.lprxApi
      .delete<GroceryLists>(`v3/grocery-lists/${key}`)
      .then(this.sortGroceryLists());
  }

  private sortGroceryLists() {
    return (groceryLists) => {
      // sort groceryLists by key
      groceryLists.sort((a, b) => {
        return a.key > b.key ? 1 : -1;
      });
      return groceryLists;
    };
  }

  /**
   * Get a grocery list by key
   *
   * @param {string} key
   * @returns {Promise<GroceryListDto>}
   */
  getGroceryList(key: string): Promise<GroceryListDto> {
    return this.lprxApi.get<GroceryListDto>(`v3/grocery-lists/${key}`);
  }

  loadWeeklyPlan(weekNumber: any) {
    return this.auth.getLoggedInUser().pipe(
      take(1),
      switchMap((user) => {
        let p: Promise<WeeklyPlan>;
        switch (user.userType) {
          case UserType.Client:
            p = this.lprxApi.weeklyPlans.get(weekNumber);
            break;
          case UserType.Distributor:
            p = this.distLoadWeeklyPlan$(weekNumber).toPromise();
            break;
          case UserType.Admin:
            p = this.masterMealPlanService.getMasterWeeklyPlan(
              this.weeklyPlans[0].mealPlanId,
              weekNumber
            );
            break;
        }

        return from(p.then((wp) => this.addWeeklyPlan(wp)));
      })
    );
  }

  /**
   *
   * @param {string} weekNumber
   * @param {string} dayName  -- comes in as abbreviation Mon , Tues, etc.
   */
  nextDays(weekNumber: string, dayName: string): NextDays {
    dayName = DaysOfTheWeek.getLong(dayName);

    // [monday, tuesday, etc...]
    let daysOfWeek = DaysOfTheWeek.getDaysLong(true);

    let possibleDays: NextDays = daysOfWeek.map((d) => ({
      weekNumber: weekNumber,
      dayName: d,
    }));

    const indexOfDay = daysOfWeek.findIndex((d) => d.match(dayName));

    // remove days before weekNumber and dayName, including weekNumber and dayName
    possibleDays.splice(0, indexOfDay + 1);

    const followingWeekNumber = addWeek(weekNumber);
    // if the followingWeek loaded?
    if (this.weeklyPlans.find((w) => w.weekNumber == followingWeekNumber)) {
      possibleDays.push(
        ...daysOfWeek.map((d) => ({
          weekNumber: followingWeekNumber,
          dayName: d,
        }))
      );
    }

    // only keep the first 7 days of possibleDays
    possibleDays = possibleDays.slice(0, 7);

    console.log(possibleDays);

    return possibleDays;
  }
}
