import Swal, { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';

export function swalOptions(opts: SweetAlertOptions) {
  return {
    showCancelButton: true,
    confirmButtonColor: '#84a03c',
    ...opts
  };
}

export function alertModal(options: SweetAlertOptions): Promise<SweetAlertResult> {
  return Swal.fire(swalOptions(options));
}
