/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/weeky-meal-plan/PlannerType.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum PlannerType {
  Static = 's',
  Custom = 'c'
}

export const PlannerTypes: Set<PlannerType> = new Set([PlannerType.Custom, PlannerType.Static]);

export function isValidPlannerType(type: PlannerType) {
  return PlannerTypes.has(type);
}

export function plannerSku(type: PlannerType): string {
  switch (type) {
    case PlannerType.Static:
      return 'bundled_static_plan';
    case PlannerType.Custom:
      return 'bundle_custom_plan';
  }

  throw new Error(`Unknown planner type '${type}'`);
}
