<app-loading *ngIf="loading"></app-loading>

<div *ngIf="!loading" [class.blur]="showSignUp">
  <div class="hero-wrapper mb-5">
    <div class="container">
      <div class="hero-content">
        <h1 class="display-4">{{ mealPlan.name }} Plan</h1>

        <h4>Weekly meal plans created by</h4>

        <ng-container *ngIf="mealPlan.cobrand">
          <div class="row align-items-center">
            <div class="col-md col-sm-12 text-center text-md-right">
              <img
                src="{{ logoUrl }}"
                style="max-width: 250px; max-height: 100px"
                alt="{{ distributor.business }}"
              />
            </div>
            <div class="col-md-1 col-sm-12 text-center" style="font-size: 50px; color: #aaa">+</div>
            <div class="col-md col-sm-12 text-center text-md-left">
              <img src="{{ cobrandLogoUrl }}" style="max-width: 250px; max-height: 100px" />
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!mealPlan.cobrand">
          <img src="{{ logoUrl }}" style="max-width: 300px" alt="{{ distributor.business }}" />
        </ng-container>

        <br />
        <br />

        <ng-container *ngTemplateOutlet="signUpButton"></ng-container>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row py-md-5">
      <div class="col-md-8 order-md-last">
        <div class="description-wrapper">
          <div class="description" [innerHtml]="mealPlanDescription"></div>
          <p>
            {{ distributor.firstName }} {{ distributor.lastName
            }}<span *ngIf="distributor.credentials != ''"
              >, <em>{{ distributor.credentials }}</em></span
            >
            <br />{{ distributor.business }}
          </p>
        </div>
      </div>
      <div class="col-md-4 text-center order-md-first mb-xs-5">
        <img src="{{ photoUrl }}" class="headshot" /><br />
        {{ distributor.firstName }}
        {{ distributor.lastName
        }}<span *ngIf="distributor.credentials != ''"
          >, <em>{{ distributor.credentials }}</em></span
        >
      </div>
    </div>
  </div>

  <section class="bg-light py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-md-6 text-center px-5">
          <img src="/assets/img/icon/healthy-diet.svg" height="100px" width="100px" alt="" />
          <br />
          <strong>Make it healthy for everyone</strong>
          <p>Delicious foods customized to fit any lifestyle</p>
        </div>
        <div class="col-md-6 text-center px-5">
          <img
            src="/assets/img/icon/cutting-board.svg"
            height="100px"
            width="100px"
            style="transform: rotate(41.75deg)"
            alt=""
          />
          <br />
          <strong>Know your way in the kitchen</strong>
          <p>
            From breakfast to dinner and everything in between, create and share meals you love.
          </p>
        </div>
      </div>

      <div class="row py-5">
        <div class="col-md-6 text-center px-5">
          <img src="/assets/img/icon/meal-plan.svg" height="100px" width="100px" alt="" />
          <br />
          <strong>Don't sweat the details</strong>
          <p>Save time and money by having a plan and knowing exactly what you need.</p>
        </div>
        <div class="col-md-6 text-center px-5">
          <img src="/assets/img/icon/nutritionist.svg" height="100px" width="100px" alt="" />
          <br />
          <strong>We'll help you every step of the way</strong>
          <p>
            Our community of credentialed health professionals will guide you to success in the
            kitchen
          </p>
        </div>
      </div>
    </div>
  </section>

  <!--    <div class="py-5 text-center">-->
  <!--        <img src="/assets/img/powered-by-lprx.png" alt="Powered By Living Plate Rx">-->
  <!--    </div>-->

  <div class="container pb-5">
    <!--        <div class="row py-5">-->
    <!--            <div class="col-12 text-center">-->
    <!--                <h4>Healthy Recipes Developed by Registered Dietitians Nutritionists</h4>-->
    <!--                <h5>-->
    <!--                    Every recipe suggestion is thoroughly tested to ensure it's delicious, easy to make,-->
    <!--                    and helps you meet your health goals.-->
    <!--                </h5>-->
    <!--            </div>-->
    <!--        </div>-->

    <!--        <ng-container *ngIf="mealPlan.showRecipes !== false">-->
    <!--            <div class="row py-5 recipes">-->
    <!--                <div class="col-md-3 text-center">-->
    <!--                    <img src="/assets/img/distributor-landing-page/recipe-buckwheat.jpg">-->
    <!--                    <br>Buckwheat Coco-Chip Overnight Oats<br><br>-->
    <!--                </div>-->
    <!--                <div class="col-md-3 text-center">-->
    <!--                    <img src="/assets/img/distributor-landing-page/recipe-chicken.jpg">-->
    <!--                    <br>Quick Game Day Chili<br><br>-->
    <!--                </div>-->
    <!--                <div class="col-md-3 text-center">-->
    <!--                    <img src="/assets/img/distributor-landing-page/recipe-kale.jpg">-->
    <!--                    <br>Kale and Squash Salad with Turmeric Dressing<br><br>-->
    <!--                </div>-->
    <!--                <div class="col-md-3 text-center">-->
    <!--                    <img src="/assets/img/distributor-landing-page/recipe-sheetpan.jpg">-->
    <!--                    <br>Flexible Sheet Pan Dinner<br><br>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </ng-container>-->

    <div class="row py-5">
      <div class="col-12 text-center">
        <ng-container *ngTemplateOutlet="signUpButton"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #signUpButton>
  <ng-container [ngSwitch]="buttonType">
    <ng-container *ngSwitchCase="'default'" [ngTemplateOutlet]="defaultButton"></ng-container>
    <ng-container *ngSwitchCase="'promo'" [ngTemplateOutlet]="promoButton"></ng-container>
    <ng-container
      *ngSwitchCase="'requestAccess'"
      [ngTemplateOutlet]="requestAccessButton"
    ></ng-container>
    <ng-container *ngSwitchCase="'subscribe'" [ngTemplateOutlet]="subscribeButton"></ng-container>
    <ng-container *ngSwitchCase="'error'" [ngTemplateOutlet]="errorAlert"></ng-container>
  </ng-container>
</ng-template>

<ng-template #defaultButton>
  <button (click)="signUp()" appButton>
    &nbsp;&nbsp;&nbsp;{{ mealPlan.signUpButtonLabel }}&nbsp;&nbsp;&nbsp;
  </button>
  <br />
  <small>
    <ng-container *ngIf="distConfig.enableFreeTrial">
      No Credit Card Required to Start
      <br />
    </ng-container>

    <ng-container *ngIf="distConfig.enableSubscriptions">
      Subscriptions starting at
      {{ lowestPrice.price | currency: lowestPrice.currency.toUpperCase() : 'symbol' }}/month
    </ng-container>
  </small>
</ng-template>

<ng-template #promoButton>
  <button (click)="signUp()" appButton>
    &nbsp;&nbsp;&nbsp;Sign Up For Your Account&nbsp;&nbsp;&nbsp;
  </button>
</ng-template>

<ng-template #subscribeButton>
  <a
    [routerLink]="'/' + distributor.vanityId + '/' + mealPlan.vanityUrl + '/subscribe'"
    appButton
    fullWidth
  >
    &nbsp;&nbsp;&nbsp;Subscribe&nbsp;&nbsp;&nbsp;
  </a>
</ng-template>

<ng-template #requestAccessButton>
  <h4 class="text-center">Please contact {{ distributor.business }} for access.</h4>
</ng-template>

<ng-template #errorAlert>
  <div class="alert alert-danger">
    <strong>Oops. Something went wrong.</strong><br />{{ error }}
  </div>
</ng-template>
