import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ClientCoupon, ClientCouponType } from '../../lprx-shared-lib/client-coupon';
import { promoCodes } from '../../lprx-shared-lib/client-promo-codes';
import { ClientSignUpDto } from '../../lprx-shared-lib/dto/client-sign-up-dto';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { DistributorPlanConfig } from '../../lprx-shared-lib/entities/distributor-config';
import { Plans } from '../../lprx-shared-lib/entities/plans';
import { Pricing } from '../../lprx-shared-lib/entities/pricing';
import { PlannerType } from '../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { PasswordPasser } from '../../lprx-shared-lib/password-passer';
import { IDEMPOTENCY_KEY_PREFIX } from '../../lprx-shared-lib/utils/constants';
import { createId } from '../../lprx-shared-lib/utils/id-generator';
import { AuthService } from '../auth.service';
import { CreditCardInputComponent } from '../credit-card-input/credit-card-input.component';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { PricingWithCurrencyPipe } from '../pipe/pricing-with-currency.pipe';
import { HeaderService } from '../service/header.service';
import { errorMessage } from '../utilities/alert-http-error';
import { loadStripe, StripeElements, StripePaymentElement } from '@stripe/stripe-js';
import * as stripe from '@stripe/stripe-js';
import { from } from 'rxjs/observable/from';
import { environment } from '../../environments/environment';
import { StripeElementsUpdateOptions } from '@stripe/stripe-js/types/stripe-js/elements-group';
import { ClientSignUpResponse } from '../../lprx-shared-lib/dto/client-sign-up-response';

export class UserDetails {
  // username: string;
  firstName: string;
  lastName: string;
  email: string;
  phone_number: string;
  password: string;
}

const defaultPromoCodeMessage = "Congrats! You're getting 30 days free.";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [PricingWithCurrencyPipe],
})
export class SignupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() distributor: Distributor;
  @Input() distributorConfig: DistributorPlanConfig;
  @Input() plans: Plans;

  @Input() mealPlan: MealPlan;
  @Input() mealPlanId: string;
  @Output() closeSignUp = new EventEmitter<boolean>();

  @ViewChild('ccForm') creditCardInputComponent: CreditCardInputComponent;

  private stripeElements: StripeElements;
  paymentElement!: StripePaymentElement;

  stripe: stripe.Stripe;

  @ViewChild('paymentElement')
  stripeElement: ElementRef<HTMLElement>;

  @ViewChild('paymentForm')
  paymentFormElementRef: ElementRef<HTMLDivElement>;

  error: any;

  public userDetails: UserDetails;
  isPasswordValid: boolean;
  isPasswordLongEnough: boolean;
  doesPasswordMatch: boolean;
  doesPasswordHaveNumber: boolean;
  doesPasswordHaveUppercase: boolean;
  doesPasswordHaveLowercase: boolean;
  passwordConfirm: string;
  isProcessing = false;
  termsAgreement = false;
  showPromoCodeInput: boolean = false;
  promoCode: string;
  showPromoCodeApplied: boolean = false;
  promoCodeMessage: string = defaultPromoCodeMessage;
  private subs: Subscription[] = [];
  promoCodeError: string;

  heading: string = '';
  subheading: string = '';
  hasFreeTrial: boolean = true;
  selectedPricing: Pricing;
  plannerType: PlannerType = PlannerType.Custom;
  PlannerType = PlannerType;
  errorMessage: string;
  signUpCost: string = '';
  showMeTheMoney: boolean;
  coupon?: ClientCoupon;
  netPriceHasValue: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private auth: AuthService,
    private header: HeaderService,
    private route: ActivatedRoute,
    private router: Router,
    private pricingWithCurrencyPipe: PricingWithCurrencyPipe
  ) {
    this.userDetails = new UserDetails();
  }

  ngAfterViewInit(): void {
    this.loadStripe().subscribe((stripe) => {
      this.stripe = stripe;

      this.stripeElements = this.stripe.elements({
        mode: 'subscription',
        amount: 9900,
        locale: 'en',
        currency: 'usd',
        appearance: {
          theme: 'flat',
        },
      });

      this.paymentElement = this.stripeElements.create('payment');
      console.log(this.stripeElement);
      this.paymentElement.mount(this.stripeElement.nativeElement);

      this.init();
    });
  }

  private loadStripe() {
    return from(
      loadStripe(environment.stripe_public_key, {
        stripeAccount: this.distributorConfig.stripeAccountId,
      })
    );
  }

  ngOnInit() {}

  private init() {
    const queryParamsSub = this.route.firstChild.queryParams.subscribe((params) => {
      if (params['promo']) {
        this.promoCode = params['promo'];
        this.showPromoCodeInput = true;
        // this.checkPromoCode();
      }
      console.log(params);

      this.subheading = `Sign up below to the ${this.mealPlan.name} meal plan from ${this.distributor.business}.`;

      if (this.mealPlan.signUpSubheader) {
        this.subheading = this.mealPlan.signUpSubheader;
      }

      this.hasFreeTrial = this.distributorConfig.enableFreeTrial;

      this.showMeTheMoney = !this.hasFreeTrial;

      // if (!this.distributorConfig.enableFreeTrial) {
      if (this.plans.static) {
        this.selectedPricing = this.plans.static.monthly;
      }

      if (this.plans.custom) {
        this.selectedPricing = this.plans.custom.monthly;
      }
      this.updateStripePaymentElement();

      this.setSignUpCost();
      // }

      if (!this.distributorConfig.enableFreeTrial) {
        this.heading = 'Subscribe';
      } else if (this.showPromoCodeApplied) {
        this.heading = 'Sign Up';
      } else {
        this.heading = this.mealPlan.signUpHeader;
      }
    });

    this.subs.push(queryParamsSub);
  }

  private updateStripePaymentElement() {
    let updateAmount: StripeElementsUpdateOptions = {
      amount: this.selectedPricing.price * 100,
      currency: this.selectedPricing.currency,
    };
    this.stripeElements.update(updateAmount);
    console.log(updateAmount);
  }

  async signUp() {
    if (this.isProcessing) {
      return;
    }

    this.errorMessage = null;

    if (!this.checkPromoCode()) {
      return;
    }

    const details = this.userDetails;
    this.isProcessing = true;

    details.email = details.email.toLowerCase().trim();

    const { error: submitError } = await this.stripeElements.submit();

    try {
      const signUpDetails: ClientSignUpDto = {
        idempotencyKey: createId(IDEMPOTENCY_KEY_PREFIX),
        firstName: details.firstName,
        lastName: details.lastName,
        email: details.email,
        password: PasswordPasser.encrypt(details.password),
        distributorId: this.distributor.id,
        mealPlanId: this.mealPlan.id,
        promoCode: this.promoCode,
        plannerType: this.plannerType,
      };

      if (this.selectedPricing) {
        signUpDetails.pricingId = this.selectedPricing.priceId;
      }

      // if (this.showMeTheMoney) {
      //   signUpDetails.paymentMethodId = await this.creditCardInputComponent
      //     .getPaymentSource()
      //     .toPromise();
      // }

      const res: ClientSignUpResponse = await this.auth.clientSignUp(signUpDetails).toPromise();

      const { type, clientSecret } = res;

      let noClientSecretPresent = !clientSecret || clientSecret === '';

      // if no client secret is present and we have a magic link, redirect to the magic link
      // this occurs when there is no payment to be made at the time of sign up
      if (noClientSecretPresent && res.magicLink) {
        console.log(res);
        window.location.href = res.magicLink;
      } else {
        const confirmIntent =
          type === 'setup' ? this.stripe.confirmSetup : this.stripe.confirmPayment;

        // Confirm the Intent using the details collected by the Payment Element
        const response = await confirmIntent({
          elements: this.stripeElements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: window.location.href,
          },
        });

        if (
          response['paymentIntent']?.status === 'succeeded' ||
          response['setupIntent']?.status === 'succeeded'
        ) {
          console.log(res);

          window.location.href = res.magicLink;

          // magic link
        } else {
          console.log(response);
        }

        if (response.error) {
          // This point is only reached if there's an immediate error when confirming the Intent.
          // Show the error to your customer (for example, "payment details incomplete").
          this.error(submitError.message);
        } else {
          // Your customer is redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer is redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      }

      this.isProcessing = false;
      this.error = null;

      // if (res.magicLink) {
      //   window.location.href = res.magicLink;
      //   // await this.router.navigateByUrl('/planner?show_upgrade=1');
      // } else {
      // }
    } catch (e) {
      this.errorMessage = errorMessage(e);
    }
    this.isProcessing = false;
  }

  closeForm() {
    this.activeModal.dismiss();
  }

  validatePassword() {
    // todo: this is duplicated in many places....in fact, create a component that can be reused for creating a password

    const p = this.userDetails.password;
    this.isPasswordLongEnough = p.length >= 6;
    this.doesPasswordHaveLowercase = p.match(/[a-z]/) !== null;
    this.doesPasswordHaveUppercase = p.match(/[A-Z]/) !== null;
    this.doesPasswordHaveNumber = p.match(/[0-9]/) !== null;
    this.doesPasswordMatch = this.userDetails.password === this.passwordConfirm;

    this.isPasswordValid =
      this.isPasswordLongEnough &&
      this.doesPasswordHaveUppercase &&
      this.doesPasswordHaveLowercase &&
      this.doesPasswordHaveNumber &&
      this.doesPasswordMatch;
    console.log(this.isPasswordValid);
  }

  checkPromoCode() {
    this.coupon = undefined;
    this.promoCodeError = null;
    this.showPromoCodeApplied = false;
    this.showMeTheMoney = !this.hasFreeTrial;

    if (!this.promoCode || this.promoCode === '') {
      return true;
    }

    // /*
    // Promocodes aren't for subscriptions, at least not yet.
    //  */
    // if (!this.distributorConfig.enableFreeTrial) {
    //   this.promoCodeError = 'Sorry, that is not a valid promo code.';
    //   return false;
    // }

    this.promoCode = this.promoCode.toLowerCase();

    for (const coupon of this.distributorConfig.coupons) {
      if (coupon.code.toLowerCase() === this.promoCode && !coupon.isDeactivated) {
        if (!coupon.plannerTypes.includes(this.plannerType)) {
          this.promoCodeError = 'Promo code not valid for the selected planner type.';
          return false;
        }

        const isValidForSelectedPlan =
          coupon.forMealPlans.includes('all') || coupon.forMealPlans.includes(this.mealPlan.id);

        if (!isValidForSelectedPlan) {
          this.promoCodeError = 'Promo code not valid for this meal plan.';
          return false;
        }

        this.coupon = coupon;

        if (coupon.type === ClientCouponType.ExtendedTrial) {
          this.promoCodeMessage = `Congrats! You're getting ${coupon.amount} days free.`;
          this.showPromoCodeApplied = true;
          this.showMeTheMoney = false;
          this.hasFreeTrial = true;
          this.setNetPrice();
          return true;
        }

        if (coupon.type === ClientCouponType.AmountOff) {
          this.promoCodeMessage = `Congrats! You're getting ${this.pricingWithCurrencyPipe.transform(
            coupon.amount,
            this.distributorConfig.pricing.currency
          )} off per month.`;
          this.showPromoCodeApplied = true;
          this.setNetPrice();
          this.showMeTheMoney = this.netPriceHasValue;
          return true;
        }

        if (coupon.type === ClientCouponType.PercentOff) {
          this.promoCodeMessage = `Congrats! You're getting ${coupon.amount}% off per month.`;
          this.showPromoCodeApplied = true;
          this.setNetPrice();
          this.showMeTheMoney = this.netPriceHasValue;
          return true;
        }
      }
    }

    /**
     * Yes, hard coding this for now due to time constraints.
     * A more robust coupon system to be built in the future.
     */

    if (
      this.promoCode === 'gatheredtable' ||
      this.promoCode === 'irms30' ||
      this.promoCode === 'lp30' ||
      this.promoCode === 'gift30'
    ) {
      this.promoCodeMessage =
        this.promoCode === 'gatheredtable'
          ? "Congrats! You're getting 12 free months."
          : defaultPromoCodeMessage;

      this.showPromoCodeApplied = true;
      return true;
    }

    if (this.promoCode === 'gift7') {
      this.promoCodeMessage = "Congrats! You're getting 7 days free.";
      this.showPromoCodeApplied = true;
      return true;
    }

    if (this.promoCode === 'gift14') {
      this.promoCodeMessage = "Congrats! You're getting 14 days free.";
      this.showPromoCodeApplied = true;
      return true;
    }

    if (promoCodes[this.promoCode]) {
      const promoCodeMealPlans = promoCodes[this.promoCode].mealPlans;
      const hasMealPlans =
        promoCodeMealPlans && Array.isArray(promoCodeMealPlans) && promoCodeMealPlans.length;
      if (hasMealPlans) {
        if (promoCodeMealPlans.includes(this.mealPlan.id)) {
          this.promoCodeMessage = `Congrats! You're getting ${
            promoCodes[this.promoCode].trialDays
          } days free.`;
          this.showPromoCodeApplied = true;
          return true;
        }
      } else {
        const days = promoCodes[this.promoCode].trialDays;
        this.promoCodeMessage = `Congrats! You're getting ${days} days free.`;
        this.showPromoCodeApplied = true;
        return true;
      }
    }

    this.promoCodeError = 'Sorry, that is not a valid promo code.';
    return false;
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  selectStaticPlan() {
    this.selectedPricing = this.plans.static.monthly;
    this.plannerType = PlannerType.Static;
    this.setSignUpCost();
  }

  selectCustomPlan() {
    this.selectedPricing = this.plans.custom.monthly;
    this.plannerType = PlannerType.Custom;
    this.setSignUpCost();
  }

  private setSignUpCost() {
    this.updateStripePaymentElement();

    this.checkPromoCode();
    if (!this.hasFreeTrial && this.selectedPricing) {
      const netPrice = this.setNetPrice();

      if (netPrice) {
        this.signUpCost = netPrice + ' per month';
        this.showMeTheMoney = true;
      } else {
        this.showMeTheMoney = false;
      }
    }
  }

  private setNetPrice() {
    const netPrice = this.netPrice(this.selectedPricing.price, this.plannerType);

    this.netPriceHasValue = !!netPrice;
    return netPrice;
  }

  netPrice(price: number, plannerType: PlannerType): string | null {
    let netPrice = price;

    if (this.coupon) {
      if (this.coupon.plannerTypes.includes(plannerType)) {
        netPrice =
          this.coupon.type === ClientCouponType.PercentOff
            ? price * (1 - this.coupon.amount / 100)
            : price - this.coupon.amount;
      }
    }

    if (netPrice === 0) {
      return null;
    }

    if (netPrice === price) {
      return this.pricingWithCurrencyPipe.transform(
        netPrice,
        this.distributorConfig.pricing.currency
      );
    }

    return (
      `<span>$${price}</span> ` +
      this.pricingWithCurrencyPipe.transform(netPrice, this.distributorConfig.pricing.currency)
    );
  }
}
