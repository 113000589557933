import { Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Subscription } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { Contributor } from '../../../lprx-shared-lib/entities/contributor';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { PlannerType } from '../../../lprx-shared-lib/entities/weeky-meal-plan/PlannerType';
import { AuthService } from '../../auth.service';
import { Card } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';
import { PrinterService } from '../../service/printer.service';
import { RecipeService } from '../../service/recipe.service';
import { parseCardId } from '../../utilities/card-data-extractor';
import { PlannerService } from '../planner.service';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { CopyToLeftoversDialogComponent } from './copy-to-leftovers/copy-to-leftovers-dialog.component';
import { CopyToLeftoversData } from './copy-to-leftovers/copy-to-leftovers-data';
import { NextDays } from '../next-days';
import { CdnService } from '../../service/cdn.service';

@Component({
  selector: 'app-planner-recipe',
  templateUrl: './planner-recipe.component.html',
  styleUrls: ['./planner-recipe.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlannerRecipeComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;
  contributor: Contributor;
  @Input() servings: number;
  @Input() isLeftOver = false;
  @Input() fromRecipeCard = false;

  private subs: Subscription[] = [];
  user: User;
  canEdit: boolean = false;
  isCopying = false;
  canCopy: boolean = true;
  card: Card;
  canPrint: boolean = true;
  tick: number = Math.random();
  isFavorite = false;
  enableRecipeInsert: boolean = false;
  logo: SafeResourceUrl;
  canCopyToLeftOvers: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private auth: AuthService,
    private plannerService: PlannerService,
    private printer: PrinterService,
    private router: Router,
    private recipesService: RecipeService,
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private cdn: CdnService
  ) {}

  ngOnInit(): void {
    this.canCopyToLeftOvers = !!this.plannerService.getOpenedCardId();

    this.subs.push(
      this.plannerOpenStateSub(),
      this.plannerConfigSub(),
      this.userSub(),
      this.favoriteRecipesSub()
    );

    this.plannerService.recipeToInsert$.pipe(take(1)).subscribe((details) => {
      const url = new URL(window.location.href);
      this.enableRecipeInsert =
        !!url.pathname.match(/recipes$/) && !details && !url.pathname.match(/distributor\/recipes/);
    });

    let brandLogo = this.document.body.querySelector('a img.brand-image');
    let src = brandLogo?.getAttribute('src');
    if (brandLogo && src) {
      this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    } else {
      if (this.auth._getUser().userType === UserType.Admin) {
        this.logo = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/img/lprx-logo.png');
      } else {
        // this.logo = this.sanitizer.bypassSecurityTrustResourceUrl('/assets/img/lprx-logo.png');
        this.auth.getDistributor$().subscribe({
          next: (d) => {
            console.log(d);
            this.logo = this.sanitizer.bypassSecurityTrustResourceUrl(this.cdn.getUrl(d.logo));
          },
        });
      }
    }
  }

  private userSub() {
    return this.auth.user$.subscribe((user: User) => {
      this.user = user;

      // can edit is user id distributor and same distributor as recipe
      this.canEdit =
        (user.userType === UserType.Distributor && user.distributorId === this.recipe.ownedBy) ||
        (user.userType === UserType.Client && user.username === this.recipe.ownedBy);

      this.canCopy =
        this.canCopy !== false &&
        (user.userType === UserType.Distributor ||
          (user.userType === UserType.Client && user.plannerType === PlannerType.Custom));
    });
  }

  private plannerConfigSub() {
    return this.plannerService.plannerConfig$.subscribe((config) => {
      this.tick = Math.random();
      this.canCopy = config.canCopyRecipes;
      this.canEdit = config.canEditMeals;
      this.canPrint = config.canPrint;
    });
  }

  private plannerOpenStateSub() {
    /**
     * If the planner is not open close the active modal.
     */
    return this.plannerService.isPlannerOpen$.subscribe((isPlannerOpen) => {
      if (!isPlannerOpen) {
        this.activeModal.close();
      }
    });
  }

  private favoriteRecipesSub() {
    return this.recipesService.favoriteRecipes().subscribe((favoriteRecipes) => {
      this.isFavorite = favoriteRecipes.is(this.recipe.id);
    });
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  printRecipe() {
    this.printer.printRecipe(this.recipe);
    this.router.navigateByUrl('/print/recipe/' + this.recipe.id);
  }

  updateServings(servings: any) {
    this.servings = servings;
  }

  goEdit() {
    this.router
      .navigateByUrl('/recipe/edit/' + this.recipe.id + '?return_to=')
      .then(() => this.activeModal.close());
  }

  print() {
    // set body tag as print recipe
    this.document.body.classList.add('print-recipe');
    setTimeout(() => {
      window.addEventListener('afterprint', (event: Event) => {
        this.document.body.classList.remove('print-recipe');
      });
      window.print();
    }, 250);
  }

  /**
   *
   */
  copyAndEdit() {
    if (this.isCopying) {
      return;
    }

    this.isCopying = true;
    const copySub = this.recipesService
      .copy(this.recipe.id)
      .pipe(
        switchMap((recipe) => {
          // if this from an open card then
          const cardId = this.plannerService.getOpenedCardId();
          if (cardId) {
            const { weekNumber, dayName, mealId, recipeId } = parseCardId(cardId);
            return this.replaceRecipe(weekNumber, dayName, mealId, recipeId, recipe);
            // return this.plannerService.saveWeeklyPlan(weekNumber).switchMap(() => of(recipe));
          } else {
            return of(recipe);
          }
        })
      )
      .subscribe(
        (recipe) => {
          this.isCopying = false;
          // Do not need this.isCopying = false as we are navigating away from this component
          this.router.navigateByUrl('/recipe/edit/' + recipe.id).then(() => {
            this.activeModal.close();
          });
        },
        (err) => {
          this.isCopying = false;
          alert('Sorry! There was an issue copying the recipe. ' + err.error.message);
        }
      );
    this.subs.push(copySub);
  }

  /**
   * Replaces the recipe on the planner and makes sure it saved.
   *
   * @todo Should be in planner service
   *
   * @param weekNumber
   * @param dayName
   * @param mealId
   * @param recipeId
   * @param recipe
   */
  private replaceRecipe(
    weekNumber: string,
    dayName: string,
    mealId: string,
    recipeId: string,
    recipe: Recipe
  ) {
    return this.plannerService.removeRecipe(recipeId, weekNumber, dayName, mealId).pipe(
      tap(() =>
        this.plannerService
          .getWeeklyPlan(weekNumber)
          .getDay(dayName)
          .getMeal(mealId)
          .addRecipe(recipe)
      ),
      switchMap(() =>
        this.plannerService.saveWeeklyPlan(weekNumber).pipe(switchMap(() => of(recipe)))
      )
    );
  }

  addToPlanner() {
    console.log(this.recipe);
    this.activeModal.dismiss('Opening Insert to Recipe');
    this.plannerService.openInsertRecipe(this.recipe);
  }

  copyToLeftOvers() {
    const cardId = this.plannerService.getOpenedCardId();
    if (cardId) {
      const { weekNumber, dayName, mealId, recipeId } = parseCardId(cardId);
      // calc the next 7 days from weekNumber and dayName
      const nextDays: NextDays = this.plannerService.nextDays(weekNumber, dayName);

      this.activeModal.close();

      this.dialog.open<CopyToLeftoversDialogComponent, CopyToLeftoversData>(
        CopyToLeftoversDialogComponent,
        {
          data: {
            recipe: this.recipe,
            mealId: mealId,
            nextDays: nextDays,
            plannerService: this.plannerService,
          },
        }
      );
    }
  }

  canLeftover() {
    return !!this.plannerService.getOpenedCardId();
  }
}
