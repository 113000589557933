import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { calcDate } from '../../../lprx-shared-lib/calc-date';
import { LayoutService } from '../../layout/layout.service';
import { Brand } from '../../model/entities/brand';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { daysOfTheWeek } from '../../utilities/days-of-the-week';
import { PlannerPrinterService } from '../planner-printer.service';
import { PlannerService } from '../planner.service';

@Component({
  selector: 'app-print-week-selection',
  templateUrl: './print-week-selection.component.html',
  styleUrls: ['./print-week-selection.component.scss'],
})
export class PrintWeekSelectionComponent implements OnInit, OnDestroy {
  @Input() mealPlan: MealPlan;
  daysOfTheWeek = daysOfTheWeek;
  private subs: Subscription[] = [];

  weeklyPlans: WeeklyPlan[];
  weeklyPlans$: Observable<WeeklyPlan[]>;

  @Input()
  weeklyPlan: WeeklyPlan;

  showPrintView = false;
  readyToPrint = false;
  renderingProgress = 1;
  renderingProgressStyle = this.sanitizer.bypassSecurityTrustStyle(1 + '%');
  isPrinting = false;
  brand: Brand;
  view: string = 'prepping';

  hiddenRecipes = new Set<string>();

  printPublicNotes = true;
  printPrivateNotes = true;
  printNutritionFacts = false;

  constructor(
    private plannerPrinter: PlannerPrinterService,
    private plannerService: PlannerService,
    public layout: LayoutService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private router: Router
  ) {}

  reset() {
    this.readyToPrint = false;
    this.renderingProgress = 1;
    this.renderingProgressStyle = this.sanitizer.bypassSecurityTrustStyle(1 + '%');
    this.isPrinting = false;
    this.weeklyPlan = null;
    this.showPrintView = false;
  }

  ngOnInit() {
    console.log(`PlannerPrinterComponent.ngOnInit()`);

    this.weeklyPlans$ = this.plannerService.weeklyPlans$;

    const weeklyPlansSub = this.weeklyPlans$.subscribe((weeklyPlans) => {
      this.weeklyPlans = weeklyPlans;
      this.view = 'weekSelection';
    });
    this.subs.push(weeklyPlansSub);

    const brandSub = this.layout.getBrand().subscribe((brand) => (this.brand = brand));
    this.subs.push(brandSub);
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  date(weekNumber, day) {
    return calcDate(weekNumber, day).format('MMMM D, YYYY');
  }

  adjust() {
    // const container = this.el.nativeElement.querySelector('#weekly-planner-print-container');
    // const wrapper = this.el.nativeElement.querySelector('#weekly-planner-wrapper');
    // const wrapperWidth = wrapper.clientWidth;
    // const wrapperHeight = wrapper.clientHeight;
    // const diff = (wrapperWidth - wrapperHeight) / 2;
    // console.log('diff: ' + diff);
    //
    // wrapper.setAttribute('style', `position: relative; top: ${diff}px;`);
  }

  print() {
    this.isPrinting = true;
    this.view = 'prepping';
    console.log('triggering print');
    window.print();
    // this.closePrinter();
  }

  private closePrinter() {
    this.reset();
    // this.plannerPrinter.donePrinting();
    this.router.navigateByUrl(sessionStorage.getItem('plannerPath'));
  }

  cancelPrint() {
    this.closePrinter();
  }

  /**
   * @param {WeeklyPlan} weeklyPlan
   */
  printWeeklyPlan(weeklyPlan: WeeklyPlan) {
    this.weeklyPlan = weeklyPlan;
    this.showPrintView = true;
    this.view = null;

    this.renderingProgress = 100;

    setTimeout(() => this.adjust(), 1000);
    // setTimeout(() => this.print(), 5000);
  }

  refreshHiddenRecipes() {
    // triggers change detection
    this.hiddenRecipes = new Set(this.hiddenRecipes.values());
  }

  hideRecipe(locator: string) {
    this.hiddenRecipes.add(locator);
    this.refreshHiddenRecipes();
  }

  showRecipe(locator: string) {
    this.hiddenRecipes.delete(locator);
    this.refreshHiddenRecipes();
  }
}
