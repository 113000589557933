import { plainToClass } from 'class-transformer';
import { User } from '../../../lprx-shared-lib/entities/user/user';

export class UserFactory {
  /**
   * @param {User} obj
   * @returns {any}
   */
  public static fromObject(obj: User): User {
    let userInstance: User;
    userInstance = new User();
    return plainToClass(User, { ...userInstance, ...obj });
  }
}
