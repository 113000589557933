<div *ngIf="isRecipe"
     class="recipe"
     [class.is-deleted]="card.recipe.status === 'deleted'"
     [class.is-leftover]="card.isLeftover"
     [class.ingredient-alert]="ingredientAlert !== ''"
     [attr.data-ingredientAlert]="ingredientAlert"
     [disablePopover]="ingredientAlert.length === 0"
     [ngbPopover]="recipeNotices"
     [triggers]="isTablet ? '' : 'mouseenter:mouseleave'"
     [title]="title"
     (mouseenter)='showRemove = true'
        (mouseleave)='showRemove = false'
>

    <div (click)="viewRecipe()">
        <div class="card-image" [style.backgroundImage]="'url('+ cardImage +')'">
            <img src="{{cardImage}}">
        </div>
        <div class="title">
            <img *ngIf="card.isLeftover" src="/assets/img/icon/doggie-bag.svg" height="20" alt="Leftover" />
            {{card.recipe.title}}
        </div>
    </div>

    <mat-icon
        *ngIf="ingredientAlert.length > 0"
        [color]="'warn'"
        class="ingredientAlert">report_problem
    </mat-icon>

    <a *ngIf="(!isTablet && canDelete) || showRemove" class="remove-card" title="Remove {{card.recipe.title}}" (click)="removeThisCard($event)">x</a>
</div>

<div *ngIf="card.isNote && card.isNote() && showNote"
     (click)="openNote()"
     class="note clickable"
     [class.private-note]="card.note.isPrivate"
     [class.public-note]="!card.note.isPrivate">
    <div class="note-content" [innerHTML]="card.note.message"></div>
    <a *ngIf="canDelete" class="remove-card" title="Remove note" (click)="removeThisCard($event)">x</a>
</div>
<div class="note-read-more clickable" *ngIf="showReadMore" (click)="openNote()">Read More</div>


<ng-template #recipeNotices>
    <div class="p-2">
        <strong>Food preference alert</strong>
        <br>
        {{ingredientAlert.join(', ')}}
    </div>
</ng-template>
