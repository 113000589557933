import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CopyToLeftoversData } from './copy-to-leftovers-data';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import { NextDays, WeekNumberDay } from '../../next-days';
import {
  ButtonDirective,
  PrimaryButtonDirective,
  SecondaryButtonDirective,
} from '../../../../component-directives/button.directive';
import { Meal } from '../../../../lprx-shared-lib/entities/weeky-meal-plan/Meal';
import { Card } from '../../../../lprx-shared-lib/entities/weeky-meal-plan/Card';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CdnService } from '../../../service/cdn.service';
import { PipesModule } from '../../../pipe/pipes.module';
import { LprxUser } from '../../../../lprx-shared-lib/entities/user/user';
import { AuthService } from '../../../auth.service';
import { Subscription } from 'rxjs/Subscription';
import { unsub, unsubscribe } from '../../../../lprx-shared-lib/utils/unsubscribe';
import { UserType } from '../../../../lprx-shared-lib/entities/user/UserType';

@Component({
  standalone: true,
  selector: 'app-copy-to-leftovers',
  templateUrl: './copy-to-leftovers-dialog.component.html',
  styleUrls: ['./copy-to-leftovers-dialog.component.scss'],
  imports: [
    NgForOf,
    ButtonDirective,
    SecondaryButtonDirective,
    NgOptimizedImage,
    PrimaryButtonDirective,
    PipesModule,
    MatDialogModule,
    NgIf,
  ],
})
export class CopyToLeftoversDialogComponent implements OnDestroy, OnInit {
  selected: NextDays = [];
  meals: Meal[];
  selectedMealId: string;
  recipeSrc: string;
  user: LprxUser;
  subs: Subscription[] = [];

  constructor(
    public dialogRef: MatDialogRef<CopyToLeftoversDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: CopyToLeftoversData,
    private sanitizer: DomSanitizer,
    private cdn: CdnService,
    private auth: AuthService
  ) {}
  ngOnInit(): void {
    this.subs.push(this.auth.user$.subscribe((u) => (this.user = u)));

    this.selectedMealId = this.data.mealId;

    this.recipeSrc = this.cdn.getSizedImageUrl(this.data.recipe.image, 90, 90);

    if (this.data.nextDays.length > 0) {
      let nextDay = this.data.nextDays[0];
      this.meals = this.data.plannerService
        .getWeeklyPlan(nextDay.weekNumber)
        .getDay(nextDay.dayName).meals;
    }
  }

  ngOnDestroy(): void {
    unsub(this);
  }

  changed($event: Event, next: WeekNumberDay) {
    console.log({ $event, next });

    let target = <HTMLInputElement>$event.target;
    console.log({ next, checked: target.checked });

    if (target.checked) {
      this.selected.push(next);
    } else {
      // remove from selected
      this.selected = this.selected.filter(
        (item) => item.dayName + item.weekNumber !== next.dayName + next.weekNumber
      );
    }

    console.log(this.selected);
  }

  copy() {
    const weeksModified = new Set<string>();

    for (const w of this.selected) {
      const card = Card.newRecipeCard(this.data.recipe);
      card.isLeftover = true;

      this.data.plannerService
        .getWeeklyPlan(w.weekNumber)
        .getDay(w.dayName)
        .getMeal(this.selectedMealId)
        .addCard(card);

      weeksModified.add(w.weekNumber);
    }

    // for each weeksModified save the week
    weeksModified.forEach((w) => this.data.plannerService.weeklyPlanUpdated(w));

    this.dialogRef.close();
  }

  cancel() {
    this.dialogRef.close();
  }

  selectMeal(meal: Meal) {
    this.selectedMealId = meal.id;
  }

  isSelected(next: WeekNumberDay): boolean {
    return !!this.selected.find(
      (item) => item.dayName + item.weekNumber === next.dayName + next.weekNumber
    );
  }

  protected readonly UserType = UserType;
}
