/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/planner/planner-configuration.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { defaultNutrients } from '../default-nutrients';
import { IngredientPreferences } from './ingredient.preferences';
import { PlannerSearchConfiguration } from './planner-search-configuration';

export interface PlannerConfigNutrientLimit {
  key: string;
  nutrient: string;
  low: number;
  high: number;
  enabled: boolean;
  source?: 'mealplan' | 'client';
}

export class PlannerConfigNutrientLimits {
  daily?: PlannerConfigNutrientLimit[] = [
    {
      key: 'ENERC_KCAL',
      nutrient: 'Calories',
      low: 0,
      high: 99999,
      enabled: false,
      source: 'mealplan',
    },
    {
      key: 'CHOCDF',
      nutrient: 'Carbohydrates (g)',
      low: 0,
      high: 99999,
      enabled: false,
      source: 'mealplan',
    },
    {
      key: 'PROCNT',
      nutrient: 'Protein (g)',
      low: 0,
      high: 99999,
      enabled: false,
      source: 'mealplan',
    },
    { key: 'FAT', nutrient: 'Fat (g)', low: 0, high: 99999, enabled: false, source: 'mealplan' },
    {
      key: 'FIBTG',
      nutrient: 'Fiber (g)',
      low: 0,
      high: 99999,
      enabled: false,
      source: 'mealplan',
    },
    {
      key: 'SUGAR',
      nutrient: 'Sugar (g)',
      low: 0,
      high: 99999,
      enabled: false,
      source: 'mealplan',
    },

    { key: 'NA', nutrient: 'Sodium (mg)', low: 0, high: 99999, enabled: false, source: 'mealplan' },
  ];
}

export class PlannerConfiguration {
  showNutritionFacts = true;
  nutrients = new Set(defaultNutrients);
  additionalNutrients = [];
  canEditMeals = true;
  canCanRecipes = true;
  canCopyRecipes = true;
  maxPastWeeks = 1;
  maxFutureWeeks = 1;
  searchType: PlannerSearchConfiguration = new PlannerSearchConfiguration();
  canPrint: boolean = true;
  nutrientLimits: PlannerConfigNutrientLimits = new PlannerConfigNutrientLimits();
  ingredientPreferences: IngredientPreferences = new IngredientPreferences();
}
