<div class="row no-gutters align-items-center" style="border-bottom: 1px solid #e0e0e0">
    <div class="col text-center">
        <span class="-calories-value"><strong style="font-size: 1.1em">{{calories}} cal</strong></span>
    </div>
</div>
<div class="row no-gutters align-items-center">
    <div class="col">

        <table style="width: 100%">
            <tr>
                <td class="bar-cell">
                    <div class="ratio-bar" [style.width]="carbWidth" style="background-color: #bee1e8"></div>
                </td>
                <td class="nutrient-value">&nbsp;{{carbs}}g ({{carbRatio.toFixed(0)}}%)</td>
                <td class="nutrient-name">&nbsp;carb</td>
            </tr>
            <tr>
                <td class="bar-cell">
                    <div class="ratio-bar" [style.width]="proteinWidth" style="background-color: #c9db8d"></div>
                </td>
                <td class="nutrient-value">&nbsp;{{protein}}g ({{proteinRatio.toFixed(0)}}%)</td>
                <td>&nbsp;pro</td>
            </tr>
            <tr>
                <td class="bar-cell">
                    <div class="ratio-bar" [style.width]="fatWidth" style="background-color: #f4b5b5"></div>
                </td>
                <td class="nutrient-value">&nbsp;{{fat}}g ({{fatRatio.toFixed(0)}}%)</td>
                <td class="nutrient-name">&nbsp;fat</td>
            </tr>
        </table>
    </div>
</div>
<ng-container *ngIf="additionalNutrients?.length > 0">
    <div *ngFor="let additionalNutrient of additionalNutrients" class="additional-nutrient">
        <strong>{{nutrientName(additionalNutrient)}}</strong> {{nutrientValue(additionalNutrient)}}
    </div>
</ng-container>
