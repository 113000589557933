import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';

@Component({
  selector: 'app-pending-setup',
  templateUrl: './pending-setup.component.html',
  styleUrls: ['./pending-setup.component.scss'],
})
export class PendingSetupComponent implements OnInit, OnDestroy {
  private subs: Subscription[] = [];
  email: string;
  private processing: boolean;
  emailSent: boolean;
  constructor(private lprxApi: LprxApiProvider, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.subs.push(
      this.route.queryParams.subscribe((p) => {
        this.email = p['email'];
        this.email = this.email.replace(' ', '+');
      })
    );
  }

  sendEmail() {
    if (this.processing) {
      return;
    }
    this.processing = true;
    this.emailSent = false;
    this.lprxApi.distributors.sendPendingProfile(this.email).then(() => {
      this.emailSent = true;
      return this.processing;
    });
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }
}
