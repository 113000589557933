import { Pipe, PipeTransform } from '@angular/core';
import { CdnService } from '../service/cdn.service';

@Pipe({
  name: 'cdn'
})
export class CdnPipe implements PipeTransform {
  constructor(private cdn: CdnService) {}

  transform(key: string, width: number, height: number, asStyle = true): any {
    if (asStyle) {
      return this.cdn.getSafeStyleUrl(key, width, height);
    } else {
      return this.cdn.getSizedImageUrl(key, width, height);
    }
  }
}
