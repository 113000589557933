export function doNotShowNewDragDropDialog() {
  const item = localStorage.getItem('dontShowNewDrag');
  if (!item) {
    return false;
  }
  return JSON.parse(item);
}

export function setDontShowNewDrag(bool: boolean) {
  localStorage.setItem('dontShowNewDrag', JSON.stringify(bool));
}

export function doShowNewDragDropDialog() {
  return !doNotShowNewDragDropDialog();
}
