/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/days-of-the-week.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class DaysOfTheWeek {
  public static readonly daysMap = [
    { abbrv: 'Mon', name: 'Monday' },
    { abbrv: 'Tue', name: 'Tuesday' },
    { abbrv: 'Wed', name: 'Wednesday' },
    { abbrv: 'Thu', name: 'Thursday' },
    { abbrv: 'Fri', name: 'Friday' },
    { abbrv: 'Sat', name: 'Saturday' },
    { abbrv: 'Sun', name: 'Sunday' },
  ];

  static getLong(
    abbr:
      | 'Mon'
      | 'Tue'
      | 'Wed'
      | 'Thu'
      | 'Fri'
      | 'Sat'
      | 'Sun'
      | 'mon'
      | 'tue'
      | 'wed'
      | 'thu'
      | 'fri'
      | 'sat'
      | 'sun'
      | string,
    toLower = true
  ) {
    let name = DaysOfTheWeek.daysMap.find((d) => abbr.toLowerCase() == d.abbrv.toLowerCase())?.name;
    if (name) {
      return toLower ? name.toLowerCase() : name;
    }

    return abbr;
  }

  static get days() {
    const days: string[] = [];
    for (const d of DaysOfTheWeek.daysMap) {
      days.push(d.abbrv);
    }
    return days;
  }

  static getDays(): any {
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  }

  static getDaysLong(lowercase: boolean = false): any {
    let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    if (lowercase) {
      days = days.map((d) => d.toLowerCase());
    }

    return days;
  }

  getDays() {
    return DaysOfTheWeek.days;
  }
}
