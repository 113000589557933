/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/distributor-meal-plan-pricing.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Type } from 'class-transformer';
import { MealPlanPricing } from './meal-plan-pricing';
import { Default } from './utils/default.decorator';

export class IntervalPricing {
  price: number;
  stripePriceId?: string;
}

// export interface DistributorMealPlanPricingInterface {
//   default: MealPlanPricing;
// }

const defaultStaticPricing = {
  monthly: { price: 9 },
  yearly: { price: 90 }
};

const defaultCustomPricing = {
  monthly: { price: 19 },
  yearly: { price: 190 }
};

@Exclude()
export class DistributorMealPlanPricing {
  @Expose()
  @Default('usd')
  currency: string = 'usd';

  @Expose()
  @Default(defaultStaticPricing)
  @Type(() => MealPlanPricing)
  static: MealPlanPricing = defaultStaticPricing;

  @Expose()
  @Default(defaultCustomPricing)
  @Type(() => MealPlanPricing)
  custom: MealPlanPricing = defaultCustomPricing;

  @Expose()
  @Default(0)
  trialDays: number = 0;
}
