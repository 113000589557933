import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CreditCardInputComponent } from '../credit-card-input/credit-card-input.component';

import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PaymentPlanType } from '../../lprx-shared-lib/payment-plan-type';
import Stripe from 'stripe';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import * as stripe from '@stripe/stripe-js';
import {
  loadStripe,
  StripeElements,
  StripeElementsOptions,
  StripePaymentElement,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { environment } from '../../environments/environment';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { ActivatedRoute } from '@angular/router';
import { PrimePurchaseService } from '../distributor/prime/prime-checkout/prime-purchase.service';
import { DistributorsService } from '../service/distributors.service';
import { AuthService } from '../auth.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { from } from 'rxjs/observable/from';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import { DistributorType } from '../../lprx-shared-lib/entities/distributor-type';
import { unsubscribe } from '../../lprx-shared-lib/utils/unsubscribe';
import { errorMessage } from '../utilities/alert-http-error';
import { PurchaseDto } from '../../lprx-shared-lib/social-prime-purchase.dto';
import { sanitizeEmail } from '../../lprx-shared-lib/sanitize-email';
import { passwordValidatorFunction } from './password-validator-function';
import { emailValidator } from './email-validator';
import { User } from '../../lprx-shared-lib/entities/user/user';
import { PrimePurchasePlansResponseDto } from '../../lprx-shared-lib/dto/prime-purchase-plans-response';
import { UserType } from '../../lprx-shared-lib/entities/user/UserType';

@Component({
  selector: 'app-get-prime',
  templateUrl: './get-prime.component.html',
  styleUrls: ['./get-prime.component.scss'],
})
export class GetPrimeComponent implements OnInit, OnDestroy, AfterViewInit {
  private subs: Subscription[] = [];

  @ViewChild('ccForm', { static: true })
  ccForm: CreditCardInputComponent;

  // @ViewChild(StripePaymentElementComponent)

  paymentElement!: StripePaymentElement;

  elements!: any;

  form = this.fb.group(
    {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email, emailValidator]],
      type: ['', Validators.required],
      password: ['', passwordValidatorFunction()],
      tou: [{ value: false, disabled: true }, [Validators.required, Validators.requiredTrue]],
      promoCode: [''],
    },
    {
      validators: (form) => {
        // tou must be checked
        if (!form.get('tou').value) {
          return { tou: 'You must agree to the terms of use to continue.' };
        }
        return null;
      },
    }
  );

  _annualPrice = 0;
  _monthlyPrice = 0;

  annualPrice = `\$${this._annualPrice}/year`;
  monthlyPrice = `\$${this._monthlyPrice}/month`;

  // savings  is _annualPrice - (_monthlyPrice * 12) as integer
  savings = 0;

  planChoice: PaymentPlanType = 'annual';
  type?: 'healthcare' | 'wellness';
  processing: boolean = false;
  error: SafeHtml = null;

  promoCode?: string;
  calculating: boolean;
  promoCodeError: string;
  plans: Stripe.Plan[];
  appliedCode: string;
  private coupon: Stripe.Coupon;
  // private appliedPromoCode: null;
  // private billingStart: moment.Moment;
  private discount: number;
  private subtotal: number;
  private total: number;
  distributor: Distributor;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    mode: 'subscription',
    appearance: {
      theme: 'flat',
    },
  };

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  private stripeElements: StripeElements;

  stripe: stripe.Stripe;

  @ViewChild('paymentElement')
  stripeElement: ElementRef<HTMLElement>;

  @ViewChild('paymentForm')
  paymentFormElementRef: ElementRef<HTMLDivElement>;

  // terms
  @ViewChild('terms')
  termsElementRef: ElementRef<HTMLDivElement>;

  @ViewChild('touBox')
  touBoxRef: ElementRef<HTMLDivElement>;

  touDisabled: boolean = true;
  showPaymentForm: boolean = false;
  showTerms: boolean = false;
  showDistributor: boolean = true;

  loading = true;
  private user: User;
  welcomeBackMessage?: string;
  welcomeBackPricing: PrimePurchasePlansResponseDto['welcomeBackPricing'];
  enablePromoCode: boolean = true;

  constructor(
    private fb: UntypedFormBuilder,
    private lprxApi: LprxApiProvider,
    private route: ActivatedRoute,
    private readonly primePurchaseService: PrimePurchaseService,
    private readonly distributorsService: DistributorsService,
    private readonly authService: AuthService,

    private readonly sanitizer: DomSanitizer
  ) {}

  ngAfterViewInit(): void {}

  init() {
    /*
    If redirected to this page with a payment_intent and payment_intent_client_secret, then the user has already paid.
    https://lprx.ngrok.io/prime?payment_intent=pi_3P65IKAcigfpvGsV1JkNfRHB&payment_intent_client_secret=pi_3P65IKAcigfpvGsV1JkNfRHB_secret_Ll1FkX9s9naaBfZpIguXOIXWf&redirect_status=succeeded
     */

    console.log('Get Prime Component 1');

    from(this.authService.fetchUser())
      .pipe(
        tap((user) => {
          this.user = user;
          console.log('Get Prime Component 2');
          console.log({ user });
        }),
        switchMap((user) => {
          if (user?.userType === UserType.Distributor) {
            return this.distributorsService.currentDistributor$;
          }

          return of(null);
        })
      )
      .subscribe({
        next: (distributor: Distributor | null) => {
          this.loading = false;
          console.log('Get Prime Component 3');
          if (distributor) {
            this.distributor = distributor;

            this.showTerms = true;
            this.showDistributor = false;
            this.showPaymentForm = false;

            // patch form with user data
            this.form.patchValue({
              firstName: distributor.firstName,
              lastName: distributor.lastName,
              email: distributor.email,
              // this is a dummy password for validation and will be ignored
              password: '7h15w1Ll8319n0r3d',
              type:
                distributor.type === DistributorType.HealthCareProfessional
                  ? 'healthcare'
                  : 'wellness',
            });

            // if primeTerminatesAt is in the future, then they are already a prime member
            if (distributor.primeTerminatesAt > Date.now()) {
              // alert('You are already a member!');
            }
            return of(true);
          }
          console.log(`User is not a distributor`);
          return of(false);
        },
        error: (e) => {
          console.error(`Error fetching distributor: ${e}`);
        },
      });

    this.subs.push(
      this.route.queryParams.subscribe((p) => {
        if (p['alert']) {
          this.alert = p['alert'];
        }

        if (p['promo']) {
          this.form.patchValue({ promoCode: p['promo'] });
        }

        console.log('Get Prime Component 4');
        if (p['ref']) {
          localStorage.setItem('prime-referrer', p['ref']);
        }

        if (p['fpr']) {
          localStorage.setItem('prime-referrer', p['fpr']);
        }
      })
    );

    this.primePurchaseService.getPlans().subscribe((plans) => {
      this.plans = plans.plans;

      this.planChoice =
        plans.plans[plans.plans.length - 1].interval === 'year' ? 'annual' : 'monthly';

      if (plans.welcomeBackPricing) {
        // remove local storage of prime-referrer and fpr
        localStorage.removeItem('prime-referrer');
        localStorage.removeItem('fpr');

        this.enablePromoCode = false;

        this.welcomeBackPricing = plans.welcomeBackPricing;

        this._monthlyPrice = plans.welcomeBackPricing.monthly;
        this._annualPrice = plans.welcomeBackPricing.annually;
        this.welcomeBackMessage = plans.welcomeBackPricing.message;

        // this.enablePaymentForm();

        if (plans.welcomeBackPricing.couponCode) {
          this.form.patchValue({ promoCode: plans.welcomeBackPricing.couponCode });
          this.applyPromoCode();
        }
      } else {
        // only keep the highest plan for each interval
        plans.plans = plans.plans.reduce((acc: Stripe.Plan[], plan: Stripe.Plan) => {
          const existing = acc.find((p) => p.interval === plan.interval);
          if (existing) {
            if (existing.amount < plan.amount) {
              return acc.filter((p) => p.interval !== plan.interval).concat(plan);
            }
            return acc;
          }
          return acc.concat(plan);
        }, []);

        this._monthlyPrice = plans.plans.find((p) => p.interval === 'month').amount;
        this._annualPrice = plans.plans.find((p) => p.interval === 'year').amount;
      }

      this.monthlyPrice = `\$${this._monthlyPrice / 100}/month`;
      this.annualPrice = `\$${this._annualPrice / 100}/year`;

      this.savings = Math.floor((this._monthlyPrice * 12 - this._annualPrice) / 100);

      console.log('Get Prime Component 5');
      console.log({ plans });
      this.plans = plans.plans;
      this.applyPrime20();
      this.calcPricing();

      if (this.form.get('promoCode').value) {
        this._applyPromoCode(this.form.get('promoCode').value);
      }

      if (localStorage.getItem('prime-referrer')) {
        this._applyPromoCode('prime20');
      }
    });
  }

  fpr: any;

  ngOnInit(): void {
    from(loadStripe(environment.stripe_public_key)).subscribe((stripe) => {
      this.stripe = stripe;
      this.init();
    });

    let ref = localStorage.getItem('referrerId');

    let w = window as any;
    if (w.fpr) {
      this.fpr = w.fpr;
    }

    if (this.fpr && ref) {
      this.fpr('init', { cid: 'pwicgmil', ref_id: ref });
      this.fpr('crossDomain', [
        'livingplaterx.com',
        'www.livingplaterx.com',
        'home.livingplaterx.com',
        'lprx.ngrok.io',
        'lprx-sandbox.com',
      ]);
      this.fpr('click');
    } else if (ref) {
      throw new Error('fpr not loaded');
    } else {
      throw new Error('ref not found');
    }
  }

  async setStripe() {
    let amount = Math.floor(this.total * 100);
    console.log({ amount });

    if (this.paymentElement) {
      this.stripeElements.update({ amount: amount });
      return;
    }

    this.stripeElements = this.stripe.elements({
      mode: 'subscription',
      amount: amount,
      locale: 'en',
      currency: 'usd',
      appearance: {
        theme: 'flat',
      },
    });

    this.paymentElement = this.stripeElements.create('payment');
    console.log(this.stripeElement);
    this.paymentElement.mount(this.stripeElement.nativeElement);
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  submit($event: Event) {
    $event.preventDefault();

    if (this.processing) {
      return;
    }
    this.error = null;

    console.log($event);
    this.processPayment().catch((e) => {
      let message = errorMessage(e);
      console.log(e);
      if (e.error.type.match('email-in-use')) {
        message += ' Please <a href="/login">login</a> or use a different email.';
      }
      this.processingError(message);
    });
  }

  private async processPayment() {
    this.processing = true;

    const stripe = this.stripe;
    const elements = this.stripeElements;

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      this.processingError(errorMessage(submitError.message));
      return;
    }

    let email = sanitizeEmail(this.form.get('email').value);

    console.log('should have referred');

    const purchaseDto: PurchaseDto = {
      email: email,
      firstName: this.form.get('firstName').value,
      lastName: this.form.get('lastName').value,
      type: this.form.get('type').value,
      priceId: this.getSelectedPlan().id,
      password: this.form.get('password').value.trim(),
    };

    if (this.welcomeBackPricing?.origSubscriptionId) {
      purchaseDto.origSubscriptionId = this.welcomeBackPricing.origSubscriptionId;
      purchaseDto.wbp = this.total;
    }

    if (this.distributor) {
      purchaseDto.distributorId = this.distributor.id;
      purchaseDto.username = this.user.username;
    }

    if (this.appliedCode) {
      purchaseDto.code = this.appliedCode;
    }

    if (localStorage.getItem('referrerId')) {
      purchaseDto.referrer = localStorage.getItem('referrerId');
    }

    // Create the subscription
    const res = await this.lprxApi.post<{
      type: 'setup' | 'payment';
      clientSecret: string;
      magicLink: string;
      stripeCustomerId: string;
    }>('v3/dmz/signup', purchaseDto);

    const { type, clientSecret } = res;

    const confirmIntent = type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment;

    // Confirm the Intent using the details collected by the Payment Element
    const response = await confirmIntent({
      elements,
      clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (
      response['paymentIntent']?.status === 'succeeded' ||
      response['setupIntent']?.status === 'succeeded'
    ) {
      // check local storage for referrerId
      let ref = localStorage.getItem('referrerId');

      if (this.fpr && ref) {
        // this.fpr('referral', { email: this.form.get('email').value, uid: res.stripeCustomerId });
      } else if (ref) {
        throw new Error('fpr not loaded');
      } else {
        throw new Error('ref not found');
      }

      console.log(res);

      window.location.href = res.magicLink;

      // magic link
    } else {
      console.log(response);
    }

    if (response.error) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      // Show the error to your customer (for example, "payment details incomplete").
      this.processingError(submitError.message);
    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    this.processing = false;
    this.error = null;
  }

  private processingError(message: string) {
    this.error = this.sanitizer.bypassSecurityTrustHtml(message);
    this.processing = false;
  }

  private applyPrime20() {
    if (localStorage.getItem('fpr')) {
      this.form.patchValue({ promoCode: 'prime20' });
      this._applyPromoCode('prime20');
    }
  }

  calcPricing() {
    const selectedPlan = this.getSelectedPlan();

    console.log({ selectedPlan });

    this.discount = 0;
    this.subtotal = selectedPlan.amount;

    if (this.coupon) {
      this.discount = this.coupon.amount_off
        ? this.coupon.amount_off
        : this.subtotal * (this.coupon.percent_off / 100);
    }

    this.total = (this.subtotal - this.discount) / 100;

    this.calculating = false;

    console.log('discounts............');
    console.log(this.total, this.subtotal, this.coupon, this.discount);

    this.setStripe();
  }

  _applyPromoCode(promoCode: string) {
    if (!promoCode) {
      return;
    }

    this.calculating = true;
    this.promoCodeError = null;

    let selectedPlan: Stripe.Plan;

    selectedPlan = this.getSelectedPlan();

    this.primePurchaseService
      .checkPromoCode(promoCode, selectedPlan.id)
      .pipe(
        catchError((e) => {
          this.promoCodeError = errorMessage(e);
          return of(null);
        }),
        finalize(() => {
          this.calcPricing();
        })
      )
      .subscribe((couponSetting) => {
        console.log(couponSetting);
        this.coupon = null;
        this.appliedCode = null;

        if (couponSetting.coupon) {
          if (couponSetting.coupon.valid) {
            this.coupon = couponSetting.coupon;
            this.appliedCode = promoCode;
          } else {
            this.promoCodeError = `Promo code '${promoCode}' is no longer valid.`;
          }
        }
      });
  }

  private getSelectedPlan() {
    // Translate the planChoice to the stripe interval
    const stripeInterval = this.planChoice === 'annual' ? 'year' : 'month';
    return this.plans.find((p) => p.interval === stripeInterval);
  }

  applyPromoCode() {
    this._applyPromoCode(this.form.get('promoCode').value);
  }

  changePlan(monthly: PaymentPlanType) {
    this.planChoice = monthly;
    this.applyPromoCode();
    this.calcPricing();
  }

  buyNowText() {
    const interval = this.planChoice === 'annual' ? 'year' : 'month';
    const total = this.total;
    const regularPrice = this.subtotal / 100;
    let output = `Buy Now \$${total}/${interval}`;

    if (this.welcomeBackPricing && !this.coupon) {
      const wbPrice =
        this.planChoice === 'annual'
          ? this.welcomeBackPricing.annually
          : this.welcomeBackPricing.monthly;

      let duration: 'forever' | 'once' = 'forever';

      const discountedPrice = (wbPrice / 100).toFixed(2);
      const regularPriceStr = `\$${regularPrice}/${interval}`;
      const discountedPriceStr = `\$${discountedPrice}/${interval}`;

      // output =
      //   true
      //     ? `Buy Now ${regularPriceStr} ($${discountedPrice} first ${interval})`
      //     : `Buy Now <s>(${regularPriceStr})</s> ${discountedPriceStr}`;

      output = `Buy Now <s>(${regularPriceStr})</s> ${discountedPriceStr}`;
    } else if (this.coupon) {
      const discountedPrice = (regularPrice - this.discount / 100).toFixed(2);
      const duration = this.coupon.duration;
      const regularPriceStr = `\$${regularPrice}/${interval}`;
      const discountedPriceStr = `\$${discountedPrice}/${interval}`;

      output =
        duration === 'once'
          ? `Buy Now ${regularPriceStr} ($${discountedPrice} first ${interval})`
          : `Buy Now <s>(${regularPriceStr})</s> ${discountedPriceStr}`;
    }

    return this.sanitizer.bypassSecurityTrustHtml(output);
  }

  checkTouScrollBottom($event: any) {
    // Get a fresh reference to the element
    const element = this.touBoxRef.nativeElement;
    
    // Add a larger buffer for mobile devices (20px instead of 5px)
    // This helps account for elastic scrolling and different device behaviors
    const buffer = 20;
    
    // Force a reflow to ensure we have the most up-to-date measurements
    void element.offsetHeight;
    
    const scrolledToBottom = 
      Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) <= buffer;
    
    if (scrolledToBottom && this.touDisabled) {
      this.touDisabled = false;
      this.form.get('tou').enable();
      this.form.get('tou').setValue(false);
    }
  }

  enablePaymentForm() {
    this.showTerms = false;
    this.showDistributor = false;
    this.showPaymentForm = true;
    setTimeout(() => {
      this.scrollToElement(this.paymentFormElementRef.nativeElement);
    }, 250);
  }

  enableTerms(scroll = true, expect = false) {
    this.error = null;

    // check with api if the user has an existing account
    from(
      this.lprxApi.get<'true' | 'false'>(`v3/users/e/${this.form.get('email').value}`)
    ).subscribe((res) => {
      if (!res) {
        this.showTerms = true;
        this.showDistributor = false;
        this.showPaymentForm = false;
        if (scroll) {
          setTimeout(() => {
            this.scrollToElement(this.termsElementRef.nativeElement);
          }, 250);
        }
      } else {
        // show error
        this.error = this.sanitizer.bypassSecurityTrustHtml(
          `You already have an account. Please <a href="/distributor/dashboard?email=${
            this.form.get('email').value
          }">log in</a>.`
        );
      }
    });
  }

  scrollToElement($element: HTMLElement): void {
    console.log($element);
    $element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  }

  protected readonly JSON = JSON;
  protected readonly Array = Array;
  alert: string | null = null;

  enableDistributor() {
    this.showDistributor = true;
    this.showPaymentForm = false;
    this.showTerms = false;
  }
}
