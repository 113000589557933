/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/id-prefix.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum IdPrefix {
  AffiliateAccount = 'aff',
  DistributorAffiliatePayout = 'dap',
  DistributorApplication = 'da',
  DistributorCreditTransaction = 'dct',
  FullscriptAccount = 'fsa',
  Recipe = 'recipe',
  User = 'user',
  IntegrationAuthToken = 'at',
  Distributor = 'dist',
  Tag = 'tag',
}
