import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GroceryListComponent } from './grocery-list/grocery-list.component';
import { NutritionReportComponent } from './nutrition-report/nutrition-report.component';
import { PlannerOutletComponent } from './planner-outlet/planner-outlet.component';
import { PlannerPrinterComponent } from './planner-printer/planner-printer.component';
import { PlannerRecipeViewerComponent } from './planner-recipe-viewer/planner-recipe-viewer.component';
import { PlannerSearchComponent } from './planner-search/planner-search.component';
import { PlannerComponent } from './planner/planner.component';
import { PrintWeekSelectionComponent } from './print-week-selection/print-week-selection.component';
import { RecipeBoxComponent } from './recipe-box/recipe-box.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';

const routes: Routes = [
  {
    path: '',
    component: PlannerOutletComponent,
    children: [
      { path: '', component: PlannerComponent, pathMatch: 'full' },
      {
        path: 'day/:weekNumber/:day',
        component: PlannerComponent,
        pathMatch: 'full',
      },
      {
        path: 'day/:weekNumber/:day/print',
        component: PrintWeekSelectionComponent,
        pathMatch: 'full',
      },
      {
        path: 'day/:weekNumber/:day/:meal',
        component: PlannerComponent,
        pathMatch: 'full',
      },
      {
        path: 'client/:username/grocery-list',
        component: GroceryListComponent,
        pathMatch: 'full',
      },
      {
        path: 'client/:username/print',
        component: PlannerPrinterComponent,
        pathMatch: 'full',
      },
      {
        path: 'client/:username/recipes',
        component: RecipeBoxComponent,
        pathMatch: 'full',
      },
      {
        path: 'recommendations',
        component: RecommendationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'client/:username/:mealPlanId/:distributorId/recommendations',
        component: RecommendationsComponent,
        pathMatch: 'full',
      },
      {
        path: 'client/:username/:mealPlanId/:distributorId',
        children: [
          { path: '', component: PlannerComponent, pathMatch: 'full' },
          {
            path: 'print',
            component: PrintWeekSelectionComponent,
          },
          {
            path: ':weekNumber',
            children: [
              {
                path: '',
                component: PlannerComponent,
                pathMatch: 'full',
              },
              {
                path: 'print',
                component: PlannerPrinterComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'preview/:mealPlanId',
        component: PlannerComponent,
        pathMatch: 'full',
        data: { preview: true },
      },
      { path: 'grocery-list', component: GroceryListComponent },
      { path: 'grocery-list/:weekNumber', component: GroceryListComponent },
      { path: 'print', component: PrintWeekSelectionComponent },
      { path: 'nutrition-facts', component: NutritionReportComponent },
      { path: 'recipes', component: RecipeBoxComponent, pathMatch: 'full' },
      { path: 'recipe/:recipeId', component: PlannerRecipeViewerComponent },
      {
        path: 'recipe/:recipeId/:weekNumber/:day/:meal',
        component: PlannerRecipeViewerComponent,
      },
      {
        path: 'recipe/:recipeId/:weekNumber/:day/:meal/:action',
        component: PlannerRecipeViewerComponent,
      },
      { path: 'search', component: PlannerSearchComponent },
      {
        path: 'search/:weekNumber/:day/:meal',
        component: PlannerSearchComponent,
      },
      {
        path: 'search/:weekNumber/:day/:meal/:action',
        component: PlannerSearchComponent,
      },
      {
        path: ':weekNumber/print',
        pathMatch: 'full',
        component: PlannerPrinterComponent,
      },
      {
        path: ':master/:mealPlanId',
        children: [
          {
            path: 'print',
            component: PrintWeekSelectionComponent,
          },
          {
            path: 'recipes',
            component: RecipeBoxComponent,
          },
          {
            path: ':weekNumber',
            children: [
              {
                path: '',
                component: PlannerComponent,
                pathMatch: 'full',
              },
              {
                path: 'print',
                component: PlannerPrinterComponent,
              },
            ],
          },
          {
            path: '',
            component: PlannerComponent,
            pathMatch: 'full',
          },
        ],
      },
      {
        path: ':weekNumber',
        pathMatch: 'full',
        component: PlannerComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PlannerRoutingModule {}
