/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/GroceryCategories.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export const otherKey = 'other';

export class GroceryCategories {
  static groceryCategoryMapping = {
    Produce: [],
    'Dry Goods': [],
    Dairy: [],
    Frozen: [],
    'Meat/Fish': [],
    'Soups, Sauces, and Gravies': [],
    Refrigerated: [],
    Other: [],
  };

  static categoriesDefinitions: Array<{ key: string; name: string; examples?: string }> = [
    { key: 'produce', name: 'Produce', examples: 'Fruits, vegetables, and sometimes herbs, etc.' },
    { key: 'dairy', name: 'Dairy', examples: 'Milk, cheese, yogurt, and butter, etc.' },
    {
      key: 'meat',
      name: 'Meat, Poultry & Fish',
      examples: 'Beef, chicken, pork, fish and other meats, etc.',
    },
    { key: 'deli', name: 'Deli', examples: 'Sliced meats, cheeses, and prepared foods, etc.' },
    { key: 'bakery', name: 'Bakery', examples: 'Bread, pastries, and cakes, etc.' },
    { key: 'canned', name: 'Canned Goods', examples: 'vegetables, and beans, etc.' },
    { key: 'soups', name: 'Soups, Sauces, and Gravies', examples: 'Canned soups and broths, etc.' },
    {
      key: 'frozen',
      name: 'Frozen Foods',
      examples: 'Ice cream, frozen vegetables, frozen entrees, etc.',
    },
    { key: 'beverages', name: 'Beverages', examples: 'Soda, juice, tea, and coffee, etc.' },
    { key: 'snacks', name: 'Snacks', examples: 'Chips, pretzels, and popcorn, etc.' },
    {
      key: 'breakfast',
      name: 'Breakfast Foods',
      examples: 'Cereal, oatmeal, and breakfast bars, etc.',
    },
    {
      key: 'condiments',
      name: 'Condiments & Sauces',
      examples: 'Ketchup, mustard, mayonnaise, oils and salad dressings, etc.',
    },
    {
      key: 'pasta',
      name: 'Pasta & Rice',
      examples: 'Various types of pasta, rice, and noodles, etc.',
    },
    {
      key: 'baking',
      name: 'Baking Supplies',
      examples: 'Flour, sugar, baking powder, and mixes, etc.',
    },
    {
      key: 'seasonings',
      name: 'Spices & Seasonings',
      examples: 'Spices, herbs, salts, and blends, etc.',
    },
    { key: 'sugars', name: 'Sugars & Sweeteners', examples: 'Sugar, honey, and syrups, etc.' },
    { key: 'dry_goods', name: 'Dry Goods' },
    { key: otherKey, name: 'Other' },
  ];

  static topLevelCategories() {
    return GroceryCategories.categoriesDefinitions.map((c) => c.name);

    // return Object.keys(GroceryCategories.groceryCategoryMapping);
  }

  static mapOldCategoryToNew(oldCategory: string): string {
    const other = 'Other';

    if (oldCategory === null) {
      return other;
    }

    // old category is the keys in the groceryCategoryMapping
    // new category is the values returned from the topLevelCategories

    // if oldCategory is in topLevelCategories, return it
    if (GroceryCategories.topLevelCategories().includes(oldCategory)) {
      return oldCategory;
    }

    const map = {
      Frozen: GroceryCategories.findNameByKey('Frozen Foods'),
      'Meat/Fish': GroceryCategories.findNameByKey('meat'),
      Refrigerated: GroceryCategories.findNameByKey('Frozen Foods'),
    };

    if (map[oldCategory]) {
      return map[oldCategory];
    }

    return other;
  }

  static findNameByKey(meatKey: string) {
    return GroceryCategories.categoriesDefinitions.find((c) => c.key === meatKey)?.name || otherKey;
  }

  // find key by name
  static findKeyByName(name: string) {
    return GroceryCategories.categoriesDefinitions.find((c) => c.name === name)?.key || otherKey;
  }
}
