<main role="main" class="container my-5">

    <div [class.show-recipes]="showRecipes">

        <div class="row align-items-center">
            <div class="col-7">
                <h4>Nutrition Facts</h4>
            </div>
            <div class="col-5 text-right">
                <button class="btn btn-link" type="button" (click)="showRecipes = !showRecipes">
                    <i class="fa" [class.fa-square-o]="!showRecipes" [class.fa-check-square]="showRecipes"></i>
                    Show Recipes
                </button>

                <a [routerLink]="plannerPath" btnSecondary btnSmall>Back to Planner</a>

            </div>
        </div>

        <hr>
        <br>


        <mat-tab-group dynamicHeight>

            <mat-tab *ngFor="let weekFacts of nutritionFactsUSDA" label="{{weekFacts.title | humanWeek:true}}">

                <div >
                    <br>
                    <h4>{{weekFacts.title | humanWeek:true}}</h4>
                    <div style="border: 1px solid #ccc; padding: 15px; margin-bottom: 20px;">


                        <app-usda-nutrition-facts-row
                            [title]="'Weekly Total'"
                            [usdaFacts]="weekFacts"
                            [boldTitle]="true"
                            [showHeader]="true"
                            [showDailyPercentage]="false"
                            [nutritionFacts]="weekFacts.nutrients">
                        </app-usda-nutrition-facts-row>

                    </div>

                    <div *ngFor="let dayFacts of weekFacts.children" class="nf-day"
                         style="border: 1px solid #ccc; padding: 15px; margin-bottom: 20px;">


                        <app-usda-nutrition-facts-row
                            [title]="dayFacts.title | dayDisplay:weekFacts.title"
                            [usdaFacts]="dayFacts"
                            [showHeader]="true"
                            [boldTitle]="true"
                            [nutritionFacts]="dayFacts.nutrients">
                        </app-usda-nutrition-facts-row>

                        <hr>

                        <div *ngFor="let mealFacts of dayFacts.children" style="" class="nf-meal">

                            <app-usda-nutrition-facts-row
                                [title]="mealFacts.title"
                                [usdaFacts]="mealFacts"
                                [boldTitle]="showRecipes"
                                [boldValues]="showRecipes"
                                [nutritionFacts]="mealFacts.nutrients">
                            </app-usda-nutrition-facts-row>

                            <div *ngFor="let recipeFacts of mealFacts.children" class="nf-recipe">
                                <app-usda-nutrition-facts-row
                                    [title]="'&nbsp;&nbsp;&nbsp;' + recipeFacts.title"
                                    [recipe]="recipeFacts.recipe"
                                    [usdaFacts]="recipeFacts"
                                    [nutritionFacts]="recipeFacts.nutrients">
                                </app-usda-nutrition-facts-row>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-tab>

        </mat-tab-group>

    </div>


</main>
