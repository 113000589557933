import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule, Routes } from '@angular/router';
import { NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
// import { DragulaModule, DragulaService } from 'ng2-dragula';
import { ActiveDistributorGuard } from '../../distributor/guard/active-distributor.guard';
import { NutritionFactsLabelModule } from '../../nutrition-facts-label/nutrition-facts-label.module';
import { PipesModule } from '../../pipe/pipes.module';
import { RecipeViewerModule } from '../recipe-view/recipe-view.module';
import { RecipeContributorInputComponent } from './inputs/recipe-contributor-input/recipe-contributor-input.component';
import { RecipeDirectionsInputComponent } from './inputs/recipe-directions-input/recipe-directions-input';
import { RecipeEditorFormComponent } from './inputs/recipe-editor-form/recipe-editor-form';
import { RecipeElementInputComponent } from './inputs/recipe-element-input/recipe-element-input';
import { RecipeElementsInputComponent } from './inputs/recipe-elements-input/recipe-elements-input';
import { RecipeEquipmentInputComponent } from './inputs/recipe-equipment-input/recipe-equipment-input';
import { RecipeIngredientInputComponent } from './inputs/recipe-ingredient-input/recipe-ingredient-input';
import { RecipeIngredientsInputComponent } from './inputs/recipe-ingredients-input/recipe-ingredients-input';
import { RecipeMealPlansInputComponent } from './inputs/recipe-meal-plans-input/recipe-meal-plans-input.component';
import { RecipeMealTypeInputComponent } from './inputs/recipe-meal-type-input/recipe-meal-type-input';
import { RecipeNutritionFactsInputComponent } from './inputs/recipe-nutrition-facts-input/recipe-nutrition-facts-input.component';
import { RecipeSourceInputComponent } from './inputs/recipe-source-input/recipe-source-input';
import { RecipeStepInputComponent } from './inputs/recipe-step-input/recipe-step-input';
import { RecipeStepsInputComponent } from './inputs/recipe-steps-input/recipe-steps-input';
import { RecipeEditorComponent } from './recipe-editor.component';
import { RecipeEditorService } from './recipe-editor.service';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatButtonModule } from '@angular/material/button';
import { SwitchToSimpleEntryDialog } from './inputs/recipe-elements-input/switch-to-simple-entry-dialog/switch-to-simple-entry-dialog';
import { MatDialogModule } from '@angular/material/dialog';
import { ConvertToSimpleEntryService } from './inputs/recipe-elements-input/convert-to-simple-entry.service';
import { MatTabsModule } from '@angular/material/tabs';
import { IngredientSimpleInputComponent } from './inputs/ingredient-simple-input/ingredient-simple-input.component';
import { LoadingModule } from '../../loading/loading.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  ButtonDirective,
  DangerButtonDirective,
  SecondaryButtonDirective,
} from '../../../component-directives/button.directive';

const routes: Routes = [
  { path: 'recipe/edit', component: RecipeEditorComponent, pathMatch: 'full' },
];

@NgModule({
  declarations: [
    RecipeEditorComponent,
    RecipeDirectionsInputComponent,
    RecipeEditorFormComponent,
    RecipeElementInputComponent,
    RecipeElementsInputComponent,
    RecipeEquipmentInputComponent,
    RecipeIngredientInputComponent,
    RecipeIngredientsInputComponent,
    RecipeSourceInputComponent,
    RecipeStepInputComponent,
    RecipeStepsInputComponent,
    RecipeMealTypeInputComponent,
    RecipeNutritionFactsInputComponent,
    RecipeMealPlansInputComponent,
    RecipeContributorInputComponent,
    SwitchToSimpleEntryDialog,
    IngredientSimpleInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    // DragulaModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    NgbTooltipModule,
    NutritionFactsLabelModule,
    PipesModule,
    FroalaEditorModule,
    FroalaViewModule,
    MatChipsModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    RecipeViewerModule,
    DragDropModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    LoadingModule,
    MatTooltipModule,
    ButtonDirective,
    DangerButtonDirective,
    SecondaryButtonDirective,
  ],
  exports: [RecipeEditorComponent, RecipeEditorFormComponent],
  providers: [RecipeEditorService, ActiveDistributorGuard, ConvertToSimpleEntryService],
})
export class RecipeEditorComponentModule {
  constructor() {}
}
