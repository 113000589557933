/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/notification/notification.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Type } from 'class-transformer';
import { BaseItem } from '../entities/base-item';
import { Default } from '../utils/default.decorator';
import { NotificationMetaData } from './notification-meta-data';
import { NotificationStatus } from './notification-status';
import { NotificationType } from './notification-type';

export interface NotificationConstructorParams {
  username: string;
  title?: string;
  message?: string;
  launchUrl?: string;
  type: NotificationType;
  sendAt?: number;
  sendEmail?: boolean;
  metaData?: {
    [key: string]: any;
  };
}

@Exclude()
export class AppNotification implements BaseItem {
  @Expose()
  id: string;

  @Expose()
  username: string;

  @Expose()
  type: NotificationType;

  @Expose()
  title?: string;

  @Expose()
  message?: string;

  @Expose()
  @Default(NotificationStatus.Pending)
  status: NotificationStatus = NotificationStatus.Pending;

  @Expose()
  launchUrl?: string;

  @Expose()
  queuedAt?: number;

  @Expose()
  @Default(() => Date.now())
  sendAt: number = Date.now();

  @Expose()
  sentAt?: number;

  @Expose()
  readAt?: number;

  @Expose()
  hiddenAt?: number;

  @Expose()
  createdAt: number;

  @Expose()
  modifiedAt: number;

  @Expose()
  @Type(() => NotificationMetaData)
  metaData: NotificationMetaData;

  @Expose()
  @Default(false)
  visible: boolean = false;

  @Expose()
  @Default(false)
  sendEmail: boolean;

  constructor(options?: NotificationConstructorParams) {
    if (options) {
      this.username = options.username;
      this.type = options.type;
      this.title = options.title;
      this.message = options.message;
      this.launchUrl = options.launchUrl;
      this.sendAt = options.sendAt || Date.now();
      this.metaData = options.metaData || {};
      this.sendEmail = options.sendEmail || false;
    }
  }

  hide() {
    this.hiddenAt = Date.now();
    this.status = NotificationStatus.Hidden;
    this.visible = false;
  }

  read() {
    this.status = NotificationStatus.Read;
    this.readAt = Date.now();
    this.visible = true;
  }

  unread() {
    this.status = NotificationStatus.Unread;
    this.visible = true;
  }

  get isRead() {
    return this.status === NotificationStatus.Read || this.status === NotificationStatus.Hidden;
  }
}
