<main role="main" class="container py-5">
    <div class="row">
        <div class="col-md-6 mx-auto bg-light py-4">
            <h3 class="">Planner Upgrade</h3>
            <hr>

            <div *ngIf="view === views.UPGRADE">

                <h5 *ngIf="upgradePreventedReason === reasons.HAS_CUSTOM_PLANNER" class="text-center py-5">
                    Sorry, you can't upgrade your meal planner because you are already on the best plan.
                </h5>

                <h5 *ngIf="upgradePreventedReason === reasons.IS_BUNDLED" class="text-center py-5 px-md-5">
                    Your meal planner is provided by your dietitian.
                    Please contact your dietitian to upgrade your planner.
                </h5>

                <div [class.d-none]="upgradePreventedReason !== null">

                    <p>Upgrade to the Custom Plan to get these additional features:</p>

                    <ul>
                        <li><strong>Search and view recipes not on your planner</strong></li>
                        <li><strong>Add & Remove Recipes</strong></li>
                        <li><strong>Save changes to the # of servings</strong></li>
                        <li><strong>Enable your healthcare professional to edit and make recommendations.</strong></li>
                    </ul>

                    <hr>

                    <app-credit-card-form></app-credit-card-form>

                    <hr>

                    <p class="text-center">
                        <strong>
                            Your new monthly payment will be $19/month USD
                            <span style="display: inline-block">starting on {{nextRenewal | epoch:'MMMM DD, YYYY'}}.</span>
                        </strong>

                        <br>
                        A prorated amount of ${{(proratedAmount / 100).toFixed(2)}} USD will added to your next renewal for the period of time between now and your
                        next renewal.
                        <br><br>
                        <button type="button" appButton (click)="upgrade()">Proceed with Upgrade</button>
                    </p>

                    <br>
                    <br>

                </div>

            </div>


            <div *ngIf="view == views.SUCCESS" class="text-center py-5 px-md-5">
                <h5>
                    <strong>Thank you!</strong><br>Your planner has been upgraded to the
                    <span style="display: inline-block">Custom Plan.</span>
                </h5>
            </div>


            <p class="text-center">
                <a routerLink="/planner" btnSecondary btnSmall>Back to My Planner</a>
            </p>

        </div>
    </div>
</main>
