import { plainToClass } from 'class-transformer';
import { FinalizeResourceFileUploadCommand } from '../../../lprx-shared-lib/resources/commands/finalize-resource-file-upload.command';
import { LprxApi } from '../api';
import { ResourceFiles } from './resource-files';
import { ResourceFolders } from './resource-folders';

export class Resources {
  public readonly folders: ResourceFolders = new ResourceFolders(this.lprxApi);
  public readonly files: ResourceFiles = new ResourceFiles(this.lprxApi);

  constructor(private readonly lprxApi: LprxApi) {}

  /**
   *
   * @param key
   * @param filename
   * @param folderId
   */
  finalizeUpload(key: string, filename: string, folderId: string) {
    const data = { key, filename };
    const payload = plainToClass(FinalizeResourceFileUploadCommand, data);
    return this.lprxApi.post(`v3/resources/folder/${folderId}/file`, payload);
  }
}
