import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';

@Injectable()
export class PrinterService {
  private recipes = new BehaviorSubject<Recipe>(new Recipe());
  recipe = this.recipes.asObservable();

  printRecipe(recipe: Recipe) {
    console.log('Setting Recipe');
    console.log(recipe);
    this.recipes.next(recipe);
  }
}
