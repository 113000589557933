import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
// tslint:disable-next-line:no-implicit-dependencies
import { ISignUpResult } from 'amazon-cognito-identity-js';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, from, interval, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ChangeLoginRequest } from '../lprx-shared-lib/change-login-request';
import { ClientSignUpDto } from '../lprx-shared-lib/dto/client-sign-up-dto';
import { ClientSignUpResponse } from '../lprx-shared-lib/dto/client-sign-up-response';
import {
  PractitionerPermissions,
  PractitionerUser,
  User,
} from '../lprx-shared-lib/entities/user/user';
import { UserType } from '../lprx-shared-lib/entities/user/UserType';
import { sanitizeEmail } from '../lprx-shared-lib/sanitize-email';
import { createId } from '../lprx-shared-lib/utils/id-generator';
import { IdPrefix } from '../lprx-shared-lib/utils/id-prefix';
import { Api, Auth } from '../providers/aws.api';
import { DynamoDB } from '../providers/aws.dynamodb';
import { clearLastAuthUserName } from '../providers/getLastAuthUsername';
import { LprxApiProvider } from '../providers/lprx-api/api-provider';
import { DistributorsService } from './service/distributors.service';
import { ANONYMOUS_USER } from '../lprx-shared-lib/entities/system-users';
import { getClaimFromAccessToken, getTokenResponse, isLoggedIn } from '../lprx-auth';
import { asClass } from '../lprx-shared-lib/as-class';

declare const zE: any;

declare const window: any;

// Overriding Amplify Auth.currentAuthenticatedUser
Auth.currentAuthenticatedUser = () => {
  return new Promise((resolve, reject) => {
    const user = localStorage.getItem('userDetails');
    console.log('currentAuthenticatedUser Current User: ', user);
    if (user) {
      resolve(user);
    } else {
      reject('No user');
    }
  });
};

@Injectable()
export class AuthService implements OnDestroy {
  constructor(
    public db: DynamoDB,
    private zone: NgZone,
    private api: Api,
    private lprxApi: LprxApiProvider,
    private distributorsService: DistributorsService,
    private router: Router
  ) {
    this.refreshUserSub = interval(4000)
      .pipe(
        switchMap(() => this.refreshUser()),
        take(1)
      )
      .subscribe((user) => {
        this.userSubject.next(user);
      });

    this.checkIfSignedIn();
    this.zone.runOutsideAngular(() => {
      setInterval(() => this.zone.run(() => this.checkIfSignedIn()), 30000);
    });
  }
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  // isLoggedIn = false;
  // store the URL so we can redirect after logging in

  private userSubject = new BehaviorSubject<User>(null);
  private user: User;
  private cognitoUser: any;
  private refreshUserSub: Subscription;

  public readonly user$: Observable<User> = this.userSubject.asObservable().pipe(
    switchMap((user) => (this.user ? of(this.user) : this.getLoggedInUser())),
    map((user) => plainToClass(User, user)),
    tap((user) => this.updateZendDeskWebWidget(user))
  );

  public readonly clientUser$: Observable<User> = this.userSubject.asObservable().pipe(
    filter((user: User) => user && user.userType === UserType.Client),
    distinctUntilChanged((p: User, q: User) => p.username === q.username),
    map((user) => plainToClass(User, user as object))
  );

  /**
   *
   * @type {Observable<PractitionerUser>}
   */
  public readonly practitionerUser$: Observable<PractitionerUser> = this.userSubject
    .asObservable()
    .pipe(
      tap((user: User) => {
        if (user?.userType === UserType.Distributor) {
          return true;
        }

        throw new Error('User is not a Practitioner');
      }),
      distinctUntilChanged((p: User, q: User) => p.username === q.username),
      map((user) => plainToClass(PractitionerUser, user as object))
    );

  private lastZenUser: User = null;

  private lastUserCheck: number = 0;
  private getLoggedInUserPromise: Promise<User>;

  /**
   * Checks if the user is signed in and emits the result.
   *
   * @returns {Promise<boolean>}
   */
  private async checkIfSignedIn(): Promise<boolean> {
    try {
      const res = await Auth.currentAuthenticatedUser();
      console.log('checkIfSignedIn() ::: ' + (await isLoggedIn()));
      console.log(res);
      this.isLoggedInSubject.next(true);
      return true;
    } catch (e) {
      console.log(e);
      this.isLoggedInSubject.next(false);
      return false;
    }
  }

  ngOnDestroy() {
    this.refreshUserSub.unsubscribe();
  }

  init() {
    //
  }

  async _isLoggedIn() {
    let is = await isLoggedIn();
    this.isLoggedInSubject.next(is);
    return is;
    // try {
    //   const res = await Auth.currentAuthenticatedUser();
    //   console.log('_isLoggedIn() ::: ' + (await isLoggedIn()));
    //   console.log(res);
    //   this.isLoggedInSubject.next(true);
    //   return true;
    // } catch (e) {
    //   console.log(e);
    //   this.isLoggedInSubject.next(false);
    //   return false;
    // }
  }

  getIsSignedIn() {
    return this.isLoggedInSubject.asObservable();
  }

  setUser(user: User) {
    if (!user) {
      this.updateZendDeskWebWidget(null);
      return;
    }
    console.log('Setting user');
    this.user = User.fromObject(user);
    localStorage.setItem('userDetails', JSON.stringify(user));
    this.userSubject.next(user);
  }

  /**
   *
   * @param user
   */
  updateZendDeskWebWidget(user: User) {
    if (!user || user.userType !== UserType.Distributor) {
      this.lastZenUser = user;

      window.Intercom('shutdown');

      return;
    }

    if (this.lastZenUser?.username === user.username) {
      return;
    }

    console.log(this.lastZenUser?.username, user?.username);

    this.lastZenUser = user;

    if (user) {
      let customIntercomHashKey = 'custom:intercom_hash';

      // get intercom has from access token claim custom:intercom_hash that is stored in
      // local storage LPRX_TOKEN_RESPONSE.access_token
      const intercom_hash = getClaimFromAccessToken(customIntercomHashKey);

      window.Intercom('update', {
        name: user.firstName + ' ' + user.lastName,
        email: user.email,
        user_id: user.username, // cognito username
        signed_up_at: Math.floor(user.createdAt / 1000),
        user_hash: intercom_hash, // an Identity Verification user hash for your user
      });
    }
  }

  _getUser(): User {
    try {
      console.log('Getting Current User from Local Storage');
      let item = localStorage.getItem('userDetails');
      if (item) {
        const obj1 = JSON.parse(item);
        return obj1 ? User.fromObject(obj1) : obj1;
      }
    } catch (e) {}

    return ANONYMOUS_USER;
  }

  getUser(): Observable<User> {
    return this.userSubject.asObservable().pipe(
      filter((user) => !!user),
      map((user) => User.fromObject(user))
    );
  }

  // async signIn(email: string, password: string) {
  //   email = sanitizeEmail(email);
  //   await this.signOut();
  //   try {
  //     this.cognitoUser = await Auth.signIn(email, password);
  //     if (this.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
  //       await this.cognitoUser.completeNewPasswordChallenge(password);
  //       await Auth.signOut();
  //       this.cognitoUser = await Auth.signIn(email, password);
  //     }
  //     localStorage.setItem('userDetails', JSON.stringify({ username: this.cognitoUser.username }));
  //     localStorage.setItem('lastAuthUsername', this.cognitoUser.username);
  //     sessionStorage.setItem('time-to-upgrade-shown', 'false');
  //
  //     const clockDrift = this.cognitoUser.signInUserSession.clockDrift;
  //     console.log(`Clock drift: ${clockDrift}`);
  //     DateUtils.clockOffset = clockDrift * 1000 * -1; /// this is the one!!!! others below, just in case!
  //     DateUtils.setClockOffset(clockDrift * 1000 * -1);
  //     DateUtilsLib.setClockOffset(clockDrift * 1000 * -1);
  //     DateUtilsLib.clockOffset = clockDrift * 1000 * -1;
  //     await this.getLoggedInUser().toPromise();
  //
  //     return this.cognitoUser;
  //   } catch (e) {
  //     console.log(e);
  //
  //     if (e.code === 'UserNotFoundException') {
  //       await this.checkForPendingProfileSetup(email);
  //     }
  //     throw new Error('Incorrect email address or password.');
  //   }
  // }

  private async checkForPendingProfileSetup(email: string) {
    // check pending profile
    const result = await this.lprxApi.distributors.hasPendingProfile(email);
    if (result.code === 'pending-setup') {
      await this.router.navigateByUrl('/pending-setup?email=' + email);
      return true;
    }

    return false;
  }

  async signUp(email: string, password: string): Promise<ISignUpResult> {
    await this.signOut();
    return Auth.signUp(createId(IdPrefix.User), password, email.toLowerCase(), '');
  }

  async signOut() {
    this.updateZendDeskWebWidget(null);
    this.clearUser();
    await Auth.signOut();
  }

  // /**
  //  *
  //  * @param {string} email
  //  * @param {string} password
  //  * @param {string} firstName
  //  * @param {string} lastName
  //  * @param {string} distributorId
  //  * @param {string} mealPlanId
  //  * @param {string} promoCode
  //  * @returns {Promise<any>}
  //  */
  // async freeTrialSignUp(
  //   email: string,
  //   password: string,
  //   firstName: string,
  //   lastName: string,
  //   distributorId: string,
  //   mealPlanId: string,
  //   promoCode: string = null,
  // ) {
  //   const cognitoUser = await this.signUp(email, password);
  //
  //   const details = {
  //     username: cognitoUser.user.getUsername(),
  //     email,
  //     firstName,
  //     lastName,
  //     distributorId,
  //     mealPlanId,
  //     plannerType: 'static',
  //     promoCode,
  //   };
  //
  //   const res = await this.api.post('user/free-trial', details);
  //   await this.signIn(email, password);
  //   if (res) {
  //     return cognitoUser;
  //   }
  // }

  async changeLoginEmailAddress(email: string): Promise<ChangeLoginRequest> {
    return this.lprxApi.post<ChangeLoginRequest>('v3/me/change-login-request', { email });
  }

  checkPendingRequest() {
    return this.lprxApi.get<ChangeLoginRequest>('v3/me/change-login-request');
  }

  changeLoginEmailAddressVerify(key: string) {
    return this.lprxApi.get<ChangeLoginRequest>('v3/me/change-login-request-verify/' + key);
  }

  /**
   * Initiate a password reset for a user by their email address
   *
   * @param {string} email
   * @returns {Promise<boolean>}
   */
  async forgotPassword(email: string): Promise<boolean> {
    if (email && !(await this.checkForPendingProfileSetup(email))) {
      await Auth.forgotPassword(sanitizeEmail(email));
      return true;
    }
    return false;
  }

  /**
   * Get a username from an email address
   *
   * @param {string} email
   * @returns {Promise<void>}
   */
  async getUsername(email: string): Promise<string> {
    return this.api.post<string>('username/email', { email });
  }

  /**
   * @param {string} email
   * @param {string} code
   * @param {string} password
   * @returns {Promise<void>}
   */
  async forgotPasswordSubmit(email: string, code: string, password: string) {
    const username = await this.getUsername(email);
    return Auth.forgotPasswordSubmit(username, code, password);
  }

  /**
   * @returns {Observable<User | User>}
   */
  getLoggedInUser(): Observable<User> {
    if (this.user && Date.now() - this.lastUserCheck < 2000) {
      console.log('Current User: Cached:', this.user.username);
      return of(this.user);
    }
    if (this.getLoggedInUserPromise) {
      console.log('Current User: Waiting on Promise');
    } else {
      console.log('Current User: Determining');
      this.getLoggedInUserPromise = this.fetchUser();
    }

    return from(this.getLoggedInUserPromise).pipe(
      catchError((e) => {
        this.getLoggedInUserPromise = null;
        throw e;
      }),
      tap(() => {
        this.distributorsService.getCurrent().then(() => {});
      }),
      map((user: User) => {
        this.lastUserCheck = Date.now();
        this.getLoggedInUserPromise = null;
        if (user) {
          this.setUser(user);
          console.log('Current User: ', user);
          return User.fromObject(user);
        }
      })
    );
  }

  async fetchUser(): Promise<User> {
    let tokenResponse = getTokenResponse();

    if (!tokenResponse?.access_token) {
      console.log('Current User: No Token');
      localStorage.removeItem('userDetails');
      return null;
    }

    console.log('Current User: Fetching Here');
    const user = await this.lprxApi.get<User>(`v3/utility/user/get`);
    console.log('Current User: Fetched:', user);
    return plainToClass(User, user);
  }

  // ?is this needed?
  refreshUser(): Observable<User> {
    return this.getLoggedInUser().pipe(
      map((user) => {
        this.setUser(user);
        return this.user;
      })
    );
  }

  clearUser() {
    clearLastAuthUserName();
    this.user = null;
    this.userSubject.next(null);
  }

  clientSignUp(dto: ClientSignUpDto): Observable<ClientSignUpResponse> {
    return from(this.lprxApi.users.clientSignUp(dto));
  }

  getDistributor$() {
    return this.distributorsService.currentDistributor$;
  }

  /**
   *
   * @returns {Observable<boolean>}
   */
  practitionerUserIsAdmin$(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => {
        const practitionerUser = asClass(PractitionerUser, user);
        return (
          practitionerUser.userType === UserType.Distributor &&
          practitionerUser.hasPermission(PractitionerPermissions.admin)
        );
      })
    );
  }
}
