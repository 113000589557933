<div *ngIf="weeklyPlan">
  <div class="row no-gutters weekly-options" style="background: rgba(132, 160, 60, 0.17)">
    <div class="col" *ngIf="weeklyPlan.mealPlan.nonCalendar">
      <strong>&nbsp;{{ nonCalendarDisplay(weeklyPlan.weekNumber) }}</strong>
    </div>
    <div
      class="col"
      style="text-align: right"
      *ngIf="user?.userType === 'admin' || user?.userType === 'distributor'"
    >
      <ng-container *ngIf="canSwapUp || canSwapDown">
        <ng-container *ngIf="canSwapUp"><a (click)="swapUp()">Move Up</a> | </ng-container>
        <ng-container *ngIf="canSwapDown"
          >&nbsp;<a *ngIf="canSwapDown" (click)="swapDown()">Move Down</a> | </ng-container
        >
      </ng-container>

      <a (click)="copy()">Copy</a>
      <span *ngIf="canPaste"> | <a (click)="paste()">Paste</a></span>
      | <a (click)="reset()">Reset</a>
    </div>
  </div>
  <!--  <div-->
  <!--    *ngIf="user?.userType === 'admin' || user?.userType === 'distributor'"-->
  <!--    class="weekly-options"-->
  <!--  >-->
  <!--    <a (click)="copy()">Copy</a>-->
  <!--    <span *ngIf="canPaste"> | <a (click)="paste()">Paste</a></span>-->
  <!--    | <a (click)="reset()">Reset</a>-->
  <!--  </div>-->
  <div class="days row no-gutters flex-nowrap">
    <div
      class="col"
      *ngFor="let dayName of days"
      [class.isToday]="dayName == today && weeklyPlan.weekNumber == currentWeekNumber"
      [class.has-no-cards]='!dayHasCards(weeklyPlan.getDay(dayName))'
    >
      <app-planner-day
        [dayName]="dayName"
        [day]="weeklyPlan[dayName | dayAsLong: 'lower']"
        [mealPlan]="weeklyPlan.mealPlan"
        [weekNumber]="weeklyPlan.weekNumber"
      >
      </app-planner-day>
    </div>
  </div>
</div>
