import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, map, switchMap, take } from 'rxjs/operators';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { Storage } from '../../providers/aws.api';
import { Brand } from '../model/entities/brand';
import { CdnService } from '../service/cdn.service';
import { HeaderService } from '../service/header.service';
import { errorMessage } from '../utilities/alert-http-error';
import { of } from 'rxjs/observable/of';
import { DistributorsService } from '../service/distributors.service';
import { MealPlannerSetupService } from './meal-planner-setup.service';
import { MealPlannerSetupCommand } from '../../lprx-shared-lib/dto/meal-planner-setup-command';

interface ProfileSetupStep {
  id: string;
  name: string;
  isComplete: boolean;
}

@Component({
  selector: 'app-meal-planner-setup',
  templateUrl: './meal-planner-setup.component.html',
  styleUrls: ['./meal-planner-setup.component.scss'],
})
export class MealPlannerSetupComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('profileCanvas') public profileCanvas: ElementRef;

  distributor: Distributor;
  showSetup = false;
  password = '';
  passwordConfirm: string;
  isPasswordValid = false;
  isPasswordLongEnough = false;
  doesPasswordHaveNumber = false;
  doesPasswordHaveLowercase = false;
  doesPasswordHaveUppercase = false;

  steps: ProfileSetupStep[] = [
    // {
    //   id: 'welcome',
    //   name: 'Welcome',
    //   isComplete: true,
    // },
    // {
    //   id: 'agreement',
    //   name: 'Agreement',
    //   isComplete: false,
    // },
    {
      id: 'profile',
      name: 'Business Profile',
      isComplete: false,
    },
    {
      id: 'about',
      name: 'About You',
      isComplete: false,
    },
    {
      id: 'logo',
      name: 'Logo',
      isComplete: false,
    },
    {
      id: 'photo',
      name: 'Profile Image',
      isComplete: false,
    },
    {
      id: 'vanity',
      name: 'Landing Page',
      isComplete: false,
    },
    // {
    //   id: 'login',
    //   name: 'Login',
    //   isComplete: false,
    // },
    {
      id: 'review',
      name: 'Review',
      isComplete: false,
    },
  ];

  profileForm = this.fb.group({
    welcome: this.fb.group({}),
    // agreement: this.fb.group({
    //   termsAgreement: this.fb.control('', [Validators.required, Validators.requiredTrue]),
    // }),
    profile: this.fb.group({
      firstName: this.fb.control('', [Validators.required]),
      lastName: this.fb.control('', [Validators.required]),
      credentials: this.fb.control('', [Validators.required]),
      business: this.fb.control('', [Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email]),
      website: this.fb.control('', [Validators.required]),
      country: this.fb.control('', [Validators.required]),
      postalCode: this.fb.control('', [Validators.required]),
    }),
    about: this.fb.group({
      bio: this.fb.control('', [Validators.required]),
    }),
    logo: this.fb.group({
      logo: this.fb.control('', [Validators.required]),
    }),
    photo: this.fb.group({
      photo: this.fb.control('', [Validators.required]),
    }),
    vanity: this.fb.group({
      // add async validator
      vanityId: this.fb.control(
        '',
        [Validators.required],
        [
          (control: AbstractControl) =>
            control.value
              ? control.valueChanges.pipe(
                  debounceTime(500),
                  take(1),
                  switchMap(() =>
                    this.mealPlannerSetupService.getDistributorByVanityId(control.value),
                  ),
                  map((res) => {
                    const isValid = res ? res.id === this.distributor.id : true;
                    this.isValidVanityId = isValid;
                    return isValid ? null : { inUse: { value: control.value } };
                  }),
                )
              : of(null),
        ],
      ),
    }),
    // login: this.fb.group(
    //   {
    //     // add async validator
    //     email: this.fb.control(
    //       '',
    //       [Validators.required, Validators.email],
    //       [
    //         (control: AbstractControl) =>
    //           control.value
    //             ? control.valueChanges.pipe(
    //                 debounceTime(500),
    //                 take(1),
    //                 switchMap((_) => this.joinSetupService.checkEmail(control.value)),
    //                 map((userType) => (userType ? { existingEmail: { value: userType } } : null))
    //               )
    //             : of(null),
    //       ]
    //     ),
    //     password: this.fb.control('', [
    //       Validators.required,
    //       (control: AbstractControl) => {
    //         const p = control.value;
    //         this.isPasswordLongEnough = p.length >= 6;
    //         this.doesPasswordHaveLowercase = p.match(/[a-z]/) !== null;
    //         this.doesPasswordHaveUppercase = p.match(/[A-Z]/) !== null;
    //         this.doesPasswordHaveNumber = p.match(/[0-9]/) !== null;
    //
    //         this.isPasswordValid =
    //           this.isPasswordLongEnough &&
    //           this.doesPasswordHaveUppercase &&
    //           this.doesPasswordHaveLowercase &&
    //           this.doesPasswordHaveNumber;
    //
    //         return this.isPasswordValid ? null : { invalidPassword: { value: control.value } };
    //       },
    //     ]),
    //     passwordConfirm: this.fb.control('', [Validators.required]),
    //   },
    //   {
    //     validators: (control: UntypedFormGroup) =>
    //       control.get('password').value !== control.get('passwordConfirm').value
    //         ? { passwordNotConfirmed: true }
    //         : null,
    //   }
    // ),
    review: this.fb.group({}),
  });

  bioNumberWords = 0;
  // canvas: any;

  isImageCropped = false;
  error: string;
  isSetupComplete = false;
  isValidVanityId: boolean;
  isProcessing = false;
  logoUrl: string;
  private photoUrl: string;
  private isPhotoChanged = false;
  private croppedImageCanvas: any;
  private distributorId: string;
  private applicationId: string;
  currentStep: ProfileSetupStep;

  /**
   * For profile photo
   */
  croppedImageUrl: SafeUrl;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    private headerService: HeaderService,
    private fb: UntypedFormBuilder,
    private cdn: CdnService,
    private mealPlannerSetupService: MealPlannerSetupService,
    private distributorService: DistributorsService,
  ) {}

  ngOnInit() {
    // this.route.params.subscribe((params) => {
    //   this.distributorId = params['distributorId'];
    //   this.applicationId = params['applicationId'];
    //   this._init();
    // });
    this.distributorService.currentDistributor$.subscribe((distributor) => {
      this.distributor = distributor;
      this._init();
    });
  }

  async delay(ms: number): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(resolve, ms);
    });
  }

  prevStep() {
    const prevtIndex = this.steps.indexOf(this.currentStep) - 1;
    if (this.steps[prevtIndex]) {
      this.setStep(this.steps[prevtIndex]);
    }
  }

  async nextStep() {
    if (this.isProcessing) {
      return;
    }

    this.currentStep.isComplete = true;
    const nextIndex = this.steps.indexOf(this.currentStep) + 1;

    if (this.steps[nextIndex]) {
      this.setStep(this.steps[nextIndex]);
    }
  }

  toStep(step) {
    const toStepIndex = this.steps.indexOf(step);

    let allPreviousComplete = true;
    for (let i = toStepIndex - 1; i >= 0; i--) {
      if (!this.steps[i].isComplete) {
        allPreviousComplete = false;
      }
    }

    if (allPreviousComplete) {
      this.setStep(step);
    }
  }

  setStep(step: ProfileSetupStep) {
    this.currentStep = this.steps[this.steps.indexOf(step)];

    if (step.id === 'login') {
      this.profileForm.get('login').get('email').updateValueAndValidity();
    }

    switch (this.currentStep.id) {
      case 'headshot': {
        this.insertCroppedProfileCanvas();
        break;
      }
    }
  }

  uploadFromFile(event, field) {
    if (['logo', 'photo'].indexOf(field) === -1) {
      return;
    }

    const files = event.target.files;
    const file = files[0];
    const { type, name } = file;
    const ext = name.split('.').reverse()[0];
    const key =
      'images/distributor/' +
      this.distributor.id +
      '/' +
      field +
      '/' +
      Date.now().toString() +
      '.' +
      ext;

    Storage.put(key, file, { contentType: type, level: 'public' })
      .then((result: { key: string }) => {
        this.distributor[field] = result.key;

        this.profileForm.get(field).get(field).setValue(result.key);

        this.refreshImages();

        if (field === 'photo') {
          this.isImageCropped = false;
          this.isPhotoChanged = true;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {}

  // onChange({ error }) {
  //   this.error = error ? error.message : null;
  //   this.cd.detectChanges();
  // }

  changedProfileImage(event) {
    const files = event.target.files;
    const file = files[0];
    const { type, name } = file;
    const ext = name.split('.').reverse()[0];
    const key =
      'images/distributor/' + this.distributor.id + '/profile/' + Date.now().toString() + '.' + ext;

    Storage.put(key, file, { contentType: type, level: 'public' })
      .then((result: { key: string }) => {
        const img = new Image();
        img.onload = (ev) => {
          // @ts-ignore TS2339: Property 'width' does not exist on type 'HTMLElement'
          if (ev.currentTarget.width === ev.currentTarget.height) {
            const r = result.key;
            this.setCroppedImage(r);
            URL.revokeObjectURL(img.src);
          } else {
            this.mealPlannerSetupService
              .setProfileImage(this.distributor.id, this.cdn.getUrl(result.key))
              .then((r: string) => {
                this.setCroppedImage(r);
                URL.revokeObjectURL(img.src);
              });
          }
        };
        img.src = window.URL.createObjectURL(file);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  private setCroppedImage(r) {
    this.croppedImageUrl = this.cdn.getSafeUrl(r);
    this.distributor.photo = r;
    this.profileForm.get('photo').get('photo').setValue(r);
    this.isImageCropped = true;
  }

  validateVanityId() {
    this.profileForm
      .get('vanity')
      .get('vanityId')
      .setValue(
        this.profileForm
          .get('vanity')
          .get('vanityId')
          .value.toLowerCase()
          .replace(/[^a-z0-9-]/g, '-')
          .replace(/^[-]/g, '')
          .replace(/[-]$/g, '')
          .replace(/-{2,}/g, '-'),
      );
  }

  bioWordCount() {
    this.bioNumberWords = this.distributor.bio
      ? this.distributor.bio.replace(/ {2,}/, ' ').split(' ').length
      : 0;
  }

  private insertCroppedProfileCanvas() {
    setTimeout(() => {
      if (this.croppedImageCanvas) {
        this.croppedImageCanvas.setAttribute('style', 'max-width: 100%;width: 400px;');
        this.profileCanvas.nativeElement.innerHTML = '';
        this.profileCanvas.nativeElement.appendChild(this.croppedImageCanvas);
      }
    }, 300);
  }

  private async _init() {
    // await Auth.signOut();
    // clearLastAuthUserName();
    await this.mealPlannerSetupService.cleanDistributorAccount(this.distributorId);

    this.currentStep = this.steps[0];

    try {
      // const distResponse = await this.joinSetupService.getDistributorOnboarding(
      //   this.distributorId,
      //   this.applicationId
      // );

      // const distributor = plainToClass(Distributor, distResponse as {});
      // this.distributor = distributor;

      this.profileForm.patchValue({
        profile: {
          firstName: this.distributor.firstName,
          lastName: this.distributor.lastName,
          credentials: this.distributor.credentials,
          business: this.distributor.business,
          email: this.distributor.email,
          website: this.distributor.website,
          country: this.distributor.country,
          postalCode: this.distributor.postalCode,
        },
        about: {
          bio: this.distributor.bio || 'Coming soon',
        },
        logo: {
          logo: this.distributor.logo,
        },
        photo: {
          photo: this.distributor.photo,
        },
        vanity: {
          vanityId: this.distributor.vanityId || '',
        },
        // login: {
        //   email: distributor.email,
        //   password: '',
        //   passwordConfirm: '',
        // },
      });

      this.refreshImages();

      console.log(this.distributor.photo);

      if (!this.distributor.isSetupComplete) {
        this.showSetup = true;
      }

      this.bioWordCount();
    } catch (error) {
      console.log(error.message);
      this.error = errorMessage(error);
    }
  }

  // todo: a bit crazy below
  termsAgreement: boolean;

  private async refreshImages() {
    try {
      this.logoUrl = this.cdn.getUrl(this.distributor.logo);

      // todo: need a better way to tell if an image exists

      this.http.get(this.logoUrl).subscribe(
        (data) => {},
        (error) => {
          if (error.message.match(/parsing/)) {
            const brand = new Brand(
              this.distributor.business,
              this.distributor.logo,
              this.distributor.vanityId,
            );
            this.headerService.setBrand(brand);
          } else {
            this.logoUrl = null;
            console.log(error);
          }
        },
      );
    } catch (e) {
      console.log('storage error');
      console.log(e);
    }

    try {
      this.photoUrl = this.cdn.getUrl(this.distributor.photo);

      this.http.get(this.photoUrl).subscribe(
        (data) => {},
        (error) => {
          if (error.message.match(/parsing/)) {
            // not really an error here ... this.http.get can't parse the binary image
            this.croppedImageUrl = this.cdn.getSizedImageUrl(this.distributor.photo, 300, 0);
          } else {
            console.log('Missing Image');
            this.photoUrl = null;
            console.log(error);
          }
        },
      );
    } catch (e) {
      console.log('storage error');
      console.log(e);
    }
  }

  submit() {
    if (this.profileForm.invalid) {
      console.log('invalid form', this.profileForm);
      return;
    }

    if (this.isProcessing) {
      console.log('already processing');
      return;
    }

    const profileSetupCommand: MealPlannerSetupCommand = {
      distributorId: this.distributorId,
      // applicationId: this.applicationId,
      profile: {
        ...this.profileForm.get('profile').value,
        bio: this.profileForm.get('about').get('bio').value,
        logo: this.profileForm.get('logo').get('logo').value,
        photo: this.profileForm.get('photo').get('photo').value,
        vanityId: this.profileForm.get('vanity').get('vanityId').value,
      },
    };

    this.isProcessing = true;
    this.mealPlannerSetupService.profileSetup(profileSetupCommand).subscribe(
      () => {
        this.isSetupComplete = true;
        this.currentStep = null;
      },
      () => (this.isProcessing = false),
    );
  }

  checkCompleteAgreement($event: Event) {
    const target = $event.target as HTMLInputElement;
    this.steps[1].isComplete = target.checked;
  }
}
