import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiV2 } from '../api.v2';

@Injectable()
export class AnonymousService {
  constructor(private apiV2: ApiV2) {}

  /**
   * @param {string} email
   * @param {string} location
   * @returns {Observable<any>}
   */
  sendClientInterest(email: string, location: string): Observable<any> {
    return this.apiV2.post$('anonymous/client-interest', { email, location });
  }
}
