import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { PlannerConfiguration } from '../../../lprx-shared-lib/planner/planner-configuration';
import { USDAFactsResults } from '../../../lprx-shared-lib/recipe/USDAFactsResults';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { Day } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Day';
import { DropService } from '../drop.service';
import { PlannerService } from '../planner.service';

@Component({
  selector: 'app-planner-day',
  templateUrl: './planner-day.component.html',
  styleUrls: ['./planner-day.component.scss'],
})
export class PlannerDayComponent implements OnInit, OnDestroy, AfterViewInit {
  private subs: Subscription[] = [];

  @Input() day: Day;
  @Input() dayName: string = '';
  @Input() mealPlan: MealPlan;
  @Input() weekNumber: string;

  isToday = false;
  dateNumber: string;
  date: moment.Moment;
  usdaFacts: USDAFactsResults;
  isMouseOver = false;
  nameId: string;
  isDraggingCard = false;
  showNutritionFacts: boolean = false;
  plannerConfig: PlannerConfiguration = new PlannerConfiguration();
  nutrients: USDAFactsResults;

  nutritionFactWarnings: string[] = [];

  @ViewChild('dayContainer', { static: false }) dayContainer: ElementRef;
  private rect: any;

  constructor(
    private readonly dropService: DropService,
    private readonly plannerService: PlannerService
  ) {}

  mouseEnter() {
    this.isMouseOver = true;
    this.dropService.setActiveDrop(this.nameId);
    //
    // if (this.dropService.isDragging) {
    //   console.log('dragging ' + this.nameId);
    // }
  }

  mouseLeave() {
    this.isMouseOver = false;
  }

  ngOnInit() {
    this.nameId = 'day' + Math.random() * 100000;

    this.subs.push(
      this.dropService.getActiveDrop().subscribe((component) => {
        this.isMouseOver = component === this.nameId;
      })
    );

    const dropServiceSubscription = this.dropService
      .getIsCardDragging()
      .subscribe((isDraggingCard) => (this.isDraggingCard = isDraggingCard));

    this.subs.push(dropServiceSubscription);

    this.setDate();

    this.subs.push(
      this.plannerService.plannerConfig$.subscribe((plannerConfig) => {
        this.plannerConfig = plannerConfig;
        this.usdaFacts = this.day.getUSDAFacts(this.plannerConfig.nutrients);
        this.checkNuts();
      })
    );

    const subscription = this.plannerService.plannerUpdated$.subscribe((updatedEvent) => {
      if (updatedEvent.weekNumber === this.weekNumber) {
        this.usdaFacts = this.day.getUSDAFacts(this.plannerConfig.nutrients);
        this.checkNuts();
      }
    });
    this.subs.push(subscription);
  }

  checkNuts() {
    this.nutritionFactWarnings = [];

    if (!this.plannerConfig?.nutrientLimits?.daily) {
      return;
    }

    for (const nut of this.plannerConfig.nutrientLimits.daily) {
      if (!this.usdaFacts.nutrients[nut.key] || !nut.enabled) {
        continue;
      }

      if (this.usdaFacts.nutrients[nut.key].value > nut.high) {
        this.nutritionFactWarnings.push(`${nut.nutrient} &gt; ${nut.high}`);
      }

      if (this.usdaFacts.nutrients[nut.key].value < nut.low) {
        this.nutritionFactWarnings.push(`${nut.nutrient} &lt; ${nut.low}`);
      }
    }
  }

  // Set the date of this day
  private setDate() {
    const wkNum = this.weekNumber;
    const [year, weekNumber] = wkNum.split('.');

    this.date = moment()
      .isoWeekYear(parseInt(year, 10))
      .isoWeek(parseInt(weekNumber, 10))
      .isoWeekday(this.dayName);

    this.dateNumber = this.date.date().toString();
    if (this.dateNumber === '1') {
      this.dateNumber = this.date.format('MMMM') + ' ' + this.dateNumber;
    }

    this.isToday = this.date.isSame(moment(), 'day');
  }

  ngOnDestroy() {
    unsubscribe(this.subs);
  }

  private getDay(dayName: string): Day {
    return this.plannerService.getWeeklyPlan(this.weekNumber).getDay(dayName);
  }

  ngAfterViewInit(): void {
    const element = this.dayContainer.nativeElement;

    this.rect = element.getBoundingClientRect();

    // this.subs.push(
    //   this.dropService.draggingCardPosition$.subscribe((position) => {
    //     // if position in the element bounds using getBoundingClientRect, then log it to console
    //
    //     // const rect = element.getBoundingClientRect();
    //
    //     // if position with horizontal bounds then update to get vertical bounds
    //     if (position.x >= this.rect.left && position.x <= this.rect.right) {
    //       this.rect = element.getBoundingClientRect();
    //
    //       if (
    //         // position.x >= rect.left &&
    //         // position.x <= rect.right &&
    //         position.y >= this.rect.top &&
    //         position.y <= this.rect.bottom
    //       ) {
    //         console.log('dragging with in ' + this.nameId);
    //         this.isMouseOver = true;
    //         this.isDraggingCard = true;
    //       } else {
    //         this.isMouseOver = false;
    //         this.isDraggingCard = false;
    //       }
    //     } else {
    //       this.isMouseOver = false;
    //       this.isDraggingCard = false;
    //     }
    //
    //     //   console.log(
    //     //     position,
    //     //     element.offsetLeft,
    //     //     element.offsetTop,
    //     //     element.offsetLeft + element.offsetWidth,
    //     //     element.offsetHeight + element.offsetTop
    //     //   );
    //     //   if (
    //     //     position.x >= element.offsetLeft &&
    //     //     position.x <= element.offsetLeft + element.offsetWidth &&
    //     //     position.y >= element.offsetTop &&
    //     //     position.y <= element.offsetTop + element.offsetHeight
    //     //   ) {
    //     //     console.log('dragging with in ' + this.nameId);
    //     //   }
    //   })
    // );
  }
}
