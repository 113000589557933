import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { RecipeSearchResultInterface } from '../../app/model/recipe-search-result.interface';
import { NutrientLimitsFilter } from '../../lprx-shared-lib/interface/nutrient-limits.filter';
import { ApiV2 } from '../api.v2';
import { Api } from '../aws.api';
import { Recipe } from '../../lprx-shared-lib/entities/recipe/Recipe';

@Injectable()
export class AdminRecipesService {
  constructor(
    private apiV2: ApiV2,
    private api: Api,
    private router: Router,
    private toastr: ToastrService
  ) {}

  /**
   * @param {string} mealPlanId
   * @param {string} term
   * @param {number} from
   * @param {number} limit
   * @param {any[]} mealTypes
   * @param nutrient_limits
   * @returns {Observable<RecipeSearchResultInterface>}
   */
  search(
    mealPlanId: string,
    term = '',
    from = 0,
    limit = 30,
    mealTypes = [],
    nutrient_limits?: NutrientLimitsFilter
  ) {
    const search$ = this.apiV2.post$('admin/recipes/search', {
      mealPlanId,
      term,
      from,
      limit,
      mealTypes,
      nutrient_limits,
    });
    return search$.pipe(
      map((result) => {
        result.recipes = result.recipes.map((recipe) => Recipe.fromObject(recipe));
        return result as RecipeSearchResultInterface;
      })
    );
  }

  /**
   * @param {Recipe} recipe
   * @returns {Promise<Recipe>}
   */
  async cloneRecipe(recipe: Pick<Recipe, 'id' | 'title'>) {
    if (confirm(`Are you sure you want to clone ${recipe.title}?`)) {
      const clonedRecipe = await this.api.post('admin/recipes/clone', { id: recipe.id });
      // @todo: have fun with messaging
      this.toastr.success(
        `Aren't there laws against that?`,
        `You\'ve Cloned ${clonedRecipe.title}!`
      );
      await this.router.navigateByUrl(`/admin/recipes/edit/${clonedRecipe.id}`);
    }
  }

  /**
   *
   * @param recipe
   */
  async removeRecipe(recipe: Pick<Recipe, 'id' | 'title'>) {
    const title = recipe.title;

    if (
      confirm(`Are you sure you want to delete ${title}?`) &&
      confirm(`Absolutely sure? It is ${title}, after all.`)
    ) {
      const response = await this.api.post(`admin/recipes/delete/`, {
        id: recipe.id,
      });
      if (response) {
        this.toastr.success(`You deleted ${title}.`, 'Hasta la vista, baby!');
        return true;
      }
    }

    return false;
  }
}
