import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { switchMap } from 'rxjs/operators';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { DistributorPlanConfig } from '../../../lprx-shared-lib/entities/distributor-config';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-buy-page',
  templateUrl: './buy-page.component.html',
  styleUrls: ['./buy-page.component.scss']
})
export class BuyPageComponent implements OnInit {
  canSubscribe: boolean = true;
  isLoading: boolean = true;
  distributor: Distributor;
  user: User;

  constructor(private lprxApi: LprxApiProvider, private auth: AuthService) {}

  ngOnInit() {
    this.auth
      .getUser()
      .pipe(
        switchMap(user => {
          this.user = user;
          return forkJoin({
            distributor: fromPromise(this.lprxApi.distributors.get(user.distributorId)),
            distConfig: fromPromise(
              this.lprxApi.get<DistributorPlanConfig>(`v3/distributor-config/${user.distributorId}`)
            )
          });
        })
      )
      .subscribe(v => {
        this.isLoading = false;
        this.canSubscribe = v.distConfig.enableSubscriptions && this.user.allowSubscription;
        this.distributor = v.distributor;
      });
  }
}
