/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/currency.ts
 *  --------------------------
 *  - Swagger items commented out
 */

const stripePresentmentCurrencies: string[] = [
  'USD',
  'AED',
  'AFN',
  'ALL',
  'AMD',
  'ANG',
  'AOA',
  'ARS',
  'AUD',
  'AWG',
  'AZN',
  'BAM',
  'BBD',
  'BDT',
  'BGN',
  'BIF',
  'BMD',
  'BND',
  'BOB',
  'BRL',
  'BSD',
  'BWP',
  'BYN',
  'BZD',
  'CAD',
  'CDF',
  'CHF',
  'CLP',
  'CNY',
  'COP',
  'CRC',
  'CVE',
  'CZK',
  'DJF',
  'DKK',
  'DOP',
  'DZD',
  'EGP',
  'ETB',
  'EUR',
  'FJD',
  'FKP',
  'GBP',
  'GEL',
  'GIP',
  'GMD',
  'GNF',
  'GTQ',
  'GYD',
  'HKD',
  'HNL',
  'HRK',
  'HTG',
  'HUF',
  'IDR',
  'ILS',
  'INR',
  'ISK',
  'JMD',
  'JPY',
  'KES',
  'KGS',
  'KHR',
  'KMF',
  'KRW',
  'KYD',
  'KZT',
  'LAK',
  'LBP',
  'LKR',
  'LRD',
  'LSL',
  'MAD',
  'MDL',
  'MGA',
  'MKD',
  'MMK',
  'MNT',
  'MOP',
  'MRO',
  'MUR',
  'MVR',
  'MWK',
  'MXN',
  'MYR',
  'MZN',
  'NAD',
  'NGN',
  'NIO',
  'NOK',
  'NPR',
  'NZD',
  'PAB',
  'PEN',
  'PGK',
  'PHP',
  'PKR',
  'PLN',
  'PYG',
  'QAR',
  'RON',
  'RSD',
  'RUB',
  'RWF',
  'SAR',
  'SBD',
  'SCR',
  'SEK',
  'SGD',
  'SHP',
  'SLL',
  'SOS',
  'SRD',
  'STD',
  'SZL',
  'THB',
  'TJS',
  'TOP',
  'TRY',
  'TTD',
  'TWD',
  'TZS',
  'UAH',
  'UGX',
  'UYU',
  'UZS',
  'VND',
  'VUV',
  'WST',
  'XAF',
  'XCD',
  'XOF',
  'XPF',
  'YER',
  'ZAR',
  'ZMW'
];

const cs = {
  USD: { symbol: '$', isZeroDecimal: false },
  AED: { symbol: 'د.إ', isZeroDecimal: false },
  AFN: { symbol: '؋', isZeroDecimal: false },
  ALL: { symbol: 'L', isZeroDecimal: false },
  AMD: { symbol: '֏', isZeroDecimal: false },
  ANG: { symbol: 'ƒ', isZeroDecimal: false },
  AOA: { symbol: 'Kz', isZeroDecimal: false },
  ARS: { symbol: '$', isZeroDecimal: false },
  AUD: { symbol: '$', isZeroDecimal: false },
  AWG: { symbol: 'ƒ', isZeroDecimal: false },
  AZN: { symbol: '₼', isZeroDecimal: false },
  BAM: { symbol: 'KM', isZeroDecimal: false },
  BBD: { symbol: '$', isZeroDecimal: false },
  BDT: { symbol: '৳', isZeroDecimal: false },
  BGN: { symbol: 'лв', isZeroDecimal: false },
  BHD: { symbol: '.د.ب', isZeroDecimal: false },
  BIF: { symbol: 'FBu', isZeroDecimal: true },
  BMD: { symbol: '$', isZeroDecimal: false },
  BND: { symbol: '$', isZeroDecimal: false },
  BOB: { symbol: '$b', isZeroDecimal: false },
  BOV: { symbol: 'BOV', isZeroDecimal: false },
  BRL: { symbol: 'R$', isZeroDecimal: false },
  BSD: { symbol: '$', isZeroDecimal: false },
  BTC: { symbol: '₿', isZeroDecimal: false },
  BTN: { symbol: 'Nu.', isZeroDecimal: false },
  BWP: { symbol: 'P', isZeroDecimal: false },
  BYN: { symbol: 'Br', isZeroDecimal: false },
  BYR: { symbol: 'Br', isZeroDecimal: false },
  BZD: { symbol: 'BZ$', isZeroDecimal: false },
  CAD: { symbol: '$', isZeroDecimal: false },
  CDF: { symbol: 'FC', isZeroDecimal: false },
  CHE: { symbol: 'CHE', isZeroDecimal: false },
  CHF: { symbol: 'CHF', isZeroDecimal: false },
  CHW: { symbol: 'CHW', isZeroDecimal: false },
  CLF: { symbol: 'CLF', isZeroDecimal: false },
  CLP: { symbol: '$', isZeroDecimal: true },
  CNY: { symbol: '¥', isZeroDecimal: false },
  COP: { symbol: '$', isZeroDecimal: false },
  COU: { symbol: 'COU', isZeroDecimal: false },
  CRC: { symbol: '₡', isZeroDecimal: false },
  CUC: { symbol: '$', isZeroDecimal: false },
  CUP: { symbol: '₱', isZeroDecimal: false },
  CVE: { symbol: '$', isZeroDecimal: false },
  CZK: { symbol: 'Kč', isZeroDecimal: false },
  DJF: { symbol: 'Fdj', isZeroDecimal: true },
  DKK: { symbol: 'kr', isZeroDecimal: false },
  DOP: { symbol: 'RD$', isZeroDecimal: false },
  DZD: { symbol: 'دج', isZeroDecimal: false },
  EEK: { symbol: 'kr', isZeroDecimal: false },
  EGP: { symbol: '£', isZeroDecimal: false },
  ERN: { symbol: 'Nfk', isZeroDecimal: false },
  ETB: { symbol: 'Br', isZeroDecimal: false },
  ETH: { symbol: 'Ξ', isZeroDecimal: false },
  EUR: { symbol: '€', isZeroDecimal: false },
  FJD: { symbol: '$', isZeroDecimal: false },
  FKP: { symbol: '£', isZeroDecimal: false },
  GBP: { symbol: '£', isZeroDecimal: false },
  GEL: { symbol: '₾', isZeroDecimal: false },
  GGP: { symbol: '£', isZeroDecimal: false },
  GHC: { symbol: '₵', isZeroDecimal: false },
  GHS: { symbol: 'GH₵', isZeroDecimal: false },
  GIP: { symbol: '£', isZeroDecimal: false },
  GMD: { symbol: 'D', isZeroDecimal: false },
  GNF: { symbol: 'FG', isZeroDecimal: true },
  GTQ: { symbol: 'Q', isZeroDecimal: false },
  GYD: { symbol: '$', isZeroDecimal: false },
  HKD: { symbol: '$', isZeroDecimal: false },
  HNL: { symbol: 'L', isZeroDecimal: false },
  HRK: { symbol: 'kn', isZeroDecimal: false },
  HTG: { symbol: 'G', isZeroDecimal: false },
  HUF: { symbol: 'Ft', isZeroDecimal: false },
  IDR: { symbol: 'Rp', isZeroDecimal: false },
  ILS: { symbol: '₪', isZeroDecimal: false },
  IMP: { symbol: '£', isZeroDecimal: false },
  INR: { symbol: '₹', isZeroDecimal: false },
  IQD: { symbol: 'ع.د', isZeroDecimal: false },
  IRR: { symbol: '﷼', isZeroDecimal: false },
  ISK: { symbol: 'kr', isZeroDecimal: false },
  JEP: { symbol: '£', isZeroDecimal: false },
  JMD: { symbol: 'J$', isZeroDecimal: false },
  JOD: { symbol: 'JD', isZeroDecimal: false },
  JPY: { symbol: '¥', isZeroDecimal: true },
  KES: { symbol: 'KSh', isZeroDecimal: false },
  KGS: { symbol: 'лв', isZeroDecimal: false },
  KHR: { symbol: '៛', isZeroDecimal: false },
  KMF: { symbol: 'CF', isZeroDecimal: true },
  KPW: { symbol: '₩', isZeroDecimal: false },
  KRW: { symbol: '₩', isZeroDecimal: true },
  KWD: { symbol: 'KD', isZeroDecimal: false },
  KYD: { symbol: '$', isZeroDecimal: false },
  KZT: { symbol: '₸', isZeroDecimal: false },
  LAK: { symbol: '₭', isZeroDecimal: false },
  LBP: { symbol: '£', isZeroDecimal: false },
  LKR: { symbol: '₨', isZeroDecimal: false },
  LRD: { symbol: '$', isZeroDecimal: false },
  LSL: { symbol: 'M', isZeroDecimal: false },
  LTC: { symbol: 'Ł', isZeroDecimal: false },
  LTL: { symbol: 'Lt', isZeroDecimal: false },
  LVL: { symbol: 'Ls', isZeroDecimal: false },
  LYD: { symbol: 'LD', isZeroDecimal: false },
  MAD: { symbol: 'MAD', isZeroDecimal: false },
  MDL: { symbol: 'lei', isZeroDecimal: false },
  MGA: { symbol: 'Ar', isZeroDecimal: true },
  MKD: { symbol: 'ден', isZeroDecimal: false },
  MMK: { symbol: 'K', isZeroDecimal: false },
  MNT: { symbol: '₮', isZeroDecimal: false },
  MOP: { symbol: 'MOP$', isZeroDecimal: false },
  MRO: { symbol: 'UM', isZeroDecimal: false },
  MRU: { symbol: 'UM', isZeroDecimal: false },
  MUR: { symbol: '₨', isZeroDecimal: false },
  MVR: { symbol: 'Rf', isZeroDecimal: false },
  MWK: { symbol: 'MK', isZeroDecimal: false },
  MXN: { symbol: '$', isZeroDecimal: false },
  MXV: { symbol: 'MXV', isZeroDecimal: false },
  MYR: { symbol: 'RM', isZeroDecimal: false },
  MZN: { symbol: 'MT', isZeroDecimal: false },
  NAD: { symbol: '$', isZeroDecimal: false },
  NGN: { symbol: '₦', isZeroDecimal: false },
  NIO: { symbol: 'C$', isZeroDecimal: false },
  NOK: { symbol: 'kr', isZeroDecimal: false },
  NPR: { symbol: '₨', isZeroDecimal: false },
  NZD: { symbol: '$', isZeroDecimal: false },
  OMR: { symbol: '﷼', isZeroDecimal: false },
  PAB: { symbol: 'B/.', isZeroDecimal: false },
  PEN: { symbol: 'S/.', isZeroDecimal: false },
  PGK: { symbol: 'K', isZeroDecimal: false },
  PHP: { symbol: '₱', isZeroDecimal: false },
  PKR: { symbol: '₨', isZeroDecimal: false },
  PLN: { symbol: 'zł', isZeroDecimal: false },
  PYG: { symbol: 'Gs', isZeroDecimal: true },
  QAR: { symbol: '﷼', isZeroDecimal: false },
  RMB: { symbol: '￥', isZeroDecimal: false },
  RON: { symbol: 'lei', isZeroDecimal: false },
  RSD: { symbol: 'Дин.', isZeroDecimal: false },
  RUB: { symbol: '₽', isZeroDecimal: false },
  RWF: { symbol: 'R₣', isZeroDecimal: true },
  SAR: { symbol: '﷼', isZeroDecimal: false },
  SBD: { symbol: '$', isZeroDecimal: false },
  SCR: { symbol: '₨', isZeroDecimal: false },
  SDG: { symbol: 'ج.س.', isZeroDecimal: false },
  SEK: { symbol: 'kr', isZeroDecimal: false },
  SGD: { symbol: 'S$', isZeroDecimal: false },
  SHP: { symbol: '£', isZeroDecimal: false },
  SLL: { symbol: 'Le', isZeroDecimal: false },
  SOS: { symbol: 'S', isZeroDecimal: false },
  SRD: { symbol: '$', isZeroDecimal: false },
  SSP: { symbol: '£', isZeroDecimal: false },
  STD: { symbol: 'Db', isZeroDecimal: false },
  STN: { symbol: 'Db', isZeroDecimal: false },
  SVC: { symbol: '$', isZeroDecimal: false },
  SYP: { symbol: '£', isZeroDecimal: false },
  SZL: { symbol: 'E', isZeroDecimal: false },
  THB: { symbol: '฿', isZeroDecimal: false },
  TJS: { symbol: 'SM', isZeroDecimal: false },
  TMT: { symbol: 'T', isZeroDecimal: false },
  TND: { symbol: 'د.ت', isZeroDecimal: false },
  TOP: { symbol: 'T$', isZeroDecimal: false },
  TRL: { symbol: '₤', isZeroDecimal: false },
  TRY: { symbol: '₺', isZeroDecimal: false },
  TTD: { symbol: 'TT$', isZeroDecimal: false },
  TVD: { symbol: '$', isZeroDecimal: false },
  TWD: { symbol: 'NT$', isZeroDecimal: false },
  TZS: { symbol: 'TSh', isZeroDecimal: false },
  UAH: { symbol: '₴', isZeroDecimal: false },
  UGX: { symbol: 'USh', isZeroDecimal: true },
  UYI: { symbol: 'UYI', isZeroDecimal: false },
  UYU: { symbol: '$U', isZeroDecimal: false },
  UYW: { symbol: 'UYW', isZeroDecimal: false },
  UZS: { symbol: 'лв', isZeroDecimal: false },
  VEF: { symbol: 'Bs', isZeroDecimal: false },
  VES: { symbol: 'Bs.S', isZeroDecimal: false },
  VND: { symbol: '₫', isZeroDecimal: true },
  VUV: { symbol: 'VT', isZeroDecimal: true },
  WST: { symbol: 'WS$', isZeroDecimal: false },
  XAF: { symbol: 'FCFA', isZeroDecimal: true },
  XBT: { symbol: 'Ƀ', isZeroDecimal: false },
  XCD: { symbol: '$', isZeroDecimal: false },
  XOF: { symbol: 'CFA', isZeroDecimal: true },
  XPF: { symbol: '₣', isZeroDecimal: true },
  XSU: { symbol: 'Sucre', isZeroDecimal: false },
  XUA: { symbol: 'XUA', isZeroDecimal: false },
  YER: { symbol: '﷼', isZeroDecimal: false },
  ZAR: { symbol: 'R', isZeroDecimal: false },
  ZMW: { symbol: 'ZK', isZeroDecimal: false },
  ZWD: { symbol: 'Z$', isZeroDecimal: false },
  ZWL: { symbol: '$', isZeroDecimal: false }
};

interface StripeCurrenciesMap {
  [isoCurrency: string]: { symbol: string; isZeroDecimal: boolean };
}

const map: StripeCurrenciesMap = {};

for (const stripC of stripePresentmentCurrencies) {
  map[stripC] = cs[stripC];
}

export const stripeCurrencies: StripeCurrenciesMap = map;

console.log(stripeCurrencies);
