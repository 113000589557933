import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { ApiV2 } from '../../providers/api.v2';
import { CreditCardInputModule } from '../credit-card-input/credit-card-input.module';
import { PipesModule } from '../pipe/pipes.module';
import { AccountRoutingModule } from './account-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UpgradeConfirmationComponent } from './upgrade-confirmation/upgrade-confirmation.component';
import { UpgradeComponent } from './upgrade/upgrade.component';
import { DangerButtonDirective } from '../../component-directives/button.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AccountRoutingModule,
    PipesModule,
    CreditCardInputModule,
    DangerButtonDirective,
  ],
  declarations: [DashboardComponent, UpgradeComponent, UpgradeConfirmationComponent],
  providers: [ApiV2],
})
export class AccountModule {}
