/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/strip-recipe-data.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { classToClass } from 'class-transformer';

export function stripRecipeDataFromWeeklyMealPlan<T>(weeklyPlan: T) {
  const _weeklyPlan = classToClass(weeklyPlan); // deep clone
  const daysOfTheWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  for (const day of daysOfTheWeek) {
    if (!_weeklyPlan[day] || !_weeklyPlan[day].meals) {
      console.log('missing meals on ' + day);
      continue;
    }

    for (const meal of Object.keys(_weeklyPlan[day].meals)) {
      if (!_weeklyPlan[day].meals[meal] || !_weeklyPlan[day].meals[meal].cards) {
        console.log('missing cards');
        continue;
      }

      for (const card of Object.keys(_weeklyPlan[day].meals[meal].cards)) {
        const isRecipeCard = _weeklyPlan[day].meals[meal].cards[card].type === 'r';
        if (isRecipeCard) {
          _weeklyPlan[day].meals[meal].cards[card].recipe = undefined;
          // delete(_weeklyPlan[day].meals[meal].cards[card].recipe);
        }
      }
    }
  }

  return _weeklyPlan;
}
