<app-focused-form size="md" [background]="'url(\'/assets/img/home/hero-banner-002.jpg\') center center no-repeat'"
    [distributorId]="distributorVanityId" [showLprxLogo]="false" [showBranding]="false">

    <h2 class='text-center mb-4'>Food Assessment Questionnaire</h2>

    <div class="green-line mx-auto mb-4" style="height: 3px; width: 50px; background-color: #b8e771;"></div>

    <p class="h5 font-weight-normal mb-4">
        Please take a moment to complete the Food Assessment Questionnaire. This will help us better understand your
        dietary habits and provide you with personalized recommendations.
    </p>

    <p class="h5 font-weight-normal mb-4">
        The Food Assessment Questionnaire will take approximately 20&#8209;30&nbsp;minutes to complete.
    </p>

    <p class="h5 mb-4 text-center"><strong>Enter your information below to get&nbsp;started.</strong></p>

    <div class="mb-4 px-md-3">

        <form [formGroup]='userForm' (ngSubmit)="onSubmit()">

            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="first-name">First name</label>
                    <input id="first-name" type="text" class="form-control" formControlName="firstName"
                        placeholder="First Name">
                    <div *ngIf="userForm.get('firstName').invalid && (userForm.get('firstName').dirty || userForm.get('firstName').touched)"
                        class="alert alert-danger mt-2 small">
                        <div *ngIf="userForm.get('firstName').errors?.['required']">First name is required.</div>
                        <div *ngIf="userForm.get('firstName').errors?.['minlength']">First name must be at least 2 characters long.</div>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="last-name">Last name</label>
                    <input id="last-name" type="text" class="form-control" formControlName="lastName"
                        placeholder="Last Name">
                    <div *ngIf="userForm.get('lastName').invalid && (userForm.get('lastName').dirty || userForm.get('lastName').touched)"
                        class="alert alert-danger mt-2 small">
                        <div *ngIf="userForm.get('lastName').errors?.['required']">Last name is required.</div>
                        <div *ngIf="userForm.get('lastName').errors?.['minlength']">Last name must be at least 2 characters long.</div>
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <label for="email">Email</label>
                <input id="email" type="email" class="form-control" formControlName="email" placeholder="Email">
                <div *ngIf="userForm.get('email').invalid && (userForm.get('email').dirty || userForm.get('email').touched)"
                    class="alert alert-danger mt-2 small">
                    <div *ngIf="userForm.get('email').errors?.['required']">Email is required.</div>
                    <div *ngIf="userForm.get('email').errors?.['email']">Please enter a valid email address.</div>
                    <div *ngIf="userForm.get('email').errors?.['minlength']">Email must be at least 2 characters long.</div>
                </div>
            </div>



            <p class="text-center">
                <button type="submit" btnPrimary class="w-100" [disabled]="userForm.invalid || processing">
                    <i class="fa fa-hand-o-right"></i>
                    Take the Assessment

                    <div *ngIf="processing" role="status">
                        <app-loading *ngIf="processing" [style]="'icon'"></app-loading>
                    </div>
                </button>
            </p>

        </form>
    </div>

    <hr class="mb-4" />

    <div class="mb-5">
        <h4 class="text-center">Discover Your Nutrition in <span class="no-br">Just 20 Minutes with VioScreen!</span>
        </h4>

        <p>VioScreen is a cutting-edge dietary assessment tool that offers a comprehensive look at your eating
            habits—all in
            about 20 minutes. It’s like getting the insights of three months of nutrition tracking, without the hassle!

            Backed by science and trusted by top health organizations, VioScreen uses the latest research to provide you
            with a detailed analysis of your diet.

            Whether you're looking to improve your health or just curious about your nutrition, VioScreen gives you the
            data
            you need to take the next step.</p>

    </div>

    <h4 class="mb-4 text-center">What to Expect After Taking <span class="no-br">Your VioScreen Assessment</span></h4>





    <div class="row">
        <div class="col-md-8">
            <p>
                <strong>Your Healthy Eating Index (HEI) Score</strong>
            </p>
            <p>
                After completing the VioScreen assessment, you'll receive a Healthy Eating Index (HEI) score. This
                score, which
                ranges from 0 to 100, measures the overall quality of your diet. A higher score means your eating habits
                align
                closely with the recommended guidelines for good health.

            </p>
            <ul>
                <li>80 or above: Excellent!</li>
                <li>50 to 80: Good, but there's room for improvement.</li>
                <li>Below 50: Time to make some adjustments.</li>
            </ul>
        </div>
        <div class="col-md-4 text-center">
            <img src="/assets/img/vioscreen/hei-score.png" alt="VioScreen HEI Score"><br />Sample Score
        </div>
    </div>

</app-focused-form>
