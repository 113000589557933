import { Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { Contributor } from '../../lprx-shared-lib/entities/contributor';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class ContributorsService {
  private contributors = new Map<string, Observable<Contributor>>();

  constructor(private lprxApi: LprxApiProvider) {}

  /**
   * Get a contributor
   *
   * @param id
   */
  get(id: string): Observable<Contributor> {
    if (!id) {
      return of(null);
    }

    if (!this.contributors.has(id)) {
      this.contributors.set(id, fromPromise(this.lprxApi.get(`v3/contributors/${id}`)));
    }
    return this.contributors.get(id);
  }
}
