import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MealLocation } from '../../../lprx-shared-lib/entities/meal-location';
import { Note } from '../../../lprx-shared-lib/entities/weeky-meal-plan/note';
import { CreateOrEditNoteModalComponent } from '../note/create-or-edit-note-modal/create-or-edit-note-modal.component';
import { ViewNoteModalComponent } from '../note/view-note-modal/view-note-modal.component';

@Injectable()
export class NoteModalService {
  constructor(private modalService: NgbModal) {}

  openView(note: Note, mealLocation: MealLocation) {
    const modalRef = this.modalService.open(ViewNoteModalComponent, {
      centered: true,
      windowClass: 'note',
      backdrop: 'static'
    });
    modalRef.componentInstance.note = note;
    modalRef.componentInstance.mealLocation = mealLocation;
    return modalRef;
  }

  openCreateOrEdit(mealLocation: MealLocation, note?: Note) {
    const modalRef = this.modalService.open(CreateOrEditNoteModalComponent, {
      centered: true,
      backdrop: 'static'
    });
    modalRef.componentInstance.mealLocation = mealLocation;
    modalRef.componentInstance.note = note;
    return modalRef;
  }
}
