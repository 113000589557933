/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/recipe/Recipe.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, plainToClass, Transform, Type } from 'class-transformer';
import { IsBoolean, IsInt, Min } from 'class-validator';
import { defaultNutrients } from '../../default-nutrients';
import { NutrientsCollection } from '../../recipe/NutrientsCollection';
import { USDAFactsResults } from '../../recipe/USDAFactsResults';
import { Default } from '../../utils/default.decorator';
import { BaseItem } from '../base-item';
import { Element } from './Element';
import { EquipmentItem } from './EquipmentItem';
import { GroceryItem } from './grocery-item';
import { SimpleNutritionFacts } from './SimpleNutritionFacts';
import { Source } from './Source';

@Exclude()
export class Recipe implements BaseItem {
  @Expose()
  id: string;

  @Expose()
  @Default(Date.now())
  @IsInt()
  @Min(0)
  createdAt: number = Date.now();

  @Expose()
  @Default(Date.now())
  @IsInt()
  @Min(0)
  modifiedAt: number = Date.now();

  @Expose()
  @Default(false)
  @IsBoolean()
  isDeleted = false;

  @Expose()
  @Transform((v) => v || 'lprx')
  ownedBy: string;

  @Expose()
  addedBy: string;

  @Expose()
  @Default(true)
  canView = true;

  @Expose()
  contributedBy: string;

  @Expose()
  currDistributor: string;

  @Expose()
  description: string;

  @Expose()
  @Default([])
  @Type(() => Element)
  elements: Element[] = [];

  @Expose()
  @Default([])
  @Type(() => EquipmentItem)
  equipment: EquipmentItem[] = [];

  @Expose()
  @Default([])
  forMealPlans: string[] = [];

  @Expose()
  @Default('images/recipe/lprx-default-recipe-image.png')
  image = 'images/recipe/lprx-default-recipe-image.png';

  @Expose()
  @Default(false)
  isPublished = false;

  @Expose()
  @Default(false)
  isUsdaComplete = false;

  @Expose()
  @Transform((value) =>
    value && value.length > 0 ? value : ['Breakfast', 'Lunch', 'Dinner', 'Snack', 'Side']
  )
  mealTypes: string[] = ['Breakfast', 'Lunch', 'Dinner', 'Snack', 'Side'];

  @Expose()
  notes: string;

  @Expose()
  @IsInt()
  @Min(0)
  @Default(0)
  prepTime = 0;

  @Expose()
  @Transform((value) => (value < 1 ? 1 : value))
  @IsInt()
  @Min(1)
  @Default(1)
  servings = 1;

  @Expose()
  @Type(() => SimpleNutritionFacts)
  simpleNutritionFacts: SimpleNutritionFacts;

  @Expose()
  @Type(() => Source)
  @Default(new Source())
  source: Source = new Source();

  @Expose()
  @Default('draft')
  status: 'draft' | 'published' | 'deleted' = 'draft';

  @Expose()
  @IsInt()
  @Min(0)
  @Default(0)
  totalTime: number;

  @Expose()
  title: string;

  /**
   * @deprecated User servings is on the Card object
   */
  @Expose()
  userServings: number;

  @Expose()
  _ingredients: string;

  @Expose()
  _mealPlans: string;

  @Expose()
  _mealTypes: string;

  @Expose()
  copyrightAcknowledged: boolean;

  /**
   * @deprecated
   * @type {string[]}
   */
  @Expose()
  @Default([])
  tags: string[] = [];

  @Expose()
  copiedFrom?: string;

  @Expose()
  @Default(false)
  simpleEntry?: boolean = false;

  static fromObject(object: any): Recipe {
    return plainToClass(Recipe, object);
  }

  getGroceries(userServings: number = 0): GroceryItem[] {
    let list: GroceryItem[] = [];
    if (!userServings || userServings === 0) {
      userServings = this.servings;
    }
    this.elements.forEach((e) => {
      const groceries = e.getGroceries();
      for (const grocery of groceries) {
        grocery.quantity = grocery.quantity * (userServings / this.servings);
      }
      list = list.concat(groceries);
    });
    return list;
  }

  /**
   *
   */
  getNutritionFacts() {
    return {
      id: this.id,
      title: this.title,
      type: 'recipe',
      nutritionFacts: this.simpleNutritionFacts,
    };
  }

  /**
   *
   * @param nutrients
   */
  getNutrients(nutrients: Set<string> = defaultNutrients): NutrientsCollection {
    const nutrientSum: NutrientsCollection = {};
    for (const element of this.elements) {
      const elementNutrients = element.getNutrients(nutrients);
      for (const nutrient of Object.getOwnPropertyNames(elementNutrients)) {
        if (nutrients && !nutrients.has(nutrient)) {
          continue;
        }
        if (!nutrientSum[nutrient]) {
          nutrientSum[nutrient] = {
            value: 0,
            unit: elementNutrients[nutrient].unit,
            isKnown: elementNutrients[nutrient].isKnown,
          };
        }
        nutrientSum[nutrient].value += elementNutrients[nutrient].value / this.servings;
      }
    }
    return nutrientSum;
  }

  getIngredientNames(): string[] {
    const names: string[] = [];
    return names.concat(...this.elements.map((e) => e.ingredients.map((i) => i.item)));
  }

  /**
   *
   * @param nutrients
   * @param useCached
   */
  getUSDAFacts(nutrients: Set<string> = defaultNutrients, useCached = true): USDAFactsResults {
    return {
      id: this.id,
      title: this.title,
      type: 'recipe',
      nutrients: this.getNutrients(nutrients),
    };
  }
}
