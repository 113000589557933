import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { Storage } from '@aws-amplify/storage';

import { Observable } from 'rxjs';
import { getTokenResponse } from '../lprx-auth';
import { apiHostname } from './lprx-api/api-hostname';

Amplify.configure({ region: 'us-east-1' });

function isObject(obj) {
  return obj === Object(obj);
}

export { Amplify, Storage, API, Auth };

export const SERVERLESS_API = API;

export interface HttpOptions {
  headers?: HttpHeaders | { [p: string]: string | string[] };
  observe?: 'body';
  params?: HttpParams | { [p: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

@Injectable()
export class Api {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
  ) {
    this.configAmplify();
  }

  private configAmplify() {
    Amplify.configure({ region: 'us-east-1' });
  }

  private prefixPath(path) {
    return ('/appApi/' + path).replace(/\/\//g, '/');
  }

  /**
   * @param path
   * @param queryParams
   */
  get<T = any>(path: string, queryParams?: any): Promise<T> {
    this.configAmplify();

    let query = '';
    if (queryParams) {
      query =
        '?' +
        Object.keys(queryParams)
          .map((key) => {
            if (isObject(queryParams[key])) {
              return `${encodeURIComponent(key)}=${encodeURIComponent(
                JSON.stringify(queryParams[key]),
              )}`;
            } else {
              return `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`;
            }
          })
          .join('&');
    }

    const options: HttpOptions = {
      headers: {
        // 'x-aws-cognito-username': getLastAuthUsername()
        Authorization: `Bearer ${getTokenResponse().access_token}`,
      },
    };
    // hackish way to force the GET since HttpClient doesn't trigger until subscribe() is called
    const http$ = this.http.get(
      `https://${apiHostname()}${this.prefixPath(path)}${query}`,
      options,
    );
    return http$.toPromise() as Promise<T>;
  }

  /**
   * @param path
   * @param payload
   */
  post<T = any>(path: string, payload: any): Promise<T> {
    const options: HttpOptions = {
      headers: {
        // 'x-aws-cognito-username': getLastAuthUsername(),
        Authorization: `Bearer ${getTokenResponse().access_token}`,
      },
    };
    // hackish way to force the POST since HttpClient doesn't trigger until subscribe is called
    const http$ = this.http.post(
      `https://${apiHostname()}${this.prefixPath(path)}`,
      payload,
      options,
    ) as Observable<T>;
    return http$.toPromise<T>();
  }
}
