import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { from } from 'rxjs/observable/from';
import { User } from '../../lprx-shared-lib/entities/user/user';
import { PasswordPasser } from '../../lprx-shared-lib/password-passer';

import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { alertHttpError } from '../utilities/alert-http-error';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
  isPasswordValidated = false;
  processing: boolean;
  password: string;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user?: User },
    private lprxApi: LprxApiProvider,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  onIsValidated($event: { valid: boolean; password: string }) {
    this.isPasswordValidated = $event.valid;
    this.password = $event.password;
  }

  save() {
    if (this.isPasswordValidated) {
      from(
        this.lprxApi.post(`v3/password`, {
          username: this.data.user.username,
          password: PasswordPasser.encrypt(this.password),
        })
      )
        .pipe(alertHttpError())
        .subscribe(() => {
          this.dialogRef.close();
          this.toastr.success('Password reset successfully.');
        });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
}
