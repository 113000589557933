import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class AdminLayoutService {
  private isPlanner = false;

  private subject = new Subject<boolean>();

  constructor() {}

  setIsPlannerActive(isPlanner: boolean) {
    this.isPlanner = isPlanner;
    this.subject.next(isPlanner);
  }

  getIsPlannerActive(): Observable<boolean> {
    return this.subject.asObservable();
  }
}
