<app-focused-form
  [background]="'url(\'/assets/img/home/hero-banner-002.jpg\') center center no-repeat'"
>
  <h4 class="text-center">Forgot Your Password?</h4>

  <app-bad-clock></app-bad-clock>

  <form
    *ngIf="view == 'email'"
    class="form-signin"
    (submit)="sendResetCode()"
    #forgotPasswordForm="ngForm"
  >
    <div class="text-center">
      <p><strong>We know how it feels!</strong></p>
      <p>Enter your email address below and we'll send you a password reset code.</p>
    </div>
    <label for="inputEmail" class="sr-only">Email address</label>
    <input
      type="email"
      [(ngModel)]="email"
      id="inputEmail"
      class="form-control mb-3"
      placeholder="Email address"
      required=""
      autofocus=""
      name="email"
    />

    <button
      appButton btnSmall fullWidth
      type="submit"
      [disabled]="isProcessing || !forgotPasswordForm.form.valid"
    >
      Reset Password
    </button>

    <br />
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>

    <br />
    <p class="text-center">
      <a routerLink="/login" class="text-muted">Never mind, take me back to Log In</a>
    </p>
  </form>

  <form
    *ngIf="view == 'password'"
    class="form-signin"
    (submit)="resetPassword()"
    #codeForm="ngForm"
  >
    <div class="text-center">
      <p>
        We just sent you a verification code to your email address. Please check your inbox to
        retrieve the code and enter below. Then, create your new password.
      </p>
    </div>

    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
    <label for="code" class=""><strong>Verification Code</strong></label>
    <input
      type="text"
      [(ngModel)]="code"
      id="code"
      class="form-control mb-3"
      placeholder="Enter code"
      required=""
      autofocus=""
      name="code"
    />

    <strong>Create Password</strong>
    <ul>
      <li
        [class.text-success]="isPasswordLongEnough"
        [class.text-danger]="password && !isPasswordLongEnough"
      >
        At least 6 Characters
      </li>

      <li
        [class.text-success]="doesPasswordHaveUppercase"
        [class.text-danger]="password && !doesPasswordHaveUppercase"
      >
        At least 1 Uppercase character
      </li>

      <li
        [class.text-success]="doesPasswordHaveLowercase"
        [class.text-danger]="password && !doesPasswordHaveLowercase"
      >
        At least 1 Lowercase character
      </li>

      <li
        [class.text-success]="doesPasswordHaveNumber"
        [class.text-danger]="password && !doesPasswordHaveNumber"
      >
        At least 1 Number
      </li>

      <li
        [class.text-success]="doesPasswordMatch"
        [class.text-danger]="password && !doesPasswordMatch"
      >
        Passwords Match
      </li>
    </ul>

    <input type="hidden" name="email" [(ngModel)]="email" />

    <div class="form-group">
      <label for="inputPassword" class="-sr-only">Password</label>
      <input
        type="password"
        [(ngModel)]="password"
        (change)="validatePassword()"
        (keyup)="validatePassword()"
        id="inputPassword"
        name="password"
        placeholder="Password"
        required
        class="form-control mb-3"
      />
    </div>
    <div class="form-group">
      <label for="confirmPassword" class="-sr-only">Repeat Password</label>
      <input
        type="password"
        [(ngModel)]="passwordConfirm"
        (change)="validatePassword()"
        (keyup)="validatePassword()"
        name="confirmPassword"
        id="confirmPassword"
        placeholder="Repeat Password"
        class="form-control mb-3"
        required=""
      />
    </div>

    <button
      appButton btnSmall fullWidth
      type="submit"
      [disabled]="
        !this.isPasswordValid ||
        isProcessing ||
        password !== passwordConfirm ||
        !codeForm.form.valid
      "
    >
      Reset Password
    </button>

    <br />
    <div *ngIf="error" class="alert alert-danger">{{ error }}</div>

    <br />
    <p class="text-center">
      <button type="button" (click)="sendNewCode()" class="btn btn-sm btn-link" title="">
        Request a New Code
      </button>
    </p>
  </form>

  <div *ngIf="view == 'success'" class="text-center">
    <strong>You're all set with your new password. Now it's time to log in.</strong>
    <br />
    <br />
    <a routerLink="/login" appButton>Take me to the Log In.</a>
  </div>
</app-focused-form>
