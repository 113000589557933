<input type="hidden" [(ngModel)]="contributedBy" name="contributedBy">

<div *ngIf="contributor" class="row">
  <div class="col">
    <strong>{{contributor.name}}</strong>
    <br>{{contributor.website}}
    <br>
    <br>
    <a class="btn btn-link btn-danger" (click)="removeContributor()">Remove</a>
  </div>
  <div class="col">
    <img src="{{contributorLogoURL}}" style="max-width: 150px;">
  </div>
  <div class="col"></div>
  <div class="col"></div>
</div>

<div *ngIf="!contributor">
  <input class="form-control" (keyup)="search()" [(ngModel)]="contributorFilter">
  <br>
  <div *ngIf="filteredContributors">
    <div *ngFor="let c of filteredContributors">
        Select > <a href="javascript:" (click)="selectContributor(c.id)">{{c.name}}</a>
    </div>
  </div>
  <div *ngIf="filteredContributors.length == 0">
    <p>Search using the field above.</p>
  </div>
</div>