<div class="wrapper m-1">

    <div (click)="openRecipe()" [title]="recipe.title" class="clickable">
        <div [style.backgroundImage]="imageUrl"
             class="recipe-image clickable"
             style="height: 140px; background-position:center; background-size: cover;">&nbsp;
        </div>
        <div class="p-2 title">
            {{recipe.title | truncate:35}}
        </div>
    </div>

    <div class="row text-center utility-icons no-gutters">
        <div class="col">
            <mat-icon [style.opacity]="belongsToUser ? 1 : .3" color="primary"
                      [title]="belongsToUser ? 'Your recipe': ''">person
            </mat-icon>
        </div>
        <div class="col clickable" (click)="openAddToPlanner($event)">
            <mat-icon color="primary" title="Add to Planner">calendar_today
            </mat-icon>
        </div>
        <div class="col">
            <app-favorite-recipe-toggle [recipe]="recipe" [block]="true"></app-favorite-recipe-toggle>
        </div>
    </div>
</div>
