import { plainToClass } from 'class-transformer';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import {
  stripRecipeDataFromWeeklyMealPlan
} from '../../../lprx-shared-lib/utils/strip-recipe-data';
import { LprxApi } from '../api';

export class WeeklyPlans {
  constructor(private readonly lprxApi: LprxApi) {}

  get(weekNumber: string, clientId?: string): Promise<WeeklyPlan> {
    const endPoint: string = clientId
      ? `v3/weekly-plans/${clientId}/${weekNumber}`
      : `v3/weekly-plans/${weekNumber}`;
    return this.lprxApi.get(endPoint).then((weeklyPlan: WeeklyPlan) => {
      const weeklyPlan1 = plainToClass(WeeklyPlan, weeklyPlan);
      console.log(weeklyPlan1);

      return weeklyPlan1;
    });
  }

  delete(weekNumber: string, username?: string): Promise<any> {
    return this.lprxApi.delete(`v3/weekly-plans/${username}/${weekNumber}`);
  }

  save(weeklyPlan: WeeklyPlan): Promise<boolean> {
    const start = Date.now();
    const _weeklyPlan = stripRecipeDataFromWeeklyMealPlan(weeklyPlan);
    return this.lprxApi.post(`v3/weekly-plans`, _weeklyPlan).then((wp) => {
      console.log(Date.now() + ' WeeklyPlans - SAVED');
      return wp;
    });
  }
}
