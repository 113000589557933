import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CreditCardInputComponent } from './credit-card-input.component';

@NgModule({
  imports: [CommonModule, FormsModule],
  exports: [CreditCardInputComponent],
  declarations: [CreditCardInputComponent]
})
export class CreditCardInputModule {}
