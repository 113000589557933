import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tool } from '../../../../../lprx-shared-lib/recipe/tool';

/**
 * Generated class for the RecipeEquipmentInputComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */
@Component({
  selector: 'app-recipe-equipment-input',
  templateUrl: 'recipe-equipment-input.html'
})
export class RecipeEquipmentInputComponent implements OnInit {
  @Input() tools: Tool[] = [];
  @Output() updatedEquipment = new EventEmitter<Tool[]>();

  allTools: any;
  options = [];

  constructor() {}

  ngOnInit() {
    this.options = [];
    Tool.getAllTools().forEach(element => {
      this.options.push({
        name: element.label,
        value: element.key,
        checked: this.isSelected(element.key)
      });
    });

    console.log(this.options);
  }

  isSelected(key) {
    let isSelected = false;
    this.tools.forEach(el => {
      if (key === el.key) {
        isSelected = true;
      }
    });
    return isSelected;
  }

  updatedTools() {
    const tools: Tool[] = [];
    this.options
      .filter(opt => opt.checked)
      .map(opt => opt.value)
      .forEach(opt => {
        const tool = new Tool();
        tool.key = opt;
        tool.name = Tool.getLabel(opt);
        tools.push(tool);
      });

    this.updatedEquipment.emit(tools);
    console.log(tools);
  }
}
