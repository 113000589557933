import { ActivateAccountDto } from '../../lprx-shared-lib/dto/activate-account-dto';
import { ClientSignUpDto } from '../../lprx-shared-lib/dto/client-sign-up-dto';
import { ClientSignUpResponse } from '../../lprx-shared-lib/dto/client-sign-up-response';
import { LprxApi } from './api';
import { SetPasswordResponse } from '../../lprx-shared-lib/dto/set-password-response';

export class Users {
  constructor(private readonly lprxApi: LprxApi) {}

  async checkEmail(email: string) {
    return this.lprxApi.get(`v3/users/e/${email}`);
  }

  activate(dto: ActivateAccountDto): Promise<SetPasswordResponse> {
    return this.lprxApi.post<SetPasswordResponse>(`v3/activate`, dto);
  }

  delete(username: string) {
    return this.lprxApi.delete(`v3/users/${username}`);
  }

  clientSignUp(dto: ClientSignUpDto): Promise<ClientSignUpResponse> {
    return this.lprxApi.post(`v3/signup`, dto);
  }

  lastLoadedWeek(weekNumber) {
    this.lprxApi.post('v3/users/last-week-loaded', { weekNumber });
  }
}
