<div class="modal-header">
    <h4 class="modal-title">{{isNew ? 'Add' : 'Edit'}} Note</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div *ngIf="mealLocation" class="modal-info">
    {{mealLocation.dayName | dayDisplay:mealLocation.weekNumber:'ddd, MMM D'}}
    &ndash; {{mealLocation.mealName | titlecase}}
</div>
<div>
    <div [froalaEditor]="froalaOptions" [(froalaModel)]="note.message"></div>
</div>
<div class="modal-footer">
    <label style="margin-right: auto;">
        <input type="checkbox" name="note.isPrivate" [(ngModel)]="note.isPrivate"/> Private
    </label>
    <button type="button" class="btn btn-link" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" appButton (click)="save()">Save</button>
</div>
