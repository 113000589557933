import { Pipe, PipeTransform } from '@angular/core';
import { LayoutService } from '../layout/layout.service';
import { WindowSize } from '../layout/windowSize';

@Pipe({
  name: 'windowSize'
})
export class WindowSizePipe implements PipeTransform {
  private windowSize: WindowSize;

  constructor(public layout: LayoutService) {
    this.layout.windowSize$.subscribe(windowSize => (this.windowSize = windowSize));
  }

  transform(comparison: string, windowSize: string): any {
    const v = this.getValue(windowSize);

    switch (comparison) {
      case '>':
        return this.getValue(this.windowSize) > v;
      case '>=':
        return this.getValue(this.windowSize) >= v;
      case '<':
        return this.getValue(this.windowSize) < v;
      case '<=':
        return this.getValue(this.windowSize) <= v;
      case '!=':
      case '!==':
        return this.getValue(this.windowSize) !== v;
      case '=':
      case '==':
      case '===':
        return this.getValue(this.windowSize) === v;
    }

    throw new Error('Invalid comparision operator');
  }

  private getValue(windowSize: string) {
    let v = 0;
    switch (windowSize) {
      case 'xs':
        v = 0;
        break;
      case 'sm':
        v = 1;
        break;
      case 'md':
        v = 2;
        break;
      case 'lg':
        v = 3;
        break;
      case 'xl':
        v = 4;
        break;
    }
    return v;
  }
}
