import { Injectable } from '@angular/core';
import { API } from '../aws.api';
import { IRestHttpHandler } from './i-rest-http-handler';

@Injectable()
export class AmplifyAdapter implements IRestHttpHandler {
  constructor() {
    // Amplify.configure({ region: 'us-east-1' });
  }

  sanitizePath(path: string) {
    path = path.replace(/^\//, '').replace(/\/$/, '');
    return '/' + path;
  }

  /**
   * GET request
   *
   * @param path
   * @param queryParams
   */
  get<T>(path: string, queryParams?: any): Promise<T> {
    if (queryParams) {
      for (const p of Object.getOwnPropertyNames(queryParams)) {
        if (Array.isArray(queryParams[p])) {
          const arr = queryParams[p];
          delete queryParams[p];

          for (let i = 0; i < arr.length; i++) {
            queryParams[`${p}[${i}]`] = arr[i];
          }
        }
      }
    }

    const init = queryParams ? { queryStringParameters: queryParams } : null;
    return API.get('v3', this.sanitizePath(path), init);
  }

  /**
   * DELETE request
   *
   * @param path
   */
  delete(path: string, payload?: any) {
    const init: { body?: any } = {};

    if (payload) {
      init.body = payload;
    }

    return API.del('v3', this.sanitizePath(path), init);
  }

  /**
   * POST request
   *
   * @param path
   * @param payload
   */
  post<T>(path: string, payload: any): Promise<T> {
    return API.post('v3', this.sanitizePath(path), { body: payload });
  }

  /**
   * PUT request
   *
   * @param path
   * @param payload
   */
  put<T>(path: string, payload: any): Promise<T> {
    return API.put('v3', this.sanitizePath(path), { body: payload });
  }

  /**
   * PATCH request
   *
   * @param path
   * @param payload
   */
  patch<T>(path: string, payload: any): Promise<T> {
    return API.patch('v3', this.sanitizePath(path), { body: payload });
  }
}
