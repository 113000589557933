/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/user/user-status.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum UserStatus {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  BUNDLED = 'Bundled',
  FREE_TRIAL = 'Free Trial'
}
