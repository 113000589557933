import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { MealLocation } from '../../../../lprx-shared-lib/entities/meal-location';
import { Note } from '../../../../lprx-shared-lib/entities/weeky-meal-plan/note';
import { AuthService } from '../../../auth.service';
import { PlannerService } from '../../planner.service';

@Component({
  selector: 'app-create-or-edit-note-modal',
  templateUrl: './create-or-edit-note-modal.component.html',
  styleUrls: ['./create-or-edit-note-modal.component.scss']
})
export class CreateOrEditNoteModalComponent implements OnInit {
  public froalaOptions = {
    key: environment.froalaOptionsKey,
    quickInsertButtons: [],
    heightMin: 100,
    charCounterCount: false,
    toolbarButtons: ['undo', 'redo', '|', 'bold', 'italic', 'underline', 'clearFormatting'],
    toolbarButtonsXS: ['undo', 'redo', '-', 'bold', 'italic', 'underline']
  };

  @Input()
  note: Note;

  @Input()
  onSave: Function;

  isNew = true;

  @Input()
  mealLocation: MealLocation;

  constructor(
    public activeModal: NgbActiveModal,
    public plannerService: PlannerService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService.user$.subscribe(user => {
      if (this.note) {
        this.isNew = false;
      } else {
        this.note = new Note();
        this.note.addedBy = { username: user.username, name: user.firstName + ' ' + user.lastName };
        this.note.ownedBy = user.username;
        this.isNew = true;
      }
    });
  }

  save() {
    this.activeModal.close(this.note);
  }
}
