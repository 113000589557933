<app-loading *ngIf="isLoading"></app-loading>

<div [hidden]="isLoading">
  <div class="p-5" *ngIf="viewState === 'subscribe'">
    <h2>
      Thanks for giving the <span style="display: inline-block">{{ mealPlan?.name }}</span> meal
      plan a&nbsp;try!
    </h2>
    <h5 class="py-4">
      Your plan has expired, but you can keep getting access to your meal plan with a paid
      subscription.
    </h5>
    <button (click)="choosePlan()" appButton>Subscribe now</button>
  </div>

  <div class="py-3 py-md-5 px-2 px-md-4 text-center" *ngIf="viewState === 'trial-upgrade'">
    <!--<h2>Thank you for signing up for <span style="display: inline-block">Living Plate Rx!</span></h2>-->
    <h3 class="text-center">Let's make life delicious, together!</h3>
    <p class="text-center">
      <strong>Get ready to reach your health goals!</strong>
    </p>

    <div class="row py-2 py-md-4 text-center">
      <div class="col-6 col-md-3 pb-1">
        <img
          src="/assets/img/icon/cutting-board.svg"
          width="65%"
          style="transform: rotate(41.75deg) scale(1.19)"
        />
        <br /><br />Personalize your&nbsp;plan
      </div>
      <div class="col-6 col-md-3 pb-1">
        <img src="/assets/img/icon/calculator.svg" width="62%" />
        <br /><br />View nutrition information
      </div>
      <div class="col-6 col-md-3 pb-1">
        <img src="/assets/img/icon/meal-plan.svg" width="62%" />
        <br /><br />Generate your grocery&nbsp;list
      </div>
      <div class="col-6 col-md-3 pb-1">
        <img src="/assets/img/icon/nutritionist.svg" width="65%" />
        <br /><br />Work with us to meet your health goals
      </div>
    </div>

    <div class="row">
      <div class="col mb-2">
        <a (click)="closeUpgradePrompt()" class="btn btn-outline-primary btn-lg btn-block"
          >Continue to Meal Plan</a
        >
        <!--            <a routerLink="/planner" class="btn btn-outline-primary btn-lg btn-block">Continue to Free Trial</a>-->
        <small class="text-muted pt-2">(no credit card required)</small>
      </div>
      <div class="col mb-2">
        <button (click)="choosePlan()" appButton fullWidth>
          Subscribe Now
        </button>
        <small class="text-muted pt-2">(view subscription options)</small>
      </div>
    </div>
  </div>

  <div class="py-3 py-md-5 p-md-3 buy-component" *ngIf="viewState === 'choose'">
    <h2>Choose your plan</h2>
    <div class="text-center">
      <a *ngIf="showUpdatePaymentMethodLink" routerLink="/update-payment-method" class="text-muted"
        >Need to update your payment method?</a
      >
    </div>
    <br />
    <table
      id="plan-comparison"
      [class.show-static]="showStaticOption"
      [class.show-custom]="showCustomOption"
    >
      <thead>
        <tr>
          <td width="25%">
            <h5>Weekly<br />Static Plans</h5>
          </td>

          <td width="50%"></td>
          <td width="25%">
            <h5>Weekly<br />Custom Plans</h5>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><i class="fa fa-check"></i></td>
          <td>Recipes</td>
          <td><i class="fa fa-check"></i></td>
        </tr>

        <tr>
          <td><i class="fa fa-check"></i></td>
          <td>Printable Grocery List</td>
          <td><i class="fa fa-check"></i></td>
        </tr>

        <tr>
          <td><i class="fa fa-check"></i></td>
          <td>Nutrition Facts</td>
          <td><i class="fa fa-check"></i></td>
        </tr>

        <tr>
          <td><i class="fa fa-close"></i></td>
          <td>Recipe Search</td>
          <td><i class="fa fa-check"></i></td>
        </tr>

        <tr>
          <td><i class="fa fa-close"></i></td>
          <td>Add & Remove Recipes</td>
          <td><i class="fa fa-check"></i></td>
        </tr>

        <tr>
          <td><i class="fa fa-close"></i></td>
          <td>Save changes to the # of servings</td>
          <td><i class="fa fa-check"></i></td>
        </tr>

        <tr>
          <td><i class="fa fa-close"></i></td>
          <td>Your healthcare professional can edit and make recommendations.</td>
          <td><i class="fa fa-check"></i></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <p>
              <strong>{{ plans.static.price | pricingWithCurrency: currency }}</strong>
            </p>
            <button type="button" appButton btnSmall fullWidth (click)="chosePlan('static')">
              Choose <span class="d-none d-inline-md">Static</span>
            </button>
          </td>
          <td></td>
          <td>
            <p>
              <strong>{{ plans.custom.price | pricingWithCurrency: currency }}</strong>
            </p>
            <button type="button" appButton btnSmall fullWidth (click)="chosePlan('custom')">
              Choose <span class="d-none d-inline-md">Custom</span>
            </button>
          </td>
        </tr>
      </tfoot>
    </table>

    <div *ngIf="initialViewState === 'trial-upgrade'">
      <a (click)="closeUpgradePrompt()" class="btn btn-link">Cancel</a>
    </div>
  </div>

  <div class="py-5 p-3" [class.d-none]="viewState !== 'purchase'">
    <h2>
      {{ chosenPlan.name }}<br />(<span
        [innerHTML]="netPrice(chosenPlan.price, chosenPlan.plannerType)"
      ></span
      >/month)
    </h2>
    <form #checkoutForm="ngForm" (ngSubmit)="buy($event)" class="checkout">
      <input type="hidden" [(ngModel)]="chosenPlan.code" name="planCode" />
      <p class="text-muted">Please enter your payment details below.</p>

      <app-credit-card-form #ccForm [showCardSelect]="false"></app-credit-card-form>

      <data *ngIf="planConfig?.canAcceptPayments" class="mb-3">
        <div
          *ngIf="promoCodeError"
          class="class=&quot;border border-danger p-1 text-danger mb-3 ng-star-inserted&quot;"
        >
          {{ promoCodeError }}
        </div>
        <div
          *ngIf="promoCodeApplied"
          class="class=&quot;border border-success p-1 text-success mb-3 ng-star-inserted&quot;"
        >
          {{ promoCodeMessage }}
        </div>

        <div *ngIf="!promoCodeApplied" class="text-center">
          <div class="form-inline" style="display: block">
            Promo Code
            <input type="text" name="promoCode" class="form-control" [(ngModel)]="promoCode" />
            <button type="button" (click)="applyPromoCode()" btnSecondary>
              Apply
            </button>
          </div>
        </div>
      </data>

      <div id="card-errors" role="alert"></div>

      <div *ngIf="purchaseError" class="alert alert-danger">{{ purchaseError }}</div>

      <div class="text-center">
        <p>
          <small
            >All plans auto renew until you cancel. By continuing you agree to our
            <a href="/terms" target="_blank">Terms of Use</a>.
          </small>
        </p>

        <p>
          <button
            [disabled]="!checkoutForm.form.valid || isProcessing"
            [innerHtml]="isProcessing ? 'Just a moment.' : 'Subscribe Now'"
            appButton
            type="submit"
          ></button>
        </p>

        <ng-container *ngIf="enableChoosePlan">
          <p>
            <br />
            <a [class.hidden]="isProcessing" (click)="viewState = 'choose'" class="text-muted"
              >Choose a different plan</a
            >
          </p>
        </ng-container>

        <div *ngIf="initialViewState === 'trial-upgrade'">
          <br />
          <a (click)="closeUpgradePrompt()" class="btn btn-link">Cancel</a>
          <!--                <a routerLink="/planner" class="btn btn-link">Cancel</a>-->
        </div>
      </div>
    </form>
  </div>

  <div class="my-5 py-5 p-3" *ngIf="viewState == 'thankyou'">
    <h3>Thank you for purchasing a subscription!</h3>

    <h5 class="my-4">
      We're glad that you chose to continue with your meal plan with us in order to meet your health
      goals.
    </h5>

    <p class="my-2">
      A confirmation email is on its way to your email address. <br />As a reminder you will be
      charged {{ chosenPlan.price | pricingWithCurrency: this.currency }} every month, until you
      cancel.
    </p>

    <p class="my-4">
      <a href="/planner" target="_top" appButton>
        Let me see my meal plan, <span title="So polite!">please</span>.
      </a>
    </p>
  </div>
</div>
