import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { Observable } from 'rxjs/Observable';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { DistributorPlanConfig } from '../../lprx-shared-lib/entities/distributor-config';
import { MealPlan } from '../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { Plans } from '../../lprx-shared-lib/entities/plans';
import { Api } from '../../providers/aws.api';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';

@Injectable({
  providedIn: 'root'
})
export class LandingPageComponentService {
  constructor(private api: Api, private lprxApi: LprxApiProvider) {}

  getDistributor(vanityUrl: string): Observable<Distributor> {
    return fromPromise(this.lprxApi.distributors.get(vanityUrl));
  }

  getMealPlan(distVanityId: string, vId: string): Observable<MealPlan> {
    return fromPromise(this.lprxApi.mealPlans.get(vId, distVanityId));
  }

  getPlans(distributorId: string, mpVId: string): Observable<Plans> {
    return fromPromise(this.lprxApi.get(`v3/plans/${distributorId}`));
  }

  getDistConfig(distributorId: string): Observable<DistributorPlanConfig> {
    return fromPromise(this.lprxApi.get(`v3/distributor-config/${distributorId}`));
  }

  requestAccess() {
    return of({});
  }
}
