<div class="modal-header">
    <h4 class="modal-title">Note</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-info">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-6 text-left">
                {{mealLocation.dayName | dayDisplay:mealLocation.weekNumber:'ddd, MMM D'}}
                &ndash; {{mealLocation.mealName | titlecase}}
            </div>
            <div class="col-md-6 text-right">
                <ng-container *ngIf="addedByName">From: {{addedByName}}</ng-container>
            </div>
        </div>
    </div>
</div>
<div class="modal-body" [innerHtml]="note.message"></div>
<div class="modal-footer">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-6 text-left">
                <button type="button" class="btn btn-link text-danger" (click)="delete()">Remove</button>
            </div>
            <div class="col-md-6 text-right">
                <button *ngIf="canEdit" type="button" class="btn btn-link" (click)="activeModal.close('edit')">
                    Edit
                </button>
                <button type="button" appButton (click)="activeModal.dismiss()">Close</button>
            </div>
        </div>
    </div>
</div>
