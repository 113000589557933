<ng-container *ngIf="nutrients">
  <div *ngIf="showDisclaimer" class="disclaimer">
    <p>
      Source: Nutrient data for this listing was provided by USDA Food Composition Database.
      <span style="display: inline-block">Each "~" indicates a missing or incomplete value.</span>
    </p>
    <p>
      Nutrition information for recipes is calculated by retrieving and compiling individual food
      data from the USDA database. Factors such as brand selection, size of produce, and preparation
      can change nutrition information in any recipe. We offer this information as an estimate only.
    </p>
  </div>

  <div class="nutrition-facts bg-white">
    <div class="row align-items-center">
      <div class="col-12 col-md-2 col-print-2" style="align-self: start">
        <div class="row">
          <div class="col">
            <h3 class="heavy">
              <strong
                >Nutrition
                <br class="d-none d-sm-inline d-print-inline" />
                Facts</strong
              >
            </h3>
          </div>
        </div>

        <div *ngIf="servings > 0" class="servings-data">{{ servings }} servings</div>

        <div class="calories-data">
          <div class="row no-gutters">
            <div class="col-7">
              <h5><strong>Calories</strong></h5>
              <small>per serving</small>
            </div>
            <div class="col-5 text-right">
              <h3 *ngIf="nutrients.ENERC_KCAL">
                <strong>{{ nutrients.ENERC_KCAL.value.toFixed(0) }}</strong>
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-8 col-print-8">
        <div class="row" style="">
          <div class="col-12 col-md-6 col-print-6 nutritional-data-table">
            <div class="row table-top">
              <div class="col-6">
                <small>Amount/serving</small>
              </div>
              <div class="col-6 text-right">
                <small>% Daily Value*</small>
              </div>
            </div>
            <div class="nutritional-data-table-values">
              <div class="row">
                <div class="col-10">
                  <strong>Total Fat</strong> {{ nutrients.FAT | nutrientValue: 1 }}
                </div>
                <div class="col-2 text-right">
                  {{ nutrients.FAT ? percentDaily(nutrients.FAT.value, 65) : '0%' }}
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  &nbsp;&nbsp;&nbsp;&nbsp; Saturated Fat {{ nutrients.FASAT | nutrientValue: 1 }}
                </div>
                <div class="col-2 text-right daily-percentage">
                  {{ nutrients.FASAT ? percentDaily(nutrients.FASAT.value, 20) : '0%' }}
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  &nbsp;&nbsp;&nbsp;&nbsp; <em>Trans</em> Fat
                  {{ nutrients.FATRN | nutrientValue: 1 }}
                </div>
                <div class="col-2 text-right daily-percentage"></div>
              </div>
              <div class="row">
                <div class="col-10">
                  <strong>Cholesterol</strong> {{ nutrients.CHOLE | nutrientValue }}
                </div>
                <div class="col-2 text-right daily-percentage">
                  {{ nutrients.CHOLE ? percentDaily(nutrients.CHOLE.value, 300) : '0%' }}
                </div>
              </div>
              <div class="row">
                <div class="col-10"><strong>Sodium</strong> {{ nutrients.NA | nutrientValue }}</div>
                <div class="col-2 text-right daily-percentage">
                  {{ nutrients.NA ? percentDaily(nutrients.NA.value, 2300) : '0%' }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-print-6 nutritional-data-table">
            <div class="row table-top d-none d-md-flex d-print-flex">
              <div class="col-6">
                <small>Amount/serving</small>
              </div>
              <div class="col-6 text-right">
                <small>% Daily Value*</small>
              </div>
            </div>
            <div class="nutritional-data-table-values">
              <div class="row">
                <div class="col-10">
                  <strong>Total Carbohydrates</strong> {{ nutrients.CHOCDF | nutrientValue }}
                </div>
                <div class="col-2 text-right">
                  {{ nutrients.CHOCDF ? percentDaily(nutrients.CHOCDF.value, 300) : '0%' }}
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  &nbsp;&nbsp;&nbsp;&nbsp; Dietary Fiber {{ nutrients.FIBTG | nutrientValue }}
                </div>
                <div class="col-2 text-right daily-percentage">
                  {{ nutrients.FIBTG ? percentDaily(nutrients.FIBTG.value, 25) : '0%' }}
                </div>
              </div>
              <div class="row">
                <div class="col-10">
                  &nbsp;&nbsp;&nbsp;&nbsp; Total Sugars {{ nutrients.SUGAR | nutrientValue }}
                </div>
                <div class="col-2 text-right"></div>
              </div>
              <div class="row pl-3" *ngIf="nutrients.SUGAR_ADD?.value > 0">
                <div class="col-10">
                  Includes {{ nutrients.SUGAR_ADD | nutrientValue }} Added Sugars
                </div>
                <div class="col-2 text-right">
                  {{ nutrients.SUGAR_ADD ? percentDaily(nutrients.SUGAR_ADD.value, 50) : '0%' }}
                </div>
              </div>
              <div class="row" *ngIf="!nutrients.SUGAR_ADD || nutrients.SUGAR_ADD.value === 0">
                <div class="col-10">&nbsp;</div>
                <div class="col-2 text-right"></div>
              </div>
              <div class="row">
                <div class="col-10">
                  <strong>Protein</strong> {{ nutrients.PROCNT | nutrientValue }}
                </div>
                <div class="col-2 text-right daily-percentage"></div>
              </div>
            </div>
          </div>
        </div>

        Vitamin&nbsp;D&nbsp;{{ nutrients.VITD | nutrientValue }}&nbsp;{{
          nutrients.VITD ? percentDaily(nutrients.VITD.value, 10) : '0%'
        }}
        &middot; Calcium&nbsp;{{ nutrients.CA | nutrientValue }}&nbsp;{{
          nutrients.CA ? percentDaily(nutrients.CA.value, 1000) : '0%'
        }}
        &middot; Iron&nbsp;{{ nutrients.FE | nutrientValue }}&nbsp;{{
          nutrients.FE ? percentDaily(nutrients.FE.value, 18) : '0%'
        }}
        &middot; Potassium&nbsp;{{ nutrients.K | nutrientValue }}&nbsp;{{
          nutrients.K ? percentDaily(nutrients.K.value, 4700) : '0%'
        }}
      </div>

      <div class="col-12 col-md-2 col-print-2">
        <small class="daily-info"
          >* The percent Daily Value (DV) tells you how much a nutrient in a serving of food
          contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.
        </small>
      </div>
    </div>
  </div>

  <ng-container *ngIf="enableShowAllNutrients">
    <div *ngIf="nutrients" class="text-center">
      <a (click)="toggleShowAllNutrients()">
        - {{ showAllNutrients ? 'Hide' : 'Show' }} all nutrients -
      </a>
      <br />
    </div>

    <div *ngIf="showAllNutrients" class="all-nutrients">
      <br />
      <div class="text-center">
        <small><strong>Values are per serving</strong></small>
      </div>
      <div class="row">
        <div class="col-md-4 nutrient" *ngFor="let n of nutrientTypes">
          <div class="row align-items-center">
            <div class="col-9">{{ n.name }}</div>
            <div class="col-3" style="text-align: right">
              {{ nutrients[n.tagname] | nutrientValue }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
