import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

class Version {
  version: string;
}

@Injectable()
export class VersionCheckerService {
  v: Version;

  constructor(
    private http: HttpClient,
    // private cookieService: CookieService,
    private toastr: ToastrService,
    private zone: NgZone,
    private sanitizer: DomSanitizer
  ) {
    this.checkVersion();
    this.zone.runOutsideAngular(() => {
      setInterval(() => this.zone.run(() => this.checkVersion()), 60000);
    });
  }

  getVersion(): Observable<Version> {
    return this.http.get<Version>('/assets/version.json?' + Date.now());
  }

  checkVersion() {
    this.getVersion().subscribe(v => {
      if (!this.v) {
        console.log(`Setting the version to ${v.version}`);
        sessionStorage.setItem('_version', v.version.toString());
        // this.cookieService.put('_version', v.version.toString());
        // this.cookieService.put('\n', v.version.toString());
        this.v = v;
      } else if (v.version !== this.v.version) {
        console.log(`Version ${this.v.version} does not match ${v.version}.`);
        sessionStorage.setItem('_versionNext', v.version.toString());
        // this.cookieService.put('_versionNext', v.version.toString());

        // todo: Do only if logged in?
        // todo: Make the message a component...so we can do this
        //   <a href="javascript:window.reload()">Refresh the page</a>
        //   that's if we have to.
        //   const refresh = this.sanitizer.bypassSecurityTrustUrl('javascript:window.reload()');
        this.toastr.warning(
          'Refresh the page to get the newest tasty features and fixes.',
          'We cooked up a new version!',
          { enableHtml: true, timeOut: 15000 }
        );
      } else {
        // console.log(`Version ${this.v.version} matches ${v.version}.`);
      }
    });
  }
}
