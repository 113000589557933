import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
// noinspection TypeScriptCheckImport
import { create, formatDependencies, fractionDependencies, numberDependencies } from 'mathjs';
import { UnitSystem } from '../../lprx-shared-lib/enum/unit-system';
import { Ingredient } from '../../lprx-shared-lib/entities/recipe/Ingredient';
import { ingredientFormatter } from '../../lprx-shared-lib/format-ingredient';

const { fraction, format, number } = create(
  {
    fractionDependencies,
    formatDependencies,
    numberDependencies,
  },
  {}
);

@Pipe({
  name: 'ingredientDisplay',
})
export class IngredientDisplayPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}

  /**
   * Transform the ingredient into a displayable string
   *
   * This function is closely replicated in function formatIngredient()
   * in lprx-shared-lib/format-ingredient.ts, the functions should be combined in some way.
   *
   * @param {Ingredient} ingredient
   * @param {number} serves
   * @param {number} userServings
   * @param {boolean} itemNameFirst
   * @param {UnitSystem} unitSystem
   * @returns {any}
   */

  transform(
    ingredient: Ingredient,
    serves: number = 1,
    userServings = 0,
    itemNameFirst = false,
    unitSystem: UnitSystem | null = null,
    roundUp = false
  ) {
    const fractionSpacing = '&nbsp;&nbsp;';
    const metricUnitSpace = '&nbsp;';

    const output = ingredientFormatter(
      ingredient,
      unitSystem,
      roundUp,
      userServings,
      serves,
      fractionSpacing,
      metricUnitSpace,
      itemNameFirst
    );
    return this.sanitized.bypassSecurityTrustHtml(output);
  }
}
