import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { FavoriteRecipesModule } from '../../favorite-recipes/favorite-recipes.module';
import { NutritionFactsLabelModule } from '../../nutrition-facts-label/nutrition-facts-label.module';
import { PipesModule } from '../../pipe/pipes.module';
import { ContributorsService } from '../../service/contributors.service';
import { UnitSystemToggleModule } from '../../unit-system-toggle/unit-system-toggle.module';
import { RecipeViewComponent } from './recipe-view.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    NgbModalModule,
    NutritionFactsLabelModule,
    NgbDropdownModule,
    UnitSystemToggleModule,
    FavoriteRecipesModule
  ],
  declarations: [RecipeViewComponent],
  exports: [RecipeViewComponent],
  providers: [ContributorsService]
})
export class RecipeViewerModule {}
