<div
  #searchTool
  class="search-tool pt-2"
  [class.px-1]="isTablet"
>
  <div class="input-group">
    <input
      [(ngModel)]="term"
      (ngModelChange)="changed($event)"
      id="search-input"
      class="form-control"
      placeholder="Search"
    />

    <a
      [ngbPopover]="popContent"
      #filterPopover="ngbPopover"
      [popoverTitle]="popTitle"
      [autoClose]="'outside'"
      class="input-group-append"
      title="filter"
      popoverClass="filter-popover"
    >
      <div class="input-group-text">
        <i class="fa fa-filter" [class.text-success]="filterApplied"></i>
      </div>
    </a>
  </div>

  <p class="text-center mb-0 pb-0" [style.visibility]="recipes?.length ? 'visible' : 'hidden'">
    <small>
      <ng-container *ngIf="layout.isWide$ | async">
        Select a recipe to view, or drag it on to the planner.
      </ng-container>
      <ng-container *ngIf="!(layout.isWide$ | async)">
        Select a recipe to view and add it to the planner.
      </ng-container>
    </small>
  </p>
</div>

<ng-container *ngIf="(layout.isWide$ | async) && recipes?.length > 0">
  <div data-style="rr" [attr.data-tick]="tick">
    <div
      id="search-results"
      cdkDropList
      cdkDropListSortingDisabled
      [cdkDropListEnterPredicate]="enterPredicate"
      *ngIf="recipes"
    >
      <ng-container *ngFor="let recipe of recipes">
        <div
            (mouseenter)='removeDragHandle = true'
            (mouseleave)='removeDragHandle = false'
          cdkDrag
          (cdkDragStarted)="dragStarted($event, recipe)"
          (cdkDragEnded)="dragEnded($event, recipe)"
          [cdkDragData]="recipe"
          [cdkDragStartDelay]="dragDropDelayTimeout"
          style="position: relative"
        >
          <app-recipe-search-result *ngIf="recipe" [recipe]="recipe" id="recipe-{{ recipe?.id }}">
          </app-recipe-search-result>

          <div
              *ngIf='isTablet && !removeDragHandle'
              cdkDragHandle
              class=''
              style="
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              background: url('/assets/img/drag-handle.png') bottom right no-repeat;
              background-size: cover;
              height: 32px;
              width: 32px;
            "
          ></div>

          <!--          <div-->
          <!--            *cdkDragPlaceholder-->
          <!--            class="placeholder"-->
          <!--            style="-->
          <!--              background: #ccc;-->
          <!--              border: dotted 3px #999;-->
          <!--              min-height: 12px;-->
          <!--              margin-bottom: 4px;-->
          <!--              transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);-->
          <!--              opacity: 1;-->
          <!--            "-->
          <!--          ></div>-->

          <div *cdkDragPlaceholder>
            <app-recipe-drag
              [recipe]="recipe"
              style="
                position: relative;
                display: block;
                max-width: 100%;
                background: white;
                border: 3px dashed rgb(140, 140, 140);
                box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                opacity: 0.5;
                margin-bottom: 4px;
              "
            ></app-recipe-drag>
          </div>

          <ng-container *ngIf="recipe?.image">
            <div *cdkDragPreview>
              <div
                style="
                  position: relative;
                  display: block;
                  left: -150px;
                  top: -30px;
                  width: 200px;
                  background: white;
                  border: 1px solid rgb(56, 56, 56);
                  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
                  transform: scale(0.5);
                "
              >
                <div class="row align-items-center no-gutters">
                  <div class="col-auto">
                    <img [src]="recipe.image | cdn: 40:40:false" [alt]="recipe.title" />
                  </div>
                  <div class="col px-1" style="font-size: 10px">
                    {{ recipe.title }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <p class="text-center">
      <br />
      <i *ngIf="isProcessing" class="fa fa-spinner fa-spin text-secondary"></i>
      <button
        *ngIf="showLoadMore"
        (click)="loadMore()"
        type="button"
        appButton btnSmall
      >
        Show me more
      </button>
    </p>
  </div>
</ng-container>

<ng-container *ngIf="layout.isNarrow$ | async">
  <div [style.padding-top]="searchToolHeight">
    <ng-container *ngFor="let recipe of recipes">
      <div
        (click)="openRecipe(recipe)"
        class="row align-items-center no-gutters border-bottom-dashed"
      >
        <div class="col-4">
          <img
            [src]="recipe.image | cdn: 800:800:false"
            style="max-width: 100%"
            [alt]="recipe.title"
          />
        </div>
        <div class="col-8">
          <div class="p-3 recipe-title">
            {{ recipe.title }}
          </div>
        </div>
      </div>
    </ng-container>

    <p class="text-center">
      <br />
      <i *ngIf="isProcessing" class="fa fa-spinner fa-spin text-secondary"></i>
      <button
        type="button"
        appButton btnSmall
        (click)="loadMore()"
        *ngIf="showLoadMore"
      >
        Show me more
      </button>
    </p>
  </div>
</ng-container>

<div *ngIf="!isProcessing && recipes && recipes.length == 0">
  <div class="alert alert-warning">
    <h5 class="text-center">
      <ng-container *ngIf="term">
        Sorry, we could not find any recipes with '<strong>{{ term }}</strong
        >'.
      </ng-container>
      <ng-container *ngIf="filterApplied"> Please check your filters. </ng-container>
    </h5>
  </div>
</div>

<ng-template #popTitle>
  <div class="row no-gutters text-center align-items-center">
    <div class="col-3">
      <button
        type="button"
        (click)="resetFilters()"
        class="btn btn-block btn-xs btn-outline-secondary"
      >
        Reset
      </button>
    </div>
    <div class="col">
      <strong>Filters</strong>
    </div>
    <div class="col-3">
      <button
        type="button"
        (click)="filterPopover.close()"
        class="btn btn-block btn-xs btn-outline-primary"
      >
        Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #popContent>
  <div style="max-height: 400px; overflow-y: scroll">
    <div class="list-group">
      <div
        *ngIf="user?.isAdmin"
        (click)="toggleSearchAll()"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        <label>
          Search All Meal Plans
          <i class="fa" [class.fa-square-o]="!searchAll" [class.fa-check-square-o]="searchAll"></i>
        </label>
      </div>

      <div class="list-group-item">
        <strong>Meal Types</strong>
      </div>

      <label
        *ngFor="let mealType of mealTypes"
        [class.text-primary]="selectedMealTypes.includes(mealType)"
        (click)="filterMealType($event, mealType)"
        class="list-group-item d-flex justify-content-between align-items-center"
      >
        &nbsp;&nbsp;{{ mealType }}

        <i
          class="fa"
          [class.fa-square-o]="!selectedMealTypes.includes(mealType)"
          [class.fa-check-square-o]="selectedMealTypes.includes(mealType)"
        ></i>
      </label>

      <label
        *ngIf="showClientFilters"
        class="list-group-item d-flex justify-content-between align-items-center"
        (click)="toggleOnlyFavorites()"
        [class.text-success]="onlyFavorites"
      >
        Favorites

        <i
          class="fa"
          [class.fa-square-o]="!onlyFavorites"
          [class.fa-check-square-o]="onlyFavorites"
        ></i>
      </label>

      <label
        *ngIf="showClientFilters"
        (click)="toggleOnlyMyRecipe()"
        class="list-group-item d-flex justify-content-between align-items-center"
        [class.text-success]="onlyMine"
      >
        My Recipes

        <i class="fa" [class.fa-square-o]="!onlyMine" [class.fa-check-square-o]="onlyMine"></i>
      </label>

      <ng-container *ngIf="plannerConfig.showNutritionFacts">
        <div class="list-group-item">
          <strong>Macronutrients</strong>
        </div>

        <label *ngFor="let macroOption of macroOptions" class="list-group-item align-items-center">
          <div class="row align-items-center no-gutters">
            <div class="col-6">
              &nbsp;{{ macroOption.title }}
              <br />
              &nbsp;{{ macroOption.low }} - {{ macroOption.highDisplay }}
            </div>
            <div class="col-6">
              <ngx-slider
                [(value)]="macroOption.low"
                [(highValue)]="macroOption.high"
                (userChangeEnd)="onUserChangeEnd($event, macroOption)"
                (userChange)="onUserChange($event, macroOption)"
                [options]="{
                  floor: macroOption.floor,
                  ceil: macroOption.ceil,
                  step: macroOption.step
                }"
              >
              </ngx-slider>
            </div>
          </div>
        </label>
      </ng-container>
    </div>
  </div>
</ng-template>
