import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { alertModal } from '../../../utilities/sweet-alert/sweet-alert';

@Injectable()
export class PremiumAlertModalService {
  constructor(private readonly router: Router) {}

  show(html: string) {
    return alertModal({
      title: 'Sorry',
      icon: 'warning',
      html,
      showCancelButton: true,
      confirmButtonText: 'Learn More',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.value) {
        this.router.navigateByUrl('/distributor/prime/purchase/info?renew=true');
      }
    });
  }
}
