<div class=" container py-5">
    <div class=" row">
        <div class=" col-lg-8 mx-auto">
            <h2 style="text-align: center;"><u>Privacy Policy</u></h2>

            <p>Last modified: March 31, 2018</p>


            <p><a name="a598115">Introduction</a></p>

            <p>Living Plate RX LLC (collectively, the &quot;<u>Company</u>&quot; or&quot;<u>We</u>&quot;)
                respect your privacy and are committed to protecting it through our compliance with this policy. This
                policy describes the types of information we may collect from you or that you may provide when you visit
                the website www.livingplaterx.com (our &quot;<u>Website</u>&quot;) and our practices for collecting,
                using, maintaining, protecting, and disclosing that information. This policy applies to information we
                collect: (A) on this Website; (B) in email, text, and other electronic messages between you and this
                Website; and (C) when you interact with our advertising and applications on third-party websites and
                services, if those applications or advertising include links to this policy.</p>

            <p>It does not apply to information collected by: (A) us offline or through any other means, including on
                any other website operated by Company or any third party; or (B) any third party, including through any
                application or content (including advertising) that may link to or be accessible from or on the
                Website.</p>

            <p>Please read this policy carefully to understand our policies and practices regarding your information and
                how we will treat it. If you do not agree with our policies and practices, your choice is not to use our
                Website. By accessing or using this Website, you agree to this privacy policy. This policy may change
                from time to time (see&nbsp;Changes to Our Privacy Policy). Your continued use of this Website after we
                make changes is deemed to be acceptance of those changes, so please check the policy periodically for
                updates.&nbsp;</p>

            <p><a name="a1019988">Children Under the Age of 13</a></p>

            <p>Our Website is not intended for children under 13 years of age. No one under age 13 may provide any
                information to or on the Website. We do not knowingly collect personal information from children under
                13. If you are under 13, do not use or provide any information on this Website or on or through any of
                its features/register on the Website, make any purchases through the Website, use any of the interactive
                or public comment features of this Website or provide any information about yourself to us, including
                your name, address, telephone number, email address, or any screen name or user name you may use. If we
                learn we have collected or received personal information from a child under 13 without verification of
                parental consent, we will delete that information. If you believe we might have any information from or
                about a child under 13, please contact us at <a
                    href="mailto:info@livingplaterx.com">info@livingplaterx.com</a>.&nbsp;</p>

            <p><a name="a216371">Information We Collect About You and How We Collect It</a></p>

            <p>We collect several types of information from and about users of our Website, including information: (A)
                by which you may be personally identified, such as name, postal address, e-mail address, telephone
                number, any other identifier by which you may be contacted online or offline (&quot;<u>personal
                    information</u>&quot;); and (B) about your internet connection, the equipment you use to access our
                Website and usage details.</p>

            <p>We may collect this information: (A) Directly from you when you provide it to us; and (B) Automatically
                as you navigate through the site. Information collected automatically may include usage details, IP
                addresses, and information collected through cookies, web beacons, and other tracking technologies.</p>

            <p><a name="a782263">Information You Provide to Us</a>. &nbsp;The information we collect on or through our
                Website may include:&nbsp;information that you provide by filling in forms on our Website. This includes
                information provided at the time of registering to use our Website, subscribing to our service, or
                requesting further services. We may also ask you for information when you report a problem with our
                Website. This may also include: (A) &nbsp;records and copies of your correspondence (including email
                addresses), if you contact us; (B) details of transactions you carry out through our Website and of the
                fulfillment of your orders. You may be required to provide financial information before placing an order
                through our Website; and (C) Your search queries on the Website.</p>

            <p><a name="a683129">Information We Collect Through Automatic Data Collection Technologies</a>. &nbsp;As you
                navigate through and interact with our Website, we may use automatic data collection technologies to
                collect certain information about your equipment, browsing actions, and patterns, including:&nbsp;(A)
                Details of your visits to our Website, including traffic data, location data and other communication
                data and the resources that you access and use on the Website; and (B) Information about your computer
                and internet connection, including your IP address, operating system, and browser type.</p>

            <p>We also may use these technologies to collect information about your online activities over time and
                across third-party websites or other online services (behavioral tracking). Click here [____] for
                information on how you can opt out of behavioral tracking on this website and how we respond to web
                browser signals and other mechanisms that enable consumers to exercise choice about behavioral
                tracking.</p>

            <p>The information we collect automatically [does not/may] include personal information[, but/or] we may
                maintain it or associate it with personal information we collect in other ways or receive from third
                parties. It helps us to improve our Website and to deliver a better and more personalized service,
                including by enabling us to: (A) estimate our audience size and usage patterns; (B) store information
                about your preferences, allowing us to customize our Website according to your individual interests; (C)
                speed up your searches; (D) recognize you when you return to our Website.</p>

            <p>The technologies we use for this automatic data collection may include:</p>

            <p>
                <br>
            </p>

            <p><strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your
                computer. You may refuse to accept browser cookies by activating the appropriate setting on your
                browser. However, if you select this setting you may be unable to access certain parts of our Website.
                Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue
                cookies when you direct your browser to our Website.&nbsp;</p>

            <p><strong>Flash Cookies.</strong> Certain features of our Website may use local stored objects (or Flash
                cookies) to collect and store information about your preferences and navigation to, from, and on our
                Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For
                information about managing your privacy and security settings for Flash cookies, see&nbsp;Choices About
                How We Use and Disclose Your Information.</p>

            <p><strong>Web Beacons.</strong> Pages of our the Website&nbsp;and our e-mails may&nbsp;contain small
                electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who have visited those pages or opened an
                email and for other related website statistics (for example, recording the popularity of certain website
                content and verifying system and server integrity).&nbsp;</p>

            <p>We do not collect personal information automatically, but we may tie this information to personal
                information about you that we collect from other sources or you provide to us.</p>

            <p><a name="a612118">Third-Party Use of Cookies and Other Tracking Technologies.</a></p>

            <p>Some content or applications, including advertisements, on the Website are served by third-parties,
                including advertisers, ad networks and servers, content providers, and application providers. These
                third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to
                collect information about you when you use our website. The information they collect may be associated
                with your personal information or they may collect information, including personal information, about
                your online activities over time and across different websites and other online services. They may use
                this information to provide you with interest-based (behavioral) advertising or other targeted content.&nbsp;</p>

            <p>We do not control these third parties&#39; tracking technologies or how they may be used. If you have any
                questions about an advertisement or other targeted content, you should contact the responsible provider
                directly. For information about how you can opt out of receiving targeted advertising from many
                providers, see&nbsp;Choices About How We Use and Disclose Your Information.</p>

            <p><a name="a183824">How We Use Your Information</a></p>

            <p>We use information that we collect about you or that you provide to us, including any personal
                information: (A) to present our Website and its contents to you; (B) to provide you with information,
                products, or services that you request from us; (C) to fulfill any other purpose for which you provide
                it; (D) to provide you with notices about your account, including expiration and renewal notices; (E) to
                carry out our obligations and enforce our rights arising from any contracts entered into between you and
                us, including for billing and collection; (F) to notify you about changes to our Website or any products
                or services we offer or provide though it; (G) to allow you to participate in interactive features on
                our Website; (H) in any other way we may describe when you provide the information; and (I) for any
                other purpose with your consent.</p>

            <p>We may also use your information to contact you about our own and third-parties&#39; goods and services
                that may be of interest to you. If you do not want us to use your information in this way, please adjust
                your user preferences in your account profile. For more information, see&nbsp;Choices About How We Use
                and Disclose Your Information.</p>

            <p>We may use the information we have collected from you to enable us to display advertisements to our
                advertisers&#39; target audiences. Even though we do not disclose your personal information for these
                purposes without your consent, if you click on or otherwise interact with an advertisement, the
                advertiser may assume that you meet its target criteria.</p>

            <p><a name="a998196">Disclosure of Your Information</a>&nbsp;</p>

            <p>We may disclose aggregated information about our users, and information that does not identify any
                individual, without restriction. We may disclose personal information that we collect or you provide as
                described in this privacy policy: (A) to our subsidiaries and affiliates; (B) to contractors, service
                providers, and other third parties we use to support our business; (C) to a buyer or other successor in
                the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of the Company&rsquo;s assets, whether as a going concern or as part of
                bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company about
                our Website users is among the assets transferred; (D) to fulfill the purpose for which you provide it
                (for example, if you give us an email address to use the &quot;email a friend&quot; feature of our
                Website, we will transmit the contents of that email and your email address to the recipients); (E) or
                any other purpose disclosed by us when you provide the information; and/or (F) with your consent.</p>

            <p>We may also disclose your personal information: (A) to comply with any court order, law, or legal
                process, including to respond to any government or regulatory request; (B) to enforce or apply our terms
                of use and other agreements, including for billing and collection purposes; and/or (C) If we believe
                disclosure is necessary or appropriate to protect the rights, property, or safety of the Company our
                customers, or others.&nbsp;</p>

            <p><a name="a741158">Choices About How We Use and Disclose Your Information</a></p>

            <p>We strive to provide you with choices regarding the personal information you provide to us. We have
                created mechanisms to provide you with the following control over your information:&nbsp;</p>

            <p><strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some
                browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash
                cookie settings, visit the Flash player settings page on Adobe&#39;s website. If you disable or refuse
                cookies, please note that some parts of this site may then be inaccessible or not function properly.</p>

            <p><strong>Disclosure of Your Information for Third-Party Advertising.</strong> If you do not want us to
                share your personal information with unaffiliated or non-agent third parties for promotional purposes,
                you can opt-out by emailing <a href="mailto:info@livingplaterx.com">info@livingplaterx.com</a>. &nbsp;</p>

            <p><strong>Promotional Offers from the Company.</strong> If you do not wish to have your contact information
                used by the Company to promote our own or third parties&#39; products or services, you can opt-out by
                sending us an email stating your request to info@livingplaterx.com. If we have sent you a promotional
                email, you may send us a return email asking to be omitted from future email distributions. This opt out
                does not apply to information provided to the Company as a result of a product purchase, warranty
                registration, product service experience or other transactions.</p>

            <p><strong>Targeted Advertising.</strong> If you do not want us to use information that we collect or that
                you provide to us to deliver advertisements according to our advertisers&#39; target-audience
                preferences, you can opt-out by emailing <a href="mailto:info@livingplaterx.com">info@livingplaterx.com</a>.
                &nbsp;We do not control third parties&#39; collection or use of your information to serve interest-based
                advertising. However, these third parties may provide you with ways to choose not to have your
                information collected or used in this way. You can opt out of receiving targeted ads from members of the
                Network Advertising Initiative (&quot;<u>NAI</u>&quot;) on the NAI&#39;s website.</p>

            <p><a name="a87692">Accessing and Correcting Your Information</a></p>

            <p>You can review and change your personal information by logging into the Website and visiting your account
                profile page. &nbsp;You may also send us an email at info@livingplaterx.com to request access to, correct
                or delete any personal information that you have provided to us. We cannot delete your personal
                information except by also deleting your user account. We may not accommodate a request to change
                information if we believe the change would violate any law or legal requirement or cause the information
                to be incorrect.</p>

            <p><a name="a820658">Your California Privacy Rights</a></p>

            <p>California Civil Code Section &sect; 1798.83 permits users of our Website that are California residents
                to request certain information regarding our disclosure of personal information to third parties for
                their direct marketing purposes. To make such a request, please send an email to
                info@livingplaterx.com.</p>

            <p><a name="a539528">Data Security</a></p>

            <p>We have implemented measures designed to secure your personal information from accidental loss and from
                unauthorized access, use, alteration, and disclosure. [All information you provide to us is stored on
                our secure servers behind firewalls. Any payment transactions will be encrypted using SSL technology.]&nbsp;</p>

            <p>The safety and security of your information also depends on you. Where we have given you (or where you
                have chosen) a password for access to certain parts of our Website, you are responsible for keeping this
                password confidential. We ask you not to share your password with anyone.&nbsp;</p>

            <p>Unfortunately, the transmission of information via the internet is not completely secure. Although we do
                our best to protect your personal information, we cannot guarantee the security of your personal
                information transmitted to our Website. Any transmission of personal information is at your own risk. We
                are not responsible for circumvention of any privacy settings or security measures contained on the
                Website.</p>

            <p><a name="a286592">Changes to Our Privacy Policy</a></p>

            <p>It is our policy to post any changes we make to our privacy policy on this page. If we make material
                changes to how we treat our users&#39; personal information, we will notify you through a notice on the
                Website home page. The date the privacy policy was last revised is identified at the top of the page.
                You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and
                for periodically visiting our Website and this privacy policy to check for any changes.</p>

            <p><a name="a955470">Contact Information</a></p>

            <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at:
                info@livingplaterx.com .&nbsp;</p>

            <p>
                <br>
            </p>


        </div>
    </div>
</div>
