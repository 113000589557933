import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-upgrade-confirmation',
  templateUrl: './upgrade-confirmation.component.html',
  styleUrls: ['./upgrade-confirmation.component.scss']
})
export class UpgradeConfirmationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
