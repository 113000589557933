import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-planner-display',
  templateUrl: './planner-display.component.html',
  styleUrls: ['./planner-display.component.scss']
})
export class PlannerDisplayComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
