<!-- Generated template for the RecipeElementInputComponent component -->

<!--<div *ngIf="hasMultipleElements">-->
<!--  <div class="row align-items-center">-->
<!--    <div class="col-11">-->
<!--&lt;!&ndash;      <input [(ngModel)]="element.name" required="" class="form-control" placeholder="Element Name (e.g. Dressing)" name="element-name">&ndash;&gt;-->
<!--    </div>-->
<!--    <div class="col-1">-->
<!--      <span class=""></span>-->
<!--        <i class="material-icons element-drag-handle mover" title="Move">drag_handle</i>-->
<!--        <i *ngIf="hasMultipleElements"-->
<!--           (click)="remove()"-->
<!--           class="material-icons text-danger clickable"-->
<!--           title="Remove element">close</i>-->
<!--    </div>-->
<!--  </div>-->
<!--  <br>-->
<!--</div>-->


<app-recipe-ingredients-input [ingredients]="element.ingredients"></app-recipe-ingredients-input>
<br>
<recipe-directions-input [directions]="element.directions"></recipe-directions-input>
<br>
