import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { UserType } from '../../../lprx-shared-lib/entities/user/UserType';
import { nonCalendarStartWeek } from '../../../lprx-shared-lib/vars';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { AuthService } from '../../auth.service';
import { LayoutService } from '../../layout/layout.service';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { getCurrentDayName, getCurrentWeekNumber } from '../../utilities/getCurrentWeekNumber';
import { PlannerPrinterService } from '../planner-printer.service';
import { PlannerService } from '../planner.service';

/**
 * TODO: Clean up this component ... move logic to PlannerOutletComponent and PlannerService
 */

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
})
export class PlannerComponent implements OnInit, OnDestroy {
  weeklyPlans: WeeklyPlan[] = [];
  client: any;
  showPlanner: boolean;

  private subs: Subscription[] = [];
  enableSlideOutPlanner$: Observable<boolean>;

  constructor(
    public layout: LayoutService,
    private plannerPrinter: PlannerPrinterService,
    private plannerService: PlannerService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private lprxApi: LprxApiProvider
  ) {
    // this.isWindowWide$ = this.layout.isWindowWide$;
  }

  ngOnInit(): void {
    this.enableSlideOutPlanner$ = this.plannerService.enableSlideOutPlanner$;

    // Listen for when the print planner view is active, or not
    const printingStatusSub = this.plannerPrinter.printingStatus$.subscribe(
      () => (this.showPlanner = true)
    );
    this.subs.push(printingStatusSub);
    sessionStorage.setItem('plannerPath', location.pathname);

    const layoutSub = this.auth.user$
      .pipe(
        switchMap((user: User) => {
          if (user.userType !== UserType.Client) {
            return this.getCombineLatest(getCurrentWeekNumber());
          }
          return from(this.lprxApi.mealPlans.get(user.mealPlanId)).pipe(
            switchMap((mp: MealPlan) => {
              const weekNumber = mp.nonCalendar
                ? user.lastWeekNumberLoaded || nonCalendarStartWeek
                : getCurrentWeekNumber();
              return this.getCombineLatest(weekNumber);
            })
          );
        })
      )
      .subscribe(([isNarrowLayout, params, weekNumber]) => {
        if (isNarrowLayout && !(params && params['day'])) {
          const url = `/planner/day/${weekNumber}/${getCurrentDayName()}`;
          this.router.navigateByUrl(url, { replaceUrl: true });
        }
      });
    this.subs.push(layoutSub);
  }

  private getCombineLatest(weekNumber: string) {
    return combineLatest([this.layout.isNarrow$, this.route.params, of(weekNumber)]);
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }
}
