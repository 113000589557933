import { Pipe, PipeTransform } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Pipe({
  name: 'ngbdate'
})
export class NgbdatePipe implements PipeTransform {
  transform(date: NgbDateStruct, args?: any): any {
    return moment()
      .year(date.year)
      .month(date.month - 1)
      .date(date.day)
      .format('MMMM D');
  }
}
