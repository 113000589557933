<ng-container *ngIf='isReady; else loading'>
    <mat-sidenav-container>

        <mat-sidenav
            (closedStart)='onClosedStart()'
            #drawer
            [mode]="'over'"
            [fixedInViewport]='true'
            [position]="'end'">

            <div class='px-4 pt-0 pb-4'>
                <div class='pt-4 pb-2'
                     style='position: sticky; top: 50px; background: white; z-index: 1; margin-top: 50px'>
                    <div class='container-fluid'>
                        <div class='row align-items-center'>
                            <div class='col-auto'>
                                <div (click)='closeDrawer()' class='clickable alert alert-danger'>Close</div>
                                <!--                            <mat-icon (click)="closeDrawer()" class="clickable" title="Cancel">close</mat-icon>-->
                            </div>
                            <div class='col'>
                                <div class='alert alert-success'>
                                    Adding {{recipeToInsert ? recipeToInsert.title : ''}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class='planner-wrapper layout-compressed inserting-recipe' *ngIf='enableSlideOutPlanner$ | async'>
                    <app-planner-layout-wide></app-planner-layout-wide>
                </div>
            </div>

        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>

    </mat-sidenav-container>
</ng-container>

<ng-template #loading>
    <div class='py-5 text-center text-muted'>
        <br>
        <h2>Loading</h2>
        <i class='fa fa-spinner fa-3x fa-spin'></i>
    </div>
</ng-template>


<div class='upgrade container-fluid' *ngIf='timeToSubscribe || showClientUpgrade'>
    <div class='row'>
        <div class='col-md-8 col-xl-4 mt-lg-5 mx-auto bg-white text-center'
             style='box-shadow: 2px 2px 10px rgba(123, 123, 123, 0.5);'>
            <app-upgrade
                (closedUpgradePrompt)='onClosed($event)'
                [viewState]="showClientUpgrade ? 'trial-upgrade' : 'subscribe'">
            </app-upgrade>
        </div>
    </div>
</div>
