/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/list-result.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export class ListResult<T> {
  public readonly has_more: boolean = false;

  /**
   *
   * @param items
   * @param next_key
   * @param next A URL for the next request
   */
  constructor(
    public readonly items: T[] = [],
    public readonly next_key?: string,
    public readonly next?: string
  ) {
    this.has_more = !!this.next_key;
  }
}
