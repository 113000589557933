import { plainToClass } from 'class-transformer';
import { DistributorAccessLevel } from '../../../lprx-shared-lib/distributor-access-level';
import { FolderRenameDto } from '../../../lprx-shared-lib/resources/commands/folder-rename-dto';
import { ChangeResourceItemAccessLevel } from '../../../lprx-shared-lib/resources/dto/change-resource-item-access-level';
import { CreateResourceFolderCommand } from '../../../lprx-shared-lib/resources/dto/create-resource-folder-command';
import { ResourceFolder } from '../../../lprx-shared-lib/resources/resource-folder';
import { ResourceFolderResponse } from '../../../lprx-shared-lib/resources/resource-folder.response';
import { FolderId } from '../../../lprx-shared-lib/utils/types';
import { LprxApi } from '../api';

export class ResourceFolders {
  constructor(private readonly lprxApi: LprxApi) {}

  /**
   *
   * @param id
   * @param includeChildren
   */
  get(id: string, includeChildren: boolean = false): Promise<ResourceFolderResponse> {
    const queryParams = { include_children: includeChildren };
    return this.lprxApi
      .get(`v3/resources/folder/${id}`, queryParams)
      .then((data: ResourceFolderResponse) => {
        return plainToClass(ResourceFolderResponse, data as ResourceFolderResponse);
      });
  }

  /**
   *
   * @param command
   */
  create(command: CreateResourceFolderCommand): Promise<ResourceFolder> {
    return this.lprxApi
      .post('v3/resources/folder', command)
      .then((data: object) => plainToClass(ResourceFolder, data as object));
  }

  /**
   *
   * @param folderId
   */
  publish(folderId: string) {
    return this.lprxApi.patch(`v3/resources/folder/${folderId}/publish`);
  }

  /**
   *
   * @param folderId
   */
  unpublish(folderId: string) {
    return this.lprxApi.patch(`v3/resources/folder/${folderId}/unpublish`);
  }

  changeAccessLevel(folderId: string, level: DistributorAccessLevel) {
    const dto = plainToClass(ChangeResourceItemAccessLevel, { level });
    return this.lprxApi.patch(`v3/resources/folder/${folderId}/access`, dto);
  }

  delete(folderId: string) {
    return this.lprxApi.delete(`v3/resources/folder/${folderId}`);
  }

  rename(folderId: FolderId, name: string) {
    name = name.replace(/[^ A-Za-z0-9-_!.)(']/, '-');
    const dto = plainToClass(FolderRenameDto, { name });
    return this.lprxApi.patch(`v3/resources/folder/${folderId}/name`, dto);
  }

  getFolderTree(): Promise<ResourceFolderResponse> {
    return this.lprxApi.get('v3/resources/folders/tree');
  }
}
