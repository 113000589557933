/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/client-coupon.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { PlannerType } from './entities/weeky-meal-plan/PlannerType';

export enum ClientCouponType {
  ExtendedTrial = 'e',
  PercentOff = 'p',
  AmountOff = 'a',
}

export class ClientCoupon {
  type: ClientCouponType;
  amount: number;
  code: string;
  plannerTypes: PlannerType[];
  forMealPlans: string[];
  startsAt: number;
  expires: boolean;
  expiresAt: number;
  timezone: string;
  isDeactivated: boolean;
  stripeCouponId: string;
}

export function sanitizeCouponCode(code: string) {
  return code.replace(/[^a-z0-9]/gi, '-').trim();
}
