<ng-container *ngIf="recipe">
    <app-recipe-view
        [recipe]="recipe"
        [userServings]="card?.userServings || recipe.servings"
        [weekNumber]="weekNumber"
        [day]="day"
        [mealName]="meal"
        [action]="action"
        [card]="card"
    ></app-recipe-view>
</ng-container>
