import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { MealPlan } from '../../../lprx-shared-lib/entities/meal-plan/MealPlan';
import { User } from '../../../lprx-shared-lib/entities/user/user';
import { Day } from '../../../lprx-shared-lib/entities/weeky-meal-plan/Day';
import { WeeklyPlan } from '../../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';
import { unsubscribe } from '../../../lprx-shared-lib/utils/unsubscribe';
import { AuthService } from '../../auth.service';
import { Recipe } from '../../../lprx-shared-lib/entities/recipe/Recipe';

import { CdnService } from '../../service/cdn.service';
import { PlannerService } from '../planner.service';
import { RecipeOpenerService } from '../recipe-opener.service';

export const AUTO_CLOSE = true;

@Component({
  selector: 'app-recipe-box-result',
  templateUrl: './recipe-box-result.component.html',
  styleUrls: ['./recipe-box-result.component.scss'],
})
export class RecipeBoxResultComponent implements OnInit, OnDestroy {
  @Input() recipe: Recipe;

  @Output() addToPlanner = new EventEmitter<Recipe>();

  imageUrl: SafeUrl;
  user: User;
  private subs: Subscription[] = [];
  canView = false;
  belongsToUser = false;

  weeklyPlans: WeeklyPlan[];

  sideNavDay: Day;
  sideNavMealPlan: MealPlan;
  sideNavDayName: string;
  recipeToInsert: Recipe;

  constructor(
    private recipeOpener: RecipeOpenerService,
    private plannerService: PlannerService,
    private auth: AuthService,
    private cdn: CdnService
  ) {}

  ngOnInit() {
    this.imageUrl = this.cdn.getSafeStyleUrl(this.recipe.image, 200, 200);

    this.subs.push(
      this.getUserSubscription(),
      this.canEditSubscription(),
      this.weeklyPlansSubscription()
    );
  }

  private getUserSubscription() {
    return this.auth.getUser().subscribe((user) => {
      this.belongsToUser = this.recipe.ownedBy === user.username;
      this.user = user;
    });
  }

  private canEditSubscription() {
    return this.plannerService.getCanEdit().subscribe((canEdit) => (this.canView = canEdit));
  }

  ngOnDestroy(): void {
    unsubscribe(this.subs);
  }

  openRecipe() {
    this.recipeOpener.openRecipe(this.recipe);
  }

  openAddToPlanner($event: MouseEvent) {
    $event.stopPropagation();
    $event.preventDefault();
    this.plannerService.openInsertRecipe(this.recipe, true);
    console.log(this.recipe);
  }

  private weeklyPlansSubscription() {
    return this.plannerService.weeklyPlans$.subscribe((wps) => {
      this.weeklyPlans = wps;
      this.sideNavDay = this.weeklyPlans[0].monday;
      this.sideNavMealPlan = this.weeklyPlans[0].mealPlan;
      this.sideNavDayName = 'Monday';
    });
  }
}
