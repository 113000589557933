/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/update-meal-names.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { DaysOfTheWeek } from '../days-of-the-week';
import { WeeklyPlan } from '../entities/weeky-meal-plan/WeeklyMealPlan';

/**
 * Updates the meal names in the weekly plan to match the meal names defined in the meal plan.
 *
 * This occurs when a meal name is updated in the meal plan, and the weekly plan needs to be updated
 * because the meal with in the weekly plan has the meal plan name defined in it.
 *
 *
 * @param {WeeklyPlan} weeklyPlan
 */
export function updateMealNames(weeklyPlan: WeeklyPlan) {
  // check meal name within the day meals to see if it needs to be updated to the latest meal name from weeklyPlan.mealPlan
  for (const dayName of DaysOfTheWeek.getDaysLong(true)) {
    for (const meal of weeklyPlan[dayName].meals) {
      const mealPlanMeal = weeklyPlan.mealPlan.meals.find((m) => m.id === meal.id);
      if (mealPlanMeal && meal.name !== mealPlanMeal.name) {
        meal.name = mealPlanMeal.name;
      }
    }
  }
}
