/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/enum/card-type.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum CardType {
  Recipe = 'r',
  Note = 'n'
}
