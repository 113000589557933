import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contributor } from '../../../../../lprx-shared-lib/entities/contributor';
import { Api, Storage } from '../../../../../providers/aws.api';

@Component({
  selector: 'app-recipe-contributor-input',
  templateUrl: './recipe-contributor-input.component.html',
  styleUrls: ['./recipe-contributor-input.component.scss']
})
export class RecipeContributorInputComponent implements OnInit {
  @Input() contributedBy: string;
  @Output() updatedContributor = new EventEmitter<string>();

  contributor: Contributor;
  allContributors: Contributor[];
  filteredContributors: Contributor[] = [];
  contributorFilter: string;
  contributorLogoURL: string;

  constructor(private api: Api) {}

  ngOnInit() {
    this.loadAllContributors();

    this.refreshContributor();
  }

  private refreshContributor() {
    if (this.contributedBy && this.contributedBy !== '') {
      this.api
        .get(`admin/contributors/${this.contributedBy}`)
        .then(res => {
          this.contributor = res;
          this.loadContributorLogo();
        })
        .catch(err => console.log(err));
    }
  }

  private loadAllContributors() {
    this.api
      .get(`admin/contributors`)
      .then(res => {
        this.allContributors = res.contributors.Items;
        this.allContributors = this.allContributors.sort((c1: Contributor, c2: Contributor) => {
          const name1 = c1.name.toLowerCase();
          const name2 = c2.name.toLowerCase();
          if (name1 > name2) {
            return 1;
          }
          if (name1 < name2) {
            return -1;
          }
          return 0;
        });
      })
      .catch(err => console.log(err));
  }

  search() {
    this.filteredContributors = [];
    const regEx = new RegExp(this.contributorFilter.trim(), 'i');
    this.allContributors.forEach(contributor => {
      const doFiltering =
        this.contributorFilter.length > 0 && contributor.name && contributor.name.match(regEx);
      if (doFiltering) {
        this.filteredContributors.push(contributor);
      }
    });
  }

  loadContributorLogo() {
    if (this.contributor.logo) {
      Storage.get(this.contributor.logo).then((url: string) => (this.contributorLogoURL = url));
    }
  }

  /**
   *
   * @param contributorId
   */
  selectContributor(contributorId: string) {
    this.contributedBy = contributorId;
    console.log(contributorId);
    this.filteredContributors = [];
    this.contributorFilter = '';
    this.refreshContributor();
    this.updatedContributor.emit(this.contributedBy);
  }

  removeContributor() {
    this.contributedBy = null;
    this.contributor = null;
  }
}
