import { Component, Input, OnInit } from '@angular/core';
import { LprxApiProvider } from '../../../providers/lprx-api/api-provider';
import { isBadClock } from '../../is-bad-clock';

@Component({
  selector: 'app-bad-clock',
  templateUrl: './bad-clock.component.html',
  styleUrls: ['./bad-clock.component.scss']
})
export class BadClockComponent implements OnInit {
  @Input()
  minutes: number = 4.5;

  @Input()
  message: string =
    "You device's clock is off by more than 4 minutes 30 seconds.\n" +
    '    Please correct your system clock before continuing.';

  isBadClock: boolean = false;

  constructor(private lprxApi: LprxApiProvider) {}

  ngOnInit(): void {
    this.lprxApi
      .time()
      .then(time => isBadClock(4.5, time))
      .then(result => (this.isBadClock = result));
  }
}
