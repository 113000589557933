/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/enum/payout-account-status.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum PayoutAccountStatus {
  PENDING = 'pending',
  COMPLETE = 'complete'
}
