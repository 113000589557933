import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss']
})
export class PasswordInputComponent implements OnInit {
  showPassword = true;

  password = '';
  // passwordConfirm: string;
  isPasswordLongEnough = false;
  doesPasswordHaveNumber = false;
  doesPasswordHaveLowercase = false;
  doesPasswordHaveUppercase = false;
  // doesPasswordMatch = false;
  isPasswordValid = false;

  @Output()
  isValidated = new EventEmitter<{ valid: boolean; password: string }>();

  constructor() {}

  ngOnInit(): void {}

  validatePassword() {
    const p = this.password;
    this.isPasswordLongEnough = p.length >= 6;
    this.doesPasswordHaveLowercase = p.match(/[a-z]/) !== null;
    this.doesPasswordHaveUppercase = p.match(/[A-Z]/) !== null;
    this.doesPasswordHaveNumber = p.match(/[0-9]/) !== null;
    // this.doesPasswordMatch = this.password === this.passwordConfirm;

    this.isPasswordValid =
      this.isPasswordLongEnough &&
      this.doesPasswordHaveUppercase &&
      this.doesPasswordHaveLowercase &&
      this.doesPasswordHaveNumber; // &&
    // this.doesPasswordMatch;
    console.log(this.isPasswordValid);

    this.isValidated.emit({ valid: this.isPasswordValid, password: this.password });
  }
}
