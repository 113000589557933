/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/meal-plan/MealPlanState.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export enum MealPlanState {
  Draft = 'draft',
  Test = 'test',
  Active = 'active',
  Private = 'private',
  Disabled = 'disabled'
}
