import { Injectable } from '@angular/core';
import { ActivateAccountDto } from '../../lprx-shared-lib/dto/activate-account-dto';
import { SetPasswordDto } from '../../lprx-shared-lib/dto/set-password-dto';
import { BundleClient } from '../../lprx-shared-lib/entities/bundle-client';
import { User } from '../../lprx-shared-lib/entities/user/user';
import { PasswordPasser } from '../../lprx-shared-lib/password-passer';
import { Api, Auth } from '../../providers/aws.api';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { AuthService } from '../auth.service';
import { Distributor } from '../../lprx-shared-lib/entities/distributor';
import { SetPasswordResponse } from '../../lprx-shared-lib/dto/set-password-response';

@Injectable()
export class ActivateAccountService {
  constructor(
    private api: Api,
    public authService: AuthService,
    private lprxApi: LprxApiProvider,
  ) {}

  async getBundleClient(clientId: string, bundleId: string): Promise<BundleClient> {
    return this.api.get(`user/activation/${clientId}/${bundleId}`);
  }

  async getDistributor(distributorId: string): Promise<Distributor> {
    return this.api.get(`distributor/${distributorId}`);
  }


  async activateAccount(bundleClient: BundleClient, password: string): Promise<any> {
    const dto = new ActivateAccountDto(bundleClient, password);
    return this.lprxApi.users.activate(dto);
  }

  async signIn(email: string, password: string) {
    await this.authService.signOut();
    // await this.authService.signIn(email, password);
  }

  async getClient(clientId: string, sig: string) {
    return this.lprxApi.get<User>(`v3/activate/${clientId}/${sig}`);
  }

  async activateAccountPassword(username: string, sig: string, password: string) {
    password = PasswordPasser.encrypt(password);
    const payload: SetPasswordDto = { userId: username, sig, p: password };
    return this.lprxApi.post<SetPasswordResponse>(`v3/activate/account`, payload);
  }
}
