<div *ngIf="error" class="alert alert-danger text-center">{{ error }}</div>

<main *ngIf="showSetup" role="main" class="container my-5">
  <div class="row">
    <div class="col-12 bg-white p-5">
      <div *ngIf="!currentStep">
        <h3>Fantastic, you're finished!</h3>
        <hr />
        <div style="font-size: 16px">
          <p></p>
          <p><strong>You're almost ready to serve up Meal Plans.</strong></p>
        </div>
        <a routerLink="/distributor/meal-plans" appButton>Continue to Meal Planner</a>
        <br />
        <br />
      </div>

      <form *ngIf="currentStep" [formGroup]="profileForm">
        <h3>Set up your Meal Planner!</h3>
        <br>
        <div class="row">
          <div class="col-lg-4">
            <div class="d-none d-lg-block">
              <ul class="steps">
                <ng-container *ngFor="let step of steps">
                  <li
                    *ngIf="step.id !== 'review' || profileForm.valid"
                    (click)="toStep(step)"
                    [class.active]="currentStep.id == step.id"
                    [class.complete]="step.isComplete && profileForm.get(step.id).valid"
                  >
                    {{ step.name }}
                    <i class="fa fa-check check-mark"></i>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>

          <div class="col-12 col-lg-8">
<!--            <div *ngIf="currentStep.id == 'welcome'">-->
<!--              <h4 class="mb-3">Welcome to Living Plate Rx</h4>-->
<!--              <hr />-->
<!--              <div style="font-size: 16px">-->
<!--                <p>Hi, {{ distributor.firstName }}.</p>-->
<!--                <p>-->
<!--                  Now that your practitioner account is ready, we need a few ingredients from you in-->
<!--                  order to completely serve up your account.-->
<!--                </p>-->

<!--                <p>While it looks like there are a lot of steps, it'll go pretty fast.</p>-->
<!--                <div class="text-right">-->
<!--                  <button type="button" appButton (click)="nextStep()">Let's Get Cooking</button>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

            <!--                        <div [hidden]="currentStep.id !== 'agreement'" formGroupName="agreement">-->
            <!--                            <h3>Terms of Use Agreement</h3>-->

            <!--                            <br>-->

            <!--                            <div style="height: 300px; overflow-y: scroll; border: 1px solid #ccc; padding: 15px">-->
            <!--                                <app-terms-html></app-terms-html>-->
            <!--                            </div>-->
            <!--                            <a href='/terms' target='_blank'>View Larger</a>-->
            <!--                            <br>-->
            <!--                            <br>-->
            <!--                            <input id="agree" type="checkbox" formControlName="termsAgreement" (change)="checkCompleteAgreement($event)">&nbsp;-->
            <!--                            <label for="agree">-->
            <!--                                I acknowledge and agree to the terms set forth above.-->
            <!--                            </label>-->

            <!--                        </div>-->

            <div [hidden]="currentStep.id !== 'profile'" formGroupName="profile">
              <h4 class="mb-3">Your Business Profile</h4>
              <hr />

              <p>
                We've pre-populated your profile with the information you provided us during your
                application. Please verify that it is correct and make any changes that you many
                need.
              </p>

              <div>
                <div class="row">
                  <div class="col-sm-6 col-md-4">
                    <div class="form-group">
                      <label for="first-name">First Name</label>
                      <input
                        id="first-name"
                        class="form-control"
                        type="text"
                        formControlName="firstName"
                        name="distributor.firstName"
                        required
                      />
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-4">
                    <div class="form-group">
                      <label for="last-name">Last Name</label>
                      <input
                        id="last-name"
                        formControlName="lastName"
                        required
                        type="text"
                        class="form-control"
                        name="distributor.lastName"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label>Credentials</label>
                      <input
                        formControlName="credentials"
                        type="text"
                        class="form-control"
                        name="distributor.credentials"
                      />
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label for="distributor.business">Business Name</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="business"
                    name="distributor.business"
                    id="distributor.business"
                    required
                  />
                </div>

                <div class="form-group">
                  <label for="distributor.email">Business Email</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="email"
                    name="distributor.email"
                    id="distributor.email"
                    required
                  />
                </div>

                <div class="form-group">
                  <label>Website</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="website"
                    name="distributor.website"
                    required
                  />
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="country">Country</label>
                      <select
                        id="country"
                        class="form-control"
                        formControlName="country"
                        name="distributor.country"
                        required
                      >
                        <option value="US">United States</option>
                        <option value="CA">Canada</option>
                        <option value="EG">Egypt</option>
                        <option value="IN">India</option>
                        <option value="GB">United Kingdom</option>
                        <option disabled>----------</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">Bolivia, Plurinational State of</option>
                        <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">British Indian Ocean Territory</option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">Congo, the Democratic Republic of the</option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CW">Curaçao</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">Heard Island and McDonald Islands</option>
                        <option value="VA">Holy See (Vatican City State)</option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">Korea, Democratic People's Republic of</option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">Lao People's Democratic Republic</option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">Macedonia, the former Yugoslav Republic of</option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">Micronesia, Federated States of</option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">Palestinian Territory, Occupied</option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Réunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">Saint Vincent and the Grenadines</option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SX">Sint Maarten (Dutch part)</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">South Georgia and the South Sandwich Islands</option>
                        <option value="SS">South Sudan</option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">United States Minor Outlying Islands</option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">Venezuela, Bolivarian Republic of</option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.S.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="postal-code">Postal Code</label>
                      <input
                        id="postal-code"
                        class="form-control"
                        type="text"
                        formControlName="postalCode"
                        name="distributor.postalCode"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div [hidden]="currentStep.id !== 'about'" formGroupName="about">
              <h4 class="mb-3">About You</h4>
              <hr />
              <p>
                Tell us about yourself or your business. Please keep it around 100 words and in the
                first person.
              </p>

              <textarea
                class="form-control"
                rows="5"
                name="distributor.bio"
                (keyup)="bioWordCount()"
                formControlName="bio"
                >{{ distributor.bio }}
                            </textarea
              >
              <small class="text-muted"> Word Count: {{ bioNumberWords }}</small>
            </div>

            <div [hidden]="currentStep.id !== 'logo'" formGroupName="logo">
              <h4 class="mb-3">Add Your Logo</h4>
              <hr />

              <p>
                Your logo is used to brand your landing pages, as well as, branding the meal planner
                for your clients.
              </p>

              <p>Horizontal logos work best as it fits much better on the website.</p>

              <label for="logoFile" style="display: block">
                <img *ngIf="logoUrl" src="{{ logoUrl }}" style="max-width: 300px" />
                <br /><Br />
                <div btnSecondary btnSmall>
                  <span *ngIf="!logoUrl">Upload</span>
                  <span *ngIf="logoUrl">Change</span>
                  Your Logo
                </div>
              </label>
              <input
                id="logoFile"
                type="file"
                style="visibility: hidden"
                accept="image/x-png,image/gif,image/jpeg"
                (change)="uploadFromFile($event, 'logo')"
              />
            </div>

            <div [hidden]="currentStep.id !== 'photo'" formGroupName="photo">
              <h4 class="mb-3">Your Profile Image</h4>
              <hr />

              <p>
                To give a personal touch for your clients and our community, please add a picture of
                yourself. As an alternative, you can use a square representation of your logo or an
                icon.
              </p>

              <div *ngIf="croppedImageUrl">
                <img [src]="croppedImageUrl" width="300" />
                <br />
                <br />
              </div>

              <label for="photoFile" style="">
                <div btnSecondary btnSmall>New Profile Image</div>
                <input
                  id="photoFile"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg"
                  style="visibility: hidden"
                  (change)="changedProfileImage($event)"
                />
              </label>

              <span class="text-danger"><em>*Use a square photo for best results.</em></span>

              <br />
            </div>

            <div [hidden]="currentStep.id !== 'vanity'" formGroupName="vanity">
              <h4 class="mb-3">Landing Pages</h4>
              <hr />

              <p>
                Your landing pages are how you can direct your clients to sign up for a particular
                meal plan. You'll create a identifier below.
              </p>

              <div class="form-group">
                <label>Vanity ID (Used for Link to Landing Pages)</label>
                <input
                  class="form-control"
                  type="text"
                  formControlName="vanityId"
                  autocomplete="off"
                  (change)="validateVanityId()"
                  (keyup)="validateVanityId()"
                  name="distributor.vanityId"
                  required
                />

                <div
                  *ngIf="profileForm.get('vanity').get('vanityId').errors?.inUse"
                  class="alert alert-danger"
                >
                  Sorry, <strong>{{ profileForm.get('vanity').get('vanityId').value }}</strong>
                  is already taken.
                </div>

                <small class="form-text text-muted">
                  Here is an example of what your landing page URLs will look like:
                  <br />https://www.livingplaterx.com/<strong>{{
                    profileForm.get('vanity').get('vanityId').value
                  }}</strong
                  >/plan/signup
                </small>
              </div>

              <!--                                <div class="text-right">-->
              <!--                                    <button type="button" appButton (click)="nextStep()"-->
              <!--                                            [disabled]="!isValidVanityId || !profileForm.valid || isProcessing">-->
              <!--                                        <span *ngIf="!isProcessing">Finish</span>-->
              <!--                                        <span *ngIf="isProcessing">Just a moment, please.</span>-->
              <!--                                    </button>-->
              <!--                                </div>-->
            </div>

            <!--                        <div [hidden]="currentStep.id !== 'login'" formGroupName="login">-->

            <!--                            <h4 class="mb-3">Create your login</h4>-->
            <!--                            <hr>-->

            <!--                            <div class="form-group">-->
            <!--                                <label for="username">Email</label>-->
            <!--                                <input type="email"-->
            <!--                                       id="username"-->
            <!--                                       name="username"-->
            <!--                                       class="form-control mb-3"-->
            <!--                                       formControlName="email">-->
            <!--                            </div>-->

            <!--                            <br>-->

            <!--                            <strong>Password Requirements</strong>-->
            <!--                            <ul>-->
            <!--                                <li [class.text-success]="isPasswordLongEnough"-->
            <!--                                    [class.text-danger]="password && !isPasswordLongEnough">-->
            <!--                                    At least 6 Characters-->
            <!--                                </li>-->

            <!--                                <li [class.text-success]="doesPasswordHaveUppercase"-->
            <!--                                    [class.text-danger]="password && !doesPasswordHaveUppercase">-->
            <!--                                    At least 1 Uppercase character-->
            <!--                                </li>-->

            <!--                                <li [class.text-success]="doesPasswordHaveLowercase"-->
            <!--                                    [class.text-danger]="password && !doesPasswordHaveLowercase">-->
            <!--                                    At least 1 Lowercase character-->
            <!--                                </li>-->

            <!--                                <li [class.text-success]="doesPasswordHaveNumber"-->
            <!--                                    [class.text-danger]="password && !doesPasswordHaveNumber">-->
            <!--                                    At least 1 Number-->
            <!--                                </li>-->
            <!--                            </ul>-->

            <!--                            <div *ngIf="error" class="alert alert-danger">-->
            <!--                                {{error}}-->
            <!--                            </div>-->

            <!--                            <div *ngIf="profileForm.get('login').get('email').errors?.existingEmail"-->
            <!--                                 class="alert alert-danger">-->
            <!--                                There is an existing account for-->
            <!--                                <strong>{{profileForm.get('login').get('email').value}}</strong>.-->
            <!--                            </div>-->

            <!--                            <div class="form-group">-->
            <!--                                <label for="inputPassword" class="-sr-only">Password</label>-->
            <!--                                <input type="password"-->
            <!--                                       formControlName="password"-->
            <!--                                       id="inputPassword"-->
            <!--                                       name="password"-->
            <!--                                       placeholder="Password"-->
            <!--                                       required-->
            <!--                                       class="form-control mb-3">-->
            <!--                            </div>-->

            <!--                            <div class="form-group">-->
            <!--                                <label for="confirmPassword" class="-sr-only">Repeat Password</label>-->
            <!--                                <input type="password"-->
            <!--                                       formControlName="passwordConfirm"-->
            <!--                                       name="confirmPassword"-->
            <!--                                       id="confirmPassword"-->
            <!--                                       placeholder="Repeat Password"-->
            <!--                                       class="form-control mb-3"-->
            <!--                                       required="">-->
            <!--                            </div>-->

            <!--                            <div *ngIf="profileForm.get('login').errors?.passwordNotConfirmed"-->
            <!--                                 class="alert alert-danger">-->
            <!--                                Passwords do not match.-->
            <!--                            </div>-->

            <!--                        </div>-->

            <div [hidden]="currentStep.id !== 'review'">
              <h4 class="mb-3">Review</h4>
              <hr />
              <h5>Business Profile</h5>
              <p>
                {{ profileForm.get('profile').get('business').value }}

                <br />
                Name:
                {{ profileForm.get('profile').get('firstName').value }}
                {{ profileForm.get('profile').get('lastName').value }}

                <br />
                Credentials:
                {{ profileForm.get('profile').get('credentials').value }}

                <br />
                Email:
                {{ profileForm.get('profile').get('email').value }}

                <br />
                Website:
                {{ profileForm.get('profile').get('website').value }}

                <br />
                Location:
                {{ profileForm.get('profile').get('country').value }},
                {{ profileForm.get('profile').get('postalCode').value }}
              </p>
              <hr />
              <h5>Logo</h5>
              <p>
                <img *ngIf="logoUrl" src="{{ logoUrl }}" style="max-width: 300px" />
              </p>

              <hr />
              <h5>About You</h5>
              <p>
                {{ profileForm.get('about').get('bio').value }}
              </p>

              <hr />

              <h5>Profile Image</h5>
              <p>
                <img [src]="croppedImageUrl" width="300" />
              </p>

              <hr />
              <h5>Landing Pages</h5>
              <p>Vanity Id: {{ profileForm.get('vanity').get('vanityId').value }}</p>

              <!--              <hr />-->
              <!--              <h5>Login</h5>-->
              <!--              <p>-->
              <!--                Email: {{ profileForm.get('login').get('email').value }}<br />-->
              <!--                Password: *****************-->
              <!--              </p>-->

              <hr />
              <br />
            </div>

            <div class="text-right">
              <button
                *ngIf="currentStep.id !== 'welcome'"
                class="btn btn-link"
                type="button"
                (click)="prevStep()"
              >
                Back
              </button>

              <ng-container *ngIf="currentStep.id !== 'review' && currentStep.id !== 'welcome'">
                <button
                  appButton
                  type="button"
                  (click)="nextStep()"
                  [disabled]="
                    profileForm.get(currentStep.id).invalid ||
                    profileForm.get(currentStep.id).pending
                  "
                >
                  Continue
                </button>
              </ng-container>

              <button
                [hidden]="currentStep.id !== 'review'"
                appButton
                type="button"
                (click)="submit()"
              >
                Finish
                <app-loading *ngIf="isProcessing" [style]="'icon'"></app-loading>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
