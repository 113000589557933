/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/getCurrentWeekNumber.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import * as moment from 'moment';

export function buildWeekNumber(year: number, week: number) {
  return (year + week / 100).toFixed(2);
}

/**
 * Gets the current week number determined by the date.
 *
 * @returns {string}
 */
export function getCurrentWeekNumber() {
  return buildWeekNumber(moment().isoWeekYear(), moment().isoWeek());
}

export function getCurrentDayName() {
  return moment()
    .format('dddd')
    .toLowerCase();
}

function toMoment(weekNumber: string) {
  let year: string;
  let week: string;
  [year, week] = weekNumber.split('.');
  const yr = parseInt(year, 10);
  const wk = parseInt(week, 10);
  return moment()
    .isoWeekYear(yr)
    .isoWeek(wk)
    .isoWeekday(1);
}

/**
 * Get the next week number
 *
 * @param weekNumber
 * @param weeks
 */
export function addWeek(weekNumber: string, weeks: number = 1) {
  return toMoment(weekNumber)
    .add(weeks, 'week')
    .format('GGGG.WW');
}

/**
 * Get the previous week number.
 *
 * @param weekNumber
 * @param weeks
 * @returns {string}
 */
export function subWeek(weekNumber: string, weeks: number = 1) {
  return toMoment(weekNumber)
    .subtract(weeks, 'week')
    .format('GGGG.WW');
}

/**
 *
 * @param weekNumber
 */
export function isWeekNumber(weekNumber: string) {
  return weekNumber.match(/^2[0-9]{3}\.[0-5][0-9]$/);
}
