<p class="text-muted">Updated 14-MAY-2024</p>
<p id="isPasted">
  <strong
    ><u
      >IMPORTANT &ndash; READ THESE TERMS OF USE CAREFULLY BEFORE USING THE WEBSITE AND THE
      SOFTWARE</u
    ></strong
  >
</p>
<p>
  <u>Acceptance of the Terms of&nbsp;</u>Use. These terms of use are entered into by and between You
  (&quot;<u>Licensee</u>&quot;) and&nbsp;Living Plate Rx&nbsp;(&quot;<strong><u>Company</u></strong
  >&quot;, &quot;<strong><u>Living Plate Rx</u></strong
  >&quot;, &quot;<strong><u>we</u></strong
  >&quot; or &quot;<strong><u>us</u></strong
  >&quot;). The following terms and conditions, together with any documents they expressly
  incorporate by reference (collectively, these &quot;<strong><u>Terms of Use</u></strong
  >&quot;), govern your access to and use of&nbsp;<a href="http://www.livingplaterx.com"
    >www.livingplaterx.com</a
  >
  by way of a Living Plate membership subscription (&ldquo;<strong>Membership</strong>&rdquo;)
  including any content, functionality, software and services offered on or through&nbsp;<a
    href="http://www.livingplaterx.com"
    >www.livingplaterx.com</a
  >
  (the &quot;<strong><u>Website</u></strong
  >&quot;).
</p>
<p>
  Please read the Terms of Use carefully before you start to use the Website. By using the Website
  or by clicking to accept or agree to the Terms of Use when this option is made available to you,
  you accept and agree to be bound and abide by these Terms of Use. If you do not want to agree to
  these Terms of Use, you must not access or use the Website.
</p>
<p>
  <u>License Grant</u>. Subject to the terms of this Agreement, Company grants you a limited,
  non-exclusive, and non-transferable license to download, install, use, and copy the Content for
  electronic or hardcopy distribution to Your patients and/or clients <strong>strictly</strong> in
  accordance with this agreement. You may also modify, translate, adapt and create derivative works
  or improvements of the Content, including branding, editing, and adding Your logo or trademark to
  the Content. &nbsp;Collectively, the aforementioned uses shall be referred to as &quot;<u
    >Permitted Uses</u
  >.&quot; &nbsp;When Content is distributed to patients and/or clients, You will ensure that all
  Content is clearly marked as being limited to the individual patient or client&rsquo;s use and not
  for distribution to third parties. Further, when Content containing recipes is distributed to
  patients and/or clients, it is best practice to provide attribution to the recipe developer listed
  on the Content, In the event that any patient and/or client terminates their relationship with
  You, You will promptly bar such patient or client&rsquo;s access to the Content.
  &ldquo;<u>Content</u>&rdquo; for the purposes of this Agreement means each discrete creative work
  on the Website (or accessed through the Website) whenever made available by Living Plate Rx,
  including, without limitation, recipes, recipe notes, recipe photographs, recipe directions,
  recipe videos, recipe methods, recipe tests, recipe plans, meal plans, meal plan notes, meal plan
  photographs, meal plan directions, meal plan videos, meal plan methods, meal plan tests, grocery
  lists, grocery list notes, grocery list photographs, grocery list directions, grocery list videos,
  grocery list methods, grocery methods, grocery list tests, grocery list descriptions, lesson
  plans, modules, social media content, professional tools, referral forms, handouts, content items
  containing content from third parties used with permission or license (whether or not modified,
  amended or adapted by Living Plate Rx), information, or other data derived from, made available
  on, or otherwise viewable on or from the Website.
</p>
<p>
  <u>License Restrictions</u>. Licensee shall not: (A) reverse engineer, disassemble, decompile,
  decode, or otherwise attempt to derive or gain access to the source code of the Website or any
  underlying software or any part thereof; (B) except as permitted herein, rent, lease, lend, sell,
  sublicense, assign, distribute, publish, transfer, or otherwise make available the Content to any
  third party other than Your Patients and/or clients for any reason, including, without limitation,
  by making the Content available on a network where it is capable of being accessed by more than
  one device at any time.
</p>
<p>
  <u>Period of License</u>. Licensee shall have a limited, non-exclusive and nontransferable license
  to use the Content for Permitted Uses, so long as Licensee pays for a Monthly/Annual Subscription.
  Your failure to pay Monthly/Annual Subscription fees, or Your cancellation of the Monthly/Annual
  Subscription, will lead to <strong>immediate termination</strong> of this Agreement and Your
  license to use the Content for Permitted Uses. Upon termination of this Agreement, You will
  immediately cease any and all use of the Content and shall not distribute the Content to any
  patients or clients. Should You continue to access and use the Content after your license has
  terminated, Company reserves the right to take all necessary steps against You for violation(s) of
  these Terms of Use.
</p>
<p>
  <u>Reservation of Rights</u>. You acknowledge and agree that the Website and the Content is
  provided under license, and not sold, to you. You do not acquire any ownership interest in the
  Website and the Content under these Terms of Service, or any other rights thereto other than to
  use the Website and the Content strictly in accordance with the license granted, and subject to
  all terms, conditions, and restrictions, under these Terms of Use. Company reserves and shall
  retain its entire right, title, and interest in and to the Website and the Content, including all
  copyrights, trademarks, and other intellectual property rights therein or relating thereto, except
  as expressly granted to you in these Terms of Use.
</p>
<p>
  <u>Monthly/Annual/Annual Subscription Fees and Payment</u>. By purchasing a Monthly/Annual
  Subscription for Membership to access and use the Website and Content for Permitted Uses, you
  agree to an initial and recurring Monthly/Annual Subscription fee at the then-current
  Monthly/Annual Subscription rate. You accept responsibility for all recurring charges until You
  cancel your subscription. Monthly/Annual Subscription fees are non-refundable after You are billed
  for the then-current month. You may cancel your Monthly/Annual subscription at any time. If
  Company does not receive payment from your credit card provider or if your credit card is expired
  or is rejected, You agree to pay all amounts due upon demand. Following any such non-payment, we
  may require You to provide a second valid credit card before continuing to access and use the
  Content. You authorize Company to charge outstanding fees and other amounts due to Company against
  any credit card You have on file with Company. Should You not provide another method of payment,
  your Monthly/Annual Subscription and license to use the Content will terminate. Company reserves
  the right to take all steps necessary to collect amounts due from You, including, but not limited
  to legal action and/or the use of third-party collection agencies. If your account is more than
  thirty (30) days past due, and is forwarded to a collection agency, You will additionally be
  liable for any recovery fees charged by the agency. You are solely responsible for any and all
  fees charged to your credit card by the issuer, bank, or financial institution, including, but not
  limited to, membership, overdraft, insufficient funds, and over the credit limit fees. Company
  reserves the right to modify the price of the Monthly/Annual Subscription at any time. If Company
  modifies any of the foregoing terms, You may cancel your Monthly/Annual Subscription, and such
  cancellation will be your sole remedy. Company may provide notice of pricing changes by email, a
  notice to You upon log-in, or by publishing on the Website.
</p>
<p>
  <u>Visitors and Users of the Website</u>. &nbsp;Visitors and Users of the Website who do not
  maintain a Monthly/Annual Subscription for Membership are strictly prohibited from &nbsp;(A)
  copying, reproducing or downloading the Content (B) modifying, translating, adapting, or otherwise
  creating derivative works or improvements, whether or not patentable or copyrightable, of the
  Content; (C) reverse engineering, disassembling, decompiling, decoding, or otherwise attempting to
  derive or gain access to the source code of the Website or any underlying software or any part
  thereof; (D) removing, deleting, altering, or obscuring any trademarks or any copyright,
  trademark, patent, or other intellectual property or proprietary rights notices from the Content,
  including any copy thereof; or (E) except as permitted herein, renting, leasing, lending, selling,
  sublicensing, assigning, distributing, publishing, transferring, or otherwise making available the
  Content to any third party for any reason, including by making the Content available on a network
  where it is capable of being accessed by more than one device at any time.
</p>
<p>
  <u>Intellectual Property Rights</u>.&nbsp;The Website and its entire contents, features and
  functionality (including but not limited to all information, software, text, displays, images,
  video and audio, and the design, selection and arrangement thereof), are owned by the Company, its
  licensors or other providers of such material and are protected by United States and international
  copyright, trademark, patent, trade secret and other intellectual property or proprietary rights
  laws.
</p>
<p>
  Except as expressly set forth herein, these Terms of Use permit you to use the Website and the
  Content for the Permitted Uses. You must not reproduce, distribute, modify, create derivative
  works of, publicly display, publicly perform, republish, download, store or transmit any of the
  Content and material on our Website, except as follows: (A) in accordance with your License (as
  described above); (B) Your computer may temporarily store copies of such materials in RAM
  incidental to your accessing and viewing those materials; (C) You may store files that are
  automatically cached by your Web browser for display enhancement purposes; (D) If we provide
  desktop, mobile or other applications for download, you may download a single copy to your
  computer or mobile device solely for the Permitted Uses; and (E) If we provide
  <em>social media features</em> with certain Content, you may take such actions as are enabled by
  such features.
</p>
<p>
  If you print, copy, modify, download or otherwise use or provide any other person with access to
  any part of the Website and Content outside the scope of the License Grant and in breach of the
  Terms of Use, your right to use the Website and Content will cease immediately and you must, at
  our option, return or destroy any copies of the materials you have made. No right, title or
  interest in or to the Website or any Content on the Website is transferred to you, and all rights
  not expressly granted are reserved by the Company. Any use of the Website and Content not
  expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate
  copyright, trademark and other laws.
</p>
<p>
  <u>Registered Users</u>.&nbsp;Access to and use of the Website&rsquo;s data is limited to
  individuals who are at least 18 years of age and residents of the United States. You must register
  to create an account (&ldquo;<u>User Account</u>&rdquo;) and become a &ldquo;<u>Registered User</u
  >&rdquo; to use the Website. To register, You must provide Your name, Your email address, proof
  (in a form satisfactory to the Company in its sole and absolute discretion) of your healthcare
  credentials, and other information specified in the registration form (&ldquo;<u
    >Registration Data</u
  >&rdquo;). By registering, and in consideration of the use of the Website, You represent and
  warrant: (A) the Registration Data that You provide about Yourself is true, accurate, current, and
  complete; (B) You are at least 18 years of age; and (C) You will maintain and promptly update the
  Registration Data to keep it at all times true, accurate, current, and complete. You may change or
  correct information from Your account by logging into Your User Account directly and making the
  desired changes. You agree not to register for a User Account on behalf of an individual other
  than Yourself unless You are authorized to bind such person to these Terms.
</p>
<p>
  You agree that all information you provide to register with this Website or otherwise, including
  but not limited to through the use of any interactive features on the Website, is governed by our
  <u>Privacy Policy</u> at www.livingplaterx.com/privacy. By clicking on the
  &ldquo;<u>Agree</u>&rdquo; or &ldquo;<u>I Accept</u>&rdquo; button, or accessing or using the
  Website, you (i) consent to all actions we take with respect to your information consistent with
  our Privacy Policy; and (ii) acknowledge and agree to the provisions of the Privacy Policy and
  affirm that the Privacy Policy forms a part of these terms.
</p>
<p>
  Use of the Website is void where prohibited by law or otherwise. By using the Website, You
  represent and warrant that You have the right, authority, and capacity to agree to and abide by
  these Terms and that You are not prohibited from using the Website. You understand that Your use
  of the Website may involve or require the transmission of significant amounts of data. You are
  solely responsible for all data charges that may be assessed by Your wireless carrier or Internet
  service provider or that may otherwise arise from Your use of the Website.
</p>
<p>
  <u>Changes to the Terms of Use.&nbsp;</u> We may revise and update these Terms of Use from time to
  time in our sole discretion. All changes are effective immediately when we post them, and apply to
  all access to and use of the Website and Content thereafter. Your continued use of the Website and
  Content following the posting of revised Terms of Use means that you accept and agree to the
  changes. You are expected to check this page from time to time so you are aware of any changes, as
  they are binding on you.<u>&nbsp;</u>
</p>
<p><strong>MEDICAL ADVICE DISCLAIMER</strong></p>
<p>
  The Website and Content and the information available to You through the Website and Content is
  intended to provide a platform for You to assist You in providing nutrition support and monitoring
  for Your patients or clients through, among things, access to meal plans, modules and recipes and
  other nutrition information tailored to the specific needs of your clients or patients. The
  Website and the Content are in no way intended to replace or supplant the independent clinical
  judgment of a qualified healthcare professional, and should not be used by qualified healthcare
  professionals for active patient monitoring whose purpose is to determine the need for immediate
  clinical action. If any time You are concerned about your patients or clients&rsquo; care, or if
  You believe that your patients or clients have or suspect that they have any serious or
  life-threatening condition, you should advise Your patients or clients to call 9-1-1 in areas
  where that service is available, or go to the nearest emergency room.
</p>
<p>
  THIS WEBSITE AND THE DATA AVAILABLE ON IT IS NOT DESIGNED, INTENDED OR APPROPRIATE TO REPLACE THE
  RELATIONSHIP YOU HAVE WITH YOUR CLIENTS OR PATIENTS OR THE RELATIONSHIP BETWEEN YOUR CLIENTS OR
  PATIENTS AND YOU OR TO ADDRESS SERIOUS, EMERGENT OR LIFE-THREATENING MEDICAL CONDITIONS AND SHOULD
  NOT BE USED IN THOSE CIRCUMSTANCES.&nbsp;
</p>
<p><strong>FOR REGISTERED MEDICAL OR NUTRITION PROFESSIONAL USERS ONLY</strong></p>
<p>
  Any medical advice provided by You or another healthcare provider using information from the
  Website is based on the personal health data You provide. If You do not provide complete and
  accurate personal health information, the medical advice You receive may not be correct.
</p>
<p>
  FURTHER, THIRD-PARTY USE OF OUR WEBSITE (e.g., use by You) IS NOT AN ENDORSEMENT OR RECOMMENDATION
  OF SUCH THIRD PARTY BY THE COMPANY. THE MEDICAL ADVICE PROVIDED BY YOU TO YOUR PATIENTS AND/OR
  CLIENTS L IS NOT UNDER THE CONTROL OF LIVING PLATE RX, NOR IS IT PROVIDED TO OR USED BY LIVING
  PLATE RX. YOU ARE SOLELY RESPONSIBLE FOR THE MEDICAL ADVICE AND TREATMENTS PROVIDED BY YOU TO YOUR
  PATIENTS AND CLIENTS. &nbsp;
</p>
<p>
  By accessing and using the Website, You accept responsibility for Yourself in Your use of the
  Website. You acknowledge that Your relationship with patients or clients for healthcare services
  is with Your patients or clients and providing services to any such patients or clients is solely
  at Your own risk. By using the Website and Content, You agree to not hold Living Plate Rx liable
  in any way for any acts or omissions, including without limitation, malpractice and substandard
  treatment, involving your patients or clients. &nbsp;
</p>
<p>
  Although Living Plate Rx approves registered users as registered dietitians or healthcare
  professionals, IT DOES SO FOR ITS OWN BENEFIT ONLY AND PURSUANT TO ITS OWN STANDARDS, WHICH IT
  APPLIES IN ITS SOLE AND ABSOLUTE DISCRETION. Living Plate Rx does not confirm the credentials of
  any healthcare professional using the Website. Living Plate Rx does not validate that You or any
  such persons are in good standing with their respective licensure board(s) or that any of the
  foregoing are using the Website in accordance with laws applicable to the practice of medicine. It
  is YOUR responsibility to remain in good standing with Your respective licensing board(s) and to
  exercise whatever other due diligence You feel appropriate in selecting and maintaining Your
  choice of healthcare professionals.
</p>
<p>
  General information available through the Website is provided for general educational purposes
  only. You shall inform your patients and customers that they should never disregard, avoid, or
  delay in obtaining medical advice from a physician or other qualified healthcare professional
  because of something contained in the Website. You shall inform your patients and clients in
  writing that if they have or suspect that they have an urgent medical problem or condition, they
  should immediately contact a qualified healthcare professional immediately. YOU SHALL ADVISE ALL
  PATIENTS AND CUSTOMERS IN WRITING THAT (1) THE CONTENT IN THE WEBSITE IS NOT AND SHOULD NOT BE
  CONSIDERED MEDICAL ADVICE OR A SUBSTITUTE FOR INDIVIDUAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT;
  AND (2). YOU SHOULD ALWAYS TALK TO YOUR HEALTHCARE PROVIDERS FOR DIAGNOSIS AND TREATMENT,
  INCLUDING INFORMATION REGARDING WHICH DRUGS, THERAPY, OR OTHER TREATMENT MAY BE APPROPRIATE FOR
  YOU.
</p>
<p>
  <u>Accessing the Website and Account Security</u>. We reserve the right to withdraw or amend this
  Website, and any service or material we provide on the Website, in our sole discretion without
  notice. We will not be liable if for any reason all or any part of the Website is unavailable at
  any time or for any period. From time to time, we may restrict access to some parts of the
  Website, or the entire Website, to users, including registered users.
</p>
<p>
  You are responsible for: (A) making all arrangements necessary for you to have access to the
  Website; and (B) ensuring that all persons who access the Website through your internet connection
  are aware of these Terms of Use and comply with them.
</p>
<p>
  If you choose, or are provided with, a user name, password or any other piece of information as
  part of our security procedures, you must treat such information as confidential, and you must not
  disclose it to any other person or entity. You also acknowledge that your account is personal to
  you and agree not to provide any other person with access to this Website or portions of it using
  your user name, password or other security information. You agree to notify us immediately of any
  unauthorized access to or use of your user name or password or any other breach of security. You
  also agree to ensure that you exit from your account at the end of each session. You should use
  particular caution when accessing your account from a public or shared computer so that others are
  not able to view or record your password or other personal information.
</p>
<p>
  We have the right to disable any user name, password or other identifier, whether chosen by you or
  provided by us, at any time in our sole discretion for any or no reason, including if, in our
  opinion, you have violated any provision of these Terms of Use.
</p>
<p>
  <u>Third-Party Materials</u>. The Website may display, include, or make available third-party
  content (including data, information, applications, and other products, services, and/or
  materials) or provide links to third-party websites or services, including through third-party
  advertising (&ldquo;<u>Third-Party Materials</u>&rdquo;). You acknowledge and agree that Company
  is not responsible for Third-Party Materials, including their accuracy, completeness, timeliness,
  validity, copyright compliance, legality, decency, quality, or any other aspect thereof. Company
  does not assume and will not have any liability or responsibility to you or any other person or
  entity for any Third-Party Materials. Third-Party Materials and links thereto are provided solely
  as a convenience to you, and you access and use them entirely at your own risk and subject to such
  third parties&rsquo; terms and conditions.
</p>
<p>
  <u>Term and Termination</u>. These Terms of Use commence when you acknowledge your acceptance,
  register for use of the Website or use the Website and will continue in effect until terminated by
  you or Company. &nbsp;You may terminate these Terms of Use by deleting your registered account and
  Membership for the Website. Living Plate Rx may terminate the Terms of Use at any time without
  notice, which Company may do in its sole discretion, including immediately upon your failure to
  pay the Membership fees. In addition, these Terms of Use (and your agreement with the Company)
  will terminate immediately and automatically without any notice if you violate any of the terms
  and conditions of these Terms of Use:
</p>
<p>
  Upon termination: (A) all rights granted to you under these Terms of Use (including, without
  limitation, any licenses granted to You) will also terminate; and (B) Termination will not limit
  any of Company&rsquo;s rights or remedies at law or in equity.
</p>
<p>
  <u>Trademarks</u>.&nbsp;The Company name, the terms &ldquo;Living Plate Rx&rdquo;, the Company
  logo and all related names, logos, product and service names, designs and slogans are trademarks
  of the Company or its affiliates or licensors. You must not use such marks without the prior
  written permission of the Company. All other names, logos, product and service names, designs and
  slogans on this Website are the trademarks of their respective owners.
</p>
<p>
  <u>Prohibited Uses</u>.&nbsp;You may use the Website and Content only for lawful purposes and in
  accordance with these Terms of Use. You agree not to use the Website and Content: (A) In any way
  that violates any applicable federal, state, local or international law or regulation (including,
  without limitation, any laws regarding the export of data or software to and from the US or other
  countries); (B) For the purpose of exploiting, harming or attempting to exploit or harm minors in
  any way by exposing them to inappropriate content, asking for personally identifiable information
  or otherwise; (C) To transmit, or procure the sending of, any advertising or promotional material
  without our prior written consent, including any &quot;junk mail&quot;, &quot;chain letter&quot;
  or &quot;spam&quot; or any other similar solicitation; (D) To impersonate or attempt to
  impersonate the Company, a Company employee, another user or any other person or entity
  (including, without limitation, by using e-mail addresses or screen names associated with any of
  the foregoing) and/or (E) To engage in any other conduct that restricts or inhibits anyone&#39;s
  use or enjoyment of the Website, or which, as determined by us, may harm the Company or users of
  the Website or expose them to liability.
</p>
<p>
  Additionally, you agree not to: (A) Use the Website in any manner that could disable, overburden,
  damage, or impair the site or interfere with any other party&#39;s use of the Website, including
  their ability to engage in real time activities through the Website; (B) Use any robot, spider or
  other automatic device, process or means to access the Website for any purpose, including
  monitoring or copying any of the material on the Website; (C) Use any manual process to monitor or
  copy any of the material on the Website or for any other unauthorized purpose without our prior
  written consent; (D) Use any device, software or routine that interferes with the proper working
  of the Website; (E) Introduce any viruses, trojan horses, worms, logic bombs or other material
  which is malicious or technologically harmful; (F) Attempt to gain unauthorized access to,
  interfere with, damage or disrupt any parts of the Website, the server on which the Website is
  stored, or any server, computer or database connected to the Website; (G) Attack the Website via a
  denial-of-service attack or a distributed denial-of-service attack; and/or (H) Otherwise attempt
  to interfere with the proper working of the Website.
</p>
<p>
  <u>Reliance on Information Posted</u>.&nbsp;The information presented on or through the Website is
  made available solely for general information purposes. We do not warrant the accuracy,
  completeness or usefulness of this information. Any reliance you place on such information is
  strictly at your own risk. We disclaim all liability and responsibility arising from any reliance
  placed on such materials by you or any other visitor to the Website, or by anyone who may be
  informed of any of its contents. This Website may include content provided by third parties,
  including materials provided by other users, bloggers and third-party licensors, syndicators,
  aggregators and/or reporting services. All statements and/or opinions expressed in these
  materials, and all articles and responses to questions and other content, other than the content
  provided by the Company, are solely the opinions and the responsibility of the person or entity
  providing those materials. These materials do not necessarily reflect the opinion of the Company.
  We are not responsible, or liable to you or any third party, for the content or accuracy of any
  materials provided by any third parties.
</p>
<p>
  <u>Changes to the Website</u>. We may update the content on this Website from time to time, but
  its content is not necessarily complete or up-to-date. Any of the material on the Website may be
  out of date at any given time, and we are under no obligation to update such material.
</p>
<p>
  <u>Information About You and Your Visits to the Website.</u> All information we collect on this
  Website is subject to our Privacy Policy. By using the Website, you consent to all actions taken
  by us with respect to your information in compliance with the Privacy Policy.
</p>
<p>
  <u>Online Purchases and Other Terms and Conditions</u>.&nbsp;All purchases through our site or
  other transactions for the sale of Content or other information formed through the Website or as a
  result of visits made by you are governed by these Terms of Use.&nbsp;Additional terms and
  conditions may also apply to specific portions, services or features of the Website. All such
  additional terms and conditions are hereby incorporated by this reference into these Terms of Use.
</p>
<p>
  <u>Linking to the Website and Social Media Features</u>.&nbsp;You may link to our homepage,
  provided you do so in a way that is fair and legal and does not damage our reputation or take
  advantage of it, but you must not establish a link in such a way as to suggest any form of
  association, approval or endorsement on our part without our express written consent.<strong
    ><u>&nbsp;</u></strong
  >
</p>
<p>
  This Website may provide certain social media features that enable you to: (A) Link from your own
  or certain third-party websites to certain content on this Website; (B) Send e-mails or other
  communications with certain content, or links to certain content, on this Website; and/or (C)
  Cause limited portions of content on this Website to be displayed or appear to be displayed on
  your own or certain third-party websites.
</p>
<p>
  You may use these features solely as they are provided by us, solely with respect to the content
  they are displayed with and otherwise in accordance with any additional terms and conditions we
  provide with respect to such features. Subject to the foregoing, you must not: (A) Establish a
  link from any website that is not owned by you; (B) Cause the Website or portions of it to be
  displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on
  any other site; (C) Link to any part of the Website other than the homepage; and/or (D) Otherwise
  take any action with respect to the materials on this Website that is inconsistent with any other
  provision of these Terms of Use
</p>
<p>
  You agree to cooperate with us in causing any unauthorized framing or linking immediately to
  cease. We reserve the right to withdraw linking permission without notice. We may disable all or
  any social media features and any links at any time without notice in our discretion.
</p>
<p>
  <u>Links from the Website</u>.&nbsp;If the Website contains links to other sites and resources
  provided by third parties, these links are provided for your convenience only. This includes links
  contained in advertisements, including banner advertisements and sponsored links. We have no
  control over the contents of those sites or resources, and accept no responsibility for them or
  for any loss or damage that may arise from your use of them. If you decide to access any of the
  third-party websites linked to this Website, you do so entirely at your own risk and subject to
  the terms and conditions of use for such websites.
</p>
<p>
  <u>Geographic Restrictions</u>. The owner of the Website is based in the state of New Jersey in
  the United States. We provide this Website for use only by persons located in the United States.
  We make no claims that the Website or any of its content is accessible or appropriate outside of
  the United States. Access to the Website may not be legal by certain persons or in certain
  countries. If you access the Website from outside the United States, you do so on your own
  initiative and are responsible for compliance with local laws.
</p>
<p>
  <u>Disclaimer of Warranties</u>.&nbsp;You understand that we cannot and do not guarantee or
  warrant that files available for downloading from the internet or the Website will be free of
  viruses or other destructive code. You are responsible for implementing sufficient procedures and
  checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data
  input and output, and for maintaining a means external to our site for any reconstruction of any
  lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
  ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
  COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY
  SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON
  IT, OR ON ANY WEBSITE LINKED TO IT.
</p>
<p>
  YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT
  YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE
  PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS, WITHOUT ANY WARRANTIES OF ANY
  KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
  MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
  QUALITY, ACCURACY OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE
  COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
  CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE,
  ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT
  MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY
  SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
</p>
<p>
  THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR
  OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND
  FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
</p>
<p>
  <u>Limitation on Liability</u>. IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS,
  SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND,
  UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
  WEBSITE, THE CONTENT, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES
  OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY
  DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT
  LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
  PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND
  WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF
  FORESEEABLE. THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
  APPLICABLE LAW.
</p>
<p>
  <u>Indemnification</u>. You agree to defend, indemnify and hold harmless the Company, its
  affiliates, licensors and service providers, and its and their respective officers, directors,
  employees, contractors, agents, licensors, suppliers, successors and assigns from and against any
  claims, liabilities, damages, judgments, awards, settlements, interest, penalties, losses, costs,
  expenses or fees (including reasonable attorneys&#39; fees), of any kind, arising out of or
  relating to any third-party claim alleging (i) your violation of these Terms of Use or your use of
  the Website, including, but not limited to, your User Contributions, any use of the Website&#39;s
  content, services and products other than as expressly authorized in these Terms of Use or your
  use of any information obtained from the Website or Content; (ii) any negligent or more culpable
  act or omission of you or your owners, officers, employees, contractors, subcontractors or other
  affiliates; (iii) any bodily injury, death of any person, or damage to real or tangible personal
  property caused by the negligent or more culpable acts or omissions of you or owners, officers,
  employees, contractors, subcontractors or other affiliates; and (iv) any failure by you or your
  owners, officers, employees, contractors, subcontractors or other affiliates to comply with any
  applicable federal, state or local laws, regulations, or codes in the performance of your
  obligations under this agreement. &nbsp;You will defend, indemnify and hold harmless the Company
  regardless of whether or not the Company is alleged to have committed negligent or more culpable
  acts or omissions. &nbsp;
</p>
<p>
  <u>Governing Law and Jurisdiction</u>.&nbsp;All matters relating to the Website and these Terms of
  Use and any dispute or claim arising therefrom or related thereto (in each case, including
  non-contractual disputes or claims), shall be governed by and construed in accordance with the
  internal laws of the State of New Jersey&nbsp;without giving effect to any choice or conflict of
  law provision or rule (whether of the State of New Jersey or any other jurisdiction).&nbsp;Any
  legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Website
  shall be instituted exclusively in the federal courts of the United States or the courts of the
  State of New Jersey in each case located in the County of Morris, although we retain the right to
  bring any suit, action or proceeding against you for breach of these Terms of Use in your country
  of residence or any other relevant country. You waive any and all objections to the exercise of
  jurisdiction over you by such courts and to venue in such courts.
</p>
<p>
  <u>Arbitration</u>.&nbsp;At Company&#39;s sole discretion, it may require You to submit any
  disputes arising from the use of these Terms of Use or the Website, including disputes arising
  from or concerning their interpretation, violation, invalidity, non-performance, or termination,
  to final and binding arbitration under the Rules of Arbitration of the American Arbitration
  Association applying New Jersey law.
</p>
<p>
  <u>Limitation on Time to File Claims</u>. ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF
  OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE
  CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
</p>
<p>
  <u>Waiver and Severability</u>.&nbsp;No waiver of by the Company of any term or condition set
  forth in these Terms of Use shall be deemed a further or continuing waiver of such term or
  condition or a waiver of any other term or condition, and any failure of the Company to assert a
  right or provision under these Terms of Use shall not constitute a waiver of such right or
  provision.&nbsp;If any provision of these Terms of Use is held by a court or other tribunal of
  competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision
  shall be eliminated or limited to the minimum extent such that the remaining provisions of the
  Terms of Use will continue in full force and effect.
</p>
<p>
  <u>Entire Agreement</u>.&nbsp;The Terms of Use, our Privacy Policy and, in the case of Content
  Providers (as defined below), the Content Provider Agreements, constitute the sole and entire
  agreement between you and Living Plate Rx with respect to the Website and supersede all prior and
  contemporaneous understandings, agreements, representations and warranties, both written and oral,
  with respect to the Website.
</p>
<p>
  <u>Your Comments and Concerns</u>.&nbsp;All other feedback, comments, requests for technical
  support and other communications relating to the Website should be directed to:
  help@livingplaterx.com.
</p>
<p><strong>ADDITIONAL TERMS OF SERVICE FOR CONTENT PROVIDERS</strong></p>
<p>
  All content posted by a Content Provider pursuant to a Content Provider Agreement with the Company
  (&ldquo;<u>LP Content</u>&rdquo;) must comply with the Content Standards set out in these Terms of
  Use.
</p>
<p>
  Any LP Content you post to the site will be considered non-confidential and non-proprietary. By
  providing any LP Content indirectly or directly to the Website, you grant us and our affiliates
  and service providers, and each of their and our respective licensees, successors and assigns the
  right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third
  parties any such material for any purpose.
</p>
<p>
  <u>License Grant</u>: Subject to these Terms of Use, by providing LP Content to Living Plate Rx,
  you are granting to Living Plate Rx and its affiliates an irrevocable, non-exclusive,
  fully-transferable right and license in to reproduce, display, transmit and distribute the LP
  Content through all media now known or hereinafter developed.
</p>
<p>
  <u>Waiver of Moral Rights</u>. Content Provider waives to the extent permitted by applicable law,
  all rights of paternity, integrity, attribution, disclosure, withdrawal, and any other rights that
  may be known as moral rights with respect to the LP Content.
</p>
<p>
  <u>Permissions</u>. Content Provider has obtained from all persons and entities whose trademark or
  other property is identified, depicted or otherwise referred to in the LP Content such licenses,
  permissions, waivers and consents as are or reasonably may be expected to be necessary for Living
  Plate Rx to exercise its rights in the LP Content as may be permitted pursuant to these Terms of
  Use.
</p>
<p>
  <u>Ownership</u>. Except for the license granted under these Terms of Use and the Content Provider
  Agreement, the LP Content shall be owned by the Content Provider.
</p>
<p>
  <u>Content Provider&rsquo;s Representations and Warranties</u>. Content Provider represents and
  warrants that: (A) it solely and exclusively owns the right, title, and interest in and to the LP
  Content in connection with the uses permitted hereunder; (B) the exercise by Living Plate Rx of
  the rights and license granted under these Terms of Use will not infringe or otherwise conflict
  with the rights of any other person or entity; (C) there is no settled, pending, or to its
  knowledge threatened litigation, opposition, or other claim or proceeding challenging the
  validity, enforceability, ownership, registration, or use of the LP Content in connection with
  Living Plate Rx uses permitted hereunder; and (D) it has not brought or threatened any claim
  against any third party alleging infringement of the LP Content.
</p>
<p>
  <u>Indemnification</u>.&nbsp;Content Provider shall indemnify, defend and hold harmless Living
  Plate Rx and its affiliates, officers, directors, employees, members, agents, employees,
  successors and assigns (each a &ldquo;<u>LP Indemnified Party</u>&quot;&rdquo; from and against
  all losses, damages, liabilities, deficiencies, claims, actions judgments, settlements, interest,
  awards, penalties, fines, fees, costs or expenses of whatever kind, including attorneys&rsquo;
  fees (collectively, &ldquo;<u>Losses</u>&rdquo;) arising out of or in connection with any
  third-party claim, suit, action or proceeding (&ldquo;<u>Third Party Claim</u>&rdquo;) relating to
  any actual or alleged: (a) breach by Content Provider of any representations, warranty, covenant
  or obligations under these Terms of Use; or (b) any infringement or other violation of any
  intellectual property or other rights of any person or entity resulting from the use of the LP
  Content by Living Plate Rx or any of its affiliates.
</p>
