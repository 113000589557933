import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emailValidator(control: AbstractControl): ValidationErrors | null {
  const email = control.value;

  // trim email and set it back to control if it was trimmed
  if (email) {
    const trimmedEmail = email.trim();
    if (trimmedEmail !== email) {
      control.setValue(trimmedEmail);
    }
  }

  if (!email || email.indexOf('@') <= 0 || !email.includes('.', email.indexOf('@'))) {
    return { email: 'Invalid email.' };
  }

  return null;
}
