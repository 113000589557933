import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { Distributor } from '../../../lprx-shared-lib/entities/distributor';
import { ApiV2 } from '../../../providers/api.v2';
import { ClientAccountService } from './client-account.service';
import { ClientMealPlanService } from './client-meal-plan.service';
import { ClientRecipesService } from './client-recipes-service';

@Injectable()
export class ClientService {
  constructor(
    private apiV2: ApiV2,
    public recipes: ClientRecipesService,
    public account: ClientAccountService,
    public mealPlan: ClientMealPlanService
  ) {}

  /**
   * Get the client's distributor
   */
  getDistributor(): Observable<Distributor> {
    return this.apiV2.get$(`client/account/distributor`) as Observable<Distributor>;
  }

  /**
   * @param receiveWeeklyEmails
   */
  setReceiveWeeklyEmails(receiveWeeklyEmails: boolean) {
    return this.apiV2.post$(`client/account/preferences/weekly-emails`, { receiveWeeklyEmails });
  }
}
