/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/unit-converter.ts
 *  --------------------------
 *  - Swagger items commented out
 */

// noinspection TypeScriptCheckImport
// @ts-ignore

import { UnitDescription } from '../enum/unit-system';
import { convertUnits } from '../convert-units';

export class UnitConverter {
  static findUnit(unitName: string): UnitDescription {
    // noinspection TypeScriptValidateTypes
    const units = convertUnits().list();

    for (const unit of units) {
      if (
        unitName.toLowerCase() === unit.abbr.toLowerCase() ||
        unitName.toLowerCase() === unit.singular.toLowerCase() ||
        unitName.toLowerCase() === unit.plural.toLowerCase()
      ) {
        return unit;
      }
    }
    throw new Error(`Could not find unit '${unitName}'`);
  }

  static findUnitOrNull(unitName: string): UnitDescription | null {
    try {
      return UnitConverter.findUnit(unitName);
    } catch (e) {
      return null;
    }
  }

  static findUnitAbbrvOrDefault(unitName: string): convertUnits.Unit {
    try {
      const unit = UnitConverter.findUnit(unitName);
      return unit.abbr;
    } catch (e) {
      return unitName as convertUnits.Unit;
    }
  }
}
