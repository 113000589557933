/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/system-users.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { plainToClass } from 'class-transformer';
import { User } from './user/user';
import { UserType } from './user/UserType';

const systemUserPartial: Partial<User> = {
  userType: UserType.System,
  firstName: 'SYSTEM',
  lastName: 'SYSTEM',
  username: 'system',
  email: 'admin@livingplaterx.com',
};

export const SYSTEM_USER: User = plainToClass<User, Partial<User>>(User, systemUserPartial);

const anonUserPartial: Partial<User> = {
  userType: UserType.Anonymous,
  firstName: 'ANONYMOUS',
  lastName: 'ANONYMOUS',
  username: 'anonymous',
  email: 'admin@livingplaterx.com',
};

export const ANONYMOUS_USER: User = plainToClass<User, Partial<User>>(User, anonUserPartial);
