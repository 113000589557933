/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/entities/distributor-config.ts
 *  --------------------------
 *  - Swagger items commented out
 */

import { Exclude, Expose, Type } from 'class-transformer';
import { ClientCoupon } from '../client-coupon';
import { DistributorMealPlanPricing } from '../distributor-meal-plan-pricing';
import { Default } from '../utils/default.decorator';
import { Distributor } from './distributor';

const defaultMealPlanPricing = new DistributorMealPlanPricing();
const defaultCurrency = 'usd';

@Exclude()
export class DistributorPlanConfig {
  // export class DistributorPlanConfig implements BaseItem {
  // @Expose()
  // id: string;

  @Expose()
  @Default(true)
  enableFreeTrial: boolean = true;

  @Expose()
  @Default(true)
  enableStaticPlans: boolean = true;

  @Expose()
  @Default(true)
  enableCustomPlans: boolean = true;

  @Expose()
  @Default(true)
  enableSubscriptions: boolean = true;

  @Expose()
  @Default(true)
  enableRenewalEmails: boolean = true;

  // @Expose()
  // createdAt: number;
  //
  // @Expose()
  // isDeleted: boolean;
  //
  // @Expose()
  // modifiedAt: number;

  @Expose()
  @Type(() => DistributorMealPlanPricing)
  @Default(defaultMealPlanPricing)
  pricing: DistributorMealPlanPricing = defaultMealPlanPricing;

  @Expose()
  canAcceptPayments: boolean;

  @Expose()
  @Type(() => ClientCoupon)
  @Default([])
  coupons: ClientCoupon[] = [];

  @Expose()
  @Default(true)
  enableWeeklyEmails: boolean = true;

  constructor(distributor?: Pick<Distributor, 'id'>) {
    // if (distributor) {
    //   this.id = DistributorPlanConfig.genId(distributor);
    // }
  }

  public static genId(distributor: Pick<Distributor, 'id'>) {
    return distributor.id + '.planConfig';
  }

  public static genOldId(distributor: Pick<Distributor, 'id'>) {
    return distributor.id + '.config';
  }
}
