import { Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { PrimeCouponSetting } from '../../../../lprx-shared-lib/prime/commands/prime-coupon-setting';
import { LprxApiProvider } from '../../../../providers/lprx-api/api-provider';
import { map } from 'rxjs/operators';

@Injectable()
export class PrimePurchaseService {
  constructor(private lprx: LprxApiProvider) {}

  checkPromoCode(promoCode: string, planId: string): Observable<PrimeCouponSetting> {
    return fromPromise(this.lprx.primePurchase.checkPromoCode(promoCode, planId));
  }

  getPlans() {
    return fromPromise(this.lprx.primePurchase.getPlans()).pipe(map(r => r.data));
  }

  purchase(planId: string, source: string, promoCode?: string) {
    return fromPromise(this.lprx.primePurchase.purchase(planId, source, promoCode));
  }
}
