import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { switchMap, take } from 'rxjs/operators';
import { DistributorPlanConfig } from '../../lprx-shared-lib/entities/distributor-config';
import { LprxApiProvider } from '../../providers/lprx-api/api-provider';
import { AuthService } from '../auth.service';
import { alertHttpError } from '../utilities/alert-http-error';

@Injectable({
  providedIn: 'root',
})
export class DistributorPlanConfigService {
  constructor(private lprxApi: LprxApiProvider, private auth: AuthService) {}

  getDistConfig(disableErrorAlert = false): Observable<DistributorPlanConfig> {
    const distributorPlanConfigObservable = this.auth.user$.pipe(
      take(1),
      switchMap((u) => from(this.lprxApi.distributorPlanConfig.get(u.distributorId)))
    );

    return disableErrorAlert
      ? distributorPlanConfigObservable
      : distributorPlanConfigObservable.pipe(alertHttpError());
  }

  save(distConfig: DistributorPlanConfig) {
    return from(this.lprxApi.distributorPlanConfig.save(distConfig));
  }
}
