import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WeeklyPlan } from '../../lprx-shared-lib/entities/weeky-meal-plan/WeeklyMealPlan';

@Injectable()
export class PlannerPrinterService {
  private weeklyPlanSubject = new Subject<WeeklyPlan[]>();
  private printingStatusSubject = new Subject<boolean>();
  readonly printingStatus$ = this.printingStatusSubject.asObservable();

  constructor() {}

  printWeek(weeklyPlans: WeeklyPlan[]) {
    console.log(`PlannerPrinterService.printWeek()`);
    this.weeklyPlanSubject.next(weeklyPlans);
  }

  getWeeklyPlan() {
    return this.weeklyPlanSubject.asObservable();
  }

  getPrintingStatus() {
    return this.printingStatusSubject.asObservable();
  }

  donePrinting() {
    this.printingStatusSubject.next(false);
  }
}
