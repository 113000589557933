/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/sanitize-email.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export function sanitizeEmail(email: string) {
  return email.toLowerCase().trim();
}
