/*
 * THIS FILE IS AUTOMATICALLY GENERATED FROM
 * lprx-serverless/src/lprx-shared-lib/utils/calc-quantity-based-on-preferred-servings.ts
 *  --------------------------
 *  - Swagger items commented out
 */

export function calcQuantityBasedOnPreferredServings(
  userServings: number,
  serves: number,
  quantity: number
) {
  if (userServings > 0 && userServings !== serves) {
    quantity = (quantity * userServings) / serves;
  }
  return quantity;
}
